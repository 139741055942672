export default {
  addSubscription: 'Ajouter un abonnement',
  name: {
    allLines: 'Toutes les lignes',
    availabilityInsight: 'Informations sur la disponibilité',
    ignoredCounter: 'Compteur ignoré',
    connectionLost: 'Connexion machine perdue',
    performanceThreshold: 'Performances de la machine inférieures aux attentes',
    cmmsNotification: 'Service de maintenance',
    newDowntime: 'Nouveau temps d\'arrêt',
    oeeThreshold: 'TRS inférieur à l\'objectif',
    ongoingStop: 'Arrêt de ligne en cours',
    orderProductionNotAchieved: 'Commande clôturée avec la production commandée ci-dessous',
    orderDelayed: 'Commande retardée',
    orderProductionDone: 'Production de commandes atteinte',
    orderNotifications: 'Changement de statut de la commande',
    orderWastedThresholdExceeded: 'Seuil de gaspillage de commande dépassé',
    selectedLines: 'Lignes sélectionnées',
    thresholdExceeded: 'Seuil dépassé',
    oeeInfo: 'Résumé OEE',
    oeeSummaryMail: 'Résumé OEE - rapport par e-mail',
  },
  enablePush: 'Activer les notifications push',
  type: {hints: 'Conseils', userNotifications: 'Avis', warnings: 'Mises en garde'},
  timePeriod: {
    immediately: 'Immédiatement',
    daily: 'Dans un récapitulatif quotidien des notifications',
    hourly: 'Dans un résumé des notifications toutes les heures',
    none: 'Non',
    everyEndOfShift: 'À la fin de chaque shift',
    everyDay: 'Chaque jour',
    everyWeek: 'Chaque semaine',
  },
  list: 'Lister',
  mySubscriptions: 'Mes abonnements',
  nothing: 'Aucune notification',
  notificationType: 'Type de notification',
  pushDenied: 'Les notifications ont été bloquées',
  pushNotSupported: 'Notifications non prises en charge dans cet environnement',
  numberOfSubs: 'Nombre d\'abonnements',
  outdated: 'Sice obsolète',
  pushDisabled: 'Notifications push désactivées',
  pushEnabled: 'Notifications push activées',
  mailing: 'Recevoir par e-mail',
  subscribedOnly: 'Abonné uniquement',
  subscription: 'Abonnement',
  teamsSubscriptions: 'Abonnements des équipes',
  subscriptionFromTeam: 'Cet abonnement provient de l\'équipe et ne peut être modifié que pour l\'ensemble de l\'équipe par un utilisateur disposant des autorisations appropriées',
  threshold: 'Seuil',
  usersSubscriptions: 'Abonnements utilisateurs',
  wastedThreshold: 'Seuil de déchets',
  howHelpful: 'À quel point cette astuce vous a-t-elle été utile ?',
  whyNotHelpful: 'Pourquoi n\'a-t-elle pas été utile ?',
};
