export default {
  originalDates: 'Pierwotne daty',
  originalDuration: 'Pierwotny czas trwania',
  newDates: 'Nowe daty',
  newDuration: 'Nowy czas trwania',
  firstDowntime: '1. Przestój',
  secondDowntime: '2. Przestój',
  split: 'Podziel',
  thisMachine: 'ta maszyna',
  addComment: 'Dodaj komentarz',
  noComments: 'Brak komentarzy',

  downtimeSummary: 'Podsumowanie przestoju',
  step: 'Krok {step}',
  selectLineMachine: 'Wybierz linię/maszynę',
  selectState: 'Wybierz stan',
  selectProblem: 'Wybierz problem',
  specifyAReason: 'Podaj przyczynę',
  correctiveActionTaken: 'Podaj podjętą akcję korekcyjną',
  downtimeDuration: 'Czas trawania przestoju',
  vision: 'Wizja',
  visionRequestClip: 'Klip na żądanie',
  chooseACamera: 'Wybierz kamerę',
  startOfAVideo: 'Początek klipu',
  endOfAVideo: 'Koniec klipu',
  requestVideo: 'Zażądaj klipu',
  oeeLossType: 'Typ Straty OEE',
  lossType: 'Typ Straty',

  filterAny: 'Każdy',
  hasClips: 'Posiada klipy wideo',
  notHaveClips: 'Nie posiada klipów wideo',
  hasCorrectiveAction: 'Ma wybraną akcję korekcyjną',
  notHaveCorrectiveAction: 'Nie ma wybranej akcji korekcyjnej',
  hasReason: 'Ma wybrany powód',
  notHaveReason: 'Nie ma wybranego powodu',
  hasComments: 'Posiada komentarze',
  notHaveComments: 'Nie posiada komenatarzy',
  noClipsRequested: 'Brak pobranych klipów wideo',

  table: {
    downtime: "Przestój",
    details: "Szczegóły",
    time: "Czas",
    duration: "Czas trwania",
  },
}
