<template>
  <div class="row justify-content-center no-gutters">
    <div
      v-for="el in list"
      :key="el.key"
      class="col-6 col-sm-3 col-md-auto btn-container"
    >
      <div
        :class="el.key === selected ? 'active' : ''"
        class="radio-label w-100 d-flex justify-content-center align-items-center"
        @click="select(el)"
      >
        <i :class="el.icon" />
        <div class="pl-1 text-nowrap">
          {{ el.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: String,
  },
  computed: mapGetters(['screenWidth']),
  methods: {
    select(el) {
      if (el.openInNewTab) {
        window.open(el.route, '_blank');
        return;
      }
      this.$emit('selected', el);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .radio-label {
    text-transform: uppercase;
    font-weight: 500;
    padding: 4px 10px;
    font-size: 10px;
    border-radius: 10px;
    border: 2px solid rgba(100,100,100,0.1);
    cursor: pointer;

    &.active {
      color: $ilabo;
      text-shadow: 0 0 2px rgba(100, 100, 100, 0.2);
      box-shadow: 0 0 2px rgba(100,100,100,0.2);
      border-color: $ilabo;
    }

    i {
      font-size: 19px;
    }

    &:hover {
      box-shadow: 0 0 3px rgba(100,100,100,0.4);
    }
  }

  .btn-container {
    width: 160px;
    padding: 2px;
  }
</style>
