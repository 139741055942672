export default {
  'addDowntime': 'Adicionar tempo de inatividade',
  'newDowntime': 'Novo tempo de inatividade',
  'actionsAndReasons': 'Ações e motivos',
  'performActions': 'Executar as seguintes ações:',
  'secifyReasonIfKnown': 'Especificar motivo',
  'downtimelist': 'Motivo do tempo de inatividade',
  'annotations': 'Anotações',
  'categories': 'Tags',
  'clearRootCause': 'Apagar a causa raiz',
  'reason': 'Motivo',
  'reasons': 'Motivos',
  'rootCauseReason': 'Motivo raiz',
  'noReasons': 'Nenhum motivos definido',
  'noCorrectiveActions': 'Nenhuma ação corretiva definida',
  'noEvents': 'Nenhum evento definido',
  'noCameras': 'Nenhuma câmera definida',
  'removeReasonWorning': 'Aviso! Todas as ações corretivas associadas a este motivo serão removidas!',
  'addReason': 'Adicionar novo motivo',
  'selectEvent': 'Evento / Problema',
  'newCorrectiveAction': 'Nova ação corretiva',
  'correctiveAction': 'Ação corretiva',
  'correctiveActions': 'Ações corretivas',
  'callForService': 'Chamado para atendimento',
  'serviceHasBeenCalled': 'Foi solicitado atendimento',
  'specifyRootcause': 'Causa raiz',
  'lastDowntimes': 'Últimos tempos de inatividade',
  'startTime': 'Iniciado',
  'endTime': 'Finalizado',
  'externalReason': 'Motivo externo',
  'uncategorized': 'Sem categoria',
  'noActions': 'Nenhuma ação a ser executada',
  'timeSlot': 'Intervalo de tempo',
  'selectOtherMachineDowntime': 'Selecionar outro tempo de inatividade da máquina',
  'selectMachine': 'Selecionar máquina',
  'workEventFilter': 'Filtro de eventos',
  'deselectAll': 'Desmarcar todos',
  'connect': 'Conectar',
  'commonReason': 'Motivo comum',
  'commonProblem': 'Problema comum',
  'clearSelection': 'Apagar a seleção',
  'noReason': 'Nenhum motivo',
  'addOrCreateReason': 'Selecione o motivo relacionado',
  'lineState': 'Estado da linha',
  'selectProblemFirst': 'Selecione o problema primeiro',
  'setProblem': 'Definir problema',
  'setReason': 'Definir motivo',
  'setCorrectiveAction': 'Definir ação corretiva',
  'summary': 'Resumo',
  'deleteConfirmMessage': 'Confirme que deseja excluir os tempos de inatividade selecionados.',
  'changeDuration': 'Alterar duração',
  'downtimeUpdated': 'Tempo de inatividade atualizado',
  'noMachineDowntime': 'Não se aplica à máquinas',
  'setAction': 'Definir ação',
  'addAnotherCorrectiveAction': 'Adicionar outra ação corretiva',
  'addAnotherReason': 'Adicionar outro motivo',
  'newReason': 'Novo motivo',
  'noCorrectiveAction': 'Nenhuma ação corretiva tomada',
  'toEditSetCommonWorkEvent': 'Para editar o evento de trabalho comum definido',
  'differentCorrectiveActions': 'Diferentes ações corretivas',
  'differentReasons': 'Diferentes motivos',
  addLineDowntime: "Adicionar tempo de paralisação de linha",
  addMachineDowntime: "Adicionar tempo de paralisação de {nome}",
  noDetails: "Sem detalhes",
  noClipsCameraAvailable: "Sem clipes solicitados, câmera está disponível",
  clipsDownloaded: "Clipes solicitados",
  stopLine: "Parar linha",
  stopLineStep: "Parar linha - passo {passo}",
};
