const SettingsIndex = () => import('@/pages/settings/Index');
const WorkEvents = () => import('@/pages/settings/WorkEvents');

const SignalsAndProperties = () => import('@/components/settings/config/signalsAndProperties/Index');
const Functions = () => import('@/components/settings/config/Functions');
const HintRules = () => import('@/components/settings/config/HintRules');
const SignalsImporter = () => import('@/components/settings/config/import/ImportSignal');
const WorkEventsImporter = () => import('@/components/settings/config/import/ImportWorkEvent');

const Electricity = () => import('@/components/settings/config/Electricity');
const WorkStates = () => import('@/components/settings/config/WorkStates');
const Units = () => import('@/components/settings/config/Units');
const Layout = () => import('@/components/settings/config/Layout');
const ExpectationsSettings = () => import('@/pages/settings/ExpectationsSettings');
const WorkingHoursSettings = () => import('@/pages/settings/WorkingHoursSettings');
const CamerasSettings = () => import('@/pages/settings/CamerasSettings');
const EdgeDeviceIndex = () => import('@/pages/settings/edgeDevice/EdgeDeviceIndex');
const EdgeDeviceSettings = () => import('@/pages/settings/edgeDevice/EdgeDeviceSettings');
const EdgeDeviceTags = () => import('@/pages/settings/edgeDevice/EdgeDeviceTags');
const EdgeDeviceTagHistory = () => import('@/pages/settings/edgeDevice/EdgeDeviceTagHistory');
const PlantSettings = () => import('@/components/settings/admin/PlantSettings');
const GraphEditor = () => import('@/components/settings/config/GraphEditor');
const OrderSettings = () => import('@/components/settings/config/OrderSettings');
const NotificationsSettings = () => import('@/pages/settings/NotificationsSettings');
const SetupIssues = () => import('@/components/settings/SetupIssues');
const PlantDataValidation = () => import('@/components/settings/admin/PlantDataValidation');
const ImportTemplates = () => import('@/components/settings/import/ImportTemplates');
const ImportTemplate = () => import('@/components/settings/import/ImportTemplate');

export default {
  path: 'settings',
  component: SettingsIndex,
  name: 'settings',
  props: true,
  children: [
    {
      path: '',
      component: SetupIssues,
      props: true,
    },
    {
      path: 'setupIssues',
      component: SetupIssues,
      props: true,
    },
    {
      path: 'shifts',
      component: WorkingHoursSettings,
      props: true,
    },
    {
      path: 'cameras',
      component: CamerasSettings,
      props: true,
    },
    {
      path: 'edge-devices',
      props: true,
      component: EdgeDeviceIndex,
      children: [
        {
          name: 'edge-device-settings',
          path: 'settings',
          component: EdgeDeviceSettings,
        },
        {
          name: 'tags',
          path: 'tags',
          component: EdgeDeviceTags,
        },
        {
          name: 'tag-history',
          path: 'tag-history',
          component: EdgeDeviceTagHistory,
        },
        {
          path: '',
          redirect: 'settings',
        },
      ],
    },
    {
      path: 'expectations',
      component: ExpectationsSettings,
    },
    {
      path: 'admin/plantsettings',
      component: PlantSettings,
      props: true,
    },
    {
      path: 'admin/data-validation',
      component: PlantDataValidation,
      props: true,
    },
    {
      path: 'import-templates',
      component: ImportTemplates,
      props: true,
    },
    {
      path: 'import-templates/template/:templateId?',
      component: ImportTemplate,
      props: true,
    },
    {
      path: 'config/order',
      component: OrderSettings,
      props: true,
    },
    {
      path: 'config/events',
      name: 'config/events',
      component: WorkEvents,
      props: true,
    },
    {
      path: 'config/events/:elementId/import',
      component: WorkEventsImporter,
    },
    {
      path: 'config/signals',
      component: SignalsAndProperties,
      props: true,
    },
    {
      path: 'config/functions',
      component: Functions,
      props: true,
    },
    {
      path: 'config/hints',
      component: HintRules,
      props: true,
    },
    {
      path: 'config/signals/:elementId/import',
      component: SignalsImporter,
    },
    {
      path: 'config/states',
      component: WorkStates,
      props: true,
    },
    {
      path: 'config/units',
      component: Units,
    },
    {
      path: 'config/electricity',
      component: Electricity,
      props: true,
    },
    {
      path: 'config/layout',
      component: Layout,
      props: true,
    },
    {
      path: 'config/connections/:graphId?',
      component: GraphEditor,
      props: true,
    },
    {
      path: 'subscriptions',
      component: NotificationsSettings,
    },
  ],
};
