export const elementType = {
  plant: 'Plant',
  machine: 'Machine',
  segment: 'Segment',
  area: 'Area',
  line: 'Line',
};

export const systemTags = {
  downtime: 'downtime',
  forced: 'forced',
  planned: 'planned',
  videoTrigger: 'videotrigger',
};

export const states = {
  holdup: 'holdup',
  failure: 'failure',
  idle: 'idle',
  stop: 'stop',
  lack: 'lack',
};

export const systemStates = {
  nolivedata: 'No live data',
  work: 'Work',
};

export const workEventTypes = {
  availabilityLoss: 'AvailabilityLoss',
  performanceLoss: 'PerformanceLoss',
  utilizationLoss: 'UtilizationLoss',
  qualityLoss: 'QualityLoss',
};

export const plantProperty = {
  dayStart: 'DAYSTART',
  energy: 'ENERGY',
  cardLogin: 'CARD_LOGIN',
  getWarnings: 'GET_WARNINGS',
  layout: 'LAYOUT',
  customReports: 'CUSTOM_REPORTS',
  elementsOrder: 'ELEMENTS_ORDER',
  vision: 'VISION',
  tenantId: 'TENANTID',
  useValidatedSpeed: 'USE_VALIDATED_SPEED',
  showLogixGateExport: 'SHOW_LOGIXGATE_EXPORT',
  cockpitType: 'COCKPIT_TYPE',
  cockpitHideMachines: 'COCKPIT_HIDE_MACHINES',
  changeoversEnabled: 'CHANGEOVERS_ENABLED',
  labourEnabled: 'LABOUR_ENABLED',
  manualWayOfWorkingEnabled: 'MANUAL_WAY_OF_WORKING_ENABLED',
  hideDowntimesButton: 'HIDE_DOWNTIMES_BUTTON',
  newAndonScreen: 'NEW_ANDON_SCREEN',
};

export const machineProperty = {
  upcomingRootCauseOffset: 'UpcomingRootCauseOffset',
  bufferBeforeMachine: 'BufferBeforeMachine',
  maxPerformance: 'MaxPerformance',
  energyBaseline: 'energy_baseline',
  energyMax: 'energy_max',
  isDummy: 'IsDummy',
};

export const lineProperty = {
  workBoostGroupId: 'WorkBoostGroupId',
  manualWayOfWorkingEnabled: 'MANUAL_WAY_OF_WORKING_ENABLED',
};

export const elementProperty = {
  minorStopThreshold: 'MinorStopThreshold',
};

export const valueType = {
  int: 'INT',
  decimal: 'DECIMAL',
  string: 'STRING',
  bool: 'BOOL',
};

export const orderStatus = {
  inProgress: 'inprogress',
  complete: 'complete',
  ready: 'ready',
  activated: 'activated',
  onhold: 'onhold',
};

export const notificationTypes = {
  hint: 2,
  notification: 1,
  warning: 4,
};

export const activityType = {
  order: 'Order',
  cleaning: 'Cleaning',
  changeover: 'Changeover',
};

export const activityStatus = {
  draft: 'Draft',
  released: 'Released',
  activated: 'Activated',
  started: 'Started',
  completed: 'Completed',
  suspended: 'Suspended',
  unknown: 'Unknown',
};

export const downloadStatus = {
  success: 'Success',
  inProgress: 'InProgress',
  failedOnEdge: 'FailedOnEdge',
  noArchiveRecordings: 'NoArchiveRecordings',
  deleted: 'Deleted',
};
export const productionHistoryType = {
  orderStarted: 'order-started',
  orderManualProductionHistoryAdded: 'order-manual-production-history-added',
  orderCompleted: 'order-completed',
};

export const ruleType = {
  event: 'event',
  csharpEvent: 'csharp-event',
  csharpEventTimed: 'csharp-event-timed',
};

export const manualResourceType = {
  waste: 'waste',
  production: 'production',
  humanResources: 'human-resources',
};

export const activityValidationErrorCodes = {
  productIdErrorCode: 84031,
  productLineNoConfigurationCode: 23526,
  flowIdErrorCode: 96189,
  lineErrorCode: 32890,
  orderedQuantityErrorCode: 73614,
  orderedQuantityUnitErrorCode: 94041,
  invalidQuantityUnitErrorCode: 33211,
  missingScheduledExecutionErrorCode: 61415,
  missingScheduledExecutionEndErrorCode: 10139,
};

export const radioFilterType = {
  with: 'with',
  without: 'without',
  all: 'all',
};

export default {
  systemTags,
  plantProperty,
  orderStatus,
  machineProperty,
  downloadStatus,
  manualResourceType,
  activityValidationErrorCodes,
  radioFilterType,
};
