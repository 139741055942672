import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getAvailableTags',
    path: 'v2/tags/observed',
  })
  .get({
    action: 'getCurrentTagValues',
    path: 'v2/tags/current',
  })
  .post({
    action: 'getTagHistory',
    path: 'v2/tags/history',
  })
  .getStore();

export default store;
