<template>
  <div class="activity-wrapper">
    <div>
      <h3
        v-if="edited"
        class="title mb-4"
      >
        <i class="fas fa-pen pr-2" />
        {{ $t('activities.edit') }}
      </h3>
      <h3
        v-else
        class="title mb-4"
      >
        <i class="fas fa-plus pr-2" />
        {{ $t('activities.add') }}
      </h3>
    </div>
    <div class="activity-container">
      <div>
        <button
          type="button"
          class="close modal-close"
          @click="back"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="row h-100 main-row">
        <div class="col-3 h-100">
          <div class="pb-4">
            <button
              class="btn btn-secondary icon-btn"
              @click="back"
            >
              <i class="fas fa-chevron-left" />
            </button>
          </div>
          <div>
            <FormNavigation
              class="pt-4"
              :breadcrumb="type !== activityType.order ? filteredPages : pages"
              :type="type"
              :activity="activityToSave"
              :selected="selectedPage"
              :activity-already-exists="activityAlreadyExists"
              :loaded-activity-already-exists="loadedActivityAlreadyExists"
              :general-error="generalError"
              :config-error="configError"
              :quantity-error="quantityError"
              :dates-error="datesError"
              @selectedPage="selectedPage = $event"
            />
          </div>
        </div>
        <div
          class="col-9 h-100"
        >
          <div class="h-100 d-flex flex-column">
            <div>
              <div class="secondary-text-style">
                {{ $t('activities.step') }} {{ selectedPage.index }}
              </div>
              <h3>
                {{ selectedPage.title }}
              </h3>
            </div>
            <hr>
            <div>
              <GeneralPage
                v-if="selectedPage.key === 'general'"
                ref="generalPageRef"
                :activity="activityToSave"
                :type="type"
                :edited="edited"
                :disabled="isDisabled"
                :activity-already-exists="activityAlreadyExists"
                :loaded-activity-already-exists="loadedActivityAlreadyExists"
                @selectedType="type = $event"
                @lineId="activityToSave.lineId = $event"
                @externalId="activityToSave.externalId = $event"
                @description="activityToSave.description = $event"
                @additionalFields="activityToSave.additionalFields = $event"
                @solve-errors="addIgnoredValidationErrors"
              />
              <ConfigPage
                v-if="selectedPage.key === 'config'"
                ref="configPageRef"
                :activity="activityToSave"
                :type="type"
                :edited="edited"
                :disabled="isDisabled"
                :validation-errors="validationErrors"
                @skuId="activityToSave.productId = $event"
                @skuDescription="setOrderDescription($event)"
                @flowId="activityToSave.flowId = $event"
                @manhours="activityToSave.expectedEffort.manhours = $event"
                @errorCheck="errorCheck = $event"
                @solve-errors="addIgnoredValidationErrors"
              />
              <QuantityPage
                v-if="selectedPage.key === 'quantity'"
                ref="quantityPageRef"
                :activity="activityToSave"
                :edited="edited"
                :disabled="isDisabled"
                @orderedValue="activityToSave.orderedQuantity.value = $event"
                @unit="activityToSave.orderedQuantity.unit = $event"
                @errorCheck="errorCheck = $event"
                @solve-errors="addIgnoredValidationErrors"
              />
              <DatesPage
                v-if="selectedPage.key === 'dates'"
                ref="datesPageRef"
                :activity="activityToSave"
                :changeover="changeover"
                :add-changeover="addChangeover"
                :type="type"
                :edited="edited"
                :disabled="isDisabled"
                @begin="activityToSave.plannedExecution.begin = $event"
                @end="activityToSave.plannedExecution.end = $event"
                @duration="changeover.duration = $event"
                @changeoverEffort="activityToSave.changeoverExpectedEffort.manhours = $event"
                @add="addChangeover = $event"
                @errorCheck="errorCheck = $event"
                @solve-errors="addIgnoredValidationErrors"
              />
            </div>

            <div class="mt-auto">
              <Error
                v-if="error"
                :message="error.detail"
              />
              <hr>

              <div class="mb-1">
                <ValidationErrors
                  v-if="validationErrors.length"
                  :validation-errors="validationErrors"
                />
              </div>

              <button
                v-if="selectedPage.key !== 'general'"
                class="buttons btn btn-secondary px-4 mr-4 mt-2"
                @click="goToRightPage('back')"
              >
                {{ $t('general.back') }}
              </button>

              <button
                v-if="selectedPage.key === 'general' && lastExistingActivityId && !edited"
                class="buttons btn btn-primary px-4 mr-4 mt-2"
                @click="getFormData(); edited = true"
              >
                {{ $t('activities.editExisting') }}
              </button>

              <button
                v-else-if="selectedPage.key !== 'dates'"
                class="buttons btn btn-primary px-4 mr-4 mt-2"
                :disabled="
                  !errorCheck || selectedPage.key === 'general' && !activityToSave.externalId
                    || !loadedActivityAlreadyExists || activityAlreadyExists ||
                    selectedPage.key === 'config' && type === activityType.order
                    && (!activityToSave.productId) ||
                    selectedPage.key === 'quantity' && (!activityToSave.orderedQuantity.value
                      || !activityToSave.orderedQuantity.unit)
                "
                @click="goToRightPage('next')"
              >
                {{ $t('general.next') }}
              </button>

              <button
                v-if="selectedPage.key === 'dates'"
                class="buttons btn btn-primary px-4 mr-4 mt-2 d-inline-flex align-items-center justify-content-center"
                :disabled="!errorCheck
                  || !activityToSave.plannedExecution.begin
                  || pending
                  || (type === 'Cleaning' && !activityToSave.plannedExecution.end)"
                @click="save"
              >
                <span v-if="!pending">
                  {{ $t('general.save') }}
                </span>
                <BSpinner
                  v-if="pending"
                  variant="light"
                  small
                  class="ml-3"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { activityStatus, activityType, activityValidationErrorCodes } from '@/utils/dictionary';
import moment from 'moment';
import activityForm from '@/mixins/activityForm';
import FormNavigation from './FormNavigation';
import GeneralPage from './GeneralPage';
import ConfigPage from './ConfigPage';
import DatesPage from './DatesPage';
import QuantityPage from './QuantityPage';

export default {
  props: {
    line: String,
    activity: Object,
    isEdited: Boolean,
    fixedLineId: String,
    isEditBlocked: Boolean,
    importResult: {
      type: Object,
      required: false,
    },
    showEditCorrelated: Boolean,
  },
  data() {
    return {
      activityType,
      selectedPage: null,
      errorCheck: true,
      addChangeover: null,
      error: null,
      validationErrors: [],
      ignoredValidationErrors: [],
      changeover: {},
      lastExistingActivityId: null,
      type: null,
      pending: false,
      edited: false,
      activityToSave: {
        plannedActivation: {},
        plannedExecution: {
          begin: null,
          end: null,
        },
        expectedEffort: {},
        changeoverExpectedEffort: {},
        orderedQuantity: {
          value: null,
          unit: null,
        },
        externalId: null,
        lineId: null,
        productId: null,
        flowId: null,
        orderWithChangeover: false,
      },
      activityAlreadyExists: false,
      activityAlreadyExistsTimeout: null,
      loadedActivityAlreadyExists: true,
    };
  },
  components: {
    FormNavigation,
    GeneralPage,
    ConfigPage,
    DatesPage,
    QuantityPage,
  },
  mixins: [activityForm()],
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('plant', ['displayChangeovers', 'displayLabour']),
    showConfigPage() {
      return this.type !== activityType.cleaning || this.displayLabour;
    },
    pages() {
      let nextIndex = 1;
      const pages = [
        {
          key: 'general',
          title: this.$t('activities.general'),
          index: nextIndex,
        },
      ];
      if (this.showConfigPage) {
        nextIndex += 1;
        pages.push({
          key: 'config',
          title: this.$t('activities.configuration'),
          index: nextIndex,
        });
      }
      if (this.type !== activityType.cleaning) {
        nextIndex += 1;
        pages.push({
          key: 'quantity',
          title: this.$t('activities.quantity'),
          index: nextIndex,
        });
      }
      nextIndex += 1;
      pages.push({
        key: 'dates',
        title: this.$t('activities.dates'),
        index: nextIndex,
      });
      return pages;
    },
    filteredPages() {
      if (this.type !== activityType.order) {
        return this.pages
          .filter(x => x.key !== 'quantity')
          .map((x, i) => ({ ...x, index: i + 1 }));
      }
      return this.pages;
    },
    activityExternalId() {
      return this.activityToSave.externalId;
    },
    generalError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return false;

      return !!this.validationErrors
        .filter(e => !this.ignoredValidationErrors.includes(e.code))
        .find(e => [
          activityValidationErrorCodes.lineErrorCode,
        ].includes(e.code));
    },
    configError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return false;

      return !!this.validationErrors
        .filter(e => !this.ignoredValidationErrors.includes(e.code))
        .find(e => [
          activityValidationErrorCodes.productIdErrorCode,
          activityValidationErrorCodes.productLineNoConfigurationCode,
          activityValidationErrorCodes.flowIdErrorCode,
        ].includes(e.code));
    },
    quantityError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return false;

      return !!this.validationErrors
        .filter(e => !this.ignoredValidationErrors.includes(e.code))
        .find(e => [
          activityValidationErrorCodes.orderedQuantityErrorCode,
          activityValidationErrorCodes.orderedQuantityErrorCode,
          activityValidationErrorCodes.invalidQuantityUnitErrorCode,
        ].includes(e.code));
    },
    datesError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return false;

      return !!this.validationErrors
        .filter(e => !this.ignoredValidationErrors.includes(e.code))
        .find(e => [
          activityValidationErrorCodes.missingScheduledExecutionErrorCode,
          activityValidationErrorCodes.missingScheduledExecutionEndErrorCode,
        ].includes(e.code));
    },
  },
  watch: {
    type() {
      if (!this.activityExternalId
        || this.edited) return;

      this.activityAlreadyExists = false;
      this.loadedActivityAlreadyExists = false;
      if (this.activityAlreadyExistsTimeout) {
        clearTimeout(this.activityAlreadyExistsTimeout);
      }
      this.activityAlreadyExistsTimeout = setTimeout(() => {
        this.validateIfActivityExist();
      }, 500);
    },
    activityExternalId(v) {
      if (this.isEdited) return;

      this.activityAlreadyExists = false;
      if (v) {
        this.loadedActivityAlreadyExists = false;
        if (this.activityAlreadyExistsTimeout) {
          clearTimeout(this.activityAlreadyExistsTimeout);
        }
        this.activityAlreadyExistsTimeout = setTimeout(() => {
          this.validateIfActivityExist();
        }, 500);
      } else {
        this.loadedActivityAlreadyExists = true;
      }
    },
  },
  methods: {
    ...mapActions([
      'createOrUpdateOrder',
      'createCleaning',
      'createChangeover',
      'getOrder',
      'getOrderErrors',
      'getChangeoverErrors',
      'getChangeover',
      'getCleaning',
      'getActivities',
      'checkOrder',
      'checkCleaning',
    ]),
    ...mapActions('element', ['getElementsProperties']),
    setOrderDescription(val) {
      if (this.activityToSave.description) return;
      this.activityToSave.description = val;
    },
    addIgnoredValidationErrors(e) {
      this.ignoredValidationErrors = [...this.ignoredValidationErrors, ...e];
    },
    changeoverCheck() {
      if (this.activityToSave.correlationId) {
        this.getActivities({
          params: {
            query: {
              plantId: this.plantId,
              correlationIds: this.activityToSave.correlationId,
            },
          },
        }).then(({ data }) => {
          const correlated = data.filter(a => a.id !== this.activityToSave.id)[0];

          this.activityToSave.orderWithChangeover = correlated;
          this.changeover = correlated || {};

          const duration = correlated?.plannedExecution
            ? ((
              moment(correlated?.plannedExecution?.end).unix()
              - moment(correlated?.plannedExecution?.begin).unix()
            ) / 60).toString()
            : null;
          if (duration) {
            this.activityToSave.orderWithChangeover.duration = duration;
            this.changeover.duration = duration;
          }
        })
          .catch(({ response: { data } }) => {
            this.activityToSave.orderWithChangeover = false;
            this.changeover = {};
            this.error = data;
          });
      }
    },
    setPlannedActiviation() {
      const executionBegin = this.activityToSave.plannedExecution.begin;

      if (!this.displayChangeovers || !executionBegin) {
        return;
      }

      this.activityToSave.plannedActivation.end = moment(executionBegin)
        .toISOString();

      this.activityToSave.plannedActivation.begin = moment(executionBegin)
        .subtract(parseInt(this.changeover.duration.replace(' ', ''), 10), 'minutes')
        .toISOString();
    },
    getFormData() {
      if (this.importResult) {
        this.setFormData(this.importResult);
        if (this.importResult.plannedActivation) {
          const activationBegin = moment(this.activityToSave.plannedActivation.begin);
          const activationEnd = moment(this.activityToSave.plannedActivation.end);
          this.changeover.duration = moment.duration(activationEnd.diff(activationBegin)).asMinutes().toString();
        } else {
          this.activityToSave.plannedActivation = {};
        }
        this.activityToSave.changeoverExpectedEffort ||= {};
        return;
      }
      if (!this.activityToSave?.externalId) {
        return;
      }
      this.error = null;
      this.pending = true;

      switch (this.type) {
        case activityType.changeover:
          this.getInitialChangeoverData();
          break;
        case activityType.cleaning:
          this.getInitialCleaningData();
          break;
        case activityType.order:
        default:
          this.getInitialOrderData();
          break;
      }
    },
    getInitialOrderData() {
      this.getOrder({
        params: {
          orderId: encodeURIComponent(this.activityToSave?.externalId),
          query: { plantId: this.plantId },
        },
      })
        .then(({ data }) => {
          this.setFormData(data);
          if (data.status === activityStatus.draft && data.validationErrorCount) {
            this.getValidationErrors();
          }
          if (!this.activityToSave.plannedActivation) {
            this.activityToSave.plannedActivation = {};
          }
          if (!this.activityToSave.changeoverExpectedEffort) {
            this.activityToSave.changeoverExpectedEffort = {};
          }
          this.changeoverCheck();
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        }).finally(() => {
          this.pending = false;
        });
    },
    getInitialChangeoverData() {
      this.getChangeover({
        params: {
          changeoverId: this.activityToSave?.externalId,
          query: { plantId: this.plantId },
        },
      })
        .then(({ data }) => {
          this.setFormData(data);
          if (data.status === activityStatus.draft && data.validationErrorCount) {
            this.getValidationErrors();
          }
          if (!this.activityToSave.plannedActivation) {
            this.activityToSave.plannedActivation = {};
          }
          if (!this.activityToSave.changeoverExpectedEffort) {
            this.activityToSave.changeoverExpectedEffort = {};
          }
          this.changeoverCheck();
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        }).finally(() => {
          this.pending = false;
        });
    },
    getInitialCleaningData() {
      this.getCleaning({
        params: {
          cleaningId: this.activityToSave?.externalId,
          query: { plantId: this.plantId },
        },
      })
        .then(({ data }) => {
          this.setFormData(data);
          if (data.status === activityStatus.draft && data.validationErrorCount) {
            this.getValidationErrors();
          }
          if (!this.activityToSave.plannedActivation) {
            this.activityToSave.plannedActivation = {};
          }
          if (!this.activityToSave.changeoverExpectedEffort) {
            this.activityToSave.changeoverExpectedEffort = {};
          }
          this.changeoverCheck();
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        }).finally(() => {
          this.pending = false;
        });
    },
    getValidationErrors() {
      if (this.type === activityType.changeover) {
        this.getChangeoverErrors({
          params: {
            changeoverId: this.activityToSave?.id,
            query: { plantId: this.plantId },
          },
        }).then(({ data }) => {
          this.validationErrors = data;
        });
      } else {
        this.getOrderErrors({
          params: {
            orderId: this.activityToSave?.id,
            query: { plantId: this.plantId },
          },
        }).then(({ data }) => {
          this.validationErrors = data;
        });
      }
    },
    save() {
      if (this.type === activityType.order) {
        this.saveOrder();
      } else if (this.type === activityType.cleaning) {
        this.saveCleaning();
      } else {
        this.saveChangeover();
      }
    },
    saveCleaning() {
      this.error = null;
      this.pending = true;

      this.createCleaning({
        data: {
          ...this.activityToSave,
          plannedExecution: {
            begin: moment(this.activityToSave.plannedExecution.begin).toISOString(),
            end: this.activityToSave.plannedExecution.end
              ? moment(this.activityToSave.plannedExecution.end).toISOString() : null,
          },
          plantId: this.plantId,
          expectedEffort: this.activityToSave?.expectedEffort?.manhours
            ? this.activityToSave.expectedEffort : null,
        },
      })
        .then(({ data }) => {
          this.$emit('saved', data);
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        }).finally(() => {
          this.pending = false;
        });
    },
    saveChangeover() {
      this.error = null;
      this.pending = true;

      this.createChangeover({
        data: {
          ...this.activityToSave,
          plannedExecution: {
            begin: moment(this.activityToSave.plannedExecution.begin).toISOString(),
            end: this.activityToSave.plannedExecution.end
              ? moment(this.activityToSave.plannedExecution.end).toISOString() : null,
          },
          plantId: this.plantId,
          expectedEffort: this.activityToSave?.expectedEffort?.manhours
            ? this.activityToSave.expectedEffort : null,
        },
      })
        .then(({ data }) => {
          this.$emit('saved', data);
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        }).finally(() => {
          this.pending = false;
        });
    },
    saveOrder() {
      this.error = null;
      this.pending = true;

      if (this.displayChangeovers && this.changeover.duration) {
        this.setPlannedActiviation();
      }

      const canEditPlannedExecution = ![
        activityStatus.activated,
        activityStatus.started,
        activityStatus.completed,
      ].includes(this?.activity?.status || activityStatus.draft);

      this.createOrUpdateOrder({
        data: {
          ...this.activityToSave,
          plannedExecution: canEditPlannedExecution ? {
            begin: moment(this.activityToSave.plannedExecution.begin).toISOString(),
            end: this.activityToSave.plannedExecution.end
              ? moment(this.activityToSave.plannedExecution.end).toISOString() : null,
          } : this.activity.plannedExecution,
          plannedActivation: this.displayChangeovers && this.activityToSave.plannedActivation?.begin
            ? this.activityToSave.plannedActivation
            : {},
          orderedQuantity: {
            value: parseInt(this.activityToSave.orderedQuantity.value, 10),
            unit: this.activityToSave.orderedQuantity.unit.replace(' ', ''),
          },
          plantId: this.plantId,
          expectedEffort: this.activityToSave?.expectedEffort?.manhours
            ? this.activityToSave.expectedEffort : null,
          changeoverExpectedEffort: this.displayChangeovers && this.activityToSave?.changeoverExpectedEffort?.manhours
            ? this.activityToSave.changeoverExpectedEffort : null,
          kind: this.displayChangeovers && this.changeover.changeoverType
            ? this.changeover.changeoverType : null,
        },
      })
        .then(({ data }) => {
          this.$emit('saved', data);
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        }).finally(() => {
          this.pending = false;
        });
    },
    back() {
      this.$emit('close', this.activity ? this.activity.status : activityStatus.draft);
    },
    goToRightPage(goTo) {
      if (!this.loadedActivityAlreadyExists || this.activityAlreadyExists) return;

      const value = goTo === 'next' ? 1 : -1;
      const currentIndex = this.filteredPages.find(x => x.key === this.selectedPage.key).index;
      this.selectedPage = this.filteredPages.find(x => x.index === currentIndex + value);
    },
    validateIfActivityExist() {
      this.loadedActivityAlreadyExists = false;
      if (!this.edited) {
        if (this.type === activityType.order) {
          this.checkOrder({
            params: {
              externalId: encodeURIComponent(this.activityExternalId),
              query: {
                plantId: this.plantId,
              },
            },
          })
            .then(() => {
              this.activityAlreadyExists = true;
            }).finally(() => {
              this.loadedActivityAlreadyExists = true;
            });
        } else {
          this.checkCleaning({
            params: {
              externalId: encodeURIComponent(this.activityExternalId),
              query: {
                plantId: this.plantId,
              },
            },
          })
            .then(() => {
              this.activityAlreadyExists = true;
            }).finally(() => {
              this.loadedActivityAlreadyExists = true;
            });
        }
      }
    },
  },
  mounted() {
    this.getFormData();
  },
  created() {
    this.selectedPage = this.filteredPages.find(x => x.key === 'general');
    this.edited = this.isEdited;
    this.activityToSave.lineId = this.line;
    if (this.activity) {
      this.activityToSave = this.activity;
      this.activityToSave.changeoverExpectedEffort ??= {};
      this.type = this.activity?.type;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.activity-wrapper {
  background-color: #f4f4f4;
  padding-top: 25px;
  padding-bottom: 75px;
  padding-inline: 300px;
  min-height: 100%;

  .main-row {
    @media screen and (max-width: 900px) {
      height: 85vh !important;
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 900px) {
    padding-inline: 5%;
    margin: -1rem;
    min-height: calc(100% + 2rem);
    padding-bottom: 0;
  }
}

.activity-save-button {
  text-align: right !important;
  padding-top: 0 !important;
}

.activity-container {
  border-radius: 15px;
  background-color: white;
  padding-block: 30px;
  padding-inline: 25px;
  @media screen and (max-width: 900px) {
    min-height: 90vh;
  }
}

.close.modal-close {
  top: 5px;
  right: 15px;
  z-index: 100;
  padding: 0 5px;
  font-size: 35px;
}

.buttons {
  width: 175px;
}

.secondary-text-style {
  opacity: 0.3;
  font-weight: 600;
  font-size: 1.1em;
}
</style>
