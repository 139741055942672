<template>
  <div
    class="text-left"
    style="font-size: 0.79rem"
  >
    <table>
      <tbody>
        <tr>
          <div class="font-weight-bold">
            {{ formatDate(startDate) }} - {{ formatDate(Math.min($now, endDate)) }}
          </div>
        </tr>
        <tr class="font-weight-bold">
          <td class="pr-2">
            {{ $t('reports.oeeLoss.time.totalTime') }}:
          </td>
          <td>
            {{ round(totalDuration / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr>
          <td class="text-righl pr-2">
            {{ $t('reports.oeeLoss.utilizationLosses') }}:
          </td>
          <td>
            {{ round(utilizationLoss / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr class="font-weight-bold">
          <td class="pr-2">
            {{ $t('reports.oeeLoss.time.scheduledTime') }}:
          </td>
          <td>
            {{ round((totalDuration - utilizationLoss) / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr>
          <td class="text-righl pr-2">
            {{ $t('reports.oeeLoss.availabilityLosses') }}:
          </td>
          <td>
            {{ round(availabilityLoss / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr class="font-weight-bold">
          <td class="pr-2">
            {{ $t('reports.oeeLoss.time.productionTime') }}:
          </td>
          <td>
            {{ round((productionTime) / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr>
          <td class="text-righl pr-2">
            {{ $t('reports.oeeLoss.performanceLosses') }}:
          </td>
          <td>
            {{ round(performanceLoss / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr class="font-weight-bold">
          <td class="text-righl pr-2">
            {{ $t('reports.oeeLoss.time.effectiveTime') }}:
          </td>
          <td>
            {{ round(effectiveTime / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr
          style="border-top: 1px solid #eee"
        >
          <td class="text-righl pr-2">
            {{ $t('work.workTime') }}:
          </td>
          <td>
            {{ round(workDuration / 60) }} {{ $t('time.minutes') }}
          </td>
        </tr>
        <tr>
          <td class="text-righl pr-2">
            {{ $t('monitoring.goodCount') }}:
          </td>
          <td>
            {{ production }} {{ productionUnit }}
          </td>
        </tr>
        <tr>
          <td class="text-righl pr-2">
            {{ $t('monitoring.wasteCount') }}:
          </td>
          <td>
            {{ waste }} {{ productionUnit }}
          </td>
        </tr>
        <tr>
          <td class="text-righl pr-2">
            {{ $t('monitoring.averageTargetPerformance') }}:
          </td>
          <td>
            {{ avgPerf }} {{ productionUnit || '?' }}/h
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import calcDuration from '@/utils/calcDuration';

export default {
  props: {
    performanceLoss: Number,
    availabilityLoss: Number,
    utilizationLoss: Number,
    effectiveTime: Number,
    expectedProduction: Number,
    productionUnit: String,
    startDate: Number,
    endDate: Number,
    scheduledTime: Number,
    waste: Number,
    production: Number,
    workDuration: Number,
    totalDuration: Number,
    productionTime: Number,
  },
  computed: {
    avgPerf() {
      if (!this.scheduledTime) {
        return 0;
      }
      return Math.floor((this.expectedProduction / this.scheduledTime) * 3600);
    },
  },
  methods: {
    calcDuration,
    round(v) {
      return Math.round(v || 0);
    },
    formatDate(d) {
      return moment.unix(d).format('lll');
    },
  },
};
</script>

<style>

</style>
