import axios from 'axios';
import { paramsSerializer, setHeaders } from '@core/api/axiosSetup';

export const packos = axios.create({ paramsSerializer });
export const workBoost = axios.create({ paramsSerializer });
export const auth = axios.create({ paramsSerializer });

export const init = store => {
  setHeaders(packos, store);
  setHeaders(workBoost, store);
  setHeaders(auth, store);
};

export default packos;
