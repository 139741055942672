import axios from '@/api/axios';
import { systemStates } from '@/utils/dictionary';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    plantEvents: {},
    deletedWorkEvents: {},
    plantWorkEventTaxonomies: {},
  },
})
  .post({
    action: 'addWorkEvent',
    path: ({ plantId }) => `/v2/plant/${plantId}/workEvent`,
    onSuccess: (s, { data }) => {
      Vue.set(s.plantEvents, data.id, {
        ...data,
        description: data.name,
      });
    },
  })
  .get({
    action: 'getWorkEventDetails',
    path: ({ plantId }) => `/v2/plant/${plantId}/workEvent/details`,
  })
  .put({
    action: 'updateWorkEventName',
    path: ({ plantId, eventId, name }) => `/v2/plant/${plantId}/workEvent/${eventId}/name/${name}`,
    onSuccess: (s, payload, _, { params }) => {
      Vue.set(s.plantEvents[params.eventId], 'description', decodeURIComponent(params.name));
    },
  })
  .put({
    action: 'updateWorkEventState',
    path: ({ plantId, eventId, stateId }) => `/v2/plant/${plantId}/workEvent/${eventId}/workState/${stateId}`,
    onSuccess: (s, payload, _, { params }) => {
      Vue.set(s.plantEvents[params.eventId], 'workStateId', params.stateId);
    },
  })
  .put({
    action: 'updateWorkEventDefault',
    path: ({ plantId, eventId, isDefault }) => `/v2/plant/${plantId}/workEvent/${eventId}/isDefault/${isDefault}`,
    onSuccess: (s, payload, _, { params }) => {
      Vue.set(s.plantEvents[params.eventId], 'isDefault', params.isDefault);
    },
  })
  .delete({
    action: 'deleteWorkEvent',
    path: ({ plantId, eventId }) => `/v2/plant/${plantId}/workEvent/${eventId}`,
    onSuccess: (s, payload, _, { params }) => {
      const ev = s.plantEvents[params.eventId];
      if (ev) {
        Vue.delete(s.plantEvents, ev.id);
      }
    },
  })
  /* WorkEvents importer */
  .post({
    action: 'importWorkEvents',
    path: ({ plantId, elementId }) => `/v3/plant/${plantId}/element/${elementId}/workEvents/import`,
  })
  .get({
    action: 'getWorkEventsImport',
    path: ({
      plantId,
      elementId,
      importId,
    }) => `/v3/plant/${plantId}/element/${elementId}/workEvents/import/${importId}`,
  })
  .get({
    action: 'exportWorkEvents',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/workEvents/export`,
  })
  /* Manual work */
  .put({
    action: 'addDowntime',
    path: ({
      plantId, elementId, workEventId, startDate, endDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/workEvent/${workEventId}/${startDate}/${endDate}`,
  })
  .delete({
    action: 'deleteDowntime',
    path: ({
      plantId, elementId, startDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/workLog/${startDate}`,
  })
  .getStore();

const filterEvents = (eventsDict, elementId) =>
  Object.values(eventsDict).filter(e => e.elementId === elementId);

store.getters = {
  workEvents: s => elementId => {
    if (!elementId) return s.plantEvents;
    return filterEvents(s.plantEvents, elementId);
  },
  workEventTaxonomies: s => workEventId => {
    if (!workEventId) return s.plantWorkEventTaxonomies;
    return s.plantWorkEventTaxonomies.filter(t => t.workEventId === workEventId);
  },
  workEvent: s => id => {
    const plantWorkEvent = s.plantEvents ? s.plantEvents[id] : null;
    const deletedWorkEvent = s.deletedWorkEvents ? s.deletedWorkEvents[id] : null;
    return {
      ...(deletedWorkEvent || {}),
      ...(plantWorkEvent || {}),
      softDeleted: !plantWorkEvent,
    };
  },
  selectableWorkEvents: (s, g) => elementId => filterEvents(s.plantEvents, elementId)
    .filter(e => {
      const state = g.workState(e.workStateId);
      return state && !systemStates[state.name.toLowerCase()] && !e.isDeleted;
    }),
  workEventsReasons: s => Object.keys(s.plantEvents)
    .flatMap(we => s.plantEvents[we].reasons),
  workEventsCorrectiveActions: s => Object.keys(s.plantEvents)
    .flatMap(we => s.plantEvents[we].correctiveActions)
    .filter((v, i, m) => m.indexOf(v) === i),
  isDowntime: (s, g) => eventId => {
    const state = g.workStateOfEvent(eventId);
    if (!state) return false;
    return !systemStates[state.name.toLowerCase()];
  },
  isPlanned: (s, g, rs, rg) => eventId => {
    const event = g.workEvent(eventId);
    const state = g.workState(event?.workStateId);

    if (!state || !event) return false;
    const planned = rg['work/tags'].find(x => x.name.toLowerCase() === 'planned');

    return systemStates[state.name.toLowerCase()] || (planned && event?.tags?.some(y => y === planned.id));
  },
  getDefaultWorkStateEvent: (s, g) => (elementId, workStateName) => g.workEvents(elementId).find(e =>
    g.workState(e.workStateId).name === workStateName && e.isDefault),
};

store.mutations.setWorkEvents = (s, events) => {
  if (!events) return;
  s.plantEvents = {};
  events.forEach(e => {
    Vue.set(s.plantEvents, e.id, e);
  });
};

store.mutations.setWorkEventTaxonomies = (s, eventTaxonomies) => {
  if (!eventTaxonomies) return;
  s.plantWorkEventTaxonomies = {};
  eventTaxonomies.forEach(e => {
    Vue.set(s.plantWorkEventTaxonomies, e.id, e);
  });
};

store.mutations.setDeletedWorkEvents = (s, deletedWorkEvents) => {
  if (!deletedWorkEvents) return;
  s.deletedWorkEvents = {};
  deletedWorkEvents.forEach(e => {
    Vue.set(s.deletedWorkEvents, e.id, e);
  });
};

export default store;
