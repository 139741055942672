<template>
  <div>
    <div class="font-weight-bold mb-2">
      {{ $t('activities.resources.history') }}:
    </div>
    <div class="text-center">
      <Loader v-if="historyPending" />
    </div>
    <div
      v-for="(entryGroup, index) in grouppedEntries"
      :key="index"
      class="update-box d-flex align-items-center"
    >
      <div style="width: 150px">
        <div
          v-for="entry in entryGroup"
          :key="entry.id"
        >
          <div
            class="text-nowrap text-secondary"
            :class="entry.type === manualResourceType.waste ? 'text-danger' : ''"
          >
            <i
              :class="getIcon(entry)"
            />
            <span class="pl-2">
              {{ getIconTooltip(entry) }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="entryGroup[0].issuerName"
        class="flex-grow-1 small ml-2"
      >
        {{ entryGroup[0].issuerName }}
      </div>
      <div
        v-else
        class="flex-grow-1"
      />

      <div
        style="width: 120px"
      >
        <div
          v-for="entry in entryGroup"
          :key="entry.id"
        >
          <div
            class="row mx-2"
            :class="{ faded: !entry.isActive }"
          >
            <div
              class="col-auto"
            >
              <span
                v-if="entry.type === manualResourceType.waste"
                class="text-danger text-nowrap"
              >
                + {{ entry.value }} {{ entry.unit }}
              </span>
              <span
                v-else-if="entry.type === manualResourceType.production"
                class="text-nowrap"
              >
                + {{ entry.value }} {{ entry.unit }}
              </span>
              <span
                v-else-if="entry.type === manualResourceType.humanResources"
              >
                <Tippy
                  placement="left"
                  theme="light"
                  interactive
                  arrow
                >
                  <template #trigger>
                    <div style="cursor: default">
                      {{ minutesToDuration(entry.value) }}
                    </div>
                  </template>

                  <div class="pt-1">
                    <div
                      v-for="(m, i) in entry.manhours"
                      :key="i"
                      class="d-flex justify-content-between pb-1"
                      style="width: 200px"
                    >
                      <div>
                        <i class="fas fa-user pr-1" />
                        <span class="font-weight-bold">
                          {{ m.workerCode }}:
                        </span>
                      </div>
                      <div>
                        {{ minutesToDuration(m.minutes) }}
                      </div>
                    </div>
                  </div>
                </Tippy>
              </span>
              <span v-else />
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 320px"
      >
        {{ formatDate(entryGroup[0].from) }}
        <span v-if="entryGroup[0].to">- {{ formatDate(entryGroup[0].to) }}</span>
      </div>
      <div
        style="width: 55px"
        class="px-3"
      >
        <button
          v-if="deleteEnabled(entryGroup[0]) && !hideDelete"
          :id="`remove-manual-resource${entryGroup[0].id}`"
          :disabled="pending[entryGroup[0].id]"
          class="btn btn-primary btn-sm icon-btn right"
          type="button"
          @click="showRemove = true"
        >
          <slot>
            <i
              class="ion ion-md-trash action-icon"
            />
          </slot>
        </button>

        <RemovePopover
          v-if="deleteEnabled(entryGroup[0])"
          :target="`remove-manual-resource${entryGroup[0].id}`"
          :pending="pending[entryGroup[0].id]"
          placement="bottom"
          @remove="remove(entryGroup)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { manualResourceType } from '@/utils/dictionary';
import { minutesToDuration } from '@/utils/time';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    entries: {
      type: Array,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    historyPending: Boolean,
    resourceType: String,
    activityStart: String,
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    error: false,
    pending: {},
    showRemove: false,
    manualResourceType,
  }),
  computed: {
    ...mapGetters(['plantId']),
    grouppedEntries() {
      const grouping = this.entries
        .filter(x => x.isActive)
        .reduce((acc, curr) => {
          const groupingType = curr.type === manualResourceType.waste
            ? manualResourceType.production
            : curr.type;

          if (this.resourceType && this.resourceType !== groupingType) {
            return acc;
          }

          const key = `${groupingType}_${curr.to}`;
          if (acc[key]) {
            acc[key].push(curr);
          } else {
            acc[key] = [curr];
          }
          return acc;
        }, {});

      return Object.values(grouping)
        .sort((a, b) => moment(b[0].from).unix() - moment(a[0].from).unix())
        .concat([[{
          type: 'start',
          from: this.activityStart,
        }]]);
    },
  },
  methods: {
    ...mapActions([
      'removeManualResource',
      'removeOrderManualProduction',
    ]),
    minutesToDuration,
    formatDate(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    },
    deleteEnabled({ to, type }) {
      const max = this.entries
        .filter(x => x.type === type && x.isActive)
        .reduce((acc, curr) => Math.max(acc, moment(curr.to).unix()), 0);

      return max === moment(to).unix();
    },
    getIcon(entry) {
      switch (entry.type) {
        case 'start':
          return 'fas fa-play';
        case manualResourceType.production:
          return 'fas fa-thumbs-up';
        case manualResourceType.waste:
          return 'fas fa-thumbs-down';
        case manualResourceType.humanResources:
          return 'fas fa-male';
        default:
          return '';
      }
    },
    getIconTooltip(entry) {
      switch (entry.type) {
        case 'start':
          return this.$t('activities.activityStart');
        case manualResourceType.production:
          return this.$t('activities.resources.production');
        case manualResourceType.waste:
          return this.$t('activities.resources.waste');
        case manualResourceType.humanResources:
          return this.$t('activities.resources.manhours');
        default:
          return '';
      }
    },
    getProductionClass(entry) {
      if (!entry.isActive) {
        return 'col-2 text-right strikethrough';
      }
      return 'col-2 text-right';
    },
    async remove(entries) {
      const entry = entries[0];
      this.$set(this.pending, entry.id, true);
      try {
        if (entry.type === manualResourceType.production || entry.type === manualResourceType.waste) {
          await this.removeOrderManualProduction({
            params: {
              plantId: this.plantId,
              orderId: this.activityId,
              query: {
                resourceType: entry.type,
                lineId: entry.lineId,
                to: moment(entry.to).toISOString(),
                activityBeginDate: moment(entry.activityStart).toISOString(),
              },
            },
          });
        } else {
          await this.removeManualResource({
            params: {
              plantId: this.plantId,
              activityId: this.activityId,
              query: {
                resourceType: entry.type,
                lineId: entry.lineId,
                to: moment(entry.to).toISOString(),
                activityBeginDate: moment(entry.activityStart).toISOString(),
              },
            },
          });
        }

        entries.forEach(x => this.$emit('removed', x));
      } catch (e) {
        this.error = e;
      } finally {
        this.$set(this.pending, entry.id, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.update-box {
  border-top: 1px solid #eee;
  padding-top: 5px;
  padding-bottom: 5px;

  &.hoverable {
    transition: box-shadow 300ms, transform 300ms;
    cursor: pointer;

    &:hover {
      transform: translateX(-5px);
      box-shadow: 0 0 5px rgba(150, 150, 150, 0.3),
      0 0 25px rgba(150, 150, 150, 0.3);
    }
  }

  &:hover {
    transform: translateX(0px);
  }
}
.fa.fa-times {
  margin-top: 5px;
}

.fa.fa-play {
  margin-top: 4px;
}
.col-1 {
  margin-bottom: -2px;
}
.faded {
  color:#777777;
}

.right {
  float: right;
}

.strikethrough {
  text-decoration: line-through;
}

.action-icon {
  color: white;
}
</style>
