export default {
  addDowntime: 'Dodaj przestój',
  addLineDowntime: 'Dodaj przestój linii',
  addMachineDowntime: 'Dodaj przestój dla {name}',
  newDowntime: 'Nowy przestój',
  actionsAndReasons: 'Akcje i Przyczyny',
  performActions: 'Podejmij następujące działania:',
  secifyReasonIfKnown: 'Określ przyczynę',
  downtimelist: 'Przyczyny przestoju',
  annotations: 'Adnotacje',
  categories: 'Tagi',
  clearRootCause: 'Wyczyść źródło przestoju',
  callForService: 'Wezwij serwis',
  serviceHasBeenCalled: 'Serwis został wezwany!',
  reason: 'Przyczyna',
  reasons: 'Przyczyny',
  noReasons: 'Brak zdefiniowanych przyczyn',
  noCorrectiveActions: 'Brak zdefiniowanych akcji korekcyjnych',
  noEvents: 'Brak zdefiniowanych zdarzeń',
  noCameras: 'Brak zdefiniowanych kamer',
  rootCauseReason: 'Przyczyna źródłowa',
  removeReasonWorning: 'Uwaga! Wszystkie corrective actions przypisane do tej przyczyny zostaną usunięte!',
  newCorrectiveAction: 'Nowa akcja korekcyjna',
  newReason: 'Nowa przyczyna',
  addAnotherCorrectiveAction: 'Dodaj inną akcję korekcyjną',
  addAnotherReason: 'Dodaj inną przyczynę',
  addReason: 'Jako nowa przyczyna',
  correctiveAction: 'Akcja korekcyjna',
  correctiveActions: 'Akcje korekcyjne',
  selectEvent: 'Zdarzenie / Problem',
  specifyRootcause: 'Źródło przestoju',
  lastDowntimes: 'Ostatnie przestoje',
  startTime: 'Początek',
  endTime: 'Koniec',
  uncategorized: 'Bez kategorii',
  noActions: 'Brak określonych działań',
  timeSlot: 'Przedział czasu',
  externalReason: 'Zewnętrzna przyczyna',
  selectOtherMachineDowntime: 'Wskaż przestój innej maszyny',
  selectMachine: 'Wybierz maszynę',
  workEventFilter: 'Filtr zdarzeń',
  deselectAll: 'Odznacz wszystkie',
  connect: 'Połącz',
  commonReason: 'Wspólna przyczyna',
  commonProblem: 'Wspólny problem',
  clearSelection: 'Wyczyść zaznaczenie',
  noCorrectiveAction: 'Brak podjętej akcji korekcyjnej',
  toEditSetCommonWorkEvent: 'Aby edytować przypisz wspólny problem',
  differentCorrectiveActions: 'Różne akcje korekcyjne',
  differentReasons: 'Różne przyczyny',
  noReason: 'Brak przyczyny',
  addOrCreateReason: 'Wybierz powiązaną przyczynę',
  lineState: 'Stan linii',
  selectProblemFirst: 'Ustaw najpierw problem',
  setProblem: 'Ustaw problem',
  setReason: 'Ustaw przyczynę',
  setCorrectiveAction: 'Ustaw akcję korekcyjną',
  setAction: 'Ustaw akcję',
  summary: 'Podsumowanie',
  deleteConfirmMessage: 'Potwierdź, że chcesz usunąć wybrane przestoje.',
  changeDuration: 'Zmień czas trwania',
  downtimeUpdated: 'Przestój zaktualizowany',
  noMachineDowntime: 'Nie dotyczy maszyny',
  noDetails: 'Brak szczegółów',
  noClipsCameraAvailable: 'Brak pobranych klipów, kamera jest dostępna',
  clipsDownloaded: 'Klipy pobrane',
};
