export default {
  'report': 'Báo cáo',
  'new': 'Mới',
  'sum': 'Tổng',
  'time': 'Thời gian',
  'quantity': 'Số lượng',
  'aggregated': 'Tổng hợp',
  'detailed': 'Chi tiết',
  'showNAlarms': 'Hiển thị <b>{0}</b> báo động dài nhất',
  'showOthers': 'Hiển thị khác',
  'hideOthers': 'Ẩn khác',
  'sumOfSmaller': 'Tổng số khác',
  'dropHereToCreateNew': 'Kéo và thả ở đây để tạo biểu đồ mới',
  'completedOrders': 'Các đơn hàng đã hoàn thành',
  'history': 'Lịch sử',
  'usedEnergy': 'Năng lượng đã qua sử dụng',
  'addPersonal': 'Biểu đồ trực quan bộ phận',
  'addFactory': 'Biểu đồ trực quan nhà máy',
  'work': 'Công việc',
  'plannedDowntime': 'Đã lên kế hoạch',
  'unplannedDowntime': 'Không có kế hoạch',
  'expected': 'Dự kiến/ Mong đợi',
  'rootElement': 'Phần tử gốc',
  'performanceImpact': 'Tổn thất hiệu suất',
  'availabilityImpact': 'Tổn thất khả năng sẵn sàng',
  'minorStops': 'Điểm dừng nhỏ',
  'oeeLoss': {
    'oeeLoss': 'Tổn thất {0}',
    'productionLoss': 'Tổn thất sản xuất',
    'undefinedIssue': 'Vấn đề không xác định',
    'utilizationLosses': 'Tổn thất sử dụng',
    'availabilityLosses': 'Tổn thất khả năng sẵn sàng',
    'performanceLosses': 'Tổn thất hiệu suất',
    'qualityLosses': 'Tổn thất chất lượng',
    'speedLoss': 'Tổn thất tốc độ',
    'qualityLoss': 'Tổn thất chất lượng',
    'speedIncrease': 'Tăng tốc độ',
    'combined': 'Kết hợp',
    'byMachine': 'Chia theo dây chuyền/máy',
    'byProblem': 'Chia theo vấn đề',
    'byState': 'Chia theo tiểu bang',
    'base': 'Thời gian cơ sở',
    'time': {
      'totalTime': 'Tổng thời gian',
      'scheduledTime': 'Thời gian kế hoạch',
      'operatingTime': 'Thời gian hoạt động',
      'productionTime': 'Thời gian sản xuất',
      'effectiveTime': 'Thời gian hiệu quả'
    },
    'performanceStops': 'Thời gian ngừng máy tổn thất hiệu suất',
    'qualityStops': 'Thời gian ngừng máy tổn thất chất lượng',
    'calculatedBasedOnTotalAvailabilityLoss': 'Được tính toán dựa trên tổng tổn thất khả dụng/ khả năng sẵn sàng'
  },
  'select': {
    'allLines': 'Tất cả các dây chuyền',
    'allMachines': 'Tất cả các máy',
    'selectMachine': 'Chọn máy',
    'selectLine': 'Chọn dây chuyền',
    'clear': 'Xóa',
    'selectLineAreaOrPlant': 'Chọn nhà máy, dây chuyền hoặc khu vực'
  },
  'alarms': {
    'description': 'Mô tả',
    'numberOfOccurances': 'Số',
    'duration': 'Khoảng thời gian',
    'sumOfSmallerAlarms': 'Tổng số cảnh báo nhỏ hơn',
    'noDataInThisPeriod': 'Không có dữ liệu trong giai đoạn này'
  },
  'selectors': {
    'period': 'Giai đoạn',
    'moment': 'Khoảnh khắc',
    'dateTo': 'Đến ngày',
    'dateFrom': 'Từ ngày',
    'shift': 'Ca làm việc',
    'day': 'Ngày',
    'week': 'Tuần',
    'month': 'Tháng',
    'today': 'Hôm nay',
    'yesterday': 'Hôm qua',
    'thisWeek': 'Tuần này',
    'previousWeek': 'Tuần trước',
    'thisMonth': 'Tháng này',
    'previousMonth': 'Tháng trước',
    'selectLineAndMachine': 'Chọn dây chuyền và máy',
    'selectLine': 'Chọn dây chuyền',
    'selectLineOrMachine': 'Chọn dây chuyền và máy',
    'timeFilter': 'Bộ lọc thời gian',
    'lastThreeMonths': '3 tháng cuối',
    'selectExactDates': 'Chọn ngày chính xác',
    'selectDatesFromList': 'Chọn ngày từ danh sách',
    'addElement': 'Thêm phần tử nhà máy vào báo cáo',
    'amount': 'Số lượng'
  },
  'types': {
    'custom': 'Khách hàng',
    'availability': 'Khả năng sẵn sàng',
    'efficiency': 'Hiệu quả',
    'oee': 'OEE',
    'oeeLosses': 'Tổn thất OEE',
    'production': 'Sản xuất',
    'productionMap': 'Bản đồ sản xuất',
    'downtimes': 'Các thời gian ngừng máy',
    'rootcauses': 'Nguyên nhân gốc thời gian ngừng máy',
    'alarms': 'Cảnh báo',
    'column': 'Cột',
    'pie': 'Hình tròn',
    'operatorStops': 'Người vận hành dừng',
    'downtimeDuration': 'Khoảng thời gian ngừng máy',
    'downtimeDurations': 'Các khoảng thời gian ngừng máy',
    'reasons': 'Các nguyên nhân',
    'energy': 'Năng lượng',
    'energySankey': 'Sơ đồ sankey',
    'energyHistory': 'Lịch sử sử dụng',
    'heatmap': 'Sơ đồ nhiệt',
    'commentsFromLosses': 'Nhận xét từ các tổn thất',
    'oeeDetails': 'Chi tiết OEE',
    'reportsDownload': 'Tải báo cáo',
    'typeOfLosses': 'Loại tổn thất'
  },
  'operatorStop': {
    'waiting': 'Đang chờ hành động',
    'fixing': 'Khoản thời gian hành động',
    'stopsBecauseOf': 'Dừng lại do',
    'timeSum': 'Tổng thời gian'
  },
  'rootcause': {
    'impact': {
      'title': 'Tác động',
      'desc': '(Khoảng thời gian ảnh hưởng/ khoảng thời gian nguyên nhân gốc rễ)'
    },
    'effectDuration': 'Khoảng thời gian ảnh hưởng',
    'rootcauseDuration': 'Khoảng thời gian nguyên nhân gốc rễ'
  },
  'chart': {'toggleFullView': 'Chuyển đổi chế độ xem đầy đủ'},
  'showNLongest': 'Hiển thị <b>{0}</b> lâu nhất',
  'splitBy': 'Chia',
  'unitConversionNotComplete': 'Chuyển đổi đơn vị không hoàn thành',
  'unitConversionComplete': 'Chuyển đổi đơn vị hoàn tất',
  'unableToFullyConvertUnitMessage': 'Chúng tôi không thể chuyển đổi tất cả quá trình sản xuất thành \'{unit}\', hãy kiểm tra cấu trúc đóng gói của SKU được sản xuất trong giai đoạn này',
  'utilizationImpact': 'Tổn thất sử dụng',
  'minorStopsIncluded': 'Dừng nhỏ (Đã bao gồm trong tổng thời lượng)',
  'detailedEventsReport': 'Báo cáo sự kiện chi tiết',
  'siteOeeReport': 'Báo cáo OEE của nhà máy',
  'speedLossReport': 'Báo cáo tổn thất tốc độ',
  'labourVarianceReport': 'Báo cáo chênh lệch lao động',
  'siteWeeklyScorecard': 'Bảng điểm hàng tuần của nhà máy',
  'siteMonthlyScorecard': 'Bảng điểm hàng tháng của nhà máy',
  'exportReport': 'Xuất báo cáo',
  'excludeUtilizationLoss': 'Loại trừ tổn thất sử dụng',
  'chooseEvent': 'Chọn vấn đề',
  'needToSelectEvent': 'Bạn cần chọn vấn đề'
};
