<template>
  <div class="row no-gutters mx-0">
    <div
      v-if="!machine.isDummy && canEditWorklogs && currentState && machine"
      class="route-sm col-6"
    >
      <div
        v-if="!currentState.isForced"
        @click.stop="showStopOffcanvas = true"
      >
        <i class="fas fa-pause pr-1" />
        {{ $t('machineControl.turnOff') }}
      </div>
      <div
        v-else
        @click.stop="$emit('switch-state', true)"
      >
        <i class="fas fa-play pr-1" />
        {{ $t('machineControl.turnOn') }}
      </div>
    </div>
    <div
      v-ellipsis
      class="route-sm"
      :class="(!machine.isDummy && canEditWorklogs && machine) ? 'col-6' : 'col-12'"
      @click.stop="worklogModal = true"
    >
      <i class="icon-downtime pr-1" />
      {{ $t('monitoring.workLog') }}
    </div>
    <div
      v-if="!machine.isDummy"
      class="route-sm col-6"
      @click.stop="openProcessParams"
    >
      <i class="ion ion-ios-stats pr-1" />
      {{ $t('processParams.signals') }}
    </div>
    <div
      v-if="!machine.isDummy"
      class="route-sm col-6"
      @click.stop="openCurrentParams"
    >
      <i class="ion ion-ios-speedometer pr-1" />
      {{ $t('machineControl.current') }}
    </div>
    <div
      v-if="cameraHaveLiveStream && !machine.isDummy"
      class="route-sm col-12"
      @click.stop="showLiveVideo = !showLiveVideo"
    >
      <i class="fas fa-video pr-1" />
      {{ $t('machineControl.liveStream') }}
    </div>

    <LModal
      :show.sync="worklogModal"
      size="lg"
    >
      <h3 class="font-weight-bold modal-title">
        {{ $t('monitoring.workStates') }}
      </h3>
      <WorklogModal
        :element-id="elementId"
        :pareto-states="paretoStates"
      />
    </LModal>

    <LModal
      v-if="cameraHaveLiveStream"
      :show.sync="showLiveVideo"
      size="xl"
      :full-screen="isMobile"
      :lazy="true"
    >
      <VisionLive
        v-if="showLiveVideo"
        :element-id="elementId"
      />
    </LModal>

    <Offcanvas
      v-if="showStopOffcanvas"
      :show.sync="showStopOffcanvas"
      no-bottom-padding
      bind-to-body
    >
      <LineStopOffcanvas
        v-if="showStopOffcanvas"
        :line-id="elementId"
        @hide="showStopOffcanvas = false"
      />
    </Offcanvas>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PACKOS_DOWNTIME_MANAGEMENT, PACKOS_MANUAL_DOWNTIME } from '@core/dict/permissions';
import WorklogModal from '@/components/modals/WorklogModal';
import LineStopOffcanvas from '@/components/offcanvases/LineStopOffcanvas';

const VisionLive = () => import('@/components/vision/VisionLive');

export default {
  props: {
    elementId: String,
    currentState: Object,
    machine: Object,
    paretoStates: Array,
  },
  data: () => ({
    showLiveVideo: false,
    worklogModal: false,
    showStopOffcanvas: false,
  }),
  components: {
    LineStopOffcanvas,
    VisionLive,
    WorklogModal,
  },
  computed: {
    ...mapGetters(['isMobile', 'plantId', 'elementHaveLiveStream']),
    ...mapGetters('core', ['canOnElement']),
    canEditWorklogs() {
      return this.canOnElement(PACKOS_DOWNTIME_MANAGEMENT, this.elementId)
        || this.canOnElement(PACKOS_MANUAL_DOWNTIME, this.elementId);
    },
    cameraHaveLiveStream() {
      return this.elementHaveLiveStream(this.elementId);
    },
  },
  methods: {
    openCurrentParams() {
      this.$emit('current');
    },
    openProcessParams() {
      this.$router.push(`/${this.plantId}/element/${this.elementId}/params`);
    },
  },
};
</script>

<style lang="scss" scoped>
  .route-sm {
    margin: 2px 0;
    padding: 2px 9px;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    color: #585858;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;

  i {
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  &:hover {
    background-color: rgba(150, 150, 150, 0.2);
  }
}
</style>
