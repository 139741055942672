import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const item = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getActiveEnergy',
    property: 'activeEnergy',
    path: ({ plantId, startDate, endDate }) => `/v2/plant/${plantId}/elements/energy/active/${startDate}/${endDate}`,
  })
  .get({
    action: 'getEnergy',
    path: ({
      plantId, elementId, startDate, endDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/energy/aggregated/${startDate}/${endDate}`,
  })
  .getStore();

export default item;
