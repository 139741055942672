<template>
  <span
    class="audit-log-text"
    v-html="text"
  />
</template>

<script>
import moment from 'moment';

export default {
  props: {
    eventType: String,
    references: Array,
    body: Object,
  },
  computed: {
    text() {
      const { body } = this;

      switch (this.eventType) {
        case 'OrderStarted':
        case 'ChangeoverStarted':
        case 'CleaningStarted':
          return 'started activity';

        case 'OrderActivated':
          return 'activated the order';

        case 'OrderDeactivated':
          return 'deactivated the order';

        case 'ChangeoverSuspended':
          return 'suspended the changeover';

        case 'OrderCompleted':
        case 'ChangeoverCompleted':
        case 'CleaningCompleted':
          return 'completed activity';

        case 'OrderReleased':
        case 'CleaningReleased':
        case 'ChangeoverReleased':
          return 'released activity';

        case 'OrderOrderedQuantityUpdated':
          return `updated ordered quantity to 
            ${body.OrderedQuantity?.Value || 0} ${body.OrderedQuantity?.Unit || ''}`;

        case 'OrderSpeedUpdated':
          return `${!body.DesignedSpeed && !body.ValidatedSpeed ? 'reset order expected performance' : ''} 
          ${body.DesignedSpeed
    ? `updated designed performance: ${body.DesignedSpeed.Value} ${body.DesignedSpeed.Unit} ` : ''}
          ${body.DesignedSpeed && body.ValidatedSpeed ? '<br>' : ''}
          ${body.ValidatedSpeed
    ? `updated validated performance: ${body.ValidatedSpeed.Value} ${body.ValidatedSpeed.Unit} ` : ''}`;

        case 'OrderExpectedEffortUpdated':
        case 'ChangeoverExpectedEffortUpdated':
        case 'CleaningExpectedEffortUpdated':
          return body.ExpectedEffort
            ? `updated expected effort to ${body.ExpectedEffort.Manhours} mh`
            : 'reset expected effort';

        case 'OrderPlannedExecutionUpdated':
        case 'CleaningPlannedExecutionUpdated':
        case 'ChangeoverPlannedExecutionUpdated':
          return body.PlannedExecution
            ? `updated planned execution to 
            <b>${this.formatDate(body.PlannedExecution.Begin)}</b> 
              - <b>${this.formatDate(body.PlannedExecution.End)}</b>`
            : 'reset planned execution dates';

        case 'OrderDeleted':
        case 'CleaningDeleted':
        case 'ChangeoverDeleted':
          return 'deleted this activity';

        case 'OrderFlowUpdated':
          return `updated order flow to ${this.getReference(body.FlowId, 'Flow')}`;

        case 'OrderLineUpdated':
        case 'CleaningLineUpdated':
        case 'ChangeoverLineUpdated':
          return `updated activity line to ${this.getReference(body.LineId, 'Element')}`;

        case 'OrderProductUpdated':
          return `updated order product to ${this.getReference(body.ProductId, 'Product')}`;

        case 'OrderInfoUpdated':
        case 'ChangeoverInfoUpdated':
        case 'CleaningInfoUpdated':
          return `set description to <b>${body.Description}</b>
          ${body.Kind ? ` set kind to ${body.Kind}` : ''}
          ${this.formatAdditionalFields('set additional fields', body.AdditionalFields)}`;

        case 'OrderCreated':
          return `created order <b>${body.ExternalId}</b> with the following fields:
            <br>Line: ${this.getReference(body.LineId, 'Element')}
            <br>SKU: ${this.getReference(body.ProductId, 'Product')}
            <br>Flow: ${this.getReference(body.FlowId, 'Flow')}
            ${body.ExpectedEffort ? `<br>Expected manhours: ${body.ExpectedEffort.Manhours} mh` : ''}
            <br>Description: <b>${body.Description || '-'}</b>
            <br>Ordered quantity: <b>${body.OrderedQuantity.Value} ${body.OrderedQuantity.Unit}</b>
            <br>Planned for: 
              <b>${this.formatDate(body.PlannedExecution.Begin)}</b> 
              - <b>${this.formatDate(body.PlannedExecution.End)}</b>            
            ${this.formatAdditionalFields('<br>Additional fields:', body.AdditionalFields)}`;

        case 'ChangeoverCreated':
          return `created changeover <b>${body.ExternalId}</b> with the following fields:
            <br>Line: ${this.getReference(body.LineId, 'Element')}
            ${body.ExpectedEffort ? `<br>Expected manhours: ${body.ExpectedEffort.Manhours} mh` : ''}
            <br>Description: <b>${body.Description || '-'}</b>
            <br>Planned for: 
              <b>${this.formatDate(body.PlannedExecution.Begin)}</b> 
              - <b>${this.formatDate(body.PlannedExecution.End)}</b>
            ${this.formatAdditionalFields('<br>Additional fields:', body.AdditionalFields)}`;

        case 'CleaningCreated':
          return `created cleaning <b>${body.ExternalId}</b> with the following fields:
            <br>Line: ${this.getReference(body.LineId, 'Element')}
            ${body.ExpectedEffort ? `<br>Expected manhours: ${body.ExpectedEffort.Manhours} mh` : ''}
            <br>Description: <b>${body.Description || '-'}</b>
            <br>Planned for: 
              <b>${this.formatDate(body.PlannedExecution.Begin)}</b> 
              - <b>${this.formatDate(body.PlannedExecution.End)}</b>
            ${this.formatAdditionalFields('<br>Additional fields:', body.AdditionalFields)}`;

        default:
          return this.body;
      }
    },
  },
  methods: {
    getReference(id, type) {
      const ref = this.references.find(x => x.id === id && x.type === type);
      if (!ref) {
        return '<b class="reference">-</b>';
      }

      return `<b class="reference">${ref?.name || '-'} 
        ${ref?.code ? `<span class="code">(${ref.code})</span>` : ''}</b>`;
    },
    formatAdditionalFields(prefix, fields) {
      if (!fields || fields.length === 0) return '';
      return `${prefix} ${fields.map(x => `${x.Name}: ${x.Value}`).join(', ')}`;
    },
    formatDate(date) {
      if (!date) return '-';
      return moment(date).format('HH:mm DD MMM YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
  .audit-log-text ::v-deep .code {
    color: #aaa;
    font-size: 0.8rem;
    position: relative;
    top: -1px;
  }
</style>
