import color from '@/utils/colors';
import lang from '@/lang';

export const importStatus = s => {
  const name = s.toLowerCase();

  if (name === 'applied') {
    return {
      icon: 'fas fa-check',
      color: color('white'),
      backgroundColor: color('green'),
      text: lang.t('import.status.applied'),
      haveResult: true,
    };
  }
  if (name === 'appliedwitherrors') {
    return {
      icon: 'fas fa-exclamation',
      color: color('white'),
      backgroundColor: color('orange'),
      text: lang.t('import.status.appliedWithErrors'),
      haveResult: true,
    };
  }

  if (name === 'faulted') {
    return {
      icon: 'fas fa-exclamation',
      color: color('white'),
      backgroundColor: color('red'),
      text: lang.t('import.status.faulted'),
    };
  }
  if (name === 'parsingfailed') {
    return {
      icon: 'fas fa-exclamation',
      color: color('white'),
      backgroundColor: color('red'),
      text: lang.t('import.status.parsingFailed'),
    };
  }
  if (name === 'scheduled') {
    return {
      icon: 'fas fa-clock',
      color: color('white'),
      backgroundColor: color('grey'),
      text: lang.t('import.status.scheduled'),
      refresh: true,
    };
  }

  return {
    icon: 'fas fa-spinner fa-pulse',
    color: color('blue'),
    backgroundColor: color('white'),
    text: lang.t('import.status.processing'),
    refresh: true,
  };
};

export default importStatus;
