<template>
  <div :class="selectedReport === 'production' ? '' : 'pb-4'">
    <div class="pb-3">
      <ProductionWithNumbers
        :production="production"
        :performance="performance"
        :desc="false"
      />
    </div>
    <hr class="my-1">

    <div class="row mt-2">
      <div class="col-7">
        <span v-if="selectedReport === 'production'">
          <h4
            class="font-weight-bold mb-0"
            style="font-size: 20px"
          >
            {{ currentScopeText }}
          </h4>

          <span v-if="lineShift">
            {{ lineShift.name }}:
            {{ formatDate(realStartDate) }} - {{ formatDate(realEndDate) }}
          </span>
        </span>
      </div>

      <div class="col-5">
        <HorizontalMenu
          :list="reports"
          :selected="selectedReport"
          :small="true"
          @update:selected="selectReport($event)"
        />
      </div>
    </div>

    <div class="report-type">
      <ProductionModal
        v-if="selectedReport === 'production'"
        :production="production"
        :performance="performance"
        :element-id="elementId"
        :hide-expected="false"
        :start="realStartDate"
        :end="realEndDate"
        @validatedSpeed="validatedSpeed = $event"
        @labels-width="labelsWidth = $event"
        @scheduleDates="scheduleDates = $event"
      />

      <ProductionMap
        v-else
        :element-id="elementId"
        :start-date="heatmapStartDate"
        :end-date="heatmapEndDate"
        :height="430"
        :options="heatmapOptions"
      />
    </div>
    <div />
    <div v-if="selectedReport === 'production'">
      <hr class="mt-1 mb-3">
      <HorizontalMenu
        class="mb-2"
        :list="historyDetails"
        :selected="detailsPage"
        :small="true"
        @update:selected="detailsPage = $event"
      />

      <div v-if="detailsPage === 'orders'">
        <div style="overflow: hidden; padding: 5px">
          <ElementSchedule
            v-if="ready"
            :key="`${scheduleStartDate}_${scheduleEndDate}_${labelsWidth}`"
            :start-date="scheduleStartDate"
            :end-date="scheduleEndDate"
            :element-id="elementId"
            no-utc-offset
            :style="`margin-left: ${labelsWidth - 6}px;`"
          />
        </div>
      </div>
      <div v-else-if="detailsPage === 'performance'">
        <Loader v-if="performancePending" />
        <div
          v-for="change in uniquePerformanceChanges"
          :key="change.start"
          class="my-1"
        >
          <PerformanceSettingRow
            v-bind="change"
            :production-unit="production.unit"
          />
        </div>
      </div>
      <div v-else-if="detailsPage === 'summary'">
        <Loader v-if="summaryPending" />
        <CounterSummary
          v-if="summary"
          :summary="summary"
          :show-total="true"
          class="p-4"
        />
      </div>
      <div v-else>
        <Loader v-if="packingStructurePending" />
        <div
          v-for="change in uniquePackingStructureChanges"
          :key="change.start"
          class="my-1"
        >
          <PackingStructureRow v-bind="change" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionWithNumbers from '@/components/charts/production/ProductionWithNumbers';
import CounterSummary from '@/components/counters/CounterSummary';
import ProductionModal from '@/components/modals/ProductionModal';
import ProductionMap from '@/components/reports/production/ProductionMap';
import ElementSchedule from '@/components/schedule/ElementSchedule';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import PackingStructureRow from './PackingStructureRow';
import PerformanceSettingRow from './PerformanceSettingRow';

export default {
  props: {
    elementId: String,
    production: Object,
    performance: Object,
  },
  data: () => ({
    ready: false,
    selectedReport: 'production',
    detailsPage: 'orders',
    validatedSpeed: false,
    heatmapOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
    summary: null,
    summaryPending: false,
    packingStructureHistory: [],
    performanceSettingsHistory: [],
    performancePending: false,
    packingStructurePending: false,
    scheduleDates: null,
    labelsWidth: 0,
  }),
  components: {
    ProductionWithNumbers,
    ProductionModal,
    ElementSchedule,
    HorizontalMenu,
    ProductionMap,
    PerformanceSettingRow,
    PackingStructureRow,
    CounterSummary,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'currentScopeText',
      'startDate',
      'endDate',
      'selectedDateScope',
      'shiftInstances',
    ]),
    ...mapGetters('element', ['element']),
    lineShift() {
      const { name } = this.selectedDateScope;
      if (name !== 'shift') return null;
      const now = moment().unix();
      return this.shiftInstances?.find(x => x.elementId === this.elementId && x.startDate <= now
        && x.endDate > now);
    },
    realStartDate() {
      const { name } = this.selectedDateScope;
      if (name === 'order') {
        const order = this.element(this.elementId)?.activities?.activeOrder;
        if (!order) return this.startDate;
        return moment(order?.actualExecution?.begin).unix() || moment(order.scheduledExecution?.begin).unix();
      }
      if (name === 'shift') {
        return this.lineShift ? this.lineShift.startDate : this.startDate;
      }
      return this.startDate;
    },
    scheduleStartDate() {
      if (!this.scheduleDates) return this.realStartDate;
      return moment.utc(this.scheduleDates.from).unix();
    },
    scheduleEndDate() {
      if (!this.scheduleDates) return this.realEndDate;
      return moment.utc(this.scheduleDates.to).unix();
    },
    realEndDate() {
      const { name } = this.selectedDateScope;
      if (name === 'order') {
        const order = this.element(this.elementId)?.activities?.activeOrder;
        if (!order) return moment().unix();
        return moment(order?.actualExecution?.end).unix() || moment(order.scheduledExecution?.end).unix();
      }
      if (name === 'shift') {
        return this.lineShift ? this.lineShift.endDate : moment().unix();
      }
      return moment().unix();
    },
    historyDetails() {
      return [
        {
          name: this.$t('orders.orders'),
          icon: 'icon-orders',
          key: 'orders',
        },
        {
          name: this.$t('monitoring.performanceSettingHistory'),
          icon: 'fas fa-clipboard',
          key: 'performance',
        },
        {
          name: this.$t('monitoring.unitConversionsHistory'),
          icon: 'fas fa-divide',
          key: 'unitConversions',
        },
        {
          name: this.$t('general.summary'),
          icon: 'fas fa-file-alt',
          key: 'summary',
        },
      ];
    },
    reports() {
      return [
        {
          name: this.$t('monitoring.production'),
          icon: 'fas fa-chart-line',
          key: 'production',
        },
        {
          name: this.$t('monitoring.productionMap'),
          icon: 'fas fa-map',
          key: 'productionMap',
        },
      ];
    },
    heatmapStartDate() {
      return moment().subtract(2, 'w').unix();
    },
    heatmapEndDate() {
      return moment().unix();
    },
    uniquePerformanceChanges() {
      return this.performanceSettingsHistory
        .reduce((acc, curr) => {
          if (curr.value !== acc.current) {
            acc.list.push({
              start: curr.startDate,
              end: curr.endDate,
              value: curr.value,
            });

            acc.current = curr.value;
            acc.openIndex = acc.list.length - 1;
          } else {
            acc.list[acc.openIndex].end = curr.endDate;
          }

          return acc;
        }, { current: -1, openIndex: -1, list: [] })
        .list
        .reverse();
    },
    uniquePackingStructureChanges() {
      return this.packingStructureHistory
        .reduce((acc, curr) => {
          if (!this.matrixEquals(curr.matrix, acc.current)) {
            acc.list.push({
              start: moment.utc(curr.startDate).local(),
              end: moment.utc(curr.endDate).local(),
              matrix: curr.matrix,
            });

            acc.current = curr.matrix;
            acc.openIndex = acc.list.length - 1;
          } else {
            acc.list[acc.openIndex].end = moment.utc(curr.endDate).local();
          }

          return acc;
        }, { current: '_', openIndex: -1, list: [] })
        .list
        .reverse();
    },
  },
  watch: {
    validatedSpeed() {
      this.requestPerformance();
    },
    detailsPage(v) {
      if (v === 'summary') {
        this.summaryPending = true;

        this.getCounterSummary({
          params: {
            plantId: this.plantId,
            query: {
              elementId: this.elementId,
              startDate: moment.unix(this.realStartDate).utc().format(),
              endDate: moment.unix(this.realEndDate).utc().format(),
            },
          },
        })
          .then(({ data }) => {
            this.summary = data;
          })
          .finally(() => {
            this.summaryPending = false;
          });
      }
    },
  },
  methods: {
    ...mapActions('settings', [
      'getPackingStructureHistory',
      'getPerformanceSettingsHistory',
    ]),
    ...mapActions(['getCounterSummary']),
    matrixEquals(m1, m2) {
      return JSON.stringify(m1) === JSON.stringify(m2);
    },
    toUnix(date) {
      return moment(date).unix();
    },
    formatDate(date) {
      return moment.unix(date).format('HH:mm');
    },
    selectReport(key) {
      this.selectedReport = key;
    },
    request() {
      const { plantId, elementId } = this;

      this.packingStructurePending = true;
      this.getPackingStructureHistory({
        params: {
          plantId,
          query: {
            lineId: elementId,
            from: moment.unix(this.realStartDate).format(),
            to: moment.unix(this.realEndDate).format(),
          },
        },
      })
        .then(({ data }) => {
          this.packingStructureHistory = data;
        })
        .finally(() => {
          this.packingStructurePending = false;
        });

      this.requestPerformance();
    },
    requestPerformance() {
      this.performancePending = true;
      this.getPerformanceSettingsHistory({
        params: {
          plantId: this.plantId,
          query: {
            lineId: this.elementId,
            from: moment.unix(this.realStartDate).format(),
            to: moment.unix(this.realEndDate).format(),
            performanceType: this.validatedSpeed ? 1 : 0,
          },
        },
      })
        .then(({ data }) => {
          this.performanceSettingsHistory = data;
        })
        .finally(() => {
          this.performancePending = false;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 500);
    this.request();
  },
};
</script>

<style scoped>

.report-type{
  height: 450px;
  max-height: 55vh;
}

</style>
