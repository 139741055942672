<template>
  <div class="row oees no-gutters">
    <div
      :class="size"
      class="col suboee-container"
    >
      <OEE
        :size="size"
        :value="oee"
        :max-height="maxHeight"
        :target="target ? target.oee : null"
        class="world-oee-subchart"
        :class="{'same-size': sameSize}"
      />
      <div class="text-center oee-name text-nowrap overflow-hidden text-ellipsis w-100 mx-auto">
        {{ $t('oee.oee') }}
      </div>
    </div>
    <div
      :class="size"
      class="col suboee-container"
    >
      <OEE
        :size="size"
        :value="oeeA"
        :max-height="maxHeight"
        :target="target ? target.oeeA : null"
        class="world-oee-subchart"
        :class="{'same-size': sameSize}"
      />
      <div class="text-center oee-name text-nowrap overflow-hidden text-ellipsis w-100 mx-auto">
        {{ $t('oee.availability') }}
      </div>
    </div>
    <div
      :class="size"
      class="col suboee-container"
    >
      <OEE
        :size="size"
        :value="oeeP"
        :max-height="maxHeight"
        :target="target ? target.oeeP : null"
        class="world-oee-subchart"
        :class="{'same-size': sameSize}"
      />
      <div class="text-center oee-name text-nowrap overflow-hidden text-ellipsis w-100 mx-auto">
        {{ $t('oee.performance') }}
      </div>
    </div>
    <div
      :class="size"
      class="col suboee-container"
    >
      <OEE
        :size="size"
        :value="oeeQ"
        :max-height="maxHeight"
        :target="target ? target.oeeQ : null"
        class="world-oee-subchart"
        :class="{'same-size': sameSize}"
      />
      <div class="text-center oee-name text-nowrap overflow-hidden text-ellipsis w-100 mx-auto">
        {{ $t('oee.quality') }}
      </div>
    </div>
    <div
      v-if="!hideTeep"
      class="col suboee-container"
      :class="size"
      style="height: 45px; width: 45px"
      :style="!sameSize && 'position: relative; top: 5px'"
    >
      <OEE
        :size="size"
        :value="teep"
        :max-height="maxHeight"
        :style="!sameSize && 'height: 45px'"
        class="world-oee-subchart"
        :class="{'same-size': sameSize}"
      />
      <div
        class="text-center oee-name text-nowrap overflow-hidden text-ellipsis w-100 mx-auto"
        style="margin-top: -2px"
      >
        {{ $t('oee.teep') }}
      </div>
    </div>
  </div>
</template>

<script>
import OEE from './OEE';

export default {
  props: {
    oee: Number,
    oeeA: Number,
    oeeP: Number,
    oeeQ: Number,
    teep: Number,
    size: String,
    target: Object,
    hideTeep: Boolean,
    sameSize: Boolean,
    maxHeight: Number,
  },
  components: {
    OEE,
  },
};
</script>

<style lang="scss" scoped>
  .oees {
    position: relative;
    margin: 0;
    height: 100%;
    width: 100%;
  }

  .world-oee-subchart :deep(.OEEpercent) {
    font-size: 15px;
  }

  .suboee-container > .oee-name {
    font-size: 12px;
    max-width: 100px;
  }

  .suboee-container.xs {
    margin-right: 2px;
    margin-left: 2px;
    max-height: 80px;
  }

  .suboee-container.xs > .oee-name {
    font-size: 11px;
    margin-top: -4px;
  }

  .suboee-container.xxs > .oee-name {
    font-size: 9px;
    margin-top: -5px;
  }

  .suboee-container.sm > .oee-name {
    font-size: 12px;
    margin-top: -9px;
  }

  .oees .world-oee-subchart {
    height: 50px;

    &.same-size {
      width: 50px;
      margin: auto;
    }
  }
</style>
