var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row no-gutters"},_vm._l((_vm.list),function(el){return _c('div',{key:el.key,staticClass:"btn-container",class:{
      'col': _vm.fluid,
      'col-6 col-sm-3 col-md-auto': !_vm.fluid
    }},[_c('div',{staticClass:"radio-label w-100 d-flex justify-content-center align-items-center",class:{
        small: _vm.small,
        active: el.key === _vm.selected
      },on:{"click":function($event){return _vm.$emit('update:selected', el.key)}}},[_c('i',{class:el.icon}),_c('div',{staticClass:"pl-1 text-nowrap"},[_vm._v(" "+_vm._s(el.name)+" ")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }