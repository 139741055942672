import loopRequest from '@/mixins/loopRequest';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    ids: {
      type: Array,
      required: true,
    },
    start: Number,
    end: Number,
    splitByShift: Boolean,
    splitByOrder: Boolean,
  },
  data: () => ({
    knownIds: [],
    needsRefresh: false,
  }),
  mixins: [loopRequest('request', 10000)],
  computed: {
    ...mapGetters([
      'plantId',
      'startDate',
      'endDate',
      'performanceType',
      'selectedDateScope',
      'productionFromOrders',
    ]),
    realStart() {
      return this.start || this.startDate;
    },
    realEnd() {
      return this.end || this.endDate;
    },
    refreshParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        start: this.start,
        end: this.end,
        splitByShift: this.splitByShift,
        splitByOrder: this.splitByOrder,
        performanceType: this.performanceType,
        selectedDateScope: this.selectedDateScope,
        productionFromOrders: this.productionFromOrders,
      };
    },
  },
  methods: {
    ...mapActions([
      'getDashboardData',
      'getDashboardDataForShifts',
      'getDashboardDataForOrders',
    ]),
    correctParams({
      plantId, splitByOrder, splitByShift, startDate, query,
    }) {
      if (this.splitByShift) {
        return this.plantId === plantId && query.performanceType === this.performanceType
          && splitByShift === this.splitByShift;
      }
      if (this.splitByOrder) {
        return this.plantId === plantId && query.performanceType === this.performanceType
          && splitByOrder === this.splitByOrder;
      }
      return this.plantId === plantId && this.realStart === startDate
        && query.performanceType === this.performanceType;
    },
    makeRequest() {
      if (this.splitByShift) {
        return this.getDashboardDataForShifts({
          params: {
            plantId: this.plantId,
            splitByShift: true,
            query: {
              id: this.ids,
              performanceType: this.performanceType,
              planFromOrders: this.productionFromOrders,
            },
          },
        });
      }

      if (this.splitByOrder) {
        return this.getDashboardDataForOrders({
          params: {
            plantId: this.plantId,
            splitByOrder: true,
            query: {
              id: this.ids,
              performanceType: this.performanceType,
              planFromOrders: this.productionFromOrders,
            },
          },
        });
      }

      return this.getDashboardData({
        params: {
          plantId: this.plantId,
          startDate: this.realStart,
          endDate: this.realEnd,
          query: {
            id: this.ids,
            performanceType: this.performanceType,
            planFromOrders: this.productionFromOrders,
          },
        },
      });
    },
    request() {
      return this.makeRequest()
        .then(({ data, config: { params } }) => {
          // validate request
          if (!this.correctParams(params)) return;
          this.$emit('response', data);

          const disabledIds = this.ids.filter(x => !data.find(y => y.id === x));

          this.$store.commit('plant/updateElements', {
            elements: data.map(i => ({
              ...i,
              type: i.kind,
            })),
            disabledIds,
          });
        });
    },
  },
  watch: {
    refreshParams() {
      this.ids.forEach(eId => {
        this.$store.dispatch('plant/clearElementCache', eId);
      });
      this.request();
    },
    ids(ids) {
      const newIds = ids.filter(i => this.knownIds.findIndex(k => k === i) === -1);
      if (newIds.length === 0 && ids.length === this.knownIds.length) {
        return;
      }
      this.knownIds = ids;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      // delayed request
      this.timeout = setTimeout(() => {
        this.request();
      }, 500);
    },
  },
  render() { return true; },
  created() {
    this.knownIds = this.ids;
  },
};
