<template>
  <BOverlay
    :show="pending"
    spinner-small
    spinner-variant="primary"
    class="d-inline-block"
    opacity="0.5"
  >
    <BFormCheckbox
      v-model="checked"
      v-b-tooltip.hover.bottom="$t('vision.videoTrigger')"
      type="checkbox"
      switch
    >
      {{ $t('vision.autoDownload') }}
    </BFormCheckbox>
    <br>
  </BOverlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { systemTags } from '@/utils/dictionary';

export default {
  props: {
    elementId: {
      type: String,
      required: true,
    },
    workEventId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    checked: false,

    selected: [],
    edited: null,
    loading: false,
    showPopover: false,
    willBeRemoved: null,
    adding: false,
    search: null,
    newTag: null,
    pending: false,
    workEventTags: [],
  }),
  computed: {
    ...mapGetters([
      'plantId',
    ]),
    ...mapGetters('work', [
      'workEvent',
      'tags',
    ]),
    videoTriggerWorkTagId() {
      return this.tags
        .find(t => t.name.toLowerCase() === systemTags.videoTrigger.toLowerCase())?.id || '';
    },
    tagStatus() {
      return this.workEventTags.some(t => t.id === this.videoTriggerWorkTagId);
    },
    event() {
      return this.workEvent(this.workEventId);
    },
  },
  watch: {
    checked(c) {
      if (c) {
        this.addTag();
      } else {
        this.removeTag();
      }
    },
  },
  methods: {
    ...mapActions('work', [
      'getWorkEventDetails',
      'removeWorkEventTag',
      'addWorkEventTag',
    ]),
    addTag() {
      if (!this.videoTriggerWorkTagId) {
        this.$bvToast.toast(this.$t('vision.videoTriggerWorkTagError'), {
          title: this.$t('error.error'),
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
        });
        return Promise.resolve();
      }
      const { workEventId } = this;

      this.pending = true;

      return this.addWorkEventTag({
        params: {
          plantId: this.plantId,
          workEventId,
          workTagId: this.videoTriggerWorkTagId,
        },
      })
        .then(() => {
          this.edited = null;
        }).catch(({ response }) => {
          this.$root.$bvToast.toast(response.data, {
            title: this.$t('error.error'),
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-bottom-left',
          });
        }).finally(() => {
          this.pending = false;
        });
    },
    removeTag() {
      const { workEventId } = this;
      this.pending = true;
      this.removeWorkEventTag({
        params: {
          plantId: this.plantId,
          workEventId,
          workTagId: this.videoTriggerWorkTagId,
        },
      })
        .then(() => {
          this.$store.commit('work/removeWorkEventTag',
            { workEventId, tagId: this.videoTriggerWorkTagId });
        }).catch(({ response }) => {
          this.$root.$bvToast.toast(response.data, {
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-bottom-left',
          });
        }).finally(() => {
          this.pending = false;
        });
    },
    async getWorkEventTags() {
      this.pending = true;
      const { data } = await this.getWorkEventDetails({
        params: {
          plantId: this.plantId,
          query: {
            id: this.workEventId,
          },
        },
      });
      this.workEventTags = data[0]?.tags || [];
      this.pending = false;
    },
  },
  async created() {
    await this.getWorkEventTags();
    this.checked = this.tagStatus;
  },
};
</script>

<style>
@media screen and (max-width: 415px)
{
  .pickers-container {
      height: 100%;
    }
}
</style>
