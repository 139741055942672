export default {
  'nothing': 'keine Benachrichtigungen',
  'list': 'Liste',
  'subscribedOnly': 'nur Zugeordnet',
  'subscription': 'Zuordnung',
  'addSubscription': 'füge Zuordnung hinzu',
  'notificationType': 'Benachrichtigungstyp',
  'pushNotSupported': 'Benachrichtigungen werden in dieser Umgebung nicht unterstützt',
  'pushDenied': 'Benachrichtigungen wurden blockiert',
  'enablePush': 'Push-Benachrichtigungen aktivieren',
  'pushDisabled': 'Push-Benachrichtigungen deaktiviert',
  'pushEnabled': 'Push-Benachrichtigungen aktiviert',
  'outdated': 'veraltete Größe',
  'wastedThreshold': 'Ausschuss Schwellenwert',
  'threshold': 'Schwellenwert',
  'mailing': 'erhalten als E-Mail',
  'mySubscriptions': 'meine Zuordnungen',
  'usersSubscriptions': 'Benutzer Zuordnungen',
  'teamsSubscriptions': 'Team Zuordnungen',
  'numberOfSubs': 'Anzahl Zuordnungen',
  'subscriptionFromTeam': 'Diese Zuordnung stammt vom Team und kann nur für das gesamte Team von einem Benutzer mit entsprechenden Berechtigungen bearbeitet werden',
  'type': {'hints': 'Hinweise', 'warnings': 'Warnungen', 'userNotifications': 'Benachrichtigungen'},
  'name': {
    'cmmsNotification': 'Wartungsservice',
    'connectionLost': 'Maschinenverbindung verloren',
    'availabilityInsight': 'Linienverfügbarkeit Information',
    'ongoingStop': 'andauernder Linienstop',
    'newDowntime': 'neue Stillstandszeit',
    'orderNotifications': 'Auftragsstatus Änderung',
    'thresholdExceeded': 'Schwellenwert über- / unterschritten',
    'oeeThreshold': 'OEE unter dem Zielwert',
    'orderProductionDone': 'Auftragsende erreicht',
    'orderProductionNotAchieved': 'Auftragende mit unterschrittener Auftrgamenge erreicht',
    'orderDelayed': 'Auftrag Verspätung',
    'performanceThreshold': 'Maschinenleistung geringer als erwartet',
    'orderWastedThresholdExceeded': 'Ausschuss Schwellenwert überschritten',
    'selectedLines': 'ausgewählte Linien',
    'allLines': 'alle Linien',
    'ignoredCounter': 'Ignorierter Zähler',
    'oeeInfo': 'OEE Zusammenfassung'
  },
  'timePeriod': {
    'none': 'keine',
    'immediately': 'unmittelbar',
    'hourly': 'stündliche Zusammenfassung der Benachrichtigungen',
    'daily': 'tägliche Zusammenfassung der Benachrichtigungen'
  }
};
