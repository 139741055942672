import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import { notify as axios } from '@core/api/axios';
import lang from '@/lang';

const store = new Vapi({
  axios,
  state: {
    usersNotifications: [],
    usersReportSubscriptions: [],
    teamsNotifications: [],
  },
})
  .get({
    action: 'getUsersNotifications',
    property: 'usersNotifications',
    path: ({ tenantId }) => `tenant/${tenantId}/users`,
  })
  .get({
    action: 'getUsersReportSubscriptions',
    property: 'usersReportSubscriptions',
    path: () => 'v2/reports/subscriptions',
  })
  .get({
    action: 'getUsersReportTemplateCodes',
    path: () => 'v2/reports/templateCodes',
  })
  .get({
    action: 'getTeamsNotifications',
    property: 'teamsNotifications',
    path: ({ tenantId }) => `tenant/${tenantId}/teams`,
  })
  .get({
    action: 'getUserNotifications',
    path: ({ tenantId, userId }) => `tenant/${tenantId}/user/${userId}`,
    onSuccess: (s, { data }) => {
      const notificaitonIndex = s.usersNotifications.findIndex(u => u.id === data.id);
      if (notificaitonIndex > -1) {
        s.usersNotifications[notificaitonIndex] = data;
      } else {
        s.usersNotifications.push(data);
      }
      s.usersNotifications = [...s.usersNotifications];
    },
  })
  .post({
    action: 'addSubscription',
    path: ({ ownerType, ownerId }) => `subscribe/${ownerType}/${ownerId}`,
    onSuccess: (s, { data }, _, { params }) => {
      let owner;
      if (params.ownerType === 'user') {
        owner = s.usersNotifications.find(u => u.id === params.ownerId);
      } else {
        owner = s.teamsNotifications.find(t => t.id === params.ownerId);
      }
      owner.subscriptions.push(data);
    },
  })
  .post({
    action: 'addReportSubscription',
    path: () => 'v2/reports/subscriptions',
  })
  .put({
    action: 'updateSubscription',
    path: ({ id }) => `subscription/${id}`,
    onSuccess: (s, { data }, _, { params }) => {
      let owner;
      if (params.ownerType === 'user') {
        owner = s.usersNotifications.find(u => u.id === params.ownerId);
      } else {
        owner = s.teamsNotifications.find(t => t.id === params.ownerId);
      }
      const index = owner.subscriptions.findIndex(x => x.id === data.id);
      if (index > -1) {
        Vue.set(owner.subscriptions, index, data);
      }
    },
  })
  .put({
    action: 'updateReportSubscription',
    path: ({ id }) => `v2/reports/subscriptions/${id}`,
  })
  .delete({
    action: 'deleteSubscription',
    path: ({ id }) => `subscription/${id}`,
    onSuccess: (s, res, _, { params }) => {
      let owner;
      if (params.ownerType === 'user') {
        owner = s.usersNotifications.find(u => u.id === params.ownerId);
      } else {
        owner = s.teamsNotifications.find(t => t.id === params.ownerId);
      }
      owner.subscriptions = owner.subscriptions.filter(el => el.id !== params.id);
    },
  })
  .delete({
    action: 'deleteReportSubscription',
    path: ({ id }) => `v2/reports/subscriptions/${id}`,
  })
  .getStore();

store.getters = {
  ownerPlantSubscriptions: (s, g) => (ownerType, ownerId, plantId) => {
    let ownerSubscriptions = [];

    if (ownerType === 'user' && s.usersNotifications) {
      const user = s.usersNotifications.find(x => x.id === ownerId);
      ownerSubscriptions = user.subscriptions;
      const userTeams = s.teamsNotifications.filter(t => user.roles.includes(t.id))
        .flatMap(t => t.subscriptions.map(sub => ({
          ...sub,
          fromTeam: t.name,
        })));
      ownerSubscriptions = ownerSubscriptions.concat(userTeams);
    } else if (ownerType === 'team' && s.teamsNotifications) {
      ownerSubscriptions = s.teamsNotifications.find(t => t.id === ownerId).subscriptions;
    }

    if (!ownerSubscriptions) return [];

    return ownerSubscriptions
      .filter(x => x.plantId === plantId)
      .map(x => ({
        ...x,
        config: JSON.parse(x.config),
        typeDetails: g.subscriptionTypes.find(t => t.key.toLowerCase() === x.type.toLowerCase()),
        mailDetails: g.timePeriodTypes.find(t => t.value === x.mailing),
      }))
      .filter(x => x.typeDetails);
  },
  userReportSubscriptions: (s, g) => userId => s.usersReportSubscriptions
    .filter(sub => sub.userId === userId).map(sub => ({
      ...sub,
      type: 'OEESummaryMail',
      mailing: sub.frequency,
      typeDetails: {
        key: 'OEESummaryMail',
        icon: 'fas fa-envelope-open-text',
        name: lang.t('notify.name.oeeSummaryMail'),
      },
      mailDetails: g.timePeriodTypesForReportSubscriptions.find(t => t.value === sub.frequency),
    })),
  subscriptionTypes: () => [
    {
      key: 'NewDowntime',
      icon: 'fas fa-clock',
      name: lang.t('notify.name.newDowntime'),
    },
    {
      key: 'ConnectionLost',
      icon: 'fas fa-plug',
      name: lang.t('notify.name.connectionLost'),
      single: true,
    },
    // TODO: Disabled temporarly because not supported
    // {
    //   key: 'CmmsNotification',
    //   icon: 'fas fa-hammer',
    //   name: lang.t('notify.name.cmmsNotification'),
    //   single: true,
    // },
    // {
    //   key: 'AvailabilityInsight',
    //   icon: 'fas fa-lightbulb',
    //   name: lang.t('notify.name.availabilityInsight'),
    //   single: true,
    // },
    // {
    //   key: 'ThresholdExceeded',
    //   icon: 'fas fa-temperature-high',
    //   name: lang.t('notify.name.thresholdExceeded'),
    //   single: true,
    // },
    // {
    //   key: 'OrderWastedThresholdExceeded',
    //   icon: 'fas fa-trash-restore',
    //   name: lang.t('notify.name.orderWastedThresholdExceeded'),
    // },
    {
      key: 'OEEThreshold',
      icon: 'fas fa-chart-line',
      name: lang.t('notify.name.oeeThreshold'),
    },
    {
      key: 'OEELossInfo',
      icon: 'fas fa-file-medical-alt',
      name: lang.t('notify.name.oeeInfo'),
    },
    {
      key: 'OrderStatusChange',
      icon: 'fas fa-tags',
      name: lang.t('notify.name.orderNotifications'),
    },
    {
      key: 'OrderRequiredProductionDone',
      icon: 'fas fa-clipboard-check',
      name: lang.t('notify.name.orderProductionDone'),
    },
    {
      key: 'OrderProducedLessThanOrdered',
      icon: 'fas fa-clipboard',
      name: lang.t('notify.name.orderProductionNotAchieved'),
    },
    {
      key: 'OrderDelayed',
      icon: 'fas fa-calendar-times',
      name: lang.t('notify.name.orderDelayed'),
    },
    {
      key: 'PerformanceThreshold',
      icon: 'fas fa-cog',
      name: lang.t('notify.name.performanceThreshold'),
    },
    {
      key: 'IgnoredCounter',
      icon: 'fas fa-times',
      name: lang.t('notify.name.ignoredCounter'),
    },
    {
      key: 'CustomHint',
      icon: 'far fa-lightbulb',
      name: lang.t('notify.type.hints'),
    },
    {
      key: 'OEESummaryMail',
      icon: 'fas fa-envelope-open-text',
      name: lang.t('notify.name.oeeSummaryMail'),
    },
  ],
  timePeriodTypes: () => [
    {
      key: 'None',
      text: lang.t('notify.timePeriod.none'),
      label: '',
      value: 0,
    },
    {
      key: 'Immediately',
      text: lang.t('notify.timePeriod.immediately'),
      label: '',
      value: 1,
    },
    {
      key: 'Hourly',
      text: lang.t('notify.timePeriod.hourly'),
      label: '1h',
      value: 2,
    },
    {
      key: 'Daily',
      text: lang.t('notify.timePeriod.daily'),
      label: '24h',
      value: 3,
    },
  ],
  timePeriodTypesForReportSubscriptions: () => [
    {
      key: 'EndOfShift',
      text: lang.t('notify.timePeriod.everyEndOfShift'),
      label: lang.t('shift'),
      value: 'EndOfShift',
    },
    {
      key: 'EndOfWorkDay',
      text: lang.t('notify.timePeriod.everyDay'),
      label: '24h',
      value: 'EndOfWorkDay',
    },
    {
      key: 'EndOfWeek',
      text: lang.t('notify.timePeriod.everyWeek'),
      label: `1 ${lang.t('time.week').toLowerCase()}`,
      value: 'EndOfWeek',
    },
  ],
};

export default store;
