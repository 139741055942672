<template>
  <div
    id="plant-title-wrapper"
    v-click-outside="hidePopover"
  >
    <div
      id="p-title"
      class="plant-title mx-auto"
      :style="{
        'max-width': isMobile ? '90px' : '100%',
        'margin-right' : isMobile ? '100px !important' : '',
      }"
      @click="expanded = !expanded"
    >
      <div
        v-if="plantName"
        class="text-fancy mb-0 title underscore text-truncate overflow-hidden"
      >
        {{ plantName }}
      </div>
      <span
        v-else-if="!plantId"
        class="text-fancy  mb-0 title underscore"
      >
        <i class="ion ion-md-repeat" /> {{ $t('menu.selectPlant') }}
      </span>
    </div>
    <BPopover
      :show.sync="expanded"
      target="p-title"
      placement="bottom"
      container="plant-title-wrapper"
    >
      <div
        v-if="plants"
        class="text-center plant-list text-fancy"
      >
        <div
          v-for="plant in plants"
          :key="plant.id"
          class="p-select"
          @click="selectPlant(plant)"
        >
          <span class="underscore">{{ plant.name }}</span>
        </div>
        <div
          v-if="showWorld"
          class="globe h3 mt-2 mb-1"
          @click="toWorld"
        >
          <i class="fas fa-globe-africa" />
        </div>
      </div>
    </BPopover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showWorld: Boolean,
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters(['plantId', 'plants']),
    ...mapGetters('plant', ['plant']),
    plantName() {
      const plant = this.plant(this.plantId);
      return plant ? plant.name : null;
    },
  },
  methods: {
    hidePopover() { this.expanded = false; },
    selectPlant(plant) {
      this.expanded = false;
      this.$emit('selected', plant.id);
    },
    toWorld() {
      this.expanded = false;
      this.$router.push('/world');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .plant-title {
    position: relative;
    cursor: pointer;
    .expand-arrow > i {
      transition: transform 500ms, color 500ms;
    }
  }
  .title {
    font-size: 1.3rem;
    color: white;
    line-height: 1.1;
  }

  .p-select {
    position: relative;
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: color 500ms, transform 200ms;
    &:hover {
      color: $ilabo;
    }
    &:active {
      transform: scale(0.9);
    }
  }

  .underscore {
    &:active {
      text-decoration: none;
    }

    &::after {
      content: "";
      position: absolute;
    }

    &::after {
      top: 90%;
      height: 2px !important;
      width: 0%;
      left: 50%;
      background-color: rgba(224, 224, 224, 0.6);
      transition: 0.4s ease-out all .1s;
    }
    &:hover::after {
      width: 90%;
      left: 5%;
      transition: 0.5s ease all;
    }
  }

  .plant-list {
    font-weight: 300;
    font-size: 18px;
  }

  .globe { cursor: pointer; }
  .globe:hover {
    color: $ilabo;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 1.1rem;
      line-height: 36px;
      letter-spacing: 0px;
    }
  }

</style>
