import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import BootstrapVue from 'bootstrap-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import '@/utils/extensions';
import LogixCore from '@core';
import { init } from '@/api/axios';

import tooltipMixin from '@/mixins/tooltipMixin';

import './styles/app.scss';
import vars from './styles/vars.icss.scss';

import App from './App';
import router from './router';
import store from './store';
import i18n from './lang';
import start from './start';
import './registerServiceWorker';

Vue.use(VueTippy);
Vue.use(LogixCore, { colors: vars, store });
Vue.component('Tippy', TippyComponent);
Vue.use(BootstrapVue);

// set interceptors for api calls
init(store);

Vue.use(VueScrollTo);

Vue.mixin(tooltipMixin);

start({
  store,
  vue: {
    el: '#app',
    i18n,
    router,
    store,
    render: h => h(App),
  },
});
