<template>
  <div style="position: relative; text-align: left">
    <BarChart
      :list="dataset"
      style="width: 100%; margin-top: 2px"
      :style="{ height: `${height}px`}"
      :tooltip-fn="legend ? getTooltip : null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BarChart from '@/components/charts/BarChart';
import colors from '@/utils/colors';
import CalcDuration from '@/utils/calcDuration';

export default {
  props: {
    paretoStates: Array,
    clickable: Boolean,
    height: {
      type: Number,
      default: 17,
    },
    legend: {
      type: Boolean,
      default: true,
    },
    customStates: Boolean,
  },
  components: {
    BarChart,
  },
  computed: {
    ...mapGetters('work', ['workEvent', 'workStates']),
    dataset() {
      const empty = {
        stateId: -1,
        label: '',
        color: '#e9e9e9',
        value: 100,
        time: 0,
      };

      if (!this.paretoStates) {
        return [empty];
      }

      let states = [];
      if (this.customStates) {
        states = this.paretoStates;
      } else {
        states = this.paretoStates
          .slice()
          .sort((a, b) => b.timeInPercents - a.timeInPercents)
          .map(s => {
            const state = this.workStates.find(x => x.name.toLowerCase() === s.workStateName.toLowerCase());
            if (!state) return null;
            return {
              stateId: s.workStateId,
              color: colors(state.colorCode),
              label: this.legend ? state.description : '',
              value: Math.min(Math.max(0, s.timeInPercents) * 100, 100),
              time: Math.max(0, s.timeInSeconds),
            };
          })
          .filter(s => s && s.time > 0);
      }

      const sum = states.reduce((acc, curr) => acc + curr.value, 0);
      if (sum < 100) {
        states.push({ ...empty, value: Math.min(100, 100 - sum) });
      }
      return states;
    },
  },
  methods: {
    CalcDuration,
    getUniqueId(state) {
      return `workstate_${state.stateId}_${this.$uId}`;
    },
    getTooltip(el) {
      return `${el.label}<br>
        <b>${Math.floor(el.value)}%</b>
        - ${CalcDuration(1, el.time + 1)}`;
    },
  },
};
</script>

<style scoped>

  .fake-tooltips {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fake-tooltips > div {
    height: 100%;
    float: left;
  }
  .fake-tooltips.clickable {
    cursor: pointer;
  }
  .pareto-legend {
    font-size: 9px;
    font-weight: normal;
    color: white;
    line-height: 14px;
    padding-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fake-tooltips.clickable:hover {
    margin: 2px 0 -2px;
    border-radius: 20px;
    height: 16px;
    background-color: rgba(150, 150, 150, 0.2);
  }
</style>
