<template>
  <div class="position-relative">
    <BarChart
      :list="chartList"
      style="height: 16px;"
      hide-labels
    />
    <span
      class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center text-white duration-text"
    >
      {{ leftDuration }}
    </span>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import calcDuration from '@/utils/calcDuration';
import loopRequest from '@/mixins/loopRequest';

export default {
  props: {
    actualStart: {
      type: Number,
      required: true,
    },
    estimatedStart: {
      type: Number,
      required: true,
    },
    estimatedEnd: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    now: null,
  }),
  components: { BarChart },
  mixins: [loopRequest('loop', 1000)],
  computed: {
    changeoverEndUnix() {
      const duration = this.estimatedEnd - this.estimatedStart;
      return this.actualStart + duration;
    },
    isOverdue() {
      return this.now > this.changeoverEndUnix;
    },
    leftDuration() {
      if (this.isOverdue) {
        return this.$t('monitoring.overdue', [calcDuration(this.changeoverEndUnix, this.now)]);
      }
      return this.$t('monitoring.left', [calcDuration(this.now, this.changeoverEndUnix)]);
    },
    chartList() {
      if (this.isOverdue) {
        return [{ color: '#F9414D', value: 100 }];
      }

      const nowRefPoint = this.now - this.actualStart;
      const endRefPoint = this.changeoverEndUnix - this.actualStart;
      const passedValue = (nowRefPoint / endRefPoint) * 100;
      const leftValue = 100 - passedValue;
      return [
        { color: '#5886C1', value: passedValue },
        { color: '#C4C4C4', value: leftValue },
      ];
    },
  },
  methods: {
    loop() {
      this.now = this.$now;
    },
  },
  created() {
    this.loop();
  },
};
</script>

<style lang="scss" scoped>
  .duration-text {
    top: 0;
    left: 0;
    font-size: 10px;
    font-weight: 500;
  }
</style>
