<template>
  <div style="position: relative; overflow: hidden">
    <!-- Main chart -->

    <Tippy
      arrow
      theme="light"
      offset="[0, 15]"
      :trigger="hideLabels ? 'manual' : 'mouseenter'"
    >
      <template #trigger>
        <BarChart
          :list="dataset"
          :hide-labels="hideLabels"
          style="width: 100%; margin-top: 2px; text-align: center"
          :style="{ height }"
        />

        <!-- Estimated production icon -->
        <div
          v-if="estimated && planned"
          :style="{ left: `${estimatedRatio}%`, 'font-size': height }"
          class="icon"
        >
          <span
            :class="estimated ? productionColor({ done, estimated: Math.min(planned, estimated) }) : ''"
            class="caret"
          >
            <i class="fas fa-minus fa-rotate-90" />
          </span>
        </div>
      </template>
      <div
        class="text-left"
        style="max-width: 300px"
      >
        <div
          v-if="estimated"
          class="d-flex align-items-center"
          style="line-height: 1.1"
        >
          <div class="small flex-grow-1 pr-1">
            {{ $t('monitoring.lineInfo.toAchieveTarget') }}
          </div>
          <div class="text-nowrap font-weight-bold">
            {{ addSpaces(Math.floor(estimated)) }} {{ unit }}
          </div>
        </div>
        <div
          v-if="estimated"
          class="text-secondary small text-right"
        >
          ({{ $t('monitoring.ofThePlan', {value: ofThePlanRatio}) }})
        </div>
        <hr class="my-1">
        <div
          v-if="plannedRemaining"
          class="d-flex align-items-center mb-2"
          style="line-height: 1.1"
        >
          <div class="small flex-grow-1 pr-1">
            {{ $t('monitoring.remainingPlannedProduction') }}
          </div>
          <div class="text-nowrap font-weight-bold">
            {{ addSpaces(Math.floor(plannedRemaining)) }} {{ unit }}
          </div>
        </div>
        <div
          v-if="estimatedRemaining"
          class="d-flex align-items-center"
          style="line-height: 1.1"
        >
          <div class="small flex-grow-1 pr-1">
            {{ $t('monitoring.remainingEstimatedProduction') }}
          </div>
          <div class="text-nowrap font-weight-bold">
            {{ addSpaces(Math.floor(estimatedRemaining)) }} {{ unit }}
          </div>
        </div>
        <div
          v-if="remainingAvailableTime"
          class="text-secondary small text-right"
        >
          ({{ CalcDuration(1, remainingAvailableTime+1) }})
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
import { addSpaces } from '@core/utils/numbers';
import BarChart from '@/components/charts/BarChart';
import CalcDuration from '@/utils/calcDuration';
import productionColor from './productionColor';

export default {
  props: {
    done: Number,
    planned: Number,
    estimated: Number,
    wasted: Number,
    unit: String,
    pastAvailableTime: Number,
    remainingAvailableTime: Number,
    plannedRemaining: Number,
    estimatedRemaining: Number,
    height: {
      type: String,
      default: '17px',
    },
    hideLabels: Boolean,
  },
  components: {
    BarChart,
  },
  computed: {
    estimatedRatio() {
      return this.planned === 0 ? 100
        : Math.min(1, (this.estimated / this.planned)) * 100;
    },
    doneNorm() {
      if (!this.planned) {
        return 0;
      }
      const done = this.done || 0;
      return this.planned <= done || this.planned <= 0
        ? 100
        : Math.floor((100 * done) / this.planned);
    },
    wastedNorm() {
      if (!this.wasted || !this.planned) return 0;
      if (this.planned <= (this.done + this.wasted) || this.planned <= 0) {
        return 100 - this.doneNorm;
      }
      return Math.max(0.1, Math.floor((100 * this.wasted) / this.planned));
    },
    dataset() {
      return [
        {
          color: this.$color('blue'),
          label: this.planned
            ? `${Math.floor((this.done / this.planned) * 100)}%`
            : this.$t('monitoring.noPlan'),
          value: this.planned ? this.doneNorm : 0,
        },
        {
          color: this.$color('red'),
          value: this.planned ? this.wastedNorm : 0,
        },
        {
          color: '#e9e9e9',
          value: Math.max(0, 100 - (this.doneNorm + this.wastedNorm)),
        },
      ];
    },
    ofThePlanRatio() {
      const ratio = this.planned
        ? 100 * (this.done / Math.min(this.planned, this.estimated))
        : 0;
      return Math.round(ratio);
    },
  },
  methods: {
    productionColor,
    addSpaces,
    CalcDuration,
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars.icss';

  .icon {
    position: absolute;
    text-align: center;
    margin-left: -7px;
    top: 0;

    i {
      text-shadow: 0 0 4px rgba(100,100,100,0.5);
    }
  }

  .estimate-percent {
    position: absolute;
    top: 2px;
    font-size: 12px;
    right: 0;
    z-index: 10;
    width: 60px;
    text-align: left;

    div {
      position: relative;
      left: 10px;
    }

    .left-side {
      position: relative;
      left: -35px;
      text-align: right;

      .ratio {
        padding-right: 0.5rem;
        min-width: 40px;
        display: inline-block;
      }
    }
  }

  .icon .caret {
    position: relative;
    top: -3px;
    margin-left: -3px;
    font-size: 1.5em;
    line-height: 1;
  }
</style>
