export default {
  orders: 'Orders',
  order: 'Order',
  import: 'Import orders',
  completedOrders: 'Completed orders',
  inProgressOrders: 'Orders in progress',
  start: 'Start',
  removeLastEntryFirst: 'Remove last production declaration before confirming order production again',
  saveAndActivate: 'Save and activate',
  orderDetails: 'Order details',
  activate: 'Activate',
  stop: 'Stop',
  end: 'End',
  show: 'Show order',
  startOrder: 'Start order',
  reportResources: 'Report resources',
  confirmCurrentProduction: 'Confirm production',
  activateOrder: 'Activate order',
  abortOrderActivate: 'Abort activation',
  selectOrder: 'Select order',
  selectLineAndSku: 'Select line and SKU',
  selectSku: 'Select SKU',
  skuPerformance: 'SKU performance',
  abort: 'Abort',
  status: 'Status',
  number: 'Number',
  orderNumber: 'Order number',
  skuDescription: 'SKU Description',
  skuNumber: 'SKU Number', 
  skuId: 'SKU ID',
  orderId: 'Order ID',
  item: 'Item',
  sku: 'SKU',
  itemNo: 'Item number',
  schedule: 'Schedule',
  scheduled: 'Scheduled',
  plannedProduction: 'Planned production',
  realisation: 'Realisation',
  expectedStart: 'Expected\xa0start',
  expectedEnd: 'Expected\xa0end',
  toPlannedEnd: 'to planned end',
  toPlannedStart: 'to planned start',
  afterPlannedEnd: 'after planned end',
  afterPlannedStart: 'after planned start',
  expectedPerformance: 'Expected performance',
  description: 'Description',
  quantity: 'Produced',
  limitExceeded: 'Limit of orders exceeded, please stop or complete any running orders',
  production: 'Production summary',
  wasted: 'Wasted',
  updateProduction: 'Update production',
  totalProduction: 'Total production',
  incrementalProduction: 'Incremental production',
  productionUpdates: 'Production updates',
  complete: 'Complete',
  hold: 'Hold',
  completed: 'Completed',
  planned: 'Planned',
  lineConfig: 'Line configuration',
  config: 'Configuration',
  additional: 'Additional',
  settlement: 'Settlement',
  machinesForOrder: 'Select machines for this job',
  requiredComponentsNotScanned: 'Some components needs to be scanned first',
  firstActivate: 'Some components are required before start. Activate the order and scan the components first',
  productionStats: 'Production',
  comment: {
    startOrder: 'The order will start immediately. All scanned packages will be activated',
    activateOrder: 'The order will be prepared for start, flow can be still changed and some packages will need to be scanned before the start',
    completeOrder: 'The order will be completed and cannot be started again',
    stopOrder: 'The order will be stopped and can be started again later',
    abortOrderActivate: 'Activation will be aborted, order state will change back to \'Ready\'',
  },
  states: {
    inProgress: 'In\xa0progress',
    ready: 'Waiting',
    completed: 'Completed',
    other: 'Other',
    activated: 'Activated',
    onHold: 'On\xa0hold',
    taken: 'Taken',
  },
  activities: {
    addActivity: 'Plan new activity',
    editActivity: 'Edit activity',
    changeover: 'Changeover',
    stop: 'Stop by Operator',
    failure: 'Failure',
    lack: 'Lack of components and product',
    order: 'Order',
    idle: 'Idle',
    holdup: 'Holdup',
    inactive: 'Inactive',
    cip: 'CIP',
    completedJob: 'Completed job',
    inProgressJob: 'In progress job',
  },
  add: 'Add order',
  create: 'Create order',
  new: 'New order',
  edit: 'Edit order',
  orderedQuantity: 'Planned quantity',
  typeCorrectQuantity: 'Planned quantity must me greater than zero',
  quantityUnit: 'Quantity unit',
  type: 'Type',
  loadingItems: 'Loading items..',
  manage: 'Manage orders',
  noActiveOrPlannedOrders: 'No active or planned orders',
  noOrders: 'No orders',
  noProducts: 'No SKU',
  orderStarted: 'Order started',
  productionEnteredManually: 'Production and waste confirmed manually',
  manualProductionAdded: 'Manual production added',
  manualProductionOverwritten: 'Manual production overwritten',
  incrementalProduction: 'Increment',
  totalProduction: 'Total',
  release: 'Release',
  take: 'Take',
  finishOrder: 'Finish order',
  product: 'Product',
  plannedQuantity: 'Planned quantity',
  orderHistory: 'Order history',
  defaultConfiguration: 'Default configuration',
};
