export default {
  originalDates: 'Original dates',
  originalDuration: 'Original duration',
  newDates: 'New dates',
  newDuration: 'New duration',
  firstDowntime: '1st Downtime',
  secondDowntime: '2nd Downtime',
  split: 'Split',
  thisMachine: 'this machine',
  addComment: 'Add a comment',
  noComments: 'No comments',

  downtimeSummary: 'Downtime summary',
  step: 'Step {step}',
  selectRootLineMachine: 'Select place of a root cause',
  selectLineMachine: 'Select line/machine',
  selectState: 'Select state',
  selectProblem: 'Select problem',
  specifyAReason: 'Specify a reason',
  correctiveActionTaken: 'Corrective action taken',
  downtimeDuration: 'Downtime duration',
  vision: 'Vision',
  visionRequestClip: 'Request video',
  chooseACamera: 'Choose a camera',
  startOfAVideo: 'Start of a video',
  endOfAVideo: 'End of a video',
  requestVideo: 'Request video',
  oeeLossType: 'OEE Loss Type',
  lossType: 'Loss Type',

  filterAny: 'Any',
  hasClips: 'Has video clips',
  notHaveClips: 'Does not have video clips',
  hasCorrectiveAction: 'Has corrective action',
  notHaveCorrectiveAction: 'Does not have corrective action',
  hasReason: 'Has reason',
  notHaveReason: 'Does not have reason',
  hasComments: 'Has comments',
  notHaveComments: 'Does not have comments',
  noClipsRequested: 'No video clips requested',

  table: {
    downtime: "Downtime",
    details: "Details",
    time: "Time",
    duration: "Duration",
  },
}
