import axios from '@/api/axios';
import { getDeleterFor, getUpdaterFor } from '@core/utils/storeUpdater';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getWorkLogAnnotations',
    property: 'annotations',
    path: ({
      plantId, workLogId,
    }) =>
      `/v2/plant/${plantId}/worklog/${workLogId}/annotations`,
  })
  .get({
    action: 'getAnnotations',
    path: ({ plantId }) => `/v2/plant/${plantId}/annotations`,
  })
  .post({
    action: 'addAnnotation',
    path: ({ plantId }) => `/v2/plant/${plantId}/annotation`,
  })
  .delete({
    action: 'deleteAnnotation',
    path: ({
      plantId, elementId, timestamp, annotationId,
    }) =>
      `/v2/plant/${plantId}/element/${elementId}/annotation/${timestamp}/${annotationId}`,
  })
  .put({
    action: 'updateAnnotation',
    path: ({
      plantId, elementId, timestamp, annotationId,
    }) =>
      `/v2/plant/${plantId}/element/${elementId}/annotation/${timestamp}/${annotationId}`,
  })
  .getStore();

store.mutations.annotationUpdate = getUpdaterFor('annotations', 'id');
store.mutations.annotationDelete = getDeleterFor('annotations', 'id');

export default store;
