<template>
  <div class="px-1">
    <h4>
      {{ skuGroup && skuGroup.id ? skuGroup.name : $t("items.new") }}<br>
    </h4>
    <div>
      <div>
        <HorizontalMenu
          class="justify-content-center"
          :selected.sync="page"
          :list="pages"
          :small="true"
        />
      </div>
      <div>
        <div v-if="page === 'details'">
          <hr>
          <div style="max-width: 500px; margin: auto">
            <div class="label">
              {{ $t('general.name') }}
            </div>
            <BFormInput
              v-model="name"
              :state="skuGroupCheck() | validate(validated)"
              :placeholder="$t('general.name')"
            />
            <Error
              v-if="errorInput"
              class="text-center pt-1"
              :message="errorInput"
            />
          </div>
          <hr>
          <Error
            v-if="error"
            class="text-center pt-1"
            :message="error"
          />
          <SaveButton
            :show-remove="!!skuGroup"
            :remove-pending="removePending"
            :pending="pending"
            class="mt-3"
            @remove="remove"
            @cancel="$emit('close')"
            @save="submit"
          />
        </div>

        <div v-if="page === 'lines'">
          <ItemLineConfiguration
            class="mt-3"
            :sku-group-id="skuGroup.id"
            :imported-line-configurations="importedLineConfigurations"
            :show-import-result="true"
            @saved="showSaved()"
            @cancelled="$emit('close')"
          />
        </div>
      </div>
      <div v-if="page === 'packingStructure'">
        <PackingStructure
          :id="skuGroup.id"
          :imported-structures="importedStructures"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import ItemLineConfiguration from '@/components/item/ItemLineConfiguration';
import PackingStructure from './PackingStructure';

export default {
  props: {
    skuGroup: Object,
    importedStructures: Array,
    importedLineConfigurations: Array,
    groups: Array,
  },
  data: () => ({
    name: '',
    page: 'details',
    linePerf: {},
    removePending: false,
    pending: false,
    linePending: {},
    error: null,
    errorInput: null,
    validated: false,
  }),
  filters: {
    validate(value, v) {
      if (!v) return null;
      return value;
    },
  },
  components: {
    ItemLineConfiguration,
    PackingStructure,
    HorizontalMenu,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('plant', ['lines']),
    ...mapGetters('element', ['elementProperties']),
    time() {
      return [
        { value: 'h', text: 'h' },
        { value: 'min', text: 'min' },
        { value: 's', text: 's' },
      ];
    },
    pages() {
      const pagesList = [{
        key: 'details',
        name: this.$t('general.details'),
        icon: 'icon-sku',
      }];

      if (!this.skuGroup) return pagesList;

      return pagesList.concat([
        {
          key: 'lines',
          name: this.$t('items.lineConfiguration'),
          icon: 'fas fa-cogs',
        },
        {
          key: 'packingStructure',
          name: this.$t('items.packingStructure'),
          icon: 'fas fa-boxes',
        },
      ]);
    },
  },
  methods: {
    ...mapActions('element', ['getElementsProperties']),
    ...mapActions([
      'addSkuGroup',
      'updateSkuGroup',
      'deleteSkuGroup',
      'assignSkuGroupToLine',
      'removeGroupFromLine',
    ]),
    remove() {
      this.error = null;
      this.pending = true;
      this.deleteSkuGroup({
        params: {
          plantId: this.plantId,
          groupId: this.skuGroup.id,
        },
      })
        .then(() => {
          this.$emit('deleteSkuGroup');
          this.$emit('close');
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.removePending = false;
        });
    },
    validateModel() {
      return this.name?.length > 0;
    },
    nameExist() {
      return this.groups?.find(x => x.name === this.name);
    },
    submit() {
      this.error = null;
      this.errorInput = null;
      this.validated = true;
      if (!this.validateModel()) {
        this.errorInput = this.$t('error.wrongInput');
        return;
      }
      if (this.nameExist()) {
        this.errorInput = this.$t('error.nameExist');
        return;
      }
      if (this.skuGroup) {
        this.updateGroup();
      } else {
        this.addGroup();
      }
    },
    updateGroup() {
      this.pending = true;
      this.updateSkuGroup({
        params: {
          plantId: this.plantId,
          groupId: this.skuGroup.id,
        },
        data: {
          name: this.name,
        },
      })
        .then(() => {
          this.showSaved();
          this.validated = false;
          this.error = null;
          this.errorInput = null;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    addGroup() {
      this.pending = true;
      this.addSkuGroup({
        params: {
          plantId: this.plantId,
        },
        data: {
          name: this.name,
        },
      })
        .then(({ data }) => {
          this.showSaved();
          this.validated = false;
          this.error = null;
          this.errorInput = null;
          this.$emit('created', data);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    showSaved() {
      this.$bvToast.toast(this.$t('import.dataSaved'), {
        title: this.$t('import.saved'),
        autoHideDelay: 3000,
        variant: 'success',
        toaster: 'b-toaster-top-right',
      });
    },
    skuGroupCheck() {
      return this.name?.trim().length > 0 && !this.nameExist();
    },
  },
  created() {
    if (this.skuGroup) {
      this.name = this.skuGroup.name;
    }
  },
};
</script>

  <style
    lang="scss"
    scoped
  >
    .line-name {
    font-size: 16px;
    }

    .label {
    font-weight: bold;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 4px;
    margin-right: 20px;
   }

    .disabled {
    color: #e3dff5;
    cursor: pointer;
    }

    .anabled {
    border-bottom: 3px solid #046dbe;
    cursor: pointer;
    }

    .table th, .table td {
    padding: 0.3rem;
    vertical-align: middle;
    border: 0;
   }
  </style>
