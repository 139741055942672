import getMetaData from '@core/api/oidcMeta';

const config = ({ authority, publicPath }) => ({
  authority,
  client_id: 'packos_ui',
  redirect_uri: `${window.location.origin}${publicPath}oidc-callback`,
  response_type: 'code',
  scope: 'openid profile logix.api logix.auth logix.notify Logix.Vision.Cloud Logix.Hub offline_access',
  post_logout_redirect_uri: `${window.location.origin}${publicPath}`,
  silent_redirect_uri: `${window.location.origin}${publicPath}silent-renew-oidc.html`,
  accessTokenExpiringNotificationTime: 10,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  metadata: getMetaData(authority),
});

export const authorityStorageKey = 'packos:authority';
export const settings = {
  authority: localStorage.getItem(authorityStorageKey), // this is set in the start.js
  publicPath: process.env.VUE_APP_PUBLIC_PATH,
};

export default () => config(settings);
