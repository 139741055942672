export default {
  'originalDates': 'Dates d\'origine',
  'originalDuration': 'Durée d\'origine',
  'newDates': 'Nouvelles dates',
  'newDuration': 'Nouvelle durée',
  'firstDowntime': '1er temps d\'arrêt',
  'secondDowntime': '2e temps d\'arrêt',
  'split': 'Diviser',
  'thisMachine': 'cette machine',
  'addComment': 'Ajouter un commentaire',
  'noComments': 'Pas de commentaires',
  'downtimeSummary': 'Résumé des temps d\'arrêt',
  'step': 'Étape {step}',
  'selectRootLineMachine': 'Sélectionnez l\'emplacement d\'une cause principale',
  'selectLineMachine': 'Sélectionnez la ligne/la machine',
  'selectState': 'Sélectionnez l\'état',
  'selectProblem': 'Sélectionnez le problème',
  'specifyAReason': 'Précisez une raison',
  'correctiveActionTaken': 'Action corrective entreprise',
  'downtimeDuration': 'Durée d\'arrêt',
  'vision': 'Vision',
  'visionRequestClip': 'Demande de vidéo',
  'chooseACamera': 'Choisissez une caméra',
  'startOfAVideo': 'Début d\'une vidéo',
  'endOfAVideo': 'Fin d\'une vidéo',
  'requestVideo': 'Demande de vidéo',
  'oeeLossType': 'Type de perte OEE',
  'lossType': 'Type de perte',
  'filterAny': 'Tout',
  'hasClips': 'A des clips vidéo',
  'notHaveClips': 'N\'a pas de clips vidéo',
  'hasCorrectiveAction': 'A une action corrective',
  'notHaveCorrectiveAction': 'N\'a pas d\'action corrective',
  'hasReason': 'A une raison',
  'notHaveReason': 'N\'a pas de raison',
  'hasComments': 'A des commentaires',
  'notHaveComments': 'N\'a pas de commentaires',
  'noClipsRequested': 'Aucune demande de clips vidéo',
  'table': {
    'downtime': 'Temps d\'arrêt',
    'details': 'Détails',
    'time': 'Temps',
    'duration': 'Durée'
  }
};
