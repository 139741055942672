var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"position-relative my-2 text-center minimap",style:({
    height: `${_vm.height + 6}px`,
    width: `${_vm.graphWidth + 6}px`,
  }),on:{"mousedown":_vm.mouseDown}},[_vm._l((_vm.elements),function(e){return _c('div',{key:e.id,staticClass:"box position-absolute",style:({
      width: `${e.w}px`,
      height: `${e.h}px`,
      'line-height': `${e.h}px`,
      top: `${e.y + 3}px`,
      left: `${e.x + 3}px`,
      'border-color': _vm.colors[e.id]
    })},[_vm._v(" "+_vm._s(_vm.names[e.id])+" ")])}),_c('div',{staticClass:"scroller",style:({
      width: `${_vm.scrollWidth * _vm.graphWidth}px`,
      left: `${_vm.scrollLeft * _vm.graphWidth + (_vm.isMobile && 3)}px`
    })}),_c('Connections',{staticStyle:{"padding-top":"3px"},attrs:{"paths":_vm.paths,"connection-type":_vm.connectionType}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }