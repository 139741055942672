var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"timeaxis",staticClass:"position-relative"},[(!_vm.hideLabels)?_c('div',{staticClass:"position-relative",style:({
      'margin-left': _vm.leftOffset ? `${(_vm.width / _vm.steps.length) / 2}px` : null
    })},_vm._l((_vm.steps),function(step,index){return _c('span',{key:index,staticClass:"step",style:({ left: `${step.left}px` })},[_vm._v(" "+_vm._s(step.time)+" ")])}),0):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCursor),expression:"showCursor"}],ref:"cursor",staticClass:"step cursor"}),(_vm.showBackground)?_c('div',{ref:"grid-bg-wrapper",staticClass:"w-100"},_vm._l((_vm.steps),function(step,index){return _c('span',{key:'bg-' +step.timestamp,staticClass:"grid-bg",style:({
        left: `${step.left}px`,
        height: `${_vm.wrapperHeight}px`,
        top: `-${_vm.wrapperHeight}px`,
        width: _vm.getStepLength(step, index)
      })})}),0):_vm._e(),_vm._l((_vm.steps.slice(1)),function(step){return _c('span',{key:step.timestamp,staticClass:"grid-line",style:({
      left: `${step.left}px`,
      height: `${_vm.wrapperHeight}px`,
      top: `-${_vm.wrapperHeight}px`,
    })})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }