var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root-canvas"},[_c('div',{staticClass:"horizontal-toolbar"},[_c('div',{staticClass:"horizontal-navigation"},[_c('span',{staticClass:"navigation-button mr-2",on:{"click":function($event){return _vm.goRight()}}},[_c('i',{staticClass:"fas fa-angle-left"})]),_c('span',{staticClass:"navigation-button mr-2",on:{"click":function($event){return _vm.goRightPage()}}},[_c('i',{staticClass:"fas fa-angles-left"})]),_c('span',{staticClass:"navigation-button mr-2",on:{"click":function($event){return _vm.goLeftPage()}}},[_c('i',{staticClass:"fas fa-angles-right"})]),_c('span',{staticClass:"navigation-button",on:{"click":function($event){return _vm.goLeft()}}},[_c('i',{staticClass:"fas fa-angle-right"})]),_c('span',{staticClass:"horizontal-navigation-description"},[_vm._v(" "+_vm._s(_vm.axisDescription('x'))+" ")])]),_c('div',{staticClass:"heat-container"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('reports.decrease'))+" ")]),_c('div',{staticClass:"inner-heat"}),_c('span',{staticClass:"m-2"},[_vm._v(" "+_vm._s(_vm.$t('reports.increase'))+" ")])]),_c('div',{staticClass:"horizontal-navigation-right"},[_c('span',{staticClass:"navigation-button",class:{
          'disabled': _vm.zoomInHistory.length === 0
        },on:{"click":function($event){return _vm.goLevelUp()}}},[_c('i',{staticClass:"fas fa-search-minus"})])])]),_c('div',{staticClass:"outer-canvas"},[_c('BOverlay',{staticClass:"w-100 h-100",attrs:{"show":_vm.loadingCells,"spinner-variant":"primary","opacity":"0.5"}},[_c('div',{staticClass:"canvas"},[_c('div',{staticClass:"canvas-row legend",class:{animate: _vm.animateRows},style:({..._vm.xLegendStyle})},_vm._l((_vm.cols),function(col){return _c('div',{key:col,staticClass:"canvas-cell",style:({
              width: _vm.colsWidths[col - 1],
              ..._vm.colsStyles[col - 1],
            })},[_c('div',{staticClass:"canvas-cell-inner horizontal legend",class:{
                'small': _vm.scope === _vm.canvasScope.day,
                'hovered-legend': !_vm.zooming && col - 1 === _vm.hoveredX
              }},[_c('div',{staticClass:"text-center"},[(_vm.scope === _vm.canvasScope.month)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('time.week'))+"  ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.labels.x[col - 1])+" "),(_vm.subLabels.x[col - 1])?_c('p',{staticClass:"sub-labels small mb-0 text-black-50"},[_vm._v(" "+_vm._s(_vm.subLabels.x[col - 1])+" ")]):_vm._e()])])])}),0),_vm._l((_vm.rows),function(row){return (_vm.checkIfRenderRow(row - 1))?_c('div',{key:row,staticClass:"canvas-row",class:{animate: _vm.animateRows},style:({
            height: _vm.rowsHeights[row - 1],
            ..._vm.rowsStyles[row - 1]
          })},[_vm._l((_vm.cols),function(col){return (_vm.checkIfRenderCol(col - 1))?_c('div',{key:col,staticClass:"canvas-cell",class:{
              animate: _vm.animateCols,
              hoverable: !_vm.zooming && _vm.hoveredX === (col - 1)
                && !_vm.areTheSameUnix(col - 1, row - 1)
                && _vm.hoveredY === (row - 1)
                && !_vm.isInFuture(col - 1, row - 1),
              clickable: !_vm.zooming && _vm.scope !== _vm.canvasScope.day
                && !_vm.areTheSameUnix(col - 1, row - 1)
                && !_vm.isInFuture(col - 1, row - 1),
            },style:({
              width: _vm.colsWidths[col - 1],
              ..._vm.colsStyles[col - 1],
            }),on:{"click":function($event){return _vm.goLevelDown(col - 1, row - 1)}}},[(!_vm.zooming && _vm.hoveredX === (col - 1) && _vm.hoveredY === (row - 1)
                && !_vm.isInFuture(col - 1, row - 1))?_c('div',{staticClass:"hover-graph"},[_c('Apexcharts',{ref:"chart",refInFor:true,attrs:{"type":"bar","options":_vm.chartOptions,"series":_vm.getSeriesForChart(col - 1, row - 1),"height":225}})],1):_vm._e(),_c('div',{staticClass:"canvas-cell-bg"},[_c('div',{staticClass:"canvas-cell-inner",class:{animate: _vm.animateCols},style:({
                  backgroundColor: _vm.cellColor(col - 1, row - 1),
                }),on:{"mouseenter":function($event){return _vm.handleHover(col - 1, row - 1)},"mouseleave":function($event){return _vm.handleHover(null, null)}}},[(_vm.zooming[0] === col - 1 && _vm.zooming[1] === row - 1)?_c('div',{staticClass:"w-100 h-100"},_vm._l((_vm.zoomingRowsAmount),function(zoomingRow){return _c('div',{key:zoomingRow,staticClass:"canvas-row",class:{animate: _vm.animateCols},style:({
                      ..._vm.zoomingRowsStyles[zoomingRow - 1],
                    })},_vm._l((_vm.zoomingRowsAmount),function(zoomingCol){return _c('div',{key:zoomingCol,staticClass:"canvas-cell",class:{animate: _vm.animateCols},style:({
                        ..._vm.zoomingColsStyles[zoomingRow - 1],
                      })},[_c('div',{staticClass:"canvas-cell-bg"})])}),0)}),0):(!_vm.zooming
                    && !_vm.isInFuture(col - 1, row - 1)
                    && !_vm.isOutsideVisibleScope(col - 1, row - 1))?_c('div',{staticClass:"w-100 h-100"},[(_vm.areTheSameUnix(col - 1, row - 1))?_c('span'):(_vm.scope !== _vm.canvasScope.day)?_c('div',{staticClass:"cell-data"},[_c('span',{staticClass:"x-value"},[_vm._v(" "+_vm._s(_vm.calculatedValuesArray.x[col - 1])+" ")]),_c('span',{staticClass:"y-value"},[_vm._v(" "+_vm._s(_vm.calculatedValuesArray.y[row - 1])+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(_vm.calculateDifference(col - 1, row - 1)))+"%")])]):_vm._e()]):(_vm.isOutsideVisibleScope(col - 1, row - 1))?_c('div',{staticClass:"skeleton"}):_vm._e()])])]):_vm._e()}),_c('div',{staticClass:"canvas-cell",class:{animate: _vm.animateCols},style:({
              ..._vm.colsStyles[_vm.cols],
              ..._vm.yLegendStyle,
            })},[_c('div',{staticClass:"canvas-cell-inner vertical legend",class:{
                'small': _vm.scope === _vm.canvasScope.day,
                'hovered-legend': !_vm.zooming && row - 1 === _vm.hoveredY,
              }},[_c('div',{staticClass:"text-center"},[(_vm.scope === _vm.canvasScope.month)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('time.week'))+"  ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.labels.y[row - 1])+" "),(_vm.subLabels.y[row - 1])?_c('p',{staticClass:"sub-labels small mb-0 text-black-50"},[_vm._v(" "+_vm._s(_vm.subLabels.y[row - 1])+" ")]):_vm._e()])])])],2):_vm._e()})],2)]),_c('div',{staticClass:"vertical-toolbar"},[_c('div',{staticClass:"vertical-navigation"},[_c('span',{staticClass:"navigation-button mb-2",on:{"click":function($event){return _vm.goUp()}}},[_c('i',{staticClass:"fas fa-angle-up"})]),_c('span',{staticClass:"navigation-button mb-2",on:{"click":function($event){return _vm.goUpPage()}}},[_c('i',{staticClass:"fas fa-angles-up"})]),_c('span',{staticClass:"navigation-button mb-2",on:{"click":function($event){return _vm.goDownPage()}}},[_c('i',{staticClass:"fas fa-angles-down"})]),_c('span',{staticClass:"navigation-button mr-2",on:{"click":function($event){return _vm.goDown()}}},[_c('i',{staticClass:"fas fa-angle-down"})]),_c('span',{staticClass:"vertical-navigation-description"},[_vm._v(" "+_vm._s(_vm.axisDescription('y'))+" ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }