<template>
  <div class="list-taxonomy-selector pl-2">
    <BOverlay
      :show="loadingTaxonomies"
      opacity="0.5"
      spinner-variant="primary"
      spinner-small
    >
      <BFormGroup :label="$t('taxonomies.classification').toUpperCase()">
        <BFormRadio
          v-model="innerValue"
          value=""
          name="taxonomy"
          size="sm"
        >
          {{ $t('factoryStructure') }}
        </BFormRadio>
        <BFormRadio
          v-for="taxonomy in elementTaxonomies"
          :key="taxonomy.id"
          v-model="innerValue"
          :value="taxonomy.id"
          name="taxonomy"
          size="sm"
        >
          {{ taxonomy.description || taxonomy.code || '-' }}
        </BFormRadio>
      </BFormGroup>
    </BOverlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loadingTaxonomies: false,
  }),
  computed: {
    ...mapGetters(['plantId']),
    ...mapState({
      elementTaxonomies: state => state.taxonomies.elementTaxonomies,
    }),
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.list-taxonomy-selector {
  text-align: left;

  &:deep(legend) {
    font-size: 11px;
  }
}
</style>
