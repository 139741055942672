import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';
import activities from './activities';
import downtimes from './downtimes';

export default {

  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,

  'world': 'Mundo',
  'plant': 'Fábrica',
  'area': 'Area',
  'areas': 'Áreas',
  'line': 'Línea',
  'lines': 'Líneas',
  'delete': 'Borrar',
  'segment': 'Segmento',
  'machine': 'Máquina',
  'machines': 'Máquinas',
  'element': 'Elemento',
  'map': 'Mapa',
  'issue': 'Asunto',
  'typeOfIssue': 'Tipo de problema',
  'currentShift': 'Turno actual',
  'noShift': 'Sin turno',
  'currentOrder': 'Orden actual',
  'currentWeek': 'Semana actual',
  'today': 'Hoy',
  'shift': 'Cambio',
  'shifts': 'Turnos',
  'noShifts': 'Sin turnos asignados',
  'asset': {
    'globalCounter': 'Contador global',
    'localCounter': 'Contador local',
    'waterCounter': 'Contador de agua',
    'valvePosition': 'Posición de la válvula',
    'waterLevel': 'Nivel de agua',
    'waterFlow': 'Flujo de agua',
    'pumpBalance': 'Equilibrio de la bomba'
  },
  'wall': {
    'comment': 'Comentario',
    'comments': 'Comentarios',
    'vision': 'Visión',
    'downtimeEditorSettings': 'Configuración del editor de tiempo de inactividad'
  },
  'menu': {
    'monitoring': 'Vigilancia',
    'reports': 'Informes',
    'overview': 'Visión general',
    'andon': 'Visualización',
    'weeklyAnalysis': 'Análisis semanal',
    'scoreAnalysis': 'Análisis de puntuación',
    'settings': 'Ajustes',
    'userSettings': 'Configuraciones de la cuenta',
    'notifications': 'Notificaciones',
    'language': 'Idioma',
    'user': 'Usuario',
    'setAsHome': 'Establecer como página de inicio',
    'setFullScreen': 'Hacer pantalla completa',
    'logout': 'Cerrar sesión',
    'loadMore': 'Carga más',
    'selectPlant': 'Seleccionar planta',
    'activities': 'Actividades',
    'login': 'Acceso',
    'preview': 'Previo',
    'lineGraph': 'Gráfico de líneas',
    'homePageDevice': {
      'title': 'Página de inicio de este dispositivo',
      'description': 'Esta pantalla se mostrará siempre después de iniciar sesión en este dispositivo'
    },
    'homePageUser': {
      'title': 'Página de inicio de su cuenta',
      'description': 'Esta pantalla se mostrará después de su inicio de sesión si el dispositivo no tiene una página de inicio.'
    },
    'machineGraph': 'Gráfica de la máquina',
    'version': 'Versión'
  },
  'import': {
    'upload': 'Arrastra los archivos aquí para subirlos',
    'notSaved': 'No guardado',
    'saved': 'Salvado',
    'dataSaved': 'Datos guardados con éxito',
    'multiplierIsInvalid': 'Multiplicador está en conflicto con la conversión ya definida',
    'multiplierIsLessOrEqualZero': 'Multiplicador no puede ser menor o igual a cero',
    'actionRequired': 'Acción requerida',
    'checked': 'Verificado',
    'fillAndAdd': 'Llenar y agregar',
    'chooseImage': 'Elegir imagen',
    'nothingImported': 'No hay datos válidos para importar',
    'previousImports': 'Importaciones anteriores',
    'fileIsBeingProcessed': 'Se está procesando el archivo. Te enviaremos una notificación cuando esté lista',
    'groupSuccessfullyAssign': 'Asignado con éxito al grupo',
    'groupFailedToAssign': 'No se pudo asignar al grupo',
    'groupSuccessfullyRemoved': 'Eliminado correctamente del grupo',
    'groupFailedToRemove': 'No se pudo quitar del grupo',
    'noChanges': 'No hay cambios',
    'seeTemplates': 'Ver plantillas',
    'fileReview': 'Revisión de archivo',
    'templates': 'Plantillas',
    'addTemplate': 'Adicionar plantillas',
    'editTemplate': 'Editar Plantillas',
    'import': 'Importar',
    'uploadFile': 'Cargar archivo',
    'template': 'Plantilla',
    'toUploadFilePickTemplate': 'Para cargar archivo escoja la plantilla',
    'result': 'Resultado',
    'testFile': 'Archivo prueba',
    'toSeeResultUploadTestFile': 'Para ver el resultado cargue un archivo prueba',
    'toTestFileSaveChanges': 'Para probar el archivo guarde los cambios',
    'visible': 'Visible',
    'saveFunction': 'Función guardar',
    'key': 'Clave',
    'value': 'Valor',
    'oldValue': 'Antiguo valor',
    'newValue': 'Nuevo valor',
    'unchanged': 'Deshacer cambio',
    'status': {
      'applied': 'Exitoso',
      'appliedWithErrors': 'Aplicado con errores',
      'scheduled': 'Programado',
      'faulted': 'Con error',
      'processing': 'Procesando'
    }
  },
  'work': {
    'event': 'Problema',
    'events': 'Problemas',
    'state': 'Estado',
    'states': 'Estados',
    'systemStates': 'Estados del sistema',
    'reason': 'Razón',
    'reasons': 'Razones',
    'correctiveAction': 'Acción correctiva',
    'correctiveActions': 'Acciones correctivas',
    'downtime': 'Tiempo de inactividad',
    'downtimes': 'Tiempos de inactividad',
    'wiki': 'elementos wiki',
    'disconnected': 'desconectado',
    'rootCause': 'Causa principal',
    'detachRootCause': 'Separar la causa raíz',
    'rootMachine': 'Máquina de causa raíz',
    'spectrum': 'Espectro de trabajo',
    'downtimeList': 'Lista de tiempo de inactividad',
    'default': 'Valor por defecto',
    'tags': 'Etiquetas',
    'addTags': 'Agregar etiquetas',
    'addSelected': 'Agregar seleccionados',
    'plannedDowntimes': 'Tiempos de inactividad planificados',
    'unplannedDowntimes': 'Tiempos de inactividad no planificados',
    'workTime': 'Tiempo de trabajo',
    'actionTaken': 'Acción correctiva tomada',
    'noOngoingOrder': 'Sin orden en curso',
    'noOngoingShift': 'Sin turno en curso',
    'updateQueued': 'Tiempo de inactividad actualizado en cola. Esto puede tardar unos pocos segundos',
    'overrideQueued': 'Inserción de tiempo de inactividad en cola. Esto puede tardar unos pocos segundos',
    'noForcedState': 'No hay problemas definidos con la etiqueta \'Forzado\'. Agregue una definición de tiempo de inactividad con esta etiqueta, para detener la máquina o la línea',
    'downtimeSince': 'Tiempo de inactividad desde',
    'lasting': 'Duradero'
  },
  'general': {
    'name': 'Nombre',
    'from': 'De',
    'search': 'Buscar',
    'max': 'Max',
    'min': 'Min',
    'noResults': 'No hay resultados',
    'title': 'Título',
    'description': 'Descripción',
    'fromStart': 'desde el principio',
    'confirm': 'Confirmar',
    'tryAgain': 'Intentar otra vez',
    'to': 'Para',
    'toEnd': 'para terminar',
    'finish': 'Terminar',
    'add': 'Agregar',
    'all': 'Todo',
    'clear': 'Limpiar',
    'hidden': 'oculto',
    'remove': 'Eliminar',
    'inherited': 'Coversión automatica',
    'detach': 'Despegar',
    'attach': 'Adjuntar',
    'close': 'Cerrar',
    'cancel': 'Cancelar',
    'refresh': 'Actualizar',
    'lastRefresh': 'Última actualización: hace {0}',
    'reset': 'Resetear',
    'edit': 'Editar',
    'event': 'Evento',
    'details': 'Detalles',
    'collapseDetails': 'Ocultar detalles',
    'summary': 'Resumen',
    'save': 'Ahorrar',
    'change': 'Cambio',
    'noData': 'Sin datos',
    'nothingDefined': 'Nada definido',
    'others': 'Otros',
    'back': 'Atrás',
    'results': 'resultados',
    'selected': 'Seleccionado',
    'selectAll': 'Seleccionar todo',
    'createNew': 'Crear nuevo',
    'useExisting': 'Utilizar existente',
    'select': 'Seleccione',
    'saveSuccessed': 'Guardado con éxito',
    'saveFailed': 'Guardar fallido',
    'shouldArchive': 'Debería archivar',
    'areYouSure': 'Estas seguro?',
    'manHours': 'Horas hombre',
    'view': 'Vista',
    'yes': 'Si',
    'no': 'No',
    'reason': 'Razón',
    'actions': 'Comportamiento',
    'reasonUnknown': 'Razón desconocida',
    'forcedByOperator': 'Obligado por el operador',
    'type': 'Escribir',
    'valueType': 'Tipo de valor',
    'value': 'Valor',
    'unit': 'Unidad',
    'disable': 'Desactivar',
    'disabled': 'Desactivado',
    'disabledShe': 'Desactivado',
    'inactive': 'Inactivo',
    'enable': 'Habilitar',
    'enabled': 'Habilitado',
    'enabledShe': 'Habilitado',
    'ignore': 'Ignorar',
    'cancelled': 'Cancelar',
    'nominal': 'Nominal',
    'unsavedChanges': '¿De verdad quieres irte? ¡Usted tiene cambios no guardados!',
    'now': 'Ahora',
    'showAll': 'Mostrar todo',
    'showMore': 'Mostrar más',
    'continue': 'Continuar',
    'group': 'Grupo',
    'download': 'Descargar',
    'progress': 'Progreso',
    'dates': 'Fechas',
    'dir': {'top': 'Arriba', 'bottom': 'Fondo', 'left': 'Izquierdo', 'right': 'Derecho'},
    'sourceURI': 'URI de origen',
    'dimensions': 'Dimensiones',
    'status': 'Estado',
    'code': 'Código',
    'start': 'Inicio',
    'end': 'Fin',
    'update': 'Actualizar',
    'deviceId': 'ID del dispositivo',
    'time': 'Tiempo',
    'show': 'Show',
    'hide': 'Esconder',
    'last': 'Último',
    'requestReceived': 'Solicitud recibida',
    'deleted': 'Eliminado',
    'filters': 'Filtros',
    'total': 'Total',
    'restoreDefaults': 'Restaurar predeterminado',
    'setDate': 'Fecha fijada',
    'setTime': 'Tiempo fijado',
    'line': 'Linea',
    'errors': 'Errores',
    'next': 'Siguiente',
    'allSelected': 'Todo seleccionado',
    'unchecked': 'Sin verificar',
    'pickDate': 'Escoja una fecha',
    'option': 'Opción',
    'default': 'Por defecto (Default)',
    'set': 'Parametrizar',
    'apiUrls': 'Dirección API',
    'done': 'Realizado',
    'filterBy': 'Filtrar por',
    'apply': 'Aplicar',
    'clearAll': 'Borrar todo'
  },
  'machineControl': {
    'turnOn': 'Encender',
    'turnOff': 'Apagar',
    'current': 'Actual',
    'liveStream': 'Transmisión en vivo',
    'disconnectedWarning': '¡ADVERTENCIA! La máquina está actualmente desconectada; si se apaga, se perderán los datos almacenados en el búfer',
    'startMachine': 'Inicio máquina <b>{machine}</b>?'
  },
  'lineControl': {'startLine': 'Start linie <b>{line}</b>?', 'stop': 'Parar', 'start': 'Inicio'},
  'time': {
    'week': 'Semana',
    'minutes': 'Minutos',
    'date': 'Fecha',
    'day': 'Dia',
    'hour': 'hora',
    'hours': 'horas',
    'hoursPlural': 'horas',
    'days': 'dias',
    'month': 'Mes',
    'year': 'Año',
    'quarter': 'Cuarto',
    'resolution': 'Resolución',
    'duration': 'Duración',
    'seconds': 'Segundos',
    'startDate': 'Fecha de inicio',
    'endDate': 'Fecha final',
    'startTime': 'Hora de inicio',
    'endTime': 'Hora de finalización',
    'selectDay': 'Seleccionar día',
    'longerThan': 'Mas largo que',
    'shorterThan': 'Más corto que',
    'scope': 'Alcance de tiempo',
    'currentOrder': 'Orden actual',
    'showMinorStops': 'Mostrar paradas menores',
    'plannedOnly': 'Mostrar solo planeado',
    'unplannedOnly': 'Mostrar solo no planificado',
    'performanceOnly': 'Pérdidas de rendimiento',
    'availabilityOnly': 'Perdidas de disponibilidad',
    'utilizationOnly': 'Pérdidas de utilización',
    'excludeDefaults': 'Ocultar Valores por defecto',
    'monday': 'Lunes',
    'tuesday': 'Martes',
    'wednesday': 'Miercoles',
    'thursday': 'Jueves',
    'friday': 'Viernes',
    'saturday': 'Sabado',
    'sunday': 'Domingo',
    'plannedDate': 'Fecha planificada',
    'custom': 'Personalizado'
  },
  'graph': {
    'flow': 'Flujo',
    'flows': 'Flujos',
    'production': 'Flujo de producción',
    'physical': 'Físico',
    'supplying': 'Abastecimiento',
    'archiveChanges': 'Archivar cambios',
    'isActive': 'Activo',
    'addElement': 'Agregar elemento',
    'detached': 'Separado',
    'andon': 'Visualización',
    'noActiveFlow': 'Sin flujo activo'
  },
  'production': {'rawCounterIncreases': 'Valores del cojtador sin procesar en este periodo'},
  'monitoring': {
    'ofThePlan': 'Ha alcanzado el %{value}% de este valor',
    'noPlan': 'Ningún plan',
    'performanceSettingHistory': 'Historial de configuración de rendimiento',
    'unitConversionsHistory': 'Historial de conversiones de unidades',
    'averageTargetPerformance': 'Rendimiento objetivo medio',
    'reportParam': 'Parametrización reporte',
    'performanceType': 'Tipo de rendimiento',
    'designSpeed': 'Rendimiento de diseño',
    'validatedSpeed': 'Rendimiento validado',
    'targetProduction': 'Objetivo de producción',
    'maxPerfProduction': 'Plan basado en el desempeño',
    'plannedFromOrders': 'Plan basado en ordenes',
    'planned': 'Planificado',
    'notInFlow': 'No en el flujo de producción',
    'produced': 'Producido',
    'wasted': 'Desperdiciado',
    'lastMessage': 'Ultimo mensaje',
    'noMessages': 'Ningún mensaje',
    'noAlarms': 'Sin alarmas',
    'noConnectionInfo': 'No hay información sobre la conexión.',
    'noConnection': 'Sin conexión con el servidor',
    'processing': 'Procesando',
    'dataProcessingFromLast': 'Procesando datos del último',
    'lineStates': 'Estados de línea',
    'disconnected': 'desconectado',
    'serverDisconnected': 'Servidor desconectado',
    'working': 'laboral',
    'ready': 'listo',
    'notWorking': 'en tiempo de inactividad',
    'productionProgress': 'Progreso de la producción',
    'production': 'Producción',
    'goodCount': 'Cantidad buena',
    'wasteCount': 'Cantidad desperdicio',
    'productionMap': 'Mapa de producción',
    'workStates': 'Estados de trabajo',
    'workLog': 'Historial de trabajo',
    'event': 'Evento',
    'events': 'Eventos',
    'cause': 'Causa',
    'causes': 'Causas',
    'reasons': 'Razones',
    'warnings': 'Advertencias',
    'correctiveAction': 'Acción correctiva',
    'stopRealTime': 'Deténgase en tiempo real',
    'stoppedRealTime': 'El tiempo real se detuvo',
    'realTime': 'Tiempo real',
    'toRealTime': 'A tiempo real',
    'downtimes': 'Tiempos de inactividad',
    'changeCurrentDowntime': 'Cambiar parada',
    'doubleClickToEdit': 'Haga doble clic para editar el tiempo de inactividad',
    'percentShare': 'Participación porcentual',
    'timeShare': 'Tiempo compartido',
    'minorStops': 'incluyendo paradas menores',
    'remainingPlannedProduction': 'Posible producción restante basada en la velocidad nominal',
    'remainingEstimatedProduction': 'Posible producción restante en función de la velocidad efectiva',
    'remainingTime': 'Tiempo restante',
    'performance': {
      'short': 'Últimos {} de desempeño',
      'effective': 'Desempeño efectivo',
      'nominal': 'Rendimiento nominal'
    },
    'segment': {
      'baseQuantity': 'Cantidad base',
      'baseQuality': 'Calidad base',
      'baseAvailability': 'Disponibilidad base',
      'basePerformance': 'Rendimiento base'
    },
    'machine': {
      'counter': 'Contador',
      'parameters': 'Parámetros',
      'alarms': 'Alarmas',
      'processParameters': 'Parámetros de proceso',
      'currentParameters': 'Parámetros actuales',
      'activeAlarms': 'Alarmas activas',
      'counters': 'Contadores',
      'alarmsHoldups': 'Alarmas e interrupciones',
      'performanceInfo': 'Rendimiento medio desde el ultimo {0}',
      'others': 'Otros',
      'lifespan': 'Esperanza de vida',
      'environmentCondition': 'Condición ambiental',
      'condition': 'Condición del activo',
      'cost': 'Costo',
      'failurePreditcion': 'Predicción de fallas',
      'probabilityFailure': 'Probabilidad de falla dentro de los 10 días',
      'failureType': 'Tipo de falla',
      'timeToFailure': 'Tiempo previsto para fallar',
      'predictedFailure': 'Fallo previsto',
      'maintenanceHistory': 'Historial de mantenimiento'
    },
    'lineInfo': {
      'advices': 'Consejos',
      'forService': 'Tareas',
      'jobs': 'Trabajos',
      'completed': 'Trabajos realizados',
      'problems': 'Problemas',
      'planned': 'Trabajos en cola',
      'toAchieveTarget': 'Volumen de producción esperado en este momento',
      'confirmLinePause': 'Confirmar pausa de línea'
    },
    'worstAlarms': 'Peores problemas',
    'problems': 'sin problemas | un problema | {0} problemas',
    'overdue': 'Atrasado',
    'left': '{0} Restantes'
  },
  'components': {
    'title': 'Componentes',
    'one': 'Componente',
    'usagePerUnit': 'Uso por unidad',
    'required': 'Required',
    'convertUnit': 'Convertir unidad',
    'validation': 'Se requiere validación',
    'batchTracking': 'Seguimiento de lotes',
    'scanTrackingId': 'Numero de seguimiento escaneado',
    'batchTrackingHelp': 'Cuando el seguimiento está habilitado, debe proporcionar\\nnúmero de identificación de lote para cada paquete escaneado',
    'defaultPackageQuantity': 'En el paquete',
    'warningLevelPercentage': 'Porcentaje de advertencia',
    'warningLevel': 'Nivel de advertencia',
    'warningLevelHelp': '\\n %: advertencia cuando se consume un porcentaje del paquete\\n\\n mínimo: advertencia cuando quedan una cantidad estimada de minutos\\n',
    'scannerCode': 'Código del escáner',
    'scannerCodeType': 'tipo de código',
    'noTrackingId': 'Sin identificación de seguimiento',
    'scan': 'Escanear',
    'packages': 'Paquetes',
    'scanPackages': 'Escanear paquetes',
    'noComponents': 'Sin componentes asignados',
    'invalidCode': 'Componente no encontrado',
    'addComponent': 'Agregar componente',
    'closed': 'Cerrado',
    'scanned': 'Escaneado',
    'closeConfirm': 'Cerrar',
    'eta': 'Tiempo de llegada estimada',
    'active': 'Activo',
    'clickToActivate': 'Haga clic para activar',
    'used': 'Consumido',
    'estimatedTotalQuantity': 'Cantidad estimada necesaria',
    'packageScannerCode': 'Código de paquete',
    'defaultPackageQuantitySum': 'Cantidad total en paquetes',
    'registeredDate': 'Fecha de escaneo',
    'trackingId': 'ID de rastreo',
    'consumptionType': {
      'title': 'Tipo de consumo',
      'help': '"MULTIPLE" permite muchos paquetes abiertos a la vez, el primero se consume."SINGLE" cierra automáticamente el paquete anterior cuando se activa uno nuevo, el último es consumido.'
    }
  },
  'oee': {
    'oee': 'OEE',
    'oeeLoss': 'Pérdida OEE',
    'availability': 'Disponibilidad',
    'performance': 'Rendimiento',
    'quality': 'Calidad',
    'oeePercentage': 'OEE [%]',
    'availabilityPercentage': 'Disponibilidad [%]',
    'performancePercentage': 'Desempeño [%]',
    'teep': 'TEEP',
  },
  'metrics': {
    'mtbf': 'Tiempo medio entre fallos (MTBF)',
    'mttr': 'Tiempo medio de reparación (MTTR)',
    'mtbm': 'Tiempo medio entre mantenimiento (MTBM)',
    'reliabilityMetrics': 'Métricas de confiabilidad'
  },
  'energy': {
    'usedEnergy': 'Energía utilizada',
    'currentLeastEfficient': 'Máquina actualmente menos eficiente',
    'currentPower': 'Poder actual',
    'baseline': 'Línea Base',
    'max': 'Promedio máximo'
  },
  'items': {
    'items': 'Elementos',
    'import': 'Importar elementos',
    'itemGroups': 'Grupos de elementos',
    'itemGroup': 'Grupo de elementos',
    'inheritedFromGroup': 'Heredar desde la familia',
    'lineConfiguration': 'Configuración de línea',
    'noLineConfigured': 'No hay productos configurados para esta línea',
    'groups': 'Grupos',
    'add': 'Añadir elemento',
    'addGroup': 'Añadir grupo',
    'new': 'Nuevo elemento',
    'edit': 'Editar elemento',
    'number': 'Número',
    'description': 'Descripción',
    'quantity': 'Cantidad / palet',
    'unit': 'Unidad cuantitativa',
    'performance': 'Rendimiento',
    'expectedPerformance': 'Rendimiento esperado',
    'expectedChangeover': 'Rendimiento esperado',
    'packingStructure': 'Estructura de embalaje',
    'groupPackingStructure': 'Estructura de empaque grupal',
    'itemRoutingSpeed': 'Velocidad de enrutamiento de elementos',
    'groupRoutingSpeed': 'Velocidad de enrutamiento grupal',
    'components': 'Componentes',
    'item': 'Item'
  },
  'pageNotFoundExplanation': 'No podemos encontrar esta página,disculpas por las molestias.',
  'processParams': {
    'compositionName': 'Nombre de la composición',
    'compositions': 'Composiciones',
    'saveComposition': 'Guardar composición',
    'signals': 'Señales',
    'signalName': 'Nombre de la señal',
    'signalValue': 'Valor de la señal',
    'ongoingState': 'Estado en curso',
    'ongoingProblem': 'Problema continuo',
    'ongoingOrder': 'Orden en curso',
    'problemStart': 'Problema de inicio',
    'problemEnd': 'Fin del problema',
    'orderStart': 'Inicio de orden',
    'orderEnd': 'Fin de la orden',
    'orderProduction': 'Producción de ordenes'
  },
  'healthcheck': {'noDriverConnection': 'Sin conexión de controlador'},
  'vision': {
    'vision': 'Visión',
    'showAllClips': 'Mostrar todos los clips',
    'selectCamera': 'Seleccionar cámara',
    'getClip': 'Obtener clip',
    'getTheTwoPreviousMinutes': 'Obtenga 2 minutos antes y 2 minutos después de la hora de inicio del tiempo de inactividad',
    'noClips': 'No se encontraron clips',
    'noRecords': 'La cámara no tiene registros de ese día para descargar',
    'currentVideoTime': 'Tiempo de video actual',
    'startVideoTime': 'Hora de inicio del video',
    'startDowntime': 'Iniciar el tiempo de inactividad',
    'endDowntime': 'Fin del tiempo de inactividad',
    'showLiveVideo': 'Mostrar video en vivo desde la cámara',
    'selectStartClip': 'Seleccione iniciar video',
    'selectEndClip': 'Seleccionar final de video',
    'videoDurationIs': 'La duración del video es',
    'invalidTimeRange': 'Intervalo de tiempo no válido.',
    'toMoreBefore': 'Elija una fecha de inicio anterior para el video.',
    'toMoreAfter': 'Elija una fecha de finalización posterior para el video.',
    'maxVideoIs': 'La duración máxima del video es',
    'auto': 'A',
    'autoLong': 'Video solicitado automáticamente por el sistema',
    'manual': 'M',
    'manualLong': 'Video solicitado manualmente por el usuario.',
    'videoTrigger': 'Alternar carga automática de video',
    'autoDownload': 'Carga de video automática',
    'watchVideo': 'Vea el video'
  },
  'downloadStatus': {
    'failed': 'Fallido',
    'inProgress': 'En curso',
    'success': 'listo',
    'failedOnEdge': 'Falló la cámara',
    'noArchiveRecordings': 'La cámara no tiene grabaciones',
    'deleted': 'Eliminado'
  },
  'quests': 'Tareas',
  'quest': {
    'quest': 'Tarea',
    'questTitle': 'Título de la tarea',
    'questDescription': 'Descripción de la tarea',
    'project': 'Proyecto',
    'type': 'Tipo de tarea',
    'state': 'Estado de la tarea',
    'closeToState': 'Cerca del estado',
    'assignToGroups': 'Asignar a grupos',
    'taskDetails': 'Detalles de la tarea',
    'files': 'Archivos',
    'comments': 'Comentarios',
    'noFiles': 'Sin archivos'
  },
  'health': {'health': 'Salud', 'healthHistory': 'Historia de salud'},
  'panel': {
    'number': 'Número',
    'sku': 'SKU',
    'selectState': 'Seleccione estado',
    'selectMachine': 'Seleccionar maquina',
    'comment': 'Comentario',
    'downtimeFrom': 'Tiempo de inactividad de',
    'ongoing': 'En marcha',
    'averagePerformance': 'Rendimiento medio',
    'currentPerformance': 'Rendimiento actual',
    'currentLineStatus': 'Estado de línea actual',
    'provideReasonAndComment': 'Agregar razón y comentario',
    'commentPlaceholder': 'Escribe tu comentario',
    'confirmData': 'Confirmar fecha',
    'inputPlaceholder': 'Escriba aquí'
  },
  'workCalendar': 'Calendario laboral',
  'schedule': 'Calendario',
  'importSchedule': 'Programa de importación',
  'addSchedule': 'Agregar horario',
  'repeatEvery': 'Repite cada',
  'repeatOn': 'Repetir en',
  'ends': 'Termina',
  'starts': 'Empieza',
  'never': 'Nunca',
  'on': 'Sobre',
  'calendar': {'period': 'Período'},
  'cycle': 'Ciclo',
  'deleteRecurringInstance': 'Eliminar instancia recurrente',
  'editRecurringInstance': 'Editar instancia recurrente',
  'thisInstance': 'Esta instancia',
  'thisAndFollowingInstances': 'Esta y las siguientes instancias',
  'allInstances': 'Todas las instancias',
  'order': {
    'addNewProperty': 'Agregar nueva propiedad',
    'sortByDate': 'Ordenar por fecha',
    'sortBy': 'Ordenar por',
    'ascending': 'Ascendente',
    'descending': 'Descendente',
    'quantityProduced': 'Cantidad producida',
    'plannedProduction': 'Producción planificada',
    'endDate': 'Fecha final',
    'activatedDate': 'Fecha de activación',
    'totalTime': 'Duración de la orden',
    'plannedDate': 'Fecha de inicio',
    'eta': 'ETA',
    'delete': 'Limpiar filtro'
  },
  'noCycle': 'Sin definición de ciclo',
  'users': {'firstName': 'Nombre', 'lastName': 'Apellido'},
  'teams': {'teamName': 'Nombre del equipo'},
  'schedules': {'addLine': 'Agregar línea a los filtros', 'clearLines': 'Limpiar todas las líneas'},
  'chart': {'weekSymbol': 'S'},
  'factoryStructure': 'Estructura de la fábrica',
  'taxonomies': {
    'taxonomy': 'Taxonomía',
    'classification': 'Clasificación',
    'selectClassification': 'Seleccione clasificación',
    'selectClassificationLevel': 'Seleccione nivel de clasificación',
    'selectClassificationNodes': 'Seleccionar la clasificación de nodos',
    'defaultTaxonomy': 'Por defecto (Default)',
    'pickCategory': 'Escoger categoria',
    'selectTaxonomy': 'Seleccionar Jerarquía (Taxonomía)',
    'selectTaxonomyLevel': 'Seleccionar el nivel Jerarquico (Taxonomía)'
  },
  'menu_logoutQuestion': '¿Desea salir?'
};
