import colors from '@/utils/colors';

export function getHash(prod) {
  if (!prod || !prod.estimated) return colors('teal');
  const percent = (prod.done / prod.estimated) * 100;
  if (percent < 25) {
    return colors('red');
  } if (percent < 80) {
    return colors('yellow');
  }
  return colors('green');
}

export default prod => {
  if (!prod || !prod.estimated) return '';
  const percent = (prod.done / prod.estimated) * 100;
  if (percent < 25) {
    return 'text-danger';
  } if (percent < 80) {
    return 'text-orange';
  }
  return 'text-success';
};
