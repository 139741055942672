<template>
  <div>
    <div
      v-for="field in fields"
      :key="field.key"
      class="row pb-2"
      style="font-weight: 500"
    >
      <div
        class="col-6 good-text"
        :style="`${field.labelStyle}`"
      >
        {{ field.label }}
        <i
          v-if="field.tippy"
          v-tippy="{ arrow: true }"
          :content="`${field.tippy}`"
          class="fas fa-info-circle mx-2"
        />
      </div>
      <div class="col-2 good-text text-right">
        <span v-if="field.showSign && field.goodValue != 0">
          {{ field.goodValue > 0 ? '+' : '-' }}
        </span>
        {{ field.goodValue | counterValue }} {{ field.unit }}
      </div>
      <div class="col-3 text-danger text-right">
        <span v-if="field.showSign && field.wasteValue != 0">
          {{ field.wasteValue > 0 ? '+' : '-' }}
        </span>
        {{ field.wasteValue | counterValue }} {{ field.unit }}
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@core/utils/numbers';

export default {
  props: {
    fields: Array,
  },
  filters: {
    counterValue(v) {
      return round(Math.abs(v), 2);
    },
  },
};
</script>

<style lang="scss" scoped>
  .good-text {
    opacity: 0.85;
    color: gray;
  }
</style>
