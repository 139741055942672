<template>
  <ProductionHistoryChart
    :chart-data="chartData"
    :unit="production ? production.unit : ''"
    :hide-labels="hideLabelsAggregated"
    :show-legend="showLegend"
    @labels-width="$emit('labels-width', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import tinycolor from 'tinycolor2';
import { createLabels } from '../labels';
import { getHash } from './productionColor';

const ProductionHistoryChart = () => import('./ProductionHistoryChart');

export default {
  props: {
    production: Object,
    resolution: Object,
    check: {
      type: Object,
      default: () => ({
        produced: true,
        wasted: true,
        planned: true,
      }),
    },
    startDate: Number,
    endDate: Number,
    hideExpected: Boolean,
    showLegend: Boolean,
    hideLabels: Boolean,
  },
  data: () => ({
    plantTime: null,
  }),
  components: {
    ProductionHistoryChart,
  },
  computed: {
    ...mapGetters('plant', ['shifts']),
    ...mapGetters(['plantNow']),
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
    hideLabelsAggregated() {
      return this.hideLabels || this.labels.length >= 50;
    },
    labels() {
      return createLabels(this.series?.list || [],
        this.resolution.aggregate.length ? this.resolution.aggregate : ['day']);
    },
    datasets() {
      const datasets = this.hideExpected || (!this.check.planned) ? [] : [
        {
          label: this.$t('monitoring.planned'),
          borderColor: this.$color('orange'),
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 3,
          type: 'line',
          data: this.series.estimated,
        },
      ];

      if (this.check.produced) {
        const seriesColors = this.series.done.map((done, index) => {
          const estimated = this.series.estimated[index];
          if (!estimated) return '#fff';
          return getHash({ done, estimated });
        });
        datasets.push({
          label: this.$t('monitoring.produced'),
          backgroundColor: this.$color('teal'),
          data: this.series.done,
          datalabels: {
            color: '#333',
            borderColor: seriesColors,
            backgroundColor: seriesColors.map(c => tinycolor(c).setAlpha(0.4).toString()),
          },
        });
      }

      if (this.check.wasted) {
        datasets.push({
          label: this.$t('monitoring.wasted'),
          backgroundColor: this.$color('red'),
          data: this.series.wasted,
        });
      }

      return datasets;
    },
    series() {
      if (!this.production || !this.production.res) {
        return {
          done: [], estimated: [], wasted: [], list: [],
        };
      }
      const { res } = this.production;

      return {
        done: res.map(el => el.production.done),
        estimated: res.map(el => el.production.estimated),
        wasted: res.map(el => el.production.wasted),
        list: res,
      };
    },
  },
  created() {
    this.plantTime = this.plantNow(this.$now);
  },
};
</script>
