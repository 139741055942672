<template>
  <div
    style="font-weight: 500"
  >
    <div
      v-if="downtimes.length > 0"
      class="good-text"
    >
      {{ $t('activities.summary.stateGuard') }}
    </div>
    <div
      v-for="downtime in downtimes"
      :key="downtime.key"
      class="row pb-2"
      style="font-weight: 500"
    >
      <div
        class="col-6 good-text"
        style="padding-left: 50px"
      >
        {{ downtime.state.description }}
      </div>
      <div class="col-2 good-text text-right">
        <span v-if="downtime.goodCount === 0">
          0 {{ unit }}
        </span>
        <span v-else>
          -{{ downtime.goodCount }} {{ unit }}
        </span>
      </div>
      <div class="col-3 text-danger text-right">
        <span v-if="downtime.wasteCount === 0">
          0 {{ unit }}
        </span>
        <span v-else>
          -{{ downtime.wasteCount }} {{ unit }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    stateGuard: Object,
    unit: String,
  },
  computed: {
    ...mapGetters('work', ['workStateByName']),
    downtimes() {
      return Object.entries(this.stateGuard)
        .map(([key, value]) => ({
          state: this.workStateByName(key),
          ...value,
        }))
        .filter(x => x.goodCount > 0 || x.wasteCount > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
  .good-text {
    opacity: 0.85;
    color: gray;
  }
</style>
