<template>
  <Portal
    class="w-100 h-100"
    to="offcanvas"
    :disabled="!bindToBody"
  >
    <div
      class="offcanvas-wrapper"
      :class="{
        'shown': innerShow,
        'hiding': !completelyHidden,
        'passive': passive,
      }"
      @click.stop="handleOutsideClick"
    >
      <div
        class="offcanvas-content"
        :class="{
          'shown': innerShow,
          'pb-0': noBottomPadding,
          'passive': passive,
        }"
        :style="{
          paddingTop: `${paddingTop}px`
        }"
      >
        <slot />
      </div>
    </div>
  </Portal>
</template>

<script>
export default {
  name: 'Offcanvas',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    noBottomPadding: {
      type: Boolean,
      default: false,
    },
    bindToBody: Boolean,
    passive: Boolean,
  },
  data: () => ({
    completelyHidden: true,
    paddingTop: 66,
    throttler: null,
  }),
  computed: {
    innerShow: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
  },
  watch: {
    innerShow(v) {
      if (!v) {
        window.setTimeout(() => {
          this.completelyHidden = true;
        }, 300);
      } else {
        this.completelyHidden = false;
      }
    },
  },
  methods: {
    handleOutsideClick(ev) {
      if (ev.target.classList.contains('offcanvas-wrapper')) {
        this.innerShow = false;
      }
    },
    handleScroll() {
      let padding = 66 - window.scrollY;
      if (padding < 18) padding = 18;
      this.paddingTop = padding;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    if (this.show) this.completelyHidden = false;
  },
};
</script>

<style scoped lang="scss">
.offcanvas-wrapper {
  position: fixed;
  right: -100vw;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: rgba(217, 217, 217, 0.5);
  z-index: 1000;
  transition: opacity 0.2s;

  &.passive {
    width: unset;
  }

  &.hiding {
    right: 0;
  }

  &.shown {
    opacity: 1;
  }

  .offcanvas-content {
    transition: right 0.3s;
    position: absolute;
    right: -600px;
    width: 600px;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 14.9367px rgba(0, 0, 0, 0.1);
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

    &.passive {
      box-shadow: unset;
      border-left: 1px solid #ECECEC;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    &.shown {
      right: 0;
    }
  }
}
</style>
