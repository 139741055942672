import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,

  world: '世界',
  plant: '工厂',
  area: '区域',
  areas: '区域',
  line: '产线',
  lines: '产线',
  delete: '删除',
  segment: '碎片',
  machine: '机器',
  machines: '机器',
  element: '元素',
  map: '地图',
  issue: '问题',
  typeOfIssue: '问题类型',
  currentShift: '当前班次',
  noShift: '无班次',
  currentOrder: '当前订单',
  today: '今天',
  shift: '班次',
  shifts: '班次',
  noShifts: '未分配班次',
  asset: {
    globalCounter: '全球柜台', localCounter: '当地柜台', waterCounter: '水计数器', valvePosition: '阀位', waterLevel: '水位', waterFlow: '水流', pumpBalance: '泵平衡',
  },
  wall: {
    comment: '注释', comments: '注释', vision: '视图', downtimeEditorSettings: '停机时间编辑器设置',
  },
  menu: {
    monitoring: '监控', reports: '报告', overview: '概述', andon: '安灯', weeklyAnalysis: '周分析', scoreAnalysis: '分数分析', settings: '设置', userSettings: '帐号设定', notifications: '通知', language: '语言', user: '用户', setAsHome: '设为首页', setFullScreen: '全屏', logout: '登出', loadMore: '装载更多', selectPlant: '选择工厂', homePageDevice: { title: '此设备的主页', description: '在此设备上登录后将始终显示此页面' }, homePageUser: { title: '您帐户的主页', description: '如果设备没有主页，将在您登录后显示此屏幕' },
  },
  import: {
    upload: '将文件拖放到此处进行上传', notSaved: '未保存', saved: '已保存', actionRequired: '需要采取的行动', checked: '已验证', fillAndAdd: '填写并添加', chooseImage: '选择图片', nothingImported: '没有要导入的有效数据', previousImports: '以前的输入', fileIsBeingProcessed: '正在处理文件。 准备就绪后，我们会向您发送通知', groupSuccessfullyAssign: '已成功分配到组', groupFailedToAssign: '无法分配到组', groupSuccessfullyRemoved: '已成功从群组中移除', groupFailedToRemove: '无法从群组中移除',
  },
  work: {
    event: '问题', events: '问题', state: '状态', states: '状态', systemStates: '系统状态', reason: '原因', reasons: '原因', correctiveAction: '纠正措施', correctiveActions: '纠正措施', downtime: '停机时间', downtimes: '停机时间', wiki: '维基文章', disconnected: '断开连接', rootCause: '根本原因', detachRootCause: '分离根本原因', rootMachine: '产生根本原因的机器', spectrum: '工作范围', downtimeList: '停机清单', default: '默认', tags: '标签', addTags: '添加标签', addSelected: '添加所选项', plannedDowntimes: '计划停机时间', unplannedDowntimes: '计划外停机', workTime: '工作时间', actionTaken: '采取的纠正措施', noOngoingOrder: '没有正在进行的订单',
  },
  general: {
    name: '姓名',
    from: '从',
    search: '搜索',
    max: '最大',
    min: '最小',
    noResults: '没有结果',
    title: '标题',
    description: '描述',
    fromStart: '从头开始',
    confirm: '确认',
    tryAgain: '再试一次',
    to: '到',
    toEnd: '结束',
    finish: '完成',
    add: '添加',
    all: '全部',
    clear: '清除',
    hidden: '隐藏',
    remove: '消除',
    detach: '分离',
    attach: '附',
    close: '关闭',
    cancel: '取消',
    edit: '编辑',
    event: '事件',
    details: '细节',
    summary: '概括',
    save: '保存',
    change: '改变',
    noData: '没有数据',
    nothingDefined: '没有定义',
    others: '其他',
    back: '后退',
    results: '结果',
    selected: '已选',
    selectAll: '全选',
    createNew: '新建',
    useExisting: '利用现有的',
    select: '选择',
    saveSuccessed: '保存成功',
    saveFailed: '保存失败',
    shouldArchive: '应该存档',
    areYouSure: '你确定吗？',
    view: '视图',
    yes: '是',
    no: '否',
    reason: '原因',
    actions: '行动',
    reasonUnknown: '原因不明',
    forcedByOperator: '操作员强制',
    type: '类型',
    valueType: '价值类型',
    value: '价值',
    unit: '单元',
    disable: '禁用',
    disabled: '已禁用',
    disabledShe: '已禁用',
    inactive: '待用',
    enable: '使能够',
    enabled: '启用',
    enabledShe: '启用',
    ignore: '忽略',
    nominal: '标称',
    unsavedChanges: '你真的要离开吗？ 您有未保存的更改！',
    now: '现在',
    showAll: '显示所有',
    showMore: '展示更多',
    continue: '继续',
    group: '组',
    download: '下载',
    dir: {
      top: '顶部', bottom: '底部', left: '左边', right: '右边',
    },
    sourceURI: 'URI来源',
    dimensions: '维度',
    status: '状态',
    code: '代码',
    start: '开始',
    end: '结尾',
    update: '更新',
    deviceId: '设备编号',
    time: '时间',
    show: '显示',
    hide: '隐藏',
    last: '最后的',
  },
  machineControl: {
    turnOn: '开', turnOff: '关', current: '当前的', liveStream: '现场直播', disconnectedWarning: '警告！ 机器当前断开连接 - 关闭将导致缓冲数据丢失',
  },
  lineControl: { startLine: '开启生产线', stop: '停止', start: '开始' },
  time: {
    week: '星期', minutes: '分钟', date: '日期', day: '日', hour: '小时', hours: '小时', hoursPlural: '小时', days: '天', month: '月', year: '年', quarter: '季度', resolution: '分辨率', duration: '期间', seconds: '秒', startDate: '开始日期', endDate: '结束日期', startTime: '开始时间', endTime: '结束时间', selectDay: '选择日期', longerThan: '长于', shorterThan: '短于', scope: '时间范围', currentOrder: '当前订单', showMinorStops: '显示点点停', plannedOnly: '仅显示计划', unplannedOnly: '仅显示计划外', excludeDefaults: '隐藏默认值', monday: '周一', tuesday: '周二', wednesday: '周三', thursday: '周四', friday: '周五', saturday: '周六', sunday: '周日',
  },
  graph: {
    flow: '流程', flows: '流程', production: '生产流程', physical: '物理', supplying: '供应', archiveChanges: '存档更改', isActive: '活跃的', addElement: '添加元素', detached: '独立的', andon: '安灯',
  },
  monitoring: {
    ofThePlan: '您已达到此值的 %{value}%',
    noPlan: '没有计划',
    averageTargetPerformance: '平均目标绩效',
    designSpeed: '设计性能',
    validatedSpeed: '验证性能',
    targetProduction: '生产目标',
    maxPerfProduction: '基于绩效的计划',
    plannedFromOrders: '基于订单的计划',
    planned: '计划',
    notInFlow: '不在生产流程中',
    produced: '出品',
    wasted: '浪费',
    lastMessage: '最后一条消息',
    noMessages: '没有消息',
    noAlarms: '没有警报',
    noConnectionInfo: '没有关于连接的信息',
    noConnection: '与服务器无连接',
    processing: '处理',
    dataProcessingFromLast: '处理上一次至今的数据',
    lineStates: '产线状态',
    disconnected: '断开连接',
    serverDisconnected: '服务器断开连接',
    working: '工作',
    ready: '准备',
    notWorking: '在停机时间',
    productionProgress: '生产进度',
    production: '生产',
    productionMap: '生产图',
    workStates: '工作状态',
    workLog: '工作历史',
    event: '事件',
    events: '事件',
    cause: '原因',
    causes: '原因',
    reasons: '原因',
    warnings: '警告',
    correctiveAction: '纠正措施',
    stopRealTime: '停止实时',
    stoppedRealTime: '实时停止',
    realTime: '实时的',
    toRealTime: '到实时',
    downtimes: '停机时间',
    changeCurrentDowntime: '更改停机时间',
    doubleClickToEdit: '双击以编辑停机时间',
    percentShare: '百分比份额',
    timeShare: '分时',
    minorStops: '包括点点停',
    remainingPlannedProduction: '基于额定速度的可能剩余产量',
    remainingEstimatedProduction: '基于有效速度的可能剩余产量',
    remainingTime: '剩余时间',
    performance: { short: '最后 3 分钟的性能表现', effective: '有效表现', nominal: '标称表现' },
    segment: {
      baseQuantity: '基础数量', baseQuality: '基础质量', baseAvailability: '基础可用性', basePerformance: '基础表现指数',
    },
    machine: {
      counter: '计数器', parameters: '参数', alarms: '警报', processParameters: '工艺参数', currentParameters: '当前参数', activeAlarms: '当前警报', counters: '计数器', alarmsHoldups: '警报和滞留', performanceInfo: '过去 3 分钟的平均表现', others: '其他', lifespan: '寿命', environmentCondition: '环境条件', condition: '资产状况', cost: '成本', failurePreditcion: '故障预测', probabilityFailure: '10天内概率失败', failureType: '故障类型', timeToFailure: '预测失败时间', predictedFailure: '预测失败', maintenanceHistory: '维修历史',
    },
    lineInfo: {
      advices: '提示', forService: '任务', jobs: '工作', completed: '完成的工作', problems: '问题', planned: '作业排队', toAchieveTarget: '此时的预计产量：', confirmLinePause: '确认产线暂停',
    },
    worstAlarms: '最糟糕的问题',
    problems: '没有问题 | 一个问题 | {0} 个问题',
  },
  components: {
    title: '成分', one: '成分', usagePerUnit: '每单位用量', required: '必需的', convertUnit: '转换单位', validation: '需要验证', batchTracking: '批量跟踪', scanTrackingId: '扫描追踪号码', batchTrackingHelp: '启用跟踪后，您需要为每个扫描的包裹提供\\n 批标识号', defaultPackageQuantity: '包装内', warningLevelPercentage: '警告百分比', warningLevel: '警告级别', warningLevelHelp: '\\n % - 消耗一定百分比的包装时发出警告\\n\\n min - 估计剩余分钟数时发出警告\\n', scannerCode: '扫描码', scannerCodeType: '代码类型', noTrackingId: '没有跟踪码', scan: '扫描', packages: '包装', scanPackages: '扫描包装', noComponents: '没有分配的组件', invalidCode: '未找到组件', addComponent: '添加组件', closed: '关闭', scanned: '已扫描', closeConfirm: '关闭', eta: '预计到达时间', active: '活跃的', clickToActivate: '点击激活', used: '消耗', estimatedTotalQuantity: '预计所需数量', packageScannerCode: '包装代码', defaultPackageQuantitySum: '包装总数量', registeredDate: '扫描日期', trackingId: '跟踪码', consumptionType: { title: '消费类型', help: "\\n 'MULTIPLE' 允许一次打开多个包，第一个被消耗。\\n\\n 'SINGLE' 在激活新包时自动关闭前一个包，最后一个被消耗\\n" },
  },
  oee: {
    oee: '设备综合效率', oeeLoss: '设备综合效率损失', availability: '可用性', performance: '表现性', quality: '质量指数', teep: '产能利用率',
  },
  metrics: {
    mtbf: '平均故障间隔时间', mttr: '平均修复时间', mtbm: '平均维护间隔时间', reliabilityMetrics: '可靠性指标',
  },
  energy: {
    usedEnergy: '使用的能源', currentLeastEfficient: '目前效率最低的机器', currentPower: '电流功率', baseline: '基线', max: '平均最大值',
  },
  score: {
    points: '点', reached: '到达', target: '目标', failures: '失败', sameWeek: '与上周相同的位置',
  },
  items: {
    items: '项目', import: '输入项目', itemGroups: '项目组', itemGroup: '项目组', groups: '团体', add: '新增项目', addGroup: '添加群组', new: '新项目', edit: '编辑项目', number: '数字', description: '描述', quantity: '数量/托盘', unit: '数量单位', performance: '表现', expectedPerformance: '预期表现', expectedChangeover: '预期表现', packingStructure: '包装结构', groupPackingStructure: '分组包装结构', itemRoutingSpeed: '项目路由速度', groupRoutingSpeed: '组路由速度', components: '成分',
  },
  pageNotFoundExplanation: '我们找不到此页面，为给您带来的不便深表歉意',
  processParams: {
    compositionName: '组成名称', compositions: '组合物', saveComposition: '保存组合', signals: '信号', signalName: '信号名称', signalValue: '信号值', ongoingState: '持续状态', ongoingProblem: '持续存在的问题', ongoingOrder: '正在进行的订单', problemStart: '问题开始', problemEnd: '问题结束', orderStart: '订单开始', orderEnd: '订单结束', orderProduction: '订单生产',
  },
  healthcheck: { noDriverConnection: '没有驱动程序连接' },
  vision: {
    vision: '视图', showAllClips: '显示所有剪辑', selectCamera: '选择相机', getClip: '获取剪辑', getTheTwoPreviousMinutes: '获得开始时间前 2 分钟和开始时间后 2 分钟的停机时间', noClips: '未找到剪辑', noRecords: '相机没有当天的记录可供下载', currentVideoTime: '当前视频时间', startVideoTime: '开始视频时间', startDowntime: '开始停机', endDowntime: '结束停机时间', showLiveVideo: '显示来自相机的实时视频', selectStartClip: '选择开始视频', selectEndClip: '选择结束视频', videoDurationIs: '视频时长为', invalidTimeRange: '时间范围无效。', toMoreBefore: '为视频选择较早的开始日期。', toMoreAfter: '为视频选择一个较晚的结束日期。', maxVideoIs: '最大视频长度为', auto: '自动', autoLong: '系统自动请求的视频', manual: '手动', manualLong: '用户手动请求的视频', videoTrigger: '当选择时，VideoTrigger标签将为此问题类型配置',
    autoDownload: '为此类所有未来事件请求视频',
  },
  downloadStatus: {
    failed: '失败', inProgress: '进行中', success: '准备好', failedOnEdge: '照相失败', noArchiveRecordings: '照相没有记录', deleted: '已删除',
  },
  quests: '任务',
  quest: {
    quest: '任务', questTitle: '任务名称', questDescription: '任务描述', project: '项目', type: '任务类型', state: '任务状态', closeToState: '接近状态', assignToGroups: '分配给组',
  },
  health: { health: '健康', healthHistory: '健康史' },
  panel: {
    number: '数字', sku: '库存量单位', selectState: '选择状态', selectMachine: '选择机器', comment: '评论', downtimeFrom: '停机时间从', ongoing: '进行中', averagePerformance: '平均表现', currentPerformance: '当前表现',
  },
  workCalendar: '工作日历',
  schedule: '日程表',
  importSchedule: '输入日程表',
  addSchedule: '添加日程表',
  repeatEvery: '重复每',
  repeatOn: '重复在',
  ends: '结束',
  starts: '开始',
  never: '从不',
  on: '在',
  calendar: '日历',
  cycle: '循环',
  deleteRecurringInstance: '删除重复实例',
  editRecurringInstance: '编辑重复实例',
  thisInstance: '这个实例',
  thisAndFollowingInstances: '这个和以下实例',
  allInstances: '所有实例',
  order: { addNewProperty: '添加新属性' },
  noCycle: '无循环定义',
  users: { firstName: '名', lastName: '姓' },
  teams: { teamName: '队名' },
  schedules: { addLine: '将行添加到过滤器', clearLines: '清除所有行' },
};
