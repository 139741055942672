<template>
  <div
    class="notification-row"
    :class="action ? 'clickable' : ''"
    @click="action ? action() : false"
  >
    <div
      v-if="isClosed"
      v-tippy="{ arrow: true }"
      :content="`${$t('notify.outdated')} ${formatDate(closingDate)}`"
      class="outdated badge badge-pill badge-warning"
    >
      <i class="fas fa-bell-slash" />
    </div>
    <div
      v-else-if="!read"
      class="unread"
    />

    <div class="d-flex align-items-center w-100">
      <i
        v-if="icon"
        :class="`${icon} icon mr-3`"
      />

      <div class="w-100">
        <div class="d-flex justify-content-between">
          <div
            style="margin-top: -2px; margin-bottom: 4px"
            class="small font-weight-bold"
          >
            <span
              v-if="parentName(elementId)"
              class="text-secondary"
            >
              {{ parentName(elementId) }} /
            </span>
            {{ elementName(elementId) }}
          </div>
          <div class="created text-nowrap">
            {{ formatDate(created) }}
          </div>
        </div>
        <div class="title">
          {{ title }}
        </div>

        <div class="small pt-1">
          {{ details }}
        </div>

        <div
          v-if="isHint"
          class="d-flex mt-2"
          @mouseleave="suggestedRating = -1"
        >
          <p
            class="mb-0 small text-black-50"
            style="margin-top: 2px; min-width: 145px"
          >
            {{ $t('notify.howHelpful') }}
          </p>

          <i
            v-for="i in 5"
            :key="i"
            :class="{
              'far': i > hintRating,
              'fas text-primary': i <= hintRating,
              'highlighted': i <= suggestedRating
            }"
            class="fa-star rating-star cursor-pointer"
            @click.stop="hintRating = i; sendFeedback(i, hintRatingReason)"
            @mouseover="suggestedRating = i"
          />
        </div>

        <div
          v-if="isHint && isLowestHintRating"
          class="d-flex mt-2"
        >
          <p
            class="mb-0 small text-black-50"
            style="margin-top: 2px; min-width: 145px"
          >
            {{ $t('notify.whyNotHelpful') }}
          </p>

          <div>
            <BBadge
              v-for="entry in feedbackDictionary"
              :key="entry.id"
              class="hint-reason-badge text-black-50"
              :class="{'active': hintRatingReason && hintRatingReason.id === entry.id}"
              @click.stop="hintRatingReason = entry; sendFeedback(1, entry)"
            >
              {{ entry.content }}
            </BBadge>
          </div>
        </div>

        <div>
          <WorstInsight
            v-if="isWorstInsight"
            :data="data"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import WorstInsight from './insights/WorstInsight';

export default {
  props: {
    id: Number,
    elementId: String,
    title: String,
    details: String,
    read: Boolean,
    created: Number,
    closingDate: Number,
    data: Object,
    templateName: String,
    type: Number,
    action: Function,
    feedbackDictionary: Array,
    rating: Number,
    additionalFeedback: String,
  },
  data: () => ({
    hintRating: 0,
    hintRatingReason: '',
    suggestedRating: -1,
  }),
  components: {
    WorstInsight,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementName', 'parentName']),
    ...mapGetters('plant', ['structure']),
    ...mapGetters('notify', ['notificationTypes']),
    isWorstInsight() {
      const types = [
        'DurationInsight',
        'QuantityInsight',
        'DurationAndQuantityInsight',
      ];
      return types.includes(this.templateName);
    },
    isHint() {
      const hintIndex = 1;
      return this.type === this.notificationTypes[hintIndex].id;
    },
    isLowestHintRating() {
      return this.hintRating === 1;
    },
    icon() {
      const type = this.notificationTypes.find(x => x.id === this.type);
      return type ? type.icon : '';
    },
    isClosed() {
      return this.closingDate && this.closingDate * 1000 <= Date.now();
    },
  },
  methods: {
    ...mapActions('notify', ['sendNotificationFeedback']),
    formatDate(date) {
      if (!date) return '-';
      return moment(date * 1000).format('DD-MM-YYYY HH:mm');
    },
    sendFeedback(rating, additionalFeedback) {
      this.sendNotificationFeedback({
        data: {
          plantId: this.plantId,
          elementId: this.elementId,
          notificationId: this.id,
          notificationTimestamp: moment.unix(this.created).utc().format(),
          rating,
          additionalFeedback: additionalFeedback && rating === 1
            ? JSON.stringify(additionalFeedback)
            : '',
        },
      });
    },
  },
  created() {
    if (this.additionalFeedback) {
      try {
        this.hintRatingReason = JSON.parse(this.additionalFeedback);
      } catch {
        //
      }
    }

    this.hintRating = this.rating;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .rating-star {
    color: #aeaeae;

    &.highlighted {
      color: $blue;
    }
  }

  .notification-row {
    padding: 10px;
    box-shadow: 0 0 3px rgba(100, 100, 100, 0.2);
    margin-top: 8px;
    margin-bottom: 6px;
    line-height: 1.2;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    .icon {
      background: #CBDCF0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 28px;
      min-height: 28px;
      color: #5886C1;
      border-radius: 50px;
    }

    .hint-reason-badge {
      padding: 4px 9px;
      background-color: unset;
      border-radius: 50px;
      border: 1px solid #B0B0B0;
      margin-right: 3px;
      margin-bottom: 3px;
      cursor: pointer;

      &:hover {
        border-color: rgba(var(--primary), 0.6);
        color: var(--primary) !important;
      }

      &.active {
        border-color: var(--primary);
        color: white !important;
        background-color: var(--primary) !important;
      }
    }

    &.clickable {
      transition: all 300ms;
      cursor: pointer;

      &:hover {
        transform: translateX(-2px);
        box-shadow: 0 0 3px rgba(100, 100, 100, 0.2), 0 0 10px rgba(100, 100, 100, 0.2);
      }
    }

    .title {
      font-weight: bold;
    }
    .created {
      font-size: 11px;
      color: #aeaeae;
    }

    .unread {
      height: 12px;
      width: 12px;
      background-color: $ilabo;
      border-radius: 50%;
      position: absolute;
      right: -3px;
      top: -3px;
    }

    .outdated {
      position: absolute;
      right: -3px;
      top: -8px;
      font-size: 9px;
    }
  }
</style>
