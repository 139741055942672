<template>
  <div class="date-progress">
    <div class="progress">
      <div
        class="progress-bar"
        :style="{ width: `${dateProgress}%` }"
      />
    </div>
    <div
      class="dot"
      :style="{ left: `${dateProgress}%` }"
    />
  </div>
</template>

<script>
export default {
  props: {
    startDate: Number,
    endDate: Number,
  },
  computed: {
    dateProgress() {
      return ((this.$now - this.startDate)
        / (this.endDate - this.startDate)) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .date-progress {
    position: relative;

    .progress {
      height: 3px;
    }

    .progress-bar {
      background-color: $ilabo;
    }

    .dot {
      position: absolute;
      height: 5px;
      width: 5px;
      top: -1px;
      left: -5px;
      border-radius: 50%;
      border: 1px solid $ilabo;
      box-shadow: 0 0 3px rgba(100, 100, 100, 0.1);
    }
  }

</style>
