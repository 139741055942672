<template>
  <div style="min-height: 500px">
    <LiveElementData
      :ids="[lineId]"
      :split-by-shift="splitByShift"
      :split-by-order="splitByOrder"
    />
    <WorkCalendarData
      :date-from="yesterdayDayStart"
      :date-to="tomorrowDayStart"
    />

    <div
      v-if="haveData"
      class="mb-5 mt-2"
    >
      <div
        v-for="(group, index) in grouppedShifts"
        :key="index"
      >
        <h4 class="text-center mb-0">
          {{ group.title }}
        </h4>
        <div class="small text-center font-weight-bold mb-1">
          {{ group.date }}
        </div>

        <div class="row no-gutters justify-content-center pb-3 pt-2">
          <div
            v-for="shift in group.list"
            :key="`${shift.shiftId}:${index}`"
            class="col-auto align-self-center text-center position-relative"
            style="min-width: 200px; padding-bottom: 20px;"
          >
            <div class="shift-box">
              <span
                v-if="now && shift.startDate <= now && shift.endDate > now"
                class="in-progress text-success"
              >
                <i class="fas fa-play-circle" />
              </span>

              <p class="shift-name">
                {{ shift.name }}
              </p>
              <div class="shift-desc">
                {{ shift.description }}
              </div>
              <div class="position-relative">
                <TriangularOEEs
                  v-bind="shift.oee"
                  style="height: 165px; width: 265px"
                />
                <Tippy
                  v-if="shift.oee"
                  theme="light"
                  interactive
                  arrow
                  sticky
                  class="oee-info"
                >
                  <template #trigger>
                    <div class="oee-info">
                      <i class="fas fa-info-circle" />
                    </div>
                  </template>

                  <OEEComponentsInfo
                    v-bind="shift.oee.components"
                    :start-date="shift.startDate"
                    :end-date="shift.endDate"
                  />
                </Tippy>
              </div>
              <hr>
              <div class="px-3">
                <ProductionWithNumbers
                  :desc="false"
                  :production="shift.totalProduction"
                  :production-from-orders="false"
                />
              </div>
              <ProductionHistory
                v-if="production"
                :resolution="{ name: 'hour', aggregate: ['hour'] }"
                :start-date="shift.startDate"
                :end-date="shift.endDate"
                :production="shift.production"
                :hide-labels="true"
                :show-legend="false"
                style="height: 150px; width: 250px; margin-top: 20px"
              />
              <Loader
                v-else
                color="teal"
              />
            </div>

            <div
              v-if="index+1 !== shiftData.length"
              class="shift-arrow"
            >
              <img src="@/assets/arrow.png">
              <img
                class="left-arrow"
                src="@/assets/arrow.png"
              >
              <img
                class="right-arrow d-none d-sm-inline"
                src="@/assets/arrow.png"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import loopRequest from '@/mixins/loopRequest';
import TriangularOEEs from '@/components/charts/oees/TriangularOEEs';
import ProductionHistory from '@/components/charts/production/ProductionHistory';
import LiveElementData from '@/components/data/LiveElementData';
import ProductionWithNumbers from '@/components/charts/production/ProductionWithNumbers';
import OEEComponentsInfo from '@/components/reports/oee/OEEComponentsInfo';
import WorkCalendarData from '@/components/data/WorkCalendarData';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    production: null,
    haveData: false,
    oees: null,
    now: null,
  }),
  components: {
    ProductionWithNumbers,
    WorkCalendarData,
    TriangularOEEs,
    ProductionHistory,
    LiveElementData,
    OEEComponentsInfo,
  },
  mixins: [
    loopRequest(['requestOEE', 'requestProduction']),
    loopRequest('updateNow', 1000),
  ],
  computed: {
    ...mapGetters([
      'userDayStart',
      'plantId',
      'startDate',
      'endDate',
      'performanceType',
      'splitByShift',
      'splitByOrder',
      'shiftInstances',
      'dayStartOffsetInUserTimezone',
    ]),
    ...mapGetters('plant', ['displayUseValidatedSpeed']),
    yesterdayDayStart() {
      return moment.unix(this.userDayStart).subtract(1, 'day').unix();
    },
    dateFrom() {
      const firstShift = [...this.shiftInstances]
        .sort((a, b) => a.endDate - b.endDate)
        .find(s => s.startDate >= this.yesterdayDayStart || s.endDate >= this.yesterdayDayStart);
      return firstShift?.startDate || this.yesterdayDayStart;
    },
    tomorrowDayStart() {
      return moment.unix(this.userDayStart).add(1, 'day').unix();
    },
    dateTo() {
      return this.tomorrowDayStart;
    },

    shiftData() {
      return this.shiftInstances
        .filter(a => a.elementId === this.lineId)
        .map(o => {
          const production = this.getProductionFor(o);
          const oee = this.getOeesFor(o);

          return {
            production,
            ...o,
            oee,
            totalProduction: {
              done: oee.components?.production,
              wasted: oee.components?.waste,
              planned: oee.components?.expectedProduction,
              unit: oee.components?.productionUnit,
            },
            date: moment(o.startDate * 1000).format('DD MMMM'),
            description:
              `( ${this.dayRelative(o.startDate)}
            ${moment(o.startDate * 1000).format('HH:mm')} -
            ${moment(o.endDate * 1000).format('HH:mm')})`,
          };
        })
        .sort((a, b) => a.startDate - b.startDate);
    },
    grouppedShifts() {
      return [
        {
          title: this.$t('reports.selectors.today'),
          date: moment.unix(this.userDayStart).format('DD MMMM'),
          list: this.shiftData.filter(({ startDate }) => this.isToday(startDate) && startDate < moment().unix()),
        },
        {
          title: this.$t('reports.selectors.yesterday'),
          date: moment.unix(this.userDayStart).subtract(1, 'd').format('DD MMMM'),
          list: this.shiftData.filter(({ startDate }) => !this.isToday(startDate) && this.isYesterday(startDate)),
        },
      ];
    },
  },
  watch: {
    performanceType() {
      this.haveData = false;
      this.requestOEE();
      this.requestProduction();
    },
  },
  methods: {
    moment,
    ...mapActions(['getOees', 'getProduction']),
    isToday(startDate) {
      return moment.unix(this.userDayStart).isSameOrBefore(startDate * 1000);
    },

    isYesterday(startDate) {
      return moment.unix(this.userDayStart).subtract(1, 'd').isSameOrBefore(startDate * 1000);
    },

    dayRelative(startDate) {
      if (this.isToday(startDate)) {
        return this.$t('reports.selectors.today');
      }
      return this.$t('reports.selectors.yesterday');
    },

    getProductionFor({ startDate, shiftId }) {
      if (!this.production || !this.production.res) return null;
      const shiftProduction = this.production.res
        .filter(p => p.shiftSplit
          && p.shiftSplit.shiftId === shiftId
          && moment.utc(p.shiftSplit.shiftInstanceStart).unix() === startDate);

      return {
        unit: this.production.unit,
        res: shiftProduction,
      };
    },

    getOeesFor({ startDate, shiftId }) {
      const elem = this.oees.find(b =>
        b.shiftSplit
        && b.shiftSplit.shiftId === shiftId
        && moment.utc(b.shiftSplit.shiftInstanceStart).unix() === startDate);

      return elem ? elem.oee : {};
    },

    requestOEE() {
      const {
        plantId, lineId,
      } = this;

      return this.getOees({
        params: {
          plantId,
          startDate: this.yesterdayDayStart,
          endDate: this.tomorrowDayStart,
          elementId: lineId,
          query: {
            aggregate: ['shiftInstance'],
            performanceType: this.performanceType,
          },
        },
      })
        .then(({ data }) => {
          this.oees = data.oees;
          this.haveData = true;
        });
    },
    requestProduction() {
      return this.getProduction({
        params: {
          plantId: this.plantId,
          startDate: this.yesterdayDayStart,
          endDate: this.tomorrowDayStart,
          elementId: this.lineId,
          query: {
            aggregate: ['shift', 'hour'],
            performanceType: this.performanceType,
          },
        },
      })
        .then(({ data }) => {
          this.production = data;
        });
    },
    updateNow() {
      this.now = this.$now;
    },
  },
  created() {
    this.updateNow();
    this.haveData = false;
    this.$emit('pageChange', 'shifts');
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .shift-box {
    border-left: 1px solid rgba(100, 100, 100, 0.2);
    border-right: 1px solid rgba(100, 100, 100, 0.2);
    background-color: white;
    border-radius: 20px;
    padding: 10px 5px;
    margin: 0 25px;
    position: relative;
    z-index: 2;

    .shift-name {
      font-size: 20px;
      margin-bottom: 0;
      line-height: 1;
    }

    .shift-desc {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .oee-info {
    position: absolute;
    left: 5px;
    top: 5px;
    color: $grey;
    width: 20px;
  }

  .shift-arrow {
    right: -15px;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -25px;

    img {
      height: 50px;
    }

    .left-arrow {
      position: absolute;
      right: 25px;
      top: 0;
    }

    .right-arrow {
      position: absolute;
      right: -25px;
      top: 0;
    }
  }

  .in-progress {
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>
