export default {
  'nothing': 'Sin notifiaciones',
  'list': 'Lista',
  'subscribedOnly': 'Solo suscrito',
  'subscription': 'Suscripción',
  'addSubscription': 'Agregar suscripción',
  'notificationType': 'Tipo de notificación',
  'pushNotSupported': 'Notificaciones no admitidas en este entorno',
  'pushDenied': 'Se han bloqueado las notificaciones',
  'enablePush': 'Habilitar notificaciones push',
  'pushDisabled': 'Notificaciones push deshabilitadas',
  'pushEnabled': 'Notificaciones push habilitadas',
  'outdated': 'Sice anticuado',
  'wastedThreshold': 'Umbral de residuos',
  'threshold': 'Umbral',
  'mailing': 'Recibir por correo electrónico',
  'mySubscriptions': 'Mis Suscripciónes',
  'usersSubscriptions': 'Suscripciones de usuarios',
  'teamsSubscriptions': 'Suscripciones de equipos',
  'numberOfSubs': 'Numero de suscripciones',
  'subscriptionFromTeam': 'Esta suscripción proviene del equipo y solo puede ser editada para todo el equipo por un usuario con los permisos adecuados.',
  'type': {'hints': 'Consejos', 'warnings': 'Advertencias', 'userNotifications': 'Notificaciones'},
  'name': {
    'cmmsNotification': 'Servicio de mantenimiento',
    'connectionLost': 'Se perdió la conexión de la máquina',
    'availabilityInsight': 'Información sobre disponibilidad',
    'ongoingStop': 'Parada de línea en curso',
    'newDowntime': 'Nuevo tiempo de inactividad',
    'orderNotifications': 'Cambio de estado de la orden',
    'thresholdExceeded': 'Umbral excedido',
    'oeeThreshold': 'OEE por debajo del objetivo',
    'orderProductionDone': 'Producción de ordenes lograda',
    'orderProductionNotAchieved': 'orden cerrado con producción por debajo dla orden',
    'orderDelayed': 'orden retrasado',
    'performanceThreshold': 'Rendimiento de la máquina inferior al esperado',
    'orderWastedThresholdExceeded': 'Se superó el umbral de desperdicio de ordenes',
    'selectedLines': 'Líneas seleccionadas',
    'allLines': 'Todas las lineas',
    'ignoredCounter': 'Ignorar contador',
    'oeeInfo': 'Resumen de OEE'
  },
  'timePeriod': {
    'none': 'No',
    'immediately': 'Inmediatamente',
    'hourly': 'En un resumen de notificaciones por horas',
    'daily': 'En un resumen de notificación diario'
  }
};
