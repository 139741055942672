var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showChart)?_c('StatePareto',{attrs:{"clickable":false,"pareto-states":_vm.paretoStates}}):_vm._e(),(!_vm.hideRows)?_c('div',{staticClass:"pt-1"},[_vm._l((_vm.paretoStates),function(state){return _c('div',{key:state.workStateId,on:{"click":function($event){return _vm.selectState(state)}}},[_c('div',{staticClass:"state-type-row d-flex no-gutters justify-content-between align-items-center px-1",class:{
          active: _vm.isSelected(state),
          sel: _vm.selectable,
        }},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ arrow: true }),expression:"{ arrow: true }"}],staticClass:"text-right d-none d-sm-inline px-1",staticStyle:{"width":"45px"},attrs:{"content":_vm.$t('monitoring.percentShare')}},[_vm._v(" "+_vm._s(_vm._f("integer")(state.timeInPercents * 100))+"% ")]),_c('div',{staticClass:"col d-flex pl-1"},[_c('StateBadge',{staticClass:"d-block",attrs:{"state":state}})],1),_c('div',{staticClass:"col text-right"},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ arrow: true }),expression:"{ arrow: true }"}],attrs:{"content":_vm.$t('monitoring.timeShare')}},[_c('span',[_vm._v(_vm._s(_vm.CalcDuration(1, state.timeInSeconds + 1)))])])])]),(_vm.anyMinorStops && state.minorStopsTimeInSeconds > 0)?_c('div',{staticStyle:{"padding-left":"10px"}},[_c('div',{staticClass:"state-type-row d-flex no-gutters justify-content-between align-items-center px-1",class:{
            active: _vm.isSelected(state),
            sel: _vm.selectable,
          },staticStyle:{"font-size":"11px"}},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ arrow: true }),expression:"{ arrow: true }"}],staticClass:"text-right d-none d-sm-inline px-1",staticStyle:{"width":"35px"},attrs:{"content":_vm.$t('monitoring.percentShare')}},[_vm._v(" "+_vm._s(_vm._f("integer")(state.minorStopsTimeInPercents * 100))+"% ")]),_c('div',{staticClass:"d-flex align-items-center col pl-1"},[_c('div',{staticClass:"mr-2"},[_c('StateBadge',{staticClass:"d-block",attrs:{"state":state}})],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('reports.minorStops'))+" ")])]),(_vm.anyMinorStops)?_c('div',{staticClass:"col text-nowrap text-right pr-1"},[_vm._v(" "+_vm._s(_vm.CalcDuration(1, state.minorStopsTimeInSeconds + 1))+" ")]):_vm._e()])]):_vm._e()])}),(_vm.startDate && _vm.endDate && _vm.elementId && _vm.orderId)?_c('div',{staticClass:"text-center mt-2"},[_c('a',{staticStyle:{"color":"#888888","text-decoration":"underline"},attrs:{"href":_vm.trackingLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('reports.seeDowntimesReport'))+" ")])]):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }