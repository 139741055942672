<template>
  <BAlert
    show
    class="mb-0"
    variant="primary"
  >
    <div class="d-flex">
      <i class="fas fa-info-circle mr-2 mt-1" />

      <p
        class="mb-0"
        style="font-size: 14px"
      >
        {{ $t('activities.productionReconciliation.arithmeticExpressionsInfoBoxText') }}
      </p>
    </div>
  </BAlert>
</template>

<script>
export default {
  name: 'ProductionReconciliationFormInputFieldsInfo',
};
</script>

<style scoped>

</style>
