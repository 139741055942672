<template>
  <div>
    <div class="label">
      {{ $t('activities.resources.timeScope') }}
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <BFormGroup>
          <BFormRadioGroup
            :checked="incremental"
            button-variant="outline-primary"
            name="radio-btn-outline"
            size="sm"
            buttons
            @change="$emit('update:incremental', $event)"
          >
            <BFormRadio
              v-for="option in productionOptions"
              :key="option.text"
              :value="option.value"
            >
              <span class="py-2 px-2">
                <i
                  :class="{
                    'fas fa-chart-pie': option.value === true,
                    'fas fa-calendar': option.value === false
                  }"
                  class="pr-1"
                />
                {{ option.text }}
              </span>
            </BFormRadio>
          </BFormRadioGroup>
        </BFormGroup>
      </div>
      <div>
        <BButton
          v-if="currentHour"
          size="sm"
          variant="outline-secondary"
          @click="editDates({ endDate: currentHour })"
        >
          {{ $t('activities.schedule.currentHour') }}
        </BButton>
        <BButton
          v-else-if="lastHour"
          size="sm"
          variant="outline-secondary"
          @click="editDates({ endDate: lastHour })"
        >
          {{ $t('activities.schedule.previousHour') }}
        </BButton>
        <BButton
          v-if="currentShift"
          size="sm"
          variant="outline-secondary"
          class="mx-2"
          @click="setCurrentShift"
        >
          {{ $t('activities.schedule.currentShift') }}
        </BButton>
        <BButton
          v-else-if="lastShift"
          size="sm"
          variant="outline-secondary"
          class="mx-2"
          @click="setLastShift"
        >
          {{ $t('activities.schedule.previousShift') }}
        </BButton>
      </div>
    </div>

    <div
      style="padding: 60px 40px 20px"
    >
      <ProductionUpdatesLog
        v-if="logEnd"
        :start-date="scopeStartUnix"
        :end-date="scopeEndUnix"
        :blocked-until="blockedUntil"
        :log="timeLog"
        :incremental="incremental"
        :unit="unit"
        @applyEdit="editDates"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ProductionUpdatesLog from './resources/ProductionUpdatesLog';

export default {
  props: {
    scopeStart: {
      type: String,
      required: true,
    },
    scopeEnd: {
      type: String,
      required: true,
    },
    blockedUntil: String,
    done: {
      type: Number,
      default: 0,
    },
    wasted: {
      type: Number,
      default: 0,
    },
    totalManhours: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: '',
    },
    type: String,
    lineId: {
      type: String,
      required: true,
    },
    incremental: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    slider: [0, 10],
    logEnd: null,
  }),
  components: {
    ProductionUpdatesLog,
  },
  computed: {
    ...mapGetters(['shiftInstances']),
    lastShift() {
      const blockedUntilUnix = moment(this.blockedUntil).unix();
      return this.shiftInstances
        .filter(x => x.elementId === this.lineId
          && x.startDate < moment().unix()
          && x.endDate < moment().unix()
          && x.endDate > blockedUntilUnix)
        .sort((a, b) => a.endDate - b.endDate)[0];
    },
    currentShift() {
      const blockedUntilUnix = moment(this.blockedUntil).unix();
      return this.shiftInstances
        .filter(x => x.elementId === this.lineId
          && x.startDate < moment().unix()
          && x.endDate < moment(this.scopeEnd).unix()
          && x.endDate >= moment().unix()
          && x.endDate > blockedUntilUnix)
        .sort((a, b) => a.endDate - b.endDate)[0];
    },
    lastHour() {
      const endDate = moment(this.scopeEnd).isAfter(moment())
        ? moment()
        : moment(this.scopeEnd);

      const hourStart = endDate.startOf('hour');
      if (!hourStart.isAfter(this.blockedUntil)) {
        return null;
      }
      return hourStart.unix();
    },
    currentHour() {
      const endDate = moment(this.scopeEnd).isAfter(moment())
        ? moment()
        : moment(this.scopeEnd);

      const hourEnd = endDate.add(1, 'h').startOf('hour');
      if (!hourEnd.isBefore(this.scopeEnd)) {
        return null;
      }
      return hourEnd.unix();
    },
    sliderMax() {
      return moment(this.scopeEnd).diff(this.scopeStart, 'minutes');
    },
    scopeStartUnix() {
      const scopeDuration = moment(this.scopeEnd).unix() - moment(this.blockedUntil).unix();
      const maxBlockedDuration = scopeDuration * 0.33; // 25% can be 'blocked'
      const blocked = (moment(this.blockedUntil).unix() - moment(this.scopeStart).unix());

      return moment(this.blockedUntil)
        .subtract(Math.min(blocked, maxBlockedDuration), 's')
        .unix();
    },
    scopeEndUnix() {
      const blockedUntilUnix = moment(this.blockedUntil).unix();
      const scopeEnd = moment(this.scopeEnd).unix();

      return Math.max(blockedUntilUnix, scopeEnd);
    },
    timeLog() {
      return [
        ...(moment(this.scopeStart).isBefore(this.blockedUntil)
          ? [{
            id: 'old',
            from: moment.unix(this.scopeStartUnix).format(),
            to: this.blockedUntil,
            isDeletable: false,
            static: true,
            name: '',
          }] : []),
        {
          id: 'new',
          from: this.incremental
            ? this.blockedUntil
            : moment.unix(this.scopeStartUnix).format(),
          to: moment(this.logEnd).isBefore(this.blockedUntil) ? this.blockedUntil : this.logEnd,
          done: this.done,
          type: this.type,
          wasted: this.wasted,
          totalManhours: this.totalManhours,
          isDeletable: false,
        },
      ];
    },
    productionOptions() {
      return [
        {
          text: this.$t('orders.totalProduction'),
          value: false,
        },
        {
          text: this.$t('orders.incrementalProduction'),
          value: true,
        },
      ];
    },
  },
  watch: {
    scopeStartUnix() {
      const endDate = moment(this.scopeEnd).isAfter(moment())
        ? moment().unix()
        : moment(this.scopeEnd).unix();

      this.editDates({ endDate });
    },
  },
  methods: {
    editDates(x) {
      if (moment.unix(x.endDate).isBefore(this.blockedUntil)
        || moment.unix(x.endDate).isAfter(this.scopeEnd)) {
        return;
      }

      this.logEnd = moment.unix(x.endDate).format();
      this.$emit('end', this.logEnd);
    },
    setLastShift() {
      this.editDates({ endDate: moment.unix(this.lastShift.endDate).unix() });
    },
    setCurrentShift() {
      this.editDates({ endDate: moment.unix(this.currentShift.endDate).unix() });
    },
  },
  created() {
    const endDate = moment(this.scopeEnd).isAfter(moment())
      ? moment().unix()
      : moment(this.scopeEnd).unix();

    this.editDates({ endDate });
  },
};
</script>

<style lang="scss" scoped>
  .merge-tooltip {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, -12px);
  }

  .label {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
</style>
