<template>
  <div class="dashboard d-flex flex-column">
    <Navbar v-show="!fullScreen" />
    <div>
      <Sidebar
        v-if="plantId"
        :page="page"
      />

      <div class="container-fluid dashboard-container ">
        <RouterView
          v-if="isReady"
          class="px-1 px-md-3"
          @page="page = $event"
        />

        <Transition name="overlay-fade">
          <div
            v-if="pagePending"
            class="loading-overlay"
          >
            <Loader class="overlay-spinner" />
          </div>
        </Transition>
      </div>
    </div>

    <UserRequestToast
      v-for="o in userRequests"
      :key="o.id"
      :operation-id="o.id"
      :operation-type="o.type"
      @dismiss="removeOperation"
    />

    <LModal
      :show.sync="notificationsExplorer"
      size="lg"
      class="advice-modal"
    >
      <NotificationsExplorer
        v-if="explorer"
        :element-id="explorer.elementId"
        :select-types="explorer.selectTypes"
        :subscribed="explorer.subscribed"
        @close="notificationsExplorer = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NotificationsExplorer from '@/components/notifications/NotificationsExplorer';
import UserRequestToast from '@/components/downtimes/UserRequestToast';
import Navbar from './navbar/Navbar';
import Sidebar from './Sidebar';

export default {
  data: () => ({
    page: '',
    pagePending: false,
    pendingTimeout: null,
    fullScreen: false,
    userRequests: [],
    pagePendingDelay: 200, // 200 ms before loader will appear
  }),
  components: {
    Navbar,
    Sidebar,
    NotificationsExplorer,
    UserRequestToast,
  },
  computed: {
    ...mapGetters(['pageLoading', 'plantId', 'isReady']),
    ...mapState({
      explorer: state => state.notify.explorer,
    }),
    notificationsExplorer: {
      get() {
        return !!this.explorer;
      },
      set(v) {
        if (!v) {
          this.$store.commit('notify/notificationExplorer', null);
        }
      },
    },
  },
  watch: {
    pageLoading(v) {
      if (v) {
        this.pendingTimeout = setTimeout(() => {
          this.pagePending = true;
        }, this.pagePendingDelay);
      } else {
        this.pagePending = false;
        if (this.pendingTimeout) {
          clearTimeout(this.pendingTimeout);
        }
      }
    },
  },
  methods: {
    removeOperation(id) {
      this.userRequests = this.userRequests.filter(x => x.id !== id);
    },
  },
  created() {
    this.addOperationId = operation => {
      this.userRequests.push(operation);
    };

    this.$root.$on('user-operation-requested', this.addOperationId);
  },
  destroyed() {
    this.$root.$off('user-operation-requested', this.addOperationId);
  },
};

</script>

<style scoped>

  .dashboard {
    background-color: white;
    height: 100%;
  }

  .loading {
    background-color: white;
  }

  .dashboard-container {
    position: relative;
    min-height: 100%;
  }

  #page {
    padding-top: 20px;
  }

  .footer {
    font-size: 10px;
    position: absolute;
    z-index: 100;
    bottom: 0;
    padding: 4px;
    right: 0;
  }

  .overlay-fade-enter-active, .overlay-fade-leave-active {
    transition: opacity .5s;
  }
  .overlay-fade-enter, .overlay-fade-leave-to {
    opacity: 0;
  }

  @media screen and (min-width: 993px) {
    .dashboard-container {
      padding-left: 95px;
    }
  }
</style>
