<template>
  <div>
    <div class="mt-2">
      <div
        v-if="type === 'Order'"
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label class="title-style">
            {{ $t('orders.skuId') }}
          </label>
          <div>
            <ModelSelect
              v-if="!loadingItems"
              v-model="skuId"
              class="model-style"
              :is-disabled="disabled || skuFieldDisabled"
              :placeholder="$t('orders.sku')"
              :is-error="isSkuError"
              :options="itemsForDropdown"
              :filter-predicate="advancedSearch"
            />
            <div
              v-else
              class="text-center"
            >
              <Loader size="25px" />
            </div>
            <p
              v-if="isSkuError"
              class="text-danger font-weight-bold pt-1 mb-0"
            >
              <i class="fas fa-exclamation-circle" />
              {{ $t('activities.validationErrors.skuError') }}
            </p>
          </div>
        </div>
      </div>

      <hr v-if="type === 'Order'">

      <div
        v-if="type === 'Order'"
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
            :class="{ disabled: !skuId }"
          >
            {{ $t('graph.flow') }}
          </label>
          <BFormSelect
            v-if="!graphsPending"
            v-model="flowId"
            :disabled="!skuId || !flows.length || disabled"
          >
            >
            <option
              :value="null"
            >
              {{ $t('orders.defaultConfiguration') }}
            </option>
            <option
              v-for="f in flows"
              :key="f.id"
              :value="f.id"
            >
              {{ f.name }}
            </option>
          </BFormSelect>
          <div
            v-else
            class="text-center"
          >
            <Loader size="25px" />
          </div>
          <p
            v-if="isFlowIdError"
            class="text-danger font-weight-bold pt-1 mb-0"
          >
            <i class="fas fa-exclamation-circle" />
            {{ $t('activities.validationErrors.flowIdError') }}
          </p>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          v-if="displayLabour"
          class="col-md-8"
        >
          <label
            class="title-style"
            :class="{ disabled: !skuId && type === activityType.order }"
          >
            {{ $t('activities.expectedEffort') }}
          </label>
          <BFormInput
            v-model="manhours"
            :placeholder="$t('activities.typeEffort')"
            :disabled="(!skuId && type === activityType.order) || disabled"
            :state="expectedEffortCheck(manhours) | ifValidated(validate)"
          />
          <BFormInvalidFeedback
            v-if="manhours < 0"
          >
            {{ $t('activities.typeCorrectExpectedEffort') }}
          </BFormInvalidFeedback>
        </div>
      </div>
    </div>

    <Error
      v-if="error && !errorCheck"
      :message="error.detail"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { activityStatus, activityType, activityValidationErrorCodes } from '@/utils/dictionary';
import { ModelSelect } from 'vue-search-select';
import { dropdownSearch } from '@core/utils/search';
import activityForm from '@/mixins/activityForm';

export default {
  props: {
    activity: Object,
    type: String,
    edited: Boolean,
    disabled: Boolean,
    validationErrors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchErrors: [],
      flows: [],
      graphsPending: false,
      loadingItems: false,
      lineItems: [],
      missingSkuLineItem: {},
      manhours: null,
      skuId: null,
      flowId: null,
      activityType,
    };
  },
  components: {
    ModelSelect,
  },
  mixins: [activityForm()],
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementProperty']),
    ...mapGetters('plant', ['displayLabour']),
    ...mapState({
      items: state => state.item.items,
    }),
    errorCheck() {
      if (this.skuId && this.flowId && this.expectedEffortCheck(this.manhours)) return true;
      if (this.expectedEffortCheck(this.manhours)) return true;
      return false;
    },
    itemsForDropdown() {
      const items = this.lineId ? this.lineItems : this.items;

      if (items.length === 0) return [];
      if (Object.keys(this.missingSkuLineItem).length === 0) {
        return items
          .map(x => ({ id: x?.id, text: `${x?.skuNo} ${x?.description ? `/ ${x.description}` : ''}`, value: x?.id }));
      }
      return [
        ...items,
        this.missingSkuLineItem,
      ]
        .map(x => ({ id: x?.id, text: `${x?.skuNo} ${x?.description ? `/ ${x.description}` : ''}`, value: x?.id }));
    },
    skuDescription() {
      return this.items?.find(i => i.id === this.skuId)?.description;
    },
    skuFieldDisabled() {
      if (!this.activity.status) return false;

      return ![
        activityStatus.draft,
        activityStatus.released,
      ].includes(this.activity.status);
    },
    isSkuError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return false;

      const skuCheck = this.skuId
        && this.skuId !== this?.missingSkuLineItem?.id
        && !!this.itemsForDropdown.find(i => i.id === this.skuId);
      return (!!this.validationErrors.find(e => [
        activityValidationErrorCodes.productIdErrorCode,
        activityValidationErrorCodes.productLineNoConfigurationCode,
      ].includes(e.code)) && !skuCheck);
    },
    isFlowIdError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return null;

      return this.validationErrors.find(e => e.code === activityValidationErrorCodes.flowIdErrorCode)
        && !this.flowId;
    },
  },
  watch: {
    isSkuError(val) {
      if (!val) {
        this.$emit('solve-errors', [
          activityValidationErrorCodes.productIdErrorCode,
          activityValidationErrorCodes.productLineNoConfigurationCode,
        ]);
      }
    },
    isFlowIdError(val) {
      if (!val) {
        this.$emit('solve-errors', [
          activityValidationErrorCodes.flowIdErrorCode,
        ]);
      }
    },
    skuId(val) {
      if (val) {
        this.$emit('skuId', val);
      }
    },
    skuDescription(val) {
      if (val) {
        this.$emit('skuDescription', val);
      }
    },
    flowId(val) {
      if (val) {
        this.$emit('flowId', val);
      }
    },
    manhours(val) {
      this.validateModel();
      this.$emit('manhours', val);
      this.$emit('errorCheck', this.errorCheck);
    },
    lineItems: {
      deep: true,
      async handler(v) {
        if (!this.skuId) return;
        if (!v.includes(this.skuId)) {
          const { data } = await this.getSku({
            params: {
              plantId: this.plantId,
              skuId: this.skuId,
            },
          });
          this.missingSkuLineItem = data;
        }
      },
    },
  },
  methods: {
    ...mapActions(['getSkus', 'getSku']),
    ...mapActions('plant', ['getGraphs']),
    advancedSearch(text, inputText) {
      return dropdownSearch(text, inputText, this.searchErrors);
    },
    expectedEffortCheck(v) {
      if (!v || v.length === 0) { return true; }
      const number = Number(v);
      return !Number.isNaN(number) && number >= 0;
    },
    requestSkus(lineId) {
      this.loadingItems = true;
      this.getSkus({
        params: {
          plantId: this.plantId,
          query: {
            lineId,
          },
        },
      })
        .then(({ data }) => {
          this.lineItems = data;
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
    requestGraphs(elementId) {
      this.graphsPending = true;
      this.getGraphs({
        params: {
          plantId: this.plantId,
          elementId,
        },
      })
        .then(({ data }) => {
          this.graphsPending = false;
          this.flows = data;
          const index = data.findIndex(f => f.id === this.flowId);
          if (data.length > 0 && index === -1 && this.flowId !== null) {
            this.flowId = data[0].id;
          }
        })
        .catch(() => {
          this.graphsPending = false;
        });
    },
    validateModel() {
      this.validate = true;
      return this.errorCheck;
    },
  },
  created() {
    this.skuId = this.activity.productId;
    this.flowId = this.activity.flowId;
    this.manhours = this.activity.expectedEffort.manhours;
    this.lineId = this.activity.lineId;

    if (this.lineId) {
      this.requestGraphs(this.lineId);
      this.requestSkus(this.lineId);
    }
  },

};
</script>

<style lang="scss" scoped>
@import '~@/styles/vars.icss';

.title-style {
  font-weight: 500;
  font-size: 1.1em;

  &.disabled {
    color: gray;
    opacity: 0.6;
  }
}

.invalid-sku {
  border-color: $red !important;
}

.model-style {
  font-size: 1em;
}

</style>
