<template>
  <div>
    <VideoPlayer
      ref="videoPlayer"
      :enable-time-event="true"
      :options="playerOptions"
      @timeupdate="onPlayerTimeupdate($event)"
    />

    <div class="container">
      <div class="row vision-bar">
        <div class="col-sm align-self-center">
          {{ $t('vision.currentVideoTime') }}: {{ realCurrentTime }}
        </div>
        <div class="col-sm align-self-center">
          {{ $t('vision.startVideoTime') }}: {{ videoStartDateTimeS }}
        </div>
        <div class="col-sm align-self-center">
          {{ $t('vision.startDowntime') }}: {{ downTimeStartDateS }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VideoPlayer from '@core/components/videojs/VideoPlayer';
import { mapGetters } from 'vuex';

export default {
  props: {
    videoPath: String,
    videoPoster: String,
    videoStartDate: Number,
    downtimeStartDate: Number,
    downtimeEndDate: Number,
    offsetVideoPlaybackSecondsBack: Number,
  },
  data() {
    return {
      disallowEdit: true,
      show: true,
      showhide: false,
      playerOptions: {
        muted: true,
        autoplay: true,
        controls: true,
        fluid: true,
        language: this.locale,
        playbackRates: [0.1, 1.0, 3.0, 10.0],
      },
      currentTime: 0,
    };
  },
  components: {
    VideoPlayer,
  },
  computed: {
    ...mapGetters('core', ['locale']),
    player() {
      return this.$refs.videoPlayer.player;
    },
    realCurrentTime() {
      return moment.unix(this.videoStartDate).add(this.currentTime, 'seconds').format('LTS');
    },
    timeStartSpan() {
      return this.downtimeStartDate - this.videoStartDate - this.offsetVideoPlaybackSecondsBack;
    },
    videoStartDateTimeS() {
      return moment.unix(this.videoStartDate).format('LTS');
    },
    downTimeStartDateS() {
      return moment.unix(this.downtimeStartDate).format('LTS');
    },
    downTimeEndDateS() {
      return moment.unix(this.downtimeEndDate).format('LTS');
    },
    downTimeStartDateSpan() {
      const span = this.downtimeStartDate - this.videoStartDate;

      return parseInt(span, 10);
    },
    downTimeEndDateSpan() {
      const span = this.downtimeEndDate - this.videoStartDate;

      return parseInt(span, 10);
    },
  },
  methods: {
    onPlayerTimeupdate(player) {
      // eslint-disable-next-line no-underscore-dangle
      this.currentTime = Math.floor(player.cache_.currentTime);
    },
    setStartTime() {
      if (this.timeStartSpan >= 0) {
        this.$refs.videoPlayer.player.currentTime(this.timeStartSpan);
      }
    },
    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);

      this.loadMarkers();

      this.player.load();
      this.player.play();
    },
    loadMarkers() {
      if (!this.player) return;

      const markers = [
        {
          time: this.downTimeStartDateSpan,
          text: `${this.$t('vision.startDowntime')} - ${this.downTimeStartDateS}`,
        },
        {
          time: this.downTimeEndDateSpan,
          text: `${this.$t('vision.endDowntime')} - ${this.downTimeEndDateS}`,
        },
      ];
      this.player.markers({
        markerTip: {
          display: true,
          text(marker) {
            return marker.text;
          },
        },
        breakOverlay: {
          display: true,
          displayTime: 3,
          style: {
            height: 'auto',
          },
          text(marker) {
            return `${marker.text}`;
          },
        },
        markers,
      });
    },
  },
  mounted() {
    this.playVideo(this.videoPath);
    this.setStartTime();
  },
};
</script>

<style scoped>
.vision-bar{
  margin-bottom: auto;
  height: auto;
  min-height: 36px;
  color: black;
  font-weight: 400;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
