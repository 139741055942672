export default {
  originalDates: 'Ursprüngliche Daten',
  originalDuration: 'Ursprüngliche Dauer',
  newDates: 'Neue Daten',
  newDuration: 'Neue Dauer',
  firstDowntime: '1. Ausfallzeit',
  secondDowntime: '2. Ausfallzeit',
  shorten: 'kürzen',
  split: 'aufteilen',
  thisMachine: 'diese Anlage',
  addComment: 'Füge Kommentar hinzu',
  noComments: 'Keine Kommentare',

  downtimeSummary: 'Stilstandzeit Zusammenfassung',
  step: 'Schritt {Schritt}',
  selectRootLineMachine: 'Ort einer Grundursache auswählen',
  selectLineMachine: 'Linie/Anlage auswählen',
  selectState: 'Status auswählen',
  selectProblem: 'Problem auswählen',
  specifyAReason: 'Ursache spezifizieren',
  correctiveActionTaken: 'Korrekturmaßnahmen ergriffen',
  downtimeDuration: 'Stillstandzeit Dauer',
  vision: 'Vision',
  visionRequestClip: 'Video anfordern',
  chooseACamera: 'Wählen Sie eine Kamera',
  startOfAVideo: 'Beginn eines Videos',
  endOfAVideo: 'Ende eines Videos',
  requestVideo: 'Video anfordern',
  oeeLossType: 'OEE Verlust Typ',
  lossType: 'Verlust Typ',

  filterAny: 'Beliebig',
  hasClips: 'Hat Videoclips',
  notHaveClips: 'Hat keinen Videoclips',
  hasCorrectiveAction: 'Hat Korrekturmaßnahmen',
  notHaveCorrectiveAction: 'Hat keinen Korrekturmaßnahmen',
  hasReason: 'Hat Ursache',
  notHaveReason: 'Hat keinen Ursache',
  hasComments: 'Hat keinen Kommentare',
  notHaveComments: 'Hat Kommentare',
  noClipsRequested: 'Keine Videoclips angefordert',

  'table': {
    'downtime': 'Stillstandzeit',
    'details': 'Details',
    'time': 'Zeit',
    'duration': 'Dauer'
  }
};
