var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"scheduleLogWrapperRef",staticClass:"position-relative",style:({
    'padding-bottom': _vm.displayAxis ? '20px' : null,
    height: `${_vm.calculatedHeight}px`,
  })},[_c('div',{ref:"chartWrapper",staticClass:"worklog-wrapper",on:{"mousedown":function($event){_vm.activitiesEditMode ? _vm.mouseDownOnActivityBlock($event) : _vm.mouseDown($event)}}},[_c('div',{ref:"chart",staticClass:"progress downtimes-progress"},[_c('TransitionGroup',{attrs:{"name":_vm.animation,"tag":"div"}},[_vm._l((_vm.blocks),function(position,index){return _c('div',{key:position.id,ref:`block${position.id}`,refInFor:true,staticClass:"progress-bar schedule-bar flex-row flex-wrap",class:{
            hovered: _vm.isHovered(position),
            edited: _vm.isEdited(position),
            hovering: !_vm.isHovered(position) && !!_vm.hovered,
            static: position.static,
            conflict: _vm.hasPositionIndexConflicts(position),
          },style:(_vm.containerStyle(position)),attrs:{"id":`block${_vm.preparePositionId(position.id)}`},on:{"click":function($event){return _vm.select(position.id)},"mouseover":function($event){return _vm.handleMouseOverBlock(position)}}},[_c('div',{staticClass:"header",class:{'drag-line-trigger': _vm.activitiesEditMode && _vm.isDraggingMarkerVisibleForPosition(position)},style:(_vm.barStyle(position))},[(_vm.showCompletionLine)?_c('div',{staticClass:"header-completion-line",class:{'drag-line-trigger': _vm.activitiesEditMode && _vm.isDraggingMarkerVisibleForPosition(position)}},[_c('div',{staticClass:"header-completion-line--inner",style:({
                  width: _vm.calculateCompletionLineWidthForBlock(position),
                  backgroundColor: _vm.getCompletionLineColor(position)
                })})]):_vm._e(),_c('div',{staticClass:"block-marker"},[(!_vm.hideEntryDate || !_vm.hideEntryDate[0])?_c('div',{class:{
                  editable: _vm.editableLeft,
                  'center-marker': position.realWidth <= 100,
                  'left-marker': position.realWidth > 100,
                }},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.getPositionStartDate(position)))+" "),(!_vm.hideEntryDate || _vm.hideEntryDate[1])?_c('span',{staticClass:"end"},[_vm._v(" - "+_vm._s(_vm.formatDate(position.end))+" ")]):_vm._e()]):_vm._e(),(_vm.isLeftEditMarkerVisibleForPosition(position))?_c('div',{staticClass:"left-marker-line left-marker-line-trigger",class:{ editable: _vm.editableLeft && _vm.isEditMarkersVisibleForPosition(position) },style:(_vm.borderColor(position, index))},[_c('i',{staticClass:"fas fa-grip-lines-vertical left-marker-line-trigger"})]):_vm._e()]),_c('div',{staticClass:"bar-title text-truncate",class:{'drag-line-trigger': _vm.activitiesEditMode && _vm.isDraggingMarkerVisibleForPosition(position)}},[(position.icon)?_c('i',{class:position.icon}):_vm._e(),_vm._v("  "),_vm._t("body",function(){return [_vm._v(" "+_vm._s(position.name)+" ")]},{"block":position})],2),_c('div',{staticClass:"block-marker"},[(position.realWidth > 100 || _vm.editableRight)?_c('div',{staticClass:"right-marker",class:{
                  editable: _vm.editableRight,
                  manual: _vm.manualEdit && _vm.manualEdit.id === position.id
                }},[(_vm.manualEdit && _vm.manualEdit.id === position.id)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('BFormInput',{staticClass:"manual-input",staticStyle:{"border-color":"white","max-width":"130px"},attrs:{"type":"date"},on:{"blur":_vm.tryBlurManualTime},model:{value:(_vm.manualDate),callback:function ($$v) {_vm.manualDate=$$v},expression:"manualDate"}})],1),_c('div',{staticClass:"pl-1"},[_c('BFormInput',{ref:`manual-edit-${position.id}`,refInFor:true,staticClass:"manual-input",staticStyle:{"border-color":"white"},attrs:{"type":"time"},on:{"blur":_vm.tryBlurManualTime},model:{value:(_vm.manualTime),callback:function ($$v) {_vm.manualTime=$$v},expression:"manualTime"}})],1)]):_c('span',{staticClass:"right-marker-date"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.getPositionEndDate(position)))+" ")])]):_vm._e(),(_vm.isRightEditMarkerVisibleForPosition(position))?_c('div',{staticClass:"right-marker-line right-marker-line-trigger",class:{ editable: _vm.editableLeft && _vm.isEditMarkersVisibleForPosition(position) },style:(_vm.borderColor(position, index))},[_c('i',{staticClass:"fas fa-grip-lines-vertical right-marker-line-trigger"})]):_vm._e(),_c('div',{ref:`dateEditPopup${position.id}`,refInFor:true,staticClass:"edit-date",staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(_vm.formatDate(position.end))+" ")])])])])}),(_vm.plannedBlock && _vm.currentEditState)?_c('div',{key:"planned",staticClass:"progress-bar schedule-bar flex-row flex-wrap planned-bar",style:({
            ..._vm.containerStyle(_vm.plannedBlock, _vm.plannedBlock.index),
            zIndex: _vm.activitiesPlannedHovering ? 91 : 102
          }),on:{"click":function($event){_vm.activitiesPlannedHovering ? _vm.$emit('edit', _vm.hovered) : _vm.$emit('edit', _vm.hovered.item)}}},[_c('div',{staticClass:"header"},[(!_vm.activitiesPlannedHovering)?_c('span',{staticClass:"planned-info"},[_vm._v(" "+_vm._s(_vm.$t('orders.planned'))+" ")]):_vm._e()])]):_vm._e()],2)],1),_vm._l(((_vm.hideTooltip ? [] : _vm.blocks.filter(x => !x.hideTooltip))),function(position){return _c('Tippy',{key:position.id + '_tooltip',attrs:{"sticky":"","target":`#block${_vm.preparePositionId(position.id)}`,"placement":"top","theme":"light","multiple":"","distance":25,"arrow":"","interactive":_vm.interactiveTooltip},on:{"show":() => !_vm.activitiesEditMode}},[_vm._t("default",null,{"block":position})],2)})],2),_c('TimeAxis',{key:_vm.activitiesEditMode ? 'editing' : 'not-editing',ref:"timeaxis",staticStyle:{"height":"30px"},attrs:{"hide-labels":!_vm.displayAxis,"wrapper-height":_vm.calculatedTimeAxisHeight,"start-date":_vm.currentStartDate + (_vm.ignoreUtcOffsetInAxis ? 0 : _vm.utcOffset),"end-date":_vm.currentEndDate + (_vm.ignoreUtcOffsetInAxis ? 0 : _vm.utcOffset),"show-background":_vm.showBackground}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }