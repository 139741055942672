<template>
  <div>
    <div class="mt-md-1 row justify-content-between">
      <div class="col-12 col-md-auto text-center pb-2 pb-md-0">
        <span class="current-day">{{ currentDay }}</span>
      </div>

      <div class="col-12 col-md-auto text-center mb-3 mb-md-0">
        <slot />
        <div
          v-if="!hideResolution"
          class="btn-group"
          role="group"
        >
          <button
            v-for="res in resolutions"
            :key="res.key"
            :class="resolution === res.key ? 'btn-secondary' : 'btn-outline-secondary'"
            class="btn btn-sm text-nowrap"
            @click.stop.prevent="selectResolution(res.key)"
            @mousedown.stop="() => false"
            @mouseup.stop="() => false"
          >
            {{ res.name }}
          </button>
        </div>
      </div>
    </div>
    <div style="position: relative; min-height: 30px;">
      <VueSlider
        ref="slider"
        v-model="slider"
        class="slider"
        :fixed="true"
        :lazy="true"
        :min="0"
        :max="sliderMax"
        :tooltip-formatter="formatSlider"
        :tooltip-placement="['bottom', 'bottom']"
        :marks="sliderMarks"
        @change="updateTimeRange"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    startDate: Number,
    endDate: Number,
    zoom: Object,
    stoppedRealTime: Boolean,
    hideResolution: Boolean,
    startResolution: String,
  },
  data() {
    return {
      resolution: this.startResolution || 'day',
      realTime: false,
      slider: [0, 1],
    };
  },
  components: {
    VueSlider,
  },
  computed: {
    ...mapGetters(['screenWidth']),
    sliderMax() {
      const { endDate, startDate } = this;
      const fromDayStart = moment(endDate * 1000)
        .diff(moment(startDate * 1000), 'minutes');
      return Math.min((60 * 24), fromDayStart);
    },
    currentDay() {
      return this.startMoment().format('D MMMM');
    },
    currentRange() {
      const zoom = this.zoom || {};
      return {
        start: zoom.start || this.startDate,
        end: zoom.end || this.endDate,
      };
    },
    selectedRes() {
      return this.resolutions.find(el => el.key === this.resolution);
    },
    resolutions() {
      return [
        {
          key: 'minutes',
          name: `10 ${this.$t('time.minutes')}`,
          interval: 10,
        },
        {
          key: 'hour',
          name: this.$t('time.hour'),
          interval: 60,
        },
        {
          key: '3hours',
          name: `3 ${this.$t('time.hours')}`,
          interval: 180,
          hide: this.screenWidth < 1000,
        },
        {
          key: '8hours',
          name: `8 ${this.$t('time.hoursPlural')}`,
          interval: 480,
          hide: this.screenWidth < 1000,
        },
        {
          key: 'day',
          name: this.$t('time.day'),
          interval: 1440, // in minutes
        },
      ].filter(x => !x.hide);
    },
  },
  watch: {
    zoom(z) {
      if (!z) {
        this.setFreshSlider();
        return;
      }
      this.sliderFromEpoch(this.currentRange.start, this.currentRange.end);
      this.checkIfRealTime();
    },
    realTime(v) { this.$emit('realTime', v); },
    startDate() {
      this.selectResolution(this.resolution);
    },
  },
  methods: {
    sliderMarks(v) {
      return v % 60 === 0 ? ({
        label: moment((this.startDate + (v * 60)) * 1000).format('HH'),
      }) : false;
    },
    formatSlider(v) {
      return moment((this.startDate + (v * 60)) * 1000).format('HH:mm');
    },
    sliderFromEpoch(start, end) {
      this.slider = [this.getMinutes(start), this.getMinutes(end)];
    },
    selectResolution(res) {
      this.resolution = res;
      const { startDate, endDate } = this;
      const dayDuration = Math.floor((endDate - startDate) / 60);
      const { interval } = this.selectedRes;
      if (res === 'day' || dayDuration < interval) {
        this.sliderFromEpoch(startDate, endDate);
        this.$emit('changed', null);
      } else {
        let end = this.slider[1];
        let start = this.slider[0];
        if (this.slider[1] - interval < 0) {
          end = interval;
        } else {
          start = this.slider[1] - interval;
        }

        this.slider = [start, end];
        this.updateTimeRange();
      }
      this.updateTimeRange();
      this.checkIfRealTime();
    },
    getMinutes(seconds) {
      return Math.floor(Math.max(0, seconds - this.startDate) / 60);
    },
    startMoment() {
      return moment(this.startDate * 1000);
    },
    updateTimeRange() {
      const [start, end] = this.slider;
      const startDate = (start * 60) + this.startDate;
      const endDate = (end * 60) + this.startDate;
      this.$emit('changed', { startDate, endDate });
      this.checkIfRealTime();
    },
    checkIfRealTime() {
      const end = this.slider[1];
      const endDate = (end * 60) + this.startDate;
      const toCurrent = (this.$now - endDate) / 60;
      if (toCurrent < 10) {
        this.realTime = true;
      } else {
        this.realTime = false;
      }
    },
    refresh(toEnd) {
      if (toEnd) {
        this.slider[1] = this.sliderMax;
      }
      this.selectResolution(this.resolution);
    },
    setFreshSlider() {
      if (!this.zoom) {
        this.slider[1] = this.sliderMax;
        this.selectResolution(this.startResolution || 'day');
      } else {
        this.slider = [
          this.getMinutes(this.currentRange.start),
          this.getMinutes(this.currentRange.end),
        ];
      }
      this.checkIfRealTime();
      this.$emit('realTime', this.realTime);
    },
  },
  created() {
    this.setFreshSlider();
  },
};
</script>

<style scoped>
  .current-day {
    font-size: 23px;
  }

  .slider :deep(.vue-slider-mark-label) {
    font-size: 12px;
    line-height: 1;
    margin-top: 7px;
  }

  @media (max-width: 600px) {
    .slider :deep(.vue-slider-mark:nth-child(odd)) {
      display: none;
    }
  }
</style>
