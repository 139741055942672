<template>
  <div
    class="text-left"
    :style="{
      color: workState ? colors(workState.colorCode) : null,
    }"
  >
    <div style="line-height: 1">
      <StateBadge
        :state="workState"
        class="state text-center mr-1"
      />
      <span class="state-duration">
        {{ currentState ? CalcDuration(currentState.startDate, now) : '' }}
      </span>
    </div>

    <div class="work-event mt-1">
      {{ workEventDescription }}
    </div>

    <div
      v-if="rootState"
      :style="{
        color: eventState ? colors(eventState.colorCode) : null
      }"
      class="work-event flex-grow-1"
      style="line-height: 1; padding-top: 2px;"
    >
      <span>
        <i class="fas fa-long-arrow-alt-right pr-1" />
      </span>
      <span
        v-if="rootMachine"
        class="text-nowrap"
      >
        [ {{ rootMachine }} ]
      </span>
      {{ workEventDescription }}
      <StateBadge
        v-if="rootState && workState && rootState.id != workState.id"
        :state="rootState"
        style="font-size: 8px"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loopRequest from '@/mixins/loopRequest';
import CalcDuration from '@/utils/calcDuration';
import colors from '@/utils/colors';

export default {
  props: {
    currentState: Object,
  },
  data: () => ({
    now: 0,
  }),
  mixins: [loopRequest('updateNow', 3000)],
  computed: {
    ...mapGetters('element', ['elementName']),
    ...mapGetters('work', ['workStateOfEvent']),
    ...mapGetters('work', {
      workEventGetter: 'workEvent',
      workStateGetter: 'workState',
    }),
    workState() {
      const { eventId } = this.currentState;
      return this.workStateOfEvent(eventId);
    },
    rootState() {
      if (this.currentState.rootWorkLog) {
        const { eventId } = this.currentState.rootWorkLog;
        return this.workStateOfEvent(eventId);
      }
      return null;
    },
    eventState() {
      const { currentState } = this;
      if (currentState.rootWorkLog) return this.rootState;
      return this.workState;
    },
    workEvent() {
      const { currentState } = this;
      if (!currentState) return null;
      return this.workEventGetter(this.currentState.eventId);
    },
    rootEvent() {
      const state = this.currentState;
      if (!state.rootWorkLog || !state.rootWorkLog.eventId) return null;
      const { eventId } = state.rootWorkLog;

      return this.workEventGetter(eventId);
    },
    rootMachine() {
      const { currentState } = this;
      if (!currentState || currentState.isForced || !currentState.rootWorkLog) return '';
      return this.elementName(currentState.rootWorkLog.elementId);
    },
    workEventDescription() {
      const { currentState } = this;
      if (!currentState) {
        return this.$t('general.reasonUnknown');
      }
      if (!this.workEvent) return '';
      if (!this.rootEvent) {
        return this.workEvent.isDefault ? '' : this.workEvent.name;
      }

      return this.rootEvent.name;
    },
  },
  methods: {
    CalcDuration,
    colors,
    updateNow() {
      this.now = this.$now;
    },
  },
  created() {
    this.now = this.$now;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .state-duration {
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1;
  }

  .work-event {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    line-height: 1;
  }

  .state {
    font-size: 11px;
  }

  .reason-comment {
    font-size: 13px;
    line-height: 1.1;
  }

</style>
