<template>
  <div v-if="!success">
    <div class="text-danger text-center font-weight-bold">
      <i class="fas fa-times pr-2" />
      {{ failureText }}
    </div>
  </div>
  <div
    v-else
    class="d-flex align-items-center justify-content-center h-100"
  >
    <div class="text-success text-center font-weight-bold">
      <i class="fas fa-check pr-2" />
      {{ successText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    success: Boolean,
    successText: String,
    failureText: String,
  },
};
</script>
