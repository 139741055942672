<template>
  <div>
    <div v-if="importResult">
      <div v-if="!importResult.success">
        <div class="text-danger text-center font-weight-bold">
          <i class="fas fa-times pr-2" />
          {{ getImportMessage() }}
        </div>
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-center h-100"
      >
        <div class="text-success text-center font-weight-bold">
          <i class="fas fa-check pr-2" />
          {{ getImportMessage() }}
        </div>
      </div>
    </div>
    <div class="m-3">
      <div class="font-weight-bold">
        {{ $t('general.group') }}
      </div>
      <BFormSelect
        v-model="selected"
        :options="groupsOptions"
      />
      <div v-if="selected">
        <!-- TODO: Display group details -->
      </div>
    </div>
    <div v-if="error">
      <div class="text-danger text-center">
        {{ error }}
      </div>
    </div>
    <div class="text-center pt-2">
      <SaveButton
        :pending="saving"
        :show-remove="false"
        size="lg"
        @cancel="$emit('cancelled')"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  props: {
    importResult: Object,
    itemIds: Array,
    skuGroupId: String,
  },
  data: () => ({
    adding: false,
    skuGroup: null,
    skuGroupPending: false,
    pending: {},
    selected: null,
    newGroups: [],
    groupsPackingStructure: {},
    saving: false,
    error: null,
  }),
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('plant', ['lines']),
    ...mapGetters('element', ['elementName', 'elementProperties']),
    ...mapState({
      groups: state => state.item.skuGroups || [],
    }),
    groupsOptions() {
      if (!this.groups) return [];
      return this.groups.map(g => ({
        value: g.id,
        text: g.name,
      })).concat([{
        value: null,
        text: '-',
      }]);
    },
    selectedGroup() {
      if (!this.selected) return '';
      return this.groups.find(g => g.id === this.selected);
    },
  },
  methods: {
    ...mapActions('element', ['getElementsProperties']),
    ...mapActions([
      'setSkusGroup',
      'getPackingStructures',
    ]),
    requestPackingStructure() {
      if (!this.groups) return;
      this.getPackingStructures({
        params: {
          plantId: this.plantId,
          query: {
            id: this.groups.map(g => g.id),
          },
        },
      }).then(({ data }) => {
        if (!data) return;
        this.groupsPackingStructure = data.reduce((acc, curr) => {
          acc[curr.refId] = acc[curr.refId] || [];
          acc[curr.refId].push(curr);
          return acc;
        }, {});
      });
    },
    getImportMessage() {
      const { importResult } = this;
      if (!importResult) return '';
      let str = '';
      if (this.importResult.success === true) {
        if (this.importResult.action === 'UPDATE' || this.importResult.action === 'ADD') {
          str = this.$t('import.groupSuccessfullyAssign');
        } else if (this.importResult.action === 'DELETE') {
          str = this.$t('import.groupSuccessfullyRemoved');
        }
      } else if (this.importResult.action === 'UPDATE' || this.importResult.action === 'ADD') {
        str = this.$t('import.groupFailedToAssign');
      } else if (this.importResult.action === 'DELETE') {
        str = this.$t('import.groupFailedToRemove');
      }
      return `${str} ${this.importResult.groupName}`;
    },
    save() {
      this.saving = true;
      this.setSkusGroup({
        params: {
          plantId: this.plantId,
          groupId: this.selected || null,
        },
        data: this.itemIds,
      })
        .then(() => {
          this.saving = false;
          if (this.importResult !== undefined) {
            // eslint-disable-next-line
            this.importResult.success = true;
            // eslint-disable-next-line
            this.importResult.groupName = this.groups.find(grp => grp.id === this.selected).name;
          }
          this.$emit('saved');
        })
        .catch(err => {
          this.saving = false;
          this.error = err;
        });
    },
  },
  created() {
    this.requestPackingStructure();
    this.selected = this.skuGroupId;
  },
};
</script>

<style lang="scss" scoped>
  .group-name {
    padding: 5px 10px;
    box-shadow: 0 0 4px rgba(100, 100, 100, 0.2);
  }

  .custom-select {
    border-radius: 10px;
  }
</style>
