<template>
  <div>
    <div
      class="d-flex justify-content-between"
    >
      <div
        class="py-4"
        style="font-size: 1em; width: 25px"
      >
        <div v-if="expanded && expandable">
          <i
            class="ion ion-md-arrow-dropdown pb-1 pr-1"
            :style="expandable ? 'cursor: pointer' : 'cursor: default'"
            @click="$emit('click', expandable && !expanded)"
          />
        </div>
        <div v-else-if="expandable">
          <i
            class="ion ion-md-arrow-dropright pb-1 pr-1"
            :style="expandable ? 'cursor: pointer' : 'cursor: default'"
            @click="$emit('click', expandable && !expanded)"
          />
        </div>
      </div>

      <div
        class="field-style flex-grow-1"
        :style="{ width: `${282 - paddingSum}px` }"
      >
        <div
          v-if="types[node.type.toLowerCase()]"
          class="text-style pb-2 px-1"
        >
          <i :class="types[node.type.toLowerCase()].icon" />
          {{ types[node.type.toLowerCase()].text }}:
          {{ node.nameId }}
        </div>
        <div v-else>
          {{ node.type }}
          {{ node.nameId }}
        </div>
        <div class="table-style">
          <table
            v-if="fields.length"
            class="table"
          >
            <thead>
              <tr class="text-style">
                <div
                  v-if="!simulation"
                  class="row px-2"
                >
                  <th class="col">
                    {{ $t('import.key') }}
                  </th>
                  <th class="col">
                    {{ $t('import.oldValue') }}
                  </th>
                  <th class="col">
                    {{ $t('import.newValue') }}
                  </th>
                  <th class="col">
                    {{ $t('general.status') }}
                  </th>
                </div>
                <div
                  v-else
                  class="row px-2"
                >
                  <th class="col-6">
                    {{ $t('import.key') }}
                  </th>
                  <th class="col-6">
                    {{ $t('import.value') }}
                  </th>
                </div>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(prop, key) in fields"
                :key="key"
              >
                <div class="row px-2">
                  <td class="col">
                    {{ prop.propertyName }}
                  </td>
                  <td
                    v-if="!simulation"
                    class="borderLeft col"
                    style="display: inline-flex"
                  >
                    <div
                      class="px-2 flex-grow-1"
                      :class="{
                        unchanged: isValueUnchanged(prop),
                        'old-value': !!prop.oldValue,
                        'no-value': !prop.oldValue,
                      }"
                    >
                      {{ parsePropValues(prop).oldValue }}
                    </div>
                    <div
                      class="px-2"
                      style="width: 20px"
                    >
                      <i
                        class="fas fa-arrow-right"
                        style="font-size: 0.5em"
                      />
                    </div>
                  </td>
                  <td
                    class="col"
                    style="display: inline-flex"
                  >
                    <div
                      class="px-2 flex-grow-1"
                      :class="{
                        unchanged: isValueUnchanged(prop),
                        'new-value': !!prop.importedValue,
                        'no-value': !prop.importedValue,
                      }"
                    >
                      {{ parsePropValues(prop).importedValue }}

                      <i
                        v-if="[
                          'PlannedStart',
                          'PlannedEnd',
                        ].includes(prop.propertyName) && prop.importedValue"
                        v-b-tooltip.right.hover="$t('time.factoryTimezone')"
                        class="fas fa-info-circle text-black-50 ml-1"
                      />
                    </div>
                  </td>
                  <td
                    v-if="!simulation"
                    class="col d-flex align-items-center borderLeft"
                  >
                    <Loader
                      v-if="prop.status === 'Pending'"
                      :size="20"
                    />
                    <i
                      v-else-if="prop.status === 'Faulted'"
                      class="fas fa-times-circle text-danger pr-2"
                    />
                    <span
                      v-else-if="isValueUnchanged(prop)"
                      :class="'text-secondary'"
                    >
                      <span v-if="isValueUnchanged(prop)">
                        {{ $t('import.unchanged') }}
                      </span>
                    </span>
                    <i
                      v-else
                      class="fas fa-check-circle text-success"
                    />
                    <span class="small text-danger">
                      {{ prop.statusMessage }}
                    </span>
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="mt-4"
          >
            <div
              class="no-data"
            >
              {{ $t('import.noChanges') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    expanded: Boolean,
    expandable: Boolean,
    simulation: {
      type: Boolean,
      default: true,
    },
    paddingSum: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      'plantTimezone',
    ]),
    types() {
      return {
        order: {
          icon: 'icon-orders',
          text: this.$t('activities.order'),
        },
        changeover: {
          icon: 'fas fa-wrench',
          text: this.$t('activities.changeover'),
        },
        product: {
          icon: 'icon-sku',
          text: this.$t('items.item'),
        },
        productlineconfiguration: {
          icon: 'fas fa-cogs',
          text: this.$t('items.lineConfiguration'),
        },
        productgrouplineconfiguration: {
          icon: 'fas fa-cogs',
          text: this.$t('items.lineConfiguration'),
        },
        productpackingstructure: {
          icon: 'fas fa-boxes',
          text: this.$t('items.packingStructure'),
        },
        productgrouppackingstructure: {
          icon: 'fas fa-boxes',
          text: this.$t('items.packingStructure'),
        },
        productgroup: {
          icon: 'fas fa-object-group',
          text: this.$t('items.itemGroup'),
        },
        unit: {
          icon: 'fas fa-pallet',
          text: this.$t('items.unit'),
        },
      };
    },
    fields() {
      return this.node?.fields || [];
    },
  },
  methods: {
    isValueUnchanged(prop) {
      switch (prop.propertyName) {
        case 'FlowName':
          return (prop.importedValue || '').toLowerCase() === (prop.oldValue || '').toLowerCase();
        default:
          return prop.importedValue === prop.oldValue;
      }
    },
    parsePropValues(prop) {
      switch (prop.propertyName) {
        case 'PlannedEnd':
        case 'PlannedStart':
          return {
            ...prop,
            oldValue: prop.oldValue
              ? moment.utc(prop.oldValue).tz(this.plantTimezone).format('LLL')
              : prop.oldValue,
            importedValue: prop.importedValue
              ? moment.utc(prop.importedValue).tz(this.plantTimezone).format('LLL')
              : prop.importedValue,
          };
        default:
          return prop;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .field-style {
    padding: 15px;
    margin: 5px 20px;
    border-radius: 10px;
    background-color:rgba(150, 150, 150, 0.2);
  }

  .table-style {
    padding: 0 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 4px #dadada;
  }

  .text-style {
    font-weight: 550;
    font-size: 1em;
  }

  .icon-style {
    font-weight: 550;
    font-size: 1.5em;
  }

  .no-value {
    background-color: rgba(gray, 0.3);
    &.unchanged {
      background-color: rgba(#333, 0.2);
    }
  }

  .old-value {
    background-color: rgba(red, 0.2);
    &.unchanged {
      background-color: rgba(#333, 0.2);
    }
  }

  .new-value {
    background-color: rgba(green, 0.1);
    &.unchanged {
      background-color: rgba(#333, 0.2);
    }
  }

  .borderLeft {
    border-left: 1px solid #dee2e6;
  }

  .table th, .table td {
    padding: 0.4rem;
    vertical-align: middle;
  }

  .table th {
    border: none,
  }

  .table {
    margin-bottom: 0em;
  }

  .no-data {
    font-size: 1.75em;
    opacity: 0.6;
    font-weight: 600;
    padding-block: 25px;
    text-align: center;
  }
</style>
