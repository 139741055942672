import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    tags: [],
  },
})
  .delete({
    action: 'removeWorkEventTag',
    path: ({ plantId, workEventId, workTagId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/workTag/${workTagId}`,
  })
  .post({
    action: 'addWorkEventTag',
    path: ({ plantId, workEventId, workTagId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/workTag/${workTagId}`,
  })
  .getStore();

store.mutations.setWorkTags = (s, list) => {
  s.tags = list || [];
};

store.getters = {
  tags: s => s.tags,
};

export default store;
