import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';
import plLocale from '@/utils/plMomentLocale';

// translations
import PortalVue from 'portal-vue';
import pl from './languages/pl/index';
import en from './languages/en/index';
import pt from './languages/pt/index';
import nl from './languages/nl/index';
import de from './languages/de/index';
import es from './languages/es/index';
import ru from './languages/ru/index';
import vi from './languages/vi/index';
import zh from './languages/zh/index';
import fr from './languages/fr/index';
import th from './languages/th/index';

// load necessary moment locales
require('moment/locale/pl');
require('moment/locale/en-gb');
require('moment/locale/pt');
require('moment/locale/nl');
require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/es');
require('moment/locale/ru');
require('moment/locale/vi');
require('moment/locale/th');
require('moment/locale/zh-cn');

Vue.use(VueI18n);
Vue.use(PortalVue);

moment.updateLocale('pl', plLocale);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    pl,
    en,
    pt,
    nl,
    fr,
    de,
    es,
    ru,
    vi,
    zh,
    th,
  },
});

export default i18n;
