<template>
  <div
    v-if="!pending"
    style="min-height: 100%;"
    class="d-flex flex-column position-relative"
  >
    <div class="px-3 text-fancy line-name">
      <ElementPicture
        :element-id="lineId"
      />
      {{ elementName(lineId) }}
    </div>

    <div class="px-3 pt-2">
      <div class="font-weight-bold pb-2">
        {{ $t('monitoring.productionProgress') }}
      </div>
      <div class="d-flex justify-content-between font-weight-bold">
        <div>
          {{ done | addSpaces }} |
          <span v-if="wasted">
            <span class="text-danger">
              {{ wasted| addSpaces }}
            </span> |
          </span>
          {{ order.orderedQuantity.value | addSpaces }} {{ order.orderedQuantity.unit }}
        </div>
      </div>
      <ProductionChart
        :done="done"
        :wasted="wasted"
        :estimated="order.estimatedVsDesignSpeed"
        :planned="order.orderedQuantity.value"
        :unit="order.orderedQuantity.unit"
      />
    </div>
    <hr>
    <div class="px-3 pt-2">
      <ProductionHistory
        :resolution="resolution"
        :production="production"
        :start-date="orderStartDate"
        :end-date="endDate"
        style="max-height: 100%"
        class="px-2"
        :show-legend="false"
      />
    </div>

    <div
      v-if="productionUpdatesManualProduction && productionUpdatesManualProduction.length"
      class="chart-padding"
    >
      <ProductionUpdatesLog
        :start-date="orderStartDate"
        :end-date="endDate"
        :unit="order.orderedQuantity.unit"
        :log="productionUpdates"
      />
    </div>
    <div class="chart-padding">
      <ElementSchedule
        v-bind="scheduleDates"
        :element-id="lineId"
      />
    </div>

    <div
      v-if="productionUpdates && productionUpdates.length > 0"
      class="px-3 pt-2"
    >
      <div class="font-weight-bold">
        {{ $t('orders.productionUpdates') }} - {{ order.description }}:
      </div>

      <ManualResourceEntries
        class="pb-3"
        :production-updates="productionUpdates"
        :order="order"
        :enable-delete="false"
      />
    </div>
  </div>
  <div v-else>
    <Loader :error="error" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ManualResourceEntries from '@/components/activity/resources/ManualResourceEntries';
import ProductionHistory from '@/components/charts/production/ProductionHistory';
import ElementSchedule from '@/components/schedule/ElementSchedule';
import ProductionUpdatesLog from '@/components/activity/resources/ProductionUpdatesLog';
import ProductionChart from '@/components/charts/production/ProductionChart';
import { productionHistoryType } from '@/utils/dictionary';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    production: null,
    pending: false,
    endDate: Math.floor(Date.now() / 1000),
    productionUpdates: [],
    resolution: {
      name: 'hour',
      res: 'hours',
      format: 'H:mm',
      aggregate: 'h',
      minHourDiff: 0,
      maxHourDiff: 744,
    },
    error: null,
  }),
  components: {
    ProductionUpdatesLog,
    ManualResourceEntries,
    ProductionHistory,
    ElementSchedule,
    ProductionChart,
  },
  computed: {
    ...mapGetters('element', ['elementName']),
    ...mapGetters('element', ['element']),
    ...mapGetters(['startDate']),
    ...mapGetters(['plantId', 'performanceType']),
    scheduleDates() {
      return {
        startDate: this.orderStartDate,
        endDate: this.endDate,
      };
    },
    line() {
      return this.$store.getters['element/element'](this.lineId);
    },
    order() {
      return this.line.activities.activeOrder;
    },
    done() {
      return this.order?.producedQuantity?.value || 0;
    },
    wasted() {
      return this.order?.wastedQuantity?.value || 0;
    },
    orderStartDate() {
      return moment(this.order.actualExecution?.begin).unix();
    },
    productionUpdatesManualProduction() {
      return this.productionUpdates.filter(
        log =>
          log.type === productionHistoryType.orderManualProductionHistoryAdded
          && log.isActive
          && ((log.produced || log.wasted)
          ),
      );
    },
  },
  methods: {
    ...mapActions(['getProduction']),
    ...mapActions(['getManualProductionHistory']),
    requestProduction() {
      this.pending = true;
      this.error = null;
      this.getProduction({
        params: {
          plantId: this.plantId,
          elementId: this.lineId,
          startDate: this.orderStartDate,
          endDate: this.endDate,
          query: {
            aggregate: 'hour',
            performanceType: 1,
          },
        },
      })
        .then(({ data }) => {
          this.production = data;
          this.pending = false;
        })
        .catch(({ response: { data } }) => {
          this.pending = false;
          this.error = data;
        });
    },

    getManualChangesHistory() {
      this.getManualProductionHistory({
        params: {
          plantId: this.plantId,
          orderId: this.order.id,
        },
      }).then(({ data }) => {
        this.productionUpdates = data;
      });
    },
  },
  created() {
    this.requestProduction();
    this.getManualChangesHistory();
  },

};
</script>

<style lang="scss" scoped>
  .machine-picture {
    max-height: 40px;
    padding: 5px;
  }

  .chart-padding {
    padding-left: 93px;
    padding-right: 25px;
  }

  .line-name {
    font-size: 20px;
    font-weight: 600;
  }

</style>
