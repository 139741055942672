export default {
  'activities': 'Actividades',
  'activityType': 'Tipo de actividad',
  'addActivity': 'Planificar nueva actividad',
  'order': 'orden',
  'plannedStart': 'Inicio planificado',
  'plannedEnd': 'Fin planificado',
  'cleaning': 'Limpieza',
  'changeover': 'Cambio',
  'activityId': 'ID actividad',
  'activity': 'Actividad agregada',
  'activityStart': 'Inicio actividad',
  'toStart': 'Por iniciar',
  'afterStart': 'Despues del inicio',
  'activityAlreadyExists': 'ID de la actividad ya existe',
  'changeoverDuration': 'Duración cambio de formato (minutos)',
  'manage': 'Administrar actividades',
  'summary': {
    'summary': 'Resumen',
    'orderTotal': 'TOTAL ORDEN',
    'lineTotal': 'TOTAL LÍNEA',
    'inLineUnit': 'Unidad en línea',
    'inOrderUnit': 'Unidad en orden',
    'goodCount': 'CUENTA CANTIDAD BUENA',
    'wasteCount': 'CANTIDAD DESPERDICIO',
    'counterTotal': 'Conteo total',
    'performanceGuard': 'Velocidad desbordada (limite)',
    'performanceGuardTippy': 'Umbral del rendimiendo excedido',
    'manualReconciliation': 'Conciliación manual',
    'stateGuard': 'Estado de controlador "limite de velocidad"',
    'missingPackingStructure': 'Falta de estructura de empaque'
  },
  'list': {'order': 'Ordenes', 'cleaning': 'Limpiezas', 'changeover': 'Cambios de formato'},
  'activityAdded': 'Actividad agregada',
  'noActivities': 'Sin actividades',
  'status': {
    'released': 'Liberado',
    'draft': 'Borrador',
    'started': 'Empezado',
    'suspended': 'Suspendido',
    'completed': 'Terminado',
    'activated': 'Activado',
    'scheduled': 'Programado',
    'notPlanned': 'No planeado'
  },
  'schedule': {
    'tableView': 'Tabla',
    'axisView': 'Linea de tiempo',
    'full': 'Completo',
    'collapsed': 'Colapsado',
    'scheduled': 'Programado',
    'currentHour': 'Final de la hora actual',
    'previousHour': 'Hora anterior',
    'previousShift': 'Turno anterior',
    'currentShift': 'Turno actual'
  },
  'resources': {
    'production': 'Producción',
    'waste': 'Desperdicio',
    'manhours': 'Horas hombre',
    'timeScope': 'Alcance de tiempo',
    'workers': 'Trabajadores',
    'worker': 'Trabajador',
    'history': 'Historial',
    'noEntries': 'Sin entradas',
    'hours': 'Horas',
    'minutes': 'Minutos'
  },
  'actions': {
    'finishChangeover': 'Inicio y fin de orden',
    'abortChangeover': 'Cancelar cambio de formato',
    'productionReconciliation': 'Conciliación de producción',
    'finishOrder': 'Finalizar orden'
  },
  'validations': {
    'orderNotFound': 'Orden relacionada no encontrada',
    'externalIdError': 'El ID solo puede contener números, letras, caracteres \'-\', \'/\' y \'_\' y solo puede comenzar con un número o una letra'
  },
  'deleteConfirmMessage': '¿Está seguro de que desea eliminar esta actividad?',
  'hasValidationErrors': 'Validación tiene errores',
  'noLine': 'Sin línea',
  'editOrder': 'Editar orden',
  'editChangeover': 'Editar cambio de formato',
  'cannotCreateOrder': 'No se puede crear orden',
  'orderExists': 'Ya existe una orden con ID de actividad especificado.',
  'endOfLastShift': 'Final del último turno',
  'endOfLastHour': 'Final de la última hora',
  'productionHistory': 'Historial de producción',
  'manhoursHistory': 'Historial de horas hombre',
  'changeoverDurationTitle': 'Duración cambio de formato',
  'add': 'Adicionar actividad',
  'edit': 'Editar actividad',
  'addChangeover': 'Adicionar Cambio de producto (Changeover)',
  'step': 'Paso',
  'general': 'General',
  'quantity': 'Cantidad',
  'dates': 'Fechas',
  'configuration': 'Configuración',
  'activityIdIsTooLong': 'La ID de la actividad es muy larga',
  'changeoverType': 'Tipo de cambio de producto (Changeover)',
  'editExisting': 'Editar valor actual',
  'pickALine': 'Escoja una linea',
  'typeEffort': 'Tipo de esfuerzo',
  'showNotPlanned': 'Muestre actividades no planeadas',
  'expectedEffort': 'Esfuerzo esperado (mh)',
  'expectedChangeoverEffort': 'Esfuerzo esperado Cambio producto Changeover (mh)',
  'typeCorrectExpectedEffort': 'Esfuerzo esperado no puede ser negativo',
  'typeCorrectValue': 'Valor no puede ser negativo',
  'typeChangeoverDurationIfChangeoverExpectedEffortGiven': 'Duración esperada de cambio de producto (C/O) es requerido cuando el esfuerzo de cambio esperado de producto es suministrado',
  'editMode': 'Modo edición',
  'validationErrors': {
    'skuError': 'El producto no existe o configurado incorrectamente',
    'flowIdError': 'Falta configuración de flujo',
    'endDateNotValid': 'La fecha de finalización pleneada no es valida',
    'lineError': 'Falta configuración de línea',
    'orderedQuantityError': 'Falta cantidad ordenada',
    'orderedQuantityUnitError': 'Falta unidad de cantidad ordenada ó configurada incorrectamente'
  }
};
