<template>
  <Apexcharts
    ref="chart"
    type="heatmap"
    :options="localOptions"
    :class="{forceFitWidth}"
    :series="series"
    :height="height"
    :width="width"
  />
</template>

<script>
import Apexcharts from 'vue-apexcharts';
import moment from 'moment';

export default {
  props: {
    height: [Number, String],
    production: Object,
    forceFitWidth: Boolean,
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Apexcharts,
  },
  computed: {
    series() {
      const weeks = [];
      const curr = moment(this.startDate * 1000);
      const end = moment(this.endDate * 1000);
      if (!this.production) return [];

      const production = this.production.res.map(data => ({
        weekday: moment.utc(data.from).local().weekday(),
        week: moment.utc(data.from).local().week(),
        data,
      }));

      while (curr.isSameOrBefore(end)) {
        const data = [];

        for (let i = 0; i < 7; i += 1) {
          /* eslint-disable no-loop-func */
          const prod = production
            .find(x => x.week === curr.week() && x.weekday === i);

          if (prod) {
            data.push(prod.data.production.done);
          } else {
            data.push(0);
          }
        }

        weeks.push({
          name: `${this.$t('chart.weekSymbol')}${curr.week()}`,
          data,
        });

        curr.add(1, 'week').startOf('week').endOf('day');
      }

      const res = [];

      for (let i = 6; i >= 0; i -= 1) {
        const data = weeks.map(w => ({
          x: w.name,
          y: w.data[i],
        }));

        res.push({
          name: moment().weekday(i).format('dddd'),
          data,
        });
      }

      return res;
    },
    width() {
      const xAxisLength = this.series?.[0]?.data.length;

      if (xAxisLength && this.forceFitWidth) {
        return `${xAxisLength * 70}px`;
      }
      return undefined;
    },
    localOptions() {
      return {
        colors: [this.$color('#AC89DF')],
        tooltip: {
          x: {
            show: true,
            formatter: (value, { seriesIndex, dataPointIndex }) => {
              const date = moment(this.startDate * 1000)
                .add(dataPointIndex, 'weeks')
                .weekday(6 - seriesIndex);

              return date.format('DD (ddd) MMM YYYY');
            },
          },
          y: {
            show: true,
            formatter: v => `${v} ${this.production.unit || ''}`,

          },
        },
        dataLabels: {
          formatter: v => `${v === 0 ? '' : v} ${v ? this.production.unit : ''}`,
          style: {
            colors: ['black'],
          },
        },
        xaxis: {
          tooltip: {
            enabled: false,
          },
        },

        states: {

          hover: {
            filter: {
              type: 'darken',
              value: 0.8,
            },
          },

        },

        ...this.options,
      };
    },
  },
};
</script>

<style>
.forceFitWidth {
   overflow-x: auto;
   overflow-y: hidden;
  }
</style>
