export default {
  activities: 'Aktywności',
  activityType: 'Rodzaj aktywności',
  addActivity: 'Zaplanuj aktywność',
  add: 'Dodaj aktywność',
  edit: 'Edytuj aktywność',
  addChangeover: 'Dodaj przezbrojenie',
  step: 'Krok',
  order: 'Zlecenie',
  general: 'Ogólne',
  quantity: 'Ilość',
  dates: 'Daty',
  configuration: 'Konfiguracja',
  cleaning: 'Czyszczenie',
  changeover: 'Przezbrojenie',
  plannedStart: 'Planowany start',
  plannedEnd: 'Planowane zakończenie',
  activityId: 'ID aktywności',
  activity: 'Aktywność',
  activityStart: 'Start aktywności',
  toStart: 'do startu',
  afterStart: 'po starcie',
  activityAlreadyExists: 'ID aktywności już istnieje',
  activityIdIsTooLong: 'ID aktywności jest za długie',
  changeoverDuration: 'Czas trwania przezbrojenia (minuty)',
  manage: 'Zarządzaj aktywnościami',
  changeoverType: 'Typ przezbrojenia',
  editExisting: 'Edytuj istniejące',
  pickALine: 'Wybierz Linię',
  typeEffort: 'Uzupełnij roboczogodziny',
  showNotPlanned: 'Pokaż nieplanowane aktywności',
  summary: {
    summary: 'Podsumowanie',
    orderTotal: 'CAŁKOWITE ZLECENIE',
    lineTotal: 'CAŁKOWICIE NA LINII',
    inLineUnit: 'w jednostce linii',
    inOrderUnit: 'w jednostce zlecenia',
    goodCount: 'PRODUKCJA',
    wasteCount: 'STRATY',
    counterTotal: 'Suma Licznika',
    performanceGuard: 'Skoki Wydajności',
    performanceGuardTippy: 'Wydajność przekroczyła założone progi',
    lack: 'BRAKI',
    failure: 'AWARIA',
    missingPackingStructure: 'Brakująca struktura pakowania',
    manualReconciliation: 'Manualna rekoncyliacja',
  },
  list: {
    order: 'Zlecenia',
    cleaning: 'Czyszczenia',
    changeover: 'Przezbrojenia',
  },
  activityAdded: 'Aktywność dodana',
  noActivities: 'Brak aktywności',
  status: {
    draft: 'Szkic',
    released: 'Zwolnione',
    started: 'Rozpoczęty',
    suspended: 'Zawieszony',
    completed: 'Zakończony',
    activated: 'Aktywowany',
    scheduled: 'Zaplanowany',
    notPlanned: 'Niezaplanowane',
  },
  schedule: {
    tableView: 'Tabela',
    axisView: 'Oś czasu',
    full: 'Pełny',
    collapsed: 'Zwinięty',
    scheduled: 'Zaplanowany',
    previousHour: 'Poprzednia godzina',
    currentHour: 'Koniec godziny',
    previousShift: 'Poprzednia zmiana',
    currentShift: 'Trwająca zmiana',
  },
  resources: {
    production: 'Produkcja',
    waste: 'Straty',
    manhours: 'Roboczo-godziny',
    timeScope: 'Zakres czasu',
    workers: 'Pracownicy',
    worker: 'Pracownik',
    hours: 'godziny',
    minutes: 'minuty',
  },
  actions: {
    finishChangeover: 'Zakończ i wystartuj zlecenie',
    abortChangeover: 'Przerwij przezbrojenie',
    productionReconciliation: 'Rekoncyliacja produkcji',
    finishOrder: 'Zakończ zlecenie',
  },
  productionReconciliation: {
    increase: 'Zwiększenie',
    fromTo: 'Od - Do',
    rawCounter: 'Licznik',
    who: 'Kto',
    lastRefresh: 'Ostatnie odświeżenie',
    saveEntry: 'Zapisz wpis',
    arithmeticExpressionsInfoBoxText: 'W polach wprowadzania możesz używać prostych wyrażeń arytmetycznych, na przykład "100-10". Po naciśnięciu przycisku "Zapisz wpis" system zapisze i policzy wartość.',
    entryWithDifferentUnitError: 'Jednostki niektórych wpisów różnią się od jednostek zamówienia. Aby dodać nowy wpis, usuń wpis z inną jednostką.',
    orderUnit: 'Jednostka zlecenia',
  },
  validations: {
    orderNotFound: 'Brak przypisanego zlecenia',
    externalIdError: 'ID może się składać tylko z cyfr, liter, znaków \'-\', \'/\' i \'_\' oraz zaczynać się od cyfry lub litery',
  },
  deleteConfirmMessage: 'Czy na pewno chcesz usunąć tę aktywność?',
  hasValidationErrors: 'Posiada błędy walidacyjne',
  noLine: 'Brak linii',
  editOrder: 'Edytuj zlecenie',
  editChangeover: 'Edytuj przezbrojenie',
  cannotCreateOrder: 'Nie można utworzyć zlecenia',
  orderExists: 'Zlecenie z podanym Activity ID już istnieje.',
  endOfLastShift: 'Koniec ostatniej zmiany',
  endOfLastHour: 'Koniec ostatniej godziny',
  productionHistory: 'Historia produkcji',
  manhoursHistory: 'Historia roboczogodzin',
  changeoverDurationTitle: 'Długość przezbrojenia',
  expectedEffort: 'Spodziewane roboczogodziny',
  expectedChangeoverEffort: 'Spodziewane roboczogodziny przezbrojenia',
  typeCorrectExpectedEffort: 'Spodziewane roboczogodziny nie mogą być ujemne',
  typeCorrectValue: 'Wartość nie może być ujemna',
  typeChangeoverDurationIfChangeoverExpectedEffortGiven: 'Czas trwania przezbrojenia jest wymagany, jeżeli spodziewane roboczogodziny przezbrojenia są podane',
  editMode: 'Tryb edycji',
  validationErrors: {
    skuError: 'Nie wybrany produkt lub jest on nieprawidłowo skonfigurowany',
    flowIdError: 'Brak konfiguracji przepływu',
    endDateNotValid: 'Planowana data zakończenia jest nieprawidłowa',
    lineError: 'Brak konfiguracji linii',
    orderedQuantityError: 'Brak zamówionej ilości',
    orderedQuantityUnitError: 'Brak zamówionej jednostki ilości lub jest ona nieprawidłowo skonfigurowana',
  },
};
