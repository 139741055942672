import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import { CLIPS_DELETE, CLIPS_UPDATE } from '@/store/mutation-types';
import { plantProperty } from '@/utils/dictionary';
import lang from '@/lang';

const store = new Vapi({
  axios,
  state: {
    visionElementClips: [],
    visionElementCameras: [],
    visionTenantCameras: [],
  },
})
  .get({
    action: 'getElementClips',
    property: 'visionElementClips',
    path: ({
      elementId, startDate, endDate,
    }) => `/v2/vision/clip/element/${elementId}/${startDate}/${endDate}`,
  })
  .get({
    action: 'getCameraClips',
    path: ({
      cameraId, startDate, endDate,
    }) => `/v2/vision/clip/camera/${cameraId}/${startDate}/${endDate}`,
  })
  .get({
    action: 'getCameras',
    property: 'visionElementCameras',
    path: () => '/v2/vision/camera',
  })
  .get({
    action: 'getTenantCameras',
    property: 'visionTenantCameras',
    path: () => '/v2/vision/camera',
    onSuccess: (s, { data }) => {
      s.visionTenantCameras = data.sort((a, b) => (a.description || a.name)
        .localeCompare(b.description || b.name));
    },
  })
  .post({
    action: 'clipRequest',
    path: () => '/v2/vision/clip/request',
  })
  .getStore();

store.mutations[CLIPS_UPDATE] = s => {
  if (!s.visionElementClips) return;
  s.visionElementClips.forEach(clip => {
    s.visionElementCameras.cameras.forEach(camera => {
      if (clip.cameraId === camera.cameraId) {
        Vue.set(clip, 'cameraDescription', camera.description);
      }
    });
  });
};

store.mutations[CLIPS_DELETE] = s => {
  s.visionElementClips = null;
};

store.getters = {
  elementHaveLiveStream: (s, g, rs, rg) => elementId => {
    const showVision = rg['plant/plantProperty'](plantProperty.vision) === 'true';
    if (!showVision || !s.visionTenantCameras) return false;
    const cameras = s.visionTenantCameras;
    if (!cameras || cameras.length === 0) return false;

    const elCameras = cameras
      .filter(e => e.elementId === elementId);

    return elCameras ? elCameras.some(c => !!c.liveStreamPath) : false;
  },
  downloadStatus: () => ({
    Success: {
      name: 'Success',
      icon: 'ion ion-md-download',
      badge: 'badge badge-success',
      text: lang.t('downloadStatus.success'),
    },
    InProgress: {
      name: 'InProgress',
      icon: 'ion ion-md-calendar',
      badge: 'badge badge-info',
      text: lang.t('downloadStatus.inProgress'),
    },
    Schedule: {
      name: 'InProgress',
      icon: 'ion ion-md-calendar',
      badge: 'badge badge-info',
      text: lang.t('downloadStatus.inProgress'),
    },
    FailedOnEdge: {
      name: 'FailedOnEdge',
      icon: 'ion ion-ios-checkmark-circle-outline',
      badge: 'badge badge-danger',
      text: lang.t('downloadStatus.failedOnEdge'),
    },
    NoArchiveRecordings: {
      name: 'NoArchiveRecordings',
      icon: 'ion ion-md-close',
      badge: 'badge badge-dark',
      text: lang.t('downloadStatus.noArchiveRecordings'),
    },
    Deleted: {
      name: 'Deleted',
      icon: 'ion ion-md-clipboard',
      badge: 'badge badge-warning',
      text: lang.t('downloadStatus.deleted'),
    },
  }),
};

export default store;
