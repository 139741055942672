<template>
  <SimpleScheduleLog
    ref="chart"
    :start-date="startDate"
    :end-date="endDate"
    :log="log"
    :show-warnings="false"
    :height="30"
    :no-utc-offset="noUtcOffset"
    class="productionScheduleLog"
  >
    <div slot-scope="scope">
      <OrderPopover
        :order="scope.block.item"
        class="text-left"
      />
    </div>
  </SimpleScheduleLog>
</template>

<script>
import SimpleScheduleLog from '@/components/schedule/SimpleScheduleLog';
import OrderPopover from '@/components/schedule/popovers/OrderPopover';
import { activityStatus } from '@/utils/dictionary';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    startDate: Number,
    endDate: Number,
    elementId: String,
    noUtcOffset: Boolean,
  },
  data: () => ({
    orders: [],
    pending: false,
  }),
  components: {
    SimpleScheduleLog,
    OrderPopover,
  },
  computed: {
    ...mapGetters(['plantId', 'getOrderState', 'getActivityState']),
    ...mapGetters('plant', ['lines']),
    ...mapGetters('element', ['findLineId']),

    dates() {
      return {
        start: this.startDate,
        end: this.endDate,
      };
    },
    log() {
      if (!this.orders) return [];
      return this.orders
        .filter(a => a.status !== activityStatus.scheduled)
        .map(a => ({
          start: moment.unix(this.getStartDate(a)).startOf('minute').unix(),
          end: moment.unix(this.getEndDate(a)).startOf('minute').unix(),
          color: this.getTypeColor(a),
          type: a.type,
          name: a.externalId || a.description,
          id: a.id,
          item: {
            ...a,
          },
        }))
        .filter(a => a.start < this.endDate && a.end > this.startDate)
        .sort((a, b) => a.start - b.start);
    },
  },
  watch: {
    dates() {
      this.request();
    },
    elementId() {
      this.request();
    },
  },
  methods: {
    ...mapActions(['getOrdersBetween']),
    request() {
      let ids = this.lines.map(x => x.id);
      if (this.elementId !== this.plantId) {
        ids = [this.findLineId(this.elementId) || this.elementId];
      }
      this.pending = true;

      this.getOrdersBetween({
        params: {
          query: {
            plantId: this.plantId,
            fromEpoch: this.startDate,
            toEpoch: this.endDate,
            lineIds: ids,
          },
        },
      })
        .then(({ data }) => {
          this.$emit('orders', data);
          this.orders = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    getTypeColor(o) {
      const state = this.getActivityState(o);
      return state ? state.color : this.$color('grey');
    },
    getStartDate(order) {
      const activated = order.actualActivation?.begin;
      if (order.status === activityStatus.activated) {
        return Math.max(this.startDate, (moment(activated).unix() || this.startDate));
      }

      if (order.status === activityStatus.released && order.scheduledExecution) {
        return Math.max(
          this.startDate,
          moment(order.scheduledExecution.begin).unix(),
        );
      }

      return Math.max(
        this.startDate,
        moment(order.actualExecution?.begin).unix(),
      );
    },
    getEndDate(order) {
      const end = this.endDate || this.$now;

      if (order.status === activityStatus.activated) {
        return Math.min(end, this.$now);
      }

      if (order.status === activityStatus.started) {
        const eta = order.estimatedEnd ? moment(order.estimatedEnd).unix() : null;
        return Math.min(end, (eta || this.$now));
      }

      const orderEnd = Math.min(
        (moment(order.actualExecution?.end || order.scheduledExecution?.end).unix() || end), end,
      );
      return Math.min(end, orderEnd);
    },
  },
  created() {
    this.request();
  },
};
</script>

<style>
  @media (max-width: 500px) {
    .productionScheduleLog .step:nth-child(odd) {
      display: none;
    }
  }
</style>
