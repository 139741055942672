export default {
  criticalPleaseRefresh: 'Oh zut ! Une erreur s’est produite, essayez d’actualiser la page',
  reasonRequired: 'Créer ou sélectionner la raison en premier',
  descriptionIsRequired: 'La description est requise',
  error: 'Erreur',
  wrongInput: 'Les données données ne sont pas valides!',
  importNotAvailable: 'L’importation n’est pas disponible actuellement. Veuillez réessayer plus tard',
  invalidHour: 'Heure non valide',
  packageAddError: 'Package non valide',
  code: {
    NoRequiredComponents: 'Manque de composants requis',
    NoTrackingId: 'Aucun identifiant de suivi pour le suivi des colis'
  },
  toShortPassword: 'Le mot de passe doit comporter au moins de caractères {length}',
  passwordsMustBeEqual: 'Les mots de passe doivent être identiques !',
  jobFailed: 'Une erreur s’est produite lors du traitement du fichier',
  unableToDownloadData: 'Impossible de télécharger les données demandées',
  unknown: 'Erreur inconnue',
  'importError': 'Aucun élément à importer n\'a été trouvé. Modèle d\'importation incorrect ?',
  'nameExist': 'Le prénom existe déjà !',
  'numberExist': 'Le numéro donné existe déjà !',
  'invalidAuthUrl': 'URL d\'authentification invalide',
  'manualProductionNoLongerThan15Days': 'Vous ne pouvez pas éditer une production manuelle pendant plus de 15 jours',
};
