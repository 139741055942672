<template>
  <Loader class="home-loader" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('core', ['userSetting', 'userSettings']),
    deviceHome() {
      return localStorage.getItem('home');
    },
    userHome() {
      return this.userSetting('home');
    },
  },
  watch: {
    userSettings() {
      this.redirect();
    },
  },
  methods: {
    redirect() {
      const home = this.userHome || '/world';
      this.$router.push(home);
    },
  },
  created() {
    if (this.deviceHome) {
      this.$router.push(this.deviceHome);
    } else if (this.userSettings) {
      this.redirect();
    }
  },
};
</script>

<style scoped>
  .home-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -17px;
    margin-top: -20px;
  }
</style>
