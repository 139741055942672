<template>
  <div
    style="min-height: 100%"
    class="d-flex flex-column position-relative"
  >
    <slot>
      <div class="d-flex justify-content-between">
        <button
          v-if="canEditOrders"
          :title="$t('activities.manage')"
          class="btn btn-ilabo btn-sm icon-btn"
          @click="manageActivities()"
        >
          <i class="far fa-calendar-check" />
        </button>
        <div v-else />

        <div class="text-fancy line-name">
          <ElementPicture
            :element-id="lineId"
          />
          {{ elementName(lineId) }}
        </div>

        <div style="width: 25px">
        &nbsp;
        </div>
      </div>
    </slot>

    <div
      v-if="pending || ordersError"
      class="pt-4"
    >
      <Loader :error="ordersError" />
    </div>
    <Transition
      v-else
      name="slide"
    >
      <div v-if="settle">
        <OrderSettlementForm
          :order="edited"
          @close="settle = false"
          @completed="handleOrderSettlement"
        />
      </div>
      <div
        v-else
        class="d-flex flex-column justify-content-between flex-grow-1"
      >
        <div
          v-if="activities"
          class="flex-grow-1"
        >
          <OrdersByStatus
            :line-id="lineId"
            :list="filteredActivities"
            :actions="false"
            :selected-id.sync="selectedId"
            :status.sync="status"
            :hide-element="true"
            @components="components = true; editedId = $event"
            @refresh="refresh()"
            @settle="settle = true; editedId = $event"
            @edit="form = true; editedId = $event"
            @audit="audit = true; editedId = $event"
            @showProductionStats="$emit('showProductionStats',$event)"
          />
        </div>
        <div v-else>
          <h3
            class="text-center my-4"
            style="font-size: 20px"
          >
            <i class="ion ion-ios-alert" /> <br>
            {{ $t('orders.noActiveOrPlannedOrders') }}
          </h3>
        </div>
      </div>
    </Transition>

    <LModal
      v-if="!settle"
      :show.sync="showDetails"
      size="xl"
    >
      <OrderDetails
        :order="edited"
        @settle="settle = true"
      />
    </LModal>
    <LModal
      :show.sync="audit"
      size="xl"
    >
      <AuditLog
        :activity-id="editedId"
      />
    </LModal>
  </div>
</template>

<script>
import AuditLog from '@/components/audit/AuditLog';
import Graph from '@/components/graph/Graph';
import OrderDetails from '@/components/order/OrderDetails';
import loopRequest from '@/mixins/loopRequest';
import { activityStatus } from '@/utils/dictionary';
import { PACKOS_ORDER_EDIT, PACKOS_ORDER_MANAGEMENT } from '@core/dict/permissions';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import OrderSettlementForm from '../OrderSettlementForm';
import OrdersByStatus from '../OrdersByStatus';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
    orderId: String,
    initState: String,
    hideComplete: Boolean,
    hidePending: Boolean,
    refreshNumber: Number,
  },
  data: () => ({
    pending: false,
    ordersError: null,
    flows: [],
    currentOrder: null,
    components: false,
    selectedId: null,
    settle: false,
    editedId: null,
    status: '',
    audit: false,
    showDetails: false,
    dayStart: null,
    dayEnd: null,
  }),
  components: {
    OrderDetails,
    OrderSettlementForm,
    OrdersByStatus,
    AuditLog,
  },
  mixins: [loopRequest('requestOrders', 10000)],
  computed: {
    ...mapGetters(['plantId', 'startDate', 'endDate']),
    ...mapGetters('plant', ['structure']),
    ...mapGetters('element', ['elementName']),
    ...mapGetters('core', ['canOnElement']),
    ...mapState({
      activities: state => state.activities.activities,
    }),
    filteredActivities() {
      return this.activities.filter(({ lineId }) => lineId === this.lineId);
    },
    queryChange() {
      return this.$route.query?.orderNumber;
    },
    edited() {
      return this.activities?.find(x => x.id === this.editedId);
    },
    canEditOrders() {
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.lineId)
        || this.canOnElement(PACKOS_ORDER_EDIT, this.lineId);
    },
  },
  watch: {
    refreshNumber() {
      this.refresh();
    },
    selectedId(v) {
      this.$emit('update:selectedId', v);
    },
    queryChange(val) {
      if (val) {
        const { query } = this.$route;
        this.dayStart = moment.unix(query.created).startOf('day').unix();
        this.dayEnd = moment.unix(query.created).endOf('day').unix();
        this.requestOrders();
      }
    },
    filteredActivities() {
      const { query } = this.$route;
      if (query && query.orderNumber) {
        const selectedActivity = this.filteredActivities.find(x => x.externalId === query.orderNumber);
        this.selectedId = selectedActivity?.id;
        this.status = selectedActivity?.status;
      }
    },
  },
  methods: {
    ...mapActions([
      'getActivitiesBetween',
      'getDashboardData',
    ]),
    ...mapActions('plant', ['getGraphs']),
    refresh() {
      setTimeout(() => {
        this.requestOrders();
        this.refreshLineData();
      }, 1000);
    },
    requestOrders() {
      return this.getActivitiesBetween({
        params: {
          query: {
            lineIds: [this.lineId],
            fromEpoch: this.dayStart || this.startDate,
            toEpoch: this.dayEnd || this.endDate,
            plantId: this.plantId,

          },
        },
      })
        .catch(res => {
          if (res.response && res.response.data) {
            this.ordersError = res.response.data;
          }
        })
        .finally(() => {
          this.pending = false;
          const { query } = this.$route;
          if (query && query.orderNumber) {
            const selectedActivity = this.filteredActivities.find(x => x.externalId === query.orderNumber);
            this.selectedId = selectedActivity?.id;
            this.status = selectedActivity?.status;
          }
        });
    },
    requestGraphs() {
      this.getGraphs({
        params: {
          plantId: this.plantId,
          elementId: this.lineId,
        },
      })
        .then(({ data }) => {
          this.flows = data.map(f => {
            const nodes = f.nodes.map(n => this.structure.node(n));
            const g = new Graph(f.id, nodes, f.edges, f.elementId);
            g.name = f.name;
            return g;
          });
        });
    },
    refreshLineData() {
      return this.getDashboardData({
        params: {
          plantId: this.plantId,
          startDate: this.dayStart || this.startDate,
          endDate: this.dayEnd || this.endDate,
          query: {
            id: this.lineId,
          },
        },
      })
        .then(({ data }) => {
          this.$store.commit('plant/updateElements', {
            elements: data,
            disabledIds: data.length === 0 ? [this.lineId] : [],
          });
        });
    },
    manageActivities() {
      this.$router.push({ name: 'activities', query: { lineIds: [this.lineId] } });
    },
    startOrderFromComponents() {
      this.refresh();
      this.components = false;
    },
    handleOrderSettlement() {
      this.refresh();
      this.status = activityStatus.completed;
      this.showDetails = false;
      this.settle = false;
    },
  },
  created() {
    this.pending = true;
    this.requestGraphs();
    const { query } = this.$route;

    if (this.orderId) {
      this.selectedId = this.orderId;
    }
    this.status = this.initState;

    if (query && query.created) {
      this.dayStart = moment.unix(query.created).startOf('day').unix();
      this.dayEnd = moment.unix(query.created).endOf('day').unix();
      this.requestOrders();
    }
  },
};
</script>

<style scoped>

  .machine-picture {
    max-height: 40px;
    padding: 5px;
  }

  .line-name {
    font-size: 20px;
    font-weight: 600;
  }

  .slide-enter-active {
    transition: opacity .5s;
  }
  .slide-leave-active {
    transition: opacity 0;
  }

  .slide-enter, .slide-leave-to {
    opacity: 0;
  }

</style>
