import Vue from 'vue';

export const getUpdaterFor = (dataType, indexType) => (store, param) => {
  if (!store[dataType]) return;
  if (!param) throw new (function ex() { this.message = 'When updating no param index was specified'; })();
  // eslint-disable-next-line eqeqeq
  const index = store[dataType].findIndex(el => el[indexType] == param[indexType]);
  if (index !== -1) {
    Vue.set(store[dataType], index, param);
  } else {
    store[dataType].push(param);
  }
};

export const getDeleterFor = (dataType, indexType) => (store, id) => {
  if (!store[dataType]) return;
  if (!id) throw new (function ex() { this.message = 'When deleting no param index was specified'; })();
  // eslint-disable-next-line eqeqeq
  const index = store[dataType].findIndex(el => el[indexType] == id);
  if (index !== -1) {
    Vue.delete(store[dataType], index);
  }
};
