import lang from '@/lang';
import notifications from './notifications';
import subscriptions from './subscriptions';

export default {
  namespaced: true,
  state: {
    explorer: null,
  },
  mutations: {
    notificationExplorer(s, explorerData) {
      s.explorer = explorerData;
    },
  },
  getters: {
    notificationTypes: () => [
      {
        id: 1,
        name: lang.t('notify.type.userNotifications'),
        icon: 'ion ion-ios-notifications',
      },
      {
        id: 2,
        name: lang.t('notify.type.hints'),
        icon: 'far fa-lightbulb',
      },
      {
        id: 4,
        name: lang.t('notify.type.warnings'),
        icon: 'fas fa-exclamation',
      },
    ],
  },
  modules: {
    notifications,
    subscriptions,
  },
};
