import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    elements: {},
    plantGraphs: [],
  },
})
  .get({
    action: 'getPlantGraphs',
    path: ({ plantId }) => `/v2/plant/${plantId}/graphs`,
  })
  .get({
    action: 'getGraphs',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/graphs`,
  })
  .get({
    action: 'getGraph',
    path: ({ plantId, graphId }) => `/v2/plant/${plantId}/graph/${graphId}`,
  })
  .post({
    action: 'createGraph',
    path: ({ plantId }) => `/v2/plant/${plantId}/graph`,
  })
  .delete({
    action: 'deleteGraph',
    path: ({ plantId, graphId }) => `/v2/plant/${plantId}/graph/${graphId}`,
  })
  .put({
    action: 'updateGraphName',
    path: ({ plantId, graphId, graphName }) => `/v2/plant/${plantId}/graph/${graphId}/${graphName}`,
  })
  .put({
    action: 'updateGraphDetails',
    path: ({ plantId, graphId }) => `/v2/plant/${plantId}/graph/${graphId}/details`,
  })
  .put({
    action: 'updateGraphEdges',
    path: ({ plantId, graphId }) => `/v2/plant/${plantId}/graph/${graphId}/edges`,
  })
  .post({
    action: 'activateElementGraph',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/graph/activate`,
  })
  .post({
    action: 'deactivateElementGraph',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/graph/deactivate`,
  })
  .put({
    action: 'startGraphArchive',
    path: ({ plantId, graphId }) => `/v2/plant/${plantId}/graph/${graphId}/archive/changes/start`,
  })
  .put({
    action: 'stopGraphArchive',
    path: ({ plantId, graphId }) => `/v2/plant/${plantId}/graph/${graphId}/archive/changes/stop`,
  })
  .post({
    action: 'addGraphNode',
    path: ({ plantId, graphId, nodeId }) => `/v2/plant/${plantId}/graph/${graphId}/node/${nodeId}`,
  })
  .delete({
    action: 'removeGraphNode',
    path: ({ plantId, graphId, nodeId }) => `/v2/plant/${plantId}/graph/${graphId}/node/${nodeId}`,
  })
  .post({
    action: 'addGraphEdge',
    path: ({
      plantId, graphId, from, to,
    }) => `/v2/plant/${plantId}/graph/${graphId}/edge/${from}/${to}`,
  })
  .delete({
    action: 'removeGraphEdge',
    path: ({
      plantId, graphId, from, to,
    }) => `/v2/plant/${plantId}/graph/${graphId}/edge/${from}/${to}`,
  })
  .getStore();

store.mutations.savePlantGraphs = (s, graphs) => {
  s.plantGraphs = graphs;
};

store.mutations.graphsUpdate = (s, graphs) => {
  graphs.forEach(g => {
    const index = s.plantGraphs.findIndex(pg => pg.id === g.id);
    if (index === -1) {
      s.plantGraphs.push(g);
    } else {
      Vue.set(s.plantGraphs, index, g);
    }
  });
};

store.mutations.graphEdgeAdd = (s, { graphId, from, to }) => {
  if (!s.plantGraphs) return;
  const graph = s.plantGraphs.find(g => g.id === graphId);
  if (!graph) return;
  graph.edges.push({ from, to });
};

store.mutations.graphEdgeRemove = (s, { graphId, from, to }) => {
  if (!s.plantGraphs) return;
  const graph = s.plantGraphs.find(g => g.id === graphId);
  graph.edges = graph.edges.filter(e => !((e.from === from && e.to === to) || (e.to === from && e.from === to)));
};

store.getters = {
  plantGraphs: s => s.plantGraphs,
  flowLayouts: (_, g) => JSON.parse(g.plantProperty('FLOWS_LAYOUT') || '{}'),
  flowLayout: (_, g) => id => g.flowLayouts[id],
};

export default store;
