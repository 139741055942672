import lang from '@/lang';
import { addSpaces } from '@core/utils/numbers';
/*
  Calculate the difference in seconds between two timestamps.
  When end is not defined, takes the current time.
*/

const getDuration = (begin, end) => (end ? (end - begin) : (Math.floor(new Date().getTime() / 1000) - begin));

export function formatDuration(duration, toHours, toMinutes) {
  let remaining = Math.floor(duration);

  if (remaining < 0) return '0 s';
  // return in seconds
  if (remaining < 60) return `${remaining} s`;
  const seconds = Math.floor(remaining) % 60;
  remaining = Math.floor(remaining / 60);
  // return in minutes and seconds
  if (remaining < 60 || toMinutes) {
    if (seconds === 0) {
      return `${remaining} min`;
    }

    return `${addSpaces(remaining)} min ${seconds} s`;
  }
  const minutes = remaining % 60;
  remaining = Math.floor(remaining / 60);
  // return in hours and minutes
  if (remaining < 48 || toHours) {
    if (minutes === 0) {
      return `${addSpaces(remaining)} h`;
    }

    return `${addSpaces(remaining)} h ${minutes} min`;
  }
  remaining = Math.floor(remaining / 24);

  if (remaining > 30) {
    return `30+ ${lang.t('time.days')}`;
  }

  // return in days
  return `${remaining} ${lang.t('time.days')}`;
}

export default (begin, end) => {
  if (!begin) return null;
  const duration = getDuration(begin, end);

  return formatDuration(Math.floor(duration), false);
};
