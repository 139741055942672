import axios from '@/api/axios';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    workStates: {},
  },
})
  .put({
    action: 'updateWorkState',
    path: ({ plantId, workStateId, name }) =>
      `/v2/plant/${plantId}/workState/${workStateId}/name/${name}`,
    onSuccess: (s, payload, _, { params }) => {
      const state = s.workStates[params.workStateId];
      if (!state) return;
      Vue.set(state, 'description', params.name);
    },
  })
  .put({
    action: 'updateWorkStateColor',
    path: ({ plantId, workStateId, colorCode }) =>
      `/v2/plant/${plantId}/workState/${workStateId}/color/${colorCode}`,
    onSuccess: (s, payload, _, { params }) => {
      const state = s.workStates[params.workStateId];
      if (!state) return;
      Vue.set(state, 'colorCode', decodeURIComponent(params.colorCode));
    },
  })
  .getStore();

store.mutations.setWorkStates = (s, list) => {
  s.workStates = {};
  list.forEach(ws => {
    s.workStates[ws.id] = ws;
  });
};

store.getters = {
  workState: s => id => s.workStates[id],
  workStates: s => Object.values(s.workStates),
  workStateByName: s => name => Object.values(s.workStates)
    .find(st => st.name.toUpperCase() === (name || '').toUpperCase()),
  workStateOfEvent: (_, g) => eventId => {
    const event = g.workEvent(eventId);
    if (!event) return null;
    return g.workState(event.workStateId);
  },
};

export default store;
