<template>
  <div
    v-if="meta && meta.signals"
    class="d-flex flex-wrap flex-grow-1 justify-content-center align-self-center w-100"
    style="max-width: 600px;"
  >
    <div
      v-for="signal in meta.signals"
      :key="signal.name"
      class="py-1 px-3 col"
      style="min-width: 141px; max-width: 141px"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        style="height: 20px"
      >
        <div class="signal-name">
          {{ signal.description }}
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-center"
        style="height: 35px"
      >
        <div
          v-if="signal.max && signal.max > signal.min"
          style="width: 35px; height: 35px"
        >
          <OEE
            :value="signal.value | parseNumber"
            :max="signal.max | parseNumber"
            :min="signal.min | parseNumber"
            :mono="true"
            size="xs"
            style="height: 35px"
          >
            <span />
          </OEE>
        </div>
        <div class="text-nowrap font-weight-bold pl-1">
          {{ signal.value | roundIfNumber }} {{ signal.unit }}
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!name">
    <Loader />
  </div>
</template>

<script>
import OEE from '@/components/charts/oees/OEE';

export default {
  props: {
    meta: Object,
    name: String,
  },
  filters: {
    parseNumber(v) {
      return Number.parseFloat(v);
    },
    roundIfNumber(v) {
      const num = Number.parseFloat(v);
      if (Number.isNaN(num)) return v;
      return Math.round(v * 10) / 10;
    },
  },
  components: {
    OEE,
  },
};
</script>

<style lang="scss" scoped>

  .signal-name {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1;
  }
</style>
