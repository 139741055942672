<template>
  <div>
    <div>
      <div class="label">
        {{ $t('orders.quantity') }}:
      </div>
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <BFormInput
            ref="done"
            :value="done"
            :number="true"
            step="any"
            :state="doneState"
            type="number"
            class="text-right"
            @input="emitNumber('update:done', $event)"
            @click="$refs.done.select()"
          />
        </div>
        <div class="unit-label pl-2">
          {{ unit }}
        </div>
      </div>
    </div>

    <div class="pt-2">
      <div class="label text-danger">
        {{ $t('orders.wasted') }}:
      </div>
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <BFormInput
            ref="wasted"
            class="text-danger text-right"
            :value="wasted"
            :state="wastedState"
            :number="true"
            step="any"
            type="number"
            @input="emitNumber('update:wasted', $event)"
            @click="$refs.wasted.select()"
          />
        </div>
        <div class="unit-label pl-2">
          {{ unit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    done: Number,
    wasted: Number,
    unit: String,
  },
  data() {
    return {
      baseProduction: 0,
    };
  },
  computed: {
    wastedState() {
      return this.wasted >= 0 ? null : false;
    },
    doneState() {
      return this.done >= 0 ? null : false;
    },
  },
  methods: {
    emitNumber(eventName, v) {
      const val = parseFloat(v);
      if (!Number.isNaN(val)) {
        this.$emit(eventName, val);
      } else {
        this.$emit(eventName, 0);
      }
    },
  },
  created() {
    this.baseProduction = this.done;
  },
};
</script>

<style scoped>
  h4 {
    font-size: 15px;
  }

  .label {
    font-weight: bold;
    font-size: 14px;
  }

  .unit-label {
    font-size: 1rem;
    font-weight: normal;
  }
</style>
