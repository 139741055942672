/*
  graph: Graph,
  layout: Array of { x, y} nodes
  container: { width, height, overflow }
  box: { maxHeight, maxWidth, forceSquare }
  padding: as % of box size
*/
export function nodePositions(graph, layout, container, box, padding, margin) {
  const { maxHeight, maxWidth, forceSquare } = box;

  if (!graph) return [];
  const ids = graph.nodeList().map(e => e.id);
  if (ids.length === 0) return [];

  // get x/y positions
  let positions = {};
  if (layout) {
    positions = layout.reduce((acc, curr) => {
      acc[curr.id] = { x: curr.x, y: curr.y };
      return acc;
    }, {});
  } else {
    positions = graph.getPositionsForNodes(ids);
  }

  // Find max X and Y coordinate
  const {
    maxX, minX, maxY, minY,
  } = Object.values(positions)
    .reduce((acc, { x, y }) => ({
      maxX: Math.max(acc.maxX, x),
      maxY: Math.max(acc.maxY, y),
      minX: Math.min(acc.minX, x),
      minY: Math.min(acc.minY, y),
    }), {
      maxX: 0, maxY: 0, minX: 9999, minY: 9999,
    });

  let width = maxWidth || maxHeight || 999999;
  let height = maxHeight || width || 999999;

  if (container.width && !container.overflow) {
    width = Math.min(width, container.width / ((maxX - minX) + 1));
  }
  if (container.height && !container.overflow) {
    height = Math.min(height, container.height / ((maxY - minY) + 1));
  }
  if (forceSquare) {
    width = Math.min(height, width);
    height = width;
  }
  if (margin) {
    width += margin;
    height += margin;
  }

  const dx = (maxX - minX + 1) / 2;
  const paddingX = (width / (padding || 10));
  const paddingY = (height / (padding || 10));
  const containerWidth = container.width || dx * width * 2;
  const offsetToCenter = (containerWidth / 2) - (dx * width);

  return ids
    .reduce((acc, id) => {
      const pos = positions[id];
      const { x, y } = pos || { x: 0, y: 0 };

      acc[id] = {
        x: (x * width) - (minX * width) + offsetToCenter + (paddingX / 2),
        y: (y * height) - (minY * height) + (paddingY / 2),
        w: width - paddingX,
        h: height - paddingY,
      };
      return acc;
    }, {});
}

export function graphWidth(positions) {
  const { min, max, lastWidth } = Object.values(positions)
    .reduce((acc, curr) => ({
      max: Math.max(curr.x, acc.max),
      min: Math.min(curr.x, acc.min),
      lastWidth: curr.w,
    }), { min: 99999, max: 0, lastWidth: 0 });

  return (max - min) + lastWidth;
}

export function graphHeight(positions) {
  const { min, max, lastHeight } = Object.values(positions)
    .reduce((acc, curr) => ({
      max: Math.max(curr.y, acc.max),
      min: Math.min(curr.y, acc.min),
      lastHeight: curr.h,
    }), { min: 99999, max: 0, lastHeight: 0 });

  return (max - min) + lastHeight;
}

const getPos = (id, positions) => {
  const pos = positions[id];
  if (!pos) return null;
  return {
    x: pos.x + pos.w / 2,
    y: pos.y + pos.h / 2,
    h: pos.h,
    w: pos.w,
  };
};

export function getPath(edge, positions, center) {
  if (!positions) return null;
  const from = getPos(edge.from, positions);
  const to = getPos(edge.to, positions);
  const offsetRatio = 8 / 10;
  if (!from || !to) return null;
  let fromY = from.y;
  let toY = to.y;

  if (!center) {
    if (fromY - from.h / 2 > toY) {
      fromY -= (from.h / 2) * offsetRatio;
      toY += (to.h / 2) * offsetRatio;
    } else if (fromY < toY - (to.h / 2)) {
      fromY += (from.h / 2) * offsetRatio;
      toY -= (to.h / 2) * offsetRatio;
    }
  }

  return {
    fromPos: {
      x: from.x,
      y: fromY,
    },
    toPos: {
      x: to.x,
      y: toY,
    },
  };
}

export default {
  nodePositions,
};
