export default {
  nothing: 'Без уведомлений',
  list: 'Список',
  subscribedOnly: 'Только по подписке',
  subscription: 'Подписка',
  addSubscription: 'Добавить подписку',
  notificationType: 'Тип уведомлений',
  pushNotSupported: 'Уведомления не поддерживаются в этой среде',
  pushDenied: 'Уведомления заблокированы',
  enablePush: 'Включить push-уведомления',
  pushDisabled: 'Push-уведомления отключены',
  pushEnabled: 'Push-уведомления включены',
  outdated: 'Устаревший сис',
  wastedThreshold: 'Порог отходов',
  threshold: 'Порог',
  mailing: 'Получать по электронной почте',
  mySubscriptions: 'Мои подписки',
  usersSubscriptions: 'Подписки пользователей',
  teamsSubscriptions: 'Подписки на команды',
  numberOfSubs: 'Количество подписок',
  subscriptionFromTeam: 'Эта подписка исходит от группы и может быть изменена только для всей команды пользователем с соответствующими разрешениями.',
  type: { hints: 'Подсказки', warnings: 'Предупреждения', userNotifications: 'Уведомления' },
  name: {
    cmmsNotification: 'Техническое обслуживание', connectionLost: 'Соединение с машиной потеряно', availabilityInsight: 'Статистика доступности', ongoingStop: 'остановка данной линии', newDowntime: 'Новое время простоя', orderNotifications: 'Изменение статуса заказа', thresholdExceeded: 'Порог превышен', oeeThreshold: 'OEE ниже целевого', orderProductionDone: 'Выполнено производство заказов', orderProductionNotAchieved: 'Заказ закрыт, но проиведено меньше заказанного', orderDelayed: 'Заказ отложен', performanceThreshold: 'Производительность машины ниже ожидаемой', orderWastedThresholdExceeded: 'Превышен порог отходов по заказу', selectedLines: 'Выбранные строки', allLines: 'Все строки',
  },
  timePeriod: {
    none: 'Нет', immediately: 'Немедленно', hourly: 'В почасовой сводке уведомлений', daily: 'В сводке ежедневных уведомлений',
  },
};
