<template>
  <div
    class="position-relative"
    :style="{
      'padding-bottom': displayAxis ? '20px' : null,
      height: `${height + (displayAxis ? 30 : 10) + height * maxConflicts}px`,
    }"
  >
    <div
      ref="chartWrapper"
      class="worklog-wrapper"
      @mousedown="mouseDown"
    >
      <div
        ref="chart"
        class="progress downtimes-progress"
      >
        <TransitionGroup
          :name="animation"
          tag="div"
        >
          <div
            v-for="(position, index) in blocks"
            :id="`block${position.id}`"
            :key="position.id"
            :ref="`block${position.id}`"
            :style="containerStyle(position, index)"
            class="progress-bar schedule-bar flex-row flex-wrap"
            :class="{
              hovered: isHovered(position),
              static: position.static,
              edited: position.id === editedId,
            }"
            @click="select(position.id)"
            @mouseover="position.static ? true : hovered = position"
          >
            <div
              class="header"
              :style="barStyle(position)"
            >
              <div class="block-marker">
                <div
                  v-if="!hideEntryDate || !hideEntryDate[0]"
                  :class="{
                    editable: editableLeft,
                    'center-marker': position.realWidth <= 100,
                    'left-marker': position.realWidth > 100,
                  }"
                >
                  {{ formatDate(position.start) }}
                  <span
                    v-if="!hideEntryDate || hideEntryDate[1]"
                    class="end"
                  >
                    - {{ formatDate(position.end) }}
                  </span>
                </div>
                <div
                  class="left-marker-line"
                  :style="borderColor(position)"
                  :class="{ editable: editableLeft }"
                />
              </div>
              <div
                class="bar-title text-truncate"
              >
                <i
                  v-if="position.icon"
                  :class="position.icon"
                />
                <i
                  v-if="isConflicting(index) && showWarnings"
                  class="ion ion-ios-warning text-warning conflict-icon"
                />
                &nbsp;<slot
                  name="body"
                  :block="position"
                >
                  {{ position.name }}
                </slot>
              </div>
              <div class="block-marker">
                <div
                  v-if="position.realWidth > 100 || editableRight"
                  class="right-marker"
                  :class="{
                    editable: editableRight,
                    manual: manualEdit && manualEdit.id === position.id
                  }"
                >
                  <div
                    v-if="manualEdit && manualEdit.id === position.id"
                    class="d-flex"
                  >
                    <div>
                      <BFormInput
                        v-model="manualDate"
                        type="date"
                        class="manual-input"
                        style="border-color: white; max-width: 130px;"
                        @blur="tryBlurManualTime"
                      />
                    </div>
                    <div class="pl-1">
                      <BFormInput
                        :ref="`manual-edit-${position.id}`"
                        v-model="manualTime"
                        type="time"
                        class="manual-input"
                        style="border-color: white;"
                        @blur="tryBlurManualTime"
                      />
                    </div>
                  </div>
                  <span
                    v-else
                    class="right-marker-date"
                  >
                    {{ formatDate(position.end) }}
                  </span>
                </div>
                <div
                  class="right-marker-line"
                  :class="{ editable: editableRight }"
                  :style="borderColor(position)"
                />
                <div
                  :ref="`dateEditPopup${position.id}`"
                  class="edit-date"
                  style="display: none"
                >
                  {{ formatDate(position.end) }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="plannedBlock"
            key="planned"
            :style="containerStyle(plannedBlock, plannedBlock.index)"
            class="progress-bar schedule-bar flex-row flex-wrap planned-bar"
            style="z-index: 102"
            @click="$emit('edit', hovered.item)"
          >
            <div class="header">
              <span class="planned-info">
                {{ $t('orders.planned') }}
              </span>
            </div>
          </div>
        </TransitionGroup>
      </div>

      <Tippy
        v-for="position in (hideTooltip ? [] : blocks.filter(x => !x.hideTooltip))"
        :key="position.id"
        sticky
        :target="`#block${position.id}`"
        placement="top"
        theme="light"
        multiple
        :distance="25"
        arrow
        :interactive="interactiveTooltip"
      >
        <slot :block="position" />
      </Tippy>
    </div>
    <TimeAxis
      ref="timeaxis"
      :hide-labels="!displayAxis"
      style="height: 30px"
      :wrapper-height="height * (maxConflicts + 1)"
      :start-date="currentStartDate + (noUtcOffset ? 0 :utcOffset)"
      :end-date="currentEndDate + (noUtcOffset ? 0 : utcOffset)"
    />
  </div>
</template>

<script>
import moment from 'moment';
import tinycolor from 'tinycolor2';
import saveConflicts from '@/components/schedule/conflicts';
import TimeAxis from './TimeAxis';

export default {
  props: {
    log: {
      type: Array,
      default: () => [],
    },
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      type: Number,
      default: Math.floor(Date.now() / 1000),
    },
    displayAxis: {
      type: Boolean,
      default: true,
    },
    selected: String,
    conflicts: {
      type: Boolean,
      default: true,
    },
    showWarnings: {
      type: Boolean,
      default: true,
    },
    hideNow: Boolean,
    hideEntryDate: Array,
    editable: [Boolean, Array],
    editedId: [String, Number],
    height: Number,
    interactiveTooltip: {
      type: Boolean,
      default: false,
    },
    timezone: String,
    hideTooltip: Boolean,
    noUtcOffset: Boolean,
  },
  data() {
    return {
      manualEdit: null,
      showDetails: false,
      alarms: false,
      drawn: false,
      animation: 'move-left',
      manualTime: '',
      manualDate: '',
      margin: 0,
      leftOffset: 0,
      minPopoverWidth: 400,
      container: {},
      containerWidth: 0,
      currentStartDate: this.startDate,
      currentEndDate: this.endDate || Math.floor(Date.now() / 1000),
      popoverWidth: 1,
      nowLeft: 0,
      hovered: null,
      editState: null,
      draggingId: null,
      clipping: true,
    };
  },
  components: {
    TimeAxis,
  },
  computed: {
    offset() {
      return moment.tz(this.timezone).utcOffset() * 60;
    },
    utcOffset() {
      return moment().utcOffset() * 60;
    },
    editableLeft() {
      return Array.isArray(this.editable) ? this.editable[0] : this.editable;
    },
    editableRight() {
      return Array.isArray(this.editable) ? this.editable[1] : this.editable;
    },
    plannedBlock() {
      if (!this.hovered || !this.hovered.item) return null;
      const { item } = this.hovered;
      if (!item.actualExecution?.end && !item.actualExecution?.begin) return null;
      const block = {
        id: 'p',
        index: this.hovered.index,
        start: Math.max(moment(item.scheduledExecution?.begin).unix(), this.currentStartDate),
        end: Math.min(moment(item.scheduledExecution?.end).unix(), this.currentEndDate),
      };

      return {
        ...block,
        left: this.blockOffset(block),
        width: this.blockLength(block),
      };
    },
    blocks() {
      const fullWidth = this.containerWidth;
      return this.log
        .map(el => {
          let { start, end } = el;
          if (this.editState && this.editState.id === el.id) {
            if (this.editState.handle === 'left') {
              start = this.cursorTimestamp;
            } else if (this.editState.handle === 'right') {
              end = this.cursorTimestamp;
            }
          }

          return {
            ...el,
            start: Math.max(this.currentStartDate, start),
            end: Math.min(this.currentEndDate, (end || this.currentEndDate)),
          };
        })
        .map(el => ({
          ...el,
          left: this.blockOffset(el),
          width: this.blockLength(el),
        }))
        .filter(el => el.width > 0)
        .map((e, index) => ({
          ...e,
          realWidth: e.width * (fullWidth / 100),
          index,
        }));
    },
    withConflicts() {
      if (!this.conflicts) {
        return this.blocks.map(b => ({ ...b, conflicts: 0 }));
      }
      return saveConflicts(this.blocks);
    },
    period() {
      return this.currentEndDate - this.currentStartDate + 1;
    },
    maxConflicts() {
      return this.blocks.reduce((acc, p, index) => {
        const confl = this.withConflicts[index];
        return Math.max(acc, confl.conflicts);
      }, 0);
    },
  },
  watch: {
    startDate(sd) {
      if (sd < this.currentStartDate) {
        this.animation = 'move-left';
      } else {
        this.animation = 'move-right';
      }
      this.currentStartDate = sd;
    },
    endDate(ed) {
      this.currentEndDate = ed || Math.floor(Date.now() / 1000);
    },
    hovered(h) {
      this.$emit('hover', h);
    },
  },
  methods: {
    tinycolor,
    formatDate(time) {
      if (!this.timezone) {
        return moment(time * 1000).format('Do MMM HH:mm');
      }
      return moment((time - this.offset) * 1000).tz(this.timezone).format('Do MMM HH:mm');
    },
    tryBlurManualTime(e) {
      if (!e.relatedTarget.classList.contains('manual-input')) {
        this.cursorTimestamp = moment(`${this.manualTime} ${this.manualDate}`, 'HH:mm YYYY-MM-DD').unix();

        this.$emit('applyEdit', {
          startDate: this.manualEdit.start,
          endDate: this.cursorTimestamp,
        });
        this.manualEdit = null;
      }
    },
    openManualEdit(e) {
      this.manualEdit = e;
      this.manualTime = moment(e.to).format('HH:mm');
      this.manualDate = moment(e.to).format('YYYY-MM-DD');

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[`manual-edit-${e.id}`][0].focus();
        }, 50);
      });
    },
    isSelected(p) {
      return p && this.selected === p.id;
    },
    isHovered(p) {
      return (this.hovered && this.hovered.id === p.id)
        || (this.editedId === p.id);
    },
    getExactTimestamp(offset) {
      const density = this.period / this.containerWidth;
      return Math.floor(this.currentStartDate + (offset * density));
    },
    getTimestamp(offset) {
      const exactTimestamp = this.getExactTimestamp(offset);
      const date = new Date(exactTimestamp * 1000);
      if (this.clipping) {
        const tenMinutes = Math.round(date.getMinutes() / 10) * 10;
        date.setMinutes(tenMinutes, 0, 0);
      }

      return Math.max((this.startDate + 60),
        Math.min(this.endDate,
          Math.floor(date.getTime() / 1000)));
    },
    barStyle(p) {
      return {
        'background-color': p.color || this.$color('blue'),
        ...(p.style || {}),
      };
    },
    borderColor(p) {
      const color = p.color || this.$color('blue');

      return {
        'border-color': tinycolor(color).darken(15).toString(),
      };
    },
    isConflicting(index) {
      return this.withConflicts[index].conflicts > 0;
    },
    containerStyle(p, index) {
      const confl = index === -1 ? 0 : this.withConflicts[index].conflicts;
      const styles = {
        width: `${Math.ceil(p.realWidth)}px`,
        top: `${(confl * this.height) + (this.margin / 2)}px`,
        transition: this.draggingId === p.id ? 'none' : undefined,
      };

      const chartWidth = this.containerWidth;
      if (!chartWidth) return styles;
      const realLeft = (p.left / 100) * chartWidth;

      return {
        ...(p.barStyle || {}),
        ...styles,
        transform: `translateX(${realLeft}px)`,
        height: `${this.height - this.margin}px`,
        'line-height': '25px',
      };
    },
    blockLength({ start, end }) {
      return Math.max((((end - start) / this.period) * 100), 0);
    },
    blockOffset({ start }) {
      return ((start - this.currentStartDate) / this.period) * 100;
    },
    checkTarget(e) {
      const el = e.target && e.target.closest && e.target.closest('.schedule-bar');
      if (!el) {
        this.hovered = null;
      }
    },
    select(id) {
      // Handle click
      if (!this.editable || !this.editedId) {
        const clicked = this.log.find(e => !e.static && e.id === id);
        if (clicked) {
          this.$emit('edit', { ...clicked });
        }
      }
    },
    mouseDown(ev) {
      if (this.manualEdit) return;
      const margin = 5;
      const allowedOffset = (this.period / this.containerWidth) * margin;
      const e = this.log.find(({ id }) => id === this.editedId);
      if (!e) return;
      this.draggingId = e.id;

      if (ev.target.classList.contains('right-marker-date')) {
        this.openManualEdit(e);
        return;
      }

      const left = ev.pageX - this.container.left;
      const timestamp = this.getExactTimestamp(left);
      const dateEditPopup = this.$refs[`dateEditPopup${e.id}`][0];

      // Left handle
      if (Math.abs(e.start - timestamp) < allowedOffset && this.editableLeft) {
        dateEditPopup.style.display = 'block';
        dateEditPopup.style.left = '0px';

        this.editState = {
          id: e.id,
          handle: 'left',
          timestamp,
          limit: e.limit,
          start: e.start,
          end: e.end,
        };
        this.$nextTick(() => {
          this.updateEdited();
        });
        return;
      }

      // Right handle
      if (Math.abs(e.end - timestamp) < allowedOffset && this.editableRight) {
        dateEditPopup.style.display = 'block';
        dateEditPopup.style.right = '0px';

        this.editState = {
          id: e.id,
          handle: 'right',
          timestamp,
          limit: e.limit,
          start: e.start,
          end: e.end,
        };
        this.$nextTick(() => {
          this.updateEdited();
        });
      }
    },
    mouseUp() {
      this.draggingId = null;
      if (!this.editable) return;
      if (this.editState) {
        const dateEditPopup = this.$refs[`dateEditPopup${this.editedId}`][0];
        dateEditPopup.style.display = 'none';
        dateEditPopup.style.right = 'auto';
        dateEditPopup.style.left = 'auto';

        if (this.editState.handle === 'left') {
          this.$emit('applyEdit', {
            startDate: this.cursorTimestamp,
            endDate: this.editState.end,
          });
        } else if (this.editState.handle === 'right') {
          this.$emit('applyEdit', {
            startDate: this.editState.start,
            endDate: this.cursorTimestamp,
          });
        } else {
          this.$emit('unselect');
          this.editState = null;
        }
      }
      this.editState = null;
    },
    updateEdited() {
      if (!this.editedId || !this.editState) return;
      const timestamp = this.cursorTimestamp;

      const refs = this.$refs[`block${this.editedId}`];
      if (refs.length === 0 || !refs[0]) return;
      const ref = refs[0];
      const chartWidth = this.containerWidth;

      const block = {
        start: this.editState.start,
        end: this.editState.end,
      };
      if (this.editState.handle === 'left') {
        block.start = timestamp;
      } else if (this.editState.handle === 'right') {
        block.end = timestamp;
      }

      this.$refs[`dateEditPopup${this.editedId}`][0].innerHTML = this.formatDate(timestamp);

      const left = this.blockOffset(block);
      const width = this.blockLength(block);

      const realLeft = (left / 100) * chartWidth;
      const realWidth = (width * chartWidth) / 100;
      ref.style.transform = `translateX(${realLeft}px)`;
      ref.style.width = `${realWidth}px`;
    },
    keyDown(e) {
      // alt
      if (e.keyCode === 18) {
        this.clipping = false;
      }
    },
    keyUp(e) {
      if (e.keyCode === 18) {
        this.clipping = true;
      }
    },
  },
  created() {
    this.currentStartDate = this.startDate;
    this.currentEndDate = this.endDate || Math.floor(Date.now() / 1000);

    this.mouseMove = e => {
      this.checkTarget(e);
      const left = e.pageX - this.container.left;
      this.cursorTimestamp = this.getTimestamp(left);

      if (!this.editState) return;
      if (this.editState.limit) {
        this.cursorTimestamp = Math.max(this.editState.limit[0], this.cursorTimestamp);
        this.cursorTimestamp = Math.min(this.editState.limit[1], this.cursorTimestamp);
      }

      this.updateEdited();
    };
    document.addEventListener('mousemove', this.mouseMove);
  },
  mounted() {
    this.handleResize = () => {
      this.container = this.$refs.chartWrapper.getBoundingClientRect();
      this.containerWidth = this.container.width;
    };
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('mouseup', this.mouseUp);
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
  },
  destroyed() {
    window.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.diagram-title {
  position: absolute;
  width: 100%;
  top: -20px;
  line-height: 42px;
}

.marker {
  border-left: 1px solid rgba(#333, 0.5);
  height: 100%;
  width: 3px;
  top: 0;
}

.grid-line {
  border-left: 1px solid rgba(100, 100, 100, 0.5);
}

.disconnected-icon {
  position: absolute;
  top: 3px;
  right: 5px;
  font-size: 10px;
}

.move-right-enter, .move-left-leave-to {
  transform: translateX(1000px) !important;
  opacity: 0;
}
.move-right-leave-to, .move-left-enter {
  transform: translateX(-1000px) !important;
  opacity: 0;
}

.popover-content {
  white-space: normal;
  max-width: 100%;
  width: 100%;
  padding: 5px 15px;
}

.worklog-wrapper {
  position: relative;
  height: 100%;
  padding: 5px 0;
  user-select: none;
}

.downtimes-progress {
  height: 100%;
  margin-bottom: 10px;
  position: relative;
  border-radius: 0;
  background-color: rgba(200, 200, 200, 0.1);
  border-left: 1px solid rgba(100, 100, 100, 0.5);
  border-right: 1px solid rgba(100, 100, 100, 0.5);
}
.progress-bar {
  position: absolute;
  left: 0;
  background-color: transparent;
  transition: width 200ms, left 200ms;

  .header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.progress {
  overflow: visible;
  z-index: 2;
}

.conflict-icon {
  text-shadow: 0 0 5px rgba(100, 100, 100, 0.6);
  font-size: 18px;
  position: relative;
  top: 2px;
}

.schedule-bar {
  transition: all 300ms;
  left: 0;
  z-index: 90;
  cursor: pointer;

  &.static {
    cursor: default;
    height: 100% !important;
    z-index: 0;
  }

  &.edited {
    cursor: default;
  }

  .header {
    transition: transform 300ms;
    padding: 2px;
    font-weight: 500;
    border-radius: 4px;
  }

  &.hovered {
    .header {
      border-color: transparent;
      transform: translateY(-4px);
    }
    overflow: visible;
    z-index: 100;
  }

  &.selected {
    border: 0px solid transparent;

    border-radius: 20px;
    min-width: 400px;
    transform: translateY(-20px) scale(1);
    z-index: 100;
    box-shadow: 0 0 20px rgba(#333, 0.8),  0 0 120px rgba(#333,0.4);
    background-color: #4d4d4d;

    .header {
      border-radius: 10px;
      box-shadow: none;
    }

    .bar-title {
      display: inline-block;
      font-size: 16px;
      padding-right: 25px;
      padding-left: 25px;
      white-space: initial;
    }
  }
}

.planned-bar {
  border-color: transparent;
  .header {
    transform: translateY(-4px);
    border: 2px dashed rgba(100, 100, 100, 0.5) !important;

    .planned-info {
      color: rgba(100, 100, 100, 0.5);
      text-shadow: 0 0 10px rgba(#333, 0.8);
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 10px;
      font-weight: bold;
    }
  }
}

.block-marker {
  font-size: 9px;
  font-weight: bold;
  color: #333;
  line-height: 1;
  display: none;

  > div {
    position: absolute;
  }

  .left-marker, .right-marker, .center-marker {
    background-color: white;
    padding-bottom: 1px;
    padding-top: 1px;
    top: -14px;
  }

  .left-marker {
    left: 0;
    transform: translateX(-50%);
    .end {
      display: none;
    }
  }
  .right-marker {
    right: 0;
    transform: translateX(50%);
  }
  .center-marker {
    left: 50%;
    transform: translateX(-50%);
  }

  .left-marker-line, .right-marker-line {
    height: 100%;
    top: 0;
    width: 5px;
    z-index: 101;
    border-left: 2px solid rgba(100, 100, 100, 1);

    &.editable:hover {
      border-width: 8px;
    }
  }

  .left-marker-line {
    left: -2px;
  }
  .right-marker-line {
    right: -5px;
  }
}

.edit-date {
  background-color: white;
  box-shadow: 0 2px 4px #ddd;
  border-radius: 5px;
  padding: 5px;
  transform: translate(50%, -50%);
  top: -17px;
  font-size: 1.18rem;
}

.edited {
  .editable.left-marker-line,
  .editable.right-marker-line {
    cursor: w-resize;
  }

  .editable.left-marker,
  .editable.right-marker {
    background-color: white;
    box-shadow: 0 2px 4px #ddd;
    border-radius: 5px;
    font-size: 1rem;
    top: -17px;
    padding: 5px;
  }

  .editable.right-marker {
    transform: translate(50%, -50%);

    &:hover {
      background-color: #eee;
    }
    &.manual {
      background-color: #fff;
      top: -25px;
    }
  }

  .editable.left-marker {
    transform: translate(-50%, -50%);
  }
}

.manual-edit-form {
  border: 0px;
  padding: 5px 10px;
}

.right-marker {
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.static {
  .block-marker {
    display: block;
  }
}

.hovered {
  .block-marker {
    display: block;
  }

  .header {
    border-radius: 0;
  }
}

</style>
