<template>
  <Tippy
    :on-show="onShow"
    :on-hide="onHide"
    arrow
  >
    <template #trigger>
      <div
        style="min-width: 20px"
        :style="{
          transform: size === 'sm' ? 'scale(0.8)' : null
        }"
      >
        <span
          class="fa-stack health-icon"
        >
          <span v-if="info.connectionIssue">
            <i class="fas fa-cloud fa-2x fa-stack-1x text-danger" />
            <span
              class="text-center"
              style="position: absolute; left: 0; top: 1px; width: 100%"
            >
              <i class="fas fa-satellite-dish text-white" />
            </span>
          </span>
          <span v-else-if="info.isAlive">
            <i class="fas fa-cloud fa-2x fa-stack-1x text-success" />
            <i class="fas fa-check fa-stack-1x text-white" />
          </span>
          <span v-else-if="info.isDelayedProcessing">
            <i class="fas fa-cloud fa-2x fa-stack-1x text-warning" />
            <i class="fas fa-redo fa-stack-1x text-white" />
          </span>
          <span v-else-if="info.isAlive === false">
            <i class="fas fa-cloud fa-2x fa-stack-1x text-danger" />
            <i class="fas fa-times fa-stack-1x text-white" />
          </span>
          <span v-else>
            <i class="fas fa-cloud fa-2x fa-stack-1x text-gray" />
            <i class="fas fa-question fa-stack-1x text-white" />
          </span>
        </span>
      </div>
    </template>
    <div
      v-if="info.connectionIssue"
      class="text-danger font-weight-bold"
      style="line-height: 1.1"
    >
      {{ $t('healthcheck.noDriverConnection') }}
      {{ CalcDuration(info.connectionIssue) }}
    </div>
    <span v-if="info.isDelayedProcessing && !info.isAlive">
      {{ $t('monitoring.processing') }}
    </span>
    <span v-if="info.timestamp">
      {{ $t('monitoring.lastMessage') }}:<br>
    </span>
    <b>{{ textDate }}</b>
    <br v-if="textDate">
    {{ exactDate }}
  </Tippy>
</template>

<script>
import moment from 'moment';
import loopRequest from '@/mixins/loopRequest';
import CalcDuration from '../../utils/calcDuration';

export default {
  props: {
    size: String,
    elementId: String,
  },
  data: () => ({
    shown: false,
    now: 0,
  }),
  mixins: [loopRequest('updateNow', 1000)],
  computed: {
    info() {
      return this.$store.getters['element/element'](this.elementId)?.healthcheck || {};
    },
    textDate() {
      const timeStamp = moment(this.info.timestamp).unix();
      return this.info.timestamp ? CalcDuration(timeStamp, this.now) : null;
    },
    exactDate() {
      if (!this.info) return '?';
      if (this.info.isAlive == null) {
        return this.$t('monitoring.noConnectionInfo');
      }
      return this.info.timestamp
        ? moment(this.info.timestamp).format('HH:mm DD.MM.YYYY')
        : this.$t('monitoring.noMessages');
    },
  },
  methods: {
    CalcDuration,
    onShow() {
      this.shown = true;
      this.updateNow();
    },
    onHide() {
      this.shown = false;
    },
    updateNow() {
      if (this.shown) {
        this.now = this.$now;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.health-icon {
  font-size: 10px;
  cursor: pointer;
  position: relative;

  .text-white {
    font-size: 12px;
    line-height: 22px;
  }
}
</style>
