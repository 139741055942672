<template>
  <div class="d-flex">
    <DateTimePicker
      id="startDateSelector"
      ref="startDateSelector"
      v-model="initialCustomStart"
      :color="colors('ilabo')"
      :locale="locale"
      :first-day-of-week="weekStart === 0 ? 7 : weekStart"
      class="w-50 custom-picker mx-1"
      overlay
      no-clear-button
      enable-button-validate
      :format="pickerFormat"
      only-date
      formatted="D MMM YYYY"
      label=""
      @is-hidden="hideStart"
    />
    <DateTimePicker
      id="endDateSelector"
      ref="endDateSelector"
      v-model="customRange.end"
      :color="colors('ilabo')"
      :locale="locale"
      :first-day-of-week="weekStart === 0 ? 7 : weekStart"
      :min-date="customRange.start"
      class="w-50 custom-picker mx-1"
      overlay
      no-clear-button
      enable-button-validate
      :format="pickerFormat"
      only-date
      formatted="D MMM YYYY"
      label=""
      :right="true"
      @is-hidden="hideEnd"
    />
  </div>
</template>

<script>
import moment from 'moment';
import DateTimePicker from '@ilabo/vue-ctk-date-time-picker';
import { mapGetters } from 'vuex';
import colors from '@/utils/colors';

export default {
  props: {
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      pickerFormat: 'YYYY-MM-DD HH:mm',
      initialCustomStart: null,
      customRange: {
        start: null,
        end: null,
      },
      rangeInit: false,
    };
  },
  components: {
    DateTimePicker,
  },
  computed: {
    ...mapGetters('core', ['locale', 'weekStart']),
  },
  methods: {
    colors,
    select(m) {
      if (m) {
        this.dateFrom = m.startDate;
        this.dateTo = m.endDate;
        this.$emit('dates', m);
      }
    },
    formatDate(date) {
      const timestamp = moment(date).unix();
      return moment(timestamp * 1000).format('H:mm D MMM YYYY');
    },
    hideStart() {
      if (this.initialCustomStart !== this.customRange.start) {
        this.customRange.start = this.initialCustomStart;

        if (moment(this.customRange.start).isAfter(this.customRange.end)) {
          this.customRange.end = moment(this.customRange.start).add(1, 'day').format();
        }
        this.$refs.endDateSelector.toggleDatePicker();
      }
    },
    hideEnd() {
      this.select({
        startDate: moment(this.customRange.start).format(),
        endDate: moment(this.customRange.end).format(),
      });
    },
  },
  created() {
    if (this.startDate && this.endDate) {
      this.dateFrom = this.startDate;
      this.dateTo = this.endDate;

      this.customRange = {
        start: moment(this.dateFrom).format(this.pickerFormat),
        end: moment(this.dateTo).format(this.pickerFormat),
      };

      this.initialCustomStart = moment(this.dateFrom).format(this.pickerFormat);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .date-box {
    padding: 2px 15px;
    font-size: 15px;
    margin: 0 3px;
    cursor: pointer;
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);

    &.active {
      border-color: $ilabo;
      background-color: rgba($ilabo, 0.2);
    }
  }

  .custom-picker {
    font-weight: normal;
  }

  .custom-selectors {
    margin: 0;
    position: relative;
    max-width: 400px;
  }

  .custom-picker :deep(.field .field-input) {
    padding-top: 0 !important;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    font-family: inherit;
    height: 38px;
    min-height: 38px;
  }
  .custom-picker :deep(.datetimepicker) {
    z-index: 100;
  }
  .custom-picker :deep(.time-picker-overlay) {
    z-index: 99;
  }

  h6 {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .over-connector {
    position: relative;
    z-index: 2;
  }

  .clear-icon {
    cursor: pointer;
    transition: transform 0.5s, color 0.5s;
  }
  .clear-icon:hover {
    transform: scale(1.5);
    color: #8bb812;
  }

  .date-box-connector {
    position: absolute;
    width: 100%;
    top: 8px;
  }

</style>
