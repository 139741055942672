<template>
  <div class="corr-actions">
    <h4 class="ca-popover-title font-weight-bold">
      <i class="icon-corrective-action pr-2" /> {{ $t('work.correctiveActions') }}:
    </h4>
    <div
      v-for="(ca, index) in correctiveActions"
      :key="`ca${index}`"
    >
      <p class="ca-desc px-2">
        {{ decodeURIComponent(ca) }}
      </p>
      <hr
        v-if="index+1 !== correctiveActions.length"
        class="my-2"
        style="width: 80%"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    correctiveActions: Array,
  },
};
</script>

<style lang="scss" scoped>
  .corr-actions {
    min-width: 200px;
  }
  .show-article {
    width: 35px;
    margin-left: 10px;
  }

  .ca-desc {
    font-size: 1em;
    line-height: 1;
    text-align: center;
    margin-bottom: 0;
    font-weight: 300;
    color: rgb(87, 86, 86);
  }

  .ca-popover-title {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
  }
</style>
