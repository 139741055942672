<template>
  <div
    class="edit-mode-switch d-flex align-items-center justify-content-between"
    @click="toggleValue"
  >
    {{ $t('activities.editMode') }}

    <div
      class="switch ml-2"
      :class="{'on': internalValue}"
    />
  </div>
</template>

<script>
export default {
  name: 'ActivitiesTimelineEditModeSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalValue: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    toggleValue() {
      this.internalValue = !this.internalValue;
    },
  },
};
</script>

<style lang="scss" scoped>
  .edit-mode-switch {
    cursor: pointer;

    .switch {
      width: 30px;
      height: 20px;
      position: relative;
      background-color: #CBCBCB;
      border-radius: 50px;
      transition: 0.3s all;

      &:before {
        transition: 0.3s all;
        content: '';
        border-radius: 50px;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
        background-color: white;
      }

      &.on {
        background-color: #5886C1;

        &:before {
          left: 13px;
        }
      }
    }
  }
</style>
