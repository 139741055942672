import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const score = new Vapi({
  axios,
  state: {

  },
})
  .get({
    action: 'getScore',
    property: 'weekly',
    path: ({ plantId, year, week }) => `/v2/plant/${plantId}/score/${year}/${week}`,
  })
  .get({
    action: 'getRanking',
    property: 'ranking',
    path: ({ plantId, year, week }) => `/v2/plant/${plantId}/score/ranking/${year}/${week}`,
  })
  .getStore();

export default score;
