<template>
  <span>
    {{ parsedElementLocation }}
    <span class="font-weight-bold">
      {{ name }}
    </span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { elementType } from '@/utils/dictionary';

export default {
  name: 'ElementLocation',
  props: {
    elementId: String,
    showLines: Boolean,
  },
  computed: {
    ...mapGetters('element', [
      'elementName',
      'elementType',
    ]),
    ...mapGetters('plant', [
      'structure',
    ]),
    parsedElementLocation() {
      if (!this.elementId) return '-';
      return this.getElementParentNamesArray(this.elementId).join(' / ');
    },
    name() {
      if (!this.elementId) return '-';
      return this.elementName(this.elementId);
    },
  },
  methods: {
    getElementParentNamesArray(elementId) {
      if (this.elementType(elementId) === elementType.plant) {
        return [];
      }
      const parentId = this.structure.parent(elementId);
      if (!parentId) return [];
      const thisName = elementId === this.elementId ? '' : this.elementName(elementId);
      return [this.getElementParentNamesArray(parentId), thisName].flat();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
