export default function routeModal(paramName) {
  return {
    data: () => ({
      [paramName]: false,
    }),
    watch: {
      $route(to) {
        this[`set${paramName}Route`](to);
      },
      [paramName](val) {
        if (val === false && this.$route.query[paramName] === 'true') {
          this.$router.replace({
            query: {
              ...this.$route.query,
              [paramName]: false,
            },
          });
          this.afterRouteModalClose();
        }
      },
    },
    methods: {
      [`set${paramName}Route`](route) {
        if (!this[paramName] && route.query[paramName] === 'true') {
          this[paramName] = true;
        } else if ((!route.query[paramName] || route.query[paramName] === 'false')
          && this[paramName]) {
          this[paramName] = false;
        }
      },
      closeRouteModal() {
        this[paramName] = false;
      },
      afterRouteModalClose() {
        // Aditional actions to do after modal close
        // (to overwrite in component)
      },
    },
    mounted() {
      this[`set${paramName}Route`](this.$route);
    },
  };
}
