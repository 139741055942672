<template>
  <div>
    <div class="d-flex param-hover">
      <div
        v-tippy="{
          arrow: true,
          delay: [500, 0],
          trigger: (description || name).length > 40 ? 'mouseenter' : 'manual'
        }"
        :content="description"
        class="param-name text-truncate flex-grow-1"
      >
        {{ description || name }}
      </div>
      <div
        :class="paramChanged ? 'notified' : ''"
        class="param-value text-right text-nowrap pl-3"
      >
        {{ value | round }} {{ unit ? unit : '' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: String,
    name: String,
    value: [String, Number],
    unit: String,
  },
  data() {
    return {
      paramChanged: false,
      // Reset notification state after 2s
      interval: 2000,
    };
  },
  filters: {
    round(value) {
      const valueAsNumber = Number(value);
      if (typeof value === 'number'
        || valueAsNumber.toString() === value) {
        return Math.round(valueAsNumber * 100) / 100;
      }
      return value;
    },
  },
  watch: {
    value() {
      this.paramChanged = true;
      setTimeout(() => {
        this.paramChanged = false;
      }, this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
  .param-value {
    transition: transform 500ms, color 500ms;
  }

  .param-hover {
    border-radius: 5px;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    padding-right: 5px;
    &:hover {
      background-color: rgba(200, 200, 200, 0.5);
    }
  }

  .notified {
    transform: scale(1.5);
    color: #8bb812;
  }
</style>
