export default {
  'addDowntime': 'Agregar tiempo de inactividad',
  'newDowntime': 'Nuevo tiempo de inactividad',
  'actionsAndReasons': 'Acciones y motivos',
  'performActions': 'Realice las siguientes acciones:',
  'secifyReasonIfKnown': 'Especifica un motivo',
  'downtimelist': 'Razón del tiempo de inactividad',
  'annotations': 'Anotaciones',
  'categories': 'Etiquetas',
  'clearRootCause': 'Causa raíz clara',
  'reason': 'Razón',
  'reasons': 'Razones',
  'rootCauseReason': 'Razón fundamental (Causa Raiz)',
  'noReasons': 'Sin razones definidas',
  'noCorrectiveActions': 'No se definieron acciones correctivas',
  'noEvents': 'No hay eventos definidos',
  'noCameras': 'No hay cámaras definidas',
  'removeReasonWorning': '¡Advertencia! ¡Se eliminarán todas las acciones correctivas asociadas con este motivo!',
  'addReason': 'Como una nueva razón',
  'selectEvent': 'Evento / Problema',
  'newCorrectiveAction': 'Nueva acción correctiva',
  'correctiveAction': 'Acción correctiva',
  'correctiveActions': 'Acciones correctivas',
  'callForService': 'Llame al servicio',
  'serviceHasBeenCalled': 'Se ha llamado al servicio',
  'specifyRootcause': 'Causa principal',
  'lastDowntimes': 'Últimos tiempos de inactividad',
  'startTime': 'Empezado',
  'endTime': 'Finalizado',
  'externalReason': 'Razón externa',
  'uncategorized': 'Sin categorizar',
  'noActions': 'No hay acciones para realizar',
  'timeSlot': 'Franja horaria',
  'selectOtherMachineDowntime': 'Seleccione otro tiempo de inactividad de la máquina',
  'selectMachine': 'Seleccionar maquina',
  'workEventFilter': 'Filtro de eventos',
  'deselectAll': 'Deseleccionar todo',
  'connect': 'Conectar',
  'commonReason': 'Razón Común',
  'commonProblem': 'Problema comun',
  'clearSelection': 'Selección clara',
  'noReason': 'Sin razón',
  'addOrCreateReason': 'Seleccionar motivo relacionado',
  'lineState': 'Estado de la línea',
  'selectProblemFirst': 'Seleccione el problema primero',
  'setProblem': 'Establecer problema',
  'setReason': 'Establecer razón',
  'setCorrectiveAction': 'Establecer acción correctiva',
  'summary': 'Resumen',
  'deleteConfirmMessage': 'Confirme que desea eliminar los tiempos de inactividad seleccionados.',
  'changeDuration': 'Duración del cambio',
  'downtimeUpdated': 'Tiempo de inactividad actualizado',
  'noMachineDowntime': 'No aplica para máquinas',
  'setAction': 'Seleccionar acción',
  'addAnotherCorrectiveAction': 'Agregar otra acción correctiva',
  'addAnotherReason': 'Agregar otra razón',
  'newReason': 'Nueva razón',
  'noCorrectiveAction': 'Acción correctiva no tomada',
  'toEditSetCommonWorkEvent': 'Para editar parametrice evento común',
  'differentCorrectiveActions': 'Acciones correctivas diferentes',
  'differentReasons': 'Razones diferentes'
};
