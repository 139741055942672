import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';
import activities from './activities';
import downtimes from './downtimes';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,

  'world': 'Thế giới',
  'plant': 'Nhà máy',
  'area': 'Khu vực',
  'areas': 'Các khu vực',
  'line': 'Dây chuyền',
  'lines': 'Các dây chuyền',
  'delete': 'Xóa',
  'segment': 'Phân đoạn',
  'machine': 'Máy',
  'machines': 'Các máy',
  'element': 'Yếu tố',
  'map': 'Bản đồ',
  'issue': 'Vấn đề',
  'typeOfIssue': 'Loại vấn đề',
  'currentShift': 'Ca làm việc hiện tại',
  'noShift': 'Không có ca nào',
  'currentOrder': 'Đơn hàng hiện tại',
  'today': 'Hôm nay',
  'shift': 'Ca làm việc',
  'shifts': 'Các ca làm viêc',
  'noShifts': 'Không phân công ca làm việc',
  'asset': {
    'globalCounter': 'Bộ đếm quốc tế',
    'localCounter': 'Bộ đếm nội bộ',
    'waterCounter': 'Đồng hồ đo lường nước',
    'valvePosition': 'Vị trí van',
    'waterLevel': 'Mực nước',
    'waterFlow': 'Lưu lượng nước',
    'pumpBalance': 'Cân bằng bơm'
  },
  'wall': {
    'comment': 'Chú thích',
    'comments': 'Các chú thích',
    'vision': 'Tầm nhìn',
    'downtimeEditorSettings': 'Cài đặt chỉnh sửa thời gian ngừng máy'
  },
  'menu': {
    'monitoring': 'Sự giám sát',
    'reports': 'Báo cáo',
    'overview': 'Tổng quan',
    'andon': 'Hệ thống cảnh báo',
    'weeklyAnalysis': 'Phân tích hàng tuần',
    'scoreAnalysis': 'Phân tích điểm số',
    'settings': 'Thiết lập',
    'userSettings': 'Thiết lập tài khoản',
    'notifications': 'Các thông báo',
    'language': 'Ngôn ngữ',
    'user': 'Người dùng',
    'setAsHome': 'Đặt làm trang chủ',
    'setFullScreen': 'Phóng toàn màn hình',
    'logout': 'Thoát',
    'loadMore': 'Tải nhiều hơn',
    'selectPlant': 'Chọn nhà máy',
    'homePageDevice': {
      'title': 'Trang chủ cho thiết bị này',
      'description': 'Màn hình này sẽ luôn được hiển thị sau khi đăng nhập trên thiết bị này'
    },
    'homePageUser': {
      'title': 'Trang chủ cho tài khoản của bạn',
      'description': 'Màn hình này sẽ được hiển thị sau khi bạn đăng nhập, nếu thiết bị không có trang chủ'
    },
    'activities': 'Các hoạt động',
    'login': 'Đăng nhập',
    'preview': 'Xem trước',
    'lineGraph': 'Biểu đồ dây chuyền',
    'machineGraph': 'Biểu đồ máy',
    'version': 'Phiên bản'
  },
  'import': {
    'upload': 'Thả tệp tin ở đây để tải lên',
    'notSaved': 'Không lưu được',
    'saved': 'Đã lưu',
    'actionRequired': 'Yêu cầu hành động',
    'checked': 'Đã xác minh',
    'fillAndAdd': 'Điền vào và thêm vào',
    'chooseImage': 'Chọn ảnh',
    'nothingImported': 'Dữ liệu không hợp lệ để nhập',
    'previousImports': 'Các lần nhập trước',
    'fileIsBeingProcessed': 'Tệp tin đang được xử lý. Chúng tôi sẽ gửi cho bạn một thông báo khi nó đã sẵn sàng',
    'groupSuccessfullyAssign': 'Đã chỉ định thành công cho nhóm',
    'groupFailedToAssign': 'Chỉ định không thành công cho nhóm',
    'groupSuccessfullyRemoved': 'Loại bỏ thành công khỏi nhóm',
    'groupFailedToRemove': 'Không thể loại bỏ khỏi nhóm',
    'noChanges': 'Không thay đổi',
    'dataSaved': 'Đã lưu dữ liệu thành công',
    'multiplierIsInvalid': 'Hệ số xung đột với chuyển đổi đã được xác định',
    'multiplierIsLessOrEqualZero': 'Số nhân không được nhỏ hơn hoặc bằng 0',
    'seeTemplates': 'Xem biểu mẫu',
    'fileReview': 'Xem xét tệp',
    'templates': 'Các biểu mẫu',
    'addTemplate': 'Thêm biểu mẫu',
    'editTemplate': 'Chỉnh sửa biểu mẫu',
    'import': 'Nhập',
    'uploadFile': 'Cập nhật dữ liệu',
    'template': 'Biểu mẫu',
    'toUploadFilePickTemplate': 'Để tải tệp lên, hãy chọn biểu mẫu',
    'result': 'Kết quả',
    'testFile': 'Tập tin thử nghiệm',
    'toSeeResultUploadTestFile': 'Để xem kết quả tải lên một tập tin thử nghiệm',
    'toTestFileSaveChanges': 'Để kiểm tra tệp, hãy lưu các thay đổi của bạn',
    'visible': 'trông thấy được',
    'saveFunction': 'Lưu chức năng',
    'key': 'Chính/ chìa khóa',
    'value': 'Giá trị',
    'oldValue': 'Giá trị cũ',
    'newValue': 'Giá trị mới',
    'unchanged': 'Không thay đổi',
    'status': {
      'applied': 'sự thành công',
      'appliedWithErrors': 'Áp dụng với các lỗi',
      'scheduled': 'Đã lên kế hoạch',
      'faulted': 'Bị lỗi',
      'processing': 'Đang trong tiến trình'
    }
  },
  'work': {
    'event': 'Vấn đề',
    'events': 'Các vấn đề',
    'state': 'Trạng thái',
    'states': 'Các trạng thái',
    'systemStates': 'Trạng thái hệ thống',
    'reason': 'Nguyên nhân',
    'reasons': 'Các nguyên nhân',
    'correctiveAction': 'Hành động khắc phục',
    'correctiveActions': 'Các hành động khắc phục',
    'downtime': 'Thời gian ngừng máy',
    'downtimes': 'Các thời gian ngừng máy',
    'wiki': 'Tạp chí Wiki',
    'disconnected': 'Ngưng kết nối',
    'rootCause': 'Nguyên nhân gốc',
    'detachRootCause': 'Loại bỏ nguyên nhân gốc',
    'rootMachine': 'Máy nguyên nhân gốc',
    'spectrum': 'Phổ công việc',
    'downtimeList': 'Danh sách thời gian ngừng máy',
    'default': 'Mặc định',
    'tags': 'Thẻ/ nhãn',
    'addTags': 'Thêm thẻ/nhãn',
    'addSelected': 'Thêm đã chọn',
    'plannedDowntimes': 'Thời gian ngừng máy có kế hoạch',
    'unplannedDowntimes': 'Thời gian ngừng máy ngoài kế hoạch',
    'workTime': 'Thời gian ở trạng thái làm việc',
    'actionTaken': 'Hành động khắc phục được thực hiện',
    'noOngoingOrder': 'Không có đơn đặt hàng tiếp theo',
    'noOngoingShift': 'Không có sự thay đổi liên tục',
    'updateQueued': 'Đã cập nhật thời gian ngừng hoạt động trong hàng đợi. Điều này có thể mất một vài giây',
    'overrideQueued': 'Chèn thời gian ngừng hoạt động đã xếp hàng đợi. Điều này có thể mất một vài giây',
    'noForcedState': 'Không có vấn đề nào được xác định với thẻ \'Bắt buộc\'. Thêm định nghĩa thời gian ngừng hoạt động với thẻ này, để dừng máy hoặc dây chuyền',
    'downtimeSince': 'Ngừng máy kể từ',
    'lasting': 'kéo dài'
  },
  'general': {
    'name': 'Tên',
    'from': 'Từ',
    'search': 'Tìm kiếm',
    'max': 'Tối đa',
    'min': 'Tối thiểu',
    'noResults': 'Không có kết quả',
    'title': 'Tiêu đề',
    'description': 'Mô tả',
    'fromStart': 'Từ điểm bắt đầu',
    'confirm': 'Xác nhận',
    'tryAgain': 'Thử lại',
    'to': 'Đến',
    'toEnd': 'Kết thúc',
    'finish': 'Hoàn thành',
    'add': 'Thêm',
    'all': 'Tất cả',
    'clear': 'Xoá',
    'hidden': 'Ẩn',
    'remove': 'Di dời',
    'detach': 'Tách',
    'attach': 'Gắn',
    'close': 'Đóng',
    'cancel': 'Hủy',
    'edit': 'Sửa',
    'event': 'Sự việc/sự kiện',
    'details': 'Các chi tiết',
    'summary': 'Tổng kết',
    'save': 'Lưu',
    'change': 'Thay đổi',
    'noData': 'Không có dữ liệu',
    'nothingDefined': 'Không xác định',
    'others': 'Khác',
    'back': 'Quay lại',
    'results': 'Kết quả',
    'selected': 'Đã chọn',
    'selectAll': 'Chọn tất cả',
    'createNew': 'Tạo mới',
    'useExisting': 'Sử dụng hiện có',
    'select': 'Chọn',
    'saveSuccessed': 'Lưu thành công',
    'saveFailed': 'Lưu thất bại',
    'shouldArchive': 'Nên lưu trữ',
    'areYouSure': 'Bạn có chắc không?',
    'view': 'Xem',
    'yes': 'Có',
    'no': 'Không',
    'reason': 'Nguyên nhân',
    'actions': 'Các hành động',
    'reasonUnknown': 'Không rõ lý do',
    'forcedByOperator': 'Bị ép buộc bởi người vận hành',
    'type': 'Loại/ kiểu',
    'valueType': 'Loại giá trị',
    'value': 'Giá trị',
    'unit': 'Đơn vị',
    'disable': 'Vô hiệu hóa',
    'disabled': 'Đã vô hiệu hóa',
    'disabledShe': 'Đã vô hiệu hóa',
    'inactive': 'Không kích hoạt',
    'enable': 'Cho phép',
    'enabled': 'Đã cho phép',
    'enabledShe': 'Đã cho phép',
    'ignore': 'Bỏ qua',
    'nominal': 'Danh nghĩa',
    'unsavedChanges': 'Bạn có thực sự muốn rời khỏi không? Bạn có những thay đổi chưa được lưu!',
    'now': 'Bây giờ',
    'showAll': 'Hiển thị tất cả',
    'showMore': 'Hiển thị nhiều hơn',
    'continue': 'Tiếp tục',
    'group': 'Nhóm',
    'download': 'Tải xuống',
    'dir': {'top': 'Đỉnh', 'bottom': 'Đáy', 'left': 'Bên trái', 'right': 'Bên phải'},
    'sourceURI': 'Nguồn URI',
    'dimensions': 'Các kích thước',
    'status': 'Tình trạng',
    'code': 'Mã',
    'start': 'Bắt đầu',
    'end': 'Kết thúc',
    'update': 'Cập nhật',
    'deviceId': 'ID Thiết bị',
    'time': 'Thời gian',
    'show': 'Hiển thị',
    'hide': 'Ẩn',
    'last': 'Cuối cùng',
    'errors': 'lỗi',
    'inherited': 'thừa hưởng',
    'refresh': 'làm mới',
    'lastRefresh': 'Làm mới lần cuối: {0} trước',
    'reset': 'Cài đặt lại',
    'collapseDetails': 'Thu gọn chi tiết',
    'next': 'Tiếp theo',
    'allSelected': 'Đã chọn tất cả',
    'unchecked': 'bỏ chọn',
    'manHours': 'Giờ lao động',
    'cancelled': 'đã hủy',
    'progress': 'tiến độ',
    'dates': 'Ngày',
    'pickDate': 'chọn ngày',
    'requestReceived': 'Yêu cầu đã được nhận',
    'deleted': 'Đã xóa',
    'filters': 'Bộ lọc',
    'total': 'Tổng cộng',
    'restoreDefaults': 'Khôi phục mặc định',
    'setDate': 'Cài đặt ngày',
    'setTime': 'Cài đặt giờ',
    'line': 'Dây chuyền',
    'option': 'Lựa chọn',
    'default': 'Mặc định',
    'set': 'Cài đặt',
    'apiUrls': 'Địa chỉ API',
    'done': 'Hoàn thành',
    'filterBy': 'Lọc bởi',
    'apply': 'Ứng dụng',
    'clearAll': 'Xóa tất cả'
  },
  'machineControl': {
    'turnOn': 'Mở',
    'turnOff': 'Tắt',
    'current': 'Hiện hành',
    'liveStream': 'Phát trực tiếp',
    'disconnectedWarning': 'CẢNH BÁO! Máy hiện đang bị ngắt kết nối - việc tắt sẽ làm mất dữ liệu trong bộ đệm',
    'startMachine': 'Khởi động máy <b>{machine}</b>?'
  },
  'lineControl': {'startLine': 'Bắt đầu linie <b>{line}</b>?', 'stop': 'Dừng', 'start': 'Bắt đầu'},
  'time': {
    'week': 'Tuần',
    'minutes': 'Phút',
    'date': 'Ngày',
    'day': 'Ngày',
    'hour': 'Giờ',
    'hours': 'giờ',
    'hoursPlural': 'giờ',
    'days': 'Ngày',
    'month': 'Tháng',
    'year': 'Năm',
    'quarter': 'Quý',
    'resolution': 'Nghị quyết',
    'duration': 'Khoảng thời gian',
    'seconds': 'Giây',
    'startDate': 'Ngày bắt đầu',
    'endDate': 'Ngày kết thúc',
    'startTime': 'Thời gian bắt đầu',
    'endTime': 'Thời gian kết thúc',
    'selectDay': 'Chọn ngày',
    'longerThan': 'Dài hơn',
    'shorterThan': 'Ngắn hơn',
    'scope': 'Phạm vi thời gian',
    'currentOrder': 'Đơn hàng hiện tại',
    'showMinorStops': 'Hiện thị các điểm dừng nhỏ',
    'plannedOnly': 'Chỉ hiển thị có kế hoạch',
    'unplannedOnly': 'Chỉ hiển thị không có kế hoạch',
    'excludeDefaults': 'Ẩn mặc định',
    'monday': 'Thứ hai',
    'tuesday': 'Thứ ba',
    'wednesday': 'Thứ tư',
    'thursday': 'Thứ năm',
    'friday': 'Thứ sáu',
    'saturday': 'Thứ bảy',
    'sunday': 'Chủ nhật',
    'performanceOnly': 'Tổn thất hiệu suất',
    'availabilityOnly': 'Tổn thất khả dụng/ khả năng sẵn sàng',
    'utilizationOnly': 'Tổn thất sử dụng',
    'plannedDate': 'Ngày đã lên kế hoạch',
    'custom': 'Khách hàng'
  },
  'graph': {
    'flow': 'Tiến trình',
    'flows': 'Tiến trình',
    'production': 'Tiến trình sản xuất',
    'physical': 'Vật lý',
    'supplying': 'Sự cung cấp',
    'archiveChanges': 'Thay đổi lưu trữ',
    'isActive': 'Kích hoạt',
    'addElement': 'Thêm yếu tố',
    'detached': 'Tách ra',
    'andon': 'Hệ thống cảnh báo',
    'noActiveFlow': 'Không có luồng hoạt động'
  },
  'monitoring': {
    'ofThePlan': 'Bạn đã đạt %{giá trị}% của giá trị này',
    'noPlan': 'Không có kế hoạch',
    'averageTargetPerformance': 'Mục tiêu hiệu suất trung bình',
    'designSpeed': 'Hiệu suất thiết kế',
    'validatedSpeed': 'Hiệu suất được phê duyệt/ thẩm định',
    'targetProduction': 'Mục tiêu sản xuất',
    'maxPerfProduction': 'Hiệu suất tối đa',
    'plannedFromOrders': 'Các đơn hàng theo kế hoạch',
    'planned': 'Đã lên kế hoạch',
    'notInFlow': 'Không thuộc trong tiến trình sản xuất',
    'produced': 'Sản xuất',
    'wasted': 'Phế phẩm',
    'lastMessage': 'Thông báo cuối cùng',
    'noMessages': 'Không có thông báo',
    'noAlarms': 'Không có cảnh báo',
    'noConnectionInfo': 'Không có thông tin về kết nối',
    'noConnection': 'Không có kết nối với máy chủ',
    'processing': 'Đang trong tiến trình',
    'dataProcessingFromLast': 'Xử lý dữ liệu từ lần cuối',
    'lineStates': 'Trạng thái dây chuyền',
    'disconnected': 'Ngưng kết nối',
    'serverDisconnected': 'Máy chủ bị ngưng kết nối',
    'working': 'Đang làm việc',
    'ready': 'Sẵn sàng',
    'notWorking': 'trong thời gian ngừng máy',
    'productionProgress': 'Tiến độ sản xuất',
    'production': 'Sản xuất',
    'productionMap': 'Bản đồ sản xuất',
    'workStates': 'Trạng thái làm việc',
    'workLog': 'Lịch sử công việc',
    'event': 'Sự việc/sự kiện',
    'events': 'Các sự việc/sự kiện',
    'cause': 'Nguyên nhân',
    'causes': 'Các nguyên nhân',
    'reasons': 'Các nguyên nhân',
    'warnings': 'Các cảnh báo',
    'correctiveAction': 'Hành động khắc phục',
    'stopRealTime': 'Dừng thời gian thực',
    'stoppedRealTime': 'Thời gian thực đã dừng',
    'realTime': 'Thời gian thực',
    'toRealTime': 'Đến thời gian thực',
    'downtimes': 'Các thời gian ngừng máy',
    'changeCurrentDowntime': 'Thay đổi thời gian ngừng máy',
    'doubleClickToEdit': 'Bấm đúp để sửa thời gian ngừng máy',
    'percentShare': 'Chia sẻ tỷ lệ phần trăm',
    'timeShare': 'Chia sẻ thời gian',
    'minorStops': 'bao gồm các điểm dừng nhỏ',
    'remainingPlannedProduction': 'Sản xuất còn lại dựa trên tốc độ danh nghĩa',
    'remainingEstimatedProduction': 'Sản xuất còn lại dựa trên hiệu quả tốc độ',
    'remainingTime': 'Thời gian còn lại',
    'performance': {
      'short': 'Hiệu suất {0} cuối cùng',
      'effective': 'Hiệu quả hiệu suất',
      'nominal': 'Hiệu suất danh nghĩa'
    },
    'segment': {
      'baseQuantity': 'Số lượng chuẩn',
      'baseQuality': 'Chất lượng chuẩn',
      'baseAvailability': 'Khả năng sẵn sàng chuẩn',
      'basePerformance': 'Hiệu suất chuẩn'
    },
    'machine': {
      'counter': 'Bộ đếm',
      'parameters': 'Các thông số',
      'alarms': 'Cảnh báo',
      'processParameters': 'Thông số quy trình',
      'currentParameters': 'Thông số hiện tại',
      'activeAlarms': 'Cảnh báo hoạt động',
      'counters': 'Các bộ đếm',
      'alarmsHoldups': 'Cảnh báo và tạm dừng',
      'performanceInfo': 'Hiệu suất trung bình từ {0} phút cuối',
      'others': 'Khác',
      'lifespan': 'Tuổi thọ thiết bị',
      'environmentCondition': 'Điều kiện môi trường',
      'condition': 'Tình trạng tài sản',
      'cost': 'Chi phí',
      'failurePreditcion': 'Lỗi dự báo',
      'probabilityFailure': 'Xác suất lỗi trong vòng 10 ngày',
      'failureType': 'Loại lỗi',
      'timeToFailure': 'Dự báo thời gian lỗi',
      'predictedFailure': 'Dự báo lỗi',
      'maintenanceHistory': 'Lịch sử bảo trì'
    },
    'lineInfo': {
      'advices': 'Các gợi ý',
      'forService': 'Các nhiệm vụ',
      'jobs': 'Công việc',
      'completed': 'Công việc đã hoàn thành',
      'problems': 'Các vấn đề',
      'planned': 'Chuỗi công việc',
      'toAchieveTarget': 'Sản lượng sản xuất được mong đợi tại thời điểm này:',
      'confirmLinePause': 'Xác nhận tạm dừng dây chuyền'
    },
    'worstAlarms': 'Những vấn đề tồi tệ nhất',
    'problems': 'không có vấn đề | một vấn đề | {0} vấn đề',
    'performanceSettingHistory': 'Lịch sử cài đặt hiệu suất',
    'unitConversionsHistory': 'Lịch sử chuyển đổi đơn vị',
    'reportParam': 'Báo cáo tham số hóa',
    'performanceType': 'loại hiệu suất',
    'goodCount': 'Sản phẩm đạt',
    'wasteCount': 'Sản phẩm loại',
    'overdue': '{0} quá hạn',
    'left': '{0} còn lại'
  },
  'components': {
    'title': 'Các thành phần',
    'one': 'Thành phần',
    'usagePerUnit': 'Sử dụng trên mỗi đơn vị',
    'required': 'Yêu cầu',
    'convertUnit': 'Chuyển đổi Đơn vị',
    'validation': 'Yêu cầu thẩm định',
    'batchTracking': 'Theo dõi lô',
    'scanTrackingId': 'Quét số theo dõi',
    'batchTrackingHelp': 'Khi bật tính năng theo dõi, bạn cần cung cấp số nhận dạng lô cho từng gói được quét',
    'defaultPackageQuantity': 'Trong gói',
    'warningLevelPercentage': 'Cảnh báo tỷ lệ phần trăm',
    'warningLevel': 'Cảnh báo hạng mức',
    'warningLevelHelp': '% - cảnh báo khi một số phần trăm gói được sử dụng tối thiểu - cảnh báo khi ước tính số phút còn lại',
    'scannerCode': 'Mã máy quét',
    'scannerCodeType': 'loại mã',
    'noTrackingId': 'Không có id theo dõi',
    'scan': 'Quét',
    'packages': 'Các gói',
    'scanPackages': 'Các gói quét',
    'noComponents': 'Không có thành phần được chỉ định',
    'invalidCode': 'Thành phần không tìm thấy',
    'addComponent': 'Thêm thành phần',
    'closed': 'Khép kín',
    'scanned': 'Quét',
    'closeConfirm': 'Đóng',
    'eta': 'ETA',
    'active': 'Kích hoạt',
    'clickToActivate': 'Bấm để kích hoạt',
    'used': 'Tiêu thụ',
    'estimatedTotalQuantity': 'Ước tính số lượng cần thiết',
    'packageScannerCode': 'Mã gói',
    'defaultPackageQuantitySum': 'Tổng số lượng trong gói',
    'registeredDate': 'Ngày quét',
    'trackingId': 'Theo dõi id',
    'consumptionType': {
      'title': 'Loại tiêu thụ',
      'help': 'MULTIPLE cho phép nhiều gói được mở cùng một lúc, gói đầu tiên được tiêu thụ. "SINGLE" tự động đóng gói trước đó khi gói mới được kích hoạt, gói cuối cùng được sử dụng'
    }
  },
  'oee': {
    'oee': 'OEE',
    'oeeLoss': 'Tổn thất OEE',
    'availability': 'Khả năng sẵn sàng',
    'performance': 'Hiệu suất',
    'quality': 'Chất lượng',
    'oeePercentage': 'OEE [%]',
    'availabilityPercentage': 'Sẵn sàng [%]',
    'performancePercentage': 'Hiệu suất [%]',
    'teep': 'TEEP'
  },
  'metrics': {
    'mtbf': 'Thời gian trung bình giữa các sai hỏng/lỗi(MTBF)',
    'mttr': 'Thời gian sửa chữa trung bình (MTTR)',
    'mtbm': 'Thời gian trung bình giữa các lần bảo trì (MTBM)',
    'reliabilityMetrics': 'Chỉ số độ tin cậy'
  },
  'energy': {
    'usedEnergy': 'Năng lượng được sử dụng',
    'currentLeastEfficient': 'Máy kém hiệu quả nhất hiện tại',
    'currentPower': 'Sức mạnh hiện tại',
    'baseline': 'Cơ sở bắt đầu',
    'max': 'Trung bình tối đa'
  },
  'items': {
    'items': 'Hạng mục',
    'import': 'Nhập các hạng mục',
    'itemGroups': 'Nhóm các hạng mục',
    'itemGroup': 'Nhóm hạng mục',
    'groups': 'Các nhóm',
    'add': 'Thêm hạng mục',
    'addGroup': 'Thêm nhóm',
    'new': 'Hạng mục mới',
    'edit': 'Sửa hạng mục',
    'number': 'Số',
    'description': 'Mô tả',
    'quantity': 'Số lượng/ pallet',
    'unit': 'Đơn vị số lượng',
    'performance': 'Hiệu suất',
    'expectedPerformance': 'Hiệu suất mong đợi',
    'expectedChangeover': 'Hiệu suất mong đợi',
    'packingStructure': 'Cấu trúc đóng gói',
    'groupPackingStructure': 'Nhóm cấu trúc đóng gói',
    'itemRoutingSpeed': 'Tốc độ định tuyến hạng mục',
    'groupRoutingSpeed': 'Tốc độ định tuyến nhóm',
    'components': 'Các thành phần',
    'item': 'Hạng mục',
    'inheritedFromGroup': 'Kế thừa từ nhóm',
    'lineConfiguration': 'Cấu hình dây chuyền',
    'noLineConfigured': 'Không có sản phẩm nào được định cấu hình cho dây chuyền này'
  },
  'pageNotFoundExplanation': 'Chúng tôi không thể tìm thấy trang này, xin lỗi vì sự bất tiện',
  'processParams': {
    'compositionName': 'Tên thành phần',
    'compositions': 'Các thành phần',
    'saveComposition': 'Lưu thành phần',
    'signals': 'Các tín hiệu',
    'signalName': 'Tên tín hiệu',
    'signalValue': 'Giá trị của tín hiệu',
    'ongoingState': 'Trạng thái đang diễn ra',
    'ongoingProblem': 'Vấn đề đang diễn ra',
    'ongoingOrder': 'Đơn hàng đang diễn ra',
    'problemStart': 'Vấn đề bắt đầu',
    'problemEnd': 'Vấn đề kết thúc',
    'orderStart': 'Bắt đầu đơn hàng',
    'orderEnd': 'Kết thúc đơn hàng',
    'orderProduction': 'Lệnh sản xuất'
  },
  'healthcheck': {'noDriverConnection': 'Không có kết nối bộ điều khiển'},
  'vision': {
    'vision': 'Tầm nhìn',
    'showAllClips': 'Hiện tất cả đoạn phim',
    'selectCamera': 'Chọn Camera',
    'getClip': 'Cắt đoạn phim',
    'getTheTwoPreviousMinutes': 'Ghi nhận 2 phút trước và 2 sau thời gian ngừng máy',
    'noClips': 'Không tìm thấy đoạn phim',
    'noRecords': 'Máy ảnh không có bản ghi từ ngày hôm đó để tải xuống',
    'currentVideoTime': 'Thời gian hiện tại của video',
    'startVideoTime': 'Bắt đầu Thời gian Video',
    'startDowntime': 'Bắt đầu thời gian ngừng máy',
    'endDowntime': 'Kết thúc thời gian ngừng máy',
    'showLiveVideo': 'Hiển thị video trực tiếp từ máy ảnh',
    'selectStartClip': 'Chọn bắt đầu video',
    'selectEndClip': 'Chọn kết thúc video',
    'videoDurationIs': 'Thời lượng video là',
    'invalidTimeRange': 'Khoảng thời gian không hiệu lực',
    'toMoreBefore': 'Chọn ngày bắt đầu sớm hơn cho video.',
    'toMoreAfter': 'Chọn ngày kết thúc muộn hơn cho video.',
    'maxVideoIs': 'Độ dài phim tối đa là',
    'auto': 'Tự động hóa',
    'autoLong': 'Video được yêu cầu tự động theo hệ thống',
    'manual': 'Bằng tay',
    'manualLong': 'Video được yêu cầu thủ công bởi người dùng',
    'videoTrigger': 'Chuyển đổi tải lên video tự động',
    'autoDownload': 'Tải video tự động',
    'watchVideo': 'Xem video'
  },
  'downloadStatus': {
    'failed': 'Bị lỗi',
    'inProgress': 'Đang tiến hành',
    'success': 'Sẵn sàng',
    'failedOnEdge': 'Máy ảnh bị lỗi',
    'noArchiveRecordings': 'Máy ảnh không có bản ghi',
    'deleted': 'Đã xóa'
  },
  'quests': 'Các nhiệm vụ',
  'quest': {
    'quest': 'Nhiệm vụ',
    'questTitle': 'Tiêu đề Nhiệm vụ',
    'questDescription': 'Mô tả nhiệm vụ',
    'project': 'Dự án',
    'type': 'Loại tác vụ',
    'state': 'Trạng thái nhiệm vụ',
    'closeToState': 'Đóng trạng thái',
    'assignToGroups': 'Chỉ định cho các nhóm',
    'taskDetails': 'Chi tiết nhiệm vụ',
    'files': 'Các tập tin',
    'comments': 'Các chú thích',
    'noFiles': 'không có tập tin'
  },
  'health': {'health': 'Sức khỏe', 'healthHistory': 'Tiền sử sức khỏe'},
  'panel': {
    'number': 'Số',
    'sku': 'Sku',
    'selectState': 'Chọn trạng thái',
    'selectMachine': 'Chọn máy',
    'comment': 'Chú thích',
    'downtimeFrom': 'thời gian ngừng máy từ',
    'ongoing': 'Đang diễn ra',
    'averagePerformance': 'Hiệu suất trung bình',
    'currentPerformance': 'Hiệu suất hiện tại',
    'currentLineStatus': 'Trạng thái dây chuyền hiện tại',
    'provideReasonAndComment': 'Thêm lý do và nhận xét',
    'commentPlaceholder': 'Viết nhận xét của bạn',
    'confirmData': 'Xác nhận dữ liệu',
    'inputPlaceholder': 'Viết ở đây'
  },
  'workCalendar': 'Lịch làm việc',
  'schedule': 'Kế hoạch sản xuất',
  'importSchedule': 'Nhập kế hoạch',
  'addSchedule': 'Thêm kế hoạch',
  'repeatEvery': 'Lặp lại mỗi',
  'repeatOn': 'Lặp lại dựa trên',
  'ends': 'Kết thúc',
  'starts': 'Bắt đầu',
  'never': 'Không bao giờ',
  'on': 'Trên',
  'cycle': 'Chu kỳ',
  'deleteRecurringInstance': 'Xóa đối tượng định kỳ',
  'editRecurringInstance': 'Sửa đối tượng định kỳ',
  'thisInstance': 'Đối tượng này',
  'thisAndFollowingInstances': 'Các trường hợp này và các trường hợp sau',
  'allInstances': 'Tất cả các đối tượng',
  'order': {
    'addNewProperty': 'Thêm đặc tính mới',
    'sortByDate': 'Lọc theo ngày',
    'sortBy': 'Sắp xếp theo',
    'ascending': 'tăng dần',
    'descending': 'giảm dần',
    'quantityProduced': 'Số lượng sản xuất',
    'plannedProduction': 'Kế hoạch sản xuất',
    'endDate': 'Ngày kết thúc',
    'activatedDate': 'Ngày kích hoạt',
    'totalTime': 'Thời hạn đặt hàng',
    'plannedDate': 'Ngày bắt đầu',
    'eta': 'ETA',
    'delete': 'Xóa bộ lọc'
  },
  'noCycle': 'Không có định nghĩa chu kỳ',
  'users': {'firstName': 'Họ', 'lastName': 'Tên'},
  'teams': {'teamName': 'Tên nhóm'},
  'schedules': {'addLine': 'Thêm dây chuyền vào bộ lọc', 'clearLines': 'Xóa tất cả các dòng'},
  'currentWeek': 'Tuần hiện tại',
  'factoryStructure': 'kết cấu nhà xưởng',
  'production': {'rawCounterIncreases': 'Giá trị bộ đếm thô trong giai đoạn này'},
  'calendar': {'period': 'Giai đoạn'},
  'chart': {'weekSymbol': 'Biểu tượng tuần'},
  'taxonomies': {
    'taxonomy': 'Phân loại',
    'classification': 'Phân loại',
    'selectClassification': 'Chọn phân loại',
    'selectClassificationLevel': 'Chọn cấp độ phân loại',
    'selectClassificationNodes': 'Chọn các nút phân loại',
    'defaultTaxonomy': 'Mặc định',
    'pickCategory': 'Chọn danh mục',
    'selectTaxonomy': 'Chọn phân loại',
    'selectTaxonomyLevel': 'Chọn cấp độ phân loại'
  },
  'menu_logoutQuestion': 'Bạn có muốn đăng xuất?'
};
