import { PACKOS_REPORT_ACCESS } from '@core/dict/permissions';
import EnergyTrends from '@/pages/reports/EnergyTrends';

const Reports = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/Index');
const OrdersReport = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/OrdersReport');
const WorkStates = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/WorkStates');
const Worsts = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/Worsts');
const DowntimeComments = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/DowntimeComments');
const Production = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/Production');
const ProductionMap = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/ProductionMap');
const DowntimeDuration = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/DowntimeDuration');
const DowntimeTracking = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/DowntimeTracking');
const OEE = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/OEE');
const ShiftsReport = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/ShiftsReport');
const Energy = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/Energy');
const EnergySankey = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/EnergySankey');
const ReportsDownload = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/ReportsDownload');
const Custom = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/Custom');
const OEELosses = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/OEELosses');
const OEEDetails = () => import(/* webpackChunkName: "reports" */ '@/pages/reports/OEEDetails');

export default {
  path: 'reports',
  name: 'reports',
  component: Reports,
  children: [
    {
      path: 'workstates',
      component: WorkStates,
    },
    {
      name: 'worsts',
      path: 'worsts',
      component: Worsts,
    },
    {
      path: 'comments',
      component: DowntimeComments,
    },
    {
      path: 'downtimeDuration',
      component: DowntimeDuration,
    },
    {
      path: 'downtimeTracking',
      component: DowntimeTracking,
    },
    {
      path: 'production',
      component: Production,
    },
    {
      path: 'productionMap',
      component: ProductionMap,
    },
    {
      path: 'oee',
      component: OEE,
    },
    {
      path: 'oee-losses',
      component: OEELosses,
    },
    {
      path: 'oee-details',
      component: OEEDetails,
    },
    {
      path: 'energy',
      component: Energy,
    },
    {
      path: 'reports-download',
      component: ReportsDownload,
    },
    {
      path: 'energysankey',
      component: EnergySankey,
    },
    {
      path: 'energy-trends',
      component: EnergyTrends,
    },
    {
      path: 'shifts',
      component: ShiftsReport,
    },
    {
      path: 'orders',
      component: OrdersReport,
    },
    {
      path: 'custom/:name(.*)?',
      component: Custom,
      props: true,
    },
  ],
  meta: {
    permittedFor: [PACKOS_REPORT_ACCESS],
  },
};
