<template>
  <div class="text-center">
    <Loader v-if="!authError" />
    <div
      v-else
      class="text-danger"
      style="font-size: 35px; position: relative; top: 20px"
    >
      <i class="fas fa-times" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  data: () => ({
    authError: false,
  }),
  methods: {
    ...mapActions('oidcStore', [
      'oidcSignInCallback',
    ]),
  },
  mounted() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.authError = true;

        setTimeout(() => {
          window.location.href = `${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH}`;
        }, 2000);
      });
  },
};
</script>
