<template>
  <div>
    <StatePareto
      v-if="showChart"
      :clickable="false"
      :pareto-states="paretoStates"
    />
    <div
      v-if="!hideRows"
      class="pt-1"
    >
      <div
        v-for="state in paretoStates"
        :key="state.workStateId"
        @click="selectState(state)"
      >
        <div
          class="state-type-row d-flex no-gutters justify-content-between align-items-center px-1"
          :class="{
            active: isSelected(state),
            sel: selectable,
          }"
        >
          <div
            v-tippy="{ arrow: true }"
            :content="$t('monitoring.percentShare')"
            class="text-right d-none d-sm-inline px-1"
            style="width: 45px;"
          >
            {{ state.timeInPercents * 100 | integer }}%
          </div>
          <div class="col d-flex pl-1">
            <StateBadge
              :state="state"
              class="d-block"
            />
          </div>

          <div
            class="col text-right"
          >
            <span
              v-tippy="{ arrow: true }"
              :content="$t('monitoring.timeShare')"
            >
              <span>{{ CalcDuration(1, state.timeInSeconds + 1) }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="anyMinorStops && state.minorStopsTimeInSeconds > 0"
          style="padding-left: 10px"
        >
          <div
            class="state-type-row d-flex no-gutters justify-content-between align-items-center px-1"
            style="font-size: 11px"
            :class="{
              active: isSelected(state),
              sel: selectable,
            }"
          >
            <span
              v-tippy="{ arrow: true }"
              :content="$t('monitoring.percentShare')"
              class="text-right d-none d-sm-inline px-1"
              style="width: 35px;"
            >
              {{ state.minorStopsTimeInPercents * 100 | integer }}%
            </span>
            <div class="d-flex align-items-center col pl-1">
              <div class="mr-2">
                <StateBadge
                  :state="state"
                  class="d-block"
                />
              </div>
              <div>
                {{ $t('reports.minorStops') }}
              </div>
            </div>
            <div
              v-if="anyMinorStops"
              class="col text-nowrap text-right pr-1"
            >
              {{ CalcDuration(1, state.minorStopsTimeInSeconds + 1) }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="startDate && endDate && elementId && orderId"
        class="text-center mt-2"
      >
        <a
          :href="trackingLink"
          target="_blank"
          style="color: #888888; text-decoration: underline;"
        >

          {{ $t('reports.seeDowntimesReport') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/utils/colors';
import CalcDuration from '@/utils/calcDuration';
import StatePareto from '@/components/charts/state-pareto/StateParetoChart';
import { mapGetters } from 'vuex';

export default {
  props: {
    worklogs: Array,
    startDate: Number,
    endDate: Number,
    showChart: Boolean,
    hideRows: Boolean,
    selectable: {
      type: Boolean,
      default: true,
    },
    orderId: String,
    elementId: String,
    resolution: String,
    showTracking: Boolean,
  },
  data: () => ({
    selected: [],
  }),
  components: {
    StatePareto,
  },
  computed: {
    ...mapGetters(['plantId']),
    anyMinorStops() {
      return this.paretoStates.some(x => x.minorStopsNumInNumber > 0);
    },
    paretoStates() {
      if (!this.worklogs) return [];
      const start = this.startDate;
      const end = this.endDate;

      let allSum = 0;
      let allNum = 0;

      return this.worklogs
        .reduce((acc, curr) => {
          if (!curr.workState) return acc;
          const realEndDate = (curr.endDate || this.$now);
          if (realEndDate < start || curr.startDate > end) return acc;
          const endDate = Math.min(realEndDate, end);
          const startDate = Math.max(curr.startDate, start);
          if (endDate <= startDate) return acc;

          const i = acc.findIndex(el => el.workStateId === curr.workState.id);
          if (i !== -1) {
            acc[i].sum += (endDate - startDate);
            acc[i].num += 1;
            acc[i].minorStopsSum += curr.isMinorStop ? (endDate - startDate) : 0;
            acc[i].minorStopsNum += curr.isMinorStop ? 1 : 0;
          } else {
            const { rootWorkLog } = curr;

            acc.push({
              workStateId: curr.workState.id,
              workStateName: curr.workState.name,
              sum: (endDate - startDate),
              num: 1,
              colorCode: curr.workState.colorCode,
              description: curr.workState.description,
              minorStopsSum: curr.isMinorStop ? (endDate - startDate) : 0,
              minorStopsNum: curr.isMinorStop ? 1 : 0,
              workEventId: curr?.workEvent?.id,
              rootWorkEventId: rootWorkLog?.workEvent?.id,
              rootElementId: rootWorkLog?.elementId,
            });
          }
          allSum += (endDate - startDate);
          allNum += 1;
          return acc;
        }, [])
        .map(el => ({
          colorCode: el.colorCode,
          workStateName: el.workStateName,
          workStateId: el.workStateId,
          workEventId: el.workEventId,
          rootWorkEventId: el.rootWorkEventId,
          rootElementId: el.rootElementId,
          description: el.description,
          timeInPercents: ((el.sum) / allSum),
          timeInSeconds: el.sum,
          numInPercents: ((el.num) / allNum),
          numInNumber: el.num,
          minorStopsTimeInPercents: ((el.minorStopsSum) / allSum),
          minorStopsTimeInSeconds: el.minorStopsSum,
          minorStopsNumInPercents: ((el.minorStopsNum) / allNum),
          minorStopsNumInNumber: el.minorStopsNum,
        }))
        .sort((a, b) => b.timeInPercents - a.timeInPercents);
    },
    trackingLink() {
      const link = this.$router.resolve({
        name: 'worsts',
        query: {
          startDate: this.startDate,
          endDate: this.endDate,
          elementId: this.elementId,
          downtimeReportOrderId: this.orderId,
        },
      });
      return link.href;
    },
  },
  methods: {
    CalcDuration,
    colors,
    isSelected(state) {
      return this.selected.find(el => el.workStateId === state.workStateId);
    },
    selectState(state) {
      if (!this.selectable) return;
      if (this.isSelected(state)) {
        this.selected = this.selected.filter(el => el.workStateId !== state.workStateId);
      } else {
        this.selected.push(state);
      }
      this.$emit('selected', this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
  .state-type-row {
    width: 100%;
    cursor: pointer;
    height: 40px;
    font-size: 13px;
    border-radius: 10px;
    margin-top: 2px;
    background-color: #f9f9f9;

    &:hover {
      background-color: #eee;
    }

    &.sel {
      cursor: pointer;
    }

    &.active {
      background-color: #ccc;
    }
  }

</style>
