<template>
  <div class="row no-gutters">
    <div
      v-for="el in list"
      :key="el.key"
      :class="{
        'col': fluid,
        'col-6 col-sm-3 col-md-auto': !fluid
      }"
      class="btn-container"
    >
      <div
        class="radio-label w-100 d-flex justify-content-center align-items-center"
        :class="{
          small,
          active: el.key === selected
        }"
        @click="$emit('update:selected', el.key)"
      >
        <i :class="el.icon" />
        <div class="pl-1 text-nowrap">
          {{ el.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: String,
    small: Boolean,
    fluid: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .radio-label {
    text-transform: uppercase;
    font-weight: 500;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid rgba(100,100,100,0.1);
    cursor: pointer;

    &.active {
      color: $ilabo;
      text-shadow: 0 0 2px rgba(100, 100, 100, 0.2);
      box-shadow: 0 0 2px rgba(100,100,100,0.2);
      border-color: $ilabo;
    }

    i {
      font-size: 19px;
    }

    &:hover {
      box-shadow: 0 0 3px rgba(100,100,100,0.4);
    }

    &.small {
      font-size: 10px;
      padding: 2px 10px;
    }
  }

  .btn-container {
    min-width: 140px;
    padding: 2px !important;
  }
</style>
