<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1 p-2">
      <h6 class="mb-0">
        {{ $t('items.additionalFields') }}
      </h6>

      <p
        class="px-0 cursor-pointer text-primary mb-0"
        @click="showModal"
      >
        {{ $t('general.edit') }}
      </p>
    </div>

    <BTable
      :items="innerAdditionalFields"
      thead-class="d-none"
      show-empty
      :empty-html="$t('items.noAdditionalFields')"
    >
      <template
        #cell(name)="{value}"
      >
        <p class="mb-0 text-black-50">
          {{ value }}
        </p>
      </template>
      <template
        #cell(value)="{value}"
      >
        <p class="text-right mb-0">
          {{ value }}
        </p>
      </template>
    </BTable>

    <AdditionalFieldsEditModal
      v-model="innerAdditionalFields"
    />
  </div>
</template>

<script>
import AdditionalFieldsEditModal from '@/components/additionalFields/AdditionalFieldsEditModal';

export default {
  props: {
    additionalFields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    innerAdditionalFields: [],
  }),
  components: { AdditionalFieldsEditModal },
  watch: {
    innerAdditionalFields(v) {
      this.$emit('update:additionalFields', v);
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show('additional-fields-edit-modal');
    },
  },
  created() {
    this.innerAdditionalFields = this.additionalFields;
  },
};
</script>

<style scoped lang="scss">

</style>
