<template>
  <div
    v-if="statusInfo"
    class="badge text-white legend-pill"
    style="font-size: 0.688rem"
    :style="{
      'background-color': statusInfo.color
    }"
    :class="{ crossed, clickable, 'badge-pill': rounded, 'px-3 text-uppercase': big }"
  >
    {{ statusInfo.desc }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    crossed: Boolean,
    clickable: Boolean,
    rounded: { type: Boolean, default: true },
    big: Boolean,
  },
  computed: {
    ...mapGetters(['getActivityState']),
    statusInfo() {
      const status = this.getActivityState({ status: this.status });
      if (!status) return null;
      return {
        ...status,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .legend-pill {
    opacity: 0.8;
    border-radius: 3px;

    &.clickable {
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 3px rgba(100, 100, 100, 0.8);
      }
    }
    &.crossed {
      text-decoration: line-through;
    }
  }
</style>
