<template>
  <div>
    <hr>
    <div class="sku-wrapper">
      <div
        v-if="!sku"
        class="mb-2"
      >
        <label class="label font-weight-bold">
          {{ $t('items.number') }}
        </label>
        <div>
          <BFormInput
            v-model="skuNo"
            :state="skuCheck() | validate(validated)"
            class="form-control"
          />
        </div>
        <Error
          v-if="errorName"
          class="text-center pt-1"
          :message="errorName"
        />
      </div>
      <div>
        <label class="label font-weight-bold">
          {{ $t('items.description') }}
        </label>
        <div>
          <BFormInput
            v-model="description"
            :state="descriptionCheck() | validate(validated)"
            class="form-control"
          />
        </div>
        <Error
          v-if="errorInput"
          class="text-center pt-1"
          :message="errorInput"
        />
      </div>
      <div class="mt-2">
        <label class="font-weight-bold">
          {{ $t('general.group') }}
        </label>
        <BFormSelect
          v-model="skuGroupId"
          :options="groupsOptions"
        />
      </div>

      <AdditionalFieldsTable
        :additional-fields.sync="additionalFields"
        class="mt-3"
      />
    </div>
    <hr>
    <Error
      v-if="error"
      class="text-center pt-1"
      :message="error"
    />
    <div class="text-center mt-3">
      <SaveButton
        :pending="pending"
        :show-remove="!!sku"
        :remove-pending="removePending"
        size="lg"
        @cancel="$emit('close')"
        @save="save"
        @remove="remove"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AdditionalFieldsTable from '@/components/additionalFields/AdditionalFieldsTable';

export default {
  props: {
    sku: Object,
    items: Array,
  },
  data: () => ({
    skuGroupId: null,
    skuNo: '',
    description: '',
    additionalFields: [],
    pending: false,
    removePending: false,
    error: null,
    errorName: null,
    errorInput: null,
    validated: false,
  }),
  components: { AdditionalFieldsTable },
  filters: {
    validate(value, v) {
      if (!v) return null;
      return value;
    },
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapState({
      groups: state => state.item.skuGroups || [],
    }),
    groupsOptions() {
      if (!this.groups) return [];
      return [{
        value: null,
        text: '-',
      }]
        .concat(
          this.groups
            .map(g => ({
              value: g.id,
              text: g.name,
            })),
        );
    },
  },
  methods: {
    ...mapActions([
      'createSku',
      'updateSku',
      'deleteSku',
    ]),
    validateModel() {
      if (this.sku) return this.description.length > 0;
      return this.skuNo.length > 0 && this.description.length > 0;
    },
    numberExist() {
      return this.items?.find(x => x.skuNo === this.skuNo);
    },
    save() {
      this.error = null;
      this.errorName = null;
      this.errorInput = null;
      this.validated = true;

      if (!this.validateModel()) {
        this.errorInput = this.$t('error.wrongInput');
        this.errorName = this?.skuNo?.trim().length > 0 ? null : this.$t('error.wrongInput');
      }
      if (this.numberExist()) {
        this.errorName = this.$t('error.nameExist');
      }
      if (this.sku) {
        if (!this.validateModel()) {
          return;
        }
        this.pending = true;
        this.updateSku({
          params: {
            plantId: this.plantId,
            skuId: this.sku.id,
          },
          data: {
            description: this.description,
            skuGroupId: this.skuGroupId,
            productJson: this.sku.productJson,
            additionalFields: this.additionalFields,
          },
        })
          .then(() => {
            this.$emit('saved');
            this.pending = false;
            this.error = null;
            this.errorName = null;
            this.errorInput = null;
          })
          .catch(err => {
            this.error = err;
            this.pending = false;
          });
      } else {
        if (!this.validateModel() || this.numberExist()) {
          return;
        }
        this.pending = true;
        this.createSku({
          params: {
            plantId: this.plantId,
          },
          data: {
            skuNo: this.skuNo,
            description: this.description,
            skuGroupId: this.skuGroupId,
            productJson: '{}',
            additionalFields: this.additionalFields,
          },
        })
          .then(({ data }) => {
            this.pending = false;
            this.validated = false;
            this.error = null;
            this.errorName = null;
            this.errorInput = null;
            this.$emit('saved');
            this.$emit('created', data.id);
          })
          .catch(err => {
            this.error = err;
            this.pending = false;
          });
      }
    },
    remove() {
      this.error = null;
      this.errorName = null;
      this.errorInput = null;
      this.removePending = true;
      this.deleteSku({
        params: {
          plantId: this.plantId,
          skuId: this.sku.id,
        },
      })
        .then(() => {
          this.removePending = false;
          this.$emit('close');
          this.$emit('saved');
          this.$emit('close');
        })
        .catch(err => {
          this.error = err;
          this.removePending = false;
        });
    },
    descriptionCheck() {
      return this.description?.trim().length > 0;
    },
    skuCheck() {
      return this?.skuNo?.trim().length > 0 && !this.numberExist();
    },
  },
  created() {
    if (this.sku) {
      this.skuNo = this.sku.skuNo;
      this.skuGroupId = this.sku.skuGroupId;
      this.description = this.sku.description;
      this.additionalFields = this.sku.additionalFields;
    }
  },
};
</script>

<style lang="scss" scoped>
.sku-wrapper {
  width: 500px;
  margin: auto;
}
</style>
