export default {
  actionsAndReasons: 'Actions et raisons',
  addAnotherCorrectiveAction: 'Ajouter une autre action corrective',
  addAnotherReason: 'Ajouter une autre raison',
  addDowntime: 'Ajouter des temps d’arrêt',
  annotations: 'Annotations',
  addReason: 'Comme nouvelle raison',
  callForService: 'Appel de service',
  changeDuration: 'Modifier la durée',
  clearRootCause: 'Cause première claire',
  clearSelection: 'Effacer la sélection',
  commonProblem: 'Problème commun',
  commonReason: 'Raison commune',
  deleteConfirmMessage: 'Confirmez que vous souhaitez supprimer les temps d’arrêt sélectionnés.',
  connect: 'Connecter',
  correctiveAction: 'Mesure corrective',
  correctiveActions: 'Mesures correctives',
  deselectAll: 'Désélectionner tout',
  noMachineDowntime: 'Ne s\'applique pas aux machines',
  downtimelist: 'Raison des temps d’arrêt',
  downtimeUpdated: 'Temps d’arrêt mis à jour',
  selectEvent: 'Événement / Problème',
  workEventFilter: 'Filtre d’événements',
  externalReason: 'Raison externe',
  endTime: 'Fini',
  lastDowntimes: 'Derniers temps d’arrêt',
  lineState: 'État de la ligne',
  newCorrectiveAction: 'Nouvelle mesure corrective',
  newDowntime: 'Nouveau temps d’arrêt',
  noActions: 'Aucune action à effectuer',
  noCameras: 'Aucune caméra définie',
  noCorrectiveActions: 'Aucune action corrective définie',
  noEvents: 'Aucun évènement défini',
  noReason: 'Aucune raison',
  noReasons: 'Aucune raison définie',
  performActions: 'Effectuez les actions suivantes :',
  reason: 'Raison',
  reasons: 'Raisons',
  specifyRootcause: 'Cause',
  rootCauseReason: 'Raison fondamentale',
  selectMachine: 'Sélectionner la machine',
  selectOtherMachineDowntime: 'Sélectionner d’autres temps d’arrêt de la machine',
  selectProblemFirst: 'Sélectionnez d’abord le problème',
  addOrCreateReason: 'Sélectionner la raison associée',
  serviceHasBeenCalled: 'Le service a été appelé',
  setAction: 'Définir l’action',
  setCorrectiveAction: 'Définir une action corrective',
  setProblem: 'Définir le problème',
  setReason: 'Définir la raison',
  secifyReasonIfKnown: 'Spécifier une raison',
  startTime: 'Commencé',
  summary: 'Résumé',
  categories: 'Balises',
  timeSlot: 'Créneau horaire',
  uncategorized: 'Pas de catégorie',
  removeReasonWorning: 'Avertissement! Toutes les mesures correctives associées à cette raison seront supprimées!',
  'addLineDowntime': 'Ajouter un temps d\'arrêt de ligne',
  'addMachineDowntime': 'Ajouter un temps d\'arrêt {name}',
  'newReason': 'Nouvelle raison',
  'noCorrectiveAction': 'Aucune action corrective entreprise',
  'toEditSetCommonWorkEvent': 'Pour éditer, définir l\'événement de travail commun',
  'differentCorrectiveActions': 'Actions correctives différentes',
  'differentReasons': 'Raisons différentes',
  'noDetails': 'Aucun détail',
  'noClipsCameraAvailable': 'Aucune demande de clips, la caméra est disponible',
  'clipsDownloaded': 'Clips demandés',
  'stopLine': 'Arrêter la ligne',
  'stopLineStep': 'Arrêter la ligne - étape {step}',
};
