export const SET_CONFIG = 'SET_CONFIG';
export const USER_SETTINGS_UPDATE = 'USER_SETTINGS_UPDATE';

export const SAVE_SOCKET_CONNECTION = 'SAVE_SOCKET_CONNECTION';
export const CLEAR_SOCKET_CONNECTION = 'CLEAR_SOCKET_CONNECTION';
export const SAVE_SOCKET_LISTENER = 'SAVE_SOCKET_LISTENER';
export const REMOVE_SOCKET_LISTENER = 'REMOVE_SOCKET_LISTENER';

export const MACHINE_PROPERTY_UPDATE = 'MACHINE_PROPERTY_UPDATE';
export const MACHINE_PROPERTY_DELETE = 'MACHINE_PROPERTY_DELETE';

export const REPORT_REFRESH = 'REPORT_REFRESH';

export const CARD_ADD = 'CARD_ADD';
export const CARD_DELETE = 'CARD_DELETE';

// Cache
export const SET_PLANTS_DATA = 'SET_PLANTS_DATA';

export const ACTIVITY_UPDATE = 'ACTIVITY_UPDATE';
export const ACTIVITY_DELETE = 'ACTIVITY_DELETE';

export const WIKI_CREATE_ENTRY = 'CREATE_WIKI_ENTRY';
export const WIKI_SAVE_PROMISE = 'WIKI_SAVE_PROMISE';

export const CLIPS_UPDATE = 'CLIPS_UPDATE';
export const CLIPS_DELETE = 'CLIPS_DELETE';
