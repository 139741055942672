<template>
  <BToast
    id="my-toast"
    :variant="variant"
    :visible="visible"
    no-auto-hide
    solid
    toaster="b-toaster-bottom-right"
    @hidden="$emit('dismiss', operationId)"
  >
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">{{ $t(`work.updateType.${operationType}`) }}</strong>
        <small
          v-if="status"
          class="text-muted mr-2"
        >{{ duration }}</small>
      </div>
    </template>
    <div v-if="!status || status.operationType === 'Unstarted'">
      <div class="d-flex align-items-center">
        <Loader size="20px" />
        <div class="pl-3">
          {{ $t('work.updateQueued') }}
        </div>
      </div>
    </div>
    <div
      v-else-if="status.error"
      style="overflow-wrap: break-word"
    >
      {{ status.error }}
    </div>
    <div v-else-if="status.persisted">
      <i class="fa fa-check" />
      {{ $t('work.persisted') }}
    </div>
    <div v-else-if="!status.finished">
      <div class="d-flex align-items-center">
        <Loader size="20px" />
        <div class="pl-3">
          {{ $t('work.processing') }}
        </div>
      </div>
    </div>
    <div v-else-if="!status.persisted">
      <div class="d-flex align-items-center">
        <Loader size="20px" />
        <div class="pl-3">
          {{ $t('work.saving') }}
        </div>
      </div>
    </div>
  </BToast>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CalcDuration from '@/utils/calcDuration';
import moment from 'moment';

export default {
  props: {
    operationId: String,
    operationType: String,
  },
  data: () => ({
    status: null,
    stopped: false,
    visible: true,
    now: null,
  }),
  computed: {
    ...mapGetters(['plantId']),
    variant() {
      if (this.status && this.status.persisted) {
        return 'success';
      }
      if (this.status && this.status.error) {
        return 'danger';
      }

      return 'primary';
    },
    duration() {
      if (!this.status || this.status.operationType === 'Unstarted') return null;
      return CalcDuration(moment(this.status.started).unix(), this.now);
    },
  },
  methods: {
    CalcDuration,
    ...mapActions(['getUserRequestStatus']),
    request() {
      if (this.stopped) {
        return;
      }

      this.now = this.$now;

      if (!this.plantId) {
        this.timeout = setTimeout(() => {
          this.request();
        }, 2000);
        return;
      }

      this.getUserRequestStatus({
        params: {
          operationId: this.operationId,
        },
      })
        .then(({ data }) => {
          this.status = data;
          if (!data.persisted) {
            this.timeout = setTimeout(() => {
              this.request();
            }, 2000);
          } else {
            this.$root.$emit('user-operation-completed', {
              id: this.operationId,
              type: this.operationType,
            });
            setTimeout(() => {
              this.visible = false;
            }, 2000);
          }
        })
        .catch(() => {
          this.timeout = setTimeout(() => {
            this.request();
          }, 2000);
        });
    },
  },
  created() {
    this.now = this.$now;
    this.request();
  },
  destroyed() {
    this.stopped = true;
    clearTimeout(this.timeout);
  },
};
</script>
