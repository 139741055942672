import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getActivityAudit',
    path: ({ from, to }) => `/v2/audit/${from}/${to}/activity`,
  })
  .get({
    action: 'getWorklogAudit',
    path: ({ from, to }) => `/v2/audit/${from}/${to}/worklog`,
  })
  .getStore();

export default store;
