export default {
  'originalDates': 'Ngày gốc',
  'originalDuration': 'Thời lượng ban đầu',
  'newDates': 'Ngày mới',
  'newDuration': 'Thời lượng mới',
  'firstDowntime': 'Thời gian ngưng máy lần 1',
  'secondDowntime': 'Thời gian ngưng máy lần 2',
  'shorten': 'Rút ngắn',
  'split': 'Tách ra',
  'thisMachine': 'máy này',
  'addComment': 'thêm nhận xét',
  'noComments': 'không có nhận xét',
  'downtimeSummary': 'Tổng kết thời gian ngừng máy',
  'step': 'Bước {step}',
  'selectRootLineMachine': 'Chọn nơi của một nguyên nhân gốc rễ',
  'selectLineMachine': 'Chọn dây chuyền/ máy',
  'selectState': 'Chọn trạng thái',
  'selectProblem': 'Chọn vấn đề',
  'specifyAReason': 'Chỉ rõ nguyên nhân',
  'correctiveActionTaken': 'Hành động khắc phục được thực hiện',
  'downtimeDuration': 'Khoảng thời gian ngừng máy',
  'vision': 'Tầm nhìn',
  'oeeLossType': 'Loại tổn thất OEE',
  'table': {
    'downtime': 'Thời gian ngừng máy',
    'details': 'Các chi tiết',
    'time': 'Thời gian',
    'duration': 'Khoảng thời gian'
  },
  'noSharedComments': 'Không có bình luận chia sẻ'
};
