export default {
  activeSince: 'Actif depuis',
  automation: {
    add: 'Ajouter une automatisation',
    automation: 'Automatisation',
    type: {
      CreateAndCloseQuestOnWorkEvent: {
        title: 'Créer et fermer une tâche',
        description: 'Créer une tâche lorsque ce type de problème survient sur la machine, et la fermer automatiquement lorsque le problème s\'arrête'
      },
      CreateQuestOnWorkEvent: {
        title: 'Créer une tâche',
        description: 'Créer une tâche lorsque ce type de problème se produit sur la machine'
      }
    }
  },
  function: {
    newFunction: 'Ajouter une fonction',
    editFunction: 'Fonction d\'édition',
    sessionInfo: 'Les fonctions utilisant le même nom de session seront exécutées séquentiellement (plutôt que simultanément)',
    sequenceProcessing: 'Traiter les balises suivantes de manière séquentielle',
    script: 'Scénario',
    sessionName: 'Nom de la séance',
    isStateful: 'En état',
    testResult: 'Résultat du test',
    testValue: 'Valeur d\'essai',
    triggers: 'Déclenche',
    run: 'Exécuter',
    trigger: 'Gâchette',
    storeParameters: 'Stocker les paramètres',
  },
  addLineOrMachine: 'Ajouter ligne / machine',
  addMachine: 'Ajouter la machine aux filtres',
  addNewShift: 'Ajouter une nouvelle équipe',
  addProperty: 'Ajouter une propriété',
  addRule: 'Ajouter une règle',
  addSignal: 'Ajouter un signal',
  adminPlantSettings: 'Paramètres des plantes d\'administration',
  advanced: 'Avancé',
  allowProductionOnDowntime: 'Autoriser la production pendant les temps d\'arrêt',
  rule: {
    addAndCondition: 'ET',
    cSharpType: 'Fonction C#',
    condition: 'État',
    info: 'L\'état de la machine est déterminé en fonction des règles ci-dessous. Lorsqu\'un changement de valeur de signal est observé, le moteur de règles analysera les règles ci-dessous en commençant par la priorité la plus élevée. La première règle qui renvoie \'vrai\' déterminera le nouvel état.',
    rule: 'Règle',
    ruleType: 'Type de règle',
    script: 'Script',
    eventType: 'Conditions simples',
    timeBasedRule: 'Règle basée sur le temps',
    showAdvanced: 'Utiliser l\'éditeur avancé',
  },
  attachedLinesAndMachines: 'Lignes et machines attachées',
  authUrl: 'Adresse URL d\'authentification',
  wasteBaseDiffCounter: 'Machine de base pour calculer les déchets différentiels',
  baseUnit: 'Unité de base',
  blockCard: 'Bloquer la carte',
  card: 'Carte',
  cardId: 'Identifiant de la carte',
  cardIdLengthError: 'L\'identifiant de la carte doit comporter au moins 4 caractères',
  cardPin: 'Code PIN de la carte',
  pinChange: 'Changer le code PIN',
  fromUnit: 'Unité de quantité esclave',
  countingOEE: 'Compter le TRS',
  dayOfWork: 'Journée de travail',
  default: 'Défaut',
  baseOrderUnit: 'Unité de commande par défaut',
  expectations: {
    designPerformance: 'Performances de conception',
    maxExpectedFailures: 'Nombre maximal d\'échecs attendu',
    expectedOee: 'TRS attendu',
    expectedPerformance: 'Performances attendues',
    validatedPerformance: 'Validated performance',
  },
  disableBufferResetBetweenOrders: 'Désactiver la réinitialisation de la mémoire tampon entre les commandes',
  displaySettings: 'Affichage',
  doYouWantToDelete: 'Voulez-vous supprimer',
  isDummyInfo: 'Les machines factices ne sont pas surveillées et n\'ont aucun signal. Ils peuvent être marqués manuellement comme cause première d\'un temps d\'arrêt.',
  editShift: 'Modifier l\'équipe',
  electricity: 'Électricité',
  electricityMax: 'Moyenne d\'électricité max',
  electricityBaseline: 'Base de référence de l\'électricité',
  elementsOrder: 'Éléments de la commande',
  forcedStateThreshold: 'Mettre fin aux temps d\'arrêt forcés',
  downtimeThreshold: 'Mettre fin aux temps d\'arrêt en cours',
  excludeOEE: 'Exclure le TRS',
  csvExport: 'Exporter CSV',
  logixGateConfigExport: 'Exporter la configuration LogixGate',
  signalsExport: 'Exporter des signaux',
  connections: 'Les flux',
  help: 'Aider',
  rootCauseDelayInfo: 'Jusqu’à quel moment cette machine peut-elle se briser, pour que d’autres machines la désignent toujours comme la cause première ?',
  maxTimeBufferOffsetInfo: 'Si la machine précédente a cessé de fonctionner, combien de temps faut-il pour que cette machine s’arrête ?',
  csvImport: 'Importer CSV',
  workEventsImport: 'Problèmes d\'importation',
  signalsImport: 'Importer des signaux',
  info: 'Informations',
  shortPerformance: 'Performances instantanées',
  shortPerformanceInfo: 'Performances instantanées calculées comme une augmentation moyenne récente de la production',
  isBaseUnit: 'Est basé',
  isDummy: 'Est factice',
  layout: 'Disposition',
  noLayoutLine: 'La ligne n\'est pas définie dans la disposition de l\'usine',
  noCounter: 'La machine n\'a pas de compteur',
  machineLicenses: 'Licences machines',
  machineSignals: 'Signaux des machines',
  machineBaseUnitSetInCounter: 'L\'unité de la machine est déterminée par l\'unité du compteur',
  multiplier: 'Multiplicateur',
  newPin: 'Nouveau code PIN',
  newShift: 'Nouveau équipe',
  noIssuesDetected: 'Aucun problème de configuration détecté !',
  notSelected: 'Non séléctionné',
  nothingToAdd: 'Rien à ajouter',
  events: {
    forcedOnly: 'Seulement forcé',
    showSystem: 'Afficher les événements système',
    notAssignedEvents: 'Événements non attribués',
  },
  orderAutostartThreshold: 'Commander le démarrage automatique',
  toUnit: 'Unité de gestion principale',
  validatedPerformanceInfo: 'Des performances de la machine mesurées et validées en conditions réelles',
  shortPerformancePeriod: 'Période',
  pinLengthError: 'Le code PIN doit comporter exactement 4 chiffres',
  plantConfiguration: 'Configuration de l\'usine',
  functions: 'Fonctions de prétraitement',
  deleteInfo: 'Appuyez sur le bouton "Enregistrer" pour supprimer',
  oldPin: 'Code PIN précédent',
  priority: 'Priorité',
  production: 'Production',
  productionExpectations: 'Objectifs de production',
  properties: 'Propriétés',
  removeShift: 'Supprimer l\'équipe',
  unit: {
    usedInPackageStructure: 'Suppression de l\'unité impossible, elle est utilisée dans une définition de structure de package',
    fieldsNotValid: 'Format de champ incorrect',
  },
  rootCauseDelay: 'Retard de la cause principale',
  rules: 'Règles',
  saveFailed: 'Échec de la sauvegarde',
  saveLayout: 'Enregistrer la disposition',
  setUrl: 'Enregistrer une nouvelle URL',
  saved: 'Enregistré',
  differentTimezoneSettingsInfo: 'Les paramètres ci-dessous s\'appliqueront au fuseau horaire d\'usine',
  setupIssues: 'Problèmes de configuration',
  shiftDefinitions: 'Définitions des équipes',
  shortName: 'Nom court',
  signalNameError: 'Le nom du signal ne peut pas contenir de caractères " ( " et " ) "',
  signals: 'Signaux',
  signalsAndProperties: 'Signaux et propriétés',
  maxTimeBufferOffset: 'Délai d’arrêt après la machine précédente',
  test: 'Test',
  designPerformanceInfo: 'Les performances de la machine qui peuvent être atteintes selon le fabricant',
  tooltipState: {
    inactive: 'Il s\'agit d\'un état neutre que PackOS utilise pour votre machine lorsqu\'il n\'y a pas de panne mais que la machine ne fonctionne pas.',
    nolivedata: 'Il s\'agit d\'un état temporaire qui apparaît lorsque PackOS perd temporairement la connexion avec l\'usine,\r\n    par exemple, à cause des problèmes de connexion Internet. PackOS n\'affiche cet état que temporairement et rapidement le change en d\'autres états opérationnels',
    lack: 'Cet état apparaît lorsque votre machine ne reçoit pas assez de composants nécessaires\r\r\n    pour son travail continu. Le problème peut être un convoyeur défectueux, les opérateurs qui ne\r\r\n    recharger les composants à temps, ou le stockage des composants étant trop éloigné de la ligne. »',
    off: 'Cet état apparaît lorsque votre machine est déconnectée car, par exemple, l\'alimentation a été coupée ou le\r\n    la machine a perdu la connexion avec le pilote',
    holdup: 'Cet état apparaît lorsque votre machine ne fonctionne pas car sa sortie est bloquée, généralement par\r\n    Machines. Dans ce cas, PackOS recherche la source du problème en vérifiant la machine en aval. La source\r\n    du problème dans la machine en aval est copié comme état et problème pour la machine avec le statut Holdup',
    work: 'Cet état apparaît lorsque votre machine fonctionne correctement et que le processus de production est en cours',
    changeover: 'Cet état apparaît lorsque votre machine est arrêtée car soit la ligne de production soit la machine est\r\n    ajusté pour un nouveau processus de production (changement)',
    stop: 'Cet état indique que votre machine a été arrêtée volontairement par un opérateur parce qu\'il\r\n    voulait, par exemple, le configurer ou le nettoyer. Lorsque votre machine est dans cet état, PackOS affiche le problème qui s\'est produit avant l\'entrée en vigueur de l\'état Arrêté par l\'opérateur.',
    failure: 'Cet état indique que votre machine s\'est arrêtée ou ne fonctionne pas correctement à cause de,\r\n    par exemple, usure mécanique ou erreur de l\'opérateur. Dans ce cas, la source du problème est le problème\r\n    qui s\'est produit avant l\'apparition de l\'état d\'échec',
    idle: 'Cet état indique lorsque votre machine ne fonctionne pas bien qu\'elle ne soit pas en panne et qu\'il n\'y ait pas de problème avec ça. Dans ce cas, PackOS recherche la source du problème en vérifiant la machine en amont',
  },
  forcedStateThresholdInfo: 'Seuil définissant la quantité de production nécessaire pour terminer automatiquement l\'arrêt forcé',
  downtimeThresholdInfo: 'Seuil définissant la quantité de production nécessaire pour terminer automatiquement l\'arrêt en cours',
  orderAutostartThresholdInfo: 'Seuil définissant la quantité de production nécessaire pour lancer automatiquement une commande',
  minorStopThreshold: 'Seuil d\'un arrêt mineur',
  thresholds: 'Seuils',
  timezone: 'Fuseau horaire',
  unitCode: 'Code d\'unité',
  units: 'Unités',
  users: 'Utilisateurs',
  value: 'Valeur',
  differentTimezoneSelectorInfo: 'Nous afficherons l\'heure convertie en votre fuseau horaire',
  loadedOnNextLogin: 'Sera chargé après la prochaine connexion',
  workingHours: 'Heures de travail',
  differentTimezoneInfo: 'Vous êtes dans un fuseau horaire différent de celui de l\'usine !',
  yourCurrent: 'Votre actuel',
  'hints': 'Règles d\'astuce',
  'operatorCockpit': 'Cockpit opérateur',
  'weekStart': 'Début de semaine',
  'cockpitType': 'Type de cockpit',
  'cockpitLayout': 'Disposition du cockpit',
  'maxPerformance': 'Performance maximale instantanée',
  'maxPerformanceInfo': 'Utilisé dans le garde de compteur, pour valider si la nouvelle valeur de compteur est réalisable (0 = garde désactivé)',
  'differentialWaste': 'Gaspillage différentiel',
  'dataValidation': 'Validation des données',
  'importTemplates': 'Modèles d\'importation',
  'calculateEnergyFromMachines': 'Calculer l\'énergie à partir des machines',
  'taxonomy': {'taxonomy': 'Taxonomie'},
  'sku': {
    'expectedEffort': 'Effort attendu (mh)',
    'skuDescriptionTooltip': 'Si laissé vide, la description du SKU sera utilisée'
  },
  'energy': 'Énergie',
  'allTypes': 'Tous les types'
};
