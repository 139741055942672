import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import loopRequest from '@/mixins/loopRequest';

export default {
  props: {
    dateFrom: {},
    dateTo: {},
  },
  data: () => ({
    timeout: null,
  }),
  mixins: [loopRequest('getData', 10000)],
  computed: {
    ...mapGetters('plant', ['lines']),
    ...mapGetters(['shiftInstances']),
    startDate() {
      return this.dateFrom && this.dateTo
        ? moment.unix(this.dateFrom).format()
        : moment().startOf('day').format();
    },
    endDate() {
      return this.dateFrom && this.dateTo
        ? moment.unix(this.dateTo).format()
        : moment().endOf('day').format();
    },
  },
  methods: {
    ...mapActions(['getWorkCalendar']),
    correctParams({ startDate, endDate }) {
      return this.startDate === startDate
      && this.endDate === endDate;
    },
    debouncedRequest() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      // delayed request
      this.timeout = setTimeout(() => {
        this.getData();
      }, 500);
    },
    getData() {
      return this.getWorkCalendar({
        params: {
          startDate: this.startDate,
          endDate: this.endDate,
          query: {
            id: this.lines.map(x => x.id),
          },
        },
      })
        .then(({ data, config: { params } }) => {
          if (!this.correctParams(params)) return;
          this.$store.commit('setWorkCalendar', data);
        });
    },
  },
  watch: {
    dateFrom() {
      this.debouncedRequest();
    },
    dateTo() {
      this.debouncedRequest();
    },
  },
  mounted() {
    this.getData();
  },
  render() { return true; },
};
