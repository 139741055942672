<template>
  <div>
    <TreeTableRow
      v-if="!hide && (root.id || showNullIdElement)"
      :node="root"
      :expanded="expanded"
      :columns="columns"
      :expandable="filtered.length > 0 && !readOnly"
      :highlighted="highlighted"
      :is-value="isValue"
      :hide-add="hideAdd || readOnly"
      :hide-edit="readOnly"
      :selected="selected === root.id || selectedReduce(root.id)"
      :padding-sum="paddingSum"
      :hide-actions="hideActions"
      :disabled="disableReduce(root)"
      @highlight="highlight"
      @expand="expand"
      @edit="$emit('edit', root.id)"
      @add="$emit('add', root)"
      @select="$emit('select', root)"
    />
    <div
      v-for="element in filtered"
      :key="element.id"
      :style="{ 'padding-left': (hide || (!root.id) && !showNullIdElement) ? '' : `${padding}px` }"
    >
      <Transition name="fade">
        <TreeTable
          v-if="expanded || readOnly || hide"
          :columns="columns"
          :list="list"
          :is-root="false"
          :root="element"
          :is-value="isValue"
          :expand-list="expandList"
          :read-only="readOnly"
          :hide-add="hideAdd"
          :padding-sum="paddingSum + padding"
          :selected="selected"
          :hide-actions="hideActions"
          :default-expand="defaultExpand"
          :disable-reduce="disableReduce"
          :selected-reduce="selectedReduce"
          @highlight="highlight"
          @edit="$emit('edit', $event)"
          @add="$emit('add', $event)"
          @expand="$emit('expand', $event)"
          @select="$emit('select', $event)"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { elementType } from '@/utils/dictionary';
import TreeTableRow from './TreeTableRow';

export default {
  name: 'TreeTable',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    root: {
      type: Object,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    hide: Boolean,
    isValue: Function,
    paddingSum: {
      type: Number,
      default: 0,
    },
    padding: {
      type: Number,
      default: 40,
    },
    selected: String,
    readOnly: Boolean,
    hideActions: Boolean,
    hideAdd: Boolean,
    expandList: {
      type: Array,
      default: () => [],
    },
    isRoot: {
      type: Boolean,
      default: true,
    },
    defaultExpand: {
      type: Boolean,
      default: true,
    },
    showNullIdElement: {
      type: Boolean,
      default: true,
    },
    disableReduce: {
      type: Function,
      default: () => false,
    },
    selectedReduce: {
      type: Function,
      default: () => false,
    },
  },
  data: () => ({
    expanded: true,
    highlighted: false,
  }),
  components: {
    TreeTableRow,
  },
  computed: {
    // TODO: Active flows should not be used here, this is a generic component!!!
    ...mapGetters('plant', [
      'activeFlows',
    ]),
    filtered() {
      const flow = this.root.type === elementType.line
        && this.activeFlows.find(f => f.rootId === this.root.id);

      const filteredList = this.list.filter(el => el.parentId === this.root.id);

      if (!flow) {
        return filteredList;
      }

      const elementsOrder = flow.inline();
      const sortedList = filteredList
        .sort((a, b) => elementsOrder[a.id] - elementsOrder[b.id]);

      const withoutEdges = flow.allWithoutEdges().sort((a, b) => a.name.localeCompare(b.name))
        .map(n => filteredList.find(ln => ln.id === n.id));

      const fullList = [...sortedList, ...withoutEdges].filter(n => n);
      return fullList.filter((item, index) => fullList.indexOf(item) === index);
    },
  },
  watch: {
    defaultExpand(v) {
      this.expanded = v || this.isRoot;
    },
    selected(id) {
      if (this.root.id === id) {
        this.$emit('expand', id);
        this.expanded = true;
      }
    },
  },
  methods: {
    getElement(id) {
      return this.list.find(el => el.id === id);
    },
    highlight(select) {
      this.highlighted = select;
      this.$emit('highlight', select);
    },
    expand(select) {
      this.$emit('expand', this.root.id);
      this.expanded = select;
    },
  },
  created() {
    this.expanded = this.defaultExpand || this.isRoot;
    if (this.expandList && this.expandList.findIndex(x => x === this.root.id) > -1) {
      this.expanded = true;
    }
    if (this.root.id === this.selected) {
      this.$emit('expand', this.selected);
    }
  },
};
</script>
