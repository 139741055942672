<template>
  <div class="d-flex history-entry">
    <div class="col">
      {{ format(start) }}
    </div>
    <div>
      ->
    </div>
    <div class="col">
      {{ end ? format(end) : format($now * 1000) }}
    </div>
    <div class="col">
      {{ value }} {{ productionUnit || '?' }}/h
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    start: String,
    end: String,
    value: Number,
    productionUnit: String,
  },
  methods: {
    format(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.history-entry {
  padding: 5px 10px;
  box-shadow: 0 2px 4px #eee;
}
</style>
