import axios from '@/api/axios';
import lang from '@/lang';
import colors from '@/utils/colors';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const worklogs = new Vapi({
  axios,
  state: {
    dateScope: {},
    list: [],
    worklogWorkEvents: [],
    elementLogs: {},
    modalElementLogs: {},
    worsts: [],
    taxonomyWorsts: [],
    aggregatedWorklogs: [],
    aggregatedTaxonomyWorklogs: [],
  },
})
  .get({
    action: 'getPlantEventsConfig',
    path: ({ plantId }) => `/v2/plant/${plantId}/eventconfiguration`,
  })
  .get({
    action: 'getAggregatedWorklogs',
    property: 'aggregatedWorklogs',
    path: ({
      plantId,
    }) => `/v2/plant/${plantId}/workLogs/aggregated`,
  })
  .get({
    action: 'getAggregatedTaxonomyWorklogs',
    property: 'aggregatedTaxonomyWorklogs',
    path: ({
      plantId,
    }) => `/v2/plant/${plantId}/workLogs/taxonomy/aggregated`,
  })
  .get({
    action: 'getWorklogs',
    property: 'worklogs',
    path: ({
      plantId, startDate, endDate,
    }) => `/v2/plant/${plantId}/workLogs/between/${startDate}/${endDate}`,
    onSuccess: (s, { data }) => {
      s.worklogs = data;
      s.worklogWorkEvents = data
        .flatMap(wl => wl.workEvents)
        .reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
    },
  })
  .get({
    action: 'getWorklog',
    path: ({
      plantId,
      elementId,
      startDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/workLog/${startDate}`,
  })
  .get({
    action: 'getOrderWorklogs',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/workLogs`,
  })
  .get({
    action: 'getBufferSettings',
    path: ({ plantId, lineId }) => `/v2/plant/${plantId}/line/${lineId}/buffers/settings`,
  })
  .get({
    action: 'exportWorklogs',
    requestConfig: {
      responseType: 'stream',
    },
    path: ({ plantId, startDate, endDate }) =>
      `/v2/plant/${plantId}/workLogs/${startDate}/${endDate}/export`,
  })
  .get({
    action: 'getWorsts',
    property: 'worsts',
    path: ({
      plantId, startDate, endDate, count,
    }) => `/v2/plant/${plantId}/worsts/${startDate}/${endDate}/${count}`,
  })
  .get({
    action: 'getTaxonomyWorsts',
    property: 'taxonomyWorsts',
    path: ({
      plantId, startDate, endDate, count,
    }) => `/v2/plant/${plantId}/taxonomy-worsts/${startDate}/${endDate}/${count}`,
  })
  .get({
    action: 'getWorklogsAnnotations',
    path: ({ plantId }) => `/v2/plant/${plantId}/report/worklogs/annotations`,
  })
  .put({
    action: 'updateWorkLogCorrectiveActions',
    path: ({ plantId }) => `/v2/plant/${plantId}/workLogs/correctiveAction`,
  })
  .put({
    action: 'updateWorkLogReason',
    path: ({ plantId }) => `/v2/plant/${plantId}/workLogs/reason`,
  })
  .put({
    action: 'updateWorkLogProblem',
    path: ({ plantId }) => `/v2/plant/${plantId}/workLogs/rootCause`,
  })
  .put({
    action: 'adjustWorkLogDuration',
    path: ({
      plantId,
      elementId,
      startDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/workLog/${startDate}/time`,
  })
  .put({
    action: 'splitWorkLog',
    path: ({
      plantId,
      elementId,
      startDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/workLog/${startDate}/split`,
  })
  .put({
    action: 'stopElement',
    path: ({
      plantId,
      elementId,
      eventId,
    }) => `/v2/plant/${plantId}/element/${elementId}/downtime/force/${eventId}`,
  })
  .put({
    action: 'startElement',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/downtime/force/stop`,
  })
  .getStore();

worklogs.mutations.setWorklogWorkEvents = (s, { events }) => {
  s.worklogWorkEvents = events.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
};

worklogs.mutations.updateWorklogDateScope = (s, { startDate, endDate }) => {
  s.dateScope = { startDate, endDate };
};

worklogs.mutations.updateWorklogList = (s, { log, elementId, key = 'elementLogs' }) => {
  const sorted = log.sort((a, b) => b.startDate - a.startDate);
  Vue.set(s[key], elementId, sorted);
};

worklogs.mutations.removeWorkLog = (s, { startDate, elementId, key = 'elementLogs' }) => {
  s[key][elementId] = (s[key][elementId] || []).filter(x => x.startDate !== startDate);
};

worklogs.mutations.worklogDurationUpdate = (s, {
  workLogId,
  elementId,
  startDate,
  endDate,
}) => {
  const list = s.elementLogs[elementId] || [];
  const workLog = list.find(x => x.workLogId === workLogId);

  if (workLog != null) {
    Vue.set(workLog, 'startDate', startDate);
    Vue.set(workLog, 'endDate', endDate);
  }
};

worklogs.mutations.worklogUpdate = (s, data) => {
  data.forEach(updateData => {
    const list = s.elementLogs[updateData.elementId] || [];
    const index = list.findIndex(el => el.id === updateData.workLogId);

    if (index === -1) {
      return;
    }

    const worklog = list[index];

    const rootWorkLogUpdate = updateData.rootWorkLog;

    if (updateData.targetWorkEventId || rootWorkLogUpdate) {
      worklog.rootWorkLog = rootWorkLogUpdate?.elementId === updateData.elementId ? null : rootWorkLogUpdate;
    }

    const correctiveActionId = updateData.correctiveActionId === null || updateData.correctiveActionId
      ? updateData.correctiveActionId : worklog.correctiveActionId;

    const reasonId = updateData.reasonId === null || updateData.reasonId
      ? updateData.reasonId : worklog.reasonId;

    Vue.set(worklog, 'eventId', updateData.targetWorkEventId || rootWorkLogUpdate?.eventId || worklog?.eventId);
    Vue.set(worklog, 'correctiveActionId', correctiveActionId);
    Vue.set(worklog, 'reasonId', reasonId);

    if (worklog.rootWorkLog) {
      worklog.rootWorkLog.correctiveActionId = updateData.correctiveActionId === null || updateData.correctiveActionId
        ? updateData.correctiveActionId : worklog.rootWorkLog.correctiveActionId;

      worklog.rootWorkLog.reasonId = updateData.reasonId === null || updateData.reasonId
        ? updateData.reasonId : worklog.rootWorkLog.reasonId;
    }

    Vue.set(list, index, worklog);
  });
};

worklogs.mutations.clearWorklogs = s => {
  Vue.set(s, 'worklogs', []);
};

const getEventAndState = (g, { eventId, workStateName }) => {
  const workEvent = g.workEvent(eventId);
  if (!workEvent && !workStateName) return {};
  if (!workEvent) {
    return {
      workEvent: null,
      workState: g.workStateByName(workStateName),
    };
  }

  const workState = g.workState(workEvent.workStateId) || {};
  return {
    workEvent,
    workState,
  };
};

worklogs.getters = {
  bufferedWorkLog: s => (elementId, key = 'elementLogs') => s[key][elementId] || [],
  parseWorklog: (s, g) => w => ({
    ...w,
    ...getEventAndState(g, w),
    rootWorkLog: !w.rootWorkLog ? null : {
      ...w.rootWorkLog,
      ...getEventAndState(g, w.rootWorkLog),
    },
  }),
  workEventFromWorklogs: s => eventId => s.worklogWorkEvents[eventId],
  worklogForChart: () => ({
    worklog, startDate, endDate, now,
  }) => {
    if (!worklog) return {};
    if (!worklog.workEvent && !worklog.workState) return {};

    const end = Math.min(endDate, now || endDate);
    const w = worklog;
    const { workEvent } = w;
    const { workState } = w;

    return {
      start: Math.max(startDate, w.startDate),
      end: Math.min((w.endDate || end), end),
      color: colors(workState.colorCode),

      className: workEvent ? workEvent.name : lang.t('general.deleted'),
      classId: workEvent ? workEvent.id : 0,
      isDefault: workEvent ? workEvent.isDefault : false,

      reasonId: w.workReason ? w.workReason.id : null,

      codeId: workState.id,
      state: workState,

      worklog: {
        ...worklog,
        endDate: worklog.endDate || end,
      },
    };
  },
};

export default worklogs;
