import ElementIndex from '@/pages/Element';
import Activities from '@/pages/activities/Activities';
import Items from '@/pages/items/Items';
import LineIndex from '@/pages/line/Index';
import Machines from '@/pages/line/Machines';
import Shifts from '@/pages/line/Shifts';
import PlantIndex from '@/pages/plant/Index';
import {
  PACKOS_ELEMENT_ACCESS,
  PACKOS_MATERIAL_MANAGEMENT,
  PACKOS_ORDER_EDIT,
  PACKOS_ORDER_LIFECYCLE,
  PACKOS_ORDER_MANAGEMENT,
  PACKOS_ORDER_VIEW,
  PACKOS_SKU_EDIT,
  PACKOS_SKU_LIST,
} from '@core/dict/permissions';

import LineHeader from '@/components/line/LineHeader';
import PlantHeader from '@/components/plant/PlantHeader';
import ActiveFlows from '@/pages/plant/ActiveFlows';

import Import from '@/pages/importer/Import';
import Importer from '@/pages/importer/Importer';

import reports from './reports';
import settings from './settings';

const Overview = () => import('@/pages/plant/Overview');
const ProcessParams = () => import('@/pages/machine/ProcessParams');
const CurrentParams = () => import('@/pages/machine/CurrentParams');
const Counters = () => import('@/pages/machine/Counters');
const Details = () => import('@/pages/machine/Details');
const OrdersIndex = () => import('@/pages/orders/Index');
const ItemsIndex = () => import('@/pages/items/Index');
const Lines = () => import('@/pages/plant/Lines');
const PlantMap = () => import('@/pages/plant/PlantMap');
const Switchboard = () => import('@/pages/graph/Switchboard');
const Vision = () => import('@/pages/Vision');

// Convert specified list of parameters to integers
export const convId = (...ids) => route => ids.reduce((acc, curr) => {
  const id = parseInt(route.params[curr], 10);
  return Number.isNaN(id) ? acc : { ...acc, [curr]: id };
}, route.params);

export default [
  settings,
  reports,
  { path: 'switchboard/:graphId', component: Switchboard, props: true },
  {
    path: 'activities',
    name: 'activities',
    component: Activities,
    props: true,
    meta: {
      permittedFor: [PACKOS_ORDER_MANAGEMENT, PACKOS_ORDER_VIEW, PACKOS_ORDER_LIFECYCLE, PACKOS_ORDER_EDIT],
    },
  },
  {
    path: 'vision',
    name: 'vision',
    component: Vision,
  },
  {
    path: '',
    component: OrdersIndex,
    props: true,
  },
  {
    path: 'importer/:parent',
    component: Importer,
    props: true,
  },
  {
    path: 'importer/:parent/import/:importId',
    component: Import,
    props: true,
  },
  {
    path: '',
    component: ItemsIndex,
    props: true,
    children: [
      {
        path: 'items/:lineId?',
        name: 'items',
        component: Items,
        props: true,
        meta: {
          permittedFor: [PACKOS_MATERIAL_MANAGEMENT, PACKOS_SKU_LIST, PACKOS_SKU_EDIT],
        },
      },
    ],
  },
  {
    path: 'dashboard',
    component: PlantIndex,
    props: true,
    children: [
      {
        path: 'map',
        component: PlantMap,
      },
      {
        path: 'overview',
        component: Overview,
        props: true,
      },
      {
        path: 'lines',
        name: 'lines',
        components: {
          default: Lines,
          header: PlantHeader,
        },
        props: { default: true, header: true },
      },
      {
        path: 'flows/:flowId?',
        name: 'flows',
        component: ActiveFlows,
        props: true,
      },
    ],
  },
  // LINE
  {
    path: 'dashboard/line/:lineId',
    component: LineIndex,
    props: true,
    children: [
      {
        path: 'machines',
        components: {
          default: Machines,
          header: LineHeader,
        },
        props: { default: true, header: true },
      },
      {
        path: 'worklogs',
        component: () => import('@/pages/line/Worklogs'),
        props: true,
      },
      {
        path: 'worklogs/:elementId',
        component: () => import('@/pages/line/Worklogs'),
        props: true,
      },
      {
        path: 'shifts',
        components: {
          default: Shifts,
          header: LineHeader,
        },
        props: { default: true, header: true },
      },
    ],
  },
  // MACHINE
  {
    path: 'element/:elementId',
    component: ElementIndex,
    props: true,
    children: [
      {
        path: 'current',
        component: CurrentParams,
        props: true,
      },
      {
        path: 'counters',
        component: Counters,
        props: true,
      },
      {
        path: 'params',
        component: ProcessParams,
        props: true,
      },
      {
        path: 'details',
        component: Details,
        name: 'details',
        props: true,
      },
    ],
    meta: {
      permittedFor: [PACKOS_ELEMENT_ACCESS],
    },
  },
];
