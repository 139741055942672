import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const taxonomies = new Vapi({
  axios,
  state: {
    assignmentData: [],
    workEventTaxonomies: [],
    elementTaxonomies: [],
    elementTaxonomyBindings: {},
  },
})
  .get({
    action: 'getWorkEventTaxonomies',
    property: 'workEventTaxonomies',
    path: '/v3/taxonomies/assignment-data',
  })
  .get({
    action: 'getElementTaxonomies',
    property: 'elementTaxonomies',
    path: '/v3/taxonomies/assignment-data',
  })
  .get({
    action: 'getWorkEventsTaxonomyBinding',
    path: ({ plantId }) => `/v2/plant/${plantId}/workEvent/taxonomies`,
  })
  .get({
    action: 'getAllWorkEventsTaxonomyBinding',
    path: ({ plantId }) => `/v2/plant/${plantId}/workEvent/taxonomies/all`,
  })
  .get({
    action: 'getElementTaxonomyBinding',
    path: ({ plantId }) => `/v2/plant/${plantId}/element/taxonomies`,
    onSuccess: (s, { data }) => {
      s.elementTaxonomyBindings = {};
      data.forEach(i => {
        s.elementTaxonomyBindings[i.elementId] = [
          ...(s.elementTaxonomyBindings[i.elementId] || []),
          i,
        ];
      });
    },
  })
  .get({
    action: 'getWorkEventTaxonomyBindings',
    path: ({ plantId, workEventId }) => `/v2/plant/${plantId}/workEvent/${workEventId}/taxonomies`,
  })
  .put({
    action: 'assignWorkEventToTaxonomy',
    path: ({
      plantId,
      workEventId,
    }) => `/v2/plant/${plantId}/workEvent/${workEventId}/taxonomies/assign`,
  })
  .put({
    action: 'unassignWorkEventToTaxonomy',
    path: ({
      plantId,
      workEventId,
    }) => `/v2/plant/${plantId}/workEvent/${workEventId}/taxonomies/unassign`,
  })
  .get({
    action: 'getElementTaxonomyBindings',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/taxonomies`,
    onSuccess: (s, { data }, _, { params }) => {
      s.elementTaxonomyBindings[params.elementId] = data;
    },
  })
  .getStore({
    namespaced: true,
  });

taxonomies.getters = {
  elementTaxonomyBindings: s => elementId => s.elementTaxonomyBindings[elementId],
  taxonomyNodeName: s => (taxonomyId, id) => {
    const taxonomy = [...s.elementTaxonomies, ...s.workEventTaxonomies].find(t => t.id === taxonomyId);
    if (!taxonomy) return '?';
    const item = taxonomy.nodes.find(n => n.id === id);
    if (!item) return '?';
    return item.description || item.code || '-';
  },
};

taxonomies.mutations.setElementTaxonomyBindings = (s, elementId, elementTaxonomyBindings) => {
  if (!elementId || elementTaxonomyBindings) return;
  s.elementTaxonomyBindings[elementId] = elementTaxonomyBindings;
};

export default taxonomies;
