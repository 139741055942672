import Vapi from 'vuex-rest-api';
import moment from 'moment';
import axios from '@/api/axios';
import colors from '@/utils/colors';
import lang from '@/lang';
import { orderStatus } from '@/utils/dictionary';

const orders = new Vapi({
  axios,
  state: {
    orderStart: null,
    orders: null,
    activeOrders: [],
  },
})
  .put({
    action: 'createOrUpdateOrder',
    path: () => '/v3/activities/orders',
  })
  .head({
    action: 'checkOrder',
    path: ({ externalId }) => `/v3/activities/orders/${externalId}`,
  })
  .get({
    action: 'getOrders',
    property: 'orders',
    path: () => '/v3/activities/orders',
  })
  .get({
    action: 'getOrdersReport',
    path: ({ plantId }) => `/v2/plant/${plantId}/report/orders`,
  })
  .get({
    action: 'getOrdersBetween',
    property: 'orders',
    path: () => '/v3/activities/orders/between',
  })
  .get({
    action: 'getOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}`,
  })
  .get({
    action: 'getOrderErrors',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}/validation-errors`,
  })
  .delete({
    action: 'deleteOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}`,
  })
  .post({
    action: 'activateOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}/commands/activate`,
  })
  .post({
    action: 'startOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}/commands/start`,
  })
  .post({
    action: 'deactivateOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}/commands/deactivate`,
  })
  .post({
    action: 'completeOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}/commands/complete`,
  })
  .post({
    action: 'withdrawOrder',
    path: ({ orderId }) => `/v3/activities/orders/${orderId}/commands/withdraw`,
  })
  .put({
    action: 'updateOrderLineConfig',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/lineconfiguration`,
  })

  /* Order components */
  .get({
    action: 'getOrderComponents',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/components`,
  })
  .put({
    action: 'updateOrderComponent',
    path: ({ plantId, orderId, componentId }) => `/v2/plant/${plantId}/order/${orderId}/component/${componentId}`,
  })
  .post({
    action: 'addOrderComponent',
    path: ({ plantId, orderId, componentId }) => `/v2/plant/${plantId}/order/${orderId}/component/${componentId}`,
  })
  .delete({
    action: 'deleteOrderComponent',
    path: ({ plantId, orderId, componentId }) => `/v2/plant/${plantId}/order/${orderId}/component/${componentId}`,
  })

  /*  Order packages */
  .get({
    action: 'getOrderPackages',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/packages`,
  })
  .post({
    action: 'addOrderPackage',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/package`,
  })
  .put({
    action: 'closeOrderPackage',
    path: ({ plantId, orderId, packageId }) => `/v2/plant/${plantId}/order/${orderId}/package/${packageId}/close`,
  })
  .put({
    action: 'updatePackageQuantity',
    path: ({ plantId, orderId, packageId }) => `/v2/plant/${plantId}/order/${orderId}/package/${packageId}`,
  })
  .put({
    action: 'activatePackage',
    path: ({ plantId, orderId, packageId }) => `/v2/plant/${plantId}/order/${orderId}/package/${packageId}/activate`,
  })
  .post({
    action: 'importOrders',
    path: ({ plantId }) => `/v2/plant/${plantId}/orders/import`,
  })
  .getStore();

orders.getters = {
  getOrderState: () => ({ status }) => {
    switch ((status || '').toUpperCase()) {
      case 'READY':
        return {
          icon: 'ion ion-ios-rocket',
          color: colors('orange'),
          desc: lang.t('orders.states.ready'),
          name: status,
        };
      case 'INPROGRESS':
        return {
          icon: 'fas fa-play',
          color: colors('blue'),
          desc: lang.t('orders.states.inProgress'),
          name: status,
        };
      case 'COMPLETE':
        return {
          icon: 'fas fa-check-circle',
          color: colors('green'),
          desc: lang.t('orders.states.completed'),
          name: status,
        };
      case 'ACTIVATED':
        return {
          icon: 'ion ion-ios-flash',
          color: colors('blue_pastel'),
          desc: lang.t('orders.states.activated'),
          name: status,
        };
      case 'ONHOLD':
        return {
          icon: 'ion ion-ios-pause',
          color: colors('grey'),
          desc: lang.t('orders.states.onHold'),
          name: status,
        };
      default:
        return {
          icon: 'ion ion-md-help',
          color: colors('grey'),
          desc: lang.t('orders.states.other'),
          name: status,
        };
    }
  },
  toScheduleLog: (s, g, rs, rg) => (list, startDate, endDate, now, withActivation) => {
    if (!list) return [];
    const end = endDate || now;

    const result = list.map(o => ({
      start: o.status.toLowerCase() === 'activated'
        ? moment.unix(Math.max(startDate, o.activated)).startOf('minute').unix()
        : moment.unix(Math.max(startDate, o.startDate)).startOf('minute').unix(),
      end: o.status.toLowerCase() === 'inprogress' || o.status.toLowerCase() === 'activated' ? now
        : moment.unix(Math.min((o.endDate || end), end)).startOf('minute').unix(),
      color: g.getOrderState(o).color,
      icon: g.getOrderState(o).icon,
      type: rg.activityTypes.ZPROD.name,
      name: o.orderNumber,
      id: `order-${o.id}`,
      item: {
        ...o,
      },
    }));

    if (withActivation) {
      const activations = result.flatMap(activity => {
        if (activity.item.activated && activity.item.status?.toLowerCase() !== orderStatus.activated) {
          const state = g.getOrderState({ status: orderStatus.activated });

          const activation = {
            ...activity,
            start: activity.item.activated,
            color: state ? state.color : colors('grey'),
            end: activity.start,
            id: `${activity.id}_activation`,
            name: lang.t('orders.states.activated'),
            icon: state.icon,
            item: {
              ...activity.item,
              id: `${activity.id}_activation`,
              status: orderStatus.activated,
            },
          };
          return [activation];
        }
        return [];
      });
      return result.concat(activations);
    }

    return result;
  },
  manualHistory: s => s.manualHistory,
};

export default orders;
