<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-end"
      style="margin-bottom: 5px"
    >
      <div
        v-if="performance"
        class="d-flex no-gutters flex-wrap font-weight-bold text-nowrap"
      >
        <div class="value col-12 col-md-auto">
          <div
            v-tippy="{ arrow: true }"
            :content="shortPerformanceMessage"
          >
            <i class="fas fa-stopwatch production-icon" />{{ performance.shortPerformance | round }}
            <span
              v-if="performance.shortPerformanceUnit != performance.performanceUnit"
              class="font-weight-normal"
            >
              {{ performance.shortPerformanceUnit }}
            </span>
          </div>
        </div>
        <div class="value col-12 col-md-auto">
          <div
            v-tippy="{ arrow: true }"
            :content="`${$t('monitoring.performance.effective')} - ${simpleCurrentScopeText}`"
          >
            <i class="far fa-calendar production-icon" />{{ performance.effectivePerformance | round }}
            <span class="unit d-inline d-md-none">
              {{ performance.unit }}
            </span>
          </div>
        </div>
        <div class="value col-12 col-md-auto">
          <div
            v-if="performance.expectedPerformance"
            v-tippy="{ arrow: true }"
            :content="`${$t('monitoring.performanceType')}:
              ${performanceType ? $t('monitoring.validatedSpeed') : $t('monitoring.designSpeed')}`"
          >
            <i class="far fa-clipboard production-icon" />{{ performance.expectedPerformance | round }}
            <span class="unit d-none d-md-inline">
              {{ performance.performanceUnit }}
            </span>
          </div>
        </div>
      </div>
      <div v-else />

      <div
        v-if="production"
        class="d-flex flex-wrap no-gutters flex-fill text-nowrap ml-1"
        :class="!performance && 'justify-content-center justify-content-md-end mb-1 mb-md-0'
          || 'justify-content-end mb-0' "
      >
        <div
          v-tippy="{ arrow: true }"
          :content="$t('monitoring.produced')"
          class="value font-weight-bold text-right text-primary"
          :class="performance && 'col-12 col-md-auto' || 'col-auto'"
        >
          <i class="fas fa-thumbs-up production-icon" />{{ production.done | round | addSpaces }}
        </div>
        <div
          v-if="production.wasted"
          v-tippy="{ arrow: true }"
          :content="$t('monitoring.wasted')"
          class="value font-weight-bold text-right"
          :class="performance && 'col-12 col-md-auto' || 'col-auto'"
        >
          <span class="text-danger">
            <i class="fas fa-thumbs-down production-icon" />{{ production.wasted | round | addSpaces }}
          </span>
        </div>
        <Tippy
          :class="performance && 'col-12 col-md-auto' || 'col-auto'"
          arrow
        >
          <template #trigger>
            <div
              :content="`${$t('monitoring.targetProduction')} - ${$t('monitoring.maxPerfProduction')}`"
              class="value font-weight-bold text-right"
              :class="performance && 'col-12 col-md-auto' || 'col-auto'"
            >
              <i
                v-if="!productionFromOrders"
                class="far fa-clipboard production-icon"
              />
              <i
                v-else
                class="icon-orders pl-1 production-icon"
                style="font-size: 11px"
              />
              {{
                production.planned ? addSpaces(Math.floor(production.planned)) : 0 }}
              <span
                class="unit"
              >{{ production.unit }}</span>
            </div>
          </template>
          <div>
            {{ $t('monitoring.targetProduction') }} -
            {{ productionFromOrders ? $t('monitoring.plannedFromOrders') : $t('monitoring.maxPerfProduction') }}
          </div>
        </Tippy>
      </div>
    </div>
    <ProductionChart
      v-bind="production"
      style="height: 20px;"
    />
  </div>
</template>

<script>
import ProductionChart from '@/components/charts/production/ProductionChart';
import calcDuration from '@/utils/calcDuration';
import { addSpaces } from '@core/utils/numbers';
import { mapGetters } from 'vuex';

export default {
  props: {
    desc: {
      type: Boolean,
      default: true,
    },
    mobile: Boolean,
    production: {
      type: Object,
      default: () => ({}),
    },
    performance: Object,
  },
  filters: {
    round(v) {
      if (!v) return '0';
      if (v < 10) {
        return Math.round(v * 100) / 100;
      }
      return addSpaces(Math.round(v));
    },
  },
  components: {
    ProductionChart,
  },
  computed: {
    ...mapGetters([
      'simpleCurrentScopeText',
      'performanceType',
      'productionFromOrders',
      'splitByOrder',
    ]),
    shortPerformanceMessage() {
      const time = calcDuration(1, this.performance.shortPerformanceDurationInSeconds + 1);
      return this.$t('monitoring.performance.short', [time]);
    },
  },
  methods: {
    addSpaces,
    calcDuration,
  },
};
</script>

<style scoped>

  .production-icon {
    font-size: 0.75rem;
    padding-right: 3px;
  }

  .value {
    padding: 1px 4px;
    box-shadow: 0 1px 2px #eee;
    border-radius: 5px;
    font-size: 0.8rem;
    margin-left: 3px;
  }

  .unit {
    font-weight: normal;
  }
</style>
