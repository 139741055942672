export default {
  'orders': 'Các đơn hàng',
  'order': 'Đơn hàng',
  'import': 'Nhập đơn hàng',
  'completedOrders': 'Các đơn hàng đã hoàn thành',
  'inProgressOrders': 'Đơn hàng đang thực hiện',
  'start': 'Bắt đầu',
  'saveAndActivate': 'Lưu và kích hoạt',
  'orderDetails': 'Chi tiết đơn hàng',
  'activate': 'Kích hoạt',
  'stop': 'Dừng',
  'end': 'Kết thúc',
  'show': 'Hiển thị đơn hàng',
  'startOrder': 'Bắt đầu đơn hàng',
  'confirmCurrentProduction': 'Xác nhận sản xuất',
  'activateOrder': 'Kích hoạt đơn hàng',
  'abortOrderActivate': 'Hủy kích hoạt',
  'selectOrder': 'Chọn đơn hàng',
  'selectLineAndSku': 'Chọn dây chuyền và SKU',
  'skuPerformance': 'Hiệu suất SKU',
  'abort': 'Hủy bỏ',
  'status': 'Tình trạng',
  'number': 'Số',
  'orderNumber': 'Số đơn hàng',
  'skuId': 'ID SKU',
  'orderId': 'ID đơn hàng',
  'item': 'Hạng mục',
  'sku': 'SKU',
  'itemNo': 'Số mục',
  'schedule': 'Kế hoạch sản xuất',
  'scheduled': 'Đã lên kế hoạch',
  'plannedProduction': 'Kế hoạch sản xuất',
  'realisation': 'Sự thực hiện',
  'expectedStart': 'Khởi đầu dự kiến',
  'expectedEnd': 'Kết thúc dự kiến',
  'toPlannedEnd': 'đã kết thúc theo kế hoạch',
  'toPlannedStart': 'đã bắt đầu theo kế hoạch',
  'afterPlannedEnd': 'sau khi kết thúc theo kế hoạch',
  'afterPlannedStart': 'sau khi bắt đầu theo kế hoạch',
  'expectedPerformance': 'Hiệu suất mong đợi',
  'description': 'Mô tả',
  'quantity': 'Sản xuất',
  'limitExceeded': 'Giới hạn đơn hàng vượt ngưỡng, vui lòng dừng hoặc hoàn thành bất kỳ đơn hàng nào đang chạy',
  'production': 'Tổng kết sản xuất',
  'wasted': 'Phế phẩm',
  'productionUpdates': 'Cập nhật sản xuất',
  'complete': 'Hoàn thành',
  'hold': 'Tạm giữ',
  'completed': 'Đã hoàn thành',
  'planned': 'Đã lên kế hoạch',
  'lineConfig': 'Cấu hình dây chuyền',
  'config': 'Cấu hình',
  'additional': 'Bổ sung',
  'machinesForOrder': 'Chọn máy cho công việc này',
  'requiredComponentsNotScanned': 'Một số chi tiết cần được quét trước',
  'firstActivate': 'Một số chi tiết được yêu cầu trước khi bắt đầu. Kích hoạt đơn hàng và quét các chi tiết trước',
  'productionStats': 'Sản xuất',
  'comment': {
    'startOrder': 'Đơn hàng sẽ bắt đầu ngay lập tức. Tất cả các gói được quét sẽ được kích hoạt',
    'completeOrder': 'Đơn hàng sẽ được hoàn thành và không thể bắt đầu lại',
    'stopOrder': 'Đơn hàng sẽ bị dừng lại và có thể được bắt đầu lại sau đó.',
    'abortOrderActivate': 'Kích hoạt sẽ bị hủy bỏ, trạng thái đơn hàng sẽ thay đổi trở lại thành \'Sẵn sàng\'',
    'activateOrder': 'Đơn hàng sẽ được chuẩn bị để bắt đầu, quy trình vẫn có thể thay đổi và một số gói sẽ cần được quét trước khi bắt đầu'
  },
  'states': {
    'inProgress': 'Đang tiến hành',
    'ready': 'Chờ đợi',
    'completed': 'Đã hoàn thành',
    'other': 'Khác',
    'activated': 'Đã kích hoạt',
    'onHold': 'Trạng thái dừng',
    'taken': 'Lấy'
  },
  'activities': {
    'addActivity': 'Lập kế hoạch hoạt động mới',
    'editActivity': 'Chỉnh sửa hoạt động',
    'changeover': 'Chuyển đổi',
    'order': 'Đơn hàng',
    'cip': 'CIP',
    'completedJob': 'Công việc đã hoàn thành',
    'inProgressJob': 'Công việc đang tiến hành',
    'stop': 'Dừng bởi người vận hành',
    'failure': 'Sai hỏng/ thất bại/ lỗi',
    'lack': 'Thiếu các thành phần và sản phẩm',
    'idle': 'Không hoạt động',
    'holdup': 'Giữ',
    'inactive': 'Không kích hoạt'
  },
  'add': 'Thêm đơn hàng',
  'create': 'Tạo đơn hàng',
  'new': 'Đơn đặt hàng mới',
  'edit': 'Chỉnh sửa đơn hàng',
  'orderedQuantity': 'Số lượng đã lên kế hoạch',
  'typeCorrectQuantity': 'Số lượng kế hoạch phải lớn hơn 0',
  'quantityUnit': 'Đơn vị số lượng',
  'type': 'Loại/ kiểu',
  'loadingItems': 'Đang tải các mục..',
  'manage': 'Quản lý các đơn hàng',
  'noActiveOrPlannedOrders': 'Không có đơn hàng đang hoạt động hoặc đã lên kế hoạch',
  'noOrders': 'Không có đơn đặt hàng',
  'orderStarted': 'Bắt đầu đơn hàng',
  'manualProductionAdded': 'Thêm sản xuất thủ công',
  'manualProductionOverwritten': 'Ghi đè sản xuất thủ công',
  'removeLastEntryFirst': 'Xóa tờ khai sản xuất cuối cùng trước khi xác nhận lại đơn hàng sản xuất',
  'reportResources': 'Các tài nguyên báo cáo',
  'selectSku': 'Chọn SKU',
  'skuDescription': 'Mô tả SKU',
  'skuNumber': 'Số SKU',
  'updateProduction': 'Cập nhật sản xuất',
  'totalProduction': 'Tổng cộng',
  'incrementalProduction': 'Tăng lên',
  'settlement': 'Định cư',
  'noProducts': 'Không có SKU',
  'productionEnteredManually': 'Sản xuất và phế phẩm được xác nhận thủ công',
  'release': 'Giải phóng',
  'take': 'Lấy',
  'finishOrder': 'Kết thúc đơn hàng',
  'product': 'Sản phẩm',
  'plannedQuantity': 'Số lượng đã lên kế hoạch',
  'orderHistory': 'Lịch sử đơn hàng',
  'defaultConfiguration': 'Cấu hình mặc định'
};
