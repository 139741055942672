<template>
  <div
    ref="selectRef"
    class="position-relative"
  >
    <BInput
      v-model="inputTextValue"
      class="custom-field-input"
      :class="{'has-error': invalid}"
      @blur="handleInputTextValueChange"
      @click="toggleOptions"
    />

    <div
      v-if="showOptions"
      class="options-dropdown"
    >
      <div
        v-for="i in 16"
        :key="`option-${i}`"
        class="dropdown-option"
        @mousedown="setTime(calcTimeValue(i * 15))"
      >
        {{ calcTime(i * 15) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import calcDuration from '@/utils/calcDuration';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    start: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      default: '',
    },
    pickerFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
    negatives: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showOptions: false,
    inputTextValue: '',
  }),
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  watch: {
    value() {
      this.updateHour();
      // this.inputTextValue = v;
    },
  },
  methods: {
    calcDuration,
    handleInputTextValueChange() {
      const newValue = this.inputTextValue.trim();
      if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]|[0-9])$/gm.test(newValue)) {
        const [hour, minute] = newValue.split(':');
        const value = moment(this.value, this.pickerFormat)
          .set('hour', +(hour.padStart(2, '0')))
          .set('minute', +(minute.padStart(2, '0')))
          .format(this.pickerFormat);
        this.innerValue = value;
        this.showOptions = false;
      } else {
        this.updateHour();
      }
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    handleOutsideClick(ev) {
      if (!this.$refs.selectRef) return;

      if (this.$refs.selectRef.contains(ev.target)) return;
      this.showOptions = false;
    },
    calcTime(duration) {
      const durationString = this.calcDuration(1, duration * 60 + 1);
      return `${moment(((this.negatives ? this.end : this.start) || this.value), this.pickerFormat)
        .add('minutes', (this.negatives ? -1 : 1) * duration)
        .format('HH:mm')} (${durationString})`;
    },
    calcTimeValue(duration) {
      return moment(((this.negatives ? this.end : this.start) || this.value), this.pickerFormat)
        .add('minutes', (this.negatives ? -1 : 1) * duration)
        .format(this.pickerFormat);
    },
    setTime(time) {
      this.innerValue = time;
      this.showOptions = false;
    },
    updateHour() {
      this.inputTextValue = moment(this.value, this.pickerFormat)
        .format('HH:mm');
    },
  },
  created() {
    this.updateHour();
    window.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleOutsideClick);
  },
};
</script>

<style lang="scss" scoped>
.custom-field-input {
  cursor: pointer;
  background-color: #fff;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  position: relative;
  width: 100%;
  height: 42px;
  min-height: 42px;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  font-size: 14px;
  z-index: 0;

  &.has-error {
    border-color: #ff4500;
  }
}

.options-dropdown {
  position: absolute;
  top: 42px;
  right: 0;
  height: 160px;
  overflow-y: auto;
  width: 150%;
  z-index: 50;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 4px 0;
}

.dropdown-option {
  background-color: white;
  padding: 4px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  &:hover {
    background-color: #ECECEC;
  }
}
</style>
