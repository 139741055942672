<template>
  <div class="row justify-content-center mb-2 mx-0">
    <div :class="!nested ? 'col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 px-2' : 'col-12'">
      <div class="mb-1 px-0">
        <VerticalOEEs
          v-bind="oees"
          size="xs"
          :target="plant ? plant.targetOees : null"
          style="height: 80px"
        />
      </div>
      <ProductionWithNumbers
        v-if="production.isUnitConversionComplete"
        :production="production"
        :production-from-orders="productionFromOrders"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductionWithNumbers from '@/components/charts/production/ProductionWithNumbers';
import VerticalOEEs from '../charts/oees/VerticalOEEs';

export default {
  props: {
    nested: Boolean,
    plantData: Object,
  },
  components: {
    VerticalOEEs,
    ProductionWithNumbers,
  },
  filters: {
    round(v) {
      if (!v) return v;
      return Math.round(v);
    },
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('plant', ['displayEnergy', 'productionFromOrders']),
    ...mapGetters('element', ['element']),
    plant() {
      return this.element(this.plantId) || this.plantData;
    },
    production() {
      return (this.plant.production)
        ? this.plant.production : {};
    },
    oees() {
      return (this.plant && this.plant.oees)
        ? this.plant.oees : {};
    },
    energyUsage() {
      return this.plant.energy || {};
    },
  },
};
</script>

<style scoped>
.current-power {
  margin: auto;
}
.comment {
  font-size: 12px;
}
.arrow {
  margin: 0;
  padding: 0;
  height: 25px;
  margin-top: -10px;
}
.production-info {
  font-size: 12px;
}
</style>
