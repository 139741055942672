import store from '@/store';
import { PACKOS_ELEMENT_ACCESS, PACKOS_PLANT_ADMIN } from '@core/dict/permissions';
import { error } from '@core/utils/logger';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

import Dashboard from '@/layout/Dashboard';
import Home from '@/layout/Home';
import PageNotFound from '@/layout/PageNotFound';
import Plant from '@/layout/Plant';
import OidcCallback from '@/pages/auth/OidcCallback';

import routes from './routes';

const World = () => import(/* webpackChunkName: "world" */ '@/pages/World');
const PlantMap = () => import(/* webpackChunkName: "monitoring" */ '@/pages/plant/PlantMap');
const Andon = () => import(/* webpackChunkName: "monitoring" */ '@/pages/Andon');
const AndonV2 = () => import(/* webpackChunkName: "monitoring" */ '@/pages/AndonV2');

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: OidcCallback,
    },
    { path: '/', component: Home },
    {
      path: '',
      component: Dashboard,
      children: [
        { path: 'world', name: 'world', component: World },
        {
          path: '/:plantId',
          component: Plant,
          children: routes,
          props: true,
        },
      ],
    },
    {
      path: '/:plantId',
      component: Plant,
      children: [
        {
          path: 'map',
          component: PlantMap,
        },
        {
          path: 'andon/:lineId',
          component: Andon,
          props: true,
        },
        {
          path: 'andon2/:lineId',
          component: AndonV2,
          props: true,
        },
      ],
      props: true,
      meta: {
        permittedFor: [PACKOS_ELEMENT_ACCESS],
      },
    },
    { path: '*', component: PageNotFound },
  ],
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

// save to store page loading state
router.beforeEach((to, from, next) => {
  store.commit('pageLoading', true);
  next();
});

// check for permissions
router.beforeEach((to, from, next) => {
  if (window.umami) {
    const { plantId } = store.getters;
    const user = store.getters['core/user'];

    window.umami.track(p => ({
      ...p,
      url: to.path,
      referrer: user?.name,
      data: {
        userId: user?.id,
        plantId,
      },
    }));
  }

  if (!to.meta || !to.meta.permittedFor) {
    next();
    return;
  }

  const timeout = setTimeout(() => {
    store.commit('pageLoading', true);
  }, 200);

  // wait for permissions being downloaded
  store.getters['core/permissionsPromise']
    .then(() => {
      clearTimeout(timeout);
      const accessToRoute = store.getters['core/can'](PACKOS_PLANT_ADMIN, to.params?.plantId)
        || to.meta.permittedFor.some(rt => store.getters['core/canAnyElementInPlant'](rt, to.params?.plantId));

      if (accessToRoute) {
        next();
      } else {
        error(`No access to route ${to.fullPath}!`);
        next('/world');
      }
    });
});

router.afterEach(() => {
  store.commit('pageLoading', false);
});

export default router;
