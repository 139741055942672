import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
})
  .get({
    action: 'getTenants',
    property: 'tenants',
    path: '/v2/tenants',
  })
  .getStore();

export default store;
