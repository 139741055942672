export default (notification, $router) => {
  const { data, plantCode, templateName } = notification;

  if (templateName.toLowerCase() === 'AvailabilityInsight'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${data.elementId}/worklogs`,
        query: {
          start: notification.created - 1800,
          end: notification.created + data.duration + 1800,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'OrderWastedThresholdExceeded'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${data.lineId}/machines`,
        query: {
          orderNumber: data.orderNumber,
          created: notification.created,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'OrderDelayed'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${data.lineId}/machines`,
        query: {
          orderNumber: data.orderNumber,
          created: notification.created,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'OrderProducedLessThanOrdered'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${notification.elementId}/machines`,
        query: {
          orderNumber: data.orderNumber,
          created: notification.created,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'OrderRequiredProductionDone'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${data.lineId}/machines`,
        query: {
          orderNumber: data.orderNumber,
          created: notification.created,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'OrderStatusChange'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${data.lineId}/machines`,
        query: {
          orderNumber: data.orderNumber,
          created: notification.created,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'ShortPerformanceBelowDesign'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/element/${data.elementId}/params`,
        query: {
          start: notification.created - 1800,
          end: notification.created + data.duration + 1800,
          signalName: 'ShortPerformance',
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'ShortPerformanceBelowValidated'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/element/${data.elementId}/params`,
        query: {
          start: notification.created - 1800,
          end: notification.created + data.duration + 1800,
          signalName: 'ShortPerformance',
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'PerformanceThreshold'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/element/${data.elementId}/params`,
        query: {
          start: notification.created - 1800,
          end: notification.created + data.duration + 1800,
          signalName: data.signalName,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'ThresholdExceeded'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/element/${data.elementId}/params`,
        query: {
          start: notification.created - 1800,
          end: notification.created + data.duration + 1800,
          signalName: data.signalName,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'NewDowntime'.toLowerCase()) {
    return () => {
      $router.push({
        path: `/${plantCode}/dashboard/line/${data.lineId}/worklogs/${data.elementId}`,
        query: {
          start: notification.created - 1800,
          end: notification.created + data.duration + 1800,
          worklogStartDate: data.worklogStartDate,
        },
      });
    };
  }
  if (templateName.toLowerCase() === 'OEEThreshold'.toLowerCase()) {
    return () => {
      $router.push(`/${plantCode}/dashboard/line/${data.elementId}/machines`);
    };
  }
  if (templateName.toLowerCase() === 'ConnectionLost'.toLowerCase()) {
    return () => {
      $router.push(`/${plantCode}/dashboard/line/${data.elementId}/machines`);
    };
  }
  if (templateName.toLowerCase() === 'IgnoredCounter'.toLowerCase()) {
    return () => {
      $router.push(`/${plantCode}/dashboard/line/${data.lineId}/machines`);
    };
  }
  if (templateName.toLowerCase() === 'OEELossInfo'.toLowerCase()
      || templateName.toLowerCase() === 'OEEVeryLowPerformance'.toLowerCase()) {
    return () => {
      // eslint-disable-next-line max-len
      $router.push(`/${plantCode}/reports/oee-losses?startDate=${data.shiftStart}&endDate=${data.shiftEnd}&elementId=${data.lineId}`);
    };
  }
  return null;
};
