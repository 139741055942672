export default {
  activities: 'กิจกรรม',
  activityType: 'ประเภทกิจกรรม',
  addActivity: 'วางแผนกิจกรรมใหม่',
  add: 'เพิ่มกิจกรรม',
  edit: 'แก้ไขกิจกรรม',
  addChangeover: 'เพิ่มเวลาหยุดแต่งตัว',
  step: 'ขั้นตอน',
  order: 'คำสั่งซื้อ',
  general: 'ทั่วไป',
  quantity: 'ปริมาณ',
  dates: 'วันที่',
  configuration: 'การกำหนดค่า',
  plannedStart: 'เริ่มต้นตามแผน',
  plannedEnd: 'สิ้นสุดตามแผน',
  cleaning: 'การทำความสะอาด',
  changeover: 'เปลี่ยนงาน',
  activityId: 'รหัสกิจกรรม',
  activity: 'กิจกรรม',
  activityStart: 'เริ่มกิจกรรม',
  toStart: 'เพื่อเริ่ม',
  afterStart: 'หลังเริ่ม',
  activityAlreadyExists: 'รหัสกิจกรรมมีอยู่แล้ว',
  activityIdIsTooLong: 'รหัสกิจกรรมยาวเกินไป',
  changeoverDuration: 'ระยะเวลาเปลี่ยนงาน (นาที)',
  manage: 'จัดการกิจกรรม',
  changeoverType: 'ประเภทการเปลี่ยนงาน',
  editExisting: 'แก้ไขที่มีอยู่',
  pickALine: 'เลือกเส้นผ่าน',
  typeEffort: 'ประเภทความพยายาม',
  showNotPlanned: 'แสดงกิจกรรมที่ไม่ได้วางแผน',
  summary: {
    summary: 'สรุป', orderTotal: 'ผลรวมคำสั่งซื้อ', lineTotal: 'ผลรวมเส้นผ่าน', inLineUnit: 'ในหน่วยของเส้นผ่าน', inOrderUnit: 'ในหน่วยของคำสั่งซื้อ', goodCount: 'จำนวนผลิตภัณฑ์ที่ดี', wasteCount: 'จำนวนวัตถุดิบที่สูญเสีย', counterTotal: 'ผลรวมเคาน์เตอร์', performanceGuard: 'การป้องกันผลิตภาพ', stateGuard: 'การป้องกันสถานะ', performanceGuardTippy: 'เกินค่าเกณฑ์ผลิตภาพ', missingPackingStructure: 'โครงสร้างการบรรจุสินค้าที่หายไป', manualReconciliation: 'การตรวจสอบและปรับปรุงด้วยวิธีทำงานด้วยมือ',
  },
  list: { order: 'คำสั่งซื้อ', cleaning: 'การทำความสะอาด', changeover: 'การเปลี่ยนเครื่องจักร' },
  activityAdded: 'เพิ่มกิจกรรม',
  noActivities: 'ไม่มีกิจกรรม',
  status: {
    released: 'ปล่อย', draft: 'ร่าง', started: 'เริ่มต้น', suspended: 'ระงับ', completed: 'เสร็จสิ้น', activated: 'เปิดใช้งาน', scheduled: 'ตารางงาน', notPlanned: 'ไม่ได้วางแผน',
  },
  schedule: {
    tableView: 'ตาราง', axisView: 'ไทม์ไลน์', full: 'เต็ม', collapsed: 'ย่อ', scheduled: 'ตารางงาน', currentHour: 'สิ้นสุดของชั่วโมงปัจจุบัน', previousHour: 'ชั่วโมงก่อนหน้า', previousShift: 'กะก่อนหน้า', currentShift: 'กะปัจจุบัน',
  },
  resources: {
    production: 'การผลิต', waste: 'ของเสีย', manhours: 'ชั่วโมงคน', timeScope: 'ขอบเขตเวลา', workers: 'คนงาน', worker: 'คนงาน', history: 'ประวัติ', noEntries: 'ไม่มีรายการ', hours: 'ชั่วโมง', minutes: 'นาที',
  },
  actions: {
    finishChangeover: 'เสร็จสิ้นและเริ่มคำสั่งซื้อ', abortChangeover: 'ยกเลิกการเปลี่ยนเครื่องจักร', productionReconciliation: 'การปรับปรุงการผลิต', finishOrder: 'เสร็จสิ้นคำสั่งซื้อ',
  },
  validations: { orderNotFound: 'ไม่พบคำสั่งซื้อที่เกี่ยวข้อง', externalIdError: "รหัสสามารถประกอบด้วยตัวเลข ตัวอักษร อักขระ '-' '/' และ '_' เท่านั้น และต้องเริ่มต้นด้วยตัวเลขหรือตัวอักษรเท่านั้น" },
  deleteConfirmMessage: 'คุณแน่ใจว่าต้องการลบกิจกรรมนี้?',
  hasValidationErrors: 'มีข้อผิดพลาดในการตรวจสอบความถูกต้อง',
  noLine: 'ไม่มีเส้น',
  editOrder: 'แก้ไขคำสั่งซื้อ',
  editChangeover: 'แก้ไขการเปลี่ยนเครื่องจักร',
  cannotCreateOrder: 'ไม่สามารถสร้างคำสั่งซื้อได้',
  orderExists: 'มีคำสั่งซื้อที่มีรหัสกิจกรรมที่ระบุอยู่แล้ว',
  endOfLastShift: 'สิ้นสุดของกะก่อนหน้า',
  endOfLastHour: 'สิ้นสุดของชั่วโมงก่อนหน้า',
  productionHistory: 'ประวัติการผลิต',
  manhoursHistory: 'ประวัติชั่วโมงคน',
  changeoverDurationTitle: 'ระยะเวลาการเปลี่ยนเครื่องจักร',
  expectedEffort: 'ความพยายามที่คาดหวัง (ชม.)',
  expectedChangeoverEffort: 'ความพยายามที่คาดหวังในการเปลี่ยนเครื่องจักร (ชม.)',
  typeCorrectExpectedEffort: 'ความพยายามที่คาดหวังต้องไม่เป็นค่าลบ',
  typeCorrectValue: 'ค่าต้องไม่เป็นค่าลบ',
  typeChangeoverDurationIfChangeoverExpectedEffortGiven: 'จำเป็นต้องระบุระยะเวลาที่คาดหวังในการเปลี่ยนเครื่องจักรเมื่อมีความพยายามที่คาดหวังในการเปลี่ยนเครื่องจักร',
  editMode: 'โหมดแก้ไข',
  validationErrors: {
    skuError: 'สินค้าหายไปหรือตั้งค่าไม่ถูกต้อง', flowIdError: 'ไม่พบการตั้งค่ากระแส', endDateNotValid: 'วันที่สิ้นสุดที่วางแผนไม่ถูกต้อง', lineError: 'ไม่พบการตั้งค่าเส้น', orderedQuantityError: 'จำนวนคำสั่งซื้อหายไป', orderedQuantityUnitError: 'หน่วยจำนวนคำสั่งซื้อหายไปหรือตั้งค่าไม่ถูกต้อง',
  },
};
