<template>
  <div>
    <Error
      v-for="error in validationErrors"
      :key="`code_${error.code}`"
      :message="error.description"
    />
  </div>
</template>

<script>
export default {
  props: {
    validationErrors: {
      type: Array,
      default: () => [],
    },
  },

};
</script>
