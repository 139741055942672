import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import lang from '@/lang';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    historyScope: {
      startDate: -1,
      endDate: -1,
    },
    signalsHistory: {},
    worklogHistory: {},
    orderHistory: {},
  },
})
  .get({
    action: 'getSignalsHistory',
    path: ({ plantId, startDate, endDate }) =>
      `/v2/plant/${plantId}/signals/history/${startDate}/${endDate}`,
  })
  .getStore();

const clearHistory = (s, { startDate, endDate }) => {
  s.historyScope.startDate = startDate;
  s.historyScope.endDate = endDate;
  s.signalsHistory = {};
  s.worklogHistory = {};
  s.orderHistory = {};
};

store.mutations.clearSignalsHistory = s => {
  clearHistory(s, { startDate: -1, endDate: -1 });
};

store.mutations.addSignalHistory = (s, {
  id, startDate, endDate, values,
}) => {
  if (s.historyScope.startDate !== startDate || s.historyScope.endDate !== endDate) {
    clearHistory(s, { startDate, endDate });
  }
  Vue.set(s.signalsHistory, id, values);
};

store.mutations.addWorklogHistory = (s, {
  id, startDate, endDate, values,
}) => {
  if (s.historyScope.startDate !== startDate || s.historyScope.endDate !== endDate) {
    clearHistory(s, { startDate, endDate });
  }
  Vue.set(s.worklogHistory, id, values);
};

store.mutations.addOrderHistory = (s, {
  id, startDate, endDate, values,
}) => {
  if (s.historyScope.startDate !== startDate || s.historyScope.endDate !== endDate) {
    clearHistory(s, { startDate, endDate });
  }
  Vue.set(s.orderHistory, id, values);
};

store.getters = {
  signalGroups: () => [
    {
      name: 'counters',
      display: lang.t('monitoring.machine.counters'),
      icon: 'ion ion-ios-speedometer',
      map: ['COUNTER', 'WASTE_COUNTER', 'CYCLES', 'WORKING_HOURS'],
    },
    {
      name: 'performance',
      display: lang.t('oee.performance'),
      icon: 'ion ion-ios-trending-up',
      map: ['PERFORMANCE', 'EXPECTED_PERFORMANCE', 'SHORT_PERFORMANCE'],
    },
    {
      name: 'signals',
      display: lang.t('processParams.signals'),
      icon: 'ion ion-ios-pulse',
      map: ['SIGNAL'],
    },
    {
      name: 'statuses',
      display: lang.t('monitoring.machine.alarmsHoldups'),
      icon: 'ion ion-ios-notifications-outline',
      map: ['ALARM', 'STATE', 'HOLDUP', 'HOLDUP_DETAIL', 'ALARM_DETAIL'],
    },
    {
      name: 'others',
      display: lang.t('monitoring.machine.others'),
      icon: 'ion ion-ios-help-circle-outline',
      map: [],
    },
  ],
};

export default store;
