<template>
  <div>
    <form @submit.prevent="submit">
      <div class="font-weight-bold">
        {{ $t('activities.resources.workers') }}
      </div>

      <div class="workers-header d-flex">
        <div style="width: 25px" />
        <div class="flex-grow-1" />
        <div
          class="text-lowercase hours time-column pr-1"
          style="margin-right: 10px"
        >
          {{ $t('time.hours') }}
        </div>
        <div class="text-lowercase minutes time-column pr-1">
          {{ $t('time.minutes') }}
        </div>
      </div>
      <div class="d-flex align-items-center py-2">
        <div
          style="width: 25px"
          class="text-center text-secondary"
        >
          <i class="fas fa-user" />
        </div>
        <div class="flex-grow-1 px-2">
          <BFormInput
            ref="workerNo"
            v-model="workerNo"
            class="text-right"
            style="width: 50px"
            :formatter="twoLetterNumber"
            @click="clickWorkerNo"
          />
        </div>
        <div class="text-lowercase time-column">
          <BFormInput
            v-model="globalHour"
            class="text-right"
            :formatter="threeLetterNumber"
            @click.native="$event.target.select()"
          />
        </div>
        <div style="width: 10px">
          <span class="hour-divider">
            :
          </span>
        </div>
        <div class="text-lowercase time-column">
          <BFormInput
            v-model="globalMinute"
            class="text-right"
            :formatter="twoLetterNumber"
            @click.native="$event.target.select()"
          />
        </div>
      </div>
      <hr class="mt-1 mb-0">

      <div>
        <WorkerRow
          v-for="(worker, index) in rows"
          :key="worker.id"
          :index="index + 1"
          class="py-2"
          :name.sync="rows[index].name"
          :duration.sync="rows[index].duration"
          @remove="remove(worker.id)"
        />
      </div>
      <div class="mt-2 d-flex justify-content-between">
        <button
          class="btn btn-primary btn-sm icon-btn"
          type="button"
          @click="incrementWorkerNumber"
        >
          <i class="fas fa-plus" />
        </button>
        <div
          class="d-flex align-items-center"
          style="color: #999"
        >
          <div>
            {{ $t('general.total') }}:
          </div>
          <div class="time-column text-right pr-2">
            {{ Math.floor(totalManhours / 60) }}
          </div>
          <div
            style="width: 20px"
            class="text-right"
          >
            :
          </div>
          <div class="time-column text-right pr-2">
            {{ totalManhours - Math.floor(totalManhours / 60) * 60 }}
          </div>
        </div>
      </div>
      <hr>
      <div
        v-if="error"
        class="text-center pb-3"
      >
        <Error :message="error" />
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-50 px-2">
          <button
            class="btn btn-block btn-secondary btn-sm"
            type="button"
            @click="$emit('cancel')"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="w-50 px-2">
          <button
            class="btn btn-block btn-primary btn-sm"
            type="submit"
            :disabled="pending"
          >
            {{ actionName || $t('general.confirm') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { manualResourceType } from '@/utils/dictionary';
import {
  twoLetterNumber,
  threeLetterNumber,
  minutesToDuration,
  durationToMinutes,
} from '@/utils/time';
import WorkerRow from './WorkerRow';

export default {
  props: {
    duration: Number,
    activityId: String,
    endDate: String,
    incremental: Boolean,
    actionName: String,
    entries: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    rows: [],
    rowId: 1,
    oldDuration: 0,
    oldGlobalTime: 0,
    pending: false,
    error: '',
    globalHour: '0',
    globalMinute: '0',
    workerNo: '1',
  }),
  components: {
    WorkerRow,
  },
  computed: {
    ...mapGetters(['plantId']),
    maxTime() {
      return minutesToDuration(this.duration);
    },
    globalTime() {
      const hour = parseInt(this.globalHour, 10);
      const minute = parseInt(this.globalMinute, 10);

      if (Number.isNaN(hour) || Number.isNaN(minute)) return null;
      return hour * 60 + minute;
    },
    totalManhours() {
      return this.rows
        .reduce((acc, curr) => acc + durationToMinutes(curr.duration), 0);
    },
    pastEntries() {
      return this.getPastWorkerEntries(this.entries);
    },
  },
  watch: {
    duration(v) {
      if (this.oldDuration === this.globalTime || v < this.globalTime) {
        this.globalHour = Math.floor(this.duration / 60);
        this.globalMinute = this.duration - (this.globalHour * 60);
      }
      this.oldDuration = v;
    },
    globalTime(v) {
      if (v === null) return;
      const strTime = minutesToDuration(v);

      this.rows.forEach(r => {
        if (durationToMinutes(r.duration) === this.oldGlobalTime) {
          this.$set(r, 'duration', strTime);
        }
      });
      this.oldGlobalTime = v;
    },
    workerNo(num) {
      const intNum = parseInt(num, 10);
      if (Number.isNaN(intNum)) return;

      if (this.rows.length > intNum) {
        this.rows = this.rows.slice(0, intNum);
      }

      while (this.rows.length < intNum) {
        this.add();
      }
    },
    totalManhours(v) {
      this.$emit('totalManhours', v);
    },
    incremental(v) {
      const sums = this.pastEntries;

      if (!v) {
        this.fillWithPastEntries();
      } else {
        Object.entries(sums).forEach(([key, value]) => {
          const row = this.rows.find(x => x.name === key);
          if (row) {
            row.duration = minutesToDuration(Math.max(0, durationToMinutes(row.duration) - value));
          }
        });

        this.rows = this.rows.filter(x => durationToMinutes(x.duration) > 0);
        this.workerNo = this.rows.length;
      }
    },
  },
  methods: {
    ...mapActions(['addManualManhours']),
    twoLetterNumber,
    threeLetterNumber,
    minutesToDuration,
    incrementWorkerNumber() {
      this.workerNo = parseInt(this.workerNo, 10) + 1;
    },
    getPastWorkerEntries(entries) {
      return entries
        .filter(x => x.isActive && x.type === manualResourceType.humanResources)
        .flatMap(x => x.manhours)
        .reduce((acc, curr) => {
          acc[curr.workerCode] = (acc[curr.workerCode] || 0) + curr.minutes;
          return acc;
        }, {});
    },
    fillWithPastEntries(entries) {
      Object.entries(entries || this.pastEntries).forEach(([key, value]) => {
        const row = this.rows.find(x => x.name === key);
        if (!row) {
          this.rows.push({
            id: this.rowId,
            name: key,
            duration: minutesToDuration(value || 0),
          });
          this.rowId += 1;
        } else {
          row.duration = minutesToDuration(Math.max(value, durationToMinutes(row.duration)));
        }
      });
      this.workerNo = this.rows.length;
    },
    clickWorkerNo() {
      this.$refs.workerNo.focus();
      this.$refs.workerNo.select();
    },
    submit() {
      this.error = null;
      this.pending = true;

      this.addManualManhours({
        params: {
          plantId: this.plantId,
          activityId: this.activityId,
        },
        data: {
          to: moment(this.endDate).toISOString(),
          incremental: this.incremental,
          workerParticipation: this.rows.map(r => ({
            minutes: durationToMinutes(r.duration),
            workerCode: r.name,
          })),
        },
      })
        .then(({ data }) => {
          this.rows = [];
          this.$emit('refresh', data);
          this.fillWithPastEntries(this.getPastWorkerEntries(data));
        })
        .catch(({ response: { data } }) => {
          this.error = data?.detail || this.$t('error.error');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove(id) {
      this.rows = this.rows.filter(x => x.id !== id);
      this.workerNo -= 1;
    },
    add() {
      this.rows.push({
        id: this.rowId,
        name: '',
        duration: minutesToDuration(this.globalTime || 0),
      });
      this.rowId += 1;
    },
    ensureOneEmpty() {
      const empty = this.rows.length === 0;
      if (empty) {
        this.add();
      }
    },
  },
  created() {
    this.oldDuration = this.duration;
    this.globalHour = Math.floor(this.duration / 60);
    this.globalMinute = this.duration - (this.globalHour * 60);
    this.fillWithPastEntries();
    this.ensureOneEmpty();
  },
};
</script>

<style lang="scss" scoped>
@import "./workers.scss";

.workers-header {
  font-size: 12px;
  text-align: right;
  color: #888;
  margin-bottom: -7px;
}
</style>
