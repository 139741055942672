import loopRequest from '@/mixins/loopRequest';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    elementIds: {
      type: Array,
      required: true,
    },
    startDate: Number,
    endDate: Number,
    realTime: {
      type: Boolean,
      default: true,
    },
    downtimesOnly: Boolean,
    minorStops: Boolean,
    minDuration: Number,
    vuexKey: {
      type: String,
      default: 'elementLogs',
    },
    passive: Boolean,
  },
  data: () => ({
    prevStartDate: null,
    lastRequest: null,
    lastElementIds: [],
    firstRequest: true,
  }),
  mixins: [loopRequest('loop', 8000)],
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('work', ['bufferedWorkLog']),
    date() {
      return { start: this.startDate, end: this.endDate };
    },
  },
  watch: {
    elementIds(ids) {
      const diff = ids.filter(id => this.lastElementIds.findIndex(lid => lid === id) === -1);

      if (diff.length > 0) {
        this.lastRequest = null;
        this.lastElementIds = ids;
        this.request(true);
      }
    },
    date() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (this.prevStartDate !== this.startDate) {
          this.lastRequest = null;
          this.prevStartDate = this.startDate;
        }
        this.request(true);
      }, 300);
    },
    minorStops() {
      this.request(true);
    },
    minDuration() {
      this.request(true);
    },
  },
  methods: {
    ...mapActions('work', ['getWorklogs']),
    loop() {
      if (this.passive) return;
      this.request();
    },
    request(force) {
      const start = this.startDate;
      const end = this.endDate;

      if (!start && !end) return Promise.resolve();
      if (!this.firstRequest && !force && !this.realTime) return Promise.resolve();
      if (Math.abs(start - end) < 1) return Promise.resolve();
      this.firstRequest = false;

      return this.getWorklogs({
        params: {
          plantId: this.plantId,
          startDate: start,
          endDate: end,
          query: {
            id: this.elementIds,
            downtimesOnly: this.downtimesOnly,
            minorStops: this.minorStops,
            minDuration: this.minDuration,
          },
          requestStartDate: this.startDate,
        },
      })
        .then(({ data, config }) => {
          // Out-of-date request
          if (config.params.requestStartDate !== this.startDate) return;

          this.$emit('response', data);

          data.forEach(wl => {
            this.$store.commit('work/updateWorklogList', {
              ...wl,
              key: this.vuexKey,
            });
          });
          this.lastRequest = config.params.endDate;
        });
    },
    update({ log, elementId }) {
      const buffered = this.bufferedWorkLog(elementId, this.vuexKey).slice();
      if (!buffered || buffered.length === 0) return { log, elementId };
      if (!log || log.length === 0) {
        return { log: buffered, elementId };
      }

      // TODO: Remove disconnected ?
      const merged = buffered.concat(log)
        .sort((a, b) => a.startDate - b.startDate);
      if (merged.length === 0) return [];

      const lastWl = merged[merged.length - 1];
      if (!lastWl.endDate) {
        merged[merged.length - 1] = { ...lastWl, endDate: this.$now };
      }
      return merged;
    },
  },
  created() {
    this.prevStartDate = this.startDate;
    this.lastElementIds = this.elementIds.slice();
    this.firstRequest = true;

    if (this.passive) {
      this.request();
    }
  },
  render() { return true; },
};
