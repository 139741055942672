export default {
  moveHereToGroup: 'Move column here to group',
  moveHereToHide: 'Move column here to hide',
  sEmptyTable: 'No data available in table',
  sInfoFiltered: '(filtered from _MAX_ total entries)',
  sLoadingRecords: 'Loading...',
  sProcessing: 'Processing...',
  sSearch: 'Search:',
  sZeroRecords: 'No matching records found',
  downloadAsXls: 'Download as xls',
  oPaginate: {
    sFirst: 'First',
    sLast: 'Last',
    sNext: 'Next',
    sPrevious: 'Previous',
  },
  oAria: {
    sSortAscending: ': activate to sort column ascending',
    sSortDescending: ': activate to sort column descending',
  },
};
