<template>
  <div
    class="mt-3 calendar-bar"
  >
    <BFormGroup :label="$t('calendar.period')">
      <BFormSelect
        v-model="period"
        :options="periods"
        size="sm"
        style="width: 50%; min-width: 130px;"
        @change="setDateRange"
      />
    </BFormGroup>

    <BOverlay
      :show="disableDateSelect"
      spinner-type="none"
      opacity="0.5"
    >
      <DateTimePicker
        :key="calendarKey"
        v-model="localDate"
        format="YYYY-MM-DD HH:mm"
        :locale="locale"
        :color="color"
        range
        label=""
        inline
        no-label
        only-date
        no-header
        no-button
        overlay
        :first-day-of-week="weekStart === 0 ? 7 : weekStart"
        no-shortcuts
        :months-only="period === 'month'"
        @input="setDateRange"
      />
    </BOverlay>

    <hr>

    <BOverlay
      :show="disableFilters"
      spinner-type="none"
      opacity="0.5"
    >
      <CalendarFilters
        v-if="accessibleFilter && accessibleFilter.length"
        :init-filters="initFilters"
        :filters="accessibleFilter"
        @filtersUpdate="$emit('filtersUpdate', $event)"
      />
    </BOverlay>
  </div>
</template>

<script>
import DateTimePicker from '@ilabo/vue-ctk-date-time-picker';
import moment from 'moment';
import { mapGetters } from 'vuex';
import CalendarFilters from './CalendarFilters';

export default {
  props: {
    dates: Object,
    filters: Array,
    color: String,
    initFilters: Object,
    customPeriods: {
      type: Array,
      default: () => [],
    },
    disableFilters: Boolean,
    disableDateSelect: Boolean,
  },
  data: () => ({
    localDate: '',
    period: '',
    calendarKey: 1,
    accessibleFilter: {},
  }),
  components: {
    DateTimePicker,
    CalendarFilters,
  },
  computed: {
    ...mapGetters('core', ['locale', 'weekStart']),
    ...mapGetters(['plantId']),
    periods() {
      return [
        { text: this.$t('time.day'), value: 'day' },
        { text: this.$t('time.week'), value: 'week' },
        { text: this.$t('time.month'), value: 'month' },
        { text: this.$t('time.custom'), value: 'custom' },
        ...this.customPeriods,
      ];
    },
  },
  watch: {
    period() {
      if (this.period === 'custom') {
        this.localDate.start = moment(new Date()).format();
        this.localDate.end = moment(new Date()).format();
      }
    },
  },
  methods: {
    setDateRange() {
      if (this.period !== 'custom') {
        const startRange = moment(this.localDate.start).startOf(this.period).format();
        const endRange = moment(this.localDate.start).endOf(this.period).format();

        this.localDate = { start: startRange, end: endRange };
      }

      if (this.localDate.start && this.localDate.end) {
        this.$emit('update', {
          startDate: this.localDate.start,
          endDate: this.localDate.end,
          scope: this.period,
        });
      }
    },
  },
  created() {
    this.period = this.dates.scope;
    this.localDate = { start: this.dates?.startDate, end: this.dates?.endDate };
    this.accessibleFilter = this.filters;
    this.accessibleFilter[1].options = [...this.filters[1].options];
  },
  mounted() {
    // to force locale refresh
    this.calendarKey += new Date().getTime();
  },
};
</script>

<style lang="scss" scoped>
  .date-time-picker :deep(.custom-button-content) {
    color: black !important;
  }

  .calendar-bar {
    min-height: 100vh
  }
</style>
