<template>
  <div>
    <div class="header">
      <i class="fas fa-clock pr-2" />
      {{ $t('general.audit') }}
    </div>
    <hr>

    <div class="d-flex align-items-center">
      <i
        class="fas fa-calendar text-secondary px-2"
        style="font-size: 20px"
      />
      <DatePicker
        :start-date="from"
        :end-date="to"
        style="max-width: 300px"
        @dates="updateTimeRange"
      />
    </div>
    <hr>
    <Loader v-if="pending" />
    <div
      v-if="log.length === 0"
      class="text-center"
    >
      No data in this time period
    </div>

    <div
      v-for="entry in log"
      :key="entry.id"
      class="entry d-flex align-items-center"
    >
      <i class="fas fa-circle-user entry-icon" />
      <div>
        <div class="entry-header">
          <span class="time">
            {{ formatDate(entry.timestamp) }}
          </span> | {{ entry.eventType }}
        </div>
        <span class="font-weight-bold">
          {{ entry.issuerName || "Unknown" }}
        </span>
        <AuditLogText
          :event-type="entry.eventType"
          :body="entry.body"
          :references="entry.references"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import AuditLogText from './AuditLogText';
import DatePicker from './DatePicker';

export default {
  props: {
    activityId: String,
  },
  data: () => ({
    from: moment()
      .subtract(1, 'month')
      .startOf('day')
      .format(),
    to: moment().endOf('day').format(),
    log: [],
    pending: false,
  }),
  components: {
    DatePicker,
    AuditLogText,
  },
  methods: {
    ...mapActions(['getActivityAudit']),
    formatDate(timestamp) {
      return moment(timestamp).format('HH:mm DD MMM YYYY');
    },
    updateTimeRange(m) {
      this.from = moment(m.startDate).startOf('day').format();
      this.to = moment(m.endDate).endOf('day').format();
      this.request();
    },
    request() {
      this.pending = true;

      this.getActivityAudit({
        params: {
          from: this.from,
          to: this.to,
          query: {
            activityId: this.activityId,
            eventType: [
              'OrderInfoUpdated',
              'OrderStarted',
              'OrderActivated',
              'OrderDeactivated',
              'OrderReleased',
              'OrderSpeedUpdated',
              'OrderExpectedEffortUpdated',
              'OrderDeleted',
              'OrderFlowUpdated',
              'OrderLineUpdated',
              'OrderProductUpdated',
              'OrderCreated',
              'OrderCompleted',
              'OrderOrderedQuantityUpdated',
              'OrderPlannedExecutionUpdated',

              'ChangeoverCompleted',
              'ChangeoverCreated',
              'ChangeoverDeleted',
              'ChangeoverExpectedEffortUpdated',
              'ChangeoverInfoUpdated',
              'ChangeoverLineUpdated',
              'ChangeoverPlannedExecutionUpdated',
              'ChangeoverReleased',
              'ChangeoverStarted',
              'ChangeoverSuspended',

              'CleaningCompleted',
              'CleaningCreated',
              'CleaningDeleted',
              'CleaningExpectedEffortUpdated',
              'CleaningInfoUpdated',
              'CleaningLineUpdated',
              'CleaningPlannedExecutionUpdated',
              'CleaningReleased',
              'CleaningStarted',
            ],
          },
        },
      })
        .then(({ data }) => {
          this.log = data.map(x => {
            if (!x.issuer || !x.issuer.includes('/')) {
              return x;
            }

            const issuer = x.issuer.split('/');
            return {
              ...x,
              body: JSON.parse(x.body),
              issuerId: issuer[0],
              issuerName: issuer[1],
            };
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 26px;
  font-weight: 500;
  line-height: 26px;
  padding-top: 5px;

  i {
    font-size: 23px;
  }
}

.entry {
  padding-bottom: 20px;

  .time {
    font-weight: 500;
  }

  .entry-header {
    color: #aaa;
    font-size: 13px;
    line-height: 1.1;
  }

}

.entry-icon {
  font-size: 30px;
  padding-right: 15px;
  color: #aaa;
}
</style>
