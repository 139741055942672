export default {
  'moveHereToGroup': 'Mover la columna aquí para agrupar',
  'moveHereToHide': 'Mover columna aquí para ocultar',
  'sEmptyTable': 'No hay datos disponibles en la tabla',
  'sInfoFiltered': '(filtrado de _MAX_ entradas totales)',
  'sLoadingRecords': 'Cargando…',
  'sProcessing': 'Procesando…',
  'sSearch': 'Buscar:',
  'sZeroRecords': 'No se encontraron registros coincidentes',
  'oPaginate': {'sFirst': 'Primero', 'sLast': 'Último', 'sNext': 'Próximo', 'sPrevious': 'Previo'},
  'oAria': {
    'sSortAscending': 'activar para ordenar la columna ascendente',
    'sSortDescending': 'activar para ordenar la columna descendente'
  },
  'downloadAsXls': 'Descargar a Excel'
};
