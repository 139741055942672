<template>
  <div>
    <h4 class="text-center">
      {{ sku ? sku.skuNo : $t("items.new") }}<br>
    </h4>
    <div class="mt-3 mb-4">
      <HorizontalMenu
        class="justify-content-center"
        :selected.sync="page"
        :list="pages"
        :small="true"
      />
    </div>
    <div class="mt-2">
      <div v-if="page === 'details'">
        <ItemDetails
          :sku="sku"
          :items="items"
          @saved="showSaved()"
          @created="$emit('created', $event)"
          @close="cancel"
        />
      </div>

      <div
        v-if="page === 'lines' && sku"
      >
        <ItemLineConfiguration
          :sku-id="sku.id"
          :show-import-result="true"
          :imported-line-configurations="importedLineConfigurations"
          @cancelled="cancel"
          @saved="showSaved()"
        />
      </div>

      <div v-if="page === 'packingStructure'">
        <div v-if="sku.skuGroupId">
          <div class="text-secondary">
            <i class="fas fa-object-group pr-1" />
            {{ $t('items.inheritedFromGroup') }}
          </div>
          <PackingStructure
            :id="sku.skuGroupId"
            style="opacity: 0.7"
            :overwritten="skuPackingStructure"
            :imported-structures="importedStructures"
            :read-only="true"
          />
        </div>

        <PackingStructure
          :id="sku.id"
          :imported-structures="importedStructures"
          @update="skuPackingStructure = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import PackingStructure from '@/components/item/PackingStructure';
import ItemLineConfiguration from './ItemLineConfiguration';
import ItemDetails from './ItemDetails';

export default {
  props: {
    sku: Object,
    importedStructures: Array,
    groupAssignmentResult: Object,
    importedLineConfigurations: Array,
    items: Array,
  },
  data: () => ({
    page: 'details',
    skuPackingStructure: null,
  }),
  components: {
    ItemDetails,
    HorizontalMenu,
    PackingStructure,
    ItemLineConfiguration,
  },
  computed: {
    ...mapGetters(['plantId']),
    pages() {
      const pagesList = [{
        key: 'details',
        name: this.$t('general.details'),
        icon: 'icon-sku',
      }];
      if (!this.sku) return pagesList;
      return pagesList.concat([
        {
          key: 'lines',
          name: this.$t('items.lineConfiguration'),
          icon: 'fas fa-cogs',
        },
        {
          key: 'packingStructure',
          name: this.$t('items.packingStructure'),
          icon: 'fas fa-boxes',
        },
      ]);
    },
  },
  methods: {
    showSaved() {
      this.$bvToast.toast(this.$t('import.dataSaved'), {
        title: this.$t('import.saved'),
        autoHideDelay: 3000,
        variant: 'success',
        toaster: 'b-toaster-top-right',
      });
    },
    cancel() {
      this.$emit('cancelled');
    },
  },
};
</script>

<style scoped>

  .label {
    font-weight: bold;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 4px;
    margin-right: 20px;
  }

  .form-row {
    margin: 15px 0;
  }

  .disabled {
    color: #e3dff5;
    cursor: pointer;
  }

  .anabled {
    border-bottom: 3px solid #046dbe;
    cursor: pointer;
  }
</style>
