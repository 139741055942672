<template>
  <div>
    <div
      class="row no-gutters"
      style="font-weight: 700"
    >
      <div class="col-6 text-nowrap">
        {{ $t('activities.summary.summary') }}:
      </div>
      <div class="col-2 good-text text-right text-nowrap">
        {{ $t('activities.summary.goodCount') }}
      </div>
      <div class="col-3 text-danger text-right text-nowrap">
        {{ $t('activities.summary.wasteCount') }}
      </div>
    </div>
    <hr class="mt-2">
    <CounterSummaryTable
      :fields="machineFields"
    />
    <div v-if="!summary.line">
      <StateGuardTable
        :state-guard="summary.machine.stateGuard"
        :unit="summary.machine.machineCounterUnit"
      />
    </div>
    <div v-if="lineFields.length > 0">
      <hr>
      <CounterSummaryTable
        :fields="lineFields"
      />
      <StateGuardTable
        :state-guard="summary.line.stateGuard"
        :unit="summary.line.lineCounterUnit"
      />
    </div>
    <div v-if="showTotal">
      <hr>
      <div
        v-if="accepted"
        class="row total-style"
      >
        <div class="col-6">
          {{ $t('monitoring.production') }}
        </div>
        <div class="col-2 text-right">
          {{ accepted.goodCount | round }} {{ accepted.unit }}
        </div>
        <div class="col-3 text-danger text-right">
          {{ accepted.wasteCount | round }} {{ accepted.unit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@core/utils/numbers';
import CounterSummaryTable from './CounterSummaryTable';
import StateGuardTable from './StateGuardTable';

export default {
  props: {
    summary: {
      type: Object,
      required: true,
    },
    showTotal: Boolean,
  },
  components: {
    CounterSummaryTable,
    StateGuardTable,
  },
  computed: {
    accepted() {
      if (this.summary.line) {
        return {
          ...this.summary.line.accepted,
          unit: this.summary.line.lineCounterUnit,
        };
      }
      return {
        ...this.summary.machine.accepted,
        unit: this.summary.machine.machineCounterUnit,
      };
    },
    lineMultiplier() {
      if (this.summary.machine.accepted.goodCount === 0) return 0;
      const multiplier = this.summary.line.rawCounterIncrease.goodCount / this.summary.machine.accepted.goodCount;
      return round(multiplier, 2);
    },
    machineFields() {
      if (!this.summary) return [];
      const { machine, line } = this.summary;

      return [
        {
          key: 'machineRawCounter',
          label: `${this.$t('machine')} - ${this.$t('activities.summary.counterTotal')}`,
          unit: machine.machineCounterUnit,
          goodValue: machine.rawCounterIncrease?.goodCount || 0,
          wasteValue: machine.rawCounterIncrease?.wasteCount || 0,
        },
        {
          key: 'performanceGuard',
          label: this.$t('activities.summary.performanceGuard'),
          unit: machine.machineCounterUnit,
          showSign: true,
          goodValue: -(machine.performanceGuard?.goodCount || 0),
          wasteValue: -(machine.performanceGuard?.wasteCount || 0),
          tippy: this.$t('activities.summary.performanceGuardTippy'),
        },
        ...(line ? [{
          key: 'missingPackingStructure',
          label: this.$t('activities.summary.missingPackingStructure'),
          unit: machine.machineCounterUnit,
          showSign: true,
          goodValue: -(line.missingPackingStructure?.goodCount || 0),
          wasteValue: -(line.missingPackingStructure?.wasteCount || 0),
        }] : []),
      ];
    },
    lineFields() {
      if (!this.summary || !this.summary.line) return [];
      const { line, machine } = this.summary;
      const unitConversion = this.summary.order
        ? `(1 ${machine.machineCounterUnit} = ${this.lineMultiplier} ${line.lineCounterUnit})`
        : '';

      return [
        {
          key: 'lineRawCounter',
          label: `${this.$t('line')} - ${this.$t('activities.summary.counterTotal')} ${unitConversion}`,
          unit: line.lineCounterUnit,
          goodValue: line.rawCounterIncrease?.goodCount || 0,
          wasteValue: line.rawCounterIncrease?.wasteCount || 0,
        },
        {
          key: 'manual',
          label: this.$t('activities.summary.manualReconciliation'),
          unit: line.lineCounterUnit,
          showSign: true,
          goodValue: line.manualProduction?.goodCount || 0,
          wasteValue: line.manualProduction?.wasteCount || 0,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  .total-style {
    font-weight: 700;
    font-size: 1.1em;
  }
</style>
