import { mapGetters, mapState } from 'vuex';
import calcDuration from '@/utils/calcDuration';

export default {
  computed: {
    ...mapGetters('plant', ['plantProperty', 'lines']),
    ...mapGetters('work', [
      'workState',
      'workEvents',
      'workStateOfEvent',
      'selectableWorkEvents',
    ]),
    ...mapState({
      cameras: state => state.vision.visionTenantCameras,
    }),
  },
  methods: {
    calcDuration,
    isLine(elementId) {
      if (!this.lines) return false;
      return !!this.lines.find(x => x.id === elementId);
    },
    worklogParser(d) {
      const worklogClipLeadTime = 30; // to store
      const endDate = d.endDate || this.$now;
      const {
        reasonId,
        reasonDescription,
        correctiveActionId,
        correctiveActionDescription,
      } = d;
      const comments = this.getCommentsToDowntime(d);
      const rootMachineName = d.rootWorkLog ? this.elementName(d.rootWorkLog.elementId) : null;
      const rootDescription = d.rootWorkLog ? d.rootWorkLog.workEvent.name : null;
      const rootWorkState = d.rootWorkLog ? d.rootWorkLog.workState : null;
      const clips = this.clips ? [...this.clips].filter(c =>
        c.startDate <= (d.endDate || this.$now)
        && c.endDate >= d.startDate - worklogClipLeadTime) : [];
      const mainDescription = d.workEvent ? d.workEvent.name : '';
      const elementCameras = this.cameras.filter(c => c.elementId === d.elementId);

      return {
        ...d,
        id: `${d.startDate}-${d.elementId}`,
        eventId: d.workEvent.id,
        endDate,
        isLine: this.isLine(d.elementId),
        machineName: this.elementName(d.elementId),
        rootMachineName,
        mainDescription,
        rootDescription,
        rootWorkState,

        reasonId,
        reasonDescription,
        correctiveActionId,
        correctiveActionDescription,

        duration: this.calcDuration(d.startDate, endDate),
        durationInSeconds: endDate - d.startDate,
        comments,
        clips,
        elementCameras,
      };
    },
  },
};
