export default {
  'nothing': 'Không có thông báo',
  'list': 'Danh sách',
  'subscribedOnly': 'Chỉ đăng ký',
  'subscription': 'Đăng ký',
  'addSubscription': 'Thêm đăng ký',
  'notificationType': 'Kiểu thông báo',
  'pushNotSupported': 'Thông báo không được hỗ trợ trong môi trường này',
  'pushDenied': 'Thông báo đã bị chặn',
  'enablePush': 'Bật thông báo đẩy',
  'pushDisabled': 'Thông báo đẩy bị vô hiệu hóa',
  'pushEnabled': 'Bật thông báo đẩy',
  'outdated': 'Đã lỗi thời kể từ',
  'wastedThreshold': 'Ngưỡng chất thải',
  'threshold': 'Ngưỡng',
  'mailing': 'Nhận trong e-mail',
  'mySubscriptions': 'Đăng ký của tôi',
  'usersSubscriptions': 'Đăng ký người dùng',
  'teamsSubscriptions': 'Đăng ký nhóm',
  'numberOfSubs': 'Số của đăng ký',
  'subscriptionFromTeam': 'Đăng ký này đến từ nhóm và chỉ có thể được chỉnh sửa cho toàn bộ nhóm bởi một người dùng có quyền thích hợp',
  'type': {'hints': 'Các gợi ý', 'warnings': 'Các cảnh báo', 'userNotifications': 'Các thông báo'},
  'name': {
    'cmmsNotification': 'Dịch vụ bảo trì',
    'connectionLost': 'Máy bị mất kết nối',
    'availabilityInsight': 'Thông tin chi tiết về khả năng sẵn sàng',
    'ongoingStop': 'Đang dừng dây chuyền',
    'newDowntime': 'Thời gian ngừng máy mới',
    'orderNotifications': 'Thay đổi trạng thái đơn hàng',
    'thresholdExceeded': 'Vượt quá ngưỡng',
    'oeeThreshold': 'OEE dưới mục tiêu',
    'orderProductionDone': 'Đạt sản lượng đặt hàng',
    'orderProductionNotAchieved': 'Dừng đơn hàng với lượng sản xuất dưới mức đặt hàng',
    'orderDelayed': 'Đơn hàng bị trễ',
    'performanceThreshold': 'Hiệu suất máy thấp hơn mong đợi',
    'orderWastedThresholdExceeded': 'Phế phẩm đơn hàng đã vượt quá ngưỡng',
    'selectedLines': 'Các dây chuyền đã chọn',
    'allLines': 'Tất cả các dây chuyền',
    'ignoredCounter': 'Bỏ qua bộ đếm',
    'oeeInfo': 'Tổng kết OEE'
  },
  'timePeriod': {
    'none': 'Không',
    'immediately': 'Ngay lập tức',
    'hourly': 'Thông báo tổng kết trong 1 giờ',
    'daily': 'Thông báo tổng kết trong 1 ngày'
  }
};
