export default {
  error: 'Error',
  unknown: 'Unknown error',
  importError: 'No items to import were found. Wrong import template?',
  unableToDownloadData: 'Unable to download requested data',
  jobFailed: 'There was an error in processing the file',
  wrongInput: 'Given data is invalid!',
  nameExist: 'Given name already exist!',
  numberExist: 'Given number already exist!',
  passwordsMustBeEqual: 'Passwords must be equal!',
  toShortPassword: 'Password must have least {length} characters',
  descriptionIsRequired: 'Description is required',
  reasonRequired: 'Create or select reason first',
  criticalPleaseRefresh: 'Aw snap! There was an error, try to refresh the page',
  invalidHour: 'Invalid hour',
  importNotAvailable: 'Import is not currently available. Please try again later',
  packageAddError: 'Invalid package',
  code: {
    NoRequiredComponents: 'Lack of required components:',
    NoTrackingId: 'No tracking id for tracking packages',
  },
  invalidAuthUrl: 'Invalid auth url',
  manualProductionNoLongerThan15Days: 'You cannot edit manual production longer than 15 days',
};
