import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const cleanings = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getCleaning',
    path: ({ cleaningId }) => `v3/activities/cleanings/${cleaningId}`,
  })
  .head({
    action: 'checkCleaning',
    path: ({ externalId }) => `v3/activities/cleanings/${externalId}`,
  })
  .put({
    action: 'createCleaning',
    path: 'v3/activities/cleanings',
  })
  .get({
    action: 'getCleaningErrors',
    path: ({ cleaningId }) => `v3/activities/cleanings/${cleaningId}/validation-errors`,
  })
  .post({
    action: 'withdrawCleaning',
    path: ({ cleaningId }) => `/v3/activities/cleanings/${cleaningId}/commands/withdraw`,
  })
  .post({
    action: 'startCleaning',
    path: ({ cleaningId }) => `/v3/activities/cleanings/${cleaningId}/commands/start`,
  })
  .post({
    action: 'completeCleaning',
    path: ({ cleaningId }) => `/v3/activities/cleanings/${cleaningId}/commands/complete`,
  })
  .delete({
    action: 'deleteCleaning',
    path: ({ cleaningId }) => `v3/activities/cleanings/${cleaningId}`,
  })
  .getStore();
export default cleanings;
