<template>
  <div>
    <div class="row form-row mt-2">
      <div
        class="col-md-4"
      >
        <label class="title-style">
          {{ $t('activities.plannedStart') }}
        </label>
        <DateTimePicker
          id="from"
          v-model="begin"
          :disabled="disabled"
          :locale="locale"
          :error="datesNotValid"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          :label="$t('general.pickDate')"
          icon="ion ion-ios-body"
        />
      </div>
      <div
        class="col-md-4"
      >
        <label class="title-style">
          {{ $t('activities.plannedEnd') }}
        </label>
        <DateTimePicker
          id="to"
          v-model="end"
          :disabled="disabled"
          :locale="locale"
          :error="endDateNotValid"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          :label="$t('general.pickDate')"
        />
        <p
          v-if="isEndDateError"
          class="text-danger font-weight-bold pt-1 mb-0"
        >
          <i class="fas fa-exclamation-circle" />
          {{ $t('activities.validationErrors.endDateNotValid') }}
        </p>
      </div>
    </div>

    <div
      v-if="displayChangeovers && type === 'Order'"
      class="row ml-0 mt-3"
    >
      <label class="title-style">
        {{ $t('activities.addChangeover') }}
      </label>
      <BFormCheckbox
        v-model="add"
        :disabled="edited"
        switch
        size="lg"
        class="ml-3"
      />
    </div>

    <hr v-if="displayLabour || displayChangeovers && type === 'Order'">

    <div
      v-if="displayChangeovers && !orderWithChangeover && type === 'Order'"
      class="row form-row mt-2"
    >
      <div
        class="col-md-8"
      >
        <label
          class="title-style"
          :class="{ disabled: !add }"
        >
          {{ $t('activities.changeoverDuration') }}
        </label>
        <BFormInput
          v-model="duration"
          :state="changeoverDurationCheck(duration) | ifValidated(validate)"
          :disabled="!add || disabled"
          trim
        />
        <BFormInvalidFeedback
          v-if="!changeoverHasDurationWhenHasExpectedPerformance()"
        >
          {{ $t('activities.typeChangeoverDurationIfChangeoverExpectedEffortGiven') }}
        </BFormInvalidFeedback>
        <BFormInvalidFeedback
          v-if="duration < 0"
        >
          {{ $t('activities.typeCorrectValue') }}
        </BFormInvalidFeedback>
      </div>
    </div>

    <div
      v-if="displayChangeovers && !orderWithChangeover && type === 'Order'"
      class="row form-row mt-2"
    >
      <div
        v-if="displayLabour"
        class="col-md-8"
      >
        <label
          class="title-style"
          :class="{ disabled: !add }"
        >
          {{ $t('activities.expectedEffort') }}
        </label>
        <BFormInput
          v-model="changeoverEffort"
          :disabled="!add || disabled"
          :state="expectedEffortCheck(changeoverEffort) | ifValidated(validate)"
        />
        <BFormInvalidFeedback
          v-if="changeoverEffort < 0"
        >
          {{ $t('activities.typeCorrectExpectedEffort') }}
        </BFormInvalidFeedback>
      </div>
    </div>
  </div>
</template>

<script>
import activityForm from '@/mixins/activityForm';
import { activityValidationErrorCodes } from '@/utils/dictionary';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    activity: Object,
    changeover: Object,
    addChangeover: Boolean,
    type: String,
    edited: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      searchErrors: [],
      orderWithChangeover: false,
      lastExistingActivityId: null,
      add: null,
      begin: moment().format('YYYY-MM-DD HH:mm'),
      end: moment().add(1, 'hour').format('YYYY-MM-DD HH:mm'),
      duration: null,
      changeoverType: null,
      changeoverEffort: null,
    };
  },
  mixins: [activityForm()],
  computed: {
    ...mapGetters('plant', ['displayChangeovers', 'displayLabour']),
    errorCheck() {
      if (this.numberCheck(this.duration || 1)
        && !this.datesNotValid
        && this.expectedEffortCheck(this.changeoverEffort)
        && this.changeoverHasDurationWhenHasExpectedPerformance()
      ) return true;
      return false;
    },
    isEndDateError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return null;

      return this.validationErrors
        .find(e => [
          activityValidationErrorCodes.missingScheduledExecutionErrorCode,
          activityValidationErrorCodes.missingScheduledExecutionEndErrorCode,
        ].includes(e.code))
        && !this.endDateNotValid;
    },
  },
  watch: {
    endDateNotValid(val) {
      if (!val) {
        this.$emit('solve-errors', [
          activityValidationErrorCodes.missingScheduledExecutionErrorCode,
          activityValidationErrorCodes.missingScheduledExecutionEndErrorCode,
        ]);
      }
    },
    begin(val) {
      this.validateModel();
      this.$emit('begin', val);
      this.$emit('errorCheck', this.errorCheck);
    },
    end(val) {
      this.validateModel();
      this.$emit('end', val);
      this.$emit('errorCheck', this.errorCheck);
    },
    duration(val) {
      this.validateModel();
      this.$emit('duration', val);
      this.$emit('errorCheck', this.errorCheck);
    },
    changeoverEffort(val) {
      this.validateModel();
      this.$emit('changeoverEffort', val);
      this.$emit('errorCheck', this.errorCheck);
    },
    add(val) {
      this.$emit('add', val);
    },
  },
  methods: {
    numberCheck(v) {
      const number = Number(v);
      return !Number.isNaN(number) && number > 0;
    },
    expectedEffortCheck(v) {
      if (!v || v.length === 0) { return true; }
      const number = Number(v);
      return !Number.isNaN(number) && number >= 0;
    },
    changeoverHasDurationWhenHasExpectedPerformance() {
      if (!this.displayChangeovers || this.orderWithChangeover) {
        return true;
      }
      if (!this.duration && this.changeoverEffort) {
        return false;
      }
      return true;
    },
    changeoverDurationCheck(v) {
      if (this.changeoverHasDurationWhenHasExpectedPerformance() && !v) return true;
      if (!this.numberCheck(v || 1)) { return false; }
      if (this.activity) { return !!v; }
      return true;
    },
    validateModel() {
      this.validate = true;
      return this.errorCheck;
    },
  },
  created() {
    this.begin = this.activity.plannedExecution.begin;
    this.end = this.activity.plannedExecution.end;
    this.duration = this?.changeover?.duration;
    this.changeoverType = this.activity?.orderWithChangeover?.changeoverType;
    this.changeoverEffort = this.activity?.changeoverExpectedEffort?.manhours || 0;
    this.add = this.addChangeover || !!this.activity?.orderWithChangeover || false;
  },

};
</script>

<style lang="scss" scoped>
@import '~@/styles/vars.icss';

.title-style {
  font-weight: 500;
  font-size: 1.1em;

  &.disabled {
    color: gray;
    opacity: 0.6;
  }
}
</style>
