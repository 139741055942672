<template>
  <div v-if="production">
    <div>
      <PerformanceTypeSwitch
        :validated-speed.sync="showFromValidated"
        :plan-from-orders.sync="planFromOrders"
        show-plan-from-orders
      />
    </div>
    <div>
      <ProductionHistory
        :production="production"
        :performance="performance"
        :resolution="resolution"
        :start-date="chartStartDate"
        :end-date="chartEndDate"
        :hide-expected="hideExpected"
        :show-legend="true"
        :check="check"
        :style="{ height: `${height}px` }"
        @labels-width="$emit('labels-width', $event)"
      />
    </div>
  </div>
  <div v-else>
    <Loader :error="error" />
  </div>
</template>

<script>
import PerformanceTypeSwitch from '@/components/utils/PerformanceTypeSwitch';
import { getResolution } from '@/utils/time';
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductionHistory from '../charts/production/ProductionHistory';

export default {
  props: {
    elementId: {
      type: String,
      required: true,
    },
    hideExpected: Boolean,
    performance: Object,
    check: {
      type: Object,
      default: () => ({
        produced: true,
        wasted: true,
        planned: true,
      }),
    },
    start: Number,
    end: Number,
    height: Number,
  },
  data: () => ({
    production: null,
    error: null,
    planFromOrders: false,
    showFromValidated: false,
  }),
  components: {
    PerformanceTypeSwitch,
    ProductionHistory,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'startDate',
      'endDate',
      'performanceType',
      'productionFromOrders',
    ]),
    ...mapState({
      validatedSpeed: state => state.monitoring.validatedSpeed,
    }),
    chartStartDate() {
      return this.start || this.startDate;
    },
    chartEndDate() {
      return this.end || this.endDate;
    },
    resolution() {
      const resolution = getResolution(this.chartStartDate, this.chartEndDate);
      if (resolution === 'hour') {
        return {
          name: 'hour',
          aggregate: ['hour', 'day'],
        };
      }
      if (resolution === 'day') {
        return {
          name: 'day',
          aggregate: ['day'],
        };
      }
      return {
        name: 'week',
        aggregate: ['week'],
      };
    },
  },
  watch: {
    showFromValidated(v) {
      this.request();
      this.$store.commit('useValidatedSpeed', v);
    },
    planFromOrders() {
      this.request();
    },
    validatedSpeed(v) {
      this.showFromValidated = v;
    },
  },
  methods: {
    ...mapActions(['getProduction']),
    request() {
      const { plantId, elementId } = this;

      this.getProduction({
        params: {
          plantId,
          elementId,
          startDate: this.chartStartDate,
          endDate: this.chartEndDate,
          query: {
            aggregate: this.resolution.aggregate,
            planFromOrders: this.planFromOrders,
            performanceType: this.showFromValidated ? 1 : 0,
          },
        },
      })
        .then(({ data }) => {
          this.production = data;

          if (data.res.length > 0) {
            const { from } = data.res[0];
            const { to } = data.res[data.res.length - 1];
            this.$emit('scheduleDates', { from, to });
          } else {
            this.$emit('scheduleDates', { from: this.chartStartDate, to: this.chartEndDate });
          }
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        });
    },
  },
  created() {
    this.showFromValidated = this.validatedSpeed;
    if (this.productionFromOrders) {
      this.planFromOrders = true;
    }
    this.$emit('pageChange', 'production');
    this.request();
  },
};
</script>

<style scoped>

</style>
