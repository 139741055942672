<template>
  <div v-if="!flowId">
    <div
      v-for="flow in supplyingFlows"
      :key="flow.id"
    >
      <hr style="max-width: 50%">
      <div
        class="d-flex justify-content-center align-items-center graph-row"
        @click="showGraph(flow.id)"
      >
        <h3
          class="text-fancy text-left title"
          style="width: 300px"
        >
          {{ flow.name }}
        </h3>
        <div>
          <SmallFlow
            :flow="flow"
            :margin="10"
            :max-height="300"
            :name-fun="el => el.name.slice(0, 3)"
            :connection-type="'straight'"
            style="width: 300px; margin: auto; font-size: 10"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <FlowDetails
      :flow-id="flowId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmallFlow from '@/components/graph/SmallFlow';
import FlowDetails from '@/pages/graph/FlowDetails';

export default {
  props: {
    flowId: String,
  },
  data: () => ({
    graphs: [],
    physicalFlow: null,
  }),
  components: {
    SmallFlow,
    FlowDetails,
  },
  computed: {
    ...mapGetters([
      'plantId',
    ]),
    ...mapGetters('element', [
      'elementName',
    ]),
    ...mapGetters('plant', [
      'plantGraphs',
      'structure',
      'activeFlows',
    ]),
    supplyingFlows() {
      if (!this.plantGraphs) return [];
      return this.plantGraphs.filter(({ type }) => type === 'Supplying');
    },
  },
  methods: {
    showGraph(graphId) {
      this.$router.push(`/${this.plantId}/dashboard/flows/${graphId}`);
    },
  },
  created() {
    this.$emit('pageChange', 'flows');
  },
};
</script>

<style lang="scss" scoped>

  .flow {
    cursor: pointer;

    .element-name {
      font-size: 25px;
      line-height: 1.2;
    }

    .flow-title {
      line-height: 1;
      font-size: 15px;
    }

    &:hover {
      .flow-title, .element-name {
        text-shadow: 0 0 5px rgba(#333, 0.5);
      }
    }
  }

  .graph-row {
    cursor: pointer;
    transition: transform 500ms;

    &:hover {
      transform: translateX(-5px);
      .title {
        text-shadow: 0 0 5px rgba(#333, 0.5);
      }
    }
  }

  .mini-flow :deep(.name) {
    font-size: 9px;
  }
</style>
