export default {
  'report': 'Bericht',
  'new': 'neu',
  'sum': 'Summe',
  'time': 'Zeit',
  'quantity': 'Menge',
  'aggregated': 'Zusammengeführt',
  'detailed': 'detailiert',
  'showNAlarms': '<b>{0}</b> längste Alarmmeldungen anzeigen',
  'showNLongest': 'zeige <b>{0}</b> den längsten',
  'showOthers': 'zeige andere',
  'hideOthers': 'verberge andere',
  'sumOfSmaller': 'Summe anderer',
  'dropHereToCreateNew': 'Hierher ziehen und ablegen, um ein neues Diagramm zu erstellen',
  'completedOrders': 'abgeschlossene Aufträge',
  'history': 'Verlauf',
  'usedEnergy': 'verbrauchte Energie',
  'splitBy': 'Aufgeteilt in',
  'addPersonal': 'persönliches Dashboard',
  'addFactory': 'Standort Dashboard',
  'unitConversionNotComplete': 'Umrechnung der Einheit nicht abgeschlossen',
  'unitConversionComplete': 'Umrechnung der Einheit abgeschlossen',
  'unableToFullyConvertUnitMessage': 'Wir sind nicht in der Lage, die gesamte Produktion auf \'{Einheit}\' umzurechnen, die Verpackungsstruktur von SKUs muss geprüft werden, die in diesem Zeitraum produziert wurden',
  'work': 'Arbeit',
  'plannedDowntime': 'geplant',
  'unplannedDowntime': 'ungeplant',
  'expected': 'erwartet',
  'rootElement': 'Basiselement',
  'lineMachine': 'Linie / Maschine',
  'performanceImpact': 'Leistungsverlust',
  'availabilityImpact': 'Verfügbarkeitsverlust',
  'utilizationImpact': 'Nutzen Verlust',
  'minorStops': 'Kleinstörungen',
  viewMinorStops: 'Zeige Kleinstörungen',
  'minorStopsIncluded': 'Kleinstörungen (in Gesamtdauer enthalten)',
  'detailedEventsReport': 'Detailierter Ereignisbericht',
  'siteOeeReport': 'Standort OEE Bericht',
  'speedLossReport': 'Geschwindigkeit Verlust Bericht',
  'labourVarianceReport': 'Arbeitsstundenbericht',
  'siteWeeklyScorecard': 'wöchentliche Kennzahlen des Standortes',
  'siteMonthlyScorecard': 'monatliche Kennzahlen des Standortes',
  'exportReport': 'Bericht exportieren',
  'excludeUtilizationLoss': 'geplante Verluste ausschließen',
  decrease: 'verringern',
  increase: 'steigern',
  seeDowntimesReport: 'Siehe Bericht zu Ausfallzeiten',
  downloadReport: 'Bericht herunterladen',
  numberOfItemsProduced: 'Anzahl der produzierten Artikel',
  compare: 'Vergleichen',
  configureNewReportToCompare: 'Neuen Bericht zum Vergleichen konfigurieren',
  'oeeLoss': {
    'oeeLoss': '{0} Verlust',
    timeLoss: 'Zeitverlust',
    'productionLoss': 'Produktionsverlust',
    'undefinedIssue': 'Art des Problems undefiniert',
    'utilizationLosses': 'geplante Verluste',
    'availabilityLosses': 'Verfügbarkeitsverluste',
    'performanceLosses': 'Leistungsverluste',
    'performanceStops': 'Leistungsverluste Standzeiten',
    'qualityLosses': 'Qualitätsverluste',
    'qualityStops': 'Qualitätsverluste Standzeiten',
    'speedLoss': 'Geschwindigkeitsverlust',
    'qualityLoss': 'Qualitätsverlust',
    'speedIncrease': 'Geschwindigkeitsgewinn',
    'combined': 'kombiniert',

    'byMachine': 'unterteilt in Linien / Anlagen',
    'byProblem': 'Unterteilt in Probleme',
    'byState': 'Unterteilt in Zustände',
    byClassification: 'Unterteilt in Klassifizierung',
    'base': 'dargestellte Ausgangszeit',
    classificationInfoTooltip: 'Um eine andere Art der Klassifizierung zu verwenden, wählen Sie zunächst in einer der Verlustkategorien „Unterteilt in Klassifizierung“ aus.',
    notInClassification: 'Nicht in der Klassifizierung',
    'time': {
      'totalTime': 'gesamt verfügbare Zeit',
      'scheduledTime': 'geplante Produktionszeit',
      'operatingTime': 'tatsächliche Produktionszeit',
      'productionTime': 'verfügbare Produktionszeit',
      'effectiveTime': 'effektive Produktionszeit'
    },
    'calculatedBasedOnTotalAvailabilityLoss': 'Kalkuliert basierend auf Verfügbarkeitsverlusten'
  },
  'select': {
    'allLines': 'alle Linien',
    'allMachines': 'alle Maschinen',
    'selectMachine': 'wähle Maschine aus',
    'selectLine': 'wähle Linie aus',
    'clear': 'lösche',
    'selectLineAreaOrPlant': 'Werk, Linie oder Bereich auswählen'
  },
  'alarms': {
    'description': 'Beschreibung',
    'numberOfOccurances': 'Nummer',
    'duration': 'Dauer',
    'sumOfSmallerAlarms': 'Summe kleiner Alarme',
    'noDataInThisPeriod': 'keine Daten in dieser Periode'
  },
  'selectors': {
    'amount': 'Menge',
    'period': 'Periode',
    'moment': 'Moment',
    'dateTo': 'Datum bis',
    'dateFrom': 'Daum von',
    'shift': 'Schicht',
    'day': 'Tag',
    lastDays: 'Letzte Tage',
    'week': 'Woche',
    'month': 'Monat',
    year: 'Jahr',
    'today': 'Heute',
    'yesterday': 'Gestern',
    'thisWeek': 'diese Woche',
    'previousWeek': 'vorherige Woche',
    'thisMonth': 'dieser Monat',
    'previousMonth': 'vorheriger Monat',
    'selectLineAndMachine': 'wähle Linie und Maschine aus',
    'selectLine': 'Wähle Linie',
    'selectLineOrMachine': 'Wähle Linie oder Maschine',
    'timeFilter': 'Zeit Filter',
    'lastThreeMonths': 'Letzten 3 Monate',
    'selectExactDates': 'Wähle genaues Datum aus',
    'selectDatesFromList': 'Wähle Datum aus Liste aus',
    'addElement': 'Füge Standortelement zu dem Bericht hinzu',
    thisYear: 'Dieses Jahr',
    previousYear: 'Vorjahr',
  },
  'types': {
    'custom': 'Benutzerdefiniert',
    'availability': 'Verfügbarkeit',
    'efficiency': 'Effizienz',
    'oee': 'OEE',
    'oeeLosses': 'OEE Verluste',
    'oeeDetails': 'OEE Details',
    'production': 'Produktion',
    'productionMap': 'Produktionsplan',
    'downtimes': 'Stillstände',
    'rootcauses': 'Ursachen für Stillstände',
    comments: 'Kommentare von Verlusten',
    'alarms': 'Alarme',
    'column': 'Säule',
    'pie': 'Kuchen',
    'operatorStops': 'Bediener stoppt',
    'downtimeDuration': 'Stillstanddauer',
    'downtimeDurations': 'Stillstanddauern',
    'reasons': 'Ursachen',
    'energy': 'Energie',
    'energySankey': 'Sankey',
    'energyHistory': 'Nutzungsverlauf',
    'reportsDownload': 'Bericht Download',
    'heatmap': 'Wärebild',
    'commentsFromLosses': 'Kommentare von Verlusten',
    'typeOfLosses': 'Art der Verluste',
    downtimeTracking: 'Ausfallzeitverfolgung',
    energyTrends: 'Trends',
  },
  'operatorStop': {
    'waiting': 'Warten auf eine Aktion',
    'fixing': 'Aktionsdauer',
    'stopsBecauseOf': 'stoppt wegen',
    'timeSum': 'Gesamtdauer'
  },
  'rootcause': {
    'impact': {'title': 'Auswirkung', 'desc': '(Wirkungsdauer / Ursachendauer)'},
    'effectDuration': 'Wirkungsdauer',
    'rootcauseDuration': 'Ursachendauer'
  },
  'chart': {'toggleFullView': 'auf Vollansicht umschalten'},
  'chooseEvent': 'Problem auswählen',
  'needToSelectEvent': 'Sie müssen das Problem auswählen',

  trackerOptions: 'Optionen zur Ausfallzeitverfolgung',

  downtimeComments: {
    comment: 'Kommentar',
    downtimeElement: 'Linie/Maschine',
    downtime: 'Stillstandzeit',
    time: 'Zeit',
    duration: 'Dauer',
    commentAuthor: 'Autor des Kommentars',
    showDowntime: 'Ausfallzeit anzeigen',
    details: 'Details',
  },
  downtimeTracker: {
    track: 'Verfolgen',
    resolutionForThisSelection: 'Eingrenzen Auflösung für diese Auswahl',
    workspectrumForThisSelection: 'Arbeitsspektrum für diese Auswahl anzeigen',
    view: 'Ansehen',
    searchAProblem: 'Suchen Sie nach einem Problem...'
  },

};
