<template>
  <div
    v-if="correctiveActions.length > 0"
    style="z-index: 1000"
  >
    <div
      v-if="inPopover"
      @click.prevent.stop="caModal = true"
    >
      <Tippy
        ref="popup"
        interactive
        theme="light"
        arrow
        boundary="viewport"
        sticky="true"
        :interactive-debounce="100"
        trigger="mouseenter focus click"
      >
        <template #trigger>
          <slot />
        </template>

        <div class="p-3">
          <CorrectiveActionsContent
            :corrective-actions="correctiveActions"
          />
        </div>
      </Tippy>
    </div>
    <div v-else>
      <CorrectiveActionsContent
        :corrective-actions="correctiveActions"
      />
    </div>

    <BModal
      v-if="!inPopover || isMobile"
      v-model="caModal"
      :lazy="true"
      hide-footer
      hide-header
    >
      <button
        type="button"
        class="close"
        style="margin-top: -5px"
        @click="caModal = false"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <CorrectiveActionsContent
        :corrective-actions="correctiveActions"
      />
    </BModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CorrectiveActionsContent from './CorrectiveActionsContent';

export default {
  props: {
    inPopover: {
      type: Boolean,
      default: true,
    },
    correctiveActions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    caModal: false,
    modal: false,
  }),
  components: {
    CorrectiveActionsContent,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'isMobile',
    ]),
  },
  watch: {
    modal(v) {
      this.$emit('modal', v);
    },
  },
};
</script>

<style lang="scss" scoped>
  .article :deep(.article-content) {
    cursor: initial;
    text-align: left;
    img {
      max-width: 100%;
      box-shadow: 0 0 10px rgb(100,100,100);
    }
    iframe {
      box-shadow: 0 0 10px rgb(100,100,100);
      border: none;
      display: block;
      margin: auto;
      max-width: 100%;
    }
  }
</style>
