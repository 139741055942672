export default {
  'orders': 'Ordenes',
  'order': 'Orden',
  'import': 'Importar ordenes',
  'completedOrders': 'Ordenes completadas',
  'inProgressOrders': 'Ordenes en curso',
  'start': 'Inicio',
  'removeLastEntryFirst': 'Elimine la última declaración de producción antes de confirmar la producción de la orden  nuevamente',
  'saveAndActivate': 'Guardar y activar',
  'orderDetails': 'Detalles de la orden',
  'activate': 'Activar',
  'stop': 'Parar',
  'end': 'Fin',
  'show': 'Mostrar orden',
  'startOrder': 'Inicio de orden',
  'reportResources': 'Reporte de recursos',
  'confirmCurrentProduction': 'Confirmar producción',
  'activateOrder': 'Activar orden',
  'abortOrderActivate': 'Abortar activación',
  'selectOrder': 'Seleccione el orden',
  'selectLineAndSku': 'Seleccionar línea y SKU',
  'selectSku': 'Seleccionar Producto',
  'skuPerformance': 'Rendimiento de SKU',
  'abort': 'Abortar',
  'status': 'Estado',
  'number': 'Número',
  'orderNumber': 'Número de orden',
  'skuId': 'ID de SKU',
  'orderId': 'ID de la Orden',
  'item': 'elemento',
  'sku': 'SKU',
  'itemNo': 'Número de elemento',
  'schedule': 'Calendario',
  'scheduled': 'Programado',
  'plannedProduction': 'Producción planificada',
  'realisation': 'Comienzo real',
  'expectedStart': 'Inicio esperado',
  'expectedEnd': 'Final esperado',
  'toPlannedEnd': 'al final planeado',
  'toPlannedStart': 'al inicio planificado',
  'afterPlannedEnd': 'después del final planeado',
  'afterPlannedStart': 'después del inicio planificado',
  'expectedPerformance': 'Rendimiento esperado',
  'description': 'Descripción',
  'quantity': 'Producido',
  'limitExceeded': 'Se excedió el límite de ordenes, detenga o complete cualquier orden en ejecución',
  'production': 'Resumen de producción',
  'wasted': 'Desperdiciado',
  'updateProduction': 'Actualizar producción',
  'totalProduction': 'Total',
  'incrementalProduction': 'Incremento',
  'productionUpdates': 'Actualizaciones de producción',
  'complete': 'Completo',
  'hold': 'Pausar',
  'completed': 'Terminado',
  'planned': 'Planificado',
  'lineConfig': 'Configuración de línea',
  'config': 'Configuracion',
  'additional': 'Adicional',
  'settlement': 'Balance',
  'machinesForOrder': 'Seleccionar máquinas para este trabajo',
  'requiredComponentsNotScanned': 'Algunos componentes deben escanearse primero',
  'firstActivate': 'Se requieren algunos componentes antes de comenzar. Active la orden y escanee los componentes primero',
  'productionStats': 'Producción',
  'comment': {
    'startOrder': 'la orden comenzará de inmediato. Se activarán todos los paquetes escaneados',
    'activateOrder': 'La orden se preparará para su inicio, el flujo aún se puede cambiar y algunos datos deberán escanearse antes de su inicio',
    'completeOrder': 'la orden se completará y no se podrá iniciar de nuevo.',
    'stopOrder': 'La orden se detendrá y podrá iniciarse de nuevo más tarde.',
    'abortOrderActivate': 'La activación se cancelará, el estado de la orden volverá a cambiar a \'Listo\''
  },
  'states': {
    'inProgress': 'En curso',
    'ready': 'Esperando',
    'completed': 'Terminado',
    'other': 'Otro',
    'activated': 'Activado',
    'onHold': 'En espera',
    'taken': 'Tomado'
  },
  'activities': {
    'addActivity': 'Planificar nueva actividad',
    'editActivity': 'Editar actividad',
    'changeover': 'Cambio de producto',
    'stop': 'Detención por el operador',
    'failure': 'En Falla',
    'lack': 'Falta de componentes y productos',
    'order': 'orden',
    'idle': 'Libre/Ocioso',
    'holdup': 'Interrupción',
    'inactive': 'Inactivo',
    'cip': 'CIP',
    'completedJob': 'Trabajo completado',
    'inProgressJob': 'Trabajo en curso'
  },
  'add': 'Agregar orden',
  'create': 'Crear orden',
  'new': 'Nueva orden',
  'edit': 'Editar orden',
  'orderedQuantity': 'Cantidad planificada',
  'typeCorrectQuantity': 'La cantidad planificada debe ser mayor que cero',
  'quantityUnit': 'Unidad cuantitativa',
  'type': 'Escribir',
  'loadingItems': 'Cargando elementos …',
  'manage': 'Gestionar ordenes',
  'noActiveOrPlannedOrders': 'Sin ordenes activos o planificados',
  'noOrders': 'Sin ordenes',
  'orderStarted': 'orden iniciada',
  'productionEnteredManually': 'Confirmación manual de producción y desperdicio',
  'manualProductionAdded': 'Producción manual agregada',
  'manualProductionOverwritten': 'Producción manual sobrescrita',
  'release': 'Liberado',
  'take': 'Tomar',
  'finishOrder': 'Finalizar orden',
  'product': 'Producto',
  'plannedQuantity': 'Cantidad planificada',
  'orderHistory': 'Historial de ordenes',
  'skuDescription': 'Descripción del SKU',
  'skuNumber': 'Número de SKU',
  'noProducts': 'No SKU',
  'defaultConfiguration': 'Configuración por defecto (Default)'
};
