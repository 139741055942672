import { notify as axios } from '@core/api/axios';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    elementNotifications: {},
  },
})
  .get({
    action: 'getElementNotifications',
    path: ({ plantId, utcOffset }) =>
      `v2/notifications/plant/${plantId}/elements/${utcOffset}`,
    onSuccess: (s, { data }, a, { params }) => {
      if (params.current) {
        s.elementNotifications = {};

        data.forEach(x => {
          if (s.elementNotifications[x.elementId]) {
            s.elementNotifications[x.elementId].push(x);
          } else {
            Vue.set(s.elementNotifications, x.elementId, [x]);
          }
        });
      }
    },
  })
  .get({
    action: 'getFeedbackDictionary',
    path: () => 'v2/feedback-dictionary',
  })
  .put({
    action: 'sendNotificationFeedback',
    path: () => 'v2/feedback',
  })
  .getStore();

export default store;
