<template>
  <img
    :src="`${image(elementId)}`"
    class="machine-picture"
  >
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    elementId: String,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['image']),
    type() {
      return this.elementType(this.elementId);
    },
  },
};
</script>

<style scoped>
  .machine-picture {
    max-width: 150px;
    max-height: 120px;
  }
</style>
