import Vue from 'vue';
import axios from 'axios';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { error, info } from '@core/utils/logger';
import getOidcSettings, { authorityStorageKey, settings } from '@/api/oidc';
import { SET_CONFIG } from './store/mutation-types';

export default async function start({ store, vue }) {
  let config = {
    apiUrl: process.env.VUE_APP_API_URL,
    authUrl: process.env.VUE_APP_AUTH_URL,
    hubUrl: process.env.VUE_APP_HUB_URL,
    notifyUrl: process.env.VUE_APP_NOTIFY_URL,
  };

  if (process.env.VUE_APP_REQUESTCONFIG === 'true') {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_PUBLIC_PATH}api/config`);

      config = {
        apiUrl: data.apiUrl,
        authUrl: data.authUrl,
        hubUrl: data.hubUrl,
        notifyUrl: data.notifyUrl,
      };
    } catch (e) {
      error(e.detail);
    }
  }

  settings.authority = config.authUrl;

  localStorage.setItem(authorityStorageKey, config.authUrl);

  store.registerModule('oidcStore',
    vuexOidcCreateStoreModule(getOidcSettings(), {
      namespaced: true,
      routeBase: process.env.VUE_APP_PUBLIC_PATH,
    }));

  info(`PUBLIC PATH: ${process.env.VUE_APP_PUBLIC_PATH}`);
  info(`API URL: ${config.apiUrl}`);
  info(`AUTH URL: ${config.authUrl}`);
  info(`HUB URL: ${config.hubUrl}`);
  info(`NOTIFY URL: ${config.notifyUrl}`);
  info(`PACKOS VERSION: ${process.env.VUE_APP_PACKOS_VERSION}`);
  store.commit(SET_CONFIG, config);

  /* eslint-disable no-new */
  return new Vue(vue);
}
