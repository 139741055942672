import Vue from 'vue';
import Vuex from 'vuex';

import reportExport from '@/store/modules/reportExport';
import config from './modules/config';
import downtimes from './modules/downtimes';
import orders from './modules/orders';
import monitoring from './modules/monitoring';
import item from './modules/item';
import score from './modules/score';
import processParams from './modules/process-params';
import time from './modules/time';
import planning from './modules/planning';
import schedule from './modules/schedule';
import units from './modules/units';
import energy from './modules/energy';
import activities from './modules/activities';
import changeovers from './modules/changeovers';
import cleanings from './modules/cleanings';
import production from './modules/production';
import tenants from './modules/tenants';

import dashboard from './modules/dashboard';
import vision from './modules/vision';

// namespaced modules
import settings from './modules/settings';
import plant from './modules/plant';
import element from './modules/element';
import user from './modules/user';
import components from './modules/components';
import work from './modules/work';
import report from './modules/report';
import notify from './modules/notify';
import products from './modules/products';
import core from './modules/core';
import upload from './modules/upload';
import workCalendar from './modules/work-calendar';
import taxonomies from './modules/taxonomies';
import auditlog from './modules/auditlog';
import edgeDevices from './modules/edgeDevices';
import cameras from './modules/cameras';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,
  modules: {
    // "oidcStore" registered in start.js
    products,
    auditlog,
    plant,
    element,
    user,
    components,
    work,
    config,
    report,
    reportExport,
    notify,
    downtimes,
    monitoring,
    orders,
    item,
    upload,
    score,
    processParams,
    time,
    planning,
    schedule,
    units,
    energy,
    dashboard,
    vision,
    core,
    workCalendar,
    settings,
    activities,
    changeovers,
    cleanings,
    production,
    taxonomies,
    tenants,
    edgeDevices,
    cameras,
  },
});

export default store;
