import hub from './hub';
import workboost from './workboost';

export default {
  namespaced: true,
  modules: {
    hub,
    workboost,
  },
};
