<template>
  <div class="p-2">
    <div>
      <div class="label font-weight-bold pr-1">
        {{ activityType }}:
      </div>
      <div class="title font-weight-bold">
        {{ activity.name }}
      </div>
    </div>

    <hr class="my-2">
    <div v-if="activity.description ">
      <div class="label font-weight-bold pr-1">
        {{ $t('orders.description') }}:
      </div>
      <div class="title">
        {{ activity.description }}
      </div>
    </div>

    <div>
      <div class="label font-weight-bold pr-1">
        {{ $t('orders.sku') }}:
      </div>
      <div class="title">
        {{ skuNumber }}
      </div>
    </div>

    <div class="mt-1">
      <ActivityStatusBadge
        class="py-1"
        :status="activity.status"
        :rounded="false"
        :big="true"
      />
    </div>

    <hr class="my-2">

    <div class="dates pt-0">
      <div v-if="activity.actualExecution">
        <div class="font-weight-bold date-title">
          <i class="far fa-calendar pr-1" /> {{ $t('activities.status.started') }}:
        </div>
        <span>
          {{ activity.actualExecution.begin | formatDate }} -
          {{ activity.actualExecution.end | formatDate }}
        </span>
      </div>

      <div
        v-if="activity.scheduledExecution"
        class="pt-2"
      >
        <div class="font-weight-bold date-title">
          <i class="far fa-calendar pr-1" /> {{ $t('orders.scheduled') }}:
        </div>
        <span>
          {{ activity.scheduledExecution.begin | formatDate }} -
          {{ activity.scheduledExecution.end | formatDate }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import ActivityStatusBadge from '@/components/activity/ActivityStatusBadge';

export default {
  props: {
    activity: Object,
  },
  components: {
    ActivityStatusBadge,
  },
  filters: {
    formatDate(d) {
      if (!d) return '...';
      return moment(d).format('Do MMM HH:mm');
    },
  },
  computed: {
    ...mapState({
      items: state => state.item.items,
    }),
    ...mapGetters([
      'getActivityType',
    ]),
    skuNumber() {
      return this.items?.find(i => i.id === this.activity.productId)?.skuNo;
    },
    activityType() {
      return this.getActivityType(this.activity)?.desc;
    },
  },

};
</script>

<style scoped>
  .date-title {
    text-align: left;
  }
  .dates {
    font-weight: 400;
  }
  .title {
    text-align: left;
    font-size: 14px;
    display: inline-block;
  }
  .label {
    display: inline-block;
  }
</style>
