import notify from '@core/store/notify';
import language from '@core/store/language';
import userSettings from '@core/store/userSettings';
import permissions from '@core/store/permissions';
import { PACKOS_PLANT_ADMIN } from '@core/dict/permissions';
import i18n from '@/lang';

export default {
  namespaced: true,
  getters: {
    token: (s, g, rs, rg) => rg['oidcStore/oidcAccessToken'],
    user: (s, g, rs, rg) => rg['oidcStore/oidcUser'],
    hubUrl: (s, g, rs, rg) => rg.hubUrl,
    plantName: (s, g, rs, rg) => plantId => {
      if (!plantId || !rg.plants) return '';
      const p = rg.plants.find(x => x.id.toLowerCase() === plantId.toLowerCase());
      return p ? p.name : '';
    },
    serviceWorker: (s, g, rs) => rs.config.serviceWorker,

    // Check if user has access to 'permission' on element, or any element higher in the tree
    canOnElement: (s, g, rs, rg) => (perm, elementId) => {
      if (rg['core/can'](PACKOS_PLANT_ADMIN, rg.plantId)) return true;

      let id = elementId;
      // Check all parents
      while (id) {
        if (rg['core/can'](perm, id)) return true;
        id = rg['plant/structure'].parent(id);
      }

      return false;
    },
  },
  actions: {
    signOut(ctx) {
      ctx.dispatch('oidcStore/signOutOidc', null, { root: true });
    },
    showNotifications(ctx) {
      const { plantId } = ctx.rootGetters;

      ctx.commit('notify/notificationExplorer', {
        elementId: plantId,
        subscribed: true,
      }, { root: true });
    },
  },
  modules: {
    permissions,
    userSettings,
    notify,
    language: language(i18n),
  },
};
