import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const reportExport = new Vapi({
  axios,
})
  .get({
    action: 'exportDetailedEvents',
    path: () => '/v2/report/detailed-events',
    headers: () => ({
      accept: 'text/csv',
    }),
  })
  .get({
    action: 'exportSiteOeeReport',
    path: () => '/v2/report/site-oee-report',
    headers: () => ({
      accept: 'text/csv',
    }),
  })
  .get({
    action: 'exportOrdersSpeedLoss',
    path: () => '/v2/report/orders-speed-loss',
    headers: () => ({
      accept: 'text/csv',
    }),
  })
  .get({
    action: 'exportLabourVarianceReport',
    path: () => '/v2/report/labour-variance-report',
    headers: () => ({
      accept: 'text/csv',
    }),
  })
  .get({
    action: 'exportSiteWeeklyScorecard',
    path: () => '/v2/report/site-weekly-scorecard',
    headers: () => ({
      accept: 'text/csv',
    }),
  })
  .get({
    action: 'exportSiteMonthlyScorecard',
    path: () => '/v2/report/site-monthly-scorecard',
    headers: () => ({
      accept: 'text/csv',
    }),
  })
  .getStore({
    namespaced: true,
  });

export default reportExport;
