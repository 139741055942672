<template>
  <div>
    <slot
      v-if="!hideRoot"
      :row="{
        node: root,
        expanded,
        expandable: filtered.length > 0,
        padding: paddingSum,
      }"
      :toggle="toggle"
    >
      <FileRow
        :node="root"
        :expanded="expanded && filtered.length > 0"
        :expandable="filtered.length > 0"
        :padding-sum="paddingSum"
        :simulation="simulation"
        @click="toggle"
      />
    </slot>
    <div
      v-for="element in filtered"
      :key="element.id"
      :style="{ 'padding-left': `${padding}px` }"
    >
      <FileTree
        v-if="expanded"
        :list="list"
        :root="element"
        :inner="true"
        :simulation="simulation"
        :padding-sum="paddingSum + padding"
      >
        <template #default="scope">
          <slot v-bind="scope" />
        </template>
      </FileTree>
    </div>
  </div>
</template>

<script>
import FileRow from './FileRow';

export default {
  name: 'FileTree',
  props: {
    root: {
      type: Object,
      required: true,
    },
    inner: Boolean,
    hideRoot: Boolean,
    simulation: Boolean,
    list: {
      type: Array,
      default: () => [],
    },
    paddingSum: {
      type: Number,
      default: 0,
    },
    padding: {
      type: Number,
      default: 10,
    },
  },
  data: () => ({
    expanded: true,
  }),
  components: {
    FileRow,
  },
  computed: {
    filtered() {
      if (!this.list) return [];
      const filtered = this.list.filter(e => e && e.parentId === this.root.id);
      return this.sortByType(filtered);
    },
  },
  methods: {
    sortByType(list) {
      return list.sort((a, b) => a.type.localeCompare(b.type, undefined, { numeric: true, sensitivity: 'base' }));
    },
    toggle(expand) {
      this.expanded = expand;
    },
  },
};
</script>

<style>

</style>
