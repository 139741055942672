<template>
  <!-- TODO -->
  <!-- <Well
    v-if="kind === 'well'"
    :meta="meta"
  />
  <Spw
    v-else-if="kind === 'spw'"
    :meta="meta"
  />
  <WaterSoftener
    v-else-if="kind === 'watersoftener'"
    :meta="meta"
  /> -->

  <!-- DEFAULT MACHINE -->
  <div v-if="machine && machine.haveCounter">
    <hr
      :style="machineDetails ? 'margin-left: -10px' : 'margin: 10px 0'"
    >
    <div
      class="no-gutters text-center"
      :class="machineDetails ? '' : 'row'"
      style="margin: 0 -10px"
    >
      <div
        :class="machineDetails ? 'text-left mt-2' : 'col-6'"
      >
        <div class="more-title">
          {{ $t('monitoring.machine.counter') }}
        </div>
        <span
          v-if="production"
          class="text-nowrap"
        >
          {{ production.done | perf | addSpaces }} {{ production.unit }}
        </span>
        <span v-else>-</span>
      </div>
      <div
        :class="machineDetails ? 'text-left mt-2' : 'col-6'"
      >
        <div
          v-tippy="{ arrow: true, maxWidth: 150, placement: 'right' }"
          class="more-title"
          :content="$t('monitoring.machine.performanceInfo',
                       [calcDuration(1, performance.shortPerformanceDurationInSeconds + 1)])"
        >
          {{ $t('oee.performance') }}
          <i class="ion ion-ios-information-circle-outline perf-info-icon" />
        </div>
        <span
          v-if="performance"
          class="text-nowrap"
        >
          {{ performance.shortPerformance | perf | addSpaces }} {{ performance.shortPerformanceUnit }}
        </span>
        <span v-else>-</span>
      </div>
    </div>
  </div>
</template>

<script>
import calcDuration from '@/utils/calcDuration';
// TODO
// import Well from './types/Well';
// import Spw from './types/Spw';
// import WaterSoftener from './types/WaterSoftener';

export default {
  props: {
    type: String,
    performance: Object,
    production: Object,
    machine: Object,
    machineDetails: Boolean,
  },
  filters: {
    perf(v) {
      if (v > 10) {
        return Math.round(v);
      }
      return Math.round(v * 100) / 100;
    },
  },
  methods: {
    calcDuration,
  },
};
</script>

<style lang="scss" scoped>
  .more-title {
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
  }

  .perf-info-icon {
    font-size: 14px;
    position: relative;
    left: 5px;
    top: 1px;
  }
</style>
