/* eslint-disable max-len */
export default {
  nothing: 'No notifications',
  list: 'List',
  subscribedOnly: 'Subscribed only',
  subscription: 'Subscription',
  addSubscription: 'Add subscription',
  notificationType: 'Notification type',
  pushNotSupported: 'Notifications not supported in this environment',
  pushDenied: 'Notifications has been blocked',
  enablePush: 'Enable push notifications',
  pushDisabled: 'Push notifications disabled',
  pushEnabled: 'Push notifications enabled',
  outdated: 'Outdated sice',
  wastedThreshold: 'Waste threshold',
  threshold: 'Threshold',
  mailing: 'Receive in e-mail',
  howHelpful: 'How helpful is this hint?',
  whyNotHelpful: 'Why wasn’t this helpful?',
  mySubscriptions: 'My subscriptions',
  usersSubscriptions: 'Users subscriptions',
  teamsSubscriptions: 'Teams subscriptions',
  numberOfSubs: 'Number of subscriptions',
  subscriptionFromTeam: 'This subscription comes from the team and can be edited only for the whole team by a user with appropriate permissions',
  type: {
    hints: 'Hints',
    warnings: 'Warnings',
    userNotifications: 'Notifications',
  },
  name: {
    cmmsNotification: 'Maintenance service',
    connectionLost: 'Machine connection lost',
    availabilityInsight: 'Availability insights',
    ongoingStop: 'Ongoing line stop',
    newDowntime: 'New downtime',
    orderNotifications: 'Order status change',
    thresholdExceeded: 'Threshold exceeded',
    oeeThreshold: 'OEE below target',
    orderProductionDone: 'Order production achieved',
    orderProductionNotAchieved: 'Order closed with production below ordered',
    orderDelayed: 'Order delayed',
    performanceThreshold: 'Machine performance less than expected',
    orderWastedThresholdExceeded: 'Order waste threshold exceeded',
    selectedLines: 'Selected Lines',
    allLines: 'All Lines',
    ignoredCounter: 'Ignored counter',
    oeeInfo: 'OEE summary',
    oeeSummaryMail: 'OEE summary - email report',
  },
  timePeriod: {
    none: 'No',
    immediately: 'Immediately',
    hourly: 'In a hourly notification summary',
    daily: 'In a daily notification summary',
    everyEndOfShift: 'Every end of shift',
    everyDay: 'Every day',
    everyWeek: 'Every week',
  },
};
