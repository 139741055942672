import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const item = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getIntegratorJob',
    path: ({ plantId, jobId }) => `/v2/plant/${plantId}/integrator/job/${jobId}/result`,
  })
  .get({
    action: 'getIntegratorJobs',
    property: 'jobs',
    path: ({ plantId, take, skip }) =>
      `/v2/plant/${plantId}/integrator/imports/last/${skip}/${take}/overview`,
  })
  .get({
    action: 'getTemplates',
    property: 'templates',
    path: '/v3/flexible-importer/functions',
  })
  .get({
    action: 'getTemplate',
    path: ({ functionId }) => `/v3/flexible-importer/functions/${functionId}`,
  })
  .get({
    action: 'getImports',
    property: 'imports',
    path: '/v3/flexible-importer/imports',
  })
  .put({
    action: 'importFile',
    path: '/v3/flexible-importer/imports',
  })
  .get({
    action: 'getImportResult',
    path: ({ importId }) => `/v3/flexible-importer/imports/${importId}`,
  })
  .put({
    action: 'importSimulationFile',
    path: '/v3/flexible-importer/imports/simulation',
  })
  .delete({
    action: 'deleteFunction',
    path: ({ id }) => `/v3/flexible-importer/functions/${id}`,
  })
  .put({
    action: 'updateTemplate',
    path: '/v3/flexible-importer/functions',
  })
  .post({
    action: 'createTemplate',
    path: '/v3/flexible-importer/functions',
  })
  .getStore();

export default item;
