export default {
  data: () => ({
    animationTimeout: 0,
    hAnimationTimeout: 0,
    wAnimationTimeout: 0,
  }),
  methods: {
    resetStyles() {
      this.colsStyles = Array(this.cols).fill({}).map((v, index) => {
        if (index < this.visibleColsAmount || index >= (this.rows - this.visibleColsAmount)) {
          return {
            padding: '0',
          };
        }
        return v;
      });
      this.rowsStyles = Array(this.rows).fill({}).map((v, index) => {
        if (index < this.visibleRowsAmount || index >= (this.rows - this.visibleRowsAmount)) {
          return {
            padding: '0',
          };
        }
        return v;
      });
    },
    resetMovementAnimation(instant = false) {
      clearTimeout(this.animationTimeout);
      this.animationTimeout = setTimeout(() => {
        this.calculateColsWidths();
        this.calculateZoomingColsWidths();
        this.calculateRowsHeights();
        this.calculateZoomingRowsHeights();
        this.resetStyles();
        this.animateCols = false;
        this.animateRows = false;
        setTimeout(() => {
          this.lockMovement = false;
        }, 150);
      }, instant ? 0 : 800);
    },
    animateGoLevelDown(x, y) {
      this.animateCols = true;
      this.animateRows = true;

      setTimeout(() => {
        this.calculateZoomingColsWidths();
        this.calculateZoomingRowsHeights();

        this.colsStyles = this.colsStyles.map((v, index) => {
          if (index === x) {
            return {
              width: `calc(${100 - parseFloat(this.zoomingLegendWidth)}%)`,
            };
          }
          return {
            width: '0',
            padding: '0',
            fonsSize: '0',
            opacity: '0',
          };
        });

        this.rowsStyles = this.colsStyles.map((v, index) => {
          if (index === y) {
            return {
              height: `calc(${100 - parseFloat(this.zoomingRowsStyles[0].height)}%)`,
            };
          }
          return {
            height: '0',
            padding: '0',
            fontSize: '0',
            opacity: '0',
          };
        });
      }, 1);
    },
    animateGoLeft() {
      this.animateCols = true;

      const finalWidth = `${100 / (this.visibleColsAmount + 1)}%`;

      this.colsStyles = this.colsStyles.map((v, index) => {
        if (index === this.visibleColsAmount) {
          return {
            width: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index === this.visibleColsAmount * 2) {
          return {
            width: finalWidth,
            padding: '1.5px',
          };
        }
        return v;
      });
    },
    animateGoLeftPage() {
      this.animateCols = true;

      const finalWidth = `${100 / (this.visibleColsAmount + 1)}%`;

      this.colsStyles = this.colsStyles.map((v, index) => {
        if (index < this.visibleColsAmount * 2) {
          return {
            width: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index > this.visibleColsAmount) {
          return {
            width: finalWidth,
            padding: '1.5px',
          };
        }
        return v;
      });
    },
    animateGoRight() {
      this.animateCols = true;

      const finalWidth = `${100 / (this.visibleColsAmount + 1)}%`;

      this.colsStyles = this.colsStyles.map((v, index) => {
        if (index === this.visibleColsAmount * 2 - 1) {
          return {
            width: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index === this.visibleColsAmount - 1) {
          return {
            width: finalWidth,
            padding: '1.5px',
          };
        }
        return v;
      });
    },
    animateGoRightPage() {
      this.animateCols = true;

      const finalWidth = `${100 / (this.visibleColsAmount + 1)}%`;

      this.colsStyles = this.colsStyles.map((v, index) => {
        if (index > this.visibleColsAmount - 1) {
          return {
            width: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index < this.visibleColsAmount * 2 - 1) {
          return {
            width: finalWidth,
            padding: '1.5px',
          };
        }
        return v;
      });
    },
    animateGoUp() {
      this.animateRows = true;

      const finalHeight = `${100 / (this.visibleRowsAmount + 1)}%`;

      this.rowsStyles = this.rowsStyles.map((v, index) => {
        if (index === this.visibleRowsAmount * 2 - 1) {
          return {
            height: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index === this.visibleRowsAmount - 1) {
          return {
            height: finalHeight,
          };
        }
        return v;
      });
    },
    animateGoUpPage() {
      this.animateRows = true;

      const finalHeight = `${100 / (this.visibleRowsAmount + 1)}%`;

      this.rowsStyles = this.rowsStyles.map((v, index) => {
        if (index > this.visibleRowsAmount - 1) {
          return {
            height: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index < this.visibleRowsAmount * 2 - 1) {
          return {
            height: finalHeight,
          };
        }
        return v;
      });
    },
    animateGoDown() {
      this.animateRows = true;

      const finalHeight = `${100 / (this.visibleRowsAmount + 1)}%`;

      this.rowsStyles = this.rowsStyles.map((v, index) => {
        if (index === this.visibleRowsAmount) {
          return {
            height: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index === this.visibleRowsAmount * 2) {
          return {
            height: finalHeight,
          };
        }
        return v;
      });
    },
    animateGoDownPage() {
      this.animateRows = true;

      const finalHeight = `${100 / (this.visibleRowsAmount + 1)}%`;

      this.rowsStyles = this.rowsStyles.map((v, index) => {
        if (index < this.visibleRowsAmount * 2) {
          return {
            height: '0',
            padding: '0',
            opacity: '0',
          };
        }
        if (index >= this.visibleRowsAmount * 2) {
          return {
            height: finalHeight,
          };
        }
        return v;
      });
    },
    calculateColsWidths() {
      const finalWidth = `${100 / (this.visibleColsAmount + 1)}%`;

      this.resetStyles();
      this.colsWidths = Array(this.cols).fill(finalWidth).map((v, index) => {
        if (index < this.visibleColsAmount || index >= (this.cols - this.visibleColsAmount)) {
          return 0;
        }
        return v;
      });
    },
    calculateZoomingColsWidths() {
      const finalZoomingWidth = `${100 / (this.zoomingColsAmount)}%`;
      this.zoomingLegendWidth = `${100 / (this.zoomingRowsAmount + 1)}%`;

      this.resetStyles();
      this.zoomingColsStyles = Array(this.zoomingColsAmount).fill({}).map(() => ({
        width: finalZoomingWidth,
        height: '100%',
      }));
    },
    calculateRowsHeights() {
      const finalHeight = `${100 / (this.visibleRowsAmount + 1)}%`;

      this.resetStyles();
      this.rowsHeights = Array(this.rows).fill(finalHeight).map((v, index) => {
        if (index < this.visibleRowsAmount || index >= (this.rows - this.visibleRowsAmount)) {
          return 0;
        }
        return v;
      });
    },
    calculateZoomingRowsHeights() {
      const finalZoomingHeight = `${100 / (this.zoomingRowsAmount)}%`;
      this.zoomingLegendHeight = `${100 / (this.zoomingRowsAmount + 1)}%`;

      this.resetStyles();
      this.zoomingRowsStyles = Array(this.zoomingRowsAmount).fill({}).map(() => ({
        width: '100%',
        height: finalZoomingHeight,
      }));
    },
  },
};
