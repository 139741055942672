export default {
  'nothing': 'Nenhuma notificação',
  'list': 'Lista',
  'subscribedOnly': 'Apenas inscritos',
  'subscription': 'Assinatura',
  'addSubscription': 'Adicionar assinatura',
  'notificationType': 'Tipo de notificação',
  'pushNotSupported': 'Notificações não suportadas neste ambiente',
  'pushDenied': 'As notificações foram bloqueadas',
  'enablePush': 'Habilitar notificações push',
  'pushDisabled': 'Notificações push desativadas',
  'pushEnabled': 'Notificações push ativadas',
  'outdated': 'Desatualizado',
  'wastedThreshold': 'Limite de resíduos',
  'threshold': 'Limite',
  'mailing': 'Receber no e-mail',
  'mySubscriptions': 'Minhas assinaturas',
  'usersSubscriptions': 'Assinaturas de usuários',
  'teamsSubscriptions': 'Assinaturas das equipes',
  'numberOfSubs': 'Número de assinaturas',
  'subscriptionFromTeam': 'Esta é a assinatura da equipe e somente poderá ser alterada para toda a equipe por um usuário autorizado',
  'type': {'hints': 'Sugestões', 'warnings': 'Avisos', 'userNotifications': 'Notificações'},
  'name': {
    'cmmsNotification': 'Serviço de manutenção',
    'connectionLost': 'Conexão da máquina perdida',
    'availabilityInsight': 'Insights de disponibilidade',
    'ongoingStop': 'Parada de linha em andamento',
    'newDowntime': 'Novo tempo de inatividade',
    'orderNotifications': 'Mudança de status do pedido',
    'thresholdExceeded': 'Limite excedido',
    'oeeThreshold': 'OEE abaixo da meta',
    'orderProductionDone': 'Produção do pedido concluída',
    'orderProductionNotAchieved': 'Pedido fechado com produção abaixo do pedido',
    'orderDelayed': 'Pedido atrasado',
    'performanceThreshold': 'Desempenho da máquina inferior ao esperado',
    'orderWastedThresholdExceeded': 'Limite de resíduos do pedido excedido',
    'selectedLines': 'Linhas selecionadas',
    'allLines': 'Todas as linhas',
    'ignoredCounter': 'Contador ignorado',
    'oeeInfo': 'Resumo do OEE',
    oeeSummaryMail: "Resumo de OEE - relatório por e-mail"
  },
  'timePeriod': {
    'none': 'nº',
    'immediately': 'Imediatamente',
    'hourly': 'Em um resumo de notificação de hora em hora',
    'daily': 'Em um resumo diário de notificação',
    everyEndOfShift: "A cada final de turno",
    everyDay: "Todo dia",
    everyWeek: "Toda semana",
  },
  howHelpful: "Quão útil é esta dica?",
  whyNotHelpful: "Por que isso não foi útil?",
};
