<template>
  <div
    class="production-reconciliation-table"
    :class="{displayAsWaste}"
  >
    <SimpleTable
      :fields="fields"
      :items="tableItems"
    >
      <template #cell(fromTo)="{item}">
        <span v-if="!item.to">&nbsp;</span>

        <div
          v-if="!item.to"
          class="d-flex align-items-center"
        >
          {{ item.from | formatDate }}
          -

          <DateTimePicker
            id="startDateSelector"
            ref="startDateSelector"
            v-model="entryTo"
            :color="colors('ilabo')"
            :locale="locale"
            :first-day-of-week="weekStart === 0 ? 7 : weekStart"
            class="w-50 custom-picker mx-1"
            overlay
            no-clear-button
            enable-button-validate
            :format="pickerFormat"
            formatted="DD MMM YYYY HH:mm"
            label=""
          />
        </div>
        <div
          v-if="!item.value"
          class="text-black-50 text-left"
          style="font-size: 11px"
        >
          &nbsp;
        </div>

        <span v-else>
          {{ item.from | formatDate }}
          -
          {{ item.to | formatDate }}
        </span>
      </template>

      <template #cell(value)="{item, index}">
        <p
          v-if="index === 0"
          class="mb-0 text-left"
        >
          {{ $t('general.total') }}
        </p>
        <div
          v-if="index === 0 && !readOnly"
          class="d-flex w-100 align-items-center"
        >
          <input
            v-model="entryValue"
            class="form-control w-100 mr-2 text-right"
            style="font-size: 13px"
            :placeholder="valuePlaceholder.toString()"
            @input="calculateEntryValueIncrease()"
            @keypress.enter="$emit('enter-pressed')"
          >

          {{ item.unit }}
        </div>
        <div
          v-if="index === 0 && !readOnly"
          class="text-black-50 text-left font-weight-normal"
          style="font-size: 11px"
        >
          {{ $t('activities.productionReconciliation.rawCounter') }}: {{ rawCounter }}
        </div>

        <span v-else>
          {{ item.value }}
          {{ item.unit }}
        </span>
      </template>

      <template #cell(valueIncrease)="{item, index}">
        <p
          v-if="index === 0"
          class="mb-0 text-left"
        >
          {{ $t('activities.productionReconciliation.increase') }}
        </p>
        <div
          v-if="index === 0 && !readOnly"
          class="d-flex w-100 align-items-center"
        >
          <input
            v-model="entryValueIncrease"
            class="form-control w-100 mr-2 text-right"
            style="font-size: 13px"
            :placeholder="valueIncreasePlaceholder"
            @input="calculateEntryValue()"
            @keypress.enter="$emit('enter-pressed')"
          >
          {{ item.unit }}
        </div>
        <div
          v-if="index === 0 && !readOnly"
          class="text-black-50 text-left font-weight-normal"
          style="font-size: 11px"
        >
          {{ $t('activities.productionReconciliation.rawCounter') }}: {{ rawCounterIncrease }}
        </div>

        <span v-else>
          {{ item.valueIncrease | parseIncreaseValue }}
          {{ item.unit }}
        </span>
      </template>

      <template #cell(who)="{item}">
        <div
          v-if="item.issuerName"
          class="d-flex align-items-center"
        >
          <div class="text-black-50 h5 mb-0 mr-2">
            <i class="fas fa-user-circle" />
          </div>
          {{ item.issuerName }}
        </div>
      </template>

      <template #cell(actions)="item">
        <i
          v-if="item.index === 1 && !readOnly"
          v-b-tooltip.right="'Remove this entry'"
          class="fas fa-trash cursor-pointer"
          @click="$emit('delete-entry', item.item)"
        />
      </template>
    </SimpleTable>
  </div>
</template>

<script>

import { activityResourceType } from '@/store/modules/activities';
import colors from '@/utils/colors';
import DateTimePicker from '@ilabo/vue-ctk-date-time-picker';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductionReconciliationTable',
  props: {
    valueColumnLabel: {
      type: String,
      required: true,
    },
    unit: String,
    entries: Array,
    displayAsWaste: Boolean,
    readOnly: Boolean,
    counterValue: Number,
    newEntryObject: Object,
    orderStartDate: String,
  },
  data: () => ({
    pickerFormat: 'DD.MM.YYYY HH:mm',
    rawCounter: '0',

    entryTo: moment().format('DD.MM.YYYY HH:mm'),
    entryValue: '',
    entryValueIncrease: '',
  }),
  components: {
    DateTimePicker,
  },
  filters: {
    formatDate(v) {
      return moment(v).format('DD MMM YYYY HH:mm:ss');
    },
    parseIncreaseValue(v) {
      if (v >= 0) return `+ ${v}`;
      return `- ${Math.abs(v)}`;
    },
  },
  computed: {
    ...mapGetters('core', [
      'locale',
      'weekStart',
    ]),
    rawCounterIncrease() {
      const val = this.rawCounter - this.lastValue;
      if (val >= 0) {
        return `+ ${val}`;
      }
      return `- ${Math.abs(val)}`;
    },
    rawTableItems() {
      let lastValue = 0;

      const items = [...this.entries]
        .sort((a, b) =>
          moment(a.to || this.$now).unix()
        - moment(b.to || this.$now).unix())
        .map(e => {
          const totalValue = e.value + lastValue;
          lastValue = totalValue;
          return {
            ...e,
            value: totalValue,
            valueIncrease: e.value,
          };
        });

      items.sort((a, b) =>
        moment(b.to || this.$now).unix()
          - moment(a.to || this.$now).unix());
      return items;
    },
    tableItems() {
      if (this.readOnly) return this.rawTableItems;
      return [
        {
          from: this.lastDateTo,
          unit: this.unit,
        },
        ...this.rawTableItems,
      ];
    },
    lastDateTo() {
      return this.rawTableItems[0]?.to || this.orderStartDate;
    },
    lastValue() {
      return this.rawTableItems[0]?.value || 0;
    },
    lastValueIncrease() {
      return this.rawTableItems[0]?.valueIncrease || 0;
    },
    valuePlaceholder() {
      if (this.calculatedEntryValue !== '') return `${this.calculatedEntryValue}`;
      return (this.rawCounter !== undefined) ? this.rawCounter : this.lastValue;
    },
    valueIncreasePlaceholder() {
      if (this.calculatedEntryValueIncrease !== '') return `${this.calculatedEntryValueIncrease}`;
      return `${this.rawCounterIncrease || this.lastValueIncrease}`;
    },
    calculatedEntryValue() {
      if (!this.entryValueIncrease) return '';
      return Math.round(this.lastValue + this.calculateInner(this.entryValueIncrease));
    },
    calculatedEntryValueIncrease() {
      if (!this.entryValue) return '';
      const value = this.calculateInner(this.entryValue) - this.lastValue;
      if (value > 0) {
        return `+ ${value}`;
      }
      return `- ${Math.abs(Math.round(value))}`;
    },
    fields() {
      return [
        {
          key: 'fromTo',
          label: this.$t('activities.productionReconciliation.fromTo'),
          class: 'fromTo',
        },
        {
          key: 'value',
          label: this.valueColumnLabel,
          class: 'value',
        },
        {
          key: 'valueIncrease',
          label: '',
          class: 'value',
        },
        {
          key: 'who',
          label: this.$t('activities.productionReconciliation.who'),
          class: 'who',
        },
        {
          key: 'actions',
          label: '',
          class: 'actions',
        },
      ];
    },
    updatedEntryObject() {
      return {
        to: moment(this.entryTo, this.pickerFormat).utc().format(),
        value: this.calculateInner((this.entryValue || this.valuePlaceholder).toString()) - this.lastValue,
        resourceType: this.displayAsWaste
          ? activityResourceType.waste
          : activityResourceType.production,
      };
    },
  },
  watch: {
    entryValue(v, pv) {
      if (v === '') return;
      if (!/^[0-9 ()+-]+$/.test(v)) {
        this.entryValue = pv;
      }
    },
    entryValueIncrease(v, pv) {
      if (v === '') return;
      if (!/^[0-9 ()+-]+$/.test(v)) {
        this.entryValueIncrease = pv;
      }
    },
    counterValue() {
      this.updateCounter();
    },
    updatedEntryObject: {
      deep: true,
      handler(v) {
        this.$emit('update:new-entry-object', v);
      },
    },
  },
  methods: {
    colors,
    updateCounter() {
      this.rawCounter = this.counterValue;
    },
    calculateEntryValueIncrease() {
      this.entryValueIncrease = '';
    },
    calculateEntryValue() {
      this.entryValue = '';
    },
    calculateInner(value) {
      const regex = /(\d+|\+|-)/g;

      const tokens = value.match(regex);

      if (!tokens) {
        return 0;
      }

      let result = 0;
      let currentOperator = '+';

      // eslint-disable-next-line no-restricted-syntax
      for (const token of tokens) {
        if (token === '+' || token === '-') {
          currentOperator = token;
        } else {
          const v = parseInt(token, 10);
          if (currentOperator === '+') {
            result += v;
          } else {
            result -= v;
          }
        }
      }

      return result;
    },
  },
  created() {
    this.updateCounter();
  },
};
</script>

<style scoped lang="scss">
.production-reconciliation-table {
  font-size: 13px;

  &.displayAsWaste {
    &:deep() {
      .value {
        color: var(--danger);
      }
    }
  }

  &:deep() {
    .field-input {
      font-size: 13px;
      padding-top: unset !important;
      min-height: 33.5px;
      height: 33.5px;
    }

    thead {
      .value {
        text-align: left !important;
      }
    }

    tbody {
      .value {
        text-align: right !important;
      }
    }

    .value {
      font-weight: bold;
    }

    .value, .fromTo {
      text-wrap: avoid;
      white-space: nowrap;
    }

    .fromTo, .who {
      text-align: left !important;
    }

    .actions {
      text-align: right !important;
    }
  }
}
</style>
