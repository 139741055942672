import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    list: [],
  },
})
  .get({
    action: 'getElementsSignals',
    property: 'list',
    path: ({ plantId }) => `/v2/plant/${plantId}/elements/signals`,
  })
  .post({
    action: 'createElementSignal',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/signal`,
    onSuccess: (s, { data }, _, { params }) => {
      const el = s.list.find(e => e.elementId === params.elementId);
      if (el) {
        el.signals.push({ ...data });
      } else {
        s.list.push({
          elementId: params.elementId,
          signals: [{ ...data }],
        });
      }
    },
  })
  .delete({
    action: 'deleteElementSignal',
    path: ({ plantId, elementId, name }) =>
      `/v2/plant/${plantId}/element/${elementId}/signal/${name}`,
    onSuccess: (s, p, _, { params }) => {
      const el = s.list.find(e => e.elementId === params.elementId);
      if (el) {
        el.signals = el.signals.filter(({ name }) => name !== params.name);
      }
    },
  })
  .put({
    action: 'updateElementSignal',
    path: ({ plantId, elementId }) =>
      `/v2/plant/${plantId}/element/${elementId}/signal`,
    onSuccess: (s, { data }, _, { params }) => {
      const el = s.list.find(e => e.elementId === params.elementId);
      if (el) {
        const index = el.signals.findIndex(x => x.name.toLowerCase() === data.name.toLowerCase());
        Vue.set(el.signals, index, { ...data });
      }
    },
  })
  .patch({
    action: 'disableElementSignal',
    path: ({
      plantId, elementId, name, action,
    }) =>
      `/v2/plant/${plantId}/element/${elementId}/signal/${name}/${action}`,
    onSuccess: (s, p, _, { params }) => {
      const el = s.list.find(e => e.elementId === params.elementId);
      if (el) {
        const signal = el.signals.find(x => x.name === params.name);
        signal.disabled = params.action === 'disable';
      }
    },
  })
  /* Signals importer */
  .post({
    action: 'importSignals',
    path: ({ plantId, elementId }) => `/v3/plant/${plantId}/element/${elementId}/signals/import`,
  })
  .get({
    action: 'getSignalsImport',
    path: ({
      plantId,
      elementId,
      importId,
    }) => `/v3/plant/${plantId}/element/${elementId}/signals/import/${importId}`,
  })
  .get({
    action: 'exportSignals',
    path: ({
      plantId,
      elementId,
    }) => `/v2/plant/${plantId}/element/${elementId}/signals/export?outputType=csv`,
  })
  .get({
    action: 'exportLGConfig',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/gate/config/signals/element/${elementId}`,
  })
  .getStore();

export default store;
