export default {
  orders: '订单',
  order: '订单',
  import: '输入订单',
  completedOrders: '已完成订单',
  inProgressOrders: '在制订单',
  start: '开始',
  saveAndActivate: '保存并激活',
  orderDetails: '订单详细信息',
  activate: '启用',
  stop: '停止',
  end: '结束',
  show: '显示命令',
  startOrder: '开始命令',
  confirmCurrentProduction: '确认生产',
  activateOrder: '激活命令',
  abortOrderActivate: '中止激活',
  selectOrder: '选择命令',
  selectLineAndSku: '选择产线和产品',
  skuPerformance: '产品表现',
  abort: '中止',
  status: '状态',
  number: '数字',
  orderNumber: '订单号',
  skuId: '产品编号',
  orderId: '订单编号',
  item: '物品',
  sku: '产品',
  itemNo: '项目编号',
  schedule: '日程',
  scheduled: '预定',
  plannedProduction: '计划生产',
  realisation: '实现',
  expectedStart: '预计开始',
  expectedEnd: '预期结束',
  toPlannedEnd: '到计划结束',
  toPlannedStart: '计划开始',
  afterPlannedEnd: '计划结束后',
  afterPlannedStart: '计划开始后',
  expectedPerformance: '预期表现',
  description: '描述',
  quantity: '出品',
  limitExceeded: '超出命令限制，请停止或完成任何正在运行的订单',
  production: '生产概要',
  wasted: '浪费',
  updateTotalProduction: '更新总产量：',
  productionUpdates: '生产更新',
  complete: '完成的',
  hold: '保留的',
  completed: '完成的',
  planned: '计划的',
  lineConfig: '产线配置',
  config: '配置',
  additional: '额外的',
  machinesForOrder: '为该作业选择机器',
  requiredComponentsNotScanned: '有些组件需要先扫描',
  firstActivate: '在开始之前需要一些组件。 激活命令，先扫描组件',
  productionStats: '生产',
  comment: {
    startOrder: '订单将立即开始。 所有扫描的包装将被激活', completeOrder: '订单将完成，无法再次开始', stopOrder: '订单将被停止，稍后可以重新开始', abortOrderActivate: '激活将被中止，订单状态将变回“准备就绪”',
  },
  states: {
    inProgress: '进行中', ready: '等待', completed: '完全的', other: '其他', activated: '活跃的', onHold: '保留的',
  },
  activities: {
    addActivity: '计划新活动', editActivity: '编辑活动', changeover: '换线', order: '订单', cip: '在位清洗', completedJob: '完成的工作', inProgressJob: '进行中的工作',
  },
  add: '添加命令',
  create: '创建命令',
  new: '新命令',
  edit: '编辑命令',
  orderedQuantity: '计划数量',
  typeCorrectQuantity: '计划数量必须大于零',
  quantityUnit: '数量单位',
  type: '类型',
  loadingItems: '正在加载项目..',
  manage: '管理订单',
  noActiveOrPlannedOrders: '没有活动或计划的订单',
  noOrders: '没有订单',
  orderStarted: '订单已开始',
  manualProductionAdded: '添加了手动生产',
  manualProductionOverwritten: '手动生产被覆盖',
};
