import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const cameras = new Vapi({
  axios,
  state: {
  },
})
  .post({
    action: 'createCamera',
    path: () => 'v2/vision/camera',
  })
  .put({
    action: 'updateCamera',
    path: ({ cameraId }) => `v2/vision/camera/${cameraId}`,
  })
  .delete({
    action: 'deleteCamera',
    path: ({ cameraId }) => `v2/vision/camera/${cameraId}`,
  })
  .getStore({
    namespaced: true,
  });

export default cameras;
