<template>
  <div
    v-show="haveLog || editMode"
    class="container-fluid timeline-container"
    :class="{
      'drop-not-available': dropNotAvailable
    }"
    @mouseover="handleMouseOverLine"
  >
    <div
      v-if="topBorder"
      class="timeline-separator timeline-separator-top"
    />
    <div class="timeline-separator" />

    <div class="row">
      <div
        class="col-12 col-sm-4 col-md-3 col-lg-2
          py-1 py-sm-0 mt-1 mt-sm-0"
      >
        <div
          class="element-name d-flex align-items-center pt-3"
          style="font-size: large; cursor: pointer"
          @click="emitDisplayToggle"
        >
          <i
            class="fa fa-chevron-down collapse-toggle mr-2"
            :class="{'full': isDisplayFull}"
          />
          <span v-if="lineId">{{ lineName }}</span>
          <span v-else>{{ $t('activities.noLine') }}</span>
        </div>
      </div>
      <div
        class="col-12 col-sm-8 col-md-9 col-lg-10 offset-md-0 order-3 order-md-2"
      >
        <ScheduleLog
          id="schedule-log"
          :start-date="startDate"
          :end-date="endDate"
          :display-axis="false"
          :log="isDisplayFull ? [] : activitiesByLine"
          :hide-tooltip="isMobile"
          :height="30"
          :editable="false"
          :activities-edit-mode="editMode"
          :external-hovered="hoverBlock"
          show-completion-line
          big-padding
          show-background
          activities-planned-hovering
          :schedule-log-index="scheduleLogIndex"
          :no-line-activities-log-index="noLineActivitiesIndex"
          :hover-schedule-log-index="hoverScheduleLogIndex"
          :root-line-ids="rootLineIds"
          :is-editing="isEditing"
          @edit-state:update="s => editState = s"
          @edit="$emit('setModal', $event)"
          @hover="$emit('hover', $event)"
          @saving="$emit('saving')"
          @saved="$emit('saved', $event)"
        >
          <template #default="item">
            <ActivityPopover
              :activity="item.block"
              class="text-left"
            />
          </template>
        </ScheduleLog>
      </div>
    </div>
    <div
      v-if="display === 'full'"
    >
      <div
        v-for="(log, index) in activitiesByLine"
        :key="log.id"
        class="row"
      >
        <div
          class="order-name col-12 col-sm-4 col-md-3 col-lg-2 d-flex d-sm-block
          justify-content-center py-1 py-sm-0 mt-1 mt-sm-0 pr-0 pl-4"
          @click="$emit('setModal', log)"
        >
          <span class="d-block w-100">
            {{ log.externalId }}
          </span>

          <ActivityStatusBadge
            style="font-size: 10px"
            :status="log.status"
          />
        </div>

        <div
          class="col-12 col-sm-8 col-md-9 col-lg-10 offset-md-0 order-3 order-md-2"
        >
          <ScheduleLog
            :id="`schedule-log-inside_${log.id}`"
            :start-date="startDate"
            :end-date="endDate"
            :display-axis="false"
            :log="[log]"
            :all-log="activitiesByLine.map(prepareLog)"
            :height="30"
            :editable="false"
            :activities-edit-mode="editMode"
            overlap-conflicts
            show-completion-line
            show-background
            activities-planned-hovering
            :no-line-activities-log-index="noLineActivitiesIndex"
            :schedule-log-index="scheduleLogIndex"
            :hover-schedule-log-index="hoverScheduleLogIndex"
            :padding-bottom="index === activitiesByLine.length - 1"
            :root-line-ids="rootLineIds"
            :is-editing="isEditing"
            @edit="$emit('setModal', log)"
            @saving="$emit('saving')"
            @saved="$emit('saved', $event)"
          >
            <ActivityPopover
              :activity="log"
              class="text-left"
            />
          </ScheduleLog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ScheduleLog from '@/components/schedule/ScheduleLog';
import ActivityPopover from '@/components/schedule/popovers/ActivityPopover';
import ActivityStatusBadge from '@/components/activity/ActivityStatusBadge';
import { activityStatus } from '@/utils/dictionary';
import { sortActivitiesStatus } from '@/components/schedule/conflicts';

export default {
  provide() {
    return {
      editState: this.editState,
    };
  },
  props: {
    scheduleLogIndex: {
      type: Number,
      default: 0,
    },
    hoverScheduleLogIndex: {
      type: Number,
      default: 0,
    },
    activities: {
      type: Array,
      required: true,
    },
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      type: Number,
      required: true,
    },
    lineId: {
      type: String,
      required: false,
    },
    display: String,
    hoverBlock: Object,
    editMode: {
      type: Boolean,
      default: false,
    },
    noLineActivitiesIndex: {
      type: Number,
      default: -1,
    },
    topBorder: {
      type: Boolean,
      default: false,
    },
    isLineAvailableForDrop: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    rootLineIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editState: { current: null },
    productIdForLinesCheck: null,
  }),
  components: {
    ScheduleLog,
    ActivityPopover,
    ActivityStatusBadge,
  },
  computed: {
    collapsed() {
      return this.display === 'collapsed';
    },
    haveLog() {
      if (this.noLineActivitiesIndex === this.scheduleLogIndex) return this.activities.some(l => !l.lineId);
      return this.activities
        .some(l => l.lineId === this.lineId);
    },
    ...mapGetters('element', [
      'elementName',
      'image',
    ]),
    ...mapGetters([
      'getActivityState',
      'getActivityType',
      'isMobile',
      'plantId',
    ]),
    utcOffset() {
      return moment()
        .utcOffset() * 60;
    },
    lineName() {
      if (this.noLineActivitiesIndex === this.scheduleLogIndex) return this.$t('activities.noLine');
      return this.elementName(this.lineId);
    },
    dropNotAvailable() {
      return this.isEditing && !this.isLineAvailableForDrop;
    },
    activitiesByLine() {
      let activities = [];
      if (this.noLineActivitiesIndex === this.scheduleLogIndex) {
        activities = this.activities
          .filter(l => !l.lineId);
      } else {
        activities = this.activities
          .filter(l => l.lineId === this.lineId);
      }

      return activities
        .map(l => this.prepareLog(l))
        .filter(l =>
          (l.end >= this.startDate && (l.end <= this.endDate || !l.end))
          || (l.start >= this.startDate && l.start <= this.endDate)
          || (l.start <= this.startDate && (l.end >= this.endDate || !l.end))).sort(sortActivitiesStatus);
    },
    isDisplayFull() {
      return this.display === 'full';
    },
  },
  watch: {
    editState: {
      deep: true,
      handler(v) {
        if (v.current) {
          if (this.productIdForLinesCheck !== v.current.id) this.productIdForLinesCheck = v.current.productId;
          this.$emit('edit-started');
        } else {
          this.productIdForLinesCheck = null;
          this.$emit('edit-end');
        }
      },
    },
    async productIdForLinesCheck(v) {
      if (v) {
        const details = await this.requestSkuDetails(v);
        const lineIds = details.lines.map(l => l.lineId);
        this.$emit('update-droppable-line-ids', lineIds);
      }
      if (this.isEditing) return;
      this.$emit('update-droppable-line-ids', []);
    },
  },
  methods: {
    ...mapActions(['getSku']),
    prepareLog(log) {
      return {
        ...log,
        start: this.getLogStart(log),
        end: this.getLogEnd(log),
        color: this.getActivityState(log).color,
        icon: this.getActivityType(log).icon,
        externalId: log.externalId,
        uuid: log.id,
        id: log.id,
        name: log.externalId,
      };
    },
    async requestSkuDetails(skuId) {
      try {
        this.$emit('loading-droppable-line-ids');
        const { data } = await this.getSku({
          params: {
            plantId: this.plantId,
            skuId,
            query: {
              withInherited: true,
            },
          },
        });
        return data;
      } catch {
        this.$bvToast.toast(this.$t('error.unableToDownloadData'), {
          title: this.$t('error.error'),
          autoHideDelay: 3000,
          variant: 'error',
        });
        return null;
      }
    },
    getLogStart(log) {
      switch (log.status) {
        case activityStatus.started:
        case activityStatus.completed:
          return moment(log.actualExecution?.begin)
            .unix();
        case activityStatus.activated:
          if (moment(log.scheduledExecution?.begin)
            .unix() > this.$now) {
            return moment(log.scheduledExecution?.begin)
              .unix();
          }
          return this.$now;
        default:
          return moment(log.scheduledExecution?.begin)
            .unix();
      }
    },
    getLogEnd(log) {
      const logDuration = moment(log?.scheduledExecution?.end)
        .unix()
        - moment(log?.scheduledExecution?.begin)
          .unix();
      const localEstimatedEnd = !Number.isNaN(logDuration)
        ? new Date((moment((
          log?.actualExecution?.begin
          || log?.scheduledExecution?.begin
        ))
          .unix() + logDuration) * 1000)
        : null;

      switch (log.status) {
        case activityStatus.started:
        case activityStatus.activated:
          if (
            moment(log.estimatedEnd || localEstimatedEnd || log.scheduledExecution?.end)
              .unix() < this.$now
            || (log.estimatedEnd || localEstimatedEnd || log.scheduledExecution?.end) === null
          ) {
            return this.$now;
          }
          return moment(log.estimatedEnd || localEstimatedEnd || log.scheduledExecution?.end)
            .unix();
        case activityStatus.completed:
          return moment(log.actualExecution?.end)
            .unix();
        default:
          return moment(log.scheduledExecution?.end)
            .unix();
      }
    },
    emitDisplayToggle() {
      this.$emit('toggle:displayType');
    },
    handleMouseOverLine(event) {
      if (event.target.classList.contains('progress')) this.$emit('handle-mouse-over-line', this.lineId);
    },
  },
};

</script>

<style lang="scss" scoped>
  .line-picture {
    max-height: 40px;
    max-width: 70px
  }

  .drop-not-available {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .collapse-toggle {
    cursor: pointer;
    font-size: 14px;
    transition: 0.3s transform;
    color: #ABABAB;

    &.full {
      transform: rotate(-180deg);
    }
  }

  .timeline-container {
    position: relative;
  }

  .order-name {
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .timeline-separator-top {
    top: 0;
    bottom: unset;
  }
</style>
