<template>
  <div style="position: relative;">
    <div class="row header-row">
      <div class="col-12 text-center col-sm-4 col-md-2 position-relative">
        <div class="d-flex justify-content-around align-items-center">
          <div class="flex-grow-1 pb-2">
            <h2 class="line-name mb-0 text-fancy">
              {{ name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-4 col-md-2 order-3 order-sm-3 order-md-1 text-center d-flex">
        <img
          :src="`${image(id)}`"
          class="line-picture align-self-center"
        >
      </div>
      <div
        class="col-12 col-sm-8 col-md-7 text-center order-1 order-sm-2 d-flex"
      >
        <MonitorSignals
          :meta="meta"
          :name="name"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-1 order-4"
        style="padding: 0"
      >
        <LineActions
          :line-id="id"
          :current-state="currentState"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { addSpaces } from '@core/utils/numbers';
import colors from '@/utils/colors';
import LineActions from '@/components/line/modules/LineActions';
import CalcDuration from '@/utils/calcDuration';
import MonitorSignals from '@/components/element/MonitorSignals';

export default {
  props: {
    id: String,
    name: String,
    oees: Object,
    paretoStates: Array,
    currentState: Object,
    meta: Object,
    pending: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MonitorSignals,
    LineActions,
  },
  computed: {
    ...mapGetters(['plantId', 'screenWidth']),
    ...mapGetters('element', ['image']),
    ...mapGetters('plant', ['displayEnergy']),
    loading() {
      return !this.meta && this.pending;
    },
  },
  methods: {
    colors,
    CalcDuration,
    addSpaces,
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .header-row {
    margin: 0;
    padding: 10px 0;
    border-top: 5px solid rgba(180, 180, 180, 0.1);
  }

  .line-picture {
    max-height: 100px;
    max-width: 100px;
    height: auto;
    margin: auto;
  }

  .line-name {
    margin: 10px -5px auto -5px;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
  }

  @media (min-width: 1000px) {
    .line-picture {
      max-width: 120px;
    }
  }

</style>
