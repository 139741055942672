import Vapi from 'vuex-rest-api';
import Vue from 'vue';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    list: [],
  },
})
  .get({
    action: 'getHintsJobs',
    property: 'list',
    path: 'v2/hints/jobs',
  })
  .post({
    action: 'addHintJob',
    path: 'v2/hints/jobs',
    onSuccess: (s, { data }) => {
      s.list.push(data);
    },
  })
  .put({
    action: 'updateHintJob',
    path: ({ id }) => `v2/hints/jobs/${id}`,
    onSuccess: (s, { data }) => {
      const idx = s.list.findIndex(x => x.id === data.id);
      Vue.set(s.list, idx, data);
    },
  })
  .put({
    action: 'activateHintJob',
    path: ({ id }) => `v2/hints/jobs/${id}/activate`,
    onSuccess: (s, { data }) => {
      const idx = s.list.findIndex(x => x.id === data.id);
      Vue.set(s.list, idx, data);
    },
  })
  .put({
    action: 'deactivateHintJob',
    path: ({ id }) => `v2/hints/jobs/${id}/deactivate`,
    onSuccess: (s, { data }) => {
      const idx = s.list.findIndex(x => x.id === data.id);
      Vue.set(s.list, idx, data);
    },
  })
  .delete({
    action: 'removeHintJob',
    path: ({ id }) => `v2/hints/jobs/${id}`,
    onSuccess: (s, _, a, req) => {
      s.list = s.list.filter(x => x.id !== req.params.id);
    },
  })
  .put({
    action: 'runHintJob',
    path: ({ id }) => `v2/hints/jobs/${id}/run`,
  })
  .getStore();

export default store;
