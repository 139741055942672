<template>
  <div>
    <div class="manhours-summary py-3 px-4 m-3 mb-4">
      <div
        class="row"
        style="font-weight: 700"
      >
        <div class="col-6">
          {{ $t('activities.resources.workers') }}
        </div>
        <div class="col-3 text-right">
          {{ $t('activities.resources.hours') }} : {{ $t('activities.resources.minutes') }}
        </div>
      </div>
      <hr>
      <div
        v-for="(worker, index) in workerList"
        :key="index"
        class="row good-text"
        style="line-height: 2"
      >
        <div class="col-6">
          <i class="fas fa-user mx-2" />
          {{ worker.name || `${$t('activities.resources.worker')} ${index}` }}
        </div>
        <div class="col-3 text-right">
          {{ worker.duration }}
        </div>
      </div>
      <div
        v-if="!workerList.length"
        class="row good-text"
      >
        <div class="col-6">
          {{ $t('general.noResults') }}
        </div>
      </div>
      <hr>
      <div class="row total-style">
        <div class="col-6">
          {{ $t('general.total') }} :
        </div>
        <div class="col-3 text-right">
          {{ Math.floor(totalManhours / 60) }} : {{ totalManhours - Math.floor(totalManhours / 60) * 60 }}
        </div>
      </div>
    </div>
    <hr>

    <ManualResourceEntries
      :history-pending="pending"
      :activity-id="activityId"
      :activity-start="scopeStart"
      :entries="entries"
      :resource-type="resourceType.key"
      @removed="getEntries"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { manualResourceType } from '@/utils/dictionary';
import { durationToMinutes, minutesToDuration } from '@/utils/time';
import ManualResourceEntries from './ManualResourceEntries';

export default {
  props: {
    activityId: {
      type: String,
      required: true,
    },
    scopeStart: {
      type: String,
      required: true,
    },
    humanResources: Boolean,
  },
  data: () => ({
    entries: [],
    pending: false,
  }),
  components: {
    ManualResourceEntries,
  },
  computed: {
    ...mapGetters(['plantId']),
    resourceType() {
      return [
        {
          key: manualResourceType.humanResources,
          name: this.$t('activities.resources.manhours'),
          icon: 'fas fa-male',
        },
      ];
    },
    workerList() {
      const pastEntries = this.entries
        .filter(x => x.isActive && x.type === manualResourceType.humanResources)
        .flatMap(x => x.manhours)
        .reduce((acc, curr) => {
          acc[curr.workerCode] = (acc[curr.workerCode] || 0) + curr.minutes;
          return acc;
        }, {});

      return Object.entries(pastEntries).map(([key, value]) => ({
        name: key,
        duration: minutesToDuration(value || 0),
      }));
    },
    totalManhours() {
      return this.workerList
        .reduce((acc, curr) => acc + durationToMinutes(curr.duration), 0);
    },
  },
  methods: {
    ...mapActions(['getActivityManualResources']),
    getEntries() {
      this.pending = true;

      this.getActivityManualResources({
        params: {
          plantId: this.plantId,
          activityId: this.activityId,
        },
      })
        .then(({ data }) => {
          this.entries = data.filter(x => x.type === manualResourceType.humanResources);
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.getEntries();
  },
};
</script>

<style lang="scss" scoped>
.manhours-summary {
  box-shadow: 0 0 5px rgba(150, 150, 150, 0.2), 0 0 25px rgba(150, 150, 150, 0.2);
  background-color: white;
  border-radius: 20px;
  margin: 10px;

}

.good-text {
  opacity: 0.85;
  color: gray;
  font-weight: 500;
}

.total-style {
  font-weight: 700;
  font-size: 1.1em;
}
</style>
