<template>
  <div class="text-left">
    <div v-if="manualWorkLog.produced">
      <div class="label font-weight-light">
        {{ $t('monitoring.produced') }}:
      </div>
      <div class="title">
        {{ manualWorkLog.produced }} {{ unit }}
      </div>
    </div>
    <div v-if="manualWorkLog.wasted">
      <div class="label font-weight-light">
        {{ $t('monitoring.wasted') }}:
      </div>
      <div class="title">
        {{ manualWorkLog.wasted }} {{ unit }}
      </div>
    </div>

    <hr
      v-if="manualWorkLog.userName "
      class="my-1"
    >
    <div v-if="manualWorkLog.userName ">
      <div class="label font-weight-light">
        {{ $t('menu.user') }}:
      </div>
      <div class="title">
        {{ manualWorkLog.userName }}
      </div>
    </div>

    <hr class="my-1">
    <div class="dates">
      <div>
        <div class="date-title">
          {{ $t('orders.realisation') }}:
        </div>
        <span>
          <i class="far fa-calendar-check pr-1" />
          {{ manualWorkLog.from | formatDate }} -
          {{ manualWorkLog.to | formatDate }}
        </span>
      </div>
    </div>

    <button
      v-if="deletable"
      class="btn btn-secondary btn-sm icon-btn right mt-2"
      type="button"
      @click="remove"
    >
      <slot>
        <i class="ion ion-md-trash" />
      </slot>
    </button>

    <Error
      v-if="error"
      class="mt-2"
      :message="error.detail"
    />

    <Loader
      v-if="pending"
      class="mt-2"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { productionHistoryType } from '@/utils/dictionary';

export default {
  props: {
    manualWorkLog: Object,
    deletable: Boolean,
    unit: String,
  },
  data() {
    return {
      productionHistoryType,
      error: null,
      pending: false,
    };
  },
  filters: {
    formatDate(d) {
      if (!d) return '...';
      return moment(d).format('Do MMM HH:mm');
    },
  },
  computed: {
    ...mapGetters(['plantId']),
  },
  methods: {
    ...mapActions(['removeManualProduction']),
    remove() {
      this.pending = true;
      this.error = null;

      this.removeManualProduction({
        params: {
          plantId: this.plantId,
          orderId: this.manualWorkLog.orderId,
          manualProductionId: this.manualWorkLog.id,
        },
      })
        .then(() => {
          this.$emit('removed', this.manualWorkLog.id);
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        })
        .finally(() => { this.pending = false; });
    },
  },
};
</script>

<style scoped>
  .title {
    text-align: left;
    font-size: 14px;
    display: inline-block;
  }
  .date-title {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
  }
  .dates {
    font-weight: 400;
  }
  .label {
    margin-right: 10px;
    display: inline-block;
  }
</style>
