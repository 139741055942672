<template>
  <Apexcharts
    ref="chart"
    type="bar"
    :options="options"
    :series="series"
    :height="height"
  />
</template>

<script>
import Apexcharts from 'vue-apexcharts';
import calcDuration from '@/utils/calcDuration';

export default {
  props: {
    columns: Array,
    sortBy: String,
    hovered: Number,
    height: {
      type: [Number, String],
      default: 500,
    },
    noToolbar: Boolean,
    hideQuantity: Boolean,
    groupByState: Boolean,
  },
  components: {
    Apexcharts,
  },
  computed: {
    pareto() {
      let sum = 0;

      return this.columns.map(({ durationRatio, quantityRatio }) => {
        if (this.sortBy === 'quantity') {
          sum += quantityRatio;
        } else {
          sum += durationRatio;
        }
        return sum;
      });
    },
    labels() {
      return this.columns.map(({ label }) => label);
    },
    series() {
      return [
        {
          name: this.$t('time.duration'),
          type: 'column',
          data: this.columns.map(x => (this.sortBy === 'quantity' ? -x.durationRatio : x.durationRatio)),
        },
        ...(this.hideQuantity ? [] : [{
          name: this.$t('reports.quantity'),
          type: 'column',
          data: this.columns.map(x => (this.sortBy === 'quantity' ? x.quantityRatio : -x.quantityRatio)),
        }]),
        {
          name: 'Pareto',
          type: 'line',
          data: this.pareto,
        },
      ];
    },
    options() {
      if (this.columns.length === 0) return {};
      const ref = this.columns[0];
      const durationScale = ref.duration / (ref.durationRatio || 1);
      const quantityScale = ref.quantity / (ref.quantityRatio || 1);

      const yFormatter = val => {
        if (val === 0) return 0;
        const v = this.quantity ? -val : val;

        if (v < 0) {
          return `x${Math.floor(quantityScale * (-v))}`;
        }
        const time = Math.round(v * durationScale);
        return calcDuration(1, time + 1);
      };

      return {
        chart: {
          id: `worstsBar${this.$uId}`,
          type: 'line',
          stacked: true,
          toolbar: {
            show: !this.noToolbar,
          },
        },
        legend: {
          position: 'top',
        },
        colors: ['#F44336', '#E91E63', '#9C27B0'],
        fill: {
          colors: ['#F44336', '#E91E63', '#9C27B0'],
        },
        annotations: {
          yaxis: [{
            y: 80,
            borderColor: '#333',
            label: {
              borderColor: '#333',
              style: {
                color: '#fff',
                background: '#333',
              },
              text: '80%',
            },
          }],
          xaxis: [
            {
              x: this.labels[this.pareto.findIndex(x => x >= 80)],
              borderColor: '#333',
              label: {
                borderColor: '#333',
                style: {
                  color: '#fff',
                  background: '#333',
                },
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            left: 50,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter(val) {
              return val;
            },
          },
          y: [
            {
              formatter(val) {
                let percent = val;
                if (percent < 1 && percent > -1 && percent !== 0) {
                  percent = '<1';
                } else {
                  percent = Math.floor(Math.abs(val));
                }
                return `${yFormatter(val)} (${percent}%)`;
              },
            },
            {
              formatter(val) {
                let percent = val;
                if (percent < 1 && percent > -1 && percent !== 0) {
                  percent = '<1';
                } else {
                  percent = Math.floor(Math.abs(val));
                }
                return `${yFormatter(val)} (${percent}%)`;
              },
            },
            {
              title: {
                formatter: seriesName => {
                  const title = this.quantity ? this.$t('reports.quantity') : this.$t('time.duration');
                  return `${seriesName} (${title})`;
                },
              },
              formatter: val => `${Math.floor(Math.abs(val))}%`,
            },
          ],
        },
        xaxis: {
          categories: this.labels,
          labels: {
            trim: true,
            showDuplicates: true,
            hideOverlappingLabels: false,
            maxHeight: this.height / 3,
          },
        },
        yaxis: [
          {
            max: 100,
            min: this.hideQuantity ? 0 : -100,
            labels: {
              offsetX: 35,
              formatter: yFormatter,
            },
          },
          ...(this.hideQuantity ? [] : [
            {
              opposite: true,
              max: 100,
              min: -100,
              labels: {
                formatter: val => `${Math.abs(Math.round(val))}%`,
              },
            },
            {
              opposite: true,
              show: false,
              max: 100,
              min: -100,
            }]),
        ],
      };
    },
  },
  watch: {
    hovered(n) {
      if (!this.$refs.chart) return;
      let series = 0;
      if (this.quantity) {
        series = 1;
      }
      if (n || n === 0) {
        this.$refs.chart.toggleDataPointSelection(series, n);
      }
    },
  },
};
</script>
