import annotations from './annotations';
import correctiveActions from './correctiveActions';
import eventrules from './eventrules';
import workevents from './workevents';
import worklogs from './worklogs';
import workreasons from './workreasons';
import workstates from './workstates';
import worktags from './worktags';

export default {
  namespaced: true,
  modules: {
    annotations,
    correctiveActions,
    eventrules,
    workevents,
    worklogs,
    workreasons,
    workstates,
    worktags,
  },
};
