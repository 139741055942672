import { mapActions, mapGetters } from 'vuex';
import loopRequest from '@/mixins/loopRequest';

export default {
  props: {
    elementIds: {
      type: Array,
      default: () => [],
    },
    signalIds: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [loopRequest('request', 10000)],
  computed: mapGetters(['plantId']),
  methods: {
    ...mapActions('element', ['getElementsSignals']),
    request() {
      return this.getElementsSignals({
        params: {
          plantId: this.plantId,
          query: {
            id: this.elementIds,
            sId: this.signalIds,
          },
        },
      })
        .then(({ data }) => {
          const currentValues = {};
          data
            .flatMap(x => x.signals)
            .forEach(prop => {
              this.$set(currentValues, prop.internalId, prop);
            });

          this.$emit('currentValues', currentValues);
        });
    },
  },
  render: () => true,
};
