import cache from './cache';
import graphs from './graphs';
import structure from './structure';
import shifts from './shifts';
import properties from './properties';

export default {
  namespaced: true,
  state: {
    isLoaded: false,
  },
  modules: {
    cache,
    graphs,
    shifts,
    structure,
    properties,
  },
  getters: {
    plant: (s, g, rs, rg) => id => rg.plants.find(p => p.id === id),
    plantLoaded: s => s.isLoaded,
    // TODO: #10596
    // productionFromOrders: (s, g, rs) => rs.monitoring.ordersProduction,
    productionFromOrders: () => false,
  },
  mutations: {
    isLoaded(s, v) {
      s.isLoaded = v;
    },
  },
};
