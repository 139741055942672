import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .post({
    action: 'addWorkReason',
    path: ({ plantId, workEventId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/reason`,
  })
  .put({
    action: 'updateWorkReason',
    path: ({ plantId, workEventId, reasonId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/reason/${reasonId}`,
  })
  .delete({
    action: 'removeWorkReason',
    path: ({ plantId, workEventId, reasonId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/reason/${reasonId}`,
  })
  .getStore();

export default store;
