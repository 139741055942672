<template>
  <div>
    <div class="row text-center">
      <div class="col-12 col-sm-6">
        <div
          :class="{ 'active' : selectedUser}"
          class="card hoverable m-1"
          @click="selectedUser = true"
        >
          <div
            class=""
            style="font-size: 70px"
          >
            <i class="fas fa-user" />
          </div>
          <div class="card-body">
            <h5 class="card-title">
              {{ $t('menu.homePageUser.title') }}
            </h5>
            <p class="card-text">
              {{ $t('menu.homePageUser.description') }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div
          :class="{ 'active' : !selectedUser}"
          class="card hoverable m-1"
          @click="selectedUser = false"
        >
          <div
            class=""
            style="font-size: 70px"
          >
            <i class="fas fa-laptop" />
          </div>
          <div class="card-body">
            <h5 class="card-title">
              {{ $t('menu.homePageDevice.title') }}
            </h5>
            <p class="card-text">
              {{ $t('menu.homePageDevice.description') }}
            </p>
          </div>
        </div>
        <button
          v-if="deviceHome"
          class="btn btn-danger btn-sm mt-2"
          style="font-size: 1em"
          @click="removeDeviceHome"
        >
          <i class="fas fa-times" /> {{ $t('general.remove') }}
        </button>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between">
      <button
        class="btn btn-secondary"
        style="font-size: 1em"
        @click="$emit('hideModal')"
      >
        <i class="ion ion-md-close pr-2" />
        {{ $t('general.cancel') }}
      </button>
      <button
        class="btn btn-ilabo"
        @click="save"
      >
        <i class="ion ion-md-checkmark pr-2" />
        {{ $t('general.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      selectedUser: true,
      deviceHome: null,
    };
  },
  methods: {
    ...mapActions('core', ['setUserSetting']),
    save() {
      this.$emit('hideModal');
      if (this.selectedUser) {
        this.saveUserHome();
      } else {
        this.setDeviceHome();
        this.deviceHome = localStorage.getItem('home');
      }
    },
    saveUserHome() {
      const path = this.$router.currentRoute.fullPath;
      this.setUserSetting({
        data: {
          key: 'home',
          value: path,
          scope: 'packos',
        },
      });
    },
    setDeviceHome() {
      const path = this.$router.currentRoute.fullPath;
      localStorage.setItem('home', path);
    },
    removeDeviceHome() {
      localStorage.removeItem('home');
      this.deviceHome = null;
    },
  },
  created() {
    this.deviceHome = localStorage.getItem('home');
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .card.hoverable {
    cursor: pointer;
  }

  .card.hoverable:hover {
    background-color: rgba($ilabo, 0.2);
  }

  .card.hoverable.active {
    background-color: rgba($ilabo, 0.2);
    box-shadow: 0 0 7px rgba(black, 0.8);
  }
</style>
