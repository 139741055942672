import Vue from 'vue';
import moment from 'moment-timezone';
import { hub, notify } from '@core/api/axios';
import {
  PACKOS_ELEMENT_ACCESS,
  PACKOS_MATERIAL_MANAGEMENT,
  PACKOS_ORDER_EDIT,
  PACKOS_ORDER_LIFECYCLE,
  PACKOS_ORDER_MANAGEMENT,
  PACKOS_ORDER_VIEW,
  PACKOS_PLANT_ADMIN,
  PACKOS_REPORT_ACCESS,
  PACKOS_SKU_EDIT,
  PACKOS_SKU_LIST,
} from '@core/dict/permissions';
import { auth, packos, workBoost } from '@/api/axios';

import i18n from '@/lang';
import * as types from '../mutation-types';

const mobileBreakpoint = 768;
const tabletBreakpoint = 992;

const state = {
  timezone: moment.tz.guess(),
  plantId: null,
  apiUrl: null,
  hubUrl: null,
  socketUrl: null,
  screenWidth: null,
  screenHeight: null,
  pageLoading: false,
  serviceWorker: null,
  modules: [],
  ready: false,
};

const mutations = {
  [types.SET_CONFIG](s, config) {
    Object.keys(config).forEach(key => {
      Vue.set(s, key, config[key]);
      if (key === 'apiUrl') {
        packos.defaults.baseURL = `${config[key]}/api`;
      } else if (key === 'hubUrl') {
        hub.defaults.baseURL = `${config[key]}/api`;
      } else if (key === 'notifyUrl') {
        notify.defaults.baseURL = `${config[key]}/api`;
      } else if (key === 'authUrl') {
        auth.defaults.baseURL = `${config[key]}/api/v1`;
      }
    });
  },
  setPlantId(s, id) {
    s.plantId = id;
    packos.defaults.headers.common['Plant-Id'] = id;
    notify.defaults.headers.common['Plant-Id'] = id;
  },
  setScreenWidth(s, v) {
    s.screenWidth = v;
  },
  setScreenHeight(s, v) {
    s.screenHeight = v;
  },
  setReady(s, v) {
    s.ready = v;
  },
  pageLoading(s, value) {
    s.pageLoading = value;
  },
  registerServiceWorker(s, registration) {
    s.serviceWorker = registration;
  },
  setModules(s, modules) {
    s.modules = modules;

    s.modules.forEach(m => {
      if (m.module.toLowerCase() === 'workboost') {
        workBoost.defaults.baseURL = `${m.url}/api`;
      }
    });
  },
};
const actions = {
  changePlant(ctx, plantId) {
    ctx.commit('setPlantId', plantId);
    ctx.commit('plant/clearElementsCache');
    ctx.commit('clearSkus');
    ctx.commit('plant/isLoaded', false);
  },
};
const getters = {
  isReady: s => s.ready,
  plantId: s => s.plantId,
  timezone: s => s.timezone,
  publicPath: () => process.env.VUE_APP_PUBLIC_PATH,
  hubUrl: s => s.hubUrl,
  tenantModules: s => s.modules,
  workBoostUrl: (_, g) => {
    const wb = g.tenantModules.find(x => x.module.toLowerCase() === 'workboost');
    return wb ? wb.url : null;
  },
  isMobile: s => s.screenWidth <= mobileBreakpoint,
  isTablet: s => s.screenWidth <= tabletBreakpoint,
  screenWidth: s => s.screenWidth,
  screenHeight: s => s.screenHeight,
  pageLoading: s => s.pageLoading,
  sidebar: (_, g) => [
    {
      key: 'monitoring',
      text: i18n.t('menu.monitoring'),
      icon: 'fas fa-eye',
      route: `/${g.plantId}/dashboard/lines`,
      permittedFor: [PACKOS_ELEMENT_ACCESS],
    },
    {
      key: 'reports',
      text: i18n.t('menu.reports'),
      icon: 'fas fa-chart-column',
      route: `/${g.plantId}/reports`,
      permittedFor: [PACKOS_REPORT_ACCESS],
    },
    {
      key: 'activities',
      text: `${i18n.t('menu.activities')} (${i18n.t('orders.orders')})`,
      icon: 'fas fa-calendar-day',
      route: `/${g.plantId}/activities`,
      permittedFor: [PACKOS_ORDER_MANAGEMENT, PACKOS_ORDER_VIEW, PACKOS_ORDER_LIFECYCLE, PACKOS_ORDER_EDIT],
    },
    {
      key: 'items',
      text: i18n.t('items.items'),
      icon: 'icon-sku',
      route: `/${g.plantId}/items`,
      permittedFor: [PACKOS_MATERIAL_MANAGEMENT, PACKOS_SKU_LIST, PACKOS_SKU_EDIT],
    },
    {
      key: 'settings',
      text: i18n.t('menu.settings'),
      icon: 'fas fa-cog',
      route: `/${g.plantId}/settings`,
    },
  ],
  permittedRoutes: (s, g, rs, rg) => g.sidebar.filter(x => !x.permittedFor
    || rg['core/can'](PACKOS_PLANT_ADMIN, rg.plantId)
    || x.permittedFor.some(permission => rg['core/canAnyElementInPlant'](permission, rg.plantId))),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
