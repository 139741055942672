<template>
  <div>
    <div
      v-if="manualSummary"
      class="d-flex justify-content-center"
    >
      <div class="form-area resources-wrapper">
        <div class="form-title">
          <i
            :class="resourceType.icon"
            class="pr-1"
          />
          {{ resourceType.name }}
        </div>
        <hr>
        <ManualProductionForm
          v-if="resourceType.key === manualResourceType.production"
          :activity-id="activityId"
          :unit="orderUnit"
          :start-date="lastEntry"
          :end-date="end"
          :disabled="disabled"
          :incremental="incremental"
          :wasted-sum="orderWaste || 0"
          :done-sum="orderProduction || 0"
          :action-name="actionName"
          @done="done = $event; $emit('prodDone', done)"
          @wasted="wasted = $event; $emit('prodWasted', wasted)"
          @cancel="$emit('cancel')"
          @complete="$emit('complete')"
          @refresh="$emit('refresh', $event)"
        />
      </div>
    </div>
    <div
      v-else
      class="form-wrapper d-flex"
    >
      <div class="time-select-area flex-grow-1">
        <ActivityResourceTimeSelector
          :scope-start="scopeStart"
          :scope-end="scopeEnd || now"
          :blocked-until="lastEntry"
          :line-id="lineId"
          :unit="orderUnit"
          :done="done"
          :wasted="wasted"
          :type="resourceType.key"
          :total-manhours="totalManhours"
          :incremental.sync="incremental"
          @end="end = $event"
        />
      </div>
      <div class="form-area">
        <div class="form-title">
          <i
            :class="resourceType.icon"
            class="pr-1"
          />
          {{ resourceType.name }}
        </div>
        <hr>

        <div v-if="end">
          <ManualProductionForm
            v-if="resourceType.key === manualResourceType.production"
            :activity-id="activityId"
            :unit="orderUnit"
            :start-date="lastEntry"
            :end-date="end"
            :incremental="incremental"
            :wasted-sum="orderWaste || 0"
            :done-sum="orderProduction || 0"
            :action-name="actionName"
            @done="done = $event"
            @wasted="wasted = $event"
            @cancel="$emit('cancel')"
            @refresh="$emit('refresh', $event)"
          />
          <ManhoursForm
            v-else
            :activity-id="activityId"
            :duration="duration"
            :end-date="end"
            :incremental="incremental"
            :action-name="actionName"
            :entries="entries"
            @totalManhours="totalManhours = $event"
            @cancel="$emit('cancel')"
            @refresh="$emit('refresh', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { manualResourceType } from '@/utils/dictionary';
import ActivityResourceTimeSelector from './ActivityResourceTimeSelector';
import ManualProductionForm from './resources/ManualProductionForm';
import ManhoursForm from './resources/ManhoursForm';

export default {
  props: {
    activityId: String,
    scopeStart: String,
    scopeEnd: String,
    entries: Array,
    actionName: String,
    orderProduction: Number,
    orderWaste: Number,
    disabled: Boolean,
    resourceType: {
      type: Object,
      required: true,
    },
    manualSummary: {
      type: Boolean,
      default: false,
    },
    activityType: {
      type: String,
      required: true,
    },
    lineId: {
      type: String,
      required: true,
    },
    orderUnit: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    start: null,
    end: null,
    now: null,
    done: 0,
    wasted: 0,
    incremental: false,
    totalManhours: 0,
    manualResourceType,
  }),
  components: {
    ActivityResourceTimeSelector,
    ManualProductionForm,
    ManhoursForm,
  },
  computed: {
    duration() {
      const start = this.incremental
        ? moment(this.lastEntry).startOf('minute').add(1, 'minute').unix()
        : moment(this.scopeStart).startOf('minute').add(1, 'minute').unix();

      const secDiff = moment(this.end).startOf('minute').unix() - start;

      return Math.max(0, Math.floor(secDiff / 60));
    },
    lastEntry() {
      const lastEntry = this.entries
        .filter(x => x.isActive)
        .filter(x => (this.resourceType.key === 'production'
          ? x.type === manualResourceType.production || x.type === manualResourceType.waste
          : x.type === manualResourceType.humanResources))
        .reduce((acc, curr) => Math.max(moment(curr.to).unix(), acc), 0);

      if (lastEntry > 0) {
        return moment.unix(lastEntry).format();
      }

      return this.scopeStart;
    },
  },
  created() {
    this.now = moment()
      .add(45, 'minutes')
      .format();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .form-wrapper {
    box-shadow: 0 3px 6px #ccc;
    border-radius: 10px;
    background-color: #eee;
    text-align: left;
  }
  .resources-wrapper {
    background-color: #eee;
    padding: 15px 20px;
    box-shadow: 2px 1px 3px #efefef;
    border-radius: 10px;
  }
  .form-area {
    padding: 15px 20px;
    width: 400px;
  }

  .form-title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .time-select-area {
    background-color: white;
    padding: 15px 20px;
    box-shadow: 2px 1px 3px #efefef;
    border-radius: 10px;
  }
</style>
