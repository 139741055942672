export default {
  'activities': 'Aktivitäten',
  'activityType': 'Art der Aktivität',
  'addActivity': 'Neue Aktivität planen',
  'order': 'Auftrag',
  'plannedStart': 'Geplanter Start',
  'plannedEnd': 'Geplantes Ende',
  'cleaning': 'Reinigung',
  'changeover': 'Rüsten',
  'activityId': 'Aktivitäts-ID',
  'list': {'order': 'Aufträge', 'cleaning': 'Reinigungen', 'changeover': 'Rüstungen'},
  'activityAdded': 'Aktivität hinzugefügt',
  'noActivities': 'Keine Aktivitäten',
  'status': {
    'started': 'Gestartet',
    'suspended': 'Ausgesetzt',
    'completed': 'Abgeschlossen',
    'activated': 'Aktiviert',
    'scheduled': 'Geplant',
    'released': 'Freigegeben',
    'draft': 'Entwurf',
    'notPlanned': 'Nicht geplant'
  },
  'add': 'Aktivität hinzufügen',
  'edit': 'Aktivität bearbeiten',
  'addChangeover': 'Rüsten hinzufügen',
  'step': 'Schritt',
  'general': 'Allgemein',
  'quantity': 'Menge',
  'dates': 'Termine',
  'configuration': 'Konfiguration',
  'activity': 'Aktivitäten',
  'activityStart': 'Aktivitäten Start',
  'toStart': 'zu starten',
  'afterStart': 'nach dem Start',
  'activityAlreadyExists': 'Aktivitäts-ID existiert bereits',
  'activityIdIsTooLong': 'Aktivitäts-ID ist zu lang',
  'changeoverDuration': 'Rüst Dauer (Minuten)',
  'manage': 'Aktivitäten verwalten',
  'changeoverType': 'Rüst Typ',
  'editExisting': 'Bestehende bearbeiten',
  'pickALine': 'Wähle eine Linie',
  'typeEffort': 'Personenstunden angeben',
  'showNotPlanned': 'Nicht geplante Aktivitäten anzeigen',
  'summary': {
    'summary': 'Zusammenfassung',
    'orderTotal': 'Auftrag gesamt',
    'lineTotal': 'Linie gesamt',
    'inLineUnit': 'Einheit Linie',
    'inOrderUnit': 'Auftrag Einheit',
    'goodCount': 'Gut Menge',
    'wasteCount': 'Ausschuss Menge',
    'counterTotal': 'Gesamte Menge',
    'performanceGuard': 'Leistung Sicherung',
    'stateGuard': 'Status Sicherung',
    'performanceGuardTippy': 'Leistungsschwelle überschritten',
    'missingPackingStructure': 'Fehlende Verpackungsstruktur',
    'manualReconciliation': 'Manuelle Abstimmung'
  },
  'schedule': {
    'tableView': 'Tabellenansicht',
    'axisView': 'Zeitleiste',
    'full': 'Voll',
    'collapsed': 'Zusammengebrochen',
    'scheduled': 'Geplant',
    'currentHour': 'Ende der aktuellen Stunde',
    'previousHour': 'Vorige Stunde',
    'previousShift': 'Vorherige Schicht',
    'currentShift': 'Aktuelle Schicht'
  },
  'resources': {
    'production': 'Produktion',
    'waste': 'Ausschuss',
    'manhours': 'Personenstunden',
    'timeScope': 'Zeitlicher Umfang',
    'workers': 'Mitarbeiter',
    'worker': 'Mitarbeiter',
    'history': 'Verlauf',
    'noEntries': 'Keine Einträge',
    'hours': 'Stunden',
    'minutes': 'Minuten'
  },
  'actions': {
    'finishChangeover': 'Rüsten beenden und Auftrag starten',
    'abortChangeover': 'Rüsten abbrechen',
    'productionReconciliation': 'Produktionsabgleich',
    'finishOrder': 'Auftrag abschließen'
  },
  'validations': {
    'orderNotFound': 'Zugehöriger Auftrag nicht gefunden',
    'externalIdError': 'ID darf nur Ziffern, Buchstaben, Zeichen „-“, „/“ und „_“ enthalten und darf nur mit einer Ziffer oder einem Buchstaben beginnen'
  },
  'deleteConfirmMessage': 'Möchten Sie diese Aktivität wirklich löschen?',
  'hasValidationErrors': 'Hat Validierungsfehler',
  'noLine': 'Keine Linie',
  'editOrder': 'Auftrag bearbeiten',
  'editChangeover': 'Rüsten bearbeiten',
  'cannotCreateOrder': 'Auftrag kann nicht angelegt werden',
  'orderExists': 'Auftrag mit angegebener Aktivitäts-ID existiert',
  'endOfLastShift': 'Ende der letzten Schicht',
  'endOfLastHour': 'Ende der letzten Stunde',
  'productionHistory': 'Verlauf Produktion',
  'manhoursHistory': 'Verlauf Personenstunden',
  'changeoverDurationTitle': 'Rüsten Dauer',
  'expectedEffort': 'Erwarteter Aufwand (Personenstunden: mh)',
  'expectedChangeoverEffort': 'Erwarteter Rüstaufwand (Personenstunden: mh)',
  'typeCorrectExpectedEffort': 'Der erwartete Aufwand kann nicht negativ sein',
  'typeCorrectValue': 'Der Wert darf nicht negativ sein',
  'typeChangeoverDurationIfChangeoverExpectedEffortGiven': 'Erwartete Rüstdauer ist erforderlich, wenn der erwartete Rüstaufwand gegeben ist',
  'editMode': 'Bearbeitungsmodus',
  'validationErrors': {
    'skuError': 'Produkt fehlt oder ist falsch konfiguriert',
    'flowIdError': 'Ablauf Konfiguration fehlt',
    'endDateNotValid': 'Geplantes Enddatum ist ungültig',
    'lineError': 'Linienkonfiguration fehlt',
    'orderedQuantityError': 'Bestellte Menge fehlt',
    'orderedQuantityUnitError': 'Bestellte Mengeneinheit fehlt oder ist falsch konfiguriert'
  }
};
