<template>
  <div style="min-width: 500px">
    <div style="position: absolute; left: 260px">
      <button
        class="btn btn-outline-elegant icon-btn"
        @click="$emit('close')"
      >
        <i class="ion ion-md-close" />
      </button>

      <MachineParameters
        v-if="machine"
        :machine-id="machine.id"
        class="mt-2 bg-white"
        style="max-height: 400px; overflow: auto"
      />
    </div>
  </div>
</template>

<script>
import MachineParameters from './MachineParameters';

export default {
  props: {
    machine: Object,
  },
  components: {
    MachineParameters,
  },
};
</script>

<style>

</style>
