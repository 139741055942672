export default {
  orders: 'คำสั่งซื้อ',
  order: 'คำสั่งซื้อ',
  import: 'นำเข้าคำสั่งซื้อ',
  completedOrders: 'คำสั่งซื้อที่เสร็จสมบูรณ์',
  inProgressOrders: 'คำสั่งซื้อในระหว่างดำเนินการ',
  start: 'เริ่ม',
  removeLastEntryFirst: 'ก่อนยืนยันการผลิตคำสั่งซื้ออีกครั้ง ลบการประกาศการผลิตล่าสุด',
  saveAndActivate: 'บันทึกและเปิดใช้งาน',
  orderDetails: 'รายละเอียดคำสั่งซื้อ',
  activate: 'เปิดใช้งาน',
  stop: 'หยุด',
  end: 'สิ้นสุด',
  show: 'แสดงคำสั่งซื้อ',
  startOrder: 'เริ่มคำสั่งซื้อ',
  reportResources: 'รายงานทรัพยากร',
  confirmCurrentProduction: 'ยืนยันการผลิต',
  activateOrder: 'เปิดใช้งานคำสั่งซื้อ',
  abortOrderActivate: 'ยกเลิกการเปิดใช้งาน',
  selectOrder: 'เลือกคำสั่งซื้อ',
  selectLineAndSku: 'เลือกไลน์และ SKU',
  selectSku: 'เลือก SKU',
  skuPerformance: 'ประสิทธิภาพของ SKU',
  abort: 'ยกเลิก',
  status: 'สถานะ',
  number: 'หมายเลข',
  orderNumber: 'หมายเลขคำสั่งซื้อ',
  skuDescription: 'รายละเอียด SKU',
  skuNumber: 'หมายเลข SKU',
  skuId: 'รหัส SKU',
  orderId: 'รหัสคำสั่งซื้อ',
  item: 'รายการ',
  sku: 'SKU',
  itemNo: 'หมายเลขรายการ',
  schedule: 'ตารางเวลา',
  scheduled: 'วางตารางเวลา',
  plannedProduction: 'การผลิตที่วางแผน',
  realisation: 'การเข้าใจ',
  expectedStart: 'เริ่มต้นที่คาดหวัง',
  expectedEnd: 'สิ้นสุดที่คาดหวัง',
  toPlannedEnd: 'ถึงสิ้นสุดที่วางแผน',
  toPlannedStart: 'ถึงเริ่มต้นที่วางแผน',
  afterPlannedEnd: 'หลังจากสิ้นสุดที่วางแผน',
  afterPlannedStart: 'หลังจากเริ่มต้นที่วางแผน',
  expectedPerformance: 'ประสิทธิภาพที่คาดหวัง',
  description: 'รายละเอียด',
  quantity: 'ผลิต',
  limitExceeded: 'เกินขีดจำกัดของคำสั่งซื้อ โปรดหยุดหรือทำคำสั่งซื้อที่กำลังดำเนินการให้เสร็จสิ้น',
  production: 'สรุปการผลิต',
  wasted: 'สูญเสีย',
  updateProduction: 'อัปเดตการผลิต',
  totalProduction: 'ทั้งหมด',
  incrementalProduction: 'เพิ่มขึ้น',
  productionUpdates: 'อัปเดตการผลิต',
  complete: 'เสร็จสิ้น',
  hold: 'พักการผลิต',
  completed: 'เสร็จสมบูรณ์',
  planned: 'วางแผน',
  lineConfig: 'การกำหนดค่าไลน์',
  config: 'การกำหนดค่า',
  additional: 'เพิ่มเติม',
  settlement: 'การเรียกเก็บ',
  machinesForOrder: 'เลือกเครื่องจักรสำหรับงานนี้',
  requiredComponentsNotScanned: 'บางส่วนของชิ้นส่วนต้องสแกนก่อน',
  firstActivate: 'บางส่วนของชิ้นส่วนจำเป็นต้องมีก่อนเริ่ม ให้เปิดใช้งานคำสั่งซื้อและสแกนชิ้นส่วนก่อนเริ่ม',
  productionStats: 'การผลิต',
  comment: {
    startOrder: 'คำสั่งซื้อจะเริ่มต้นทันที แพ็คเกจที่สแกนทั้งหมดจะถูกเปิดใช้งาน', activateOrder: 'คำสั่งซื้อจะเตรียมพร้อมสำหรับการเริ่ม เรียงลำดับยังสามารถเปลี่ยนแปลงได้ และจะต้องสแกนบางแพ็คเกจก่อนที่จะเริ่ม', completeOrder: 'คำสั่งซื้อจะเสร็จสิ้นและไม่สามารถเริ่มต้นได้อีก', stopOrder: 'คำสั่งซื้อจะหยุดและสามารถเริ่มต้นได้อีกในภายหลัง', abortOrderActivate: "การเปิดใช้งานจะถูกยกเลิก สถานะคำสั่งซื้อจะเปลี่ยนกลับเป็น 'พร้อมใช้งาน'",
  },
  states: {
    inProgress: 'กำลังดำเนินการ', ready: 'รอคอย', completed: 'เสร็จสมบูรณ์', other: 'อื่น ๆ', activated: 'เปิดใช้งาน', onHold: 'พัก', taken: 'ถูกเก็บไว้',
  },
  activities: {
    addActivity: 'วางแผนกิจกรรมใหม่', editActivity: 'แก้ไขกิจกรรม', changeover: 'เปลี่ยนเครื่อง', stop: 'หยุดโดยผู้ประกอบการ', failure: 'ล้มเหลว', lack: 'ขาดส่วนประกอบและผลิตภัณฑ์', order: 'คำสั่งซื้อ', idle: 'ไม่ทำงาน', holdup: 'ความหยุด', inactive: 'ไม่ใช้งาน', cip: 'CIP (การล้างอุปกรณ์ในสถานที่ผลิต)', completedJob: 'งานที่เสร็จสมบูรณ์', inProgressJob: 'งานที่กำลังดำเนินการ',
  },
  add: 'เพิ่มคำสั่งซื้อ',
  create: 'สร้างคำสั่งซื้อ',
  new: 'คำสั่งซื้อใหม่',
  edit: 'แก้ไขคำสั่งซื้อ',
  orderedQuantity: 'ปริมาณที่วางแผน',
  typeCorrectQuantity: 'ปริมาณที่วางแผนต้องมากกว่าศูนย์',
  quantityUnit: 'หน่วยปริมาณ',
  type: 'ประเภท',
  loadingItems: 'กำลังโหลดรายการ..',
  manage: 'จัดการคำสั่งซื้อ',
  noActiveOrPlannedOrders: 'ไม่มีคำสั่งซื้อที่กำลังดำเนินการหรือวางแผน',
  noOrders: 'ไม่มีคำสั่งซื้อ',
  noProducts: 'ไม่มี SKU',
  orderStarted: 'เริ่มคำสั่งซื้อ',
  productionEnteredManually: 'การผลิตและสูญเสียได้รับการยืนยันด้วยวิธีการด้วยตนเอง',
  manualProductionAdded: 'เพิ่มการผลิตด้วยวิธีการด้วยตนเอง',
  manualProductionOverwritten: 'เขียนทับการผลิตด้วยวิธีการด้วยตนเอง',
  release: 'ปล่อย',
  take: 'รับ',
  finishOrder: 'เสร็จสิ้นคำสั่งซื้อ',
  product: 'ผลิตภัณฑ์',
  plannedQuantity: 'ปริมาณที่วางแผน',
  orderHistory: 'ประวัติคำสั่งซื้อ',
  defaultConfiguration: 'การกำหนดค่าเริ่มต้น',
};
