<template>
  <div>
    <div
      v-for="(activity, activityStatus) in groupedActivitiesSorted"
      :key="`table_${activityStatus}`"
    >
      <div
        v-if="activity.length"
        class="h4"
        :style="`color: ${getGroupInfo(activityStatus).color}`"
      >
        {{ getGroupInfo(activityStatus).desc }}
      </div>
      <BTable
        v-if="activity.length"
        class="schedule-table text-center"
        hover
        responsive
        borderless
        :items="activity"
        :fields="fields"
        :tbody-tr-class="rowClass"
        @row-clicked="$emit('setModal', $event)"
      >
        <template #cell(name)="data">
          <span class="font-weight-bold">
            {{ data.item.name }}
            <i
              v-if="hasValidationErrors(data.item)"
              v-tippy="{ arrow: true }"
              style="background-color: white"
              :content="$t('activities.hasValidationErrors')"
              class="fas fa-exclamation-circle text-danger rounded-circle ml-1"
            /></span>
          <span
            v-if="data.item.name !== data.item.externalId"
            class="d-block text-secondary number"
          >
            {{ data.item.externalId }}
          </span>
        </template>

        <template #cell(progress)="data">
          <ProgressBar
            :color="data.item.progress.color"
            :percentage="data.item.progress.percentage"
            :label="data.item.progress.label"
          />
        </template>

        <template #cell(manHours)="data">
          <ProgressBar
            :color="data.item.manHours.color"
            :percentage="data.item.manHours.percentage"
            :label="data.item.manHours.label"
          />
        </template>

        <template #cell(datesStart)="data">
          <span v-if="!data.item.dates">
            {{ $t('activities.status.notPlanned') }}
          </span>
          <ProgressBar
            v-else
            :color="data.item.dates.color"
            :percentage="data.item.dates.percentage"
            :label="data.item.dates.label"
          />
        </template>

        <template #cell(statusName)="data">
          <ActivityStatusBadge
            :status="data.item.status"
            :rounded="false"
            :big="true"
          />
        </template>

        <template #cell(details)="data">
          <TableDetails :activity="data.item" />
        </template>

        <template #cell(typeName)="data">
          <i :class=" getActivityInfo( data.item.type ).icon " />
          <span class="ml-1 font-weight-bold">
            {{ data.item.typeName }}
          </span>
        </template>

        <template #cell(lineName)="data">
          <span v-if="data.item.lineId">
            {{ data.item.lineName }}
          </span>
          <span
            v-else
            class="text-dashed"
          >
            -
          </span>
        </template>
      </BTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ProgressBar from '@core/components/ProgressBar';
import { round } from '@core/utils/numbers';
import ActivityStatusBadge from '@/components/activity/ActivityStatusBadge';
import { activityStatus } from '@/utils/dictionary';
import calcDuration from '@/utils/calcDuration';
import colors from '@/utils/colors';
import TableDetails from './table/TableDetails';

export default {
  props: {
    activities: {
      type: Array,
      required: true,
    },
    unplannedActivities: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ActivityStatusBadge,
    ProgressBar,
    TableDetails,
  },
  computed: {
    ...mapGetters(['getActivityState', 'getActivityType']),
    ...mapGetters('element', ['elementName']),
    ...mapGetters('plant', ['displayLabour']),
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('general.name'),
          class: 'name-col',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,

        },
        {
          key: 'typeName',
          label: this.$t('activities.activity'),
          class: 'activity-col',
          sortable: true,
        },
        {
          key: 'lineName',
          label: this.$t('line'),
          class: 'line-col',
          sortable: true,

        },
        {
          key: 'details',
          class: 'details-col',
          label: this.$t('general.details'),
        },
        {
          key: 'statusName',
          label: this.$t('general.status'),
          class: 'status-col',
          sortable: true,
        },
        {
          key: 'progress',
          label: this.$t('general.progress'),
          class: 'progress-col',
        },
        {
          key: this.displayLabour ? 'manHours' : '',
          label: this.$t('general.manHours'),
          class: 'manHours-col',
        },
        {
          key: 'datesStart',
          label: this.$t('general.dates'),
          class: 'dates-col',
          sortable: true,
        },
      ];
    },
    groupedActivitiesSorted() {
      const activitiesMapped = this.activities.map(a => {
        const progress = this.getProgress(a);
        const manHours = this.getManHours(a);
        const dates = this.getDates(a);
        const name = a.description || a.externalId;
        const lineName = this.elementName(a.lineId);
        const typeName = this.getActivityInfo(a.type).desc;
        const statusName = this.getStatusInfo(a.status).desc;
        const datesStart = dates.start;

        return {
          ...a,
          progress,
          manHours,
          dates,
          name,
          lineName,
          typeName,
          statusName,
          datesStart,
        };
      });
      const unplannedActivitiesMapped = this.unplannedActivities
        .filter(a => !a.plannedExecution)
        .map(a => {
          const progress = this.getProgress(a);
          const manHours = this.getManHours(a);
          const dates = null;
          const name = a.description || a.externalId;
          const lineName = this.elementName(a.lineId);
          const typeName = this.getActivityInfo(a.type).desc;
          const statusName = this.getStatusInfo(a.status).desc;

          return {
            ...a,
            progress,
            manHours,
            dates,
            name,
            lineName,
            typeName,
            statusName,
          };
        });

      const groupedActivities = activitiesMapped.reduce((acc, curr) => {
        const propertyName = curr.status;
        if (!acc[propertyName]) {
          acc[propertyName] = [];
        }
        acc[propertyName].push(curr);
        return acc;
      }, {});

      return {
        Started: [
          ...(groupedActivities.Activated || []),
          ...(groupedActivities.Started || []),
        ],
        Scheduled: [
          ...(groupedActivities.Released || []),
          ...(groupedActivities.Draft || []),
        ],
        Completed: groupedActivities.Completed || [],
        NotPlanned: unplannedActivitiesMapped || [],
      };
    },
  },
  methods: {
    getGroupInfo(status) {
      switch (status) {
        case 'Started':
          return {
            color: this.$color('blue'),
            desc: this.$t('activities.status.started'),
          };
        case 'Scheduled':
          return {
            color: colors('orange'),
            desc: this.$t('activities.schedule.scheduled'),
          };
        case 'Completed':
          return {
            color: this.$color('green'),
            desc: this.$t('activities.status.completed'),
          };
        case 'NotPlanned':
          return {
            color: this.$color('red'),
            desc: this.$t('activities.status.notPlanned'),
          };
        default:
          return {
            color: this.$color('grey'),
            desc: this.$t('orders.states.other'),
          };
      }
    },
    getStatusInfo(status) {
      const state = this.getActivityState({ status });
      if (!state) return {};
      return {
        ...state,
      };
    },
    getActivityInfo(type) {
      const info = this.getActivityType({ type });
      if (!info) return {};
      return {
        ...info,
      };
    },
    getProgress(activity) {
      const produced = activity.producedQuantity?.value || 0;
      const ordered = activity.orderedQuantity?.value || 0;
      const unit = activity.orderedQuantity?.unit || '';

      return {
        color: this.getStatusInfo(activity.status).color,
        percentage: produced && (produced / ordered) * 100,
        label: `${round(produced)}/${ordered} ${unit}`,
      };
    },
    getManHours(activity) {
      const expectedEffort = activity.expectedEffort?.manhours || 0;
      const actualEffort = activity.actualEffort?.manhours || 0;
      const unit = 'mh';

      return {
        color: this.getStatusInfo(activity.status).color,
        percentage: actualEffort && (actualEffort / expectedEffort) * 100,
        label: `${round(actualEffort)}/${expectedEffort} ${unit}`,
      };
    },
    getStartDate(activity) {
      let start = moment(activity.scheduledExecution?.begin).unix();

      if (
        activity.status === activityStatus.started
      ) {
        start = moment(activity?.actualExecution?.begin).unix();
      }

      if (activity.status === activityStatus.activated) {
        if (moment(activity?.actualActivation?.begin || activity?.scheduledExecution?.begin)
          .unix() <= this.$now) {
          start = this.$now;
        }
      }

      if (activity.status === activityStatus.completed) {
        start = moment(activity?.actualExecution?.begin).unix();
      }

      return start;
    },
    getEndDate(activity) {
      const timesSecondsToMilliseconds = 1000;
      const logDuration = moment(activity?.scheduledExecution?.end)
        .unix()
        - moment(activity?.scheduledExecution?.begin)
          .unix();
      const localEstimatedEnd = !Number.isNaN(logDuration)
        ? new Date((moment((
          activity?.actualExecution?.begin
          || activity?.actualActivation?.begin
          || activity?.scheduledExecution?.begin
        )).unix() + logDuration) * timesSecondsToMilliseconds)
        : null;

      let end = moment(activity.scheduledExecution?.end).unix();

      if (
        activity.status === activityStatus.activated
        || activity.status === activityStatus.started
      ) {
        const estimatedEndOrEnd = activity?.estimatedEnd || localEstimatedEnd || activity?.scheduledExecution?.end;

        end = moment(estimatedEndOrEnd).unix();
        if (
          moment(estimatedEndOrEnd).unix() < this.$now
          || (estimatedEndOrEnd) === null
        ) {
          end = this.$now;
        }
      }

      if (activity.status === activityStatus.completed) {
        end = moment(activity?.actualExecution?.end).unix();
      }

      return end;
    },
    getDates(activity) {
      const start = this.getStartDate(activity);
      const end = this.getEndDate(activity);

      if (activity.status === activityStatus.draft || activity.status === activityStatus.released) {
        return {
          start,
          color: '',
          percentage: 0,
          label:
            start < this.$now
              ? `${calcDuration(start, this.$now)} ${
                this.$t('activities.afterStart')}`
              : `${calcDuration(this.$now, start)} ${
                this.$t('activities.toStart')}`,
        };
      }

      const format = 'D MMM H:mm';
      const diff = activity.actualExecution?.end
        ? moment(activity.actualExecution.end).unix()
        : this.$now;

      return {
        start,
        color: colors('green'),
        percentage: (1 - (end - diff) / (end - start)) * 100,
        label: `${moment(start * 1000).format(format)} - ${
          moment(end * 1000).format(format)}`,
      };
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return '';
      return `table-${(item.status).toLowerCase()}`;
    },
    hasValidationErrors(item) {
      return item.status === activityStatus.draft && item.validationErrorCount;
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/vars.icss";

.dates-col {
  width: 20%;
  min-width: 160px;
}

.status-col {
  width: 10%;
}

.activity-col {
  width: 15%;
  font-size: 0.875rem;
}

.progress-col {
  width: 15%;
  min-width: 80px;
}

.manHours-col {
  width: 12%;
  min-width: 80px;
}
.line-col {
  font-size: 0.875rem;
}
.details-col {
  width: 8%;
}
.name-col {
  font-size: 0.875rem;
  min-width: 50px;
  max-width: 200px;
  width: 20%;
  overflow-wrap: break-word;
}

.table-released td:first-child {
  border-left: 4px solid $orange;
}

.table-scheduled td:first-child {
  border-left: 4px solid $orange;
}

.table-started td:first-child {
  border-left: 4px solid $blue;
}

.table-activated td:first-child {
  border-left: 4px solid $blue_pastel;
}

.table-completed td:first-child {
  border-left: 4px solid $green;
}

.table-suspended td:first-child {
  border-left: 4px solid $grey;
}

.table-draft td:first-child {
  border-left: 4px solid $grey;
}

.number {
  font-size: 0.656rem;
}
</style>
