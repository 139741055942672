var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2"},[_vm._l((_vm.breadcrumb),function(b){return [(b.key !== 'general')?_c('div',{key:`${b.key}line`,staticClass:"box-style",class:{
        selected: _vm.selected.index >= b.index,
        filled: _vm.isStepFilled(b),
      }}):_vm._e(),_c('div',{key:`${b.key}title`,staticClass:"row",staticStyle:{"align-items":"center"},on:{"click":function($event){return _vm.goTo(b)}}},[_c('div',{staticClass:"circle-box position-relative",class:{
          selected: _vm.selected.index >= b.index,
          filled: _vm.isStepFilled(b),
        }},[(_vm.hasStepError(b))?_c('i',{staticClass:"fas fa-exclamation-circle text-danger error-step"}):_vm._e()]),_c('div',{staticClass:"name-style",class:{
          color: _vm.selected.index >= b.index,
          filled: _vm.isStepFilled(b),
          selected: _vm.selected.index === b.index,
        }},[_vm._v(" "+_vm._s(b.title)+" ")])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }