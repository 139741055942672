import lang from '@/lang';
import moment from 'moment';

export const createLabels = (list, aggregate, showDate) => {
  let labels = list.map(t => ({
    label: '',
    row: t,
  }));

  if (aggregate.includes('hour')) {
    const format = labels.length <= 24 && !showDate ? 'HH:mm' : 'D MMM HH:mm';
    labels = labels
      .map(({ row, label }) => ({
        label: `${label} | ${moment.utc(row.from).local().format(format)}`,
        row,
      }));
  }

  if (aggregate.includes('day')) {
    const days = Object.values(labels.reduce((acc, { row }) => {
      const day = moment.utc(row.from).local().format('DD-MM');
      acc[day] = true;
      return acc;
    }, {}));

    labels = labels
      .map(({ row, label }) => ({
        // skip days, if all hours in the same day
        label: days.length > 1 || !aggregate.includes('hour')
          ? `${label} | ${moment.utc(row.from).local().format('DD MMM')}`
          : label,
        row,
      }));
  }

  if (aggregate.includes('month')) {
    labels = labels
      .map(({ row, label }) => ({
        label: `${label} | ${moment.utc(row.from).local().format('MMM')}`,
        row,
      }));
  }

  if (aggregate.includes('dotw')) { // day of the week
    labels = labels
      .map(({ row, label }) => ({
        label: `${label} | ${moment.utc(row.from).local().format('dddd')}`,
        row,
      }));
  }

  if (aggregate.includes('week')) {
    labels = labels
      .map(({ row, label }) => ({
        label: `${label} | ${lang.t('time.week')} ${moment.utc(row.from).local().format('ww')}`,
        row,
      }));
  }

  if (aggregate.includes('shift')) {
    labels = labels
      .map(({ row, label }) => {
        const shiftCode = row.shiftSplit
          ? row.shiftSplit.shiftCode
          : lang.t('noShift');

        return {
          label: `${label} | ${shiftCode}`,
          row,
        };
      });
  }

  if (aggregate.includes('order')) {
    labels = labels
      .map(({ row, label }) => {
        const orderCode = row.orderSplit
          ? row.orderSplit.orderNumber
          : lang.t('orders.noOrders');

        return {
          label: `${label} | ${orderCode}`,
          row,
        };
      });
  }

  if (aggregate.includes('product')) {
    labels = labels
      .map(({ row, label }) => {
        const productCode = row.productSplit
          ? row.productSplit.productCode
          : lang.t('orders.noProducts');

        return {
          label: `${label} | ${productCode}`,
          row,
        };
      });
  }

  return labels.map(x => x.label.trim().slice(1));
};

export default { createLabels };
