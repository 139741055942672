import Vue from 'vue';
import { plantProperty } from '@/utils/dictionary';

export default {
  state: {
    list: [],
  },
  mutations: {
    setProperties(s, list) {
      s.list = list;
    },
    addProperty(s, prop) {
      s.list.push(prop);
    },
    updateProperty(s, prop) {
      const index = s.list.findIndex(({ name }) => name === prop.name);
      if (index !== -1) {
        Vue.set(s.list, index, prop);
      }
    },
    setProperty(s, { name, value }) {
      const prop = s.list.find(x => x.name.toLowerCase() === name.toLowerCase());
      if (prop) {
        prop.value = value;
      } else {
        s.list.push({ name, value });
      }
    },
  },
  getters: {
    weekStart: s => s.weekStart,
    plantProperty: s => name => {
      const prop = s.list.find(p => p.name.toLowerCase() === name.toLowerCase());
      return prop ? prop.value : null;
    },
    tenantId: (_, g) => g.plantProperty(plantProperty.tenantId),
    displayEnergy: (_, g) => {
      const enabled = g.plantProperty(plantProperty.energy);
      return enabled === 'true' || enabled === '1';
    },
    displayUseValidatedSpeed: (_, g) => {
      const enabled = g.plantProperty(plantProperty.useValidatedSpeed);
      return enabled === 'true' || enabled === '1';
    },
    displayChangeovers: (_, g) => {
      const enabled = g.plantProperty(plantProperty.changeoversEnabled);
      return enabled === 'true' || enabled === '1';
    },
    displayLabour: (_, g) => {
      const enabled = g.plantProperty(plantProperty.labourEnabled);
      return enabled === 'true' || enabled === '1';
    },
    displayManualWayOfWorking: (_, g) => {
      const enabled = g.plantProperty(plantProperty.manualWayOfWorkingEnabled);
      return enabled === 'true' || enabled === '1';
    },
    hideDowntimesButton: (_, g) => {
      const enabled = g.plantProperty(plantProperty.hideDowntimesButton);
      return enabled === 'true' || enabled === '1';
    },
    newAndonScreen: (_, g) => {
      const enabled = g.plantProperty(plantProperty.newAndonScreen);
      return enabled === 'true' || enabled === '1';
    },
  },
  actions: {
    async setPlantProperty(ctx, data) {
      const res = await ctx.dispatch('element/setElementProperty', {
        params: {
          plantId: data.plantId,
          elementId: data.plantId,
        },
        data: {
          name: data.name,
          unit: data.unit,
          value: data.value,
        },
      }, { root: true });

      ctx.commit('setProperty', res.data);

      return res;
    },
  },
};
