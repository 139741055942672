import activities from './activities';
import datatables from './datatables';
import downtimeSelector from './downtimeSelector';
import downtimes from './downtimes';
import error from './error';
import notify from './notify';
import orders from './orders';
import reports from './reports';
import settings from './settings';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,
  //
  world: 'World',
  plant: 'Factory',
  area: 'Area',
  areas: 'Areas',
  line: 'Line',
  lines: 'Lines',
  delete: 'Delete',
  segment: 'Segment',
  machine: 'Machine',
  machines: 'Machines',
  element: 'Element',
  map: 'Map',
  issue: 'Issue',
  typeOfIssue: 'Type of Issue',
  currentShift: 'Current shift',
  noShift: 'No shift',
  currentOrder: 'Current order',
  currentWeek: 'Current week',
  today: 'Today',
  shift: 'Shift',
  shifts: 'Shifts',
  noShifts: 'No shifts assigned',
  factoryStructure: 'Factory structure',
  asset: {
    globalCounter: 'Global counter',
    localCounter: 'Local counter',
    waterCounter: 'Water counter',
    valvePosition: 'Valve position',
    waterLevel: 'Water level',
    waterFlow: 'Water flow',
    pumpBalance: 'Pump balance',
  },
  wall: {
    comment: 'Comment',
    comments: 'Comments',
    vision: 'Vision',
    downtimeEditorSettings: 'Downtime editor settings',
  },
  menu: {
    monitoring: 'Monitoring',
    reports: 'Reports',
    overview: 'Overview',
    andon: 'Andon',
    weeklyAnalysis: 'Weekly analysis',
    scoreAnalysis: 'Score analysis',
    settings: 'Settings',
    userSettings: 'Account settings',
    subscriptions: 'Subscriptions',
    cameras: 'Cameras',
    edgeDevices: 'Edge devices',
    tags: 'Tags',
    tagHistory: 'Tag history',
    language: 'Language',
    user: 'User',
    setAsHome: 'Set as a home page',
    setFullScreen: 'Make full screen',
    logout: 'Logout',
    loadMore: 'Load more',
    selectPlant: 'Select plant',
    activities: 'Activities',
    login: 'Login',
    preview: 'Preview',
    lineGraph: 'Line graph',
    machineGraph: 'Machine graph',
    homePageDevice: {
      title: 'Home page for this device',
      description: 'This screen will be displayed always after login on this device',
    },
    homePageUser: {
      title: 'Home page for your account',
      description: 'This screen will be displayed after your login if the device do not have a home page',
    },
    lineView: 'Line view',
  },
  import: {
    upload: 'Drop file here to upload',
    notSaved: 'Not saved',
    noChanges: 'No changes',
    saved: 'Saved',
    dataSaved: 'Data saved successfully',
    multiplierIsInvalid: 'Multiplier is conflicting with already defined conversion',
    multiplierIsLessOrEqualZero: 'Multiplier cannot be less or equal zero',
    actionRequired: 'Action required',
    checked: 'Verified',
    fillAndAdd: 'Fill and add',
    chooseImage: 'Choose image',
    nothingImported: 'No valid data to import',
    previousImports: 'Previous imports',
    fileIsBeingProcessed: 'File is being processed. We\'ll send you a notifiction when it\'s ready',
    groupSuccessfullyAssign: 'Successfully assigned to group',
    groupFailedToAssign: 'Failed to assign to group',
    groupSuccessfullyRemoved: 'Successfully removed from group',
    groupFailedToRemove: 'Failed to remove from group',
    seeTemplates: 'See Templates',
    fileReview: 'File Review',
    templates: 'Templates',
    addTemplate: 'Add Template',
    editTemplate: 'Edit Template',
    import: 'Import',
    uploadFile: 'Upload file',
    template: 'Template',
    toUploadFilePickTemplate: 'To upload file pick the template',
    result: 'Result',
    testFile: 'Test file',
    toSeeResultUploadTestFile: 'To see the result upload a test file',
    toTestFileSaveChanges: 'To test the file save your changes',
    visible: 'Visible',
    saveFunction: 'Save Function',
    key: 'Key',
    value: 'Value',
    oldValue: 'Old Value',
    newValue: 'New Value',
    unchanged: 'Unchanged',
    status: {
      applied: 'Success',
      appliedWithErrors: 'Applied with errors',
      scheduled: 'Scheduled',
      faulted: 'Faulted',
      processing: 'Processing',
      parsingFailed: 'Parsing Failed',
    },
    wasSuccessfully: 'was successfully',
    added: 'added',
    imported: 'imported',
    failed: 'failed',
    failedToFetchTemplates: 'Failed to fetch templates.',
  },
  audit: {
    log: 'Audit log',
  },
  work: {
    event: 'Problem',
    events: 'Problems',
    state: 'State',
    states: 'States',
    systemStates: 'System states',
    reason: 'Reason',
    reasons: 'Reasons',
    correctiveAction: 'Corrective action',
    correctiveActions: 'Corrective actions',
    downtime: 'Downtime',
    downtimes: 'Downtimes',
    wiki: 'Wiki articles',
    disconnected: 'Disconnected',
    rootCause: 'Root cause',
    detachRootCause: 'Detach root cause',
    rootMachine: 'Root cause machine',
    spectrum: 'Work spectrum',
    downtimeList: 'Downtime list',
    default: 'Default',
    byState: 'By state',
    tags: 'Tags',
    addTags: 'Add tags',
    addSelected: 'Add selected',
    plannedDowntimes: 'Planned downtimes',
    unplannedDowntimes: 'Unplanned downtimes',
    workTime: 'Duration in work state',
    actionTaken: 'Corrective action taken',
    noOngoingOrder: 'No ongoing order',
    noOngoingShift: 'No ongoing shift',
    persisted: 'Modification saved',
    processing: 'Processing in progress...',
    saving: 'Saving...',
    updateQueued: 'Downtime update queued. This may take a few seconds',
    overrideQueued: 'Downtime insertion queued. This may take a few seconds',
    noForcedState: 'No problems defined with tag \'Forced\'. Add a downtime definition with this tag, to stop machine or line',
    downtimeSince: 'Downtime since',
    lasting: 'lasting',
    updateType: {
      SetWorkLogProblem: 'Downtime type update',
      SetWorkLogReasonMessage: 'Reason update',
      SetWorkLogCorrectiveActionMessage: 'Corrective action update',
      AddWorkLogAnnotationMessage: 'New comment',
      ReplayHistory: 'Processing re-run',
      DeleteWorkLogAnnotationMessage: 'Comment remove',
      OpenWorkLogMessage: 'Beginning forced stop',
      RecalculateElementStateMessage: 'Ending forced stop',
      DeleteWorkLogMessage: 'Downtime remove',
      OverrideWorkLogMessage: 'Creating new downtime',
      AdjustWorkLogDuration: 'Downtime duration adjustement',
      WorkLogSplitMessage: 'Downtime split'
    },
    audit: {
      onElement: 'on',
      started: 'downtime start',
      WorkLogOpeningRequested: 'started downtime',
      WorkLogProblemUpdateRequested: 'changed downtime type',
      WorkLogDeletionRequested: 'deleted downtime',
      WorkLogRecalculationRequested: 'finished forced stop',
      WorkLogDurationAdjustmentRequested: 'updated downtime duration',
      WorkLogOverrideRequested: 'created downtime',
      WorkLogSplitRequested: 'split downtime'
    }
  },
  general: {
    name: 'Name',
    from: 'From',
    search: 'Search',
    max: 'Max',
    errors: 'Errors',
    min: 'Min',
    noResults: 'No results',
    title: 'Title',
    description: 'Description',
    fromStart: 'from start',
    confirm: 'Confirm',
    tryAgain: 'Try again',
    to: 'To',
    toEnd: 'to end',
    finish: 'Finish',
    add: 'Add',
    all: 'All',
    clear: 'Clear',
    hidden: 'hidden',
    remove: 'Remove',
    copy: 'Copy',
    inherited: 'Inherited',
    detach: 'Detach',
    attach: 'Attach',
    close: 'Close',
    cancel: 'Cancel',
    refresh: 'Refresh',
    lastRefresh: 'Last refresh: {0} ago',
    reset: 'Reset',
    edit: 'Edit',
    event: 'Event',
    details: 'Details',
    collapseDetails: 'Collapse details',
    summary: 'Summary',
    save: 'Save',
    change: 'Change',
    noData: 'No data',
    nothingDefined: 'Nothing defined',
    others: 'Others',
    back: 'Back',
    next: 'Next',
    results: 'results',
    selected: 'Selected',
    allSelected: 'All selected',
    unchecked: 'unchecked',
    selectAll: 'Select all',
    createNew: 'Create new',
    useExisting: 'Use existing',
    select: 'Select',
    saveSuccessed: 'Saving successed',
    saveFailed: 'Save failed',
    shouldArchive: 'Should archive',
    areYouSure: 'Are you sure?',
    manHours: 'Man Hours',
    view: 'View',
    yes: 'Yes',
    no: 'No',
    reason: 'Reason',
    actions: 'Actions',
    reasonUnknown: 'Reason unknown',
    forcedByOperator: 'Forced by Operator',
    type: 'Type',
    valueType: 'Value type',
    value: 'Value',
    unit: 'Unit',
    disable: 'Disable',
    disabled: 'Disabled',
    disabledShe: 'Disabled',
    inactive: 'Inactive',
    enable: 'Enable',
    enabled: 'Enabled',
    enabledShe: 'Enabled',
    ignore: 'Ignore',
    cancelled: 'Cancelled',
    nominal: 'Nominal',
    unsavedChanges: 'Do you really want to leave? You have unsaved changes!',
    now: 'Now',
    showAll: 'Show all',
    showMore: 'Show more',
    continue: 'Continue',
    group: 'Group',
    download: 'Download',
    progress: 'Progress',
    dates: 'Dates',
    pickDate: 'Pick a date',
    dir: {
      top: 'Top',
      bottom: 'Bottom',
      left: 'Left',
      right: 'Right',
    },
    sourceURI: 'Source URI',
    dimensions: 'Dimensions',
    status: 'Status',
    code: 'Code',
    start: 'Start',
    end: 'End',
    update: 'Update',
    deviceId: 'Device ID',
    time: 'Time',
    show: 'Show',
    hide: 'Hide',
    last: 'Last',
    recent: 'Recent',
    requestReceived: 'Request received',
    deleted: 'Deleted',
    filters: 'Filters',
    total: 'Total',
    restoreDefaults: 'Restore defaults',
    setDate: 'Set date',
    setTime: 'Set time',
    line: 'Line',
    option: 'Option',
    default: 'Default',
    set: 'Set',
    percent: 'Percent',
    audit: 'Audit',
    pickAMachine: 'Pick a machine',
    others: 'Others',
  },
  machineControl: {
    turnOn: 'Turn on',
    turnOff: 'Turn off',
    current: 'Current',
    liveStream: 'Live stream',
    disconnectedWarning: 'WARNING! Machine is currently disconnected - '
      + 'turning off will cause buffered data loss',
    startMachine: 'Start machine <b>{machine}</b>?',
  },
  lineControl: {
    startLine: 'Start linie <b>{line}</b>?',
    stop: 'Stop',
    start: 'Start',
  },
  time: {
    none: 'None',
    week: 'Week',
    minutes: 'Minutes',
    date: 'Date',
    day: 'Day',
    hour: 'hour',
    hours: 'hours',
    hoursPlural: 'hours',
    days: 'days',
    month: 'Month',
    year: 'Year',
    quarter: 'Quarter',
    resolution: 'Resolution',
    duration: 'Duration',
    seconds: 'seconds',
    startDate: 'Start date',
    endDate: 'End date',
    startTime: 'Start time',
    endTime: 'End time',
    selectDay: 'Select day',
    longerThan: 'Longer than',
    shorterThan: 'Shorter than',
    scope: 'Time scope',
    currentOrder: 'Current order',
    showMinorStops: 'Show minor stops',
    plannedOnly: 'Show only planned',
    unplannedOnly: 'Show only unplanned',
    performanceOnly: 'Performance losses',
    availabilityOnly: 'Availability losses',
    utilizationOnly: 'Utilization losses',
    excludeDefaults: 'Hide defaults',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    plannedDate: 'Planned date',
    custom: 'Custom',
    factoryTimezone: 'Factory timezone',
    timestamp: 'Timestamp'
  },
  graph: {
    flow: 'Flow',
    flows: 'Flows',
    production: 'Production flow',
    physical: 'Physical',
    supplying: 'Supplying',
    archiveChanges: 'Archive changes',
    isActive: 'Active',
    addElement: 'Add element',
    detached: 'Detached',
    andon: 'Andon',
    noActiveFlow: 'No active flow',
    setAsABaseAvailability: 'Set as a base availability',
    setAsABaseQuantity: 'Set as a base quantity',
    youNeedToSpecifyBaseMachines: 'You need to specify base availability and base quantity machine.',
  },
  production: {
    rawCounterIncreases: 'Raw counter values in this period',
  },
  monitoring: {
    rerun: 'Re-run',
    ofThePlan: 'You have reached %{value}% of this value',
    noPlan: 'No plan',
    performanceSettingHistory: 'Performance settings history',
    unitConversionsHistory: 'Unit conversions history',
    averageTargetPerformance: 'Average target performance',
    reportParam: 'Report parametrization',
    performanceType: 'Performance type',
    designSpeed: 'Design',
    validatedSpeed: 'Validated',
    targetProduction: 'Production target',
    maxPerfProduction: 'Max performance',
    plannedFromOrders: 'Planned orders',
    planned: 'Planned',
    notInFlow: 'Not in the production flow',
    produced: 'Produced',
    wasted: 'Wasted',
    lastMessage: 'Last message',
    noMessages: 'No messages',
    noAlarms: 'No alarms',
    noConnectionInfo: 'No information about the connection',
    noConnection: 'No connection with the server',
    processing: 'Processing',
    dataProcessingFromLast: 'Processing data from last ',
    lineStates: 'Line states',
    disconnected: 'disconnected',
    serverDisconnected: 'Server disconnected',
    working: 'working',
    ready: 'ready',
    notWorking: 'in downtime',
    productionProgress: 'Production progress',
    production: 'Production',
    goodCount: 'Good count',
    wasteCount: 'Waste count',
    productionMap: 'Production map',
    workStates: 'Work\xa0states',
    workLog: 'Work\xa0history',
    event: 'Event',
    events: 'Events',
    cause: 'Cause',
    causes: 'Causes',
    reasons: 'Reasons',
    warnings: 'Warnings',
    correctiveAction: 'Corrective action',
    stopRealTime: 'Stop real time',
    stoppedRealTime: 'Real time stopped',
    realTime: 'Real time',
    toRealTime: 'To real time',
    downtimes: 'Downtimes',
    changeCurrentDowntime: 'Change downtime',
    doubleClickToEdit: 'Double click to edit downtime',
    percentShare: 'Percentage share',
    timeShare: 'Time share',
    minorStops: 'including minor stops',
    remainingPlannedProduction: 'Possible remaining production based on nominal speed',
    remainingEstimatedProduction: 'Possible remaining production based on effective speed',
    remainingTime: 'Remaining time',
    performance: {
      short: 'Last {0} performance',
      effective: 'Effective performance',
      nominal: 'Nominal performance',
    },
    segment: {
      baseQuantity: 'Base quantity',
      baseQuality: 'Base quality',
      baseAvailability: 'Base availability',
      basePerformance: 'Base performance',
    },
    machine: {
      counter: 'Counter',
      parameters: 'Parameters',
      alarms: 'Alarms',
      processParameters: 'Process parameters',
      currentParameters: 'Current parameters',
      activeAlarms: 'Active alarms',
      counters: 'Counters',
      alarmsHoldups: 'Alarms and Holdups',
      performanceInfo: 'Average performance from last {0}',
      others: 'Others',
      lifespan: 'Lifespan',
      environmentCondition: 'Environment condition',
      condition: 'Asset condition',
      cost: 'Cost',
      failurePreditcion: 'Failure prediction',
      probabilityFailure: 'Probability failure within 10 days',
      failureType: 'Failure type',
      timeToFailure: 'Predicted time to failure',
      predictedFailure: 'Predicted failure',
      maintenanceHistory: 'Maintenance history',
    },
    lineInfo: {
      advices: 'Hints',
      forService: 'Tasks',
      jobs: 'Jobs',
      completed: 'Jobs done',
      problems: 'Problems',
      planned: 'Jobs queued',
      toAchieveTarget: 'Expected production volume at this moment in time:',
      confirmLinePause: 'Confirm line pause',
    },
    worstAlarms: 'Worst issues',
    problems: 'no issues | one issue | {0} issues',
    overdue: '{0} overdue',
    left: '{0} left',
  },
  components: {
    title: 'Components',
    one: 'Component',
    usagePerUnit: 'Usage per unit',
    required: 'Required',
    convertUnit: 'Convert Unit',
    validation: 'Validation Required',
    batchTracking: 'Batch Tracking',
    scanTrackingId: 'Scan tracking number',
    batchTrackingHelp: 'When tracking is enabled, you need to provide batch identification number for each scanned package',
    defaultPackageQuantity: 'In the package',
    warningLevelPercentage: 'Warning percentage',
    warningLevel: 'Warning level',
    warningLevelHelp: ' % - warning when some percentage of package is consumed\n min - warning when estimated number of minutes remaining ',
    scannerCode: 'Scanner code',
    scannerCodeType: 'code type',
    noTrackingId: 'No tracking id',
    scan: 'Scan',
    packages: 'Packages',
    scanPackages: 'Scan packages',
    noComponents: 'No assigned components',
    invalidCode: 'Component not found',
    addComponent: 'Add component',
    closed: 'Closed',
    scanned: 'Scanned',
    closeConfirm: 'Close',
    eta: 'ETA',
    active: 'Active',
    clickToActivate: 'Click to activate',
    used: 'Consumed',
    estimatedTotalQuantity: 'Estimated quantity needed',
    packageScannerCode: 'Package code',
    defaultPackageQuantitySum: 'Total quantity in packages',
    registeredDate: 'Scan date',
    trackingId: 'Tracking id',
    consumptionType: {
      title: 'Consumption type',
      help: ' "MULTIPLE" allows many opened packages at once, the first one is consumed.\n "SINGLE" closes automatically previous package when a new one is activated, the last one is consumed',
    },
  },
  oee: {
    oee: 'OEE',
    oeePercentage: 'OEE [%]',
    oeeLoss: 'OEE Loss',
    availability: 'Availability',
    availabilityPercentage: 'Availability [%]',
    performance: 'Performance',
    performancePercentage: 'Performance [%]',
    quality: 'Quality',
    utilization: 'Utilization',
    teep: 'TEEP',
    target: 'Target',
  },
  metrics: {
    mtbf: 'Mean time between failures (MTBF)',
    mttr: 'Mean time to repair (MTTR)',
    mtbm: 'Mean time between maintenance (MTBM)',
    reliabilityMetrics: 'Reliability metrics',
  },
  energy: {
    usedEnergy: 'Energy used',
    currentLeastEfficient: 'Currently least efficient machine',
    currentPower: 'Current power',
    baseline: 'Baseline',
    max: 'Average max',
  },
  items: {
    items: 'Items',
    item: 'Item',
    import: 'Import items',
    export: 'Export',
    itemGroups: 'Item groups',
    itemGroup: 'Item group',
    inheritedFromGroup: 'Inherited from group',
    lineConfiguration: 'Line configuration',
    noLineConfigured: 'No products configured for this line',
    groups: 'Groups',
    add: 'Add item',
    addGroup: 'Add group',
    new: 'New item',
    edit: 'Edit item',
    number: 'Number',
    description: 'Description',
    quantity: 'Quantity/pallet',
    unit: 'Quantity unit',
    performance: 'Performance',
    expectedPerformance: 'Expected performance',
    expectedChangeover: 'Expected performance',
    packingStructure: 'Packing structure',
    groupPackingStructure: 'Group packing structure',
    itemRoutingSpeed: 'Item routing speed',
    groupRoutingSpeed: 'Group routing speed',
    components: 'Components',
    bulkDeleteWarning: 'Are you sure you want to delete selected products?',
    bulkDeleteConfirm: 'Delete selected products',
    additionalFields: 'Additional fields',
    noAdditionalFields: 'No additional fields',
  },
  pageNotFoundExplanation: 'We can\'t find this page, apologies for the inconvenience',
  processParams: {
    compositionName: 'Composition name',
    compositions: 'Compositions',
    saveComposition: 'Save composition',
    signals: 'Signals',
    signalName: 'Signal name',
    signalValue: 'Signal value',
    ongoingState: 'Ongoing state',
    ongoingProblem: 'Ongoing problem',
    ongoingOrder: 'Ongoing order',
    problemStart: 'Problem start',
    problemEnd: 'Problem end',
    orderStart: 'Order start',
    orderEnd: 'Order end',
    orderProduction: 'Order production',
    showAsTable: 'Show as table',
  },
  healthcheck: {
    noDriverConnection: 'No driver connection',
  },
  vision: {
    vision: 'Vision',
    showAllClips: 'Show all clips',
    selectCamera: 'Select Camera',
    getClip: 'Get clip',
    getTheTwoPreviousMinutes: 'Get 2 minutes before and 2 after the start time downtime',
    noClips: 'No clips found',
    noRecords: 'Camera has no records from that day for download',
    currentVideoTime: 'Current Video Time',
    startVideoTime: 'Start Video Time',
    startDowntime: 'Start Downtime',
    endDowntime: 'End Downtime',
    showLiveVideo: 'Show live video from camera',
    selectStartClip: 'Select start video',
    selectEndClip: 'Select end video ',
    videoDurationIs: 'Video duration is',
    invalidTimeRange: 'Invalid time range.',
    toMoreBefore: 'Choose an earlier start date for the video.',
    toMoreAfter: 'Choose a later end date for the video.',
    maxVideoIs: 'Max video length is',
    auto: 'A',
    autoLong: 'Video requested automatically by system',
    manual: 'M',
    manualLong: 'Video requested manually by user',
    videoTrigger: 'When selected, VideoTrigger tag is configured for this problem type',
    autoDownload: 'Request video for all future events of this type',
    watchVideo: 'Watch video',
    clipIsNotReadyYet: 'Video clip is not ready yet',
    hasCamera: 'Has camera',
    videoTriggerWorkTagError: 'Tag VideoTrigger does not exists',
    thisScreenIsToSmall: 'This screen seems too small...',
    thisScreenIsToSmallInfo: 'Unfortunately, video player is not supported on your resolution',
    goBack: 'Go back',
    firstSelectCamerasIn: 'First select cameras in',
    cameras: 'Cameras',
    section: 'section',
    selected: 'Selected',
    inProgress: 'In progress',
    downloaded: 'Downloaded',
    failed: 'Failed',
    downloadList: 'Download list',
    cameraIsNotShown: 'Camera is not shown',
    cannotDownloadThisClip: 'Cannot download this clip.',
    youCanOnlyRequestClipsFromTheLast: 'You can only request clips from the last',
    fromNow: 'from now',
    downloadThisClip: 'Download this clip?',
    lineView: 'Line view',
    clearAllSettings: 'Clear all settings',
  },
  downloadStatus: {
    failed: 'Failed',
    inProgress: 'In Progress',
    success: 'Ready',
    failedOnEdge: 'Camera failed',
    noArchiveRecordings: 'Camera has no recordings',
    deleted: 'Deleted',
  },
  quests: 'Tasks',
  quest: {
    quest: 'Task',
    questTitle: 'Task title',
    questDescription: 'Task description',
    project: 'Project',
    type: 'Task type',
    state: 'Task state',
    closeToState: 'Close to state',
    assignToGroups: 'Assign to groups',
    taskDetails: 'Task details',
    files: 'Files',
    comments: 'Comments',
    noFiles: 'no files',
  },
  health: {
    health: 'Health',
    healthHistory: 'Health history',
  },
  panel: {
    number: 'Number',
    sku: 'Sku',
    selectState: 'Select state',
    selectMachine: 'Select machine',
    comment: 'Comment',
    downtimeFrom: 'Downtime from',
    ongoing: 'ongoing',
    averagePerformance: 'Average Performance',
    currentPerformance: 'Current Performance',
    currentLineStatus: 'Current line state',
    provideReasonAndComment: 'Add reason and comment',
    commentPlaceholder: 'Write your comment',
    confirmData: 'Confirm data',
    inputPlaceholder: 'Write here',
  },
  workCalendar: 'Work calendar',
  schedule: 'Schedule',
  importSchedule: 'Import schedule',
  addSchedule: 'Add schedule',
  repeatEvery: 'Repeat every',
  repeatOn: 'Repeat on',
  ends: 'Ends',
  starts: 'Starts',
  never: 'Never',
  on: 'On',
  calendar: 'Calendar',
  cycle: 'Cycle',
  deleteRecurringInstance: 'Delete recurring instance',
  editRecurringInstance: 'Edit recurring instance',
  thisInstance: 'This instance',
  thisAndFollowingInstances: 'This and following instances',
  allInstances: 'All instances',
  order: {
    addNewProperty: 'Add new property',
    sortByDate: 'Sort by date',
    sortBy: 'Sort by',
    ascending: 'ascending',
    descending: 'descending',
    quantityProduced: 'Quantity produced',
    plannedProduction: 'Planned production',
    endDate: 'End date',
    activatedDate: 'Activation date',
    totalTime: 'Duration of order',
    plannedDate: 'Start date',
    eta: 'ETA',
    delete: 'Clear filter',
  },
  noCycle: 'No cycle definition',
  users: {
    firstName: 'First name',
    lastName: 'Last name',
  },
  teams: {
    teamName: 'Team name',
  },
  schedules: {
    addLine: 'Add line to filters',
    clearLines: 'Clear all lines',
  },
  chart: {
    weekSymbol: 'W',
  },
  calendar: {
    period: 'Period',
  },
  taxonomies: {
    taxonomy: 'Taxonomy',
    classification: 'Classification',
    selectClassification: 'Select classification',
    selectClassificationLevel: 'Select classification level',
    selectClassificationNodes: 'Select classification nodes',
    defaultTaxonomy: 'Default',
    pickCategory: 'Pick category',
    selectTaxonomy: 'Select taxonomy',
    selectTaxonomyLevel: 'Select taxonomy level',
  },
  andon: {
    top3Downtimes: 'Top 3 Downtimes',
    todayOeeTrend: 'Today’s OEE Trend',
    stateDistribution: 'State distribution',
    thisWeekOeeTrend: 'This week’s OEE Trend',
    currentState: 'Current state',
    noActiveOrder: 'No active order',
  }
};
