export default (data, field, value) => {
  let schema = data;
  const pList = field.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i += 1) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }
  if (!value) {
    return schema[pList[len - 1]];
  }
  schema[pList[len - 1]] = value;
  return data;
};
