<template>
  <div>
    <LTable
      :searchable="true"
      :columns="tableFields"
      :list="filteredItems"
      :use-advanced-search="true"
      :selectable="ableToSelect"
      :selected.sync="selected"
      :sort-by="defaultSort"
    >
      <template #actions>
        <div
          v-if="selectedList.length > 0 && canEditSkus"
          class="text-nowrap text-left"
          style="margin: -2px 0"
        >
          <button
            class="btn btn-outline-secondary btn-sm icon-btn mx-1"
            style="font-size: 13px"
            @click="$emit('edit-many-groups', selectedList)"
          >
            <i class="fas fa-object-group" />
          </button>

          <button
            class="btn btn-outline-secondary btn-sm icon-btn mx-1"
            style="font-size: 13px"
            @click="$emit('delete-bulk', selectedList)"
          >
            <i class="fas fa-trash" />
          </button>
        </div>
      </template>

      <template #filters>
        <BFormGroup
          class="small"
          label="Item group"
        >
          <BFormSelect
            v-model="filterGroup"
            size="sm"
          >
            <BFormSelectOption
              :value="null"
            >
              {{ $t('general.all') }}
            </BFormSelectOption>
            <BFormSelectOption
              :value="noGroup"
            >
              {{ $t('general.nothingDefined') }}
            </BFormSelectOption>
            <BFormSelectOption
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
            >
              {{ group.name }}
            </BFormSelectOption>
          </BFormSelect>
        </BFormGroup>
      </template>

      <template #groups="{ item }">
        <span v-if="getSkuGroup(item)">
          {{ getSkuGroup(item).name }}
        </span>
      </template>
      <template
        #edit="row"
      >
        <div
          v-if="canEditSkus"
          class="text-nowrap text-right"
          style="margin: -2px 0"
        >
          <button
            class="btn btn-outline-secondary btn-sm icon-btn mx-1"
            style="font-size: 13px"
            @click="$emit('selected', row.item.id)"
          >
            <i class="fas fa-pen" />
          </button>
        </div>
      </template>

      <template
        v-if="showImportResult"
        #importResult="{ item }"
      >
        <div v-if="item.importResult.statusForUser === 'NotSaved'">
          <div class="text-danger text-center font-weight-bold">
            <i class="fas fa-times pr-2" />
            {{ $t('import.notSaved') }}
          </div>
        </div>
        <div
          v-else-if="item.importResult.statusForUser === 'ActionRequired'"
          class="d-flex align-items-center justify-content-center h-100"
        >
          <div class="text-warning text-center font-weight-bold">
            <i class="fas fa-exclamation pr-2" />
            {{ $t('import.actionRequired') }}
          </div>
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-center h-100"
        >
          <div class="text-success text-center font-weight-bold">
            <i class="fas fa-check pr-2" />
            {{ $t('import.saved') }}
          </div>
        </div>
      </template>
    </LTable>
    <div
      v-if="!items || items.length < 1"
      class="text-center font-weight-bold"
    >
      {{ $t('general.noData') }}
    </div>
  </div>
</template>

<script>
import LTable from '@core/components/LTable';

export default {
  props: {
    items: Array,
    groups: Array,
    canEditSkus: Boolean,
    showImportResult: {
      type: Boolean,
      default: false,
    },
    ableToSelect: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selected: {},
    noGroup: '#none#',
    filterGroup: null,
    defaultSort: {
      skuNo: 'down',
    },
  }),
  components: {
    LTable,
  },
  computed: {
    filteredItems() {
      if (this.filterGroup === this.noGroup) {
        return this.items.filter(i => !i.skuGroupId);
      }

      return this.items
        .filter(i => this.filterGroup === null || this.getSkuGroup(i)?.id === this.filterGroup);
    },
    selectedList() {
      return Object.keys(this.selected).filter(x => this.selected[x]);
    },
    tableFields() {
      return [
        {
          key: 'skuNo',
          text: this.$t('items.number'),
          sortable: true,
        },
        {
          key: 'description',
          text: this.$t('items.description'),
          sortable: true,
        },
        {
          key: 'groups',
          text: this.$t('items.itemGroup'),
        },
        {
          key: 'importResult',
          label: '',
          noSearch: true,
          hide: !this.showImportResult,
        },
        {
          key: 'edit',
          text: '',
        },
      ].filter(x => !x.hide);
    },

  },
  methods: {
    getSkuGroup(sku) {
      if (!sku.skuGroupId) return null;
      return this.groups.find(g => g.id === sku.skuGroupId);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .item-badge {
    font-size: inherit;
    font-weight: inherit;
  }

  .sku-group-select {
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 300px;
    margin: auto;
  }
</style>
