export default {
  nothing: 'ไม่มีการแจ้งเตือน',
  list: 'รายการ',
  subscribedOnly: 'เฉพาะที่สมัครไว้เท่านั้น',
  subscription: 'การสมัครสมาชิก',
  addSubscription: 'เพิ่มการสมัครสมาชิก',
  notificationType: 'ประเภทการแจ้งเตือน',
  pushNotSupported: 'ไม่รองรับการแจ้งเตือนในสภาพแวดล้อมนี้',
  pushDenied: 'การแจ้งเตือนถูกบล็อกแล้ว',
  enablePush: 'เปิดใช้งานการแจ้งเตือนผ่าน push',
  pushDisabled: 'ปิดใช้งานการแจ้งเตือนผ่าน push',
  pushEnabled: 'เปิดใช้งานการแจ้งเตือนผ่าน push',
  outdated: 'ล้าสมัยตั้งแต่',
  wastedThreshold: 'ค่าเกณฑ์การสูญเสีย',
  threshold: 'ค่าเกณฑ์',
  mailing: 'รับทางอีเมล',
  mySubscriptions: 'การสมัครสมาชิกของฉัน',
  usersSubscriptions: 'การสมัครสมาชิกของผู้ใช้',
  teamsSubscriptions: 'การสมัครสมาชิกของทีม',
  numberOfSubs: 'จำนวนการสมัครสมาชิก',
  subscriptionFromTeam: 'การสมัครสมาชิกนี้มาจากทีมและสามารถแก้ไขได้เฉพาะทีมโดยผู้ใช้ที่มีสิทธิ์เหมาะสม',
  type: { hints: 'เคล็ดลับ', warnings: 'คำเตือน', userNotifications: 'การแจ้งเตือน' },
  name: {
    cmmsNotification: 'บริการซ่อมบำรุง', connectionLost: 'การเชื่อมต่อของเครื่องจักรหลุด', availabilityInsight: 'ความเข้าใจในการใช้งาน', ongoingStop: 'การหยุดเครื่องในเวลาปัจจุบัน', newDowntime: 'เวลาหยุดทำงานใหม่', orderNotifications: 'เปลี่ยนสถานะคำสั่งซื้อ', thresholdExceeded: 'เกินค่าเกณฑ์', oeeThreshold: 'OEE ต่ำกว่าเป้าหมาย', orderProductionDone: 'ผลิตคำสั่งซื้อสำเร็จ', orderProductionNotAchieved: 'ปิดคำสั่งซื้อโดยมีการผลิตน้อยกว่าที่สั่งไว้', orderDelayed: 'คำสั่งซื้อล่าช้า', performanceThreshold: 'ประสิทธิภาพของเครื่องจักรต่ำกว่าที่คาดหวัง', orderWastedThresholdExceeded: 'เกินค่าเกณฑ์การสูญเสียในคำสั่งซื้อ', selectedLines: 'เลือกเส้น', allLines: 'ทุกเส้น', ignoredCounter: 'เคาน์เตอร์ที่ถูกเพิกเฉย', oeeInfo: 'สรุป OEE',
  },
  timePeriod: {
    none: 'ไม่', immediately: 'ทันที', hourly: 'สรุปการแจ้งเตือนรายชั่วโมง', daily: 'สรุปการแจ้งเตือนรายวัน',
  },
};
