<template>
  <div
    :class="{ selected, disabled }"
    class="d-flex"
    @click.stop="disabled ? true : $emit('select', !expanded)"
  >
    <div
      class="expand-handle d-flex flex-column justify-content-center "
      @click.stop="$emit('expand', !expanded)"
    >
      <div
        v-if="expandable"
        style="font-size: 18px"
      >
        <i
          v-if="!expanded"
          class="ion ion-md-add-circle-outline toggle"
        />
        <i
          v-else
          class="ion ion-md-remove-circle-outline toggle"
        />
      </div>
      <div
        v-else-if="isValue(node)"
        class="px-2 text-secondary "
      >
        <i class="ion ion-ios-cube" />
      </div>
      <div
        v-else
      >
        <i class="ion ion-md-radio-button-off" />
      </div>
    </div>

    <div
      class="row no-gutters table-row flex-grow-1"
      :class="{ highlighted }"
      @mouseover="mouseOn"
      @mouseleave="mouseOff"
    >
      <div
        v-for="(column, index) in columns"
        :key="index"
        :class="index == 0 ? (column.class || '') : `${column.class || ''} col px-2`"
        class="text-left"
        style="line-height: 18px"
        :style="{ width: index === 0 ? `${282 - paddingSum}px` : null }"
      >
        <span v-html="node[column.key]" />
        <span
          v-if="!node[column.key]"
          v-html="node[column.alternative]"
        />
        <small
          v-if="column.secondary"
          class="pl-2 text-secondary"
        >
          {{ node[column.secondary] }}
        </small>
      </div>
      <div
        v-if="columns.length === 1"
        class="col"
      />
    </div>

    <div
      v-if="!hideActions"
      style="line-height: 18px; width: 50px"
    >
      <button
        v-if="!isValue(node) && !hideAdd"
        class="btn btn-success btn-sm icon-btn mr-2"
        @click.stop="$emit('add')"
      >
        <i class="ion ion-md-add" />
      </button>
      <button
        v-if="!hideEdit"
        class="btn btn-secondary btn-sm icon-btn"
        @click.stop="$emit('edit')"
      >
        <i class="ion ion-md-create" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    node: {
      type: Object,
      default: () => ({}),
    },
    expanded: Boolean,
    highlighted: Boolean,
    expandable: Boolean,
    selected: Boolean,
    hideAdd: Boolean,
    hideEdit: Boolean,
    hideActions: Boolean,
    isValue: {
      type: Function,
      default: () => false,
    },
    paddingSum: {
      type: Number,
      default: 0,
    },
    disabled: Boolean,
  },
  methods: {
    mouseOn() {
      this.$emit('highlight', true);
    },
    mouseOff() {
      this.$emit('highlight', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.disabled {
  opacity: 0.5;
}

.table-row {
  border-top: 1px solid rgba(150, 150, 150, 0.2);
  padding: 3px 0;
  cursor: pointer;
  border-left: 3px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 10px;

  &.highlighted {
    background-color: rgba(150, 150, 150, 0.4);
  }
  &.selected {
    border-left: 3px solid $ilabo;
    border-top: 1px solid $ilabo;
    border-bottom: 1px solid $ilabo;
    text-shadow: 0 0 3px rgba($ilabo, 0.6);
  }
  .toggle {
    cursor: pointer;
  }
  &:hover {
    text-decoration: underline;
  }
}

.expand-handle {
  width: 25px;
  height: 25px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color:rgba(150, 150, 150, 0.2);
  }
}

.btn.btn-sm.icon-btn {
  height: 20px;
  width: 20px;
  font-size: 13px;
  line-height: 5px;
}
</style>
