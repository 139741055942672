<template>
  <div
    class="position-relative text-nowrap row align-items-center mx-0 justify-content-center"
    style="font-size: 14px;"
  >
    <div class="col-5">
      <Gauge
        v-if="gauge"
        :value="gauge.energyMax ? gauge.value : 0"
        :max="gauge.energyMax"
        :height="s.height"
        :width="s.width"
        class="mx-auto"
        style="position: relative; top: 8px"
      />
      <div class="text-center curr-pow">
        {{ gauge ? gauge.value : 0 | round }} kW
      </div>
    </div>
    <div class="col-5">
      <div v-if="production && size !== 'sm' && unit">
        <span v-if="energyPerUnit < 1000">
          {{ energyPerUnit }}
          <span class="unit">Wh/{{ unit }}</span>
        </span>
        <span v-else>
          {{ (energyPerUnit / 1000) | round }}
          <span class="unit">kWh/{{ unit }}</span>
        </span>
      </div>
      <div v-if="active || active === 0">
        {{ active | integer }} <span class="unit">kWh</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Gauge from '@/components/charts/power/Gauge';
import colors from '@/utils/colors';

export default {
  props: {
    active: Number,
    worstElementId: String,
    gauge: Object,
    elementId: String,
    production: Number,
    unit: String,
    size: {
      type: String,
      default: 'md',
    },
  },
  filters: {
    k(w) {
      return Math.round(w / 1000);
    },
    round(v) {
      if (!v) return 0;
      return Math.round(v * 10) / 10;
    },
  },
  components: {
    Gauge,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementName']),
    energyValue() {
      return null; // TODO: Improve API
    },
    energyMax() {
      return null; // TODO: Improve API
    },
    energyBaseline() {
      return null; // TODO: Improve API
    },
    color() {
      const { energyValue, energyMax, energyBaseline } = this;
      if (!energyValue || !energyMax) return colors('gray');
      if (energyValue >= energyMax) return colors('red');
      if (energyBaseline && energyValue >= energyBaseline) return colors('orange');
      return colors('green');
    },
    gaugeValue() {
      return Math.min((this.energyValue || 0), this.gaugeMax);
    },
    gaugeMax() {
      const max = this.energyMax;
      return max ? (max * 1.1) : 0.1;
    },
    gaugeBaseline() {
      return Math.min(this.gaugeMax, (this.energyBaseline || 0));
    },
    s() {
      switch (this.size) {
        case 'sm':
          return {
            height: 35,
            width: 35,
          };
        case 'lg':
          return {
            height: 120,
            width: 120,
          };
        default:
          return {
            height: 50,
            width: 50,
          };
      }
    },
    energyPerUnit() {
      if (!this.active || !this.production) return 0;
      return Math.round((this.active * 1000) / this.production);
    },
  },
};
</script>

<style lang="scss" scoped>
  .energy-details {
    font-size: 10px;
    font-weight: 500;
  }
  .unit {
    font-weight: 300;
  }

  .power-details {
    font-size: 12px;
    line-height: 1.1;
  }

  .curr-pow {
    width: 100%;
    font-size: 18px;
    margin-top: -15px;
  }

  .energy-sm {
    .curr-pow {
      top: 18px;
      font-size: 14px;
    }

  }

  .energy-lg {
    .curr-pow {
      top: 75px;
      width: 100%;
      font-size: 22px;
    }
    .energy-details {
      position: relative;
      top: -20px;
      font-size: 16px;
      .unit {
        font-size: 13px;
      }
    }
  }

</style>
