<template>
  <svg
    class="edges-container"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="(p, index) in connections"
      :id="`connection_${$uId}_${index}`"
      :key="index"
      :d="p"
      :class="{ active: isActive[index] }"
      :style="isActive[index] && activeStyle ? activeStyle : 'stroke-width: 2'"
      class="connection"
    />

    <g v-if="showFlow">
      <circle
        v-for="(i, index) in moving"
        :key="`m${index}`"
        class="moving-product"
        r="3"
      >
        <animateMotion
          :begin="`${((index / paths.length) * 1.5)}s`"
          dur="15s"
          repeatCount="indefinite"
        >
          <mpath :xlink:href="`#connection_${$uId}_${i}`" />
        </animateMotion>
      </circle>
    </g>

    <g v-if="buffers.length > 0">
      <g
        v-for="(p, index) in bufferPositions"
        :key="`buffer-${index}`"
        :transform="`translate(${p.x} ${p.y})`"
      >
        <g v-if="buffers[index]">
          <rect
            :width="getBufferBoxWidth(buffers[index])"
            height="60"
            :x="-(getBufferBoxWidth(buffers[index]) / 2)"
            rx="7"
            ry="7"
            style="fill:rgb(255,255,255);stroke-width:1;stroke:rgb(88,134,193)"
          />
          <text
            :x="0"
            :y="0"
            text-anchor="middle"
          >
            <tspan
              x="0"
              y="20"
              font-size="12"
            >
              {{ buffers[index].name || '-' }}
            </tspan>
            <tspan
              x="0"
              dy="26"
              font-size="17"
            >
              {{ buffers[index].value || '-' }}
              {{ buffers[index].value && buffers[index].unit || '' }}
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

const samePos = (p1, p2) => p1.x === p2.x && p1.y === p2.y;

export default {
  props: {
    paths: {
      type: Array,
      default: () => [],
    },
    buffers: {
      type: Array,
      default: () => [],
    },
    showFlow: Boolean,
    activeEdges: {
      type: Array,
      default: () => [],
    },
    activeStyle: String,
    connectionType: {
      type: String,
      default: 'round',
    },
  },
  computed: {
    bufferPositions() {
      return this.paths
        .filter(({ fromPos, toPos }) =>
          !Number.isNaN(fromPos.x) && !Number.isNaN(fromPos.y)
          && !Number.isNaN(toPos.x) && !Number.isNaN(toPos.y))
        .map(({ fromPos, toPos }) => {
          const x = (fromPos.x + toPos.x) / 2;
          const y = ((fromPos.y + toPos.y) / 2) - 30;
          return { x, y };
        });
    },
    connections() {
      return this.paths
        .filter(({ fromPos, toPos }) =>
          !Number.isNaN(fromPos.x) && !Number.isNaN(fromPos.y)
          && !Number.isNaN(toPos.x) && !Number.isNaN(toPos.y))
        .map(({ fromPos, toPos }) => this.getPath(fromPos, toPos));
    },
    isActive() {
      return this.paths.reduce((acc, edge, i) => {
        const index = this.activeEdges.findIndex(a => samePos(a.fromPos, edge.fromPos) && samePos(a.toPos, edge.toPos));

        if (index !== -1) {
          this.$set(acc, i, true);
        }
        return acc;
      }, {});
    },
    moving() {
      return [...new Array(10 * this.paths.length)]
        .map((_, i) => (i % this.paths.length))
        .filter(i => this.isActive[i]);
    },
  },
  methods: {
    getPath(start, end) {
      if (this.connectionType === 'straight') {
        return `
          M${start.x} ${start.y}
          L ${end.x} ${end.y}
        `;
      }
      const dx = Math.abs(end.x - start.x);
      const dy = Math.abs(end.y - start.y);
      const q = Math.min(Math.abs(dx / 2), Math.abs(dy / 2)) / 2;
      if (start.y === end.y || start.x === end.x) {
        return `M${start.x} ${start.y} L ${end.x} ${end.y}`;
      }
      if (start.y > end.y && start.x < end.x) {
        return `
          M${start.x} ${start.y}
          l ${(dx / 2) - q} 0
          q ${q} 0, ${q} ${-q}
          l 0 ${-(dy - (2 * q))}
          q 0 ${-q}, ${q} ${-q}
          L ${end.x} ${end.y}
        `;
      }
      if (start.y > end.y && start.x > end.x) {
        return `
          M${start.x} ${start.y}
          l ${(-dx / 2) + q} 0
          q ${-q} 0, ${-q} ${-q}
          l 0 ${-(dy - (2 * q))}
          q 0 ${-q}, ${-q} ${-q}
          L ${end.x} ${end.y}
        `;
      }
      if (start.y < end.y && start.x > end.x) {
        return `
          M${start.x} ${start.y}
          l 0 ${(dy / 2) - q}
          q 0 ${q}, ${-q} ${q}
          l ${-dx + (2 * q)} 0
          q ${-q} 0, ${-q} ${q}
          L ${end.x} ${end.y}
        `;
      }
      return `
        M${start.x} ${start.y}
        l 0 ${(dy / 2) - q}
        q 0 ${q}, ${q} ${q}
        l ${dx - (2 * q)} 0
        q ${q} 0, ${q} ${q}
        L ${end.x} ${end.y}
      `;
    },
    getBufferBoxWidth(buffer) {
      const size = buffer.name.length * 6.5;
      return size > 120 ? size : 120;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .edges-container {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .connection {
    stroke: $grey;
    fill: transparent;
    transition: stroke-width 200ms;
    &.active {
      stroke: $ilabo;
    }
  }

  .moving-product {
    fill: transparent;
    stroke: $ilabo;
  }
</style>
