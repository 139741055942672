import { activityStatus } from '@/utils/dictionary';

const overlaps = (a, b) => {
  if (a.static || b.static) return false;
  if (a.start === b.end || b.start === a.end) return false;
  return a.start < b.end && a.end > b.start;
};

const statusOrder = [
  activityStatus.completed,
  activityStatus.started,
  activityStatus.activated,
  activityStatus.released,
  activityStatus.suspended,
  activityStatus.draft,
  activityStatus.unknown,
];

export const sortActivitiesStatus = (a, b) => {
  const status = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  return status !== 0 ? status : a.start - b.start;
};

export default initialList => {
  const list = initialList.sort(sortActivitiesStatus);

  if (!list || list.length === 0) return [];
  const initial = list.map(el => ({ ...el, conflicts: 0 }));

  const parsed = initial.reduce(({ res, parse }) => {
    const curr = parse[0];
    res.push(curr);
    return {
      res,
      parse: parse.slice(1).map(el =>
        ((
          !overlaps(el, curr)
            || [activityStatus.completed, activityStatus.started].includes(el.status)
        ) ? el
          : ({ ...el, conflicts: Math.max(el.conflicts, curr.conflicts + 1) }))),
    };
  }, { res: [], parse: initial.slice() });

  return parsed.res;
};
