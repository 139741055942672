import lang from '@/lang';
import colors from '@/utils/colors';

export default {
  getters: {
    activityTypes: () => ({
      CHOVER: {
        color: colors('grey'),
        name: lang.t('orders.activities.changeover'),
      },
      ZPROD: {
        color: colors('green_pastel'),
        name: lang.t('orders.activities.order'),
      },
      CIP: {
        color: colors('teal_pastel'),
        name: lang.t('orders.activities.cip'),
      },
    }),
  },
};
