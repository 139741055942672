export default {
  directives: {
    ellipsis: {
      bind(el) {
        el.addEventListener('mouseover', evt => {
          const targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            targetEl.setAttribute('title', evt.target.textContent);
          } else {
            // eslint-disable-next-line no-unused-expressions
            targetEl.hasAttribute('title') && targetEl.removeAttribute('title');
          }
        });
      },
    },
  },
};
