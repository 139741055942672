<template>
  <div class="px-2">
    <template
      v-for="b in breadcrumb"
    >
      <div
        v-if="b.key !== 'general'"
        :key="`${b.key}line`"
        class="box-style"
        :class="{
          selected: selected.index >= b.index,
          filled: isStepFilled(b),
        }"
      />
      <div
        :key="`${b.key}title`"
        class="row"
        style="align-items: center"
        @click="goTo(b)"
      >
        <div
          class="circle-box position-relative"
          :class="{
            selected: selected.index >= b.index,
            filled: isStepFilled(b),
          }"
        >
          <i
            v-if="hasStepError(b)"
            class="fas fa-exclamation-circle text-danger error-step"
          />
        </div>
        <div
          class="name-style"
          :class="{
            color: selected.index >= b.index,
            filled: isStepFilled(b),
            selected: selected.index === b.index,
          }"
        >
          {{ b.title }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    selected: Object,
    breadcrumb: Array,
    activity: Object,
    activityAlreadyExists: Boolean,
    loadedActivityAlreadyExists: Boolean,
    type: String,
    generalError: Boolean,
    configError: Boolean,
    quantityError: Boolean,
    datesError: Boolean,
  },
  methods: {
    goTo(page) {
      if (this.activityAlreadyExists || !this.loadedActivityAlreadyExists) return;

      const {
        externalId,
        productId,
      } = this.activity;

      if ((page.key === 'config' && externalId)
        || (page.key === 'quantity' && productId)
        || (page.key === 'general' && externalId)
        || (page.key === 'dates' && this.type !== 'Order' && externalId)
        || (page.key === 'dates' && this.activity.orderedQuantity.value
        && this.activity.orderedQuantity.unit)) {
        this.$emit('selectedPage', page);
      }
    },
    isStepFilled(b) {
      if (b.index === this.selected.index) return false;

      const {
        externalId,
        productId,
        orderedQuantity,
      } = this.activity;

      switch (b.key) {
        case 'config':
          return externalId;
        case 'quantity':
          return productId && orderedQuantity?.value > 0;
        case 'general':
          return externalId;
        case 'dates':
          return (this.type !== 'Order' && externalId)
            || (this.activity.orderedQuantity.value && this.activity.orderedQuantity.unit);
        default:
          return false;
      }
    },
    hasStepError(b) {
      switch (b.key) {
        case 'config':
          return this.configError;
        case 'quantity':
          return this.quantityError;
        case 'general':
          return this.generalError;
        case 'dates':
          return this.datesError;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.name-style {
  font-size: 1.1rem;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  color: gray;
  opacity: 0.75;

  &.selected {
    text-decoration: underline;
  }

  &.color, &.filled {
    color: black;
  }

  &:hover, &.selected {
    opacity: 1;
  }
}

.box-style {
  border-left: 3px solid;
  height: 60px;
  margin-left: -9px;
  opacity: 0.8;
  color: gray;

  &.selected, &.filled {
    color: $ilabo;
  }
}

.circle-box {
  margin-right: 7px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  opacity: 0.8;
  background-color: gray;

  &.selected, &.filled {
    background-color: $ilabo;
  }
}

.error-step {
  position: absolute;
  left: 0;
  top: -1px;
  background-color: white;
}

</style>
