<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <div v-if="!hideBackButton">
          <button
            class="btn btn-secondary icon-btn"
            @click="$emit('back')"
          >
            <i class="fas fa-chevron-left" />
          </button>
        </div>
      </div>
      <div
        v-if="templateName"
        class="badge template-pill"
      >
        {{ templateName }}
      </div>
    </div>
    <FileTree
      class="tree-lg"
      style="margin-left: -10px"
      :list="formattedItemsList"
      :root="root"
      :simulation="simulation"
      :selectable="true"
      :is-expanded="true"
      :hide-root="true"
    />
    <div
      v-if="!formattedItemsList.length && error"
      class="mt-4"
    >
      <div class="no-data">
        {{ $t('general.noData') }}
      </div>
    </div>
    <div
      v-else-if="!entities.length"
      class="mt-4"
    >
      <div class="no-data">
        {{ $t('error.importError') }}
      </div>
    </div>
  </div>
</template>

<script>
import FileTree from '@/components/tables/FileTree';

export default {
  props: {
    importId: String,
    entities: Array,
    simulation: Boolean,
    error: String,
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    templateName: String,
  },
  components: {
    FileTree,
  },
  computed: {
    root() {
      return {
        id: this.importId,
        type: 'File',
        isActive: true,
      };
    },
    formattedItemsList() {
      const keysList = {};
      let nextKey = 0;
      this.entities.forEach((e => {
        const key = `${e.entityId}_${nextKey}`;
        nextKey += 1;
        keysList[`${e.entityId}_${e.rootEntityId}`] = key;
      }));

      return Object.entries(this.entities
        .reduce((acc, curr) => {
          const key = `${curr.entityId}_${curr.rootEntityId}`;
          const rootKey = `${curr.rootEntityId}_${curr.rootEntityId}`;
          const newCurr = {
            ...curr,
            nameId: curr.entityId,
            entityId: keysList[key],
            rootEntityId: keysList[rootKey],
          };
          if (acc[newCurr.entityId]) {
            acc[newCurr.entityId].push(newCurr);
          } else {
            acc[newCurr.entityId] = [newCurr];
          }
          return acc;
        }, {}))
        .map(([key, fields]) => ({
          id: key,
          nameId: fields[0].nameId,
          parentId: fields[0].rootEntityId === fields[0].entityId
            ? this.importId
            : fields[0].rootEntityId,
          type: fields[0].type,
          fields,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
  .text-style {
    font-weight: 600;
    font-size: 1.3em;
  }

  .no-data {
    font-size: 1.75em;
    opacity: 0.6;
    font-weight: 600;
    padding-block: 100px;
    text-align: center;
  }

  .template-pill {
    opacity: 0.8;
    border-radius: 3px;
    background-color:rgba(150, 150, 150, 0.2);
    font-size: 1rem;
    margin-left: 11px;
  }
</style>
