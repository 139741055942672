<template>
  <div class="mt-2 d-flex justify-content-end">
    <CorrectiveActionPopover :event-id="eventId">
      <div class="wand">
        <i class="icon-corrective-action" />
      </div>
    </CorrectiveActionPopover>
  </div>
</template>

<script>
import CorrectiveActionPopover from '@/components/popovers/CorrectiveActions';

export default {
  props: {
    data: Object,
  },
  components: {
    CorrectiveActionPopover,
  },
  computed: {
    eventId() {
      if (!this.data || !this.data.Event) return null;
      return this.data.Event;
    },
    correctiveActions() {
      // Pass as argument
      // return this.eventCorrectiveActions(this.eventId);
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
