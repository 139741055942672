<template>
  <div
    class="area-oee mt-3 mt-sm-0"
    style="max-width: 300px; height: 170px; margin: auto; position: relative;"
  >
    <div
      class="oee-line-chart chart-box text-center"
      style="height: 85px"
    >
      <OEEChart
        :value="oee"
        :target="target ? target.oee : null"
        class="oee-chart main"
        :label="$t('oee.oee')"
      />
      <div class="teep">
        <OEEChart
          :value="teep"
          class="chart"
        />
        <span class="label">
          {{ $t('oee.teep') }}
        </span>
      </div>
    </div>
    <div
      class="row position-absolute"
      style="left: 15px; width: 100%"
    >
      <div class="col-4 chart-box text-center">
        <OEEChart
          :weight="10"
          :value="oeeA"
          :target="target ? target.oeeA : null"
          style="height: 60px;"
        />
        <span class="oee-chart-desc text-overflow-center"> {{ $t('oee.availability') }}</span>
      </div>
      <div class="col-4 chart-box text-center">
        <OEEChart
          :weight="10"
          :value="oeeP"
          :target="target ? target.oeeP : null"
          style="height: 60px;"
        />
        <span class="oee-chart-desc text-overflow-center">{{ $t('oee.performance') }}</span>
      </div>
      <div class="col-4 chart-box text-center">
        <OEEChart
          :weight="10"
          :value="oeeQ"
          :target="target ? target.oeeQ : null"
          style="height: 60px;"
        />
        <span class="oee-chart-desc">{{ $t('oee.quality') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import OEEChart from './OEE';

export default {
  props: {
    oee: Number,
    oeeA: Number,
    oeeQ: Number,
    oeeP: Number,
    teep: Number,
    target: Object,
  },
  components: {
    OEEChart,
  },
};
</script>

<style lang="scss" >
  .area-oee {

    .chart-box {
      .OEEpercent {
        font-size: 13px;
      }
    }
    .oee-chart {
      &.main {
        height: 95px;
        overflow: hidden;
      }
      .OEEpercent {
        font-size: 18px;
      }
      .OEEtitle {
        bottom: 2px;
        position: relative;
        font-size: 14px;
        font-weight: normal;
        font-weight: 300;
      }
    }

    .oee-chart-desc {
      font-size: 12px;
      position: relative;
      top: -10px;
    }
  }

  .teep {
    position: absolute;
    top: 0;
    right: 16px;
    height: 45px;
    width: 50px;

    .label {
      position: relative;
      top: -9px;
      font-size: 10px;
    }
  }

  .teep .donut-label.OEEpercent {
    font-size: 12px;
  }

  .oee-line-chart {
    position: relative;
  }

  .info-icon {
    position: absolute;
    font-size: 22px;
    bottom: -8px;
    right: 50%;
    margin-right: -60px;
    transition: transform 300ms;
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
    }
  }

</style>
