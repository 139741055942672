<template>
  <div v-if="reseted">
    <video
      ref="video"
      class="video-js"
    >
      <track
        v-for="(crtTrack,index) in trackList"
        :key="index"
        :kind="crtTrack.kind"
        :label="crtTrack.label"
        :src="crtTrack.src"
        :srcLang="crtTrack.srcLang"
        :default="crtTrack.default"
      >
    </video>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import './player.css';
import './plugins/videojs-markers-plugin/videojs.markers.plugin.css';
import './plugins/videojs-markers-plugin/videojs-markers-plugin';
import _videojs from 'video.js';

const videojs = window.videojs || _videojs;

export default {
  name: 'Videoplayer',
  props: {
    enableTimeEvent: {
      type: Boolean,
      default: false,
    },
    crossOrigin: {
      type: String,
      default: '',
    },
    playsinline: {
      type: Boolean,
      default: false,
    },
    customEventName: {
      type: String,
      default: 'statechanged',
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Array,
      default: () => [],
    },
    globalOptions: {
      type: Object,
      default: () => ({
        // https://docs.videojs.com/tutorial-options.html#fluid
        autoplay: true,
        controls: true,
        language: 'en',
        inactivityTimeout: 0,
        preload: 'auto',
        fluid: false,
        techOrder: ['html5'],
        plugins: {},
      }),
    },
    globalEvents: {
      type: Array,
      default: () => [],
    },
    trackList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      player: null,
      reseted: true,
    };
  },
  watch: {
    options: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(options, oldOptions) {
        this.dispose(() => {
          if (options && options.sources && options.sources.length) {
            this.initialize();
          }
        });
      },
    },
  },
  methods: {
    initialize() {
      const { enableTimeEvent } = this;
      const self = this;
      const videoObj = this.$refs.video;
      // videojs options
      const videoOptions = { ...this.globalOptions, ...this.options };
      // ios fullscreen
      if (this.playsinline) {
        videoObj.setAttribute('playsinline', this.playsinline);
        videoObj.setAttribute('webkit-playsinline', this.playsinline);
        videoObj.setAttribute('x5-playsinline', this.playsinline);
        videoObj.setAttribute('x5-video-player-type', 'h5');
        videoObj.setAttribute('x5-video-player-fullscreen', false);
      }
      // cross origin
      if (this.crossOrigin !== '') {
        videoObj.crossOrigin = this.crossOrigin;
        videoObj.setAttribute('crossOrigin', this.crossOrigin);
      }
      // avoid error "VIDEOJS: ERROR: Unable to find plugin: __ob__"
      if (videoOptions.plugins) {
        // eslint-disable-next-line no-underscore-dangle
        delete videoOptions.plugins.__ob__;
      }
      // emit event
      const emitPlayerState = (event, value) => {
        if (event) {
          this.$emit(event, this.player);
        }
        if (value) {
          this.$emit(this.customEventName, { [event]: value });
        }
      };
      // player
      this.player = videojs(videoObj, videoOptions, function vjsFunc() {
        // events
        const DEFAULT_EVENTS = [
          'loadeddata',
          'canplay',
          'canplaythrough',
          'play',
          'pause',
          'waiting',
          'playing',
          'ended',
          'error',
        ];
        const events = DEFAULT_EVENTS.concat(self.events).concat(
          self.globalEvents,
        );
        // watch events
        const onEdEvents = {};
        for (let i = 0; i < events.length; i += 1) {
          if (
            typeof events[i] === 'string'
              && onEdEvents[events[i]] === undefined
          ) {
            (event => {
              onEdEvents[event] = null;
              this.on(event, () => {
                emitPlayerState(event, this);
              });
              if (enableTimeEvent) {
                this.on('timeupdate', function epsFunc() {
                  emitPlayerState('timeupdate', this.currentTime());
                });
              }
            })(events[i]);
          }
        }
        // player readied
        self.$emit('ready', this);
      });
    },
    dispose(callback) {
      if (this.player && this.player.dispose) {
        // eslint-disable-next-line no-underscore-dangle
        if (this.player.techName_ !== 'Flash') {
          if (this.player.pause) {
            this.player.pause();
          }
        }
        this.player.dispose();
        this.player = null;
        this.$nextTick(() => {
          this.reseted = false;
          this.$nextTick(() => {
            this.reseted = true;
            this.$nextTick(() => {
              if (callback) {
                callback();
              }
            });
          });
        });
      }
    },
  },
  mounted() {
    if (!this.player) {
      this.initialize();
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.dispose();
    }
  },
};

</script>

<style scoped>

</style>
