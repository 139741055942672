<template>
  <div>
    <div
      v-if="haveInheritedData"
      class="d-flex justify-content-end"
    >
      <div
        v-tippy="{ arrow: true }"
        class="small text-secondary text-right performance-from-group"
        :content="$t('items.inheritedFromGroup')"
      >
        <i class="fas fa-object-group pr-1" />
        <span v-if="inheritedEffort">
          {{ inheritedEffort.manhours }} / {{ inheritedEffort.volume }} {{ inheritedEffort.volumeUnit }}
        </span>
        <span>-</span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <BFormInput
        v-model="manhours"
        :disabled="pending"
        type="number"
        size="sm"
        style="width: 84px"
        class="mr-1 time-select"
        @input="publish"
      />

      <span class="slash-spacing">/</span>
      <BFormInput
        v-model="volume"
        :disabled="pending"
        type="number"
        size="sm"
        style="width: 84px"
        class="mr-1 time-select"
        @input="publish"
      />
      <select
        v-model="volumeUnit"
        :disabled="pending"
        class="form-control form-control-sm time-select"
        style="width: 68px"
        @change="publish"
      >
        <option
          v-for="u in units"
          :key="u.code.toLowerCase()"
          :value="u.code.toLowerCase()"
        >
          {{ u.code }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    pending: Boolean,
    value: Object,
    haveInheritedData: Boolean,
    inheritedEffort: Object,
  },
  data: () => ({
    manhours: '',
    volume: '',
    volumeUnit: '',
  }),
  computed: {
    ...mapState({
      units: state => state.units.list,
    }),
    unitsOptions() {
      if (!this.units) return [];
      return this.units
        .map(u => ({
          value: u.code.toLowerCase(),
          text: u.code,
        }))
        .concat({
          value: null,
          text: '?',
        });
    },
  },
  methods: {
    publish() {
      this.$emit('update:value', {
        manhours: this.manhours,
        volume: this.volume,
        volumeUnit: this.volumeUnit,
      });
    },
  },
  created() {
    if (this.value) {
      this.manhours = this.value.manhours;
      this.volume = this.value.volume;
      this.volumeUnit = (this.value.volumeUnit || '').toLowerCase();
    }
  },
};
</script>

<style lang="scss" scoped>
  .slash-spacing {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 500;
  }

  .performance-from-group {
    font-size: 12px;
  }
</style>
