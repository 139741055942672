<template>
  <SimpleScheduleLog
    v-if="productionUpdateLog.length"
    :start-date="startDate"
    :end-date="endDate"
    :log="productionUpdateLog"
    :height="30"
    :conflicts="false"
    :hide-entry-date="[!incremental, false]"
    :editable="[false, true]"
    edited-id="new"
    :display-axis="true"
    :interactive-tooltip="true"
    no-utc-offset
    @applyEdit="$emit('applyEdit', $event)"
  >
    <div slot-scope="scope">
      <ManualWorkPopover
        :manual-work-log="scope.block"
        :unit="unit"
        :deletable="scope.block.isDeletable"
        @removed="$emit('removed', $event)"
      />
    </div>
    <template #body="{ block }">
      <span v-if="block.type === manualResourceType.production">
        {{ block.done }} {{ unit || '' }} <span class="text-secondary">|</span>
        <span class="light-red">
          {{ block.wasted }} {{ unit || '' }}
        </span>
      </span>
      <span v-else-if="block.type === manualResourceType.humanResources">
        {{ minutesToDuration(block.totalManhours) }}
      </span>
    </template>
  </SimpleScheduleLog>
</template>

<script>
import moment from 'moment';
import colors from '@/utils/colors';
import ManualWorkPopover from '@/components/schedule/popovers/ManualWorkPopover';
import { manualResourceType } from '@/utils/dictionary';
import { minutesToDuration } from '@/utils/time';
import SimpleScheduleLog from '@/components/schedule/SimpleScheduleLog';

export default {
  props: {
    log: {
      type: Array,
      default: () => [],
    },
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      default: Math.floor(Date.now() / 1000),
      type: Number,
    },
    blockedUntil: String,
    unit: String,
    incremental: Boolean,
  },
  data: () => ({
    manualResourceType,
    minutesToDuration,
  }),
  components: {
    SimpleScheduleLog,
    ManualWorkPopover,
  },
  computed: {
    productionUpdateLog() {
      return this.log
        .filter(l => l.id === 'new')
        .map(log => ({
          ...log,
          hideTooltip: true,
          start: moment(log.from).unix(),
          end: moment(log.to).unix(),
          limit: [moment(this.blockedUntil).unix() + 60, this.endDate],
          color: log.id === 'new' ? colors('blue') : this.$color('grey'),
          id: log.id,
          isDeletable: log.isDeletable,
          name: log.name,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.light-red {
  color: lighten($red, 25%);
}
</style>
