<template>
  <div style="max-width: 1000px">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div>
          <button
            class="btn btn-secondary icon-btn"
            @click="navigateBack"
          >
            <i class="fas fa-chevron-left" />
          </button>
        </div>
        <h4 class="mb-0 ml-3">
          {{ $t('import.uploadFile') }}
        </h4>
      </div>
    </div>
    <div class="my-4 col-7 pl-0">
      <span class="font-weight-bold">
        {{ $t('import.template') }}
      </span>
      <BasicSelect
        :selected-option="selectedTemplate"
        :placeholder="$t('general.select')"
        :options="templatesForDropdown"
        @select="onSelect"
      />
    </div>
    <div>
      <VueDropzone
        v-if="!pending"
        id="dropzone"
        style="border-radius: 20px"
        :class="selectedTemplate.id ? 'cursor' : 'disabled'"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        @vdropzone-file-added="uploaded"
      >
        <template v-if="dropzoneOptions">
          <i
            v-if="selectedTemplate.id"
            class="fas fa-download"
          />
          {{ selectedTemplate.id ? $t('import.upload') : $t('import.toUploadFilePickTemplate') }}
        </template>
      </VueDropzone>

      <p class="mb-0 mt-3">
        <i class="fas fa-filter mr-1" />
        {{ $t('orders.status') }}
      </p>
      <BButtonGroup size="sm">
        <BButton
          v-for="key in filterKeys"
          :key="key"
          :variant="filters[key] ? 'outline-ilabo' : 'outline-secondary'"
          class="text-nowrap"
          @click="filters[key] = !filters[key]"
        >
          <i
            class="fas mr-1"
            :class="{'fa-eye': filters[key], 'fa-eye-slash': !filters[key]}"
          />
          {{ $t('import.status.' + key) }}
        </BButton>
      </BButtonGroup>

      <div
        v-if="importsPending && uploadedFiles.length === 0"
        class="text-center pt-4"
      >
        <Loader />
      </div>
      <div
        v-else-if="error"
        class="text-danger d-flex mt-2"
      >
        <div>
          <i class="fas fa-times pr-2" />
        </div>
        {{ error.detail || $t('error.importNotAvailable') }}
      </div>
      <div
        v-if="uploadedFiles"
        class="mt-4"
      >
        <div
          v-for="importedFile in uploadedFiles"
          :key="importedFile.importId"
          class="d-flex align-items-center"
          @click="$emit('showView', importedFile.importId)"
        >
          <div
            class="d-flex mx-2 status-icon text-center align-items-center justify-content-center"
            :style="{
              'background-color': importedFile.statusDetails.backgroundColor,
              'color' : importedFile.statusDetails.color
            }"
          >
            <i :class="importedFile.statusDetails.icon" />
          </div>
          <div class="d-flex flex-grow-1 align-items-center justify-content-between files-row">
            <div class="d-flex justify-content-between">
              <div class="pr-2 font-weight-bold">
                {{ importedFile.fileName }}
              </div>
              <div
                v-if="templateName(importedFile.functionId)"
                class="badge text-secondary ml-2 template-pill"
              >
                {{ templateName(importedFile.functionId) }}
              </div>
            </div>
            <div class="text-secondary">
              {{ formatDate(importedFile.created) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="pending"
        class="pt-4 text-center"
      >
        <Loader />
      </div>
      <div v-else />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BasicSelect } from 'vue-search-select';
import VueDropzone from 'vue2-dropzone';
import moment from 'moment';
import { importStatus } from './importStatus';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  props: {
    title: String,
    parent: String,
  },
  data() {
    return {
      importsPending: false,
      pending: false,
      error: false,
      templates: null,
      imports: null,
      selectedTemplate: {
        id: null,
        text: '',
      },
      filters: {
        parsingFailed: true,
        faulted: true,
        applied: true,
        appliedWithErrors: true,
        scheduled: true,
      },
    };
  },
  components: {
    VueDropzone,
    BasicSelect,
  },
  computed: {
    ...mapGetters(['plantId']),
    filterKeys() {
      return [
        'applied',
        'appliedWithErrors',
        'parsingFailed',
      ];
    },
    uploadedFiles() {
      return (this.imports || []).map(j => ({
        ...j,
        statusDetails: importStatus(j.status || ''),
      }))
        .filter(i => this.templateName(i.functionId))
        .filter(i => {
          const statuses = [];
          Object.keys(this.filters).forEach(k => {
            if (this.filters[k]) statuses.push(k.toLowerCase());
          });
          return statuses.includes(i.status.toLowerCase());
        })
        .sort((a, b) => b.created.localeCompare(a.created));
    },
    dropzoneOptions() {
      return {
        url: 'packos.cloud',
        dictDefaultMessage: this.$t('import.upload'),
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {},
      };
    },
    templatesForDropdown() {
      if (!this.templates) return [];
      return this.templates
        .map(x => ({ id: x.id, text: x.name }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  methods: {
    ...mapActions([
      'getTemplates',
      'getImports',
      'importFile',
    ]),
    templateName(v) {
      if (!this.templates) return null;
      return this.templates.find(t => t.id === v)?.name || '';
    },
    successMessage() {
      this.$bvToast.toast(this.$t('import.dataSaved'), {
        title: this.$t('import.saved'),
        autoHideDelay: 10000,
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
      });
    },
    errorMessage(data) {
      this.$root.$bvToast.toast(data.title, {
        title: data.type,
        autoHideDelay: 10000,
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
      });
    },
    requestTemplates() {
      this.getTemplates({
        params: {
          query: {
            plantId: this.plantId,
            visibility: 'Visible',
          },
        },
      })
        .then(({ data }) => {
          this.templates = data;
          if (this.templatesForDropdown.length > 0) {
            this.selectedTemplate = this.templatesForDropdown[0];
          }
        });
    },
    requestImport() {
      this.importsPending = true;
      if (this.loop) {
        clearTimeout(this.loop);
      }

      this.getImports({
        params: {
          query: {
            plantId: this.plantId,
          },
        },
      })
        .then(({ data }) => {
          this.imports = data;
          this.loop = setTimeout(() => {
            this.requestImport();
          }, 10000);
        }).finally(() => {
          this.importsPending = false;
        });
    },
    onSelect(item) {
      if (item) {
        this.selectedTemplate = item;
      }
    },
    formatDate(date) {
      return moment(date).format('LLLL');
    },
    uploaded(file) {
      this.pending = true;

      const formData = new FormData();
      formData.append('File', file);
      formData.append('Data.PlantId', this.plantId);
      formData.append('Data.FunctionId', this.selectedTemplate.id);
      this.importFile({
        data: formData,
      })
        .then(({ data }) => {
          this.successMessage();
          this.$emit('showView', data.id);
        })
        .catch(({ response: { data } }) => {
          this.error = data;
          this.errorMessage(data);
        })
        .finally(() => {
          this.pending = false;
          this.requestImport();
        });
    },
    navigateBack() {
      this.$router.push(`/${this.plantId}/${this.parent}`);
    },
  },
  destroyed() {
    if (this.loop) {
      clearTimeout(this.loop);
    }
  },
  created() {
    this.requestTemplates();
    this.requestImport();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.files-row {
  border-radius: 10px;
  margin: 5px 0px;
  cursor: pointer;
  padding: 10px 30px;
  font-size: 0.9rem;
  background-color: #eee;

  &:hover {
    background-color: darken(#eee, 5);
  }
}

.status-icon {
  height: 30px;
  color: white;
  width: 30px;
  flex-shrink: 0;
  border-radius: 50%;
}

.info-style {
  font-size: 1.4em;
  font-weight: 600;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.template-pill {
  opacity: 0.8;
  border-radius: 3px;
  background-color: white;
  font-size: 0.688rem;
  align-self: center;
}
</style>
