<template>
  <div>
    <div class="mt-2">
      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label class="title-style">
            {{ $t('orders.orderedQuantity') }}
          </label>
          <BFormInput
            v-model="orderedValue"
            :placeholder="$t('orders.orderedQuantity')"
            :state="numberCheck(orderedValue) | ifValidated(validate)"
          />
          <BFormInvalidFeedback
            v-if="orderedValue < 0"
          >
            {{ $t('activities.typeCorrectValue') }}
          </BFormInvalidFeedback>
          <p
            v-if="isOrderedQuantityError"
            class="text-danger font-weight-bold pt-1 mb-0"
          >
            <i class="fas fa-exclamation-circle" />
            {{ $t('activities.validationErrors.orderedQuantityError') }}
          </p>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label class="title-style mt-2">
            {{ $t('orders.quantityUnit') }}
          </label>
          <BFormSelect
            v-if="units && !propertiesPending"
            v-model="unit"
            :disabled="disabled || unitFieldDisabled"
            :state="!!unit | ifValidated(validate)"
          >
            <option
              v-for="u in units"
              :key="u.id"
              :value="u.code"
            >
              {{ u.code }}
            </option>
          </BFormSelect>
          <div
            v-else
            class="text-center"
          >
            <Loader size="25px" />
          </div>
          <p
            v-if="isOrderedQuantityUnitError"
            class="text-danger font-weight-bold pt-1 mb-0"
          >
            <i class="fas fa-exclamation-circle" />
            {{ $t('activities.validationErrors.orderedQuantityUnitError') }}
          </p>
        </div>
      </div>
    </div>

    <Error
      v-if="error && !errorCheck"
      :message="error.detail"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import activityForm from '@/mixins/activityForm';
import { activityStatus, activityValidationErrorCodes } from '@/utils/dictionary';

export default {
  props: {
    activity: Object,
    edited: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      propertiesPending: false,
      orderedValue: null,
      unit: null,
    };
  },
  mixins: [activityForm()],
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementProperty']),
    ...mapState({
      units: state => state.units.list,
    }),
    lineUnit() {
      if (!this.activity.lineId) return null;
      return this.elementProperty(this.activity.lineId, 'DefaultUnit');
    },
    errorCheck() {
      if (this.numberCheck(this.orderedValue) && this.unit) return true;
      return false;
    },
    defaultUnit() {
      if (!this.activity.lineId) return null;
      return this.elementProperty(this.activity.lineId, 'DefaultOrderUnit');
    },
    unitFieldDisabled() {
      if (!this.activity.status) return false;

      return ![
        activityStatus.draft,
        activityStatus.released,
        activityStatus.activated,
        activityStatus.started,
        activityStatus.suspended,
      ].includes(this.activity.status);
    },
    isOrderedQuantityError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return null;

      return this.validationErrors.find(e => e.code === activityValidationErrorCodes.orderedQuantityErrorCode)
        && !this.orderedValue;
    },
    isOrderedQuantityUnitError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return null;

      return this.validationErrors.find(e => [
        activityValidationErrorCodes.invalidQuantityUnitErrorCode,
      ].includes(e.code)) && !this.unit;
    },
  },
  watch: {
    isOrderedQuantityError(val) {
      if (val) {
        this.$emit('solve-errors', [
          activityValidationErrorCodes.orderedQuantityErrorCode,
        ]);
      }
    },
    isOrderedQuantityUnitError(val) {
      if (val) {
        this.$emit('solve-errors', [
          activityValidationErrorCodes.invalidQuantityUnitErrorCode,
        ]);
      }
    },
    orderedValue(val) {
      this.validateModel();
      this.$emit('orderedValue', val);
      this.$emit('errorCheck', this.errorCheck);
    },
    unit(val) {
      this.validateModel();
      this.$emit('unit', val);
      this.$emit('errorCheck', this.errorCheck);
    },
  },
  methods: {
    ...mapActions('element', ['getElementsProperties']),
    numberCheck(v) {
      const number = Number(v);
      return !Number.isNaN(number) && number > 0;
    },
    validateModel() {
      this.validate = true;
      return this.errorCheck;
    },
    getDefaultUnit(lineId) {
      this.propertiesPending = true;
      this.getElementsProperties({
        params: {
          plantId: this.plantId,
          query: {
            id: lineId,
          },
        },
      })
        .then(() => {
          const unit = (this.defaultUnit || this.lineUnit).toLowerCase();

          this.unit = this.units.find(x => x.code.toLowerCase() === unit)?.code;
        }).finally(() => { this.propertiesPending = false; });
    },
    validateAndNormalizeUnit() {
      const unitLc = this.unit.toLowerCase();
      this.unit = this.units.find(x => x.code === this.unit)?.code
        ?? this.units.find(x => x.code.toLowerCase() === unitLc)?.code;
    },
  },
  created() {
    this.orderedValue = this.activity.orderedQuantity?.value;
    this.unit = this.activity.orderedQuantity?.unit;

    const { lineId } = this.activity;
    if (lineId && !this.unit) {
      this.getDefaultUnit(lineId);
    }

    this.validateAndNormalizeUnit();
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/vars.icss';

.title-style {
  font-weight: 500;
  font-size: 1.1em;
}

</style>
