export default {
  moveHereToGroup: 'Przesuń kolumnę tutaj aby pogrupować',
  moveHereToHide: 'Przesuń kolumnę tutaj aby ukryć',
  sProcessing: 'Przetwarzanie...',
  sSearch: 'Szukaj:',
  sInfoFiltered: '(filtrowanie spośród _MAX_ dostępnych pozycji)',
  sLoadingRecords: 'Wczytywanie...',
  sZeroRecords: 'Nie znaleziono pasujących pozycji',
  sEmptyTable: 'Brak danych',
  downloadAsXls: 'Pobierz jako xls',
  oPaginate: {
    sFirst: 'Pierwsza',
    sPrevious: 'Poprzednia',
    sNext: 'Następna',
    sLast: 'Ostatnia',
  },
  oAria: {
    sSortAscending: ': aktywuj, by posortować kolumnę rosnąco',
    sSortDescending: ': aktywuj, by posortować kolumnę malejąco',
  },
};
