<template>
  <div>
    <PerformanceTypeSwitch
      :validated-speed.sync="showFromValidated"
    />
    <ProductionHeatmap
      v-if="production && !pending"
      style="max-height: 100%"
      :force-fit-width="screenWidth < 400"
      :height="height"
      :production="production"
      :start-date="startDate"
      :end-date="endDate"
      :options="options"
    />
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PerformanceTypeSwitch from '@/components/utils/PerformanceTypeSwitch';
import ProductionHeatmap from '@/components/charts/production/ProductionHeatmap';

export default {
  props: {
    elementId: {
      type: String,
      required: true,
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    startDate: Number,
    endDate: Number,
    options: {
      type: Object,
      default: () => {},
    },

  },
  data: () => ({
    production: null,
    pending: false,
    showFromValidated: false,
  }),
  components: {
    PerformanceTypeSwitch,
    ProductionHeatmap,
  },
  computed: {
    ...mapGetters(['plantId', 'screenWidth']),
    dates() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
  },
  watch: {
    dates() {
      this.request();
    },
    elementId() {
      this.request();
    },
    showFromValidated() {
      this.request();
    },
  },
  methods: {
    ...mapActions(['getProduction']),
    request() {
      const {
        startDate, endDate, plantId, elementId,
      } = this;
      this.pending = true;
      if (!this.startDate || !this.endDate) return;
      this.getProduction({
        params: {
          startDate,
          endDate,
          plantId,
          elementId,
          query: {
            aggregate: 'day',
            performanceType: this.showFromValidated ? 1 : 0,
          },
        },
      })
        .then(({ data }) => {
          this.pending = false;
          this.production = data;
          this.$emit('data', data);
        })
        .catch(({ response: { data } }) => {
          this.pending = false;
          this.error = data;
        });
    },
  },
  created() {
    this.showFromValidated = this.$store.state.monitoring.validatedSpeed;
    this.request();
  },
};
</script>
