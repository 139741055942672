<template>
  <div class="text-center activity-row-box">
    <div
      :id="`order-popover-${id}`"
      class="order-info mb-1"
    >
      <div>
        <div
          class="order-description mb-0 flex-grow-1 align-self-center"
          :class="{
            'd-flex align-items-center mt-1': isChangeover,
            'mt-2': !isChangeover
          }"
        >
          <i
            style="font-size: 0.9em;"
            :class="[activityInfo.icon, {'mr-2': isChangeover}]"
          />

          <span v-if="!isChangeover">
            {{ description || externalId }}
          </span>

          <div
            v-if="skuNumber"
            style="font-weight: 500; font-size: 10px"
            class="text-black-50 mt-2"
          >
            <span>{{ $t('orders.sku').toUpperCase() }}:</span> {{ skuNumber }}
          </div>

          <ChangeoverTimeChart
            v-if="isChangeover"
            :actual-start="actualExecutionStartUnix"
            :estimated-start="estimatedExecutionStartUnix"
            :estimated-end="estimatedExecutionEndUnix"
            class="w-100"
          />
        </div>

        <div
          v-if="estimatedEnd"
          class="order-description mb-0 flex-grow-1 align-self-center text-black-50"
        >
          <span style="font-size: 10px">
            {{ $t('order.eta') }}:
            {{ estimatedEnd | formatDate }}
            <span class="text-secondary small">
              ({{ estimatedEndDuration }})
            </span>
          </span>
        </div>
      </div>

      <div
        v-if="!isActivated && orderedQuantity"
        class="mt-2"
      >
        <SmallProductionBarWithNumbers
          :wasted-quantity-value="wastedQuantityValue"
          :ordered-quantity-value="orderedQuantity.value"
          :done-quantity-value="done"
          :estimated-quantity-value="estimatedQuantity"
          :unit="orderedQuantity.unit"
        />
      </div>
      <div
        v-else-if="isActivated"
        class="mt-1 small text-center"
      >
        <BBadge
          class="activated-badge"
          :style="activatedBadgeStyles"
        >
          {{ $t('orders.states.activated') }}
        </BBadge>
      </div>

      <div
        v-if="wastedQuantityValue"
        class="text-right text-danger order-p pt-1"
      >
        {{ wastedQuantityValue | round | addSpaces }} {{ orderedQuantity.unit }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import calcDuration from '@/utils/calcDuration';
import { activityStatus } from '@/utils/dictionary';
import ChangeoverTimeChart from '@/components/charts/changeover/ChangeoverTimeChart';
import SmallProductionBarWithNumbers
  from '@/components/charts/production/SmallProductionBarWithNumbers';
import colors from '@/utils/colors';

export default {
  props: {
    id: String,
    description: String,
    status: String,
    productId: String,
    actualExecution: Object,
    plannedExecution: Object,
    estimatedEnd: String,
    producedQuantity: Object,
    wastedQuantity: Object,
    estimated: Object,
    orderedQuantity: Object,
    externalId: String,
    type: String,
    isChangeover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activatedBadgeStyles: {
      backgroundColor: colors('blue_pastel'),
      color: 'white',
    },
  }),
  filters: {
    formatDate(d) {
      if (!d) return '...';
      return moment(d).format('Do MMM HH:mm');
    },
  },
  components: {
    SmallProductionBarWithNumbers,
    ChangeoverTimeChart,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters(['getActivityState', 'getActivityType']),
    ...mapState({
      items: state => state.item.items,
    }),
    activityInfo() {
      return this.getActivityType({ type: this.type });
    },
    isActivated() {
      return this.status === activityStatus.activated;
    },
    activatedState() {
      return this.getActivityState({ status: activityStatus.activated });
    },
    skuNumber() {
      return this.items?.find(i => i.id === this.productId)?.skuNo;
    },
    done() {
      return this.producedQuantity?.value || 0;
    },
    estimatedQuantity() {
      return this.estimated?.quantity || 0;
    },
    wastedQuantityValue() {
      return this.wastedQuantity?.value || 0;
    },
    actualExecutionStartUnix() {
      return moment(this.actualExecution.begin).unix();
    },
    estimatedExecutionStartUnix() {
      return moment(this.plannedExecution.begin).unix();
    },
    estimatedExecutionEndUnix() {
      return moment(this.plannedExecution.end).unix();
    },
    estimatedEndDuration() {
      return calcDuration(this.$now, moment(this.estimatedEnd).unix());
    },
  },
  methods: {
    ...mapActions(['getSkus']),
    calcDuration,
  },
  mounted() {
    if (!this.skuNumber) {
      this.getSkus({ params: { plantId: this.plantId } });
    }
  },

};
</script>

<style lang="scss" scoped>
  .activity-row-box {
    background-color: #F6F6F6;
  }

  .small-order-info {
    font-size: 13px !important;
    line-height: 10px;
  }

  .order-info {
    font-weight: normal;
    font-size: 18px;
  }

  .state-icon {
    color: white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .order-p {
    font-size: 10px;
    line-height: 11px;
  }
  .order-time {
    text-align: center;
  }

  .order-badge {
    position: absolute;
    top: 50%;
    margin-top: -14px;
    display: block;
    padding: 0;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    max-width: 100%;
  }

  .order-description {
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    overflow: hidden;
  }
</style>
