import moment from 'moment';

export default {
  methods: {
    parseDataForTrendCanvas(
      startDate,
      amount,
      data,
      dateKey,
      unit,
      valueKey,
    ) {
      const canvasFirstDate = moment.unix(startDate).subtract(amount, unit);
      const dataArray = Array(data.length * 3).fill({}).map((v, index) => {
        const date = canvasFirstDate.clone();
        const cell = data[index - amount] || { [valueKey]: null };
        const value = cell[valueKey];
        return {
          key: index,
          unix: date.add(index, unit).unix(),
          value,
        };
      });
      return dataArray;
    },
  },
};
