<template>
  <div class="d-flex justify-content-end">
    <WorkCalendarData
      :date-from="dateFrom"
      :date-to="dateTo"
    />
    <ProSelect
      v-if="selectedMomentStartDate && periods[selectedPeriod]"
      v-model="selectedPeriod"
      :options="periods"
      :label="$t('reports.selectors.period')"
      :custom-value-extract-function="({index}) => index"
      :custom-label-extract-function="({name}) => name"
      :light="!darkSelects"
      class="mr-2"
      dropdown-width="200%"
    />
    <ProSelect
      v-if="selectedMomentStartDate && moments.find(m => m.key === selectedMoment)"
      v-model="selectedMoment"
      :options="moments"
      :label="$t('reports.selectors.moment')"
      :custom-value-extract-function="({key}) => key"
      :custom-label-extract-function="({name}) => name"
      :light="!darkSelects"
      dropdown-width="100%"
    />
  </div>
</template>

<script>
import WorkCalendarData from '@/components/data/WorkCalendarData';
import newFactoryDayReset from '@/mixins/newFactoryDayReset';
import { elementType } from '@/utils/dictionary';
import ProSelect from '@core/components/ProSelect';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    initMoment: String,
    initStartDate: Number,
    darkSelects: Boolean,
    showFutureShifts: Boolean,
  },
  data: () => ({
    selectedPeriod: 1,
    selectedMoment: 'today',
    selectedMomentStartDate: '',
    dateFrom: moment().subtract(1, 'days').startOf('day').unix(),
    dateTo: moment().endOf('day').unix(),
    firstPeriod: null,
  }),
  components: {
    ProSelect,
    WorkCalendarData,
  },
  mixins: [
    newFactoryDayReset(),
  ],
  computed: {
    ...mapGetters(['plantId', 'hourNow']),
    ...mapGetters('report', ['elementIds']),
    ...mapGetters(['aggregatedDayShifts']),
    ...mapGetters([
      'fullDateScopes',
      'allDateScopes',
    ]),
    ...mapGetters('plant', ['structure']),
    elementsList() {
      return this.elementIds.flatMap(element => {
        const node = this.structure.nodes[element];
        if (node.type === elementType.line) return [element];
        if (node.type === elementType.area) return this.structure.lowerChildrenOf(element, 1);
        if (node.type === elementType.machine) return [this.structure.parent(element, 2)];
        return [];
      });
    },
    periods() {
      return (this.showFutureShifts
        ? this.fullDateScopes
        : this.allDateScopes).map((v, i) => ({
        ...v,
        index: i,
      }));
    },
    moments() {
      const p = [...this.periods];
      return this.selectedPeriod || this.selectedPeriod === 0
        ? p[this.selectedPeriod]?.moments || [] : [];
    },
    allMoments() {
      return this.periods
        .flatMap((p, i) => p.moments.map(x => ({
          ...x,
          period: i,
        })));
    },
  },
  watch: {
    moments() {
      const m = this.allMoments.find(({ key }) => key === this.selectedMoment);
      if (m && !this.selectedMomentStartDate) {
        this.selectedActualPeriod(this.initMoment);
        this.selectedMomentStartDate = m.startDate;
        this.$emit('moment', m);
      }
    },
    selectedMoment(idx) {
      const m = this.allMoments.find(({ key }) => key === idx);
      if (m) {
        this.selectedMomentStartDate = m.startDate;
        this.$emit('moment', m);
      }
    },
    selectedPeriod(id) {
      if (this.selectedPeriod !== this.firstPeriod) {
        this.selectFirstMoment(this.periods[id]);
        this.firstPeriod = null;
      }
    },
    elementId() {
      this.selectFirstMoment(this.periods[this.selectedPeriod]);
    },
    hourNow() {
      this.selectActualMoment(this.periods[this.selectedPeriod]);
    },
  },
  methods: {
    dayRelative(startDate) {
      if (moment.unix(startDate).add(1, 'seconds').isBefore(moment().startOf('day').subtract(1, 'day'))) {
        return '';
      }

      return moment().startOf('day').isBefore(moment.unix(startDate).add(1, 'seconds'))
        ? this.$t('reports.selectors.today') : this.$t('reports.selectors.yesterday');
    },
    hours(startDate, endDate) {
      return `${moment.unix(startDate).format('Do HH:mm')} - ${moment.unix(endDate).format('Do HH:mm')}`;
    },
    selectActualMoment(period) {
      if (!period || period.moments.length === 0) return;
      this.selectedMoment = period.moments
        .find(p => p.startDate === this.selectedMomentStartDate)?.key || this.selectedMoment;
    },
    selectFirstMoment(period) {
      if (!period || period.moments.length === 0) return;
      this.selectedMoment = period.moments[0].key;
    },
    selectedActualPeriod(initMoment) {
      if (!initMoment) return;

      const actualPeriod = this.periods.find(p => p.moments.find(m => m.key === initMoment));

      this.selectedPeriod = this.periods.indexOf(actualPeriod);
      this.firstPeriod = this.periods.indexOf(actualPeriod);
    },
  },
  created() {
    if (this.initMoment) {
      this.selectedMoment = this.initMoment;
      this.selectedActualPeriod(this.initMoment);
    }

    const m = this.allMoments.find(({ key }) => key === this.selectedMoment);
    if (m) {
      this.selectedMomentStartDate = m?.startDate;
      this.$emit('moment', m);
    }
  },
};
</script>

<style scoped>
  .date-select {
    border-radius: 9px;
    min-width: 200px;
  }

  h6 {
    font-size: 12px;
    margin-bottom: 4px;
  }
</style>
