import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import packages from './packages';

const store = new Vapi({
  namespaced: true,
  axios,
  state: {

  },
})
  .get({
    action: 'getComponents',
    path: ({ plantId }) => `/v2/plant/${plantId}/components`,
  })
  .get({
    action: 'getComponent',
    path: ({ plantId, componentId }) => `/v2/plant/${plantId}/component/${componentId}`,
  })
  .post({
    action: 'createComponent',
    path: ({ plantId }) => `/v2/plant/${plantId}/component`,
  })
  .put({
    action: 'updateComponent',
    path: ({ plantId, componentId }) => `/v2/plant/${plantId}/component/${componentId}`,
  })
  .delete({
    action: 'deleteComponent',
    path: ({ plantId, componentId }) => `/v2/plant/${plantId}/component/${componentId}`,
  })
  .getStore();

store.modules = {
  packages,
};

export default store;
