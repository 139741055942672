<template>
  <div
    v-if="!firstLoad"
    class="activities-root-wrapper"
  >
    <LiveElementData
      :ids="lineIds"
    />
    <div
      class="row justify-content-center"
      style="margin-right: -2em;"
      :class="{'schedule-filters-desktop': !isMobile}"
    >
      <div class="col-md-2 col-xs-12 pl-1 filters-container">
        <Calendar
          :dates="filterDates"
          :filters="filters"
          :init-filters="initFilters"
          :custom-periods="[{text: $t('activities.status.notPlanned'), value: 'not-planned'}]"
          :disable-date-select="showNotPlanned"
          :disable-filters="showNotPlanned"
          @update="$store.commit('setFilterDates', $event)"
          @filtersUpdate="handleFilterChange($event)"
        />
      </div>

      <div
        class="col-md-10 col-xs-12 rounded px-sm-3 mt-sm-n4 pt-sm-4 activities-wrapper"
      >
        <div class="col-12 my-3">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="title-text">
              <i class="fas fa-calendar-day pr-2" />
              {{ $t('menu.activities') }}
              ({{ $t('orders.orders') }})
            </div>
            <div
              class="mb-2 mb-md-0 d-flex align-items-center"
            >
              <div class="pr-1">
                <button
                  class="btn btn-primary btn-block btn-sm"
                  @click="showAddModal"
                >
                  <i class="fas fa-plus pr-2" />
                  {{ $t('activities.addActivity') }}
                </button>
              </div>
              <div class="pl-1">
                <button
                  class="btn btn-primary btn-block btn-sm"
                  @click="showFlexibleImporter"
                >
                  <i class="fas fa-file-upload" />
                  {{ $t('import.import') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 activities-container"
        >
          <div class="d-flex justify-content-between align-items-center">
            <ActivitiesOptions
              :view="selectedView"
              @updateView="selectedView = $event"
              @updateSearch="search = $event"
            />

            <ActivitiesTimelineEditModeSwitch
              v-if="selectedView !== 'table'"
              v-model="editMode"
            />
          </div>

          <hr :class="[selectedView === 'table' ? 'pb-2' : 'pb-3']">

          <Loader v-if="pending" />

          <Error
            v-else-if="error"
            :message="error.detail"
          />

          <div
            v-else-if="!activitiesFiltered || !activitiesFiltered.length"
            class="align-items-center"
          >
            {{ $t('activities.noActivities') }}
          </div>

          <TableActivities
            v-else-if="selectedView === 'table'"
            :activities="showNotPlanned ? [] : activitiesFiltered"
            :unplanned-activities="showNotPlanned ? unplannedActivities : []"
            @setModal="setModal"
          />

          <div
            v-else
            ref="wrapper"
            class="position-relative mb-5 mb-md-0 timeline-wrapper"
          >
            <div
              v-if="nowMarkerLeft > 0"
              class="now-marker"
              :style="{
                left: `${nowMarkerLeft}px`,
              }"
            >
              <span class="font-weight-bold text-ilabo text">
                {{ hourNow }}
              </span>
            </div>

            <div
              class="row justify-content-center pb-2 position-relative"
              style="padding-left: 15px; padding-right: 15px"
            >
              <div
                class="col-sm-8 col-md-9 col-lg-10 ml-auto"
              >
                <TimeAxis
                  ref="timeaxis"
                  class="main-timeaxis"
                  style="height: 10px; "
                  :wrapper-height="0"
                  :start-date="unixStartDate"
                  :end-date="unixEndDate"
                />
              </div>

              <div class="timeline-separator timeline-separator-first" />
            </div>

            <BOverlay
              :show="busy"
              spinner-variant="primary"
              opacity="0.9"
            >
              <LineActivities
                v-for="(line, index) in lines"
                :key="`line_${line.id}`"
                :schedule-log-index="index"
                :hover-schedule-log-index="hoverScheduleLogIndex"
                :no-line-activities-index="lines.length"
                :line-id="line.id"
                :activities="activitiesFiltered"
                :start-date="unixStartDate"
                :end-date="unixEndDate"
                :display="selectedDisplayForLines[index]"
                :hover-block="hovered"
                :edit-mode="editMode"
                :is-line-available-for-drop="isLineDroppable(line.id)"
                :is-editing="isEditing"
                :root-line-ids="lineIds"
                @setModal="setModal"
                @resize="updateNowMarker"
                @hover="hovered = $event"
                @toggle:displayType="toggleDisplayTypeForLineIndex(index)"
                @saving="handleTimelineSaving"
                @saved="handleTimelineSaved"
                @edit-started="handleEditStart(line.id)"
                @edit-end="handleEditEnd"
                @handle-mouse-over-line="handleMouseOverLine(line.id, index)"
                @loading-droppable-line-ids="loadingDroppableLineIds = true"
                @update-droppable-line-ids="handleUpdateDroppableLineIds"
              />

              <LineActivities
                class="mt-3"
                line-id="no_line_activities"
                :no-line-activities-index="lines.length"
                top-border
                :schedule-log-index="lines.length"
                :hover-schedule-log-index="hoverScheduleLogIndex"
                :activities="activitiesNoLineFiltered"
                :start-date="unixStartDate"
                :end-date="unixEndDate"
                :display="selectedDisplayForNoLine"
                :hover-block="hovered"
                :edit-mode="editMode"
                :is-line-available-for-drop="isLineDroppable('no_line_activities')"
                :is-editing="isEditing"
                :root-line-ids="lineIds"
                @setModal="setModal"
                @resize="updateNowMarker"
                @hover="hovered = $event"
                @toggle:displayType="toggleDisplayTypeForNoLine"
                @saving="handleTimelineSaving"
                @saved="handleTimelineSaved"
                @edit-started="handleEditStart('no_line_activities')"
                @edit-end="handleEditEnd"
                @handle-mouse-over-line="handleMouseOverLine('no_line_activities', lines.length)"
                @loading-droppable-line-ids="loadingDroppableLineIds = true"
                @update-droppable-line-ids="handleUpdateDroppableLineIds"
              />
            </BOverlay>
          </div>
        </div>
      </div>
    </div>

    <LModal
      v-if="selectedActivity && selectedActivity.actualExecution"
      :show.sync="reportResources"
      size="xl"
      :full-screen="isMobile"
    >
      <div class="line-name text-fancy">
        {{ elementName(selectedActivity.lineId) }}
      </div>
      <hr>
      <!-- TODO Move ActivityResources to OrderModal -->
      <ActivityResources
        :line-id="selectedActivity.lineId"
        :hide-manual-production="true"
        :order="selectedActivity"
        :activity-id="selectedActivity.id"
        :scope-start="selectedActivity.actualExecution.begin"
        :scope-end="selectedActivity.actualExecution.end"
        :activity-type="selectedActivity.type"
        :order-production="selectedActivity.producedQuantity && selectedActivity.producedQuantity.value"
        :order-waste="selectedActivity.wastedQuantity && selectedActivity.wastedQuantity.value"
        :order-unit="selectedActivity.orderedQuantity ? selectedActivity.orderedQuantity.unit : ''"
        :human-resources.sync="humanResources"
        @cancel="reportResources = false"
        @refresh="refresh"
      />
    </LModal>

    <LModal
      v-if="selectedActivity"
      :show.sync="productionReconciliation"
      size="xl"
      :full-screen="isMobile"
    >
      <ProductionReconciliation :order="selectedActivity" />
    </LModal>

    <OrderModal
      :edited="selectedActivity"
      @close="clearSelectedActivity"
      @request="refresh"
      @showCorrelated="showCorrelated($event)"
      @saved="handleSave"
    />

    <BModal
      v-model="showEditActivityModal"
      hide-header
      hide-footer
      content-class="modal-fullscreen"
    >
      <ActivityFormModal
        :activity="selectedActivity"
        @close="showEditActivityModal = false"
        @saved="handleSave"
      />
    </BModal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import Calendar from '@core/components/calendar/Calendar';
import createSearch from '@core/utils/search';
import ActivityResources from '@/components/activity/ActivityResources';
import ActivityFormModal from '@/components/activity/form/ActivityFormModal';
import { activityStatus, activityType } from '@/utils/dictionary';
import OrderModal from '@/components/modals/OrderModal';
import LiveElementData from '@/components/data/LiveElementData';
import routeModal from '@/mixins/routeModal';
import loopRequest from '@/mixins/loopRequest';
import TimeAxis from '@/components/schedule/TimeAxis';
import ActivitiesTimelineEditModeSwitch from '@/pages/activities/ActivitiesTimelineEditModeSwitch';
import ProductionReconciliation from '@/components/activity/ProductionReconciliation.vue';
import LineActivities from './LineActivities';
import TableActivities from './TableActivities';
import ActivitiesOptions from './ActivitiesOptions';

const displayType = {
  collapsed: 'collapsed',
  full: 'full',
};

export default {
  data: () => ({
    error: null,
    showEditActivityModal: false,
    pending: false,
    selectedActivity: null,
    selectedView: 'table',
    requestFilters: {},
    selectedDisplayForLines: [],
    selectedDisplayForNoLine: displayType.collapsed,
    nowMarkerLeft: 0,
    hourNow: '',
    search: '',
    initFilters: {},
    hovered: null,
    editMode: false,
    busy: false,
    isEditing: false,
    mouseOverLineIndex: 0,
    hoverScheduleLogIndex: 0,
    activityDraggedFromLineId: null,
    loadingDroppableLineIds: false,
    droppableLineIds: [],
    firstLoad: true,
    unplannedActivities: [],
  }),
  components: {
    ProductionReconciliation,
    ActivitiesTimelineEditModeSwitch,
    TimeAxis,
    LineActivities,
    OrderModal,
    TableActivities,
    Calendar,
    ActivitiesOptions,
    LiveElementData,
    ActivityResources,
    ActivityFormModal,
  },
  mixins: [
    routeModal('reportResources'),
    routeModal('humanResources'),
    routeModal('productionReconciliation'),
    loopRequest('activitiesRequest', 10000),
  ],
  computed: {
    ...mapGetters(['isMobile', 'plantId', 'screenWidth', 'dayStartOffsetInUserTimezone']),
    ...mapGetters('element', ['elementName']),
    ...mapGetters('plant', ['lines']),
    ...mapGetters(['filterDates']),
    ...mapState({
      activities: state => state.activities.activities,
    }),
    ...mapState({
      items: state => state.item.items,
    }),
    utcDatesFromUrl() {
      return {
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        scope: this.$route.query.scope || null,
      };
    },
    utcOffset() {
      return moment()
        .utcOffset() * 60;
    },
    activitiesFiltered() {
      if (!this.search) {
        return this.activities;
      }

      const search = createSearch(this.search);

      return this.activities.filter(a =>
        search(a.externalId)
        || search(a.description)
        || search(this.items?.find(i => i.id === a.productId)?.skuNo || ''));
    },
    activitiesNoLineFiltered() {
      return this.activitiesFiltered.filter(({ lineId }) => !lineId);
    },
    lineIds() {
      return this.lines.map(l => l.id);
    },
    unixStartDate() {
      const unixStart = moment(this.filterDates.startDate).unix();
      return unixStart + this.dayStartOffsetInUserTimezone;
    },
    unixEndDate() {
      const unixEnd = moment(this.filterDates.endDate).unix();
      return unixEnd + this.dayStartOffsetInUserTimezone;
    },
    filters() {
      return [{
        text: this.$t('general.status')
          .toUpperCase(),
        value: 'statuses',
        options: [
          {
            text: this.$t('activities.status.started'),
            value: 'started',
          },
          {
            text: this.$t('activities.schedule.scheduled'),
            value: 'scheduled',

          },
          {
            text: this.$t('activities.status.completed'),
            value: 'completed',
          },
        ],
      },
      {
        text: this.$t('activities.activity')
          .toUpperCase(),
        value: 'types',
        options: [
          {
            text: this.$t('activities.order'),
            value: activityType.order,
          },
          {
            text: this.$t('activities.cleaning'),
            value: activityType.cleaning,

          },
          {
            text: this.$t('activities.changeover'),
            value: activityType.changeover,
          },
        ],
      },
      {
        text: this.$t('line')
          .toUpperCase(),
        value: 'lineIds',
        options: this.lines.map(l => ({
          text: l.name,
          value: l.id,
        })),
      },
      ];
    },
    showNotPlanned() {
      return this.filterDates.scope === 'not-planned';
    },
    query() {
      return this.$route.query;
    },
  },
  watch: {
    query() {
      this.saveQueryInLocalStorage();
    },
    firstLoad() {
      this.activitiesRequest();
    },
    filterDates(val) {
      if (this.firstLoad) return;

      if (val.startDate && val.endDate) {
        this.updateDatesInUrl();
        this.refresh();
      }
    },
    selectedView() {
      this.refresh();
    },
    screenWidth() {
      this.updateNowMarker();
    },
    activities: {
      deep: true,
      handler() {
        if (!this.selectedActivity) return;
        const updatedSelectedActivity = this.activities.find(a => a.id === this.selectedActivity.id);
        if (!updatedSelectedActivity) return;
        this.selectedActivity = { ...updatedSelectedActivity };
      },
    },
    lines(v) {
      if (v.length !== this.selectedDisplayForLines.length) {
        this.selectedDisplayForLines = this.lines.map(() => displayType.collapsed);
      }
    },
  },
  methods: {
    ...mapActions(['getActivitiesBetween', 'getActivitiesUnplanned', 'getActivities']),
    updateDatesInUrl() {
      if (this.firstLoad) return;
      if (JSON.stringify(this.filterDates) === JSON.stringify(this.utcDatesFromUrl)) return;
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.filterDates,
        },
      });
    },
    refresh() {
      this.pending = true;
      this.busy = true;
      setTimeout(() => {
        this.activitiesRequest(true);
      }, 100);
    },
    showFlexibleImporter() {
      if (this.firstLoad) return;
      this.$router.push(`/${this.plantId}/importer/activities`);
    },
    updateNowMarker() {
      if (!this.$refs.wrapper) return;

      const scheduleLog = document.getElementById('schedule-log');
      if (!scheduleLog) return;

      const scheduleBox = scheduleLog.getBoundingClientRect();
      const startDate = this.unixStartDate;
      const endDate = this.unixEndDate;
      const diff = moment()
        .unix() - startDate;
      const wrapperBox = this.$refs.wrapper.getBoundingClientRect();

      this.nowMarkerLeft = ((diff / (endDate - startDate)) * scheduleBox.width)
        + ((scheduleBox.left) - wrapperBox.left);
      this.hourNow = moment()
        .format('HH:mm');
    },
    handleEditStart(lineId) {
      this.isEditing = true;
      this.activityDraggedFromLineId = lineId;
    },
    handleEditEnd() {
      this.isEditing = false;
      this.activityDraggedFromLineId = null;
    },
    handleFilterChange(filterModel) {
      if (this.firstLoad) return;
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...filterModel,
          ...this.filterDates,
        },
      });
      this.requestFilters = { ...filterModel };
      this.refresh();
    },
    setModal(activity) {
      this.selectedActivity = { ...activity };
    },
    showAddModal() {
      this.showEditActivityModal = true;
      this.selectedActivity = null;
    },
    async activitiesRequest(loader) {
      if (this.firstLoad) return;

      this.pending = !!loader;
      this.error = null;

      const query = {
        plantId: this.plantId,
      };

      const statuses = typeof this.requestFilters?.statuses === 'string'
        ? [this.requestFilters.statuses]
        : this.requestFilters?.statuses;

      query.statuses = statuses ? [] : undefined;
      query.types = this.requestFilters?.types;
      query.lineIds = this.requestFilters?.lineIds;

      if (statuses?.find(s => s === 'started')) {
        // spread to stop reactivity
        query.statuses = [...query.statuses, activityStatus.started, activityStatus.activated];
      }
      if (statuses?.find(s => s === 'scheduled')) {
        query.statuses = [...query.statuses, activityStatus.draft, activityStatus.released];
      }
      if (statuses?.find(s => s === 'completed')) {
        query.statuses = [...query.statuses, activityStatus.completed];
      }

      try {
        if (this.showNotPlanned) {
          const { data: unplannedActivities } = await this.getActivitiesUnplanned({
            params: {
              query,
            },
          });
          this.unplannedActivities = unplannedActivities;
        } else {
          await this.getActivitiesBetween({
            params: {
              query: {
                fromEpoch: this.unixStartDate,
                toEpoch: this.unixEndDate,
                ...query,
              },
            },
          });
        }
      } catch ({ response: { data } }) {
        this.error = data;
      } finally {
        this.pending = false;
        this.busy = false;
        this.$nextTick(() => {
          this.updateNowMarker();
        });
      }
    },
    handleSave() {
      this.refresh();
      this.$bvToast.toast(this.$t('activities.activityAdded'), {
        variant: 'success',
        solid: true,
        autoHideDelay: 2000,
        noCloseButton: true,
      });
      this.busy = false;
      this.showEditActivityModal = false;
    },
    setFiltersFromQuery() {
      this.initFilters = {
        ...this.$route.query,
        statuses: typeof this.$route.query.statuses === 'string'
          ? [this.$route.query.statuses]
          : this.$route.query.statuses,
      };
      this.requestFilters = {
        ...this.$route.query,
        statuses: typeof this.$route.query.statuses === 'string'
          ? [this.$route.query.statuses]
          : this.$route.query.statuses,
      };
    },
    showCorrelated(correlationId) {
      this.getActivities({
        params: {
          query: {
            plantId: this.plantId,
            correlationIds: correlationId,
          },
        },
      })
        .then(({ data }) => {
          const correlated = data.filter(a => a.id !== this.selectedActivity.id)[0];

          this.selectedActivity = correlated ? { ...correlated } : null;
        })
        .catch(({ response: { data } }) => {
          this.selectedActivity = null;
          this.error = data;
        });
    },
    clearSelectedActivity() {
      this.selectedActivity = null;
    },
    toggleDisplayTypeForLineIndex(index) {
      this.selectedDisplayForLines[index] = this.selectedDisplayForLines[index] === displayType.collapsed
        ? displayType.full
        : displayType.collapsed;
      this.selectedDisplayForLines = [...this.selectedDisplayForLines];
    },
    toggleDisplayTypeForNoLine() {
      this.selectedDisplayForNoLine = this.selectedDisplayForNoLine === displayType.collapsed
        ? displayType.full
        : displayType.collapsed;
    },
    handleTimelineSaving() {
      this.busy = true;
    },
    handleTimelineSaved() {
      this.activitiesRequest(false);
    },
    handleMouseOverLine(lineId, lineIndex) {
      if (!this.isLineDroppable(lineId)) return;
      this.hoverScheduleLogIndex = lineIndex;
    },
    handleUpdateDroppableLineIds(lineIds) {
      this.droppableLineIds = [...lineIds];
      this.loadingDroppableLineIds = false;
    },
    isLineDroppable(lineId) {
      if (!this.isEditing) return true;
      if (this.activityDraggedFromLineId === lineId
        || lineId === 'no_line_activities') return true;
      if (this.loadingDroppableLineIds) return false;
      return this.droppableLineIds.includes(lineId);
    },
    setDatesFromQuery() {
      const newDates = { ...this.filterDates };
      if (this.utcDatesFromUrl.startDate) {
        newDates.startDate = this.utcDatesFromUrl.startDate;
      }
      if (this.utcDatesFromUrl.endDate) {
        newDates.endDate = this.utcDatesFromUrl.endDate;
      }
      if (this.utcDatesFromUrl.scope) {
        newDates.scope = this.utcDatesFromUrl.scope;
      }
      this.$store.commit('setFilterDates', newDates);
    },
    saveQueryInLocalStorage() {
      localStorage.setItem('activitiesQuery', JSON.stringify(this.$route.query));
    },
    setQueryFromLocalStorage() {
      const query = JSON.parse(localStorage.getItem('activitiesQuery'));
      if (JSON.stringify(this.query) === JSON.stringify(query)) return;
      if (query) {
        this.$router.replace({ query });
      }
    },
  },
  mounted() {
    this.setQueryFromLocalStorage();
    setTimeout(() => {
      this.setFiltersFromQuery();
      this.setDatesFromQuery();
      this.$emit('page', 'activities');
      setTimeout(() => {
        this.firstLoad = false;
      }, 1);
    }, 1);
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .activities-root-wrapper {
    margin: 0 5px;
  }

  .filters-container {
    margin-top: -15px;
    height: calc(100vh - 45px);
    overflow: auto;

    @media screen and (max-width: 768px) {
      height: unset;
    }
  }

  .activities-container {
    height: calc(100vh - 60px - 90px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 15px;
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @media screen and (max-width: 768px) {
      height: unset;
    }
  }

  .timeline-wrapper {
    position: relative;
    margin: 0 -15px;
  }

  .timeline-wrapper :deep(.timeline-separator) {
    width: calc(100% - 30px);
    z-index: 1;
    height: 1px;
    background-color: #F0F0F0;
    position: absolute;
    bottom: 0;

    &.timeline-separator-first {
      width: calc(100% - 60px);
    }
  }

  .card {
    border-radius: 0.5rem;
  }

  .activities-wrapper {
    background-color: #f4f4f4;
  }

  .title-text {
    font-size: 1.75em;
    font-weight: 500;
    opacity: 0.9;
  }

  .now-marker {
    position: absolute;
    top: -8px;
    bottom: 0;
    width: 3px;
    border-left: 2px solid #91AED6;
    z-index: 3;

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      width: 8px;
      height: 8px;
      background-color: #91AED6;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
    }

    .text {
      color: #91AED6;
      position: absolute;
      top: -16px;
      left: 0;
      font-size: 10px;
      line-height: 1;
      transform: translateX(-50%);
    }
  }

</style>
