<template>
  <div
    ref="timeaxis"
    class="position-relative"
  >
    <div
      v-if="!hideLabels"
      class="position-relative"
      :style="{
        'margin-left': leftOffset ? `${(width / steps.length) / 2}px` : null
      }"
    >
      <span
        v-for="(step, index) in steps"
        :key="index"
        class="step"
        :style="{ left: `${step.left}px` }"
      >
        {{ step.time }}
      </span>
    </div>
    <span
      v-show="showCursor"
      ref="cursor"
      class="step cursor"
    />
    <div
      v-if="showBackground"
      ref="grid-bg-wrapper"
      class="w-100"
    >
      <span
        v-for="(step, index) in steps"
        :key="'bg-' +step.timestamp"
        class="grid-bg"
        :style="{
          left: `${step.left}px`,
          height: `${wrapperHeight}px`,
          top: `-${wrapperHeight}px`,
          width: getStepLength(step, index)
        }"
      />
    </div>
    <span
      v-for="step in steps.slice(1)"
      :key="step.timestamp"
      class="grid-line"
      :style="{
        left: `${step.left}px`,
        height: `${wrapperHeight}px`,
        top: `-${wrapperHeight}px`,
      }"
    />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      type: Number,
      required: true,
    },
    timestamp: {
      type: Number,
    },
    wrapperHeight: {
      type: Number,
      default: 0,
    },
    leftOffset: Boolean,
    hideLabels: Boolean,
    showBackground: {
      type: Boolean,
      default: false,
    },
    currDateUtc: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    box: {},
    width: null,
    numberOfSteps: 6,
    showCursor: false,
    resizeFunction: null,
  }),
  computed: {
    period() {
      return this.endDate - this.startDate;
    },
    format() {
      if (this.period > 2 * 24 * 60 * 60) {
        return 'Do MMM';
      }
      if (this.period > 24 * 60 * 60) {
        return 'Do MMM H[:00]';
      }
      if (this.period > 8 * 60 * 60) {
        return 'HH';
      }

      if (this.period > 5 * 60) {
        return 'H:mm';
      }

      return 'H:mm:ss';
    },
    stepSize() {
      if (this.period > 8 * 24 * 60 * 60) {
        return 'weeks';
      }
      if (this.period > 24 * 60 * 60) {
        return 'days';
      }
      if (this.period > 3 * 60 * 60) {
        return 'hours';
      }

      if (this.period > 5 * 60) {
        return 'minutes';
      }

      return 'seconds';
    },
    steps() {
      if (!this.width) return [];
      const steps = [];
      let currDate = moment(this.startDate * 1000);
      if (this.currDateUtc) {
        currDate = moment.utc(this.startDate * 1000);
      }
      const density = (this.width / this.period);
      const toAdd = this.getDuationInput();

      while (currDate.isBefore(moment.utc(this.endDate * 1000))) {
        const timestamp = currDate.unix();
        const offset = timestamp - this.startDate;
        steps.push({
          timestamp,
          end: moment(currDate).add(toAdd, this.stepSize).unix(),
          time: currDate.format(this.format),
          left: density * offset,
        });
        currDate.add(toAdd, this.stepSize);
      }
      return steps;
    },
  },
  watch: {
    timestamp(date) {
      this.setCursor(date);
    },
    steps() {
      this.$emit('steps', this.steps);
    },
  },
  methods: {
    setCursor(timestamp) {
      const density = (this.width / this.period);
      const offset = timestamp - this.startDate;
      if (!this.$refs.cursor) return;
      if (!timestamp) {
        this.showCursor = false;
        this.$refs.cursor.style.left = 0;
      }
      this.showCursor = true;
      this.$refs.cursor.style.left = `${density * offset}px`;
      this.$refs.cursor.innerHTML = moment.utc(timestamp * 1000).format('HH:mm:ss');
    },
    getDuationInput() {
      if (this.period > 3 * 60 * 60) return 1;
      if (this.period > 30 * 60) return 10;
      if (this.period > 15 * 60) return 5;
      if (this.period > 5 * 60) return 60;
      if (this.period > 1 * 60) return 30;
      return 5;
    },
    getStepLength(step, index) {
      let width = 0;
      if (index < this.steps.length - 1) {
        const nextStep = this.steps[index + 1];
        width = nextStep.left - step.left;
      } else if (this.$refs['grid-bg-wrapper']) {
        const { width: wrapperWidth } = this.$refs['grid-bg-wrapper'].getBoundingClientRect();
        width = wrapperWidth - step.left;
      }
      return `${width}px`;
    },
  },
  mounted() {
    this.resizeFunction = () => {
      this.box = this.$refs.timeaxis.getBoundingClientRect();
      this.width = this.box.width;
    };
    this.$nextTick(() => {
      this.resizeFunction();
    });
    window.addEventListener('resize', this.resizeFunction);
  },
  destroyed() {
    if (this.resizeFunction) {
      window.removeEventListener('resize', this.resizeFunction);
    }
  },
};
</script>

<style lang="scss" scoped>
  .step {
    width: 70px;
    line-height: 10px;
    margin-left: -35px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    color: #787878;
  }

  .grid-line {
    border-left: 1px solid #F0F0F0;
    position: absolute;
    top: 0;
    height: 100%;
  }

  .cursor {
    position: absolute;
    display: inline-block;
    background-color: white;
  }

  @media (max-width: 600px) {
    .step:nth-child(odd) {
      display: none;
    }
  }

  .grid-bg {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: #FBFBFB;

    &:nth-of-type(even) {
      background-color: #fff;
    }
  }
</style>
