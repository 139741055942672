import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const productionFromOrdersKey = 'monitoring:productionFromOrders';
const useValidatedSpeedKey = 'monitoring:useValidatedSpeed';

const store = new Vapi({
  axios,
  state: {
    productionFromOrders: localStorage.getItem(productionFromOrdersKey) === 'true',
    validatedSpeed: localStorage.getItem(useValidatedSpeedKey) === 'true',
  },
})
  .get({
    action: 'getShortPerformance',
    path: ({ plantId }) => `/v2/plant/${plantId}/shortperformance`,
  })
  .get({
    action: 'getProcessParams',
    property: 'processParams',
    path: ({ plantId, startDate, endDate }) => `/machine/parameters/${plantId}/${startDate}/${endDate}`,
  })
  .getStore();

store.mutations.setProductionFromOrders = (s, v) => {
  s.productionFromOrders = v;
  localStorage.setItem(productionFromOrdersKey, v);
};

store.mutations.useValidatedSpeed = (s, v) => {
  s.validatedSpeed = v;
  localStorage.setItem(useValidatedSpeedKey, v);
};

store.getters = {
  performanceType: s => (s.validatedSpeed ? 1 : 0),
  productionFromOrders: s => s.productionFromOrders,
};

export default store;
