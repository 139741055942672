import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const downtimes = new Vapi({
  axios,
  state: {
    downtimeReasons: {
      machineId: null,
      downtimeReasons: [],
    },
    downtime: null,
    downtimes: [],
    splittingDowntimesEditIndex: -1,
    splittingDowntimes: [],
  },
})
  .post({
    action: 'callService',
    property: 'service',
    path: ({ plantId }) => `/cmms/request/${plantId}`,
  })
  .put({
    action: 'runReplay',
    path: ({
      plantId, lineId, from, to,
    }) => `/v2/plant/${plantId}/replay/${lineId}/${from}/${to}`,
  })
  .getStore();

downtimes.mutations = {
  ...downtimes.mutations,
  setSplittingDowntimesEditIndex: (s, v) => {
    s.splittingDowntimesEditIndex = v;
  },
  setSplittingDowntimes: (s, v) => {
    s.splittingDowntimes = v;
  },
};

downtimes.getters = {
  ...downtimes.getters,
  splittingDowntimesEdition: s => s.splittingDowntimesEditIndex !== -1,
  splittingDowntimesEditIndex: s => s.splittingDowntimesEditIndex,
  splittingDowntimes: s => s.splittingDowntimes,
};

export default downtimes;
