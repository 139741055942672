import { CLIPS_DELETE } from '@/store/mutation-types';
import { plantProperty } from '@/utils/dictionary';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  provide() {
    return {
      comments: this.comments,
      getComments: this.getComments,
      appendComments: this.appendComments,
      getCamerasAndClips: this.getCamerasAndClips,
      getCommentsToDowntime: this.getCommentsToDowntime,
      updateSelectedWorkEvent: this.updateSelectedWorkEvent,
      updateSelectedRootWorkEvent: this.updateSelectedRootWorkEvent,
      updateSelectedWorkEventReason: this.updateSelectedWorkEventReason,
      updateSelectedWorkEventCorrectiveAction: this.updateSelectedWorkEventCorrectiveAction,
      getReasonsAndCorrectiveActionsForDowntime: this.getReasonsAndCorrectiveActionsForDowntime,
    };
  },
  data: () => ({
    comments: [],
  }),
  computed: {
    ...mapGetters('plant', ['structure', 'plantProperty', 'flows', 'activeFlows', 'lines']),
    showVision() {
      return this.plantProperty(plantProperty.vision) === 'true';
    },
  },
  methods: {
    ...mapActions([
      'getElementClips',
      'getCameras',
    ]),
    ...mapActions('work', [
      'exportWorklogs',
      'getBufferSettings',
      'getAnnotations',
      'deleteDowntime',
      'getWorkEventDetails',
    ]),
    async getComments(downtimeId, start, end) {
      const s = start || this.startDate;
      const e = end || this.endDate || moment().format();

      const { data: comments } = await this.getAnnotations({
        params: {
          plantId: this.plantId,
          query: {
            elementIds: downtimeId,
            startDate: s,
            endDate: e,
          },
        },
      });
      this.comments = comments;
    },
    appendComments(comments) {
      this.comments = [...this.comments, ...comments];
    },
    async getCamerasAndClips(elementId, start, end) {
      if (!this.showVision) return;

      try {
        await this.getCameras({
          params: {
            query: {
              elementId,
            },
          },
        });

        const dayInSeconds = 60 * 60 * 24;
        await this.getElementClips({
          params: {
            elementId,
            startDate: start - dayInSeconds,
            endDate: end + dayInSeconds,
          },
        });
      } catch {
        this.$store.commit(CLIPS_DELETE);
      }
    },
    getCommentsToDowntime(downtime) {
      return this.comments.filter(c => c.startDateTime === downtime.startDateTime);
    },
  },
};
