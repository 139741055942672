import functions from './functions';
import hints from './hints';
import history from './history';
import setup from './setup';
import tags from './tags';

export default {
  namespaced: true,
  modules: {
    functions,
    setup,
    history,
    hints,
    tags,
  },
};
