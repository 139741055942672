<template>
  <div>
    <div class="container d-flex">
      <div class="flex-grow-1">
        <div
          v-for="o in orders"
          :key="o.id"
        >
          <div
            class="order-row"
            :class="selectedId === o.id ? 'selected' : ''"
          >
            <Order
              v-bind="o"
              :selected="selectedId === o.id"
              :hoverable="!isMobile"
              :hide-element="hideElement"
              @click.native="toggle(o)"
            />

            <Transition name="appear-bottom">
              <div
                v-if="isMobile && actionsEnabled && selected.id === o.id"
                class="order-actions"
              >
                <OrderActions
                  :order="selected"
                  :show-edit="true"
                  :can-manage-orders="canManageOrders"
                  :can-confirm-production="canConfirmProduction"
                  :can-edit-orders="canEditOrders"
                  :can-edit-orders-completed="canEditOrdersCompleted"
                  :can-edit-orders-inprogress="canEditOrdersInprogress"
                  class="pt-1 pb-1"
                  @components="$emit('components', selectedId)"
                  @edit="$emit('edit', selectedId)"
                  @settle="$emit('settle', selectedId)"
                  @activated="$emit('activated', selectedId)"
                  @stopped="$emit('stopped', selectedId)"
                  @started="$emit('started', selectedId)"
                  @deleted="$emit('deleted', selectedId)"
                  @audit="$emit('audit', selectedId)"
                  @showProductionStats="$emit('showProductionStats', selectedId)"
                  @showDetails="showDetails = $event"
                />
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <Transition name="appear-right">
        <div
          v-if="!isMobile && actionsEnabled"
          class="my-0 my-md-2 sticky-top order-actions"
          style="width: 240px"
        >
          <OrderActions
            :order="selected"
            :show-edit="true"
            :can-manage-orders="canManageOrders"
            :can-confirm-production="canConfirmProduction"
            :can-edit-orders="canEditOrders"
            :can-edit-orders-completed="canEditOrdersCompleted"
            :can-edit-orders-inprogress="canEditOrdersInprogress"
            class="sticky-top pt-1 pb-1"
            @components="$emit('components', selectedId)"
            @edit="$emit('edit', selectedId)"
            @settle="$emit('settle', selectedId)"
            @activated="$emit('activated', selectedId)"
            @stopped="$emit('stopped', selectedId)"
            @started="$emit('started', selectedId)"
            @deleted="$emit('deleted', selectedId)"
            @audit="$emit('audit', selectedId)"
            @showProductionStats="$emit('showProductionStats', selectedId)"
            @showDetails="showDetails = $event"
          />
        </div>
      </Transition>

      <LModal
        v-if="selected"
        :show.sync="showDetails"
        size="xl"
      >
        <OrderDetails
          :order="selected"
          @settle="$emit('settle', selectedId)"
        />
      </LModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  PACKOS_MANUAL_PRODUCTION,
  PACKOS_ORDER_COMPLETED_EDIT,
  PACKOS_ORDER_COMPLETED_MANUALPRODUCTION,
  PACKOS_ORDER_EDIT,
  PACKOS_ORDER_INPROGRESS_EDIT,
  PACKOS_ORDER_LIFECYCLE,
  PACKOS_ORDER_MANAGEMENT,
  PACKOS_ORDER_VIEW,
  PACKOS_PLANT_ADMIN,
} from '@core/dict/permissions';
import moment from 'moment';
import { activityStatus } from '@/utils/dictionary';
import OrderDetails from '@/components/order/OrderDetails';
import Order from './single/Order';
import OrderActions from './OrderActions';

export default {
  props: {
    list: Array,
    small: Boolean,
    hideElement: Boolean,
    withDurations: Boolean,
    selectedId: String,
    sortType: String,
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    statesOrder: Object.values(activityStatus),
    completeState: activityStatus.completed,
    now: 0,
    showDetails: false,
    sortOrderUp: false,
    elementForm: false,
  }),
  components: {
    OrderActions,
    OrderDetails,
    Order,
  },
  computed: {
    ...mapGetters('core', ['canOnElement']),
    ...mapGetters(['plantId', 'isMobile']),
    ...mapGetters('core', ['can']),
    canConfirmProduction() {
      if (!this.selected) return false;
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.selected.lineId)
        || this.canOnElement(PACKOS_MANUAL_PRODUCTION, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_VIEW, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_COMPLETED_MANUALPRODUCTION, this.selected.lineId);
    },
    canManageOrders() {
      if (!this.selected) return false;
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_LIFECYCLE, this.selected.lineId);
    },
    canEditOrders() {
      if (!this.selected) return false;
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_EDIT, this.selected.lineId);
    },
    canEditOrdersCompleted() {
      if (!this.selected) return false;
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
        || this.can(PACKOS_ORDER_MANAGEMENT, this.plantId)
        || this.can(PACKOS_ORDER_COMPLETED_EDIT, this.plantId);
    },
    canEditOrdersInprogress() {
      if (!this.selected) return false;
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
        || this.can(PACKOS_ORDER_MANAGEMENT, this.plantId)
        || this.can(PACKOS_ORDER_INPROGRESS_EDIT, this.plantId);
    },
    isEditBlocked() {
      return this.canEditOrdersCompleted || this.canEditOrdersInprogress;
    },
    selected() {
      return (this.list || []).find(x => x.id === this.selectedId);
    },
    actionsEnabled() {
      return this.selected && this.showActions;
    },
    orders() {
      const index = s => {
        const i = this.statesOrder.findIndex(o => o === s);
        return i === -1 ? this.statesOrder.length + 1 : i;
      };
      return this.list
        .slice()
        .sort((a, b) => {
          const statusDiff = index(a.status) - index(b.status);
          if (statusDiff !== 0) return statusDiff;
          if (this.sortType === 'plannedAscending') {
            return this.substractDates(a.actualExecution?.begin || a.scheduledExecution?.begin,
              b.actualExecution?.begin || b.scheduledExecution?.begin);
          }
          if (this.sortType === 'plannedDescending') {
            return this.substractDates(b.actualExecution?.begin || b.scheduledExecution?.begin,
              a.actualExecution?.begin || a.scheduledExecution?.begin);
          }
          if (this.sortType === 'prodAscending') {
            return (a.producedQuantity?.value || 0) - (b.producedQuantity?.value || 0);
          }
          if (this.sortType === 'prodDescending') {
            return (b.producedQuantity?.value || 0) - (a.producedQuantity?.value || 0);
          }
          if (this.sortType === 'totalTimeAscending') {
            return this.substractDates(a.actualExecution?.end, a.actualExecution?.begin)
              - this.substractDates(b.actualExecution?.end, b.actualExecution?.begin);
          }
          if (this.sortType === 'totalTimeDescending') {
            return this.substractDates(b.actualExecution?.end, b.actualExecution?.begin)
              - this.substractDates(a.actualExecution?.end, a.actualExecution?.begin);
          }
          if (this.sortType === 'etaAscending') {
            return this.substractDates(a.estimatedEnd || a.actualExecution?.begin,
              b.estimatedEnd || b.actualExecution?.begin);
          }
          if (this.sortType === 'etaDescending') {
            return this.substractDates(b.estimatedEnd || b.actualExecution?.begin,
              a.estimatedEnd || a.actualExecution?.begin);
          }
          if (this.sortType === 'endDateAscending') {
            return this.substractDates(a.actualExecution?.end, b.actualExecution?.end);
          }
          if (this.sortType === 'endDateDescending') {
            return this.substractDates(b.actualExecution?.end, a.actualExecution?.end);
          }
          if (this.sortType === 'plannedProdAscending') {
            return (a.orderedQuantity?.value) - (b.orderedQuantity?.value);
          }
          if (this.sortType === 'plannedProdDescending') {
            return (b.orderedQuantity?.value) - (a.orderedQuantity?.value);
          }
          return this.substractDates(a.actualExecution?.begin || a.scheduledExecution?.begin,
            b.actualExecution?.begin || b.scheduledExecution?.begin);
        });
    },
  },
  methods: {
    toggle(o) {
      if (this.selected && this.selected.id === o.id) {
        this.$emit('update:selectedId', null);
      } else {
        this.$emit('update:selectedId', o.id);
      }
    },
    editDowntimes(id) {
      const { plantId } = this;
      this.$router.push(`/${plantId}/order/${id}/downtimes`);
    },
    showForm(order) {
      this.$router.push(`/${this.plantId}/dashboard/line/${order.lineId}/orders?id=${order.id}`);
    },
    showEdit() {
      this.$emit('isEditBlocked', this.isEditBlocked);
    },
    substractDates(dateA, dateB) {
      return (dateA ? moment(dateA).unix() : 0) - (dateB ? moment(dateB).unix() : 0);
    },
  },
  created() {
    this.$emit('update:orders', this.orders);
    this.showEdit();
    this.now = this.$now;
  },
};
</script>

<style lang="scss" scoped>

.order-row {
  margin-top: 5px;
  margin-bottom: 10px;
}

.sort-button {
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 200ms;

  &:hover {
    transform: translateY(-2px);
  }
}

.order-actions {
  box-shadow: 0 0 5px rgba(150, 150, 150, 0.2), 0 0 25px rgba(150, 150, 150, 0.2);
  background-color: white;
  border-radius: 20px;
  margin: 10px;
}

.appear-right-enter-active, .appear-right-leave-active {
  overflow: hidden;
  transition: opacity 200ms, max-width 200ms, min-width 200ms;
}

.appear-right-enter, .appear-right-leave-to {
  opacity: 0;
  min-width: 0;
  max-width: 0 !important;
}

.appear-right-enter-to, .appear-right-leave {
  opacity: 1;
  min-width: 240px !important;
  max-width: 240px !important;
}

.appear-bottom-enter-active, .appear-bottom-leave-active {
  overflow: hidden;
  transition: opacity 200ms, height 200ms;
}

.appear-bottom-enter, .appear-bottom-leave-to {
  opacity: 0;
  height: 0;
}

.appear-bottom-enter-to, .appear-bottom-leave {
  opacity: 1;
  height: auto;
}
</style>
