import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  /* Component packages */
  .get({
    action: 'getComponentPackage',
    path: ({ plantId, componentId }) => `/v2/plant/${plantId}/component/${componentId}/packages`,
  })
  .post({
    action: 'createComponentPackage',
    path: ({ plantId, componentId }) => `/v2/plant/${plantId}/component/${componentId}/package`,
  })
  .put({
    action: 'updateComponentPackage',
    path: ({ plantId, componentId, packageId }) => `/v2/plant/${plantId}/component/${componentId}/package/${packageId}`,
  })
  .delete({
    action: 'deleteComponentPackage',
    path: ({ plantId, packageId }) => `/v2/plant/${plantId}/package/${packageId}`,
  })
  .get({
    action: 'getPackageScan',
    path: ({ plantId, scanCode }) => `/v2/plant/${plantId}/packagescan/${scanCode}`,
  })
  .put({
    action: 'updatePackageTracking',
    path: ({
      plantId, orderId, packageId, trackingId,
    }) => `/v2/plant/${plantId}/order/${orderId}/package/${packageId}/tracking/${trackingId}`,
  })
  .getStore();

export default store;
