import moment from 'moment-timezone';

export default function newFactoryDayReset() {
  return {
    data: () => ({
      newDayInterval: null,
    }),
    methods: {
      updateDateFromAndTo() {
        this.dateFrom = moment().subtract(1, 'day').startOf('day').unix();
        this.dateTo = moment().endOf('day').unix();
      },
      checkForNewDay() {
        const oneDayInSeconds = 86400;
        const newDay = moment().subtract('1', 'day').unix() - this.dateFrom >= oneDayInSeconds * 1000;
        if (newDay) this.updateDateFromAndTo();
      },
    },
    beforeDestroy() {
      if (this.newDayInterval) {
        clearInterval(this.newDayInterval);
      }
    },
    created() {
      this.newDayInterval = setInterval(() => {
        this.checkForNewDay();
      }, 5000);
    },
  };
}
