<template>
  <div class="root-canvas">
    <div class="horizontal-toolbar">
      <div class="horizontal-navigation">
        <span
          class="navigation-button mr-2"
          @click="goRight()"
        >
          <i class="fas fa-angle-left" />
        </span>
        <span
          class="navigation-button mr-2"
          @click="goRightPage()"
        >
          <i class="fas fa-angles-left" />
        </span>
        <span
          class="navigation-button mr-2"
          @click="goLeftPage()"
        >
          <i class="fas fa-angles-right" />
        </span>
        <span
          class="navigation-button"
          @click="goLeft()"
        >
          <i class="fas fa-angle-right" />
        </span>
        <span class="horizontal-navigation-description">
          {{ axisDescription('x') }}
        </span>
      </div>

      <div class="heat-container">
        <span class="mr-2">
          {{ $t('reports.decrease') }}
        </span>
        <div class="inner-heat" />
        <span class="m-2">
          {{ $t('reports.increase') }}
        </span>
      </div>

      <div class="horizontal-navigation-right">
        <span
          class="navigation-button"
          :class="{
            'disabled': zoomInHistory.length === 0
          }"
          @click="goLevelUp()"
        >
          <i class="fas fa-search-minus" />
        </span>
      </div>
    </div>

    <div class="outer-canvas">
      <BOverlay
        :show="loadingCells"
        spinner-variant="primary"
        opacity="0.5"
        class="w-100 h-100"
      >
        <div class="canvas">
          <div
            class="canvas-row legend"
            :style="{...xLegendStyle}"
            :class="{animate: animateRows}"
          >
            <div
              v-for="col in cols"
              :key="col"
              :style="{
                width: colsWidths[col - 1],
                ...colsStyles[col - 1],
              }"
              class="canvas-cell"
            >
              <div
                class="canvas-cell-inner horizontal legend"
                :class="{
                  'small': scope === canvasScope.day,
                  'hovered-legend': !zooming && col - 1 === hoveredX
                }"
              >
                <div class="text-center">
                  <span v-if="scope === canvasScope.month">
                    {{ $t('time.week') }}&nbsp;
                  </span>
                  {{ labels.x[col - 1] }}

                  <p
                    v-if="subLabels.x[col - 1]"
                    class="sub-labels small mb-0 text-black-50"
                  >
                    {{ subLabels.x[col - 1] }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="row in rows"
            v-if="checkIfRenderRow(row - 1)"
            :key="row"
            :style="{
              height: rowsHeights[row - 1],
              ...rowsStyles[row - 1]
            }"
            :class="{animate: animateRows}"
            class="canvas-row"
          >
            <div
              v-for="col in cols"
              v-if="checkIfRenderCol(col - 1)"
              :key="col"
              :style="{
                width: colsWidths[col - 1],
                ...colsStyles[col - 1],
              }"
              :class="{
                animate: animateCols,
                hoverable: !zooming && hoveredX === (col - 1)
                  && !areTheSameUnix(col - 1, row - 1)
                  && hoveredY === (row - 1)
                  && !isInFuture(col - 1, row - 1),
                clickable: !zooming && scope !== canvasScope.day
                  && !areTheSameUnix(col - 1, row - 1)
                  && !isInFuture(col - 1, row - 1),
              }"
              class="canvas-cell"
              @click="goLevelDown(col - 1, row - 1)"
            >
              <div
                v-if="!zooming && hoveredX === (col - 1) && hoveredY === (row - 1)
                  && !isInFuture(col - 1, row - 1)"
                class="hover-graph"
              >
                <Apexcharts
                  ref="chart"
                  type="bar"
                  :options="chartOptions"
                  :series="getSeriesForChart(col - 1, row - 1)"
                  :height="225"
                />
              </div>
              <div class="canvas-cell-bg">
                <div
                  class="canvas-cell-inner"
                  :style="{
                    backgroundColor: cellColor(col - 1, row - 1),
                  }"
                  :class="{animate: animateCols}"
                  @mouseenter="handleHover(col - 1, row - 1)"
                  @mouseleave="handleHover(null, null)"
                >
                  <div
                    v-if="zooming[0] === col - 1 && zooming[1] === row - 1"
                    class="w-100 h-100"
                  >
                    <div
                      v-for="zoomingRow in zoomingRowsAmount"
                      :key="zoomingRow"
                      :style="{
                        ...zoomingRowsStyles[zoomingRow - 1],
                      }"
                      :class="{animate: animateCols}"
                      class="canvas-row"
                    >
                      <div
                        v-for="zoomingCol in zoomingRowsAmount"
                        :key="zoomingCol"
                        :style="{
                          ...zoomingColsStyles[zoomingRow - 1],
                        }"
                        :class="{animate: animateCols}"
                        class="canvas-cell"
                      >
                        <div class="canvas-cell-bg" />
                      </div>
                    </div>
                  </div>

                  <div
                    v-else-if="!zooming
                      && !isInFuture(col - 1, row - 1)
                      && !isOutsideVisibleScope(col - 1, row - 1)"
                    class="w-100 h-100"
                  >
                    <span v-if="areTheSameUnix(col - 1, row - 1)" />
                    <div
                      v-else-if="scope !== canvasScope.day"
                      class="cell-data"
                    >
                      <span class="x-value">
                        {{ calculatedValuesArray.x[col - 1] }}
                      </span>
                      <span class="y-value">
                        {{ calculatedValuesArray.y[row - 1] }}
                      </span>
                      <span>{{ calculateDifference(col - 1, row - 1) | percentage }}%</span>
                    </div>
                  </div>

                  <div
                    v-else-if="isOutsideVisibleScope(col - 1, row - 1)"
                    class="skeleton"
                  />
                </div>
              </div>
            </div>

            <div
              :style="{
                ...colsStyles[cols],
                ...yLegendStyle,
              }"
              :class="{animate: animateCols}"
              class="canvas-cell"
            >
              <div
                class="canvas-cell-inner vertical legend"
                :class="{
                  'small': scope === canvasScope.day,
                  'hovered-legend': !zooming && row - 1 === hoveredY,
                }"
              >
                <div class="text-center">
                  <span v-if="scope === canvasScope.month">
                    {{ $t('time.week') }}&nbsp;
                  </span>
                  {{ labels.y[row - 1] }}

                  <p
                    v-if="subLabels.y[row - 1]"
                    class="sub-labels small mb-0 text-black-50"
                  >
                    {{ subLabels.y[row - 1] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BOverlay>

      <div class="vertical-toolbar">
        <div class="vertical-navigation">
          <span
            class="navigation-button mb-2"
            @click="goUp()"
          >
            <i class="fas fa-angle-up" />
          </span>
          <span
            class="navigation-button mb-2"
            @click="goUpPage()"
          >
            <i class="fas fa-angles-up" />
          </span>
          <span
            class="navigation-button mb-2"
            @click="goDownPage()"
          >
            <i class="fas fa-angles-down" />
          </span>
          <span
            class="navigation-button mr-2"
            @click="goDown()"
          >
            <i class="fas fa-angle-down" />
          </span>
          <span class="vertical-navigation-description">
            {{ axisDescription('y') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TrendCanvasAnimationsMixin from '@/components/trend-canvas/TrendCanvasAnimationsMixin';
import moment from 'moment';
import Apexcharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';

const canvasScope = {
  year: 'year',
  month: 'month',
  week: 'isoWeek',
  day: 'day',
  hour: 'hour',
};

export default {
  props: {
    fromX: {
      type: Number,
      required: true,
    },
    fromY: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({
        x: [],
        y: [],
      }),
    },
    request: {
      type: Function,
      default: () => ({}),
    },
  },
  data: () => ({
    cols: 0,
    colsWidths: [],
    colsStyles: [],
    colsToRender: [],
    rows: 0,
    rowsHeights: [],
    rowsStyles: [],
    rowsToRender: [],
    animateCols: false,
    animateRows: false,
    lockMovement: false,
    zooming: false,
    zoomingColsAmount: 0,
    zoomingRowsAmount: 0,
    zoomingColsStyles: [],
    zoomingRowsStyles: [],
    zoomingLegendHeight: null,
    zoomingLegendWidth: null,
    grayOut: false,
    loadingCells: false,
    zoomInHistory: [],

    canvasScope,
    hoveredX: null,
    hoveredY: null,
    hoverDebounce: 0,
  }),
  filters: {
    percentage(v) {
      const n = (Number(v) || 0).toFixed(0);
      return Number(v) > 0 ? `+${n}` : n;
    },
  },
  components: { Apexcharts },
  mixins: [
    TrendCanvasAnimationsMixin,
  ],
  computed: {
    ...mapGetters('report', [
      'selectedTrendScope',
    ]),
    scope: {
      set(v) {
        this.$store.commit('report/setSelectedTrendScope', v);
      },
      get() {
        return this.selectedTrendScope;
      },
    },
    chartOptions() {
      if (this.hoveredX === null || this.hoveredY === null) return {};

      const xVal = this.calculatedValuesArray.x[this.hoveredX];
      const yVal = this.calculatedValuesArray.y[this.hoveredY];

      return {
        xaxis: {
          categories: [this.labels.x[this.hoveredX], this.labels.y[this.hoveredY]],
        },
        yaxis: {
          labels: {
            formatter(value) {
              return `${Number(value).toFixed(0)} kWh`;
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${Number(val).toFixed(0)}`;
          },
          offsetY: 20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            barHeight: '85%',
          },
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: [xVal > yVal ? '#FE626D' : '#4A9FFF', yVal > xVal ? '#FE626D' : '#4A9FFF'],
      };
    },
    scopeUnit() {
      switch (this.scope) {
        case canvasScope.day:
          return 'hour';
        case canvasScope.week:
          return 'day';
        case canvasScope.month:
          return 'isoWeek';
        case canvasScope.year:
        default:
          return 'month';
      }
    },
    scopeAmount() {
      switch (this.scope) {
        case canvasScope.day:
          return 24;
        case canvasScope.week:
          return 7;
        case canvasScope.month:
          return 5;
        case canvasScope.year:
        default:
          return 12;
      }
    },
    labels() {
      const lX = Array(this.cols).fill(null);
      const lY = Array(this.rows).fill(null);
      let format = '';
      switch (this.scope) {
        case canvasScope.day:
          format = 'HH:mm';
          break;
        case canvasScope.week:
          format = 'DD.MM';
          break;
        case canvasScope.month:
          format = 'W';
          break;
        default:
        case canvasScope.year:
          format = 'MMMM';
          break;
      }
      return {
        x: lX.map((v, index) => moment.unix(this.fromX).add(index, this.scopeUnit).format(format)),
        y: lY.map((v, index) => moment.unix(this.fromY).add(index, this.scopeUnit).format(format)),
      };
    },
    subLabels() {
      if (this.scope === canvasScope.month) {
        return {
          x: Array(this.cols).fill(null).map((v, index) => {
            const weekStart = moment.unix(this.fromX).add(index, this.scopeUnit)
              .startOf('isoWeek').format('DD.MM');
            const weekEnd = moment.unix(this.fromX).add(index, this.scopeUnit)
              .endOf('isoWeek').format('DD.MM');
            return `${weekStart} - ${weekEnd}`;
          }),
          y: Array(this.rows).fill(null).map((v, index) => {
            const weekStart = moment.unix(this.fromY).add(index, this.scopeUnit)
              .startOf('isoWeek').format('DD.MM');
            const weekEnd = moment.unix(this.fromY).add(index, this.scopeUnit)
              .endOf('isoWeek').format('DD.MM');
            return `${weekStart} - ${weekEnd}`;
          }),
        };
      }
      if (this.scope === canvasScope.week) {
        return {
          x: Array(this.cols).fill(null).map((v, index) =>
            moment.unix(this.calculatedUnixArray.x[index]).format('dddd')),
          y: Array(this.rows).fill(null).map((v, index) =>
            moment.unix(this.calculatedUnixArray.y[index]).format('dddd')),
        };
      }
      if (this.scope === canvasScope.year) {
        return {
          x: Array(this.cols).fill(null).map((v, index) =>
            moment.unix(this.calculatedUnixArray.x[index]).format('YYYY')),
          y: Array(this.rows).fill(null).map((v, index) =>
            moment.unix(this.calculatedUnixArray.y[index]).format('YYYY')),
        };
      }
      return {
        x: Array(this.cols).fill(null),
        y: Array(this.cols).fill(null),
      };
    },
    xLegendStyle() {
      if (this.zooming) {
        return {
          height: this.zoomingLegendHeight,
        };
      }
      return {
        height: this.rowsHeights[this.visibleRowsAmount],
      };
    },
    yLegendStyle() {
      if (this.zooming) {
        return {
          width: this.zoomingLegendWidth,
        };
      }
      return {
        width: this.colsWidths[this.visibleColsAmount],
      };
    },
    calculatedValuesArray() {
      const xValues = Array(this.cols).fill(null).map((v, index) => {
        const unixDate = moment.unix(this.fromX).add(index, this.scopeUnit).unix();
        return this.data.x.find(i => i.unix === unixDate)?.value;
      });
      const yValues = Array(this.rows).fill(null).map((v, index) => {
        const unixDate = moment.unix(this.fromY).add(index, this.scopeUnit).unix();
        return this.data.y.find(i => i.unix === unixDate)?.value;
      });

      return {
        x: xValues,
        y: yValues,
      };
    },
    calculatedUnixArray() {
      const xValues = Array(this.cols).fill(null).map((v, index) => {
        const unixDate = moment.unix(this.fromX).add(index, this.scopeUnit).unix();
        return this.data.x.find(i => i.unix === unixDate)?.unix || '';
      });
      const yValues = Array(this.rows).fill(null).map((v, index) => {
        const unixDate = moment.unix(this.fromY).add(index, this.scopeUnit).unix();
        return this.data.y.find(i => i.unix === unixDate)?.unix || '';
      });

      return {
        x: xValues,
        y: yValues,
      };
    },
    allDifferences() {
      const visibleXValues = this.calculatedValuesArray.x
        .filter((v, index) => index >= this.visibleColsAmount && index < this.visibleColsAmount * 2);
      const visibleYValues = this.calculatedValuesArray.y
        .filter((v, index) => index >= this.visibleRowsAmount && index < this.visibleRowsAmount * 2);
      return visibleXValues
        .map((x, xI) => visibleYValues
          .map((y, yI) => this.calculateDifference(xI, yI)))
        .flat();
    },
    maxPercentageValue() {
      return Math.max(
        ...this.allDifferences.map(Number),
      );
    },
    minPercentageValue() {
      return Math.min(
        ...this.allDifferences.map(Number),
      );
    },
    visibleRowsAmount() {
      return this.rows / 3;
    },
    visibleColsAmount() {
      return this.cols / 3;
    },
  },
  watch: {
    cols(v, p) {
      this.calculateColsWidths(v < p);
    },
    rows(v, p) {
      this.calculateRowsHeights(v < p);
    },
    scope(v) {
      this.updateScope(v);
    },
  },
  methods: {
    async loadCells(fromX, fromY, amount, aggregate, updateX, updateY) {
      this.loadingCells = true;
      await this.request(
        fromX,
        fromY,
        amount,
        aggregate,
        updateX,
        updateY,
      );
      this.loadingCells = false;
    },
    updateScope(scope) {
      this.hoveredX = null;
      this.hoveredY = null;
      switch (scope) {
        case canvasScope.day:
          if (this.rows !== 24 * 3) this.rows = 24 * 3;
          if (this.cols !== 24 * 3) this.cols = 24 * 3;
          break;
        case canvasScope.week:
          if (this.rows !== 7 * 3) this.rows = 7 * 3;
          if (this.cols !== 7 * 3) this.cols = 7 * 3;
          break;
        case canvasScope.month:
          if (this.rows !== 5 * 3) this.rows = 5 * 3;
          if (this.cols !== 5 * 3) this.cols = 5 * 3;
          break;
        case canvasScope.year:
        default:
          if (this.rows !== 12 * 3) this.rows = 12 * 3;
          if (this.cols !== 12 * 3) this.cols = 12 * 3;
          break;
      }
      this.scope = scope;
    },
    goLevelDown(x, y) {
      if (this.scope === canvasScope.day) return;
      if (this.areTheSameUnix(x, y)) return;
      if (this.isInFuture(x, y)) return;
      if (this.lockMovement) return;

      const diffX = x - this.visibleColsAmount;
      const diffY = y - this.visibleRowsAmount;
      this.zoomInHistory = [
        ...this.zoomInHistory,
        {
          x: this.calculatedUnixArray.x[x - diffX],
          y: this.calculatedUnixArray.y[y - diffY],
          amount: this.visibleColsAmount,
          unit: this.scopeUnit,
        },
      ];

      this.lockMovement = true;
      this.zooming = [x, y];
      this.hoveredX = null;
      this.hoveredY = null;

      switch (this.scope) {
        case canvasScope.week:
          this.zoomingColsAmount = 24;
          this.zoomingRowsAmount = 24;
          break;
        case canvasScope.month:
          this.zoomingColsAmount = 7;
          this.zoomingRowsAmount = 7;
          break;
        case canvasScope.year:
        default:
          this.zoomingColsAmount = 5;
          this.zoomingRowsAmount = 5;
          break;
      }

      setTimeout(() => {
        this.animateGoLevelDown(x, y);

        let unit = '';
        let amount = 0;
        switch (this.scope) {
          case canvasScope.week:
            unit = canvasScope.hour;
            amount = 24;
            break;
          case canvasScope.month:
            unit = canvasScope.day;
            amount = 7;
            break;
          case canvasScope.year:
          default:
            unit = canvasScope.week;
            amount = 5;
            break;
        }

        setTimeout(async () => {
          const fromX = this.calculatedUnixArray.x[x];
          const fromY = this.calculatedUnixArray.y[y];
          await this.loadCells(
            fromX,
            fromY,
            amount,
            unit,
            true,
            true,
          );

          switch (this.scope) {
            case canvasScope.week:
              this.scope = canvasScope.day;
              this.rows = 24 * 3;
              this.cols = 24 * 3;
              break;
            case canvasScope.month:
              this.scope = canvasScope.week;
              this.rows = 7 * 3;
              this.cols = 7 * 3;
              break;
            case canvasScope.year:
            default:
              this.scope = canvasScope.month;
              this.rows = 5 * 3;
              this.cols = 5 * 3;
              break;
          }
          this.zooming = false;
          this.resetMovementAnimation();
        }, 800);
      }, 10);
    },
    async goLevelUp() {
      if (this.scope === canvasScope.year) return;
      if (this.zoomInHistory.length === 0) return;
      if (this.lockMovement) return;

      await this.loadCells();

      const to = this.zoomInHistory.pop();
      this.zoomInHistory = [...this.zoomInHistory];

      await this.loadCells(
        to.x,
        to.y,
        to.amount,
        to.unit,
        true,
        true,
      );

      let animatingCols = 0;
      let animatingRows = 0;
      let targetScope = '';
      switch (this.scope) {
        case canvasScope.day:
          targetScope = canvasScope.week;
          animatingRows = 7;
          animatingCols = 7;
          break;
        case canvasScope.week:
          targetScope = canvasScope.month;
          animatingRows = 5;
          animatingCols = 5;
          break;
        default:
        case canvasScope.month:
          targetScope = canvasScope.year;
          animatingRows = 12;
          animatingCols = 12;
          break;
      }
      this.scope = targetScope;
      this.rows = animatingRows * 3;
      this.cols = animatingCols * 3;

      this.resetMovementAnimation();
    },
    goLeft() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.colsToRender = [this.visibleColsAmount * 2];

      setTimeout(() => {
        this.animateGoLeft();

        setTimeout(async () => {
          const from = moment.unix(this.fromX).add(this.visibleColsAmount + 1, this.scopeUnit).unix();
          await this.loadCells(
            from,
            this.fromY,
            this.scopeAmount,
            this.scopeUnit,
            true,
            false,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goLeftPage() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.colsToRender = Array(this.visibleColsAmount).fill(0)
        .map((v, i) => this.visibleColsAmount * 2 + i);

      setTimeout(() => {
        this.animateGoLeftPage();

        setTimeout(async () => {
          const from = moment.unix(this.fromX).add(this.visibleColsAmount * 2, this.scopeUnit).unix();
          await this.loadCells(
            from,
            this.fromY,
            this.scopeAmount,
            this.scopeUnit,
            true,
            false,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goRight() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.colsToRender = [this.visibleColsAmount - 1];

      setTimeout(() => {
        this.animateGoRight();

        setTimeout(async () => {
          const from = moment.unix(this.fromX).add(this.visibleColsAmount - 1, this.scopeUnit).unix();
          await this.loadCells(
            from,
            this.fromY,
            this.scopeAmount,
            this.scopeUnit,
            true,
            false,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goRightPage() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.colsToRender = Array(this.visibleColsAmount).fill(0)
        .map((v, i) => i);

      setTimeout(() => {
        this.animateGoRightPage();

        setTimeout(async () => {
          const from = this.fromX;
          await this.loadCells(
            from,
            this.fromY,
            this.scopeAmount,
            this.scopeUnit,
            true,
            false,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goUp() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.rowsToRender = [this.visibleRowsAmount - 1];

      setTimeout(() => {
        this.animateGoUp();

        setTimeout(async () => {
          const from = moment.unix(this.fromY).add(this.visibleColsAmount - 1, this.scopeUnit).unix();
          await this.loadCells(
            this.fromX,
            from,
            this.scopeAmount,
            this.scopeUnit,
            false,
            true,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goUpPage() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.rowsToRender = Array(this.visibleRowsAmount).fill(0)
        .map((v, i) => i);

      setTimeout(() => {
        this.animateGoUpPage();

        setTimeout(async () => {
          const from = this.fromY;
          await this.loadCells(
            this.fromX,
            from,
            this.scopeAmount,
            this.scopeUnit,
            false,
            true,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goDown() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.rowsToRender = [this.visibleRowsAmount * 2];

      setTimeout(() => {
        this.animateGoDown();

        setTimeout(async () => {
          const from = moment.unix(this.fromY).add(this.visibleColsAmount + 1, this.scopeUnit).unix();
          await this.loadCells(
            this.fromX,
            from,
            this.scopeAmount,
            this.scopeUnit,
            false,
            true,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    goDownPage() {
      if (this.lockMovement) return;
      this.lockMovement = true;

      this.rowsToRender = Array(this.visibleRowsAmount).fill(0)
        .map((v, i) => this.visibleRowsAmount * 2 + i);

      setTimeout(() => {
        this.animateGoDownPage();

        setTimeout(async () => {
          const from = moment.unix(this.fromY).add(this.visibleColsAmount * 2, this.scopeUnit).unix();
          await this.loadCells(
            this.fromX,
            from,
            this.scopeAmount,
            this.scopeUnit,
            false,
            true,
          );

          this.colsToRender = [];
          this.resetMovementAnimation(true);
        }, 800);
      }, 10);
    },
    cellColor(x, y) {
      if (this.areTheSameUnix(x, y)) return '#fff';
      if (this.noValueForAny(x, y)) return '#fff';
      if (this.isInFuture(x, y)) return '#fff';
      if (this.calculatedValuesArray.x[x] === this.calculatedValuesArray.y[y]) return '#EDEDED';
      if (this.zooming[0] === x && this.zooming[1] === y) return '#fff';
      const percentage = this.calculateDifference(x, y);
      const colorPercentage = Number(percentage) < 0
        ? percentage / -100
        : percentage / 100;
      if (Number(percentage) < 0) {
        return `rgba(74, 159, 255, ${colorPercentage})`;
      }
      return `rgba(254, 98, 109, ${colorPercentage})`;
    },
    calculateDifference(x, y) {
      const xVal = this.calculatedValuesArray.x[x];
      const yVal = this.calculatedValuesArray.y[y];
      if (xVal === yVal || yVal === 0 || xVal === 0) return '0';
      const increase = xVal <= yVal;
      const percentage = (increase ? (yVal - xVal) / xVal : (xVal - yVal) / yVal) * 100;
      return increase ? percentage.toString() : `-${percentage}`;
    },
    areTheSameUnix(x, y) {
      const xUnix = this.calculatedUnixArray.x[x] || '';
      const yUnix = this.calculatedUnixArray.y[y] || '';
      return xUnix === yUnix;
    },
    noValueForAny(x, y) {
      return this.calculatedValuesArray.x[x] === null
        || this.calculatedValuesArray.y[y] === null;
    },
    isInFuture(x, y) {
      return this.calculatedUnixArray.x[x] > moment().unix()
       || this.calculatedUnixArray.y[y] > moment().unix();
    },
    isOutsideVisibleScope(x, y) {
      return this.calculatedUnixArray.x[x] < this.calculatedUnixArray.x[this.visibleColsAmount]
      || this.calculatedUnixArray.x[x] >= this.calculatedUnixArray.x[this.visibleColsAmount * 2]
      || this.calculatedUnixArray.y[y] < this.calculatedUnixArray.y[this.visibleRowsAmount]
        || this.calculatedUnixArray.y[y] >= this.calculatedUnixArray.y[this.visibleRowsAmount * 2];
    },
    axisDescription(axis) {
      const unix = axis === 'y' ? this.fromY : this.fromX;
      let start = '';
      let end = '';
      switch (this.scope) {
        case canvasScope.day:
          start = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .format('DD.MM HH:mm');
          end = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .add(23, 'hour').format('DD.MM HH:mm');
          return `${start} - ${end}`;
        case canvasScope.week:
          start = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .format('DD MMM');
          end = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .add(5, 'day').format('DD MMM YYYY');
          return `${start} - ${end}`;
        case canvasScope.month:
          start = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .format('W YYYY');
          end = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .add(4, 'isoWeek').format('W YYYY');
          return `${this.$t('time.week')} ${start} - ${this.$t('time.week')} ${end}`;
        case canvasScope.year:
        default:
          start = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .format('MMMM YYYY');
          end = moment.unix(unix).add(this.visibleRowsAmount, this.scopeUnit)
            .add(11, 'month').format('MMMM YYYY');
          return `${start} - ${end}`;
      }
    },
    getSeriesForChart(x, y) {
      return [
        {
          name: 'series-1',
          data: [this.calculatedValuesArray.x[x], this.calculatedValuesArray.y[y]],
        },
      ];
    },
    handleHover(x, y) {
      clearTimeout(this.hoverDebounce);
      this.hoverDebounce = setTimeout(() => {
        if (this.areTheSameUnix(x, y)
          || this.isInFuture(x, y)) {
          this.hoveredX = null;
          this.hoveredY = null;
          return;
        }

        this.hoveredX = x;
        this.hoveredY = y;
      }, 20);
    },
    checkIfRenderRow(row) {
      if (row >= this.visibleRowsAmount && row < this.visibleRowsAmount * 2) return true;
      return this.rowsToRender.includes(row);
    },
    checkIfRenderCol(col) {
      if (col >= this.visibleColsAmount && col < this.visibleColsAmount * 2) return true;
      return this.colsToRender.includes(col);
    },
  },
  created() {
    this.updateScope(this.scope || canvasScope.year);

    this.$nextTick(() => {
      this.calculateColsWidths();
      this.calculateRowsHeights();
      this.calculateZoomingColsWidths();
      this.calculateZoomingRowsHeights();
    });
  },
};
</script>

<style lang="scss" scoped>
$border-color: black;

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.root-canvas {
  .hovered-legend {
    z-index: 1500;
    position: relative;
    border: 3px solid #5886C1;
  }

  .horizontal-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    .horizontal-navigation {
      width: 35%;

      &-description {
        margin-left: 2rem;
      }

      &-right {
        width: 35%;
        text-align: right;
      }
    }
  }

  .navigation-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background: #EDEDED;
    transition: 0.3s background-color;

    &:hover {
      background: #d3d3d3;
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .sub-labels {
    @media screen and (max-height: 1200px) {
      display: none;
    }
  }

  .heat-container {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 12px;

    .inner-heat {
      width: 100%;
      height: 16px;
      background: linear-gradient(270deg, #FE626D 0.28%, #ECECEC 51.41%, #4A9FFF 94.87%);
    }
  }

  .outer-canvas {
    display: flex;
    width: 100%;
    height: calc(100vh - 380px);

    .vertical-toolbar {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 60px;
      margin-bottom: 2rem;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;

      .vertical-navigation {
        display: flex;
        width: 2.25rem;
        flex-direction: column;

        &-description {
          width: 500px;
          transform-origin: 0 0;
          margin-top: 2rem;
          margin-left: 28px;
          transform: rotate(90deg);
        }
      }
    }

    .canvas {
      height: 100%;
      width: calc(100% - 4rem);

      .skeleton {
        width: 100%;
        height: 100%;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(linear, left top, right top,
          color-stop(8%, rgba(130, 130, 130, 0.2)),
          color-stop(18%, rgba(130, 130, 130, 0.3)),
          color-stop(33%, rgba(130, 130, 130, 0.2)));
        background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%,
          rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
        background-size: 800px 100px;
        animation: wave-squares 2s infinite ease-out;
      }

      &-row {
        display: flex;

        &.animate {
          transition: 0.8s width, 0.8s height, 0.8s opacity;
        }
      }

      &-cell {
        height: 100%;
        padding: 1.5px;
        position: relative;

        &:hover {
          .hover-graph {
            display: block;
          }
        }

        &.clickable {
          cursor: pointer;
        }

        &.hoverable {
          .canvas-cell-bg {
            transition: 0.3s scale;
            &:hover {
              scale: 1.2;
              z-index: 1550;
              position: relative;
              box-shadow: 2px 2px 10px rgba(#000, 0.5);
            }
          }
        }

        .cell-data {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;

          .x-value {
            position: absolute;
            left: 0;
            top: 0;
            padding: 0.25rem 0.5rem;
            font-size: 11px;
            font-weight: 400;
          }
          .y-value {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 0.25rem 0.5rem;
            font-size: 11px;
            font-weight: 400;
          }
        }

        .hover-graph {
          position: absolute;
          display: none;
          bottom: calc(100% + 20px);
          width: 200px;
          height: 250px;
          left: 50%;
          transform: translateX(-50%);
          background: #FFFFFF;
          border-radius: 5px;
          z-index: 2500;
          box-shadow: 2px 2px 10px rgba(#000, 0.5);
          padding: 1rem 1rem 2rem 1rem;

          &:after {
            content: '';
            background-color: white;
            position: absolute;
            left: 50%;
            width: 18px;
            height: 18px;
            bottom: -9px;
            transform: rotate(45deg) translateX(-50%);
            border-bottom: 2px rgba(#000, 0.5);
            border-left: 2px rgba(#000, 0.5);
          }
        }

        &.animate {
          transition: 0.8s width, 0.8s height, 0.8s opacity;
        }

        &-bg {
          width: 100%;
          height: 100%;
          border-radius: 3px;
          overflow: hidden;
          background-color: #EDEDED;
        }

        &-inner {
          width: 100%;
          height: 100%;
          border-radius: 3px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          &.small {
            font-size: 12px;
          }

          &.legend {
            font-weight: 500;
            background-color: #EDEDED;
          }
        }
      }
    }
  }
}
</style>
