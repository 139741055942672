export default {
  originalDates: 'วันที่เดิม',
  originalDuration: 'ระยะเวลาเดิม',
  newDates: 'วันที่ใหม่',
  newDuration: 'ระยะเวลาใหม่',
  firstDowntime: 'ช่วงเวลาที่หยุดการทำงานครั้งที่ 1',
  secondDowntime: 'ช่วงเวลาที่หยุดการทำงานครั้งที่ 2',
  split: 'แบ่ง',
  thisMachine: 'เครื่องจักรนี้',
  addComment: 'เพิ่มความคิดเห็น',
  noComments: 'ไม่มีความคิดเห็น',
  downtimeSummary: 'สรุปเวลาที่หยุดการทำงาน',
  step: 'ขั้นตอน {step}',
  selectRootLineMachine: 'เลือกสถานที่ของสาเหตุหลัก',
  selectLineMachine: 'เลือกเส้น/เครื่องจักร',
  selectState: 'เลือกสถานะ',
  selectProblem: 'เลือกปัญหา',
  specifyAReason: 'ระบุเหตุผล',
  correctiveActionTaken: 'ดำเนินการแก้ไข',
  downtimeDuration: 'ระยะเวลาที่หยุดการทำงาน',
  vision: 'วิสัยทัศน์',
  oeeLossType: 'ประเภทของการสูญเสีย OEE',
  filterAny: 'ใดก็ได้',
  hasClips: 'มีวิดีโอคลิป',
  notHaveClips: 'ไม่มีวิดีโอคลิป',
  hasCorrectiveAction: 'มีการแก้ไขปัญหา',
  notHaveCorrectiveAction: 'ไม่มีการแก้ไขปัญหา',
  hasReason: 'มีเหตุผล',
  notHaveReason: 'ไม่มีเหตุผล',
  hasComments: 'มีความคิดเห็น',
  notHaveComments: 'ไม่มีความคิดเห็น',
  table: {
    downtime: 'เวลาที่หยุดการทำงาน', details: 'รายละเอียด', time: 'เวลา', duration: 'ระยะเวลา',
  },
};
