<template>
  <div
    class="row no-gutters justify-content-center align-items-center"
  >
    <div class="d-none d-md-block">
      <span
        v-for="el in leftElements"
        :key="el.name"
        class="text-fancy"
      >
        <BreadcrumbNode
          v-bind="el"
        />
      </span>
    </div>
    <div class="d-block d-md-none">
      <RouterLink
        v-if="backButton"
        :to="backButton.url"
      >
        <button
          class="btn btn-ilabo d-block d-md-none icon-btn back-button"
        >
          <i
            style="position: relative; top: -1px;"
            class="fas fa-angle-left"
          />
        </button>
      </RouterLink>
    </div>
    <div
      class="text-center name-container"
      style="top: 1px; position: relative;"
    >
      <div
        id="selected"
        class="flex-grow-1"
      >
        <Tippy
          ref="popup"
          interactive
          placement="bottom"
          theme="light"
          distance="7"
          trigger="click"
          arrow
          :on-show="() => { shown = true; }"
          :on-hide="() => { shown = false; }"
        >
          <template #trigger>
            <h1 class="underscore-select w-100 element-name text-fancy d-inline">
              {{ isPlant ? $t('plant') : elementName(elementId) }}
            </h1>
          </template>

          <div>
            <ElementsTree
              v-if="shown"
              :opened="true"
              :show-plant="true"
              :selected="elementId || plantId"
              :max-height="600"
              class="pr-3"
              @select="select"
            />
          </div>
        </Tippy>
      </div>
    </div>
  </div>
</template>

<script>
import ElementsTree from '@/components/settings/ElementsTree';
import { elementType } from '@/utils/dictionary';
import { TippyComponent } from 'vue-tippy';
import { mapGetters } from 'vuex';
import BreadcrumbNode from './BreadcrumbNode';

export default {
  props: {
    breadcrumb: String,
    elementId: String,
  },
  data: () => ({
    shown: false,
    elementTypeEnum: elementType,
  }),
  components: {
    BreadcrumbNode,
    ElementsTree,
    Tippy: TippyComponent,
  },
  computed: {
    ...mapGetters(['plantTimezone', 'plantId']),
    ...mapGetters('element', ['elementName', 'elementType']),
    ...mapGetters('plant', ['structure']),
    isPlant() {
      return !this.elementId
        || this.plantId.toLowerCase() === this.elementId.toLowerCase();
    },
    list() {
      let lineId = null;
      if (this.breadcrumb === 'machine') {
        lineId = this.structure.parentsIds(this.elementId)
          .find(id => this.elementType(id) === this.elementTypeEnum.line);
      }

      return [
        {
          display: this.$t('world'),
          icon: 'fas fa-globe-africa',
          name: 'world',
          url: '/world',
        },
        {
          display: this.$t('plant'),
          name: 'plant',
          url: `/${this.plantId}/dashboard/lines`,
        },
        {
          display: this.elementName(lineId),
          name: 'line',
          url: `/${this.plantId}/dashboard/line/${lineId}/machines`,
        },
        {
          display: this.$t('machine'),
          name: 'machine',
          url: null,
        },
      ];
    },
    // returns index of selected element, or last element if can't find the element
    selectedIndex() {
      const index = this.list.findIndex(el => el.name === this.breadcrumb);
      return index === -1 ? this.list.length - 1 : index;
    },
    // returns elements before selected
    leftElements() {
      return this.list.slice(0, this.selectedIndex);
    },
    // returns selected elememnt
    selectedElement() {
      return this.list[this.selectedIndex];
    },
    backButton() {
      return this.leftElements.length > 0
        ? this.leftElements[this.leftElements.length - 1]
        : null;
    },
  },
  methods: {
    select({ id }) {
      const selected = this.elementId || this.plantId;
      const thisElementType = this.elementType(id);
      if (id === selected) return;

      if (this.$route.name === 'wall') {
        this.$router.push(`/${this.plantId}/dashboard/line/${id}/worklogs/${id}`);
      } else {
        if (thisElementType === this.elementTypeEnum.line) {
          this.$router.push(`/${this.plantId}/dashboard/line/${id}/machines`);
        }

        if (thisElementType === this.elementTypeEnum.machine) {
          this.$router.push(`/${this.plantId}/element/${id}/params`);
        }
      }

      if (id === this.plantId) {
        this.$router.push(`/${this.plantId}/dashboard/lines`);
      }

      this.$refs.popup.tippy().hide();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  #selected {
    h1 {
      line-height: 1;
      margin: 0;
      font-size: 22px;
      font-weight: bold;
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    transition: transform 300ms ease-out;

    &:hover {
      transform: translateY(-2px);
    }
  }
  .clock-alert {
    position: relative;
    top: 3px;
    font-size: 17px;
    cursor: default;
  }

  .exact-time-info {
    font-size: 10px;
    clear: both;
    font-weight: normal;
    padding-top: 5px
  }

  .back-button {
    height: 30px;
    width: 30px;

    i {
      font-size: 22px;
      padding-right: 2px;
    }
  }

  @media screen and (max-width: 768px) {
    #selected {
      h1 {
        line-height: 1;
        font-size: 18px;
        padding-bottom: 0;
      }
    }

    .name-container {
      flex-grow: 1;
      padding-right: 30px;
    }

    .date-dropdown {
      margin-top: -10px;
      margin-bottom: 10px;
    }
  }
</style>
