<template>
  <div
    :id="`current_power_${$uId}`"
    :class="`energy-${size}`"
    class="position-relative"
  >
    <Gauge
      v-if="gauge"
      :value="gauge.energyMax ? gauge.value : 0"
      :max="gauge.energyMax"
      :height="gaugeSize.height"
      :width="gaugeSize.width"
      class="mx-auto"
    />
    <div class="position-absolute text-center curr-pow">
      {{ gauge ? gauge.value : 0 | round }} kW
    </div>
    <div
      :class="production && size !== 'sm' && unit ? 'justify-content-between' : 'justify-content-center'"
      class="d-flex energy-details text-nowrap"
    >
      <div
        v-if="production && size !== 'sm' && unit"
        class="mr-1"
      >
        <span v-if="energyPerUnit < 1000">
          {{ energyPerUnit }}
          <span class="unit">Wh/{{ unit }}</span>
        </span>
        <span v-else>
          {{ (energyPerUnit / 1000) | round }}
          <span class="unit">kWh/{{ unit }}</span>
        </span>
      </div>
      <div v-if="active || active === 0">
        {{ active | round }} <span class="unit">kWh</span>
      </div>
    </div>
  </div>
</template>

<script>
import Gauge from '@/components/charts/power/Gauge';

export default {
  props: {
    active: Number,
    worstElementId: String,
    gauge: Object,
    elementId: String,
    production: Number,
    unit: String,
    size: {
      type: String,
      default: 'md',
    },
  },
  filters: {
    round(v) {
      if (!v) return 0;
      if (v < 10) {
        return Math.round(v * 100) / 100;
      }
      return Math.round(v * 10) / 10;
    },
  },
  components: {
    Gauge,
  },
  computed: {
    gaugeSize() {
      switch (this.size) {
        case 'sm':
          return {
            height: 35,
            width: 35,
          };
        case 'lg':
          return {
            height: 120,
            width: 120,
          };
        default:
          return {
            height: 50,
            width: 50,
          };
      }
    },
    energyPerUnit() {
      if (!this.active || !this.production) return 0;
      return Math.round((this.active * 1000) / this.production);
    },
  },
};
</script>

<style lang="scss" scoped>
  .energy-details {
    font-size: 10px;
    font-weight: 500;
  }
  .unit {
    font-weight: 300;
  }

  .power-details {
    font-size: 12px;
    line-height: 1.1;
  }

  .curr-pow {
    top: 28px;
    width: 100%;
    font-size: 18px;
  }

  .energy-sm {
    .curr-pow {
      top: 18px;
      font-size: 14px;
    }

  }

  .energy-lg {
    .curr-pow {
      top: 75px;
      width: 100%;
      font-size: 22px;
    }
    .energy-details {
      position: relative;
      top: -20px;
      font-size: 16px;
      .unit {
        font-size: 13px;
      }
    }
  }

</style>
