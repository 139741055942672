import axios from '@/api/axios';
import i18n from '@/lang';
import Vapi from 'vuex-rest-api';

import deepClone from '@/utils/deepClone';
import nestedObjectUpdate from '@/utils/nestedObjectUpdate';
import { info } from '@core/utils/logger';

const store = new Vapi({
  axios,
  state: {
    plants: [],
    plantsData: null,
    plantElementsStates: null,
    useValidatedSpeed: false,
    validatedSpeedToggle: false,
  },
})
  .get({
    action: 'getPlants',
    path: '/v2/plants',
    onSuccess: (s, { data }) => {
      s.plants = data.sort((a, b) => a.name.localeCompare(b.name));
    },
  })
  .get({
    action: 'getLocalization',
    path: '/localization?type=Phrase',
    onSuccess: (_, { data }) => {
      const languagesToUpdate = new Set();
      const localeMessages = deepClone(i18n.messages);
      const allLanguages = Object.keys(localeMessages);

      data.forEach(localeItem => {
        if (localeItem.lang === '*') {
          allLanguages.forEach(language => {
            try {
              nestedObjectUpdate(
                localeMessages[language],
                localeItem.name,
                localeItem.text,
              );
              languagesToUpdate.add(language);
            } catch {
              info(`Cannot import translation "${language}/${localeItem.name}" (${localeItem.text})`);
            }
          });
        } else {
          try {
            nestedObjectUpdate(
              localeMessages[localeItem.lang],
              localeItem.name,
              localeItem.text,
            );
            languagesToUpdate.add(localeItem.lang);
          } catch {
            info(`Cannot import translation "${localeItem.lang}/${localeItem.name}" (${localeItem.text})`);
          }
        }
      });

      languagesToUpdate.forEach(languageToUpdate => {
        i18n.setLocaleMessage(languageToUpdate, localeMessages[languageToUpdate]);
      });
    },
  })
  .get({
    action: 'getPlantsData',
    property: 'plantsData',
    path: '/v2/plants/dashboard',
  })
  .get({
    action: 'getPlantsElementsStates',
    property: 'plantElementsStates',
    path: '/v2/plants/elements/dashboard/currentstates',
  })
  .get({
    action: 'getDashboardData',
    path: ({ plantId, startDate, endDate }) =>
      `/v2/plant/${plantId}/elements/dashboard/${startDate}/${endDate}`,
  })
  .get({
    action: 'getDashboardDataForShifts',
    path: ({ plantId }) =>
      `/v2/plant/${plantId}/elements/dashboard/currentshift`,
  })
  .get({
    action: 'getDashboardDataForOrders',
    path: ({ plantId }) =>
      `/v2/plant/${plantId}/elements/dashboard/currentorder`,
  })
  .get({
    action: 'getProduction',
    property: 'production',
    path: ({
      plantId, elementId, startDate, endDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/production/aggregated/${startDate}/${endDate}`,
  })
  .get({
    action: 'getCurrentProduction',
    path: ({
      plantId, startDate, endDate,
    }) => `/v2/plant/${plantId}/production/current/${startDate}/${endDate}`,
  })
  .get({
    action: 'getOees',
    property: 'oees',
    path: ({
      plantId, elementId, startDate, endDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/oees/aggregated/${startDate}/${endDate}`,
  })
  .get({
    action: 'getOeeLosses',
    path: ({ plantId }) => `/v2/plant/${plantId}/report/oeeloss`,
  })
  .get({
    action: 'getUserRequestStatus',
    path: ({ operationId }) => `/v2/operation/${operationId}`,
  })
  .getStore();

store.mutations.clearDashboard = s => {
  s.plantElementsStates = null;
  s.plantsData = null;
};

store.mutations.useValidatedSpeedDashboard = (s, v) => {
  s.useValidatedSpeed = v;
};

store.mutations.validatedSpeedToggleDashboard = (s, v) => {
  s.validatedSpeedToggle = v;
};

store.getters = {
  // Plants permissions filtered by backend
  plants: s => s.plants,
};

export default store;
