import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    issues: [],
  },
})
  .get({
    action: 'getSetupIssues',
    property: 'issues',
    path: ({ plantId }) => `v2/plant/${plantId}/setup/validate`,
  })
  .getStore();

export default store;
