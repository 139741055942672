<template>
  <div
    v-if="user"
    class="comments-wrapper"
  >
    <div
      v-for="comment in dateSortedComments"
      :key="comment.annotationId"
      class="comment position-relative w-100"
    >
      <div
        v-if="comment.user && comment.user.id === user.id"
        class="edit-button cursor-pointer"
        @click="toggleEditComment(comment)"
      >
        <i
          v-if="editingComment === comment.annotationId"
          class="fas fa-times text-black-50"
        />
        <i
          v-else
          class="fas fa-edit text-black-50"
        />
      </div>
      <div class="text-black-50 h3 mb-0 mr-2">
        <i class="fas fa-user-circle" />
      </div>
      <div class="position-relative w-100">
        <div>
          <span
            v-if="comment.user"
            class="font-weight-bold mr-2"
          >
            {{ comment.user?.lastName }}
            {{ comment.user?.firstName }}
          </span>
          <span class="small text-black-50">
            {{ comment.created | formatDate }}
          </span>
        </div>
        <div
          v-if="editingComment === comment.annotationId"
          class="d-flex w-100 align-items-center"
        >
          <BOverlay
            :show="pending"
            class="d-flex w-100 align-items-center"
          >
            <input
              v-model="comment.annotation"
              class="form-control w-100"
            >
            <button
              class="btn btn-block btn-primary btn-sm d-flex
            align-items-center justify-content-center p-0 ml-2"
              style="min-width: 35px; max-width: 35px; min-height: 35px; max-height: 35px;"
              @click="requestEditComment(comment)"
            >
              <i class="fas fa-check" />
            </button>
            <button
              class="btn btn-block btn-outline-danger btn-sm d-flex align-items-center
            justify-content-center p-0 mt-0 ml-2"
              style="min-width: 35px; max-width: 35px; min-height: 35px; max-height: 35px;"
              @click="requestDeleteComment(comment)"
            >
              <i class="fas fa-trash-alt" />
            </button>
          </BOverlay>
        </div>
        <div v-else>
          {{ comment.annotation }}
        </div>
      </div>
      <div
        v-if="commonComments"
        class="ml-auto"
      >
        <BTooltip
          :target="`comment-info_${comment.annotationId}`"
          custom-class="downtime-list-tooltip summary-card summary-card--fluid"
        >
          <div
            v-for="(worklog, index) in commonActionWorklogs"
            :key="worklog.id"
          >
            <StateBadge
              :state="worklog.workState"
              class="mr-2"
            />

            <div>
              <p class="mb-0">
                <span class="font-weight-bold">
                  {{ worklog.machineName }}
                </span>
                ->
                {{ worklog.mainDescription }}
              </p>
            </div>
            <p class="mb-0">
              {{ worklog.startDate | dateFormat }} - {{ worklog.endDate | dateFormat }}
              ({{ worklog.duration }})
            </p>

            <hr v-if="index < commonActionWorklogs.length - 1">
          </div>
        </BTooltip>
        <i
          :id="`comment-info_${comment.annotationId}`"
          class="fas fa-info-circle"
        />
      </div>
    </div>
    <div v-if="comments.length === 0">
      {{ $t('downtimes.noComments') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DowntimeCommentsList',
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    commonComments: {
      type: Boolean,
      default: false,
    },
    commonActionWorklogs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editingComment: null,
    pending: false,
  }),
  filters: {
    dateFormat(v) {
      return moment(v).format('DD MMM HH:mm:ss');
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date * 1000)
        .format('DD.MM.YYYY HH:mm:ss');
    },
  },
  computed: {
    ...mapGetters('core', ['user']),
    ...mapGetters([
      'plantId',
    ]),
    dateSortedComments() {
      return [...this.comments].sort((a, b) => a.created - b.created);
    },
  },
  methods: {
    ...mapActions('work', [
      'updateAnnotation',
      'deleteAnnotation',
    ]),
    toggleEditComment(comment) {
      if (this.editingComment === comment.annotationId) {
        this.editingComment = null;
        return;
      }
      this.editingComment = comment.annotationId;
    },
    async requestEditComment(comment) {
      this.pending = true;
      try {
        const { data } = await this.updateAnnotation({
          params: {
            annotationId: comment.annotationId,
            plantId: this.plantId,
            elementId: comment.elementId,
            timestamp: comment.startDateTime,
          },
          data: {
            description: comment.annotation,
          },
        });
        data.operationIds.forEach(id => {
          this.$root.$emit('user-operation-requested', { id, type: 'AddWorkLogAnnotationMessage' });
        });
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.title || '-', {
          title: this.$t('error.unknown'),
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
        });
      } finally {
        this.pending = false;
        this.$emit('edited-comment');
        this.editingComment = null;
      }
    },
    async requestDeleteComment(comment) {
      this.pending = true;
      try {
        const { data } = await this.deleteAnnotation({
          params: {
            annotationId: comment.annotationId,
            plantId: this.plantId,
            elementId: comment.elementId,
            timestamp: comment.startDateTime,
          },
        });
        data.operationIds.forEach(id => {
          this.$root.$emit('user-operation-requested', { id, type: 'AddWorkLogAnnotationMessage' });
        });
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.title || '-', {
          title: this.$t('error.unknown'),
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
        });
      } finally {
        this.pending = false;
        this.$emit('deleted-comment');
        this.editingComment = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .comments-wrapper {
    background-color:  #F8F8F8;
    padding: 22px 40px;

    .comment {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .edit-button {
        position: absolute;
        z-index: 5;
        right: 0;
        top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .downtime-list-tooltip {
    width: 350px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(100,100,100,0.5);

    &:deep() {
      .tooltip-inner {
        background-color: transparent;
        max-width: 100%;
        color: unset;
        text-align: left;
      }

      .arrow {
        display: none;
      }
    }
  }
</style>
