<template>
  <div class="text-center">
    <div v-if="pending">
      <Loader />
    </div>
    <div
      v-else
      class="pt-2"
    >
      <BTable
        class="text-left"
        :items="[ ...packingStructure,...packingRecurringStructures]"
        :fields="fields"
        small
        striped
        :hover="!readOnly"
        :show-empty="!adding"
        :empty-text="$t('general.nothingDefined')"
      >
        <template
          v-if="adding"
          slot="top-row"
        >
          <td>
            <BFormSelect
              v-model="fromUnit"
              :options="unitsOptions"
              size="sm"
              style="min-width: 175px"
            />
          </td>
          <td>=</td>
          <td>
            <BFormInput
              v-model="multiplier"
              type="number"
              class="form-control form-control-sm"
              min="0"
              style="max-width: 100px"
              @change="multiplierChanged()"
            />
          </td>
          <td>x</td>
          <td>
            <BFormSelect
              v-model="toUnit"
              :options="toUnitOptions"
              size="sm"
              style="min-width: 175px"
            />
          </td>
          <td v-if="importedStructures" />
          <td class="text-right">
            <button
              class="btn btn-success btn-sm icon-btn mr-1"
              @click="addPackingStructure()"
            >
              <i class="ion ion-md-checkmark" />
            </button>
            <button
              class="btn btn-secondary btn-sm icon-btn"
              @click="adding = false"
            >
              <i class="ion ion-ios-close" />
            </button>
          </td>
        </template>
        <template #head(actions)="">
          <div class="text-nowrap text-right">
            <button
              v-if="!readOnly"
              class="btn btn-primary btn-sm icon-btn"
              @click="adding = true"
            >
              <i class="ion ion-md-add" />
            </button>
          </div>
        </template>
        <template #cell(masterUnit)="row">
          <span :class="{ overwritten: isOverwritten(row.item) }">
            {{ getUnitText(row.item.fromUnit) }}
          </span>
        </template>
        <template #cell(multiplier)="row">
          <span :class="{ overwritten: isOverwritten(row.item) }">
            {{ row.item.multiplier }}
          </span>
        </template>
        <template #cell(slaveUnit)="row">
          <span :class="{ overwritten: isOverwritten(row.item) }">
            {{ getUnitText(row.item.toUnit) }}
          </span>
        </template>
        <template #cell(equalsSign)>
          =
        </template>
        <template #cell(xSign)>
          x
        </template>
        <template
          v-if="importedStructures"
          #cell(importResult)="row"
        >
          <div v-if="row.item.importResult">
            <ImportStatus
              :success="row.item.importResult.resultStatus"
              :success-text="`${$t('import.saved')}`"
              :failure-text="`${$t('import.notSaved')}`"
            />
          </div>
        </template>
        <template #cell(actions)="row">
          <div
            v-if="!readOnly && !row.item.isRecurring"
            class="text-nowrap text-right"
          >
            <button
              :id="`remove-popover-${row.item.id}`"
              class="btn btn-danger btn-sm icon-btn"
            >
              <i class="ion ion-md-trash" />
            </button>
            <RemovePopover
              :target="`remove-popover-${row.item.id}`"
              @remove="removePackingStructure(row.item.id)"
            >
              <i class="ion ion-md-trash pr-1" />
              {{ $t("general.remove") }}
            </RemovePopover>
          </div>
          <div
            v-else-if="row.item.isRecurring"
            class="text-right"
          >
            {{ $t("general.inherited") }}
          </div>
        </template>
      </BTable>
      <Error
        v-if="showErrorMsgMultiplierInvalid && adding"
        class="mt-1"
        :message="invalidMultiplierErrorMsg"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Error from '@core/components/Error';
import ImportStatus from '@/components/utils/ImportStatus';

export default {
  props: {
    id: String,
    readOnly: Boolean,
    overwritten: Array,
    importedStructures: Array,
  },
  data() {
    return {
      packingStructure: [],
      packingRecurringStructure: [],
      pending: true,
      adding: false,
      fromUnit: null,
      toUnit: null,
      multiplier: 0,
      multiplierAccordingToRule: 0,
      relatedStructure: [],
      showErrorMsgMultiplierInvalid: false,
      invalidMultiplierErrorMsg: '',
    };
  },
  components: {
    ImportStatus,
    Error,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapState({
      units: state => state.units.list,
    }),
    packingRecurringStructures() {
      return this.packingRecurringStructure.map(element => {
        const newElement = element;
        if (element.multiplier < 1) {
          const recurseConversion = this.packingRecurringStructure.find(x => x.fromUnit === element.toUnit
          && x.toUnit === element.fromUnit);
          newElement.multiplier = `1/${recurseConversion.multiplier}`;
        }
        return newElement;
      });
    },
    fields() {
      const fieldsToReturn = [
        {
          key: 'masterUnit',
          label: this.$t('settings.toUnit'),
          thStyle: {
            width: '300px',
          },
        },
        {
          key: 'equalsSign',
          label: '',
          thStyle: {
            width: '50px',
          },
        },
        {
          key: 'multiplier',
          label: this.$t('settings.multiplier'),
          thStyle: {
            width: '200px',
          },
        },
        {
          key: 'xSign',
          label: '',
          thStyle: {
            width: '50px',
          },
        },
        {
          key: 'slaveUnit',
          label: this.$t('settings.fromUnit'),
          thStyle: {
            width: '300px',
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            width: '100px',
          },
        },
      ];

      if (this.importedStructures) {
        fieldsToReturn.splice(fieldsToReturn.length - 1, 0, {
          key: 'importResult',
          label: '',
        });
      }

      return fieldsToReturn;
    },
    toUnitOptions() {
      const { unitsOptions } = this;
      if (!this.fromUnit) return unitsOptions;

      const existingPS = this.packingStructure
        .filter(ps => ps.fromUnit === this.fromUnit)
        .map(ps => ps.toUnit);

      return unitsOptions.filter(
        u =>
          u.value !== this.fromUnit && !existingPS.some(ps => ps === u.value),
      );
    },
    unitsOptions() {
      if (!this.units) return [];
      return this.units.map(u => ({
        value: u.id,
        text: `${u.code} (${u.name})`,
      }));
    },
  },
  watch: {
    packingStructure() {
      this.$emit('update', this.packingStructure);
    },
  },
  methods: {
    ...mapActions([
      'getPackingStructure',
      'getRecurringPackingStructure',
      'createPackingStructureEntry',
      'deletePackingStructureEntry',
    ]),
    valueOfMultiplierIsInvalid() {
      if (parseFloat(this.multiplier) <= 0) {
        return true;
      }
      return false;
    },
    multiplierChanged() {
      if (this.valueOfMultiplierIsInvalid()) {
        this.showErrorInvalidMultiplier('import.multiplierIsLessOrEqualZero');
      } else {
        this.showErrorMsgMultiplierInvalid = false;
      }
    },
    isOverwritten({ fromUnit, toUnit }) {
      return (
        this.overwritten
        && this.overwritten.some(
          y =>
            (y.fromUnit === fromUnit && y.toUnit === toUnit)
            || (y.toUnit === fromUnit && y.fromUnit === toUnit),
        )
      );
    },
    multiplierAccordingToRecurringPackegesIsInvalid(
      fromUnit,
      toUnit,
      multiplier,
    ) {
      this.relatedStructure = this.packingStructure.filter(
        x => x.fromUnit === toUnit && x.toUnit === fromUnit,
      );

      if (this.relatedStructure.length > 0) {
        this.multiplierAccordingToRule = 1 / this.relatedStructure[0].multiplier;
        if (parseFloat(multiplier) !== this.multiplierAccordingToRule) {
          return true;
        }
      }
      return false;
    },
    showErrorInvalidMultiplier(errorPattern) {
      this.invalidMultiplierErrorMsg = this.$t(errorPattern);
      this.showErrorMsgMultiplierInvalid = true;
    },
    showErrorMsgInvalidPackingStructure(errorMsg) {
      this.invalidMultiplierErrorMsg = errorMsg;
      this.showErrorMsgMultiplierInvalid = true;
    },
    isInvalid(fromUnit, toUnit, multiplier) {
      this.showErrorMsgMultiplierInvalid = false;

      if (this.valueOfMultiplierIsInvalid()) {
        this.showErrorInvalidMultiplier('import.multiplierIsLessOrEqualZero');
        return true;
      }

      if (
        this.multiplierAccordingToRecurringPackegesIsInvalid(
          fromUnit,
          toUnit,
          multiplier,
        )
      ) {
        this.showErrorInvalidMultiplier('import.multiplierIsInvalid');
        return true;
      }

      return false;
    },
    addPackingStructure() {
      const {
        plantId, id, fromUnit, toUnit, multiplier,
      } = this;
      if (this.isInvalid(fromUnit, toUnit, multiplier)) {
        return;
      }
      this.createPackingStructureEntry({
        params: { plantId },
        data: {
          refId: id,
          fromUnit,
          toUnit,
          multiplier,
        },
      }).then(({ data }) => {
        const existing = this.packingStructure.some(
          ps => ps.fromUnit === data.fromUnit && ps.toUnit === data.toUnit,
        );

        if (!existing) {
          this.packingStructure.push(data);
          this.$emit('saved', data);
        }

        this.fromUnit = null;
        this.toUnit = null;
        this.multiplier = 0;
        this.adding = false;
        this.getRecurringPackingStructureRequest();
      })
        .catch(({ response: { data } }) => {
          this.showErrorMsgInvalidPackingStructure(data);
        });
    },
    removePackingStructure(id) {
      const { plantId } = this;
      this.deletePackingStructureEntry({
        params: { plantId, id },
      }).then(() => {
        this.packingStructure = this.packingStructure.filter(
          ps => ps.id !== id,
        );
        this.getRecurringPackingStructureRequest();
      });
    },
    getUnitText(id) {
      const unit = this.units.find(u => u.id === id);
      if (!unit) return '?';
      return `${unit.code} (${unit.name})`;
    },
    checkImportedResult() {
      const { importedStructures } = this;
      if (!importedStructures) return;
      for (let i = 0; i < importedStructures.length; i += 1) {
        const index = this.packingStructure.findIndex(
          x =>
            x.fromUnit === importedStructures[i].fromUnit
            && x.toUnit === importedStructures[i].toUnit,
        );
        if (index < 0 || !importedStructures[i].importResult.resultStatus) {
          this.packingStructure.push(importedStructures[i]);
        } else {
          this.packingStructure[index].importResult = importedStructures[i].importResult;
        }
      }
    },
    getRecurringPackingStructureRequest() {
      this.getRecurringPackingStructure({
        params: {
          plantId: this.plantId,
          refId: this.id,
        },
      })
        .then(({ data }) => {
          this.packingRecurringStructure = data.map(
            obj => ({ ...obj, isRecurring: true }),
          );
          this.$emit('update', this.packingRecurringStructure);
          this.pending = false;
        });
    },
    getPackingStructureRequest() {
      this.getPackingStructure({
        params: {
          plantId: this.plantId,
          refId: this.id,
        },
      })
        .then(({ data }) => {
          this.packingStructure = data.map(obj => ({ ...obj }));
          this.$emit('update', this.packingStructure);
          this.checkImportedResult();
          this.pending = false;
        })
        .catch(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.getPackingStructureRequest();
    this.getRecurringPackingStructureRequest();
  },
};
</script>

<style scoped>
.overwritten {
  text-decoration: line-through;
}
</style>
