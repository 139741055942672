import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    list: [],
  },
})
  .get({
    action: 'getElementProperties',
    path: ({ plantId, elementId }) =>
      `/v2/plant/${plantId}/element/${elementId}/properties`,
    onSuccess: (s, { data }, a, { params }) => {
      const index = s.list.findIndex(x => x.elementId === params.elementId);
      const props = {
        elementId: params.elementId,
        properties: data.map(i => ({
          ...i,
          value: i.value,
        })),
      };

      if (index === -1) {
        s.list.push(props);
      } else {
        Vue.set(s.list, index, props);
      }
    },
  })
  .get({
    action: 'getElementsProperties',
    property: 'list',
    path: ({ plantId }) =>
      `/v2/plant/${plantId}/elements/properties`,
  })
  .get({
    action: 'getLineBaseProp',
    path: ({ plantId, lineId }) =>
      `/v2/plant/${plantId}/line/${lineId}/base`,
  })
  .get({
    action: 'getLineMachineInfos',
    path: ({ plantId, lineId }) =>
      `/v2/plant/${plantId}/line/${lineId}/machine-info`,
  })
  .put({
    action: 'setLineBaseProp',
    path: ({ plantId, lineId }) =>
      `/v2/plant/${plantId}/line/${lineId}/base`,
  })
  .put({
    action: 'setElementProperty',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/property`,
    onSuccess: (s, { data }, a, { params }) => {
      let el = s.list.find(({ elementId }) => elementId === params.elementId);
      if (!el) {
        el = { properties: [], elementId: params.elementId };
        s.list.push(el);
      }

      const index = el.properties.findIndex(({ name }) => data.name.toLowerCase() === name.toLowerCase());
      if (index === -1) {
        el.properties.push(data);
      } else {
        Vue.set(el.properties, index, data);
      }
    },
  })
  .delete({
    action: 'deleteElementProperty',
    path: ({ plantId, elementId, name }) =>
      `/v2/plant/${plantId}/element/${elementId}/property/${name}`,
    onSuccess: (s, p, _, { params }) => {
      const el = s.list.find(({ elementId }) => elementId === params.elementId);
      if (el) {
        el.properties = el.properties
          .filter(({ name }) => name.toLowerCase() !== params.name.toLowerCase());
      }
    },
  })
  .getStore();

store.mutations.addProperty = (s, data) => {
  s.list.push(data);
};

store.mutations.updateProperty = (s, prop) => {
  const index = s.list.findIndex(({ name }) => name === prop.name);
  if (index !== -1) {
    Vue.set(s.list, index, prop);
  }
};

store.mutations.setPropertyValue = (s, { elementId, name, value }) => {
  const el = s.list.find(x => x.elementId === elementId);
  const prop = el.properties.find(x => x.name.toLowerCase() === name.toLowerCase());
  if (prop) {
    prop.value = value;
  }
};

store.getters = {
  elementProperties: s => elementId => {
    const prop = s.list.find(el => el.elementId === elementId);
    return prop ? prop.properties : [];
  },
  elementProperty: (s, g) => (elementId, name) => {
    const prop = g.elementProperties(elementId)
      .find(x => x.name.toLowerCase() === name.toLowerCase());

    return prop ? prop.value : undefined;
  },
};

export default store;
