<template>
  <div
    class="elements-tree mw-100"
    :class="{ opened: hovered || opened }"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <div
      v-if="!hideSearch"
      class="pb-2"
    >
      <BFormInput
        ref="input"
        v-model="search"
        :placeholder="`${$t('general.search')}...`"
        style="max-width: 600px"
        size="sm"
      />
    </div>
    <TreeTable
      v-if="root"
      :style="{ 'max-height': `${maxHeight}px`, 'overflow': 'auto' }"
      class="element-list"
      :columns="columns"
      :root="root"
      :expand-list="expandList"
      :list="filteredElements"
      :hide="!showPlant"
      :hide-actions="true"
      :default-expand="showExpanded || search.length > 0"
      :selected="selected"
      show-null-id-element
      @select="$emit('select', $event)"
    />
    <div
      v-if="!hovered && !opened"
      class="overlay"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import createSearch from '@core/utils/search';
import TreeTable from '@/components/tables/TreeTable';
import { elementType } from '@/utils/dictionary';

const removeType = (type, arr) => {
  const ofType = arr.filter(t => t.type === type);

  return arr
    .filter(t => t.type !== type)
    .map(el => {
      const p = ofType.find(s => s.id === el.parentId);
      if (p) {
        return {
          ...el,
          parentId: p.parentId,
        };
      }
      return el;
    });
};

export default {
  props: {
    opened: Boolean,
    selected: String,
    transform: Function,
    filter: Function,
    showToggle: Boolean,
    showPlant: Boolean,
    showAreas: Boolean,
    noMachines: Boolean,
    showExpanded: Boolean,
    hideSearch: Boolean,
    maxHeight: Number,
    perm: String,
    selectedTaxonomy: String,
  },
  data: () => ({
    hovered: false,
    search: '',
  }),
  components: {
    TreeTable,
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'plantId',
    ]),
    ...mapGetters('taxonomies', [
      'elementTaxonomyBindings',
    ]),
    ...mapGetters('plant', [
      'structure',
      'elementsOrder',
      'plant',
    ]),
    ...mapState({
      elementTaxonomies: state => state.taxonomies.elementTaxonomies,
    }),
    root() {
      return this.taxonomyBasedRootElement || this.plant(this.plantId);
    },
    expandList() {
      if (this.selectedTaxonomy) return this.taxonomyBasedElementTree.map(i => i.id);

      if (!this.selected || this.selected === this.plantId) {
        const areas = this.elementsTree.filter(x => x.type === elementType.area);
        return areas.map(x => x.id).concat(this.plantId);
      }

      const l = [];
      let id = this.selected;
      while (id) {
        l.push(id);
        id = this.structure.parent(id);
      }
      return l;
    },
    columns() {
      const c = [
        { key: 'name' },
      ];

      if (this.showToggle) {
        c.push({
          key: 'status',
        });
      }
      return c;
    },
    selectedTaxonomyObject() {
      if (!this.selectedTaxonomy) return null;
      const taxonomy = this.elementTaxonomies.find(t => t.id === this.selectedTaxonomy);
      if (!taxonomy) return null;
      return taxonomy;
    },
    taxonomyBasedRootElement() {
      if (!this.selectedTaxonomyObject) return null;
      return {
        id: null,
        name: this.selectedTaxonomyObject.description || this.selectedTaxonomyObject.code || '-',
      };
    },
    taxonomyBasedElementTree() {
      if (!this.selectedTaxonomyObject) return null;

      return this.selectedTaxonomyObject.nodes.map(n => ({
        ...n,
        name: n.description || n.code,
      }));
    },
    elementsTree() {
      const result = this.taxonomyBasedElementTree || this.structure.nodeList();

      let elements;

      if (this.showAreas) {
        elements = removeType(elementType.segment, result);
      } else {
        elements = removeType(elementType.area,
          removeType(elementType.segment, result));
      }

      if (this.noMachines) {
        elements = elements.filter(e => {
          const parentId = this.structure.parent(e.id, 2);
          return !parentId || parentId.toLowerCase() === this.plantId.toLowerCase();
        });
      }

      if (this.filter) {
        elements = elements.filter(this.filter);
      }

      if (this.transform) {
        return elements.map(el => this.transform(el));
      }

      return elements
        .sort((a, b) => {
          if (!this.elementsOrder[a.id]) return 1;
          if (!this.elementsOrder[b.id]) return -1;

          return this.elementsOrder[a.id] - this.elementsOrder[b.id];
        });
    },
    filteredElements() {
      if (this.search) {
        const search = createSearch(this.search);

        const filtered = this.elementsTree
          .filter(e => search(e.name))
          .reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {});

        Object.keys(filtered)
          .forEach(id => {
            let m = filtered[id];

            while (!filtered[m.parentId]) {
              // eslint-disable-next-line no-loop-func
              m = this.elementsTree.find(el => el.id === m.parentId);
              if (!m) break;
              filtered[m.id] = m;
            }
          });

        return Object.values(filtered);
      }

      return this.elementsTree;
    },
  },
  watch: {
    selectedTaxonomy() {
      this.getTaxonomyBindings();
    },
  },
  methods: {
    ...mapActions('taxonomies', ['getElementTaxonomyBinding']),
    async getTaxonomyBindings() {
      if (!this.selectedTaxonomy) return;
      await this.getElementTaxonomyBinding({
        params: {
          plantId: this.plantId,
          query: {
            taxonomyId: this.selectedTaxonomy,
          },
        },
      });
    },
  },
  mounted() {
    if (this.$refs.input) {
      this.$refs.input.focus();
    }
  },
  created() {
    this.getTaxonomyBindings();
  },
};
</script>

<style lang="scss" scoped>
.element-list :deep(.selected) {
  font-weight: bold;
}

.elements-tree {
  position: relative;
  width: 100px;
  overflow: hidden;
  transition: width 400ms;

  &.opened {
    width: 350px;
  }
}
</style>
