<template>
  <div>
    <LTable
      :searchable="true"
      :columns="tableFields"
      :list="groups"
      :use-advanced-search="true"
      :selectable="false"
    >
      <template #edit="{ item }">
        <div
          v-if="canEditSkus"
          class="text-nowrap text-right"
          style="margin: -2px 0"
        >
          <button
            class="btn btn-outline-secondary btn-sm icon-btn mx-1"
            style="font-size: 13px"
            @click="select(item.id)"
          >
            <i class="fas fa-pen" />
          </button>
        </div>
      </template>

      <template
        v-if="showImportResult"
        #importResult="{ item }"
      >
        <div v-if="item.importResult.statusForUser === 'NotSaved'">
          <div class="text-danger text-center font-weight-bold">
            <i class="fas fa-times pr-2" />
            {{ $t('import.notSaved') }}
          </div>
        </div>
        <div
          v-else-if="item.importResult.statusForUser === 'ActionRequired'"
          class="d-flex align-items-center justify-content-center h-100"
        >
          <div class="text-warning text-center font-weight-bold">
            <i class="fas fa-exclamation pr-2" />
            {{ $t('import.actionRequired') }}
          </div>
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-center h-100"
        >
          <div class="text-success text-center font-weight-bold">
            <i class="fas fa-check pr-2" />
            {{ $t('import.saved') }}
          </div>
        </div>
      </template>
    </LTable>
    <div
      v-if="!groups || groups.length < 1"
      class="text-center font-weight-bold"
    >
      {{ $t('general.noData') }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    groups: Array,
    canEditSkus: Boolean,
    showImportResult: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groupsWithLineNumber() {
      return this.groups.map(x => ({ ...x, linesNo: x.lines.length }));
    },
    tableFields() {
      return [
        {
          key: 'name',
          text: this.$t('general.name'),
          sortable: true,
        },
        {
          key: 'importResult',
          label: '',
          noSearch: true,
          hide: !this.showImportResult,
        },
        {
          key: 'edit',
          text: '',
        },
      ].filter(x => !x.hide);
    },
  },
  methods: {
    select(groupId) {
      const group = this.groups.find(x => x.id === groupId);
      this.$emit('edit', group);
    },
  },
};
</script>

<style>

</style>
