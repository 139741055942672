<template>
  <BOverlay
    :show="saving"
    opacity="0.5"
    spinner-variant="primary"
  >
    <div class="comment-create-wrapper">
      <div class="d-flex align-items-center">
        <div class="text-black-50 h3 mb-0 mr-2">
          <i class="fas fa-user-circle" />
        </div>

        <BFormInput
          v-model="newAnnotation"
          size="md"
          class="rounded-input"
          :placeholder="$t('downtimes.addComment')"
          @keydown.enter="createAnnotation"
        />

        <BButton
          variant="primary"
          class="ml-2"
          :disabled="!newAnnotation"
          @click="createAnnotation"
        >
          <i class="fas fa-share" />
        </BButton>
      </div>
    </div>
  </BOverlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DowntimeCommentCreateBox',
  inject: ['appendComments'],
  props: {
    elementId: {
      type: String,
      default: '',
    },
    startDateTime: {
      type: String,
      default: '',
    },
    startDate: Number,
    rootWorkLogId: {
      type: String,
      default: '',
    },
    workEvent: {
      type: Object,
      default: () => ({}),
    },
    commonComment: {
      type: Boolean,
      default: false,
    },
    commonActionWorklogs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    saving: false,
    newAnnotation: '',
  }),
  computed: {
    ...mapGetters(['plantId']),
  },
  methods: {
    ...mapActions('work', [
      'addAnnotation',
    ]),
    createAnnotation() {
      if (!this.newAnnotation) return;

      this.saving = true;

      this.addAnnotation({
        params: {
          plantId: this.plantId,
        },
        data: {
          elementId: this.commonComment
            ? this.commonActionWorklogs[0].elementId
            : this.elementId,
          timestamps: this.commonComment
            ? this.commonActionWorklogs.map(d => d.startDateTime)
            : [this.startDateTime],
          description: this.newAnnotation,
        },
      }).then(({ data }) => {
        data.operationIds.forEach(id => {
          this.$root.$emit('user-operation-requested', { id, type: 'AddWorkLogAnnotationMessage' });
        });

        this.appendComments(
          data.annotations,
        );
        this.newAnnotation = '';
        this.$emit('created-comment');
      })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .comment-create-wrapper {
    background-color: #F8F8F8;
    padding: 22px 40px;
    border-top: 2px solid #ECECEC;
  }
</style>
