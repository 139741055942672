import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const units = new Vapi({
  axios,
})
  .get({
    action: 'getPlantUnits',
    property: 'list',
    path: ({ plantId }) => `v2/plant/${plantId}/units`,
    onSuccess: (s, { data }) => {
      s.list = data.sort((a, b) => a.code.localeCompare(b.code));
    },
  })
  .post({
    action: 'addUnit',
    path: ({ plantId }) => `v2/plant/${plantId}/unit`,
    onSuccess: (s, { data }) => {
      if (!s.list.some(u => u.id === data.id)) {
        s.list.push(data);
      }
    },
  })
  .put({
    action: 'updateUnit',
    path: ({ plantId, unitId }) => `v2/plant/${plantId}/unit/${unitId}`,
    onSuccess: (s, { data }, a, { params }) => {
      const idx = s.list.findIndex(u => u.id === params.unitId);
      Vue.set(s.list, idx, data);
    },
  })
  .delete({
    action: 'removeUnit',
    path: ({ plantId, unitId }) => `v2/plant/${plantId}/unit/${unitId}`,
    onSuccess: (s, _, a, { params }) => {
      s.list = s.list.filter(u => u.id !== params.unitId);
    },
  })
  .get({
    action: 'getPackingStructure',
    path: ({ plantId, refId }) => `v2/plant/${plantId}/packing/structure/${refId}`,
  })
  .get({
    action: 'getRecurringPackingStructure',
    path: ({ plantId, refId }) => `v2/plant/${plantId}/packing/recurring-structure/${refId}`,
  })
  .get({
    action: 'getPackingStructures',
    path: ({ plantId }) => `v2/plant/${plantId}/packing/structure`,
  })
  .post({
    action: 'createPackingStructureEntry',
    path: ({ plantId }) => `v2/plant/${plantId}/packing/structure`,
  })
  .delete({
    action: 'deletePackingStructureEntry',
    path: ({ plantId, id }) => `v2/plant/${plantId}/packing/structure/${id}`,
  })
  .getStore();

export default units;
