<template>
  <div>
    <HorizontalMenu
      v-if="displayLabour && !manualSummary && !hideManualProduction"
      :selected.sync="selected"
      :list="resourceTypes"
      class="mb-3"
    />

    <div v-if="selected === 'production'">
      <ProductionReconciliation
        :order="order"
        hide-order
      />
    </div>
    <div v-else>
      <div class="mt-2">
        <ActivityResourcesForm
          v-if="hasData && activityType"
          :line-id="lineId"
          :order-unit="orderUnit"
          :order-production="orderProduction"
          :order-waste="orderWaste"
          :disabled="disabled"
          :activity-id="activityId"
          :manual-summary="manualSummary"
          :resource-type="selectedResourceType"
          :scope-start="scopeStart"
          :scope-end="scopeEnd"
          :activity-type="activityType"
          :entries="entries"
          :action-name="actionName"
          @prodDone="$emit('prodDone', $event)"
          @prodWasted="$emit('prodWasted', $event)"
          @refresh="refresh"
          @complete="$emit('complete')"
          @cancel="$emit('cancel')"
        />
        <div
          v-else
          class="text-center"
        >
          <Loader />
        </div>
      </div>
      <hr>

      <ManualResourceEntries
        :history-pending="pending"
        :activity-id="activityId"
        :activity-start="scopeStart"
        :entries="entries"
        :resource-type="selected"
        @removed="entries = entries.filter(x => x.id !== $event.id); $emit('refresh')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { activityType, manualResourceType } from '@/utils/dictionary';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import ProductionReconciliation from '@/components/activity/ProductionReconciliation.vue';
import ActivityResourcesForm from './ActivityResourcesForm';
import ManualResourceEntries from './resources/ManualResourceEntries';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    scopeStart: {
      type: String,
      required: true,
    },
    lineId: {
      type: String,
      required: true,
    },
    activityType: {
      type: String,
      default: 'Unknown',
    },
    manualSummary: {
      type: Boolean,
      default: false,
    },
    orderUnit: {
      type: String,
      default: '',
    },
    hideManualProduction: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    orderProduction: Number,
    orderWaste: Number,
    scopeEnd: String,
    actionName: String,
    humanResources: Boolean,
  },
  data: () => ({
    selected: 'production',
    entries: [],
    pending: false,
    error: null,
    hasData: false,
  }),
  components: {
    ProductionReconciliation,
    HorizontalMenu,
    ActivityResourcesForm,
    ManualResourceEntries,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementName']),
    ...mapGetters('plant', ['displayLabour']),
    selectedResourceType() {
      return this.resourceTypes.find(x => x.key === this.selected);
    },
    resourceTypes() {
      return [
        ...(this.activityType === activityType.order ? [{
          key: manualResourceType.production,
          name: this.$t('activities.resources.production'),
          icon: 'far fa-clipboard',
        }] : []),
        {
          key: manualResourceType.humanResources,
          name: this.$t('activities.resources.manhours'),
          icon: 'fas fa-male',
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'getActivityManualResources',
    ]),
    refresh(entries) {
      if (entries) {
        this.entries = entries;
        this.hasData = true;
        this.$emit('refresh');
        return;
      }

      this.pending = true;
      this.$emit('refresh');

      this.getActivityManualResources({
        params: {
          plantId: this.plantId,
          activityId: this.activityId,
        },
      })
        .then(({ data }) => {
          this.entries = data;
          this.hasData = true;
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.refresh();
    if ((this.activityType !== activityType.order && this.displayLabour) || this.humanResources) {
      this.selected = manualResourceType.humanResources;
    }
  },
};
</script>

<style lang="scss" scoped>
.line-name {
  font-size: 20px;
  font-weight: 600;
}
</style>
