export default {
  'addDowntime': 'Thêm thời gian ngừng máy',
  'newDowntime': 'Thời gian ngừng máy mới',
  'actionsAndReasons': 'Hành động và nguyên nhân',
  'performActions': 'Thực hiện các hành động sau:',
  'secifyReasonIfKnown': 'Chỉ rõ nguyên nhân',
  'downtimelist': 'Nguyên nhân ngừng máy',
  'annotations': 'Chú thích',
  'categories': 'Thẻ/ nhãn',
  'clearRootCause': 'Nguyên nhân gốc rễ rõ ràng',
  'reason': 'Nguyên nhân',
  'reasons': 'Các nguyên nhân',
  'rootCauseReason': 'Nguyên nhân gốc rễ',
  'noReasons': 'Không có nguyên nhân xác định',
  'noCorrectiveActions': 'Không có hành động khắc phục nào được xác định',
  'noEvents': 'Không có sự kiện nào được xác định',
  'noCameras': 'Không có camera nào được xác định',
  'removeReasonWorning': 'Cảnh báo! Tất cả các hành động khắc phục liên quan đến lý do này sẽ bị loại bỏ!',
  'addReason': 'Như một lý do mới',
  'selectEvent': 'Sự kiện / Vấn đề',
  'newCorrectiveAction': 'Hành động khắc phục mới',
  'correctiveAction': 'Hành động khắc phục',
  'correctiveActions': 'Các hành động khắc phục',
  'callForService': 'Gọi dịch vụ',
  'serviceHasBeenCalled': 'Dịch vụ đã được gọi',
  'specifyRootcause': 'Nguyên nhân gốc',
  'lastDowntimes': 'Thời gian ngừng máy cuối cùng',
  'startTime': 'Đã bắt đầu',
  'endTime': 'Đã kết thúc',
  'externalReason': 'Lý do bên ngoài',
  'uncategorized': 'Chưa được phân loại',
  'noActions': 'Không có hành động để thực hiện',
  'timeSlot': 'Khung thời gian',
  'selectOtherMachineDowntime': 'Chọn thời gian ngưng máy khác',
  'selectMachine': 'Chọn máy',
  'workEventFilter': 'Bộ lọc sự kiện',
  'deselectAll': 'Bỏ chọn tất cả',
  'connect': 'Kết nối',
  'commonReason': 'Lý do chung',
  'commonProblem': 'Vấn đề chung',
  'clearSelection': 'Xóa vùng chọn',
  'noReason': 'Không có lý do',
  'addOrCreateReason': 'Chọn lý do liên quan',
  'lineState': 'Trạng thái dây chuyền',
  'selectProblemFirst': 'Chọn vấn đề trước',
  'setProblem': 'Thiết lập vấn đề',
  'setReason': 'Thiết lập lý do',
  'setCorrectiveAction': 'Thiết lập hành động khắc phục',
  'summary': 'Tổng kết',
  'deleteConfirmMessage': 'Xác nhận bạn muốn xóa thời gian ngừng máy đã chọn.',
  'changeDuration': 'Thay đổi thời gian',
  'downtimeUpdated': 'Thời gian ngừng máy được cập nhật',
  'newReason': 'Nguyên nhân mới',
  'noCorrectiveAction': 'Không có hành động khắc phục nào được thực hiện',
  'toEditSetCommonWorkEvent': 'Để chỉnh sửa nhóm sự kiện công việc phổ biến',
  'differentCorrectiveActions': 'Các hành động khắc phục khác',
  'differentReasons': 'Các nguyên nhân khác',
  'noMachineDowntime': 'Không áp dụng cho máy',
  'setAction': 'Đặt hành động',
  'addAnotherCorrectiveAction': 'Thêm một hành động khắc phục khác',
  'addAnotherReason': 'Thêm một nguyên nhân khác'
};
