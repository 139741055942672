<template>
  <div>
    <div
      class="pb-2"
      style="font-size: 25px;"
    >
      <div
        v-if="elementType(elementId) === elementTypeEnum.line"
        v-html="$t('lineControl.startLine', { line: elementName(elementId) })"
      />
      <div
        v-else
        v-html="$t('machineControl.startMachine', { line: elementName(elementId) })"
      />
    </div>
    <hr class="mt-2">
    <div
      v-if="error"
      class="text-danger text-center"
    >
      <div style="font-size: 20px; line-height: 1">
        <i class="ion ion-md-close" />
        {{ $t('error.error') }}
      </div>

      <Error :message="error.detail" />
    </div>
    <div class="d-flex justify-content-center mt-2 pb-3">
      <div class="mr-2">
        <button
          class="btn btn-secondary"
          style="width: 150px"
          @click="$emit('close')"
        >
          <i class="ion ion-ios-arrow-back pr-2" />
          {{ $t('general.cancel') }}
        </button>
      </div>
      <div class="ml-2">
        <button
          class="btn btn-success"
          style="width: 150px"
          :disabled="pending"
          @click="start"
        >
          <span v-if="!pending">
            <i class="ion ion-md-play pr-2" />
            {{ $t('lineControl.start') }}
          </span>
          <span v-else>
            <Loader
              color="#fff"
              :size="18"
            />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { elementType } from '@/utils/dictionary';

export default {
  props: {
    elementId: String,
  },
  data: () => ({
    pending: false,
    error: null,
    elementTypeEnum: elementType,
  }),
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementName', 'elementType']),
  },
  methods: {
    ...mapActions('work', ['startElement']),
    start() {
      this.error = null;
      this.pending = true;
      this.$emit('pending', true);

      this.startElement({
        params: {
          plantId: this.plantId,
          elementId: this.elementId,
        },
      })
        .then(({ data }) => {
          this.pending = false;
          this.$emit('pending', false);
          this.$emit('close');

          data.operationIds.forEach(id => {
            this.$root.$emit('user-operation-requested', { id, type: 'RecalculateElementStateMessage' });
          });
        })
        .catch(({ response: { data } }) => {
          this.pending = false;
          this.$emit('pending', false);
          this.error = data;
        });
    },
  },
};
</script>

<style>

</style>
