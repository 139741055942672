<template>
  <div>
    <div>
      <div class="mb-4">
        <label class="title-style">
          {{ $t('activities.activityType') }}
        </label>
        <div class="row pl-3">
          <div
            v-for="t in filteredTypes"
            :key="t.value"
            :value="t.value"
            class="activity-box"
            :class="{
              selected: selectedType === t.value,
              disabled: edited,
            }"
            @click="selectedType = t.value"
          >
            <i
              style="font-size: 1.8em"
              :class="t.icon"
            />
            <div
              style="font-size: 1.2em"
              class="pt-2"
            >
              {{ t.text }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
          >
            {{ $t('activities.activityId') }}
          </label>
          <div class="w-100 d-flex align-items-center">
            <BFormInput
              v-model="externalId"
              :disabled="disabled || edited"
              :placeholder="$t('activities.activityId')"
              :state="externalIdCheck(externalId) | ifValidated(validate)"
              trim
            />
            <BSpinner
              v-show="!loadedActivityAlreadyExists"
              class="position-absolute"
              style="right: -20px"
              variant="primary"
              small
            />
          </div>
          <Error
            v-if="activityAlreadyExists"
            class="mt-2"
            :message="$t('activities.activityAlreadyExists')"
          />
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
          >
            {{ $t('general.description') }}
            <i
              v-if="type !== 'Cleaning'"
              v-tippy="{arrow: true, maxWidth: 150, placement: 'right'}"
              class="ion ion-ios-information-circle-outline perf-info-icon"
              :content="$t('settings.sku.skuDescriptionTooltip')"
            />
          </label>
          <div class="w-100 d-flex align-items-center">
            <BFormInput
              v-model="description"
              :placeholder="$t('general.description')"
              trim
            />
          </div>
        </div>
      </div>

      <div
        v-if="!fixedLineId"
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
          >
            {{ $t('line') }}
          </label>
          <BFormSelect
            v-if="lines"
            v-model="lineId"
            :disabled="disabled"
          >
            <option
              v-for="line in linesWithOrderViewAccess"
              :key="line.id"
              :value="line.id"
            >
              {{ line.name }}
            </option>
          </BFormSelect>
          <div v-else>
            <div
              class="d-flex flex-row justify-content-center ml-2"
              style="line-height: 40px"
            >
              <Loader
                size="15px"
                class="mr-2"
              />
            </div>
          </div>
          <p
            v-if="isLineError"
            class="text-danger font-weight-bold pt-1 mb-0"
          >
            <i class="fas fa-exclamation-circle" />
            {{ $t('activities.validationErrors.lineError') }}
          </p>

          <AdditionalFieldsTable
            :additional-fields.sync="additionalFields"
            class="mt-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AdditionalFieldsTable from '@/components/additionalFields/AdditionalFieldsTable';
import activityForm from '@/mixins/activityForm';
import { activityType, activityValidationErrorCodes } from '@/utils/dictionary';
import { mapGetters } from 'vuex';

export default {
  props: {
    activity: Object,
    type: String,
    edited: Boolean,
    disabled: Boolean,
    activityAlreadyExists: Boolean,
    loadedActivityAlreadyExists: Boolean,
  },
  data() {
    return {
      selectedType: null,
      types: [
        {
          text: this.$t('activities.order'),
          value: activityType.order,
          icon: 'fas fa-tags',
        },
        {
          text: this.$t('activities.cleaning'),
          value: activityType.cleaning,
          icon: 'fas fa-shower',
        },
        {
          text: this.$t('activities.changeover'),
          value: activityType.changeover,
          icon: 'fas fa-wrench',
        },
      ],
      activityType,
      lineId: null,
      externalId: '',
      description: '',
      additionalFields: [],
    };
  },
  components: { AdditionalFieldsTable },
  mixins: [activityForm()],
  computed: {
    ...mapGetters(['plantId']),
    errorCheck() {
      if (this.activity.externalId
        && this.lineId
        && !this.activityAlreadyExists
      ) return true;
      return false;
    },
    filteredTypes() {
      return this.edited ? this.types.filter(y => y.value === this.type)
        : this.types.filter(x => x.value !== activityType.changeover);
    },
    isLineError() {
      if (!this.validationErrors || this.validationErrors.length === 0) return null;

      return this.validationErrors.find(e => e.code === activityValidationErrorCodes.lineErrorCode)
        && !this.lineId;
    },
  },
  watch: {
    isLineError(val) {
      if (val) {
        this.$emit('solve-errors', [
          activityValidationErrorCodes.lineErrorCode,
        ]);
      }
    },
    lineId(val) {
      this.$emit('lineId', val);
    },
    externalId(val) {
      this.validateModel();
      this.$emit('externalId', val);
    },
    selectedType(val) {
      this.$emit('selectedType', val);
    },
    description(val) {
      this.$emit('description', val);
    },
    additionalFields(val) {
      this.$emit('additionalFields', val);
    },
  },
  methods: {
    externalIdCheck(v) {
      return !!v && !this.activityAlreadyExists;
    },
    validateModel() {
      this.validate = true;
      return this.errorCheck;
    },
  },
  created() {
    this.lineId = this.activity?.lineId || null;
    this.externalId = this.activity?.externalId || '';
    this.description = this.activity?.description || '';
    this.selectedType = this.type || activityType.order;
    this.additionalFields = this.activity?.additionalFields || [];
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .activity-box {
    border-radius: 5px;
    border: 2.5px solid;
    background-color: white;
    padding-top: 35px;
    padding-inline: 10px;
    margin-right: 5px;
    height: 125px;
    width: 145px;
    text-align: center;
    opacity: 0.8;
    color: gray;
    cursor: pointer;

    &.selected {
      color: $ilabo;
    }

    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.8;
      cursor: default;
    }
  }

  .title-style {
    font-weight: 500;
    font-size: 1.1em;
  }

</style>
