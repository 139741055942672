<template>
  <div class="d-flex align-items-center history-entry">
    <div class="col">
      {{ format(start) }}
    </div>
    <div>
      ->
    </div>
    <div class="col">
      {{ format(end) }}
    </div>
    <div class="col">
      <div
        v-for="m in matrix"
        :key="`${m.fromUnit}_${m.toUnit}`"
      >
        {{ m.fromUnit }} -> {{ m.toUnit }}: {{ m.multiplier | round }}
      </div>
      <div v-if="matrix.length === 0">
        -
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    start: String,
    end: String,
    matrix: Array,
  },
  methods: {
    format(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.history-entry {
  padding: 5px 10px;
  box-shadow: 0 2px 4px #eee;
  border-radius: 5px;
}
</style>
