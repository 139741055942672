import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    orderStart: null,
    orders: null,
    activeOrders: [],
  },
})
  .get({
    action: 'getCountersSummary',
    path: ({ plantId }) => `/v2/plant/${plantId}/counters`,
  })
  .get({
    action: 'getOrderCountersSummary',
    path: ({ plantId }) => `/v2/plant/${plantId}/order/counters`,
  })
  .get({
    action: 'getCounterSummary',
    path: ({ plantId }) => `/v2/plant/${plantId}/counters`,
  })
  .getStore();

export default store;
