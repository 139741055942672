export default {
  'activities': 'Các hoạt động',
  'activityType': 'Loại hoạt động',
  'addActivity': 'Lập kế hoạch hoạt động mới',
  'add': 'Thêm hoạt động',
  'edit': 'Chỉnh sửa hoạt động',
  'addChangeover': 'Thêm chuyển đổi',
  'step': 'Bước',
  'order': 'Đơn hàng',
  'general': 'Chung/ Tổng quan',
  'quantity': 'Số lượng',
  'dates': 'Ngày',
  'configuration': 'Cấu hình',
  'plannedStart': 'Bắt đầu theo kế hoạch',
  'plannedEnd': 'Kết thúc theo kế hoạch',
  'cleaning': 'Vệ sinh',
  'changeover': 'Chuyển đổi',
  'activityId': 'ID hoạt động',
  'activity': 'Hoạt động',
  'activityStart': 'Bắt đầu hoạt động',
  'toStart': 'để bắt đầu',
  'afterStart': 'sau khi bắt đầu theo kế hoạch',
  'activityAlreadyExists': 'ID hoạt động đã tồn tài',
  'activityIdIsTooLong': 'ID hoạt động quá dài',
  'changeoverDuration': 'Thời gian chuyển đổi (phút)',
  'manage': 'Quản lý hoạt động',
  'changeoverType': 'Loại chuyển đổi',
  'editExisting': 'Chỉnh sửa hiện tại',
  'pickALine': 'Chọn một dây chuyền',
  'typeEffort': 'Loại nỗ lực',
  'showNotPlanned': 'Hiển thị các hoạt động không theo kế hoạch',
  'summary': {
    'summary': 'Tổng kết',
    'orderTotal': 'Tổng đơn hàng',
    'lineTotal': 'Tổng dây chuyền',
    'inLineUnit': 'trong đơn vị dây chuyền',
    'inOrderUnit': 'trong đơn vị đơn hàng',
    'goodCount': 'Sản phẩm đạt',
    'wasteCount': 'Sản phẩm loại',
    'counterTotal': 'Tổng đếm',
    'performanceGuard': 'Performance Guard',
    'stateGuard': 'State Guard',
    'performanceGuardTippy': 'Đã vượt ngưỡng hiệu suất',
    'missingPackingStructure': 'Thiếu cấu trúc đóng gói',
    'manualReconciliation': 'đối chiếu thủ công'
  },
  'list': {'order': 'Các đơn hàng', 'cleaning': 'Vệ sinh', 'changeover': 'Chuyển đổi'},
  'activityAdded': 'Hoạt động đã thêm',
  'noActivities': 'Không có hoạt động',
  'status': {
    'released': 'Giải phóng',
    'draft': 'Nháp',
    'started': 'Đã bắt đầu',
    'suspended': 'Gián đoạn/ Hoãn lại',
    'completed': 'Đã hoàn thành',
    'activated': 'Đã kích hoạt',
    'scheduled': 'Đã lên kế hoạch',
    'notPlanned': 'Chưa lên kế hoạch'
  },
  'schedule': {
    'tableView': 'Bảng',
    'axisView': 'Thời hạn',
    'full': 'Đầy đủ',
    'collapsed': 'Đã thu gọn',
    'scheduled': 'Đã lên kế hoạch',
    'currentHour': 'Hết giờ hiện tại',
    'previousHour': 'Giờ trước',
    'previousShift': 'Ca làm việc trước',
    'currentShift': 'Ca làm việc hiện tại'
  },
  'resources': {
    'production': 'Sản xuất',
    'waste': 'Phế phẩm/ chất thải',
    'manhours': 'Giờ lao động',
    'timeScope': 'Phạm vi thời gian',
    'workers': 'Các công nhân',
    'worker': 'Công nhân',
    'history': 'Lịch sử',
    'noEntries': 'Không có lối vào',
    'hours': 'giờ',
    'minutes': 'Phút'
  },
  'actions': {
    'finishChangeover': 'Kết thúc và bắt đầu đơn hàng',
    'abortChangeover': 'Hủy bỏ chuyển đổi',
    'productionReconciliation': 'đối chiếu sản xuất',
    'finishOrder': 'Kết thúc đơn hàng'
  },
  'validations': {
    'orderNotFound': 'Không tìm thấy đơn đặt hàng liên quan',
    'externalIdError': 'ID chỉ có thể chứa số, chữ cái, ký tự \'-\', \'/\' và \'_\' và chỉ có thể bắt đầu bằng một số hoặc một chữ cái'
  },
  'deleteConfirmMessage': 'Bạn có chắc chắn muốn xóa hoạt động này không?',
  'hasValidationErrors': 'Có lỗi xác thực',
  'noLine': 'Không có dây chuyền sản xuất',
  'editOrder': 'Chỉnh sửa đơn hàng',
  'editChangeover': 'Chỉnh sửa chuyển đổi',
  'cannotCreateOrder': 'Không thể tạo đơn hàng',
  'orderExists': 'Đã tồn tại đơn đặt hàng có ID hoạt động được chỉ định.',
  'endOfLastShift': 'Kết thúc ca cuối cùng',
  'endOfLastHour': 'Kết thúc giờ cuối cùng',
  'productionHistory': 'Lịch sử sản xuất',
  'manhoursHistory': 'Lịch sử giờ lao động',
  'changeoverDurationTitle': 'Khoảng thời gian chuyển đổi',
  'expectedEffort': 'Nỗ lực dự kiến (mh)',
  'expectedChangeoverEffort': 'Nỗ lực chuyển đổi dự kiến (mh)',
  'typeCorrectExpectedEffort': 'Nỗ lực dự kiến không thể là tiêu cực',
  'typeCorrectValue': 'Giá trị không được âm',
  'typeChangeoverDurationIfChangeoverExpectedEffortGiven': 'Cần có thời lượng chuyển đổi dự kiến khi nỗ lực chuyển đổi dự kiến được đưa ra',
  'editMode': 'Chế độ chỉnh sửa',
  'validationErrors': {
    'skuError': 'Sản phẩm bị thiếu hoặc cấu hình không chính xác',
    'flowIdError': 'Cấu hình dòng bị thiếu',
    'endDateNotValid': 'Ngày kết thúc theo kế hoạch không hợp lệ',
    'lineError': 'Cấu hình dây chuyền bị thiếu',
    'orderedQuantityError': 'Số lượng đặt hàng bị thiếu',
    'orderedQuantityUnitError': 'Đơn vị số lượng đặt hàng bị thiếu hoặc cấu hình không chính xác'
  }
};
