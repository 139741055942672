<template>
  <div class="state-info">
    <span
      v-if="currentState"
      :style="{
        color: workState ? colors(workState.colorCode) : null,
      }"
    >
      <div
        v-if="showTime"
        class="state-duration"
      >
        {{ currentState ? CalcDuration(currentState.startDate, now) : '' }}
      </div>
      <div
        style="overflow-x: hidden; text-overflow: ellipsis;"
      >
        <StateBadge
          :state="workState"
          class="state text-center"
        />
      </div>
      <span
        v-if="showEvent && (!rootState || rootState.id != workState.id)"
        class="work-event"
      >
        {{ workEventDescription }}
      </span>

      <div
        v-if="showEvent && rootEvent"
        :style="{
          color: eventState ? colors(eventState.colorCode) : null
        }"
        class="work-event flex-grow-1 pt-1"
        style="line-height: 1;"
      >
        <span class="mr-1">
          →
        </span>
        <span
          v-if="rootMachine"
          class="text-nowrap"
          style="line-height: 20px"
        >
          [ {{ rootMachine }} ]
        </span>
        <StateBadge
          v-if="rootState && workState && rootState.id != workState.id"
          :state="rootState"
          style="font-size: 9px; position: relative; top: -2px"
          class="mr-1"
        />{{ rootWorkEventDescription }}
      </div>
      <div
        v-if="currentState.reasonDescription"
        class="reason-comment mt-1"
      >
        {{ $t('work.reason') }}: {{ decodeURIComponent(currentState.reasonDescription) }}
      </div>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loopRequest from '@/mixins/loopRequest';
import CalcDuration from '@/utils/calcDuration';
import colors from '@/utils/colors';

export default {
  props: {
    currentState: Object,
    elementId: String,
    showTime: {
      type: Boolean,
      default: true,
    },
    showEvent: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    now: 0,
  }),
  mixins: [loopRequest('updateNow', 1000)],
  computed: {
    ...mapGetters('element', ['elementName']),
    ...mapGetters('work', ['parseWorklog', 'workStateOfEvent']),
    ...mapGetters('work', {
      workEventGetter: 'workEvent',
      workStateGetter: 'workState',
    }),
    workState() {
      const { eventId } = this.currentState;
      return this.workStateOfEvent(eventId);
    },
    rootState() {
      if (this.currentState.rootWorkLog) {
        const { eventId } = this.currentState.rootWorkLog;
        return this.workStateOfEvent(eventId);
      }
      return null;
    },
    eventState() {
      const { currentState } = this;
      if (currentState.rootWorkLog) return this.rootState;
      return this.workState;
    },
    workEvent() {
      const { currentState } = this;
      if (!currentState) return null;
      return this.workEventGetter(this.currentState.eventId, this.elementId);
    },
    rootEvent() {
      const state = this.currentState;
      if (!state.rootWorkLog || !state.rootWorkLog.eventId) return null;
      const { eventId, elementId } = state.rootWorkLog;

      return this.workEventGetter(eventId, elementId);
    },
    rootMachine() {
      const { currentState } = this;
      if (!currentState || currentState.isForced || !currentState.rootWorkLog) return '';
      return this.elementName(currentState.rootWorkLog.elementId);
    },
    workEventDescription() {
      const { currentState } = this;
      if (!currentState) {
        return this.$t('general.reasonUnknown');
      }
      if (!this.workEvent) return '';
      return this.workEvent.isDefault ? '' : this.workEvent.name;
    },
    rootWorkEventDescription() {
      const { currentState } = this;
      if (!currentState) {
        return this.$t('general.reasonUnknown');
      }
      if (!this.workEvent) return '';
      return this.rootEvent.name;
    },
  },
  methods: {
    CalcDuration,
    colors,
    updateNow() {
      this.now = this.$now;
    },
  },
  created() {
    this.now = this.$now;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .state-info {
    text-align: center;
  }

  .state-duration {
    font-weight: bold;
    font-size: 0.6em;
    padding-bottom: 3px;
  }

  .work-event {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  .state {
    font-size: 11px;
  }

  .reason-comment {
    font-size: 11px;
    line-height: 1.1;
    color: rgba(100, 100, 100, 0.9);
  }

</style>
