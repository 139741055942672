import Vapi from 'vuex-rest-api';
import { auth as axios } from '@/api/axios';

const store = new Vapi({
  axios,
}).getStore();

store.getters = {
  user: (s, g, rs, rg) => rg['oidcStore/oidcUser'],
  tenantId: (s, g, rs, rg) => rg['oidcStore/oidcUser'].TenantId,
  // remove legacy isAdmin(g.user) when all admins get "PlantAdmin" claims
  email: (s, g) => (g.user ? g.user.name : ''),
  displayName: (s, g) => (g.user ? g.user.DisplayName : ''),
  hasElementAccess: (s, g) => elementId => g.isAdmin || g.elementAccess[elementId.toLowerCase()],
  elementAccess: (s, g) => g.claimElementTree('ElementAccess'),
  claimElementTree: (s, g, rs, rg) => name => {
    const key = Object.keys(g.user).find(x => x.toLowerCase() === name.toLowerCase());
    if (!key) return {};
    const claims = Array.isArray(g.user[key]) || !g.user[key]
      ? g.user[key] : [g.user[key]];
    const structure = rg['plant/structure'];

    return (claims || [])
      .flatMap(x => structure.allLowerChildren(x))
      .reduce((acc, curr) => {
        acc[curr.toLowerCase()] = true;
        return acc;
      }, {});
  },
};

export default store;
