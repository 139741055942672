<template>
  <div
    v-if="!isTablet"
    class="sidebar"
  >
    <RouterLink
      v-for="r in permittedRoutes"
      :key="r.key"
      class="route d-flex flex-column justify-content-center"
      :class="{ selected: r.key === page }"
      :to="r.route"
      @click="select(r)"
    >
      <div class="icon">
        <i :class="r.icon" />
      </div>
      <div class="text">
        {{ r.text }}
      </div>
    </RouterLink>
  </div>

  <div
    v-else
    class="page-buttons d-flex justify-content-between align-items-center"
  >
    <RouterLink
      v-for="r in permittedRoutes"
      :key="r.key"
      :class="{ selected: r.key === page }"
      class="page-btn"
      :to="r.route"
    >
      <i :class="r.icon" />
    </RouterLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    page: String,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters(['permittedRoutes', 'isTablet']),
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .sidebar {
    width: 80px;
    background-color: rgba(150, 150, 150, 0.2);
    margin-top: -60px;
    padding-top: 50px;
    padding-left: 3px;
    padding-right: 3px;
    min-height: 100vh;
    position: fixed;
    z-index: 100;
  }

  .route {
    text-align:  center;
    margin: 12px 0;
    cursor: pointer;
    width: 70px;
    height: 70px;
    color: rgba(#333, 0.7);
    border-radius: 50%;

    .text {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      padding-top: 2px;
    }

    .icon {
      font-size: 27px;
      line-height: 1.1;
      color: rgb(88, 88, 88);
      transition: transform 200ms;
    }

    &:hover, &.selected {
      background-color: rgba(#333, 0.1);
      text-decoration: none;
      color: #333;
    }

    &:hover .icon {
      transform: scale(1.2);
    }
  }

  .page-buttons {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 0px 2px 1px rgba(150, 150, 150, 0.2);
    background: white;
    padding: 0 10px env(safe-area-inset-bottom);

    .page-btn {
      width: 70px;
      padding: 10px 0px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #333;

      &.selected {
        color: $ilabo;
        text-shadow: rgba(100,100,100,0.1);
      }
      &:active {
        background-color: rgba(100,100,100,0.6);
      }
    }
    i {
      font-size: 26px;
    }
    .btn-name {
      line-height: 1.1;
      font-size: 9px;
      display: block;
    }

  }

  @media (max-width: 500px) {
    .page-buttons i {
      font-size: 22px;
    }
  }
</style>
