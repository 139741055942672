export default {
  'moveHereToGroup': 'Di chuyển cột vào đây để nhóm',
  'moveHereToHide': 'Di chuyển cột tại đây để ẩn',
  'sEmptyTable': 'Không có dữ liệu sẵn dùng trong bảng',
  'sInfoFiltered': '(được lọc từ _MAX_ tổng số mục)',
  'sLoadingRecords': 'Đang tải...',
  'sProcessing': 'Đang xử lý...',
  'sSearch': 'Tìm kiếm:',
  'sZeroRecords': 'Không tìm thấy hồ sơ phù hợp',
  'oPaginate': {
    'sFirst': 'Đầu tiên',
    'sLast': 'Cuối cùng',
    'sNext': 'Tiếp theo',
    'sPrevious': 'Trước'
  },
  'oAria': {
    'sSortAscending': ': kích hoạt để sắp xếp cột tăng dần',
    'sSortDescending': ': kích hoạt để sắp xếp cột giảm dần'
  },
  'downloadAsXls': 'Tải xuống dưới dạng xls'
};
