<template>
  <div>
    <div class="text-uppercase font-weight-bold select-title">
      {{ $t('monitoring.targetProduction') }}
    </div>
    <BButtonGroup size="sm">
      <BButton
        :variant="!plannedFromOrders ? 'ilabo' : 'outline-ilabo'"
        class="text-nowrap"
        @click="toggle(false)"
      >
        {{ $t('monitoring.maxPerfProduction') }}
      </BButton>
      <BButton
        :variant="plannedFromOrders ? 'ilabo' : 'outline-ilabo'"
        class="text-nowrap"
        @click="toggle(true)"
      >
        {{ $t('monitoring.plannedFromOrders') }}
      </BButton>
    </BButtonGroup>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    plannedFromOrders: Boolean,
  },
  methods: {
    toggle(v) {
      this.$store.commit('setProductionFromOrders', v);
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-title {
    font-size: 10px;
    margin-bottom: 2px;
  }
</style>
