/* eslint-disable max-len */
import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';
import activities from './activities';
import downtimes from './downtimes';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,

  'world': 'Wereld',
  'plant': 'Fabriek',
  'area': 'Gebied',
  'areas': 'Gebieden',
  'line': 'Lijn',
  'lines': 'Lijnen',
  'delete': 'Verwijderen',
  'segment': 'Segment',
  'machine': 'Machine',
  'machines': 'Machines',
  'element': 'Onderdeel',
  'map': 'Kaart',
  'issue': 'Probleem',
  'typeOfIssue': 'Soort probleem',
  'currentShift': 'Huidige dienst',
  'noShift': 'Geen dienst',
  'currentOrder': 'Huidige order',
  'today': 'Vandaag',
  'shift': 'Dienst',
  'shifts': 'Diensten',
  'noShifts': 'Geen dienst toegewezen',
  'asset': {
    'globalCounter': 'Globale teller',
    'localCounter': 'Locale teller',
    'waterCounter': 'Water teller',
    'valvePosition': 'Klep positie',
    'waterLevel': 'Water niveau',
    'waterFlow': 'Water stroming',
    'pumpBalance': 'Pomp balans'
  },
  'wall': {
    'comment': 'Opmerking',
    'comments': 'Opmerkingen',
    'vision': 'Beeld',
    'downtimeEditorSettings': 'Stilstand editor instellingen'
  },
  'menu': {
    'monitoring': 'Toezicht houden',
    'reports': 'Rapporten',
    'overview': 'Samenvatting',
    'andon': 'Andon',
    'weeklyAnalysis': 'Weekelijkse analyse',
    'scoreAnalysis': 'Score analyse',
    'settings': 'Instellingen',
    'userSettings': 'Account instellingen',
    'notifications': 'Notificaties',
    'language': 'Taal',
    'user': 'Gebruiker',
    'setAsHome': 'Stel in als thuispagina',
    'setFullScreen': 'Gebruik volledig scherm',
    'logout': 'Uitloggen',
    'loadMore': 'Meer laden',
    'selectPlant': 'Selecteer een fabriek',
    'homePageDevice': {
      'title': 'Thuispagina voor dit apparaat',
      'description': 'Dit scherm wordt altijd weergegeven op dit apparaat na inloggen'
    },
    'homePageUser': {
      'title': 'Thuispagina voor dit account',
      'description': 'Dit scherm wordt weergegeven na het inloggen als het apparaat geen thuispagina heeft'
    },
    'activities': 'Activiteiten',
    'login': 'Login',
    'preview': 'Pre-view',
    'lineGraph': 'Lijn grafiek',
    'machineGraph': 'Machine grafiek',
    'version': 'Versie'
  },
  'import': {
    'upload': 'Plaats bestanden hier om the uploaden',
    'notSaved': 'Niet opgeslagen',
    'saved': 'Opgeslagen',
    'actionRequired': 'Actie nodig',
    'checked': 'geverifieerd',
    'fillAndAdd': 'Vul in en voeg toe',
    'chooseImage': 'Kies een afbeelding',
    'nothingImported': 'Geen geldige data om in te vullen',
    'previousImports': 'Vorige geimporteerde bestanden',
    'fileIsBeingProcessed': 'Het bestand wordt verwerkt. We sturen je een notificatie als het klaar is',
    'groupSuccessfullyAssign': 'Met succes toegevoegd aan groep',
    'groupFailedToAssign': 'Niet gelukt om toe te voegen aan groep',
    'groupSuccessfullyRemoved': 'Met succes verwijderd van groep',
    'groupFailedToRemove': 'Niet gelukt om te verwijderen van groep',
    'noChanges': 'Geen wijzigingen',
    'dataSaved': 'Data succesvol opgeslagen',
    'multiplierIsInvalid': 'Vermenigvuldiger is in strijd met reeds gedefinieerde conversie',
    'multiplierIsLessOrEqualZero': 'Vermenigvuldiger kan niet kleiner of gelijk aan nul zijn',
    'seeTemplates': 'Zie templates',
    'fileReview': 'Bestand beoordelen',
    'templates': 'Templates',
    'addTemplate': 'Template toevoegen',
    'editTemplate': 'Template wijzigen',
    'import': 'Importeren',
    'uploadFile': 'Upload bestand',
    'template': 'Template',
    'toUploadFilePickTemplate': 'Kies een template om te uploaden',
    'result': 'Resultaat',
    'testFile': 'Test bestand',
    'toSeeResultUploadTestFile': 'Om het resultaat te zien upload een test bestand',
    'toTestFileSaveChanges': 'Om het bestand te testen, sla het bestand op',
    'visible': 'Zichtbaar',
    'saveFunction': 'Sla functie op',
    'key': 'Sleutel',
    'value': 'Waarde',
    'oldValue': 'Oude waarde',
    'newValue': 'Nieuwe waarde',
    'unchanged': 'Ongewijzigd',
    'status': {
      'applied': 'Geslaagd',
      'appliedWithErrors': 'Toegepast met fouten',
      'scheduled': 'Gepland',
      'faulted': 'Mislukt',
      'processing': 'Verwerken'
    }
  },
  'work': {
    'event': 'Probleem',
    'events': 'Problemen',
    'state': 'Status',
    'states': 'Statussen',
    'systemStates': 'Systeem status',
    'reason': 'Reden',
    'reasons': 'Redenen',
    'correctiveAction': 'Corrigerende actie',
    'correctiveActions': 'Corrigerende acties',
    'downtime': 'Stilstand',
    'downtimes': 'Stilstanden',
    'wiki': 'Wiki artikelen',
    'disconnected': 'Niet verbonden',
    'rootCause': 'Oorzaak',
    'detachRootCause': 'Scheiden oorzaak',
    'rootMachine': 'Oorzaak machine',
    'spectrum': 'Werk spectrum',
    'downtimeList': 'Stilstand lijst',
    'default': 'Standaard',
    'tags': 'Labels',
    'addTags': 'Voeg labels toe',
    'addSelected': 'Voeg geselecteerde toe',
    'plannedDowntimes': 'Geplande stilstand',
    'unplannedDowntimes': 'Ongeplande stilstand',
    'workTime': 'Werktijd',
    'actionTaken': 'Corrigerende acties uitgevoerd',
    'noOngoingOrder': 'Geen order bezig',
    'noOngoingShift': 'Geen lopende dienst',
    'updateQueued': 'Stilstand update in wachtrij. Dit kan enkele seconden duren',
    'overrideQueued': 'Stilstand toevoeging in wachtrij. Dit kan enkele seconden duren',
    'noForcedState': 'Geen problemen gedefinieerd met tag \'Forced\'. Voeg met deze tag een stilstand-definitie toe om machine of lijn te stoppen',
    'downtimeSince': 'Stilstand vanaf',
    'lasting': 'Aanhoudend'
  },
  'general': {
    'name': 'Naam',
    'from': 'Uit',
    'search': 'Zoek',
    'max': 'Maximaal',
    'min': 'Minimaal',
    'noResults': 'Geen resultaten',
    'title': 'Titel',
    'description': 'Beschrijving',
    'fromStart': 'Vanaf het begin',
    'confirm': 'Bevestigen',
    'tryAgain': 'Probeer opnieuw',
    'to': 'Tot',
    'toEnd': 'Tot het einde',
    'finish': 'Beëindigen',
    'add': 'Toevoegen',
    'all': 'Alles',
    'clear': 'Leegmaken',
    'hidden': 'Verstopt',
    'remove': 'Verwijder',
    'detach': 'Losmaken',
    'attach': 'Toevoegen',
    'close': 'Sluiten',
    'cancel': 'Annuleren',
    'edit': 'Bewerken',
    'event': 'Gebeurtenis',
    'details': 'Details',
    'summary': 'Samenvatting',
    'save': 'Opslaan',
    'change': 'Veranderen',
    'noData': 'Geen gegevens',
    'nothingDefined': 'Niks gedefinieerd',
    'others': 'Andere',
    'back': 'Terug',
    'results': 'Resultaten',
    'selected': 'Geselecteerd',
    'selectAll': 'Selecteer alles',
    'createNew': 'Maak nieuwe',
    'useExisting': 'Gebruik bestaande',
    'select': 'Selecteer',
    'saveSuccessed': 'Opslaan gelukt',
    'saveFailed': 'Opslaan mislukt',
    'shouldArchive': 'Moet archiveren',
    'areYouSure': 'Weet je het zeker?',
    'view': 'Weergeven',
    'yes': 'Ja',
    'no': 'Nee',
    'reason': 'Reden',
    'actions': 'Acties',
    'reasonUnknown': 'Reden onbekend',
    'forcedByOperator': 'Geforceerd door operator',
    'type': 'Type',
    'valueType': 'Waarde type',
    'value': 'Waarde',
    'unit': 'Eenheid',
    'disable': 'Uitzetten',
    'disabled': 'Uitgezet',
    'disabledShe': 'Uitgezet',
    'inactive': 'Inactief',
    'enable': 'Aanzetten',
    'enabled': 'Aangezet',
    'enabledShe': 'Aangezet',
    'ignore': 'Negeren',
    'nominal': 'Nominaal',
    'unsavedChanges': 'Weet je zeker dat je weg wil? Niet alle wijzigingen zijn opgeslagen!',
    'now': 'Nu',
    'showAll': 'Laat alles zien',
    'showMore': 'Laat meer zien',
    'continue': 'Verder',
    'group': 'Groep',
    'download': 'Download',
    'dir': {'top': 'Boven', 'bottom': 'Onder', 'left': 'Links', 'right': 'Rechts'},
    'sourceURI': 'Bron URI',
    'dimensions': 'Dimenties',
    'status': 'Status',
    'code': 'Code',
    'start': 'Start',
    'end': 'Eind',
    'update': 'Update',
    'deviceId': 'Apparaat ID',
    'time': 'Tijd',
    'show': 'Weergeven',
    'hide': 'Verbergen',
    'last': 'Laatste',
    'deleted': 'Verwijderd',
    'errors': 'Fouten',
    'inherited': 'geërfd',
    'refresh': 'Vernieuwen',
    'lastRefresh': 'Laatste vernieuwing: {0} geleden',
    'reset': 'Reset',
    'collapseDetails': 'Details samenvouwen',
    'next': 'Volgende',
    'allSelected': 'Selecteer alles',
    'unchecked': 'niet aangevinkt',
    'manHours': 'Manuren',
    'cancelled': 'Geannuleerd',
    'progress': 'Voortgang',
    'dates': 'Data',
    'pickDate': 'Kies een datum',
    'requestReceived': 'Verzoek ontvangen',
    'filters': 'Filters',
    'total': 'Totaal',
    'restoreDefaults': 'Standaardinstellingen herstellen',
    'setDate': 'Datum instellen',
    'setTime': 'Tijd instellen',
    'line': 'Lijn',
    'option': 'Optie',
    'default': 'Standaard',
    'set': 'Instellen',
    'apiUrls': 'API adres',
    'done': 'Gereed',
    'filterBy': 'Filter op',
    'apply': 'Toepassen',
    'clearAll': 'Wis alles'
  },
  'machineControl': {
    'turnOn': 'Aanzetten',
    'turnOff': 'Uitzetten',
    'current': 'Huidige',
    'liveStream': 'Live Beeld',
    'disconnectedWarning': 'WAARSCHUWING! Machine is momenteel niet verbonden - Bij uitzetten gaan de gebufferde gegevens verloren',
    'startMachine': 'Machine starten <b>{machine}</b>?'
  },
  'lineControl': {'startLine': 'Start lijn <b>{line}</b>?', 'stop': 'Stop', 'start': 'Start'},
  'time': {
    'week': 'Week',
    'minutes': 'Minuten',
    'date': 'Datum',
    'day': 'Dag',
    'hour': 'Uur',
    'hours': 'Uren',
    'hoursPlural': 'Uren',
    'days': 'Dagen',
    'month': 'Maand',
    'year': 'Jaar',
    'quarter': 'Kwartaal',
    'resolution': 'Resolutie',
    'duration': 'Duur',
    'seconds': 'Seconden',
    'startDate': 'Start datum',
    'endDate': 'Eind datum',
    'startTime': 'Start tijd',
    'endTime': 'Eind tijd',
    'selectDay': 'Selecteer dag',
    'longerThan': 'Langer dan',
    'shorterThan': 'Korter dan',
    'scope': 'Tijd domein',
    'currentOrder': 'Huidige order',
    'showMinorStops': 'Geef kleine stilstanden weer',
    'plannedOnly': 'Geef geplande stilstanden weer',
    'unplannedOnly': 'Geef ongeplande stilstanden weer',
    'excludeDefaults': 'Verberg standaardinstellingen',
    'monday': 'Maandag',
    'tuesday': 'Dinsdag',
    'wednesday': 'Woensdag',
    'thursday': 'Donderdag',
    'friday': 'Vrijdag',
    'saturday': 'Zaterdag',
    'sunday': 'Zondag',
    'performanceOnly': 'Prestatieverliezen',
    'availabilityOnly': 'Beschikbaarheidsverliezen',
    'utilizationOnly': 'Gebruiksverliezen',
    'plannedDate': 'Geplande datum',
    'custom': 'Aangepast'
  },
  'graph': {
    'flow': 'Stroom',
    'flows': 'Stromingen',
    'production': 'Productie stroming',
    'physical': 'Fysiek',
    'supplying': 'Leveren',
    'archiveChanges': 'Archiveer veranderingen',
    'isActive': 'Actief',
    'addElement': 'Voeg onderdeel toe',
    'detached': 'Losgemaakt',
    'andon': 'Andon',
    'noActiveFlow': 'Geen actieve stroom'
  },
  'monitoring': {
    'ofThePlan': 'Je hebt %{value}% van deze waarde bereikt',
    'noPlan': 'Geen plan',
    'averageTargetPerformance': 'Gemiddelde doel prestatie',
    'designSpeed': 'Ontwerp prestatie',
    'validatedSpeed': 'Gevalideerde prestatie',
    'targetProduction': 'Productie doel',
    'maxPerfProduction': 'Maximale prestatie',
    'plannedFromOrders': 'Geplande orders',
    'planned': 'Gepland',
    'notInFlow': 'Niet in de productie stroom',
    'produced': 'Geproduceerd',
    'wasted': 'Verspild',
    'lastMessage': 'Laatste bericht',
    'noMessages': 'Geen berichten',
    'noAlarms': 'Geen alarmen',
    'noConnectionInfo': 'Geen informatie over de verbinding',
    'noConnection': 'Geen connectie met de server',
    'processing': 'Verwerken',
    'dataProcessingFromLast': 'Verwerk data van laatste',
    'lineStates': 'Lijn status',
    'disconnected': 'Niet verbonden',
    'serverDisconnected': 'Server niet verbonden',
    'working': 'Bezig',
    'ready': 'Klaar',
    'notWorking': 'In stilstand',
    'productionProgress': 'Productie voortgang',
    'production': 'Productie',
    'productionMap': 'Productiekaart',
    'workStates': 'Werk status',
    'workLog': 'Werk geschiedenis',
    'event': 'Gebeurtenis',
    'events': 'Gebeurtenissen',
    'cause': 'Oorzaak',
    'causes': 'Oorzaken',
    'reasons': 'Redenen',
    'warnings': 'Waarschuwing',
    'correctiveAction': 'Corrigerende acties',
    'stopRealTime': 'Stop',
    'stoppedRealTime': 'Real-time gestopt',
    'realTime': 'Huidige tijd',
    'toRealTime': 'Naar huidige tijd',
    'downtimes': 'Stilstanden',
    'changeCurrentDowntime': 'Verander stilstand',
    'doubleClickToEdit': 'Dubbel klik om stilstand te wijzigen',
    'percentShare': 'Aandeel in percentage',
    'timeShare': 'Deel tijd',
    'minorStops': 'Inclusief kleine stops',
    'remainingPlannedProduction': 'Mogelijk resterende productie op basis van nominale snelheid',
    'remainingEstimatedProduction': 'Mogelijk resterende productie op basis van effectieve snelheid',
    'remainingTime': 'Overgebleven tijd',
    'performance': {
      'short': 'Prestatie van afgelopen {0}',
      'effective': 'Effective prestatie',
      'nominal': 'Nominale prestatie'
    },
    'segment': {
      'baseQuantity': 'Basishoeveelheid',
      'baseQuality': 'Basiskwaliteit',
      'baseAvailability': 'Basisbeschikbaarheid',
      'basePerformance': 'Basisprestaties'
    },
    'machine': {
      'counter': 'Teller',
      'parameters': 'Parameters',
      'alarms': 'Alarmen',
      'processParameters': 'Procesparameters',
      'currentParameters': 'Huidige parameters',
      'activeAlarms': 'Active alarmen',
      'counters': 'Tellers',
      'alarmsHoldups': 'Alarmen en ophoudingen',
      'performanceInfo': 'Gemiddelde prestatie van afgelopen {0}',
      'others': 'Andere',
      'lifespan': 'Levensduur',
      'environmentCondition': 'Omgevings condities',
      'condition': 'Machine conditie',
      'cost': 'Kosten',
      'failurePreditcion': 'Storing voorspelling',
      'probabilityFailure': 'Kans op storing binnen 10 dagen',
      'failureType': 'Storing type',
      'timeToFailure': 'Voorspelde tijd tot storing',
      'predictedFailure': 'Voorpelde storing',
      'maintenanceHistory': 'Onderhoud geschiedenis'
    },
    'lineInfo': {
      'advices': 'Hints',
      'forService': 'Taken',
      'jobs': 'Taak',
      'completed': 'Taak volbracht',
      'problems': 'Probleem',
      'planned': 'Taken in wachtrij',
      'toAchieveTarget': 'Verwacht productie volume op dit moment',
      'confirmLinePause': 'Bevestig pauze op de lijn'
    },
    'worstAlarms': 'Grootste problemen',
    'problems': 'Geen problemen | een probleem | {0} problemen',
    'performanceSettingHistory': 'Geschiedenis van prestatie-instellingen',
    'unitConversionsHistory': 'Geschiedenis van eenheidsconversies',
    'reportParam': 'Parametrisering rapporteren',
    'performanceType': 'Prestatietype',
    'goodCount': 'Goed product teller',
    'wasteCount': 'Afval teller',
    'overdue': '{0} te laat',
    'left': '{0} over'
  },
  'components': {
    'title': 'Componenten',
    'one': 'Component',
    'usagePerUnit': 'Verbruik per eenheid',
    'required': 'Benodigd',
    'convertUnit': 'Zet eenheid om',
    'validation': 'Validatie nodig',
    'batchTracking': 'Batch volgen',
    'scanTrackingId': 'Scan volgnummer',
    'batchTrackingHelp': 'Als volgen is ingeschakeld, moet je het \\n batch identificatie nummer voor elk gescand pakket aanleveren',
    'defaultPackageQuantity': 'In het pakket',
    'warningLevelPercentage': 'Waarschuwings percentage',
    'warningLevel': 'Waarschuwings niveau',
    'warningLevelHelp': '\\n % - Waarschuwing als een bepaald percentage pakketen wordt verbruikt \\n\\n minuten - waarschuwing als het voorspelde aantal minuten overblijft\\n',
    'scannerCode': 'Scannercode',
    'scannerCodeType': 'Code type',
    'noTrackingId': 'Geen volg ID',
    'scan': 'Scannen',
    'packages': 'Dozen',
    'scanPackages': 'Scan dozen',
    'noComponents': 'Geen componenten toegewezen',
    'invalidCode': 'Componenten niet gevonden',
    'addComponent': 'Voeg component toe',
    'closed': 'Gesloten',
    'scanned': 'Gescand',
    'closeConfirm': 'Sluiten',
    'eta': 'ETA',
    'active': 'Actief',
    'clickToActivate': 'Klik om te activeren',
    'used': 'Verbruikt',
    'estimatedTotalQuantity': 'Verwachte benodigde hoeveelheid',
    'packageScannerCode': 'Pakket code',
    'defaultPackageQuantitySum': 'Totale hoeveelheid in dozen',
    'registeredDate': 'Scan datum',
    'trackingId': 'Volg ID',
    'consumptionType': {
      'title': 'Verbruik type',
      'help': '\\n \'MEERDERE\' staat meerdere geopende dozen in een keer toe, de eerste is verbruikt. \\n\\n \'EENTJE\' sluit automatisch de vorige doos als een nieuwe is geactiveerd, de laatste is verbruikt\\n'
    }
  },
  'oee': {
    'oee': 'OEE',
    'oeeLoss': 'OEE verlies',
    'availability': 'Beschikbaarheid',
    'performance': 'Prestatie',
    'quality': 'Kwaliteit',
    'oeePercentage': 'OEE [%]',
    'availabilityPercentage': 'Beschikbaarheid [%]',
    'performancePercentage': 'Prestatie [%]',
    'teep': 'TEEP',
  },
  'metrics': {
    'mtbf': 'Gemiddelde tijd tussen twee storingen',
    'mttr': 'Gemiddelde reparatie tijd',
    'mtbm': 'Gemiddelde tijd tussen onderhoud',
    'reliabilityMetrics': 'Betrouwbaarheidsstatistieken'
  },
  'energy': {
    'usedEnergy': 'Energie verbruikt',
    'currentLeastEfficient': 'Momenteel minst efficiente machine',
    'currentPower': 'Huidig vermogen',
    'baseline': 'Basis',
    'max': 'Gemiddelde max'
  },
  'items': {
    'items': 'Artikelen',
    'import': 'Importeer artikelen',
    'itemGroups': 'Artikel groepen',
    'itemGroup': 'Artikel groep',
    'groups': 'Groepen',
    'add': 'Voeg artikel toe',
    'addGroup': 'Voeg groep toe',
    'new': 'Nieuw artikel',
    'edit': 'Pas artikel aan',
    'number': 'Nummer',
    'description': 'Beschrijving',
    'quantity': 'Hoeveelheid/pallet',
    'unit': 'Hoeveelheid eenheid',
    'performance': 'Prestatie',
    'expectedPerformance': 'Verwachte prestatie',
    'expectedChangeover': 'Verwachte prestatie',
    'packingStructure': 'Verpakking structuur',
    'groupPackingStructure': 'Groep verpakking structuur',
    'itemRoutingSpeed': 'Artikel routing snelheid',
    'groupRoutingSpeed': 'Groep routing snelheden',
    'components': 'Onderdelen',
    'item': 'Artikel',
    'inheritedFromGroup': 'Geërfd van groep',
    'lineConfiguration': 'Lijn configuratie',
    'noLineConfigured': 'Geen producten geconfigureerd voor deze lijn'
  },
  'pageNotFoundExplanation': 'We kunnen deze pagina niet vinden,\\n onze excuses voor het ongemak',
  'processParams': {
    'compositionName': 'Compositie naam',
    'compositions': 'Composities',
    'saveComposition': 'Compositie opslaan',
    'signals': 'Signalen',
    'signalName': 'Signaalnaam',
    'signalValue': 'Signaalwaarde',
    'ongoingState': 'Huidige status',
    'ongoingProblem': 'Huidig probleem',
    'ongoingOrder': 'Huidige order',
    'problemStart': 'Start probleem',
    'problemEnd': 'Einde probleem',
    'orderStart': 'Order start',
    'orderEnd': 'Order einde',
    'orderProduction': 'Order productie'
  },
  'healthcheck': {'noDriverConnection': 'Geen motor connectie'},
  'vision': {
    'vision': 'Beeld',
    'showAllClips': 'Toon alle clips',
    'selectCamera': 'Selecteer camera',
    'getClip': 'Verkrijg clip',
    'getTheTwoPreviousMinutes': 'Verkrijg 2 minuten voor en 2 minuten na de start van de stilstand',
    'noClips': 'Geen clips gevonden',
    'noRecords': 'De camera heeft geen opnames van die dag om te downloaden',
    'currentVideoTime': 'Huidige video tijd',
    'startVideoTime': 'Start video tijd',
    'startDowntime': 'Start stilstand',
    'endDowntime': 'Beëindig stilstand',
    'showLiveVideo': 'Toon live beelden van de camera',
    'selectStartClip': 'Selecteer begin video',
    'selectEndClip': 'Selecteer einde video',
    'videoDurationIs': 'Video speelduur is',
    'invalidTimeRange': 'Ongeldige tijdspan',
    'toMoreBefore': 'Kies een eerdere begin datum voor de video',
    'toMoreAfter': 'Kies een latere einddatum voor de video',
    'maxVideoIs': 'Maximale video lengte is',
    'auto': 'A',
    'autoLong': 'Video automatisch aangevraagd door systeem',
    'manual': 'M',
    'manualLong': 'Video handmatig aangevraagd door gebruiker',
    'videoTrigger': 'Zet automatische video upload aan',
    'autoDownload': 'Automatisch video upload',
    'watchVideo': 'Bekijk video'
  },
  'downloadStatus': {
    'failed': 'Mislukt',
    'inProgress': 'In uitvoering',
    'success': 'Klaar voor gebruik',
    'failedOnEdge': 'Camera mislukt',
    'noArchiveRecordings': 'Camera heeft geen opnames',
    'deleted': 'Verwijderd'
  },
  'quests': 'Taken',
  'quest': {
    'quest': 'Taak',
    'questTitle': 'Taak titel',
    'questDescription': 'Taakomschrijving',
    'project': 'Project',
    'type': 'Taak type',
    'state': 'Taak status',
    'closeToState': 'Afsluiten naar status',
    'assignToGroups': 'Toekennen aan groepen',
    'taskDetails': 'Taak details',
    'files': 'Bestanden',
    'comments': 'Opmerkingen',
    'noFiles': 'geen bestanden'
  },
  'health': {'health': 'Gezondheidstoestand', 'healthHistory': 'Gezondheidstoestand historie'},
  'panel': {
    'number': 'Nummer',
    'sku': 'SKU',
    'selectState': 'Selecteer status',
    'selectMachine': 'Selecteer machine',
    'comment': 'Opmerking',
    'downtimeFrom': 'Stilstand vanaf',
    'ongoing': 'Lopende',
    'averagePerformance': 'Gemiddelde prestatie',
    'currentPerformance': 'Huidige prestatie',
    'currentLineStatus': 'Huidige lijnstatus',
    'provideReasonAndComment': 'Voeg reden en commentaar toe',
    'commentPlaceholder': 'Schrijf uw commentaar',
    'confirmData': 'Bevestig gegevens',
    'inputPlaceholder': 'Schrijf hier'
  },
  'workCalendar': 'Werk kalender',
  'schedule': 'Planning',
  'importSchedule': 'Importeer planning',
  'addSchedule': 'Planning toevoegen',
  'repeatEvery': 'Herhaal iedere',
  'repeatOn': 'Herhaal op',
  'ends': 'Eindigt',
  'starts': 'Start',
  'never': 'Nooit',
  'on': 'Aan',
  'cycle': 'Cyclus',
  'deleteRecurringInstance': 'Verwijder huidig geval',
  'editRecurringInstance': 'Pas huidig geval aan',
  'thisInstance': 'Dit geval',
  'thisAndFollowingInstances': 'Deze en volgende gevallen',
  'allInstances': 'Alle gevallen',
  'order': {
    'addNewProperty': 'Nieuwe eigenschap toevoegen',
    'sortByDate': 'Sorteren op datum',
    'sortBy': 'Sorteer op',
    'ascending': 'oplopend',
    'descending': 'aflopend',
    'quantityProduced': 'Hoeveelheid geproduceerd',
    'plannedProduction': 'Geplande productie',
    'endDate': 'Einddatum',
    'activatedDate': 'Activatie datum',
    'totalTime': 'Duur van de order',
    'plannedDate': 'Begin datum',
    'eta': 'Verwachte eindtijd',
    'delete': 'Filter wissen'
  },
  'noCycle': 'Geen cyclus definitie',
  'users': {'firstName': 'Voornaam', 'lastName': 'Achternaam'},
  'teams': {'teamName': 'Team naam'},
  'schedules': {'addLine': 'Voeg lijn toe aan filters', 'clearLines': 'Schoon alle lijnen op'},
  'chart': {'weekSymbol': 'W'},
  'currentWeek': 'Huidige week',
  'factoryStructure': 'Fabrieks structuur',
  'production': {'rawCounterIncreases': 'Ruwe tellerstanden in deze periode'},
  'calendar': {'period': 'Periode'},
  'taxonomies': {
    'taxonomy': 'Groep',
    'classification': 'Classificatie',
    'selectClassification': 'Selecteer classificatie',
    'selectClassificationLevel': 'Selecteer classificatieniveau',
    'selectClassificationNodes': 'Selecteer classificatieknooppunten',
    'defaultTaxonomy': 'Standaard',
    'pickCategory': 'Kies categorie',
    'selectTaxonomy': 'Selecteer groep',
    'selectTaxonomyLevel': 'Selecteer groep-level'
  },
  'menu_logoutQuestion': 'Wil je uitloggen?'
};
