<template>
  <div
    id="app"
    class="app"
  >
    <div class="top-bar" />
    <RouterView />
    <div
      v-if="pageLoading"
      class="overlay d-flex align-items-center justify-content-center"
    >
      <Loader />
    </div>
    <ScreenMeasure
      @screenWidth="saveScreenWidth"
      @screenHeight="saveScreenHeight"
    />

    <PortalTarget name="offcanvas" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ScreenMeasure from '@core/utils/screenMeasure';
import loopRequest from '@/mixins/loopRequest';

export default {
  name: 'App',
  components: {
    ScreenMeasure,
  },
  mixins: [loopRequest('loop', 60000 * 10)],
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
    ...mapGetters(['pageLoading']),
  },
  watch: {
    oidcIsAuthenticated() {
      this.init();
    },
  },
  methods: {
    ...mapActions(['getPlants']),
    async init() {
      if (!this.oidcIsAuthenticated) return;

      await this.getPlants();

      this.setRefreshTime();
      const config = this.$store.dispatch('core/initUserConfig', 'packos');
      const perm = this.$store.dispatch('core/initUserPermissions', ['packos', 'config']);

      Promise.all([config, perm])
        .finally(() => {
          this.$store.commit('setReady', true);
        });
    },
    loop() {
      this.$store.commit('updateHourNow');
    },
    saveScreenWidth(width) {
      this.$store.commit('setScreenWidth', width);
    },
    saveScreenHeight(height) {
      this.$store.commit('setScreenHeight', height);
    },
    setRefreshTime() {
      const refreshDate = moment().set({ hour: 0, minute: 0, second: 1 }).add(1, 'days');
      const delay = refreshDate.diff(moment());
      setTimeout(() => this.$router.go(), delay);
    },
  },
  created() {
    this.$store.commit('setReady', false);
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

#app {
  height: 100vh;
  max-width: 100vw;
}

.top-bar {
  height: env(safe-area-inset-top);
  background-color: $ilabo;
}

.app-loading {
  text-align: center;
  padding-top: 200px;
}
</style>
