import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    assetHealth: [
      {
        value: 100,
        timestamp: Math.floor((Date.now() - 604800000) / 1000),
      },
      {
        value: 100,
        timestamp: Math.floor(Date.now() / 1000),
      },
    ],
    currentHealth: 100,
    lifespan: 100,
    environmentCondition: 100,
    assetCondition: 100,
    cost: 0,
    probabilityFailure: 0,
    failureType: '-',
    timeToFailure: '1 month 20 days',
    predictedFailure: Math.floor(Date.now() + 2629743000 + 1728000000),
  },

})
  .getStore();

export default store;
