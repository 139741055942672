import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    issues: [],
  },
})
  .get({
    action: 'getPerformanceSettingsHistory',
    path: ({ plantId }) => `v2/plant/${plantId}/performance-settings/history`,
  })
  .get({
    action: 'getPackingStructureHistory',
    path: ({ plantId }) => `v2/plant/${plantId}/packing-structure/history`,
  })
  .getStore();

export default store;
