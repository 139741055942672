<template>
  <Header
    system="packos"
    :auth-url="authUrl"
    :hub-url="hubUrl"
    :color="$color('packos')"
    :user-dropdown="userDropdown"
    :notification-action="notificationAction"
    :calendar-view="isCalendarView"
    @modules="$store.commit('setModules', $event)"
  >
    <div class="center-navbar d-flex justify-content-center">
      <PlantTitle
        v-if="!isWorld"
        :show-world="!!plantId"
        style="max-width: 350px"
        @selected="changePlant"
      />
    </div>

    <LModal :show.sync="homeModal">
      <SelectHomeSave
        class="pt-4"
        @hideModal="homeModal = false"
      />
    </LModal>
  </Header>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@core/layout/navbar/Header';
import SelectHomeSave from '@/components/modals/SelectHomeSave';
import getOidcSettings from '@/api/oidc';
import action from '@/components/notifications/action';
import PlantTitle from './PlantTitle';

export default {
  data: () => ({
    authUrl: `${getOidcSettings().authority}/api`,
    homeModal: false,
  }),
  components: {
    Header,
    SelectHomeSave,
    PlantTitle,
  },
  computed: {
    ...mapGetters(['plantId', 'hubUrl']),
    ...mapGetters('plant', ['plantProperty']),
    ...mapGetters('oidcStore', [
      'oidcUser',
      'oidcAccessToken',
    ]),
    userDropdown() {
      return [
        {
          icon: 'fas fa-home',
          text: this.$t('menu.setAsHome'),
          select: () => {
            this.homeModal = true;
          },
        },
      ];
    },
    isWorld() {
      return this.$route.name === 'world';
    },
    isCalendarView() {
      return this.$route.name === 'activities';
    },
  },
  methods: {
    notificationAction(n) {
      return action(n, this.$router);
    },
    changePlant(plantId) {
      const named = this.$route.matched.find(el => el.name);
      if (named) {
        this.$router.push({ name: named.name, params: { plantId } });
      } else {
        this.$router.push(`/${plantId}/dashboard/lines`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .center-navbar {
    left: 0;
    color: white;
    top: 9px;
    position: absolute;
    width: 100%;
  }

  .right-menu {
    position: absolute;
    right: 0;
    top: 0;
  }

  @media screen and (max-width: 768px) {
    .center-navbar {
      padding-left: 0;
      top: 2px;
    }
  }

  @media screen and (min-width: 1000px) {
    .center-navbar {
      padding-left: 80px;
    }
  }
</style>
