export default {
  addDowntime: 'Add downtime',
  addLineDowntime: 'Add line downtime',
  addMachineDowntime: 'Add {name} downtime',
  newDowntime: 'New downtime',
  actionsAndReasons: 'Actions & Reasons',
  performActions: 'Perform following actions:',
  secifyReasonIfKnown: 'Specify a reason',
  downtimelist: 'Downtime reason',
  annotations: 'Annotations',
  categories: 'Tags',
  clearRootCause: 'Clear root cause',
  reason: 'Reason',
  reasons: 'Reasons',
  rootCauseReason: 'Root reason',
  noReasons: 'No reasons defined',
  noCorrectiveActions: 'No corrective actions defined',
  noEvents: 'No events defined',
  noCameras: 'No cameras defined',
  removeReasonWorning: 'Warning! All corrective actions associated with this reason will be removed!',
  addReason: 'As a new reason',
  selectEvent: 'Event / Problem',
  newCorrectiveAction: 'New corrective action',
  newReason: 'New reason',
  correctiveAction: 'Corrective action',
  correctiveActions: 'Corrective actions',
  callForService: 'Call for service',
  serviceHasBeenCalled: 'Service has been called',
  specifyRootcause: 'Root cause',
  lastDowntimes: 'Last downtimes',
  startTime: 'Started',
  endTime: 'Finished',
  externalReason: 'External reason',
  uncategorized: 'Uncategorized',
  noActions: 'No actions to perform',
  timeSlot: 'Time slot',
  selectOtherMachineDowntime: 'Select other machine downtime',
  selectMachine: 'Select machine',
  workEventFilter: 'Events filter',
  deselectAll: 'Deselect all',
  connect: 'Connect',
  commonReason: 'Common reason',
  commonProblem: 'Common problem',
  clearSelection: 'Clear selection',
  noCorrectiveAction: 'No corrective action taken',
  toEditSetCommonWorkEvent: 'To edit set common work event',
  differentCorrectiveActions: 'Different corrective actions',
  differentReasons: 'Different reasons',
  noReason: 'No reason',
  addOrCreateReason: 'Select related reason',
  lineState: 'Line state',
  selectProblemFirst: 'Select problem first',
  setProblem: 'Set problem',
  setReason: 'Set reason',
  setCorrectiveAction: 'Set corrective action',
  summary: 'Summary',
  deleteConfirmMessage: 'Confirm that you want to delete selected downtimes.',
  changeDuration: 'Change duration',
  downtimeUpdated: 'Downtime updated',
  noMachineDowntime: 'Does not apply to machines',
  setAction: 'Set action',
  addAnotherCorrectiveAction: 'Add another corrective action',
  addAnotherReason: 'Add another reason',
  noDetails: 'No details',
  noClipsCameraAvailable: 'No clips requested, camera is available',
  clipsDownloaded: 'Clips requested',
  stopLine: 'Stop line',
  stopLineStep: 'Stop line - step {step}',
};
