export default {
  activities: 'Atividades',
  activityType: 'Tipo de atividade',
  addActivity: 'Planejar nova atividade',
  add: 'Adicionar atividade',
  edit: 'Editar atividade',
  addChangeover: 'Adicionar troca',
  step: 'Etapa',
  order: 'Ordem',
  general: 'Geral',
  quantity: 'Quantidade',
  dates: 'Datas',
  configuration: 'Configuração',
  plannedStart: 'Início planejado',
  plannedEnd: 'Fim planejado',
  cleaning: 'Limpeza',
  changeover: 'Troca',
  activityId: 'ID da Atividade',
  activity: 'Atividade',
  activityStart: 'Início da atividade',
  toStart: 'para começar',
  afterStart: 'após o início',
  activityAlreadyExists: 'ID da Atividade já existe',
  activityIdIsTooLong: 'ID da Atividade é muito longo',
  changeoverDuration: 'Duração da troca (minutos)',
  manage: 'Gerenciar atividades',
  changeoverType: 'Tipo de troca',
  editExisting: 'Editar existente',
  pickALine: 'Escolher uma linha',
  typeEffort: 'Tipo de esforço',
  showNotPlanned: 'Mostrar atividades não planejadas',
  summary: {
    summary: 'Resumo',
    orderTotal: 'Total da ordem',
    lineTotal: 'TOTAL DA LINHA',
    inLineUnit: 'na unidade da linha',
    inOrderUnit: 'na unidade da ordem',
    goodCount: 'CONTAGEM BOA',
    wasteCount: 'CONTAGEM DE RESÍDUOS',
    counterTotal: 'Total do contador',
    performanceGuard: 'Guarda de Desempenho',
    stateGuard: 'Guarda de Estado',
    performanceGuardTippy: 'Limite de desempenho excedido',
    missingPackingStructure: 'Estrutura de embalagem ausente',
    manualReconciliation: 'Reconciliação manual',
  },
  list: {
    order: 'Ordens',
    cleaning: 'Limpezas',
    changeover: 'Trocas',
  },
  activityAdded: 'Atividade adicionada',
  noActivities: 'Sem atividades',
  status: {
    released: 'Liberado',
    draft: 'Rascunho',
    started: 'Iniciado',
    suspended: 'Suspenso',
    completed: 'Concluído',
    activated: 'Ativado',
    scheduled: 'Agendado',
    notPlanned: 'Não planejado',
  },
  schedule: {
    tableView: 'Tabela',
    axisView: 'Linha do tempo',
    full: 'Completo',
    collapsed: 'Colapsado',
    scheduled: 'Agendado',
    currentHour: 'Fim da hora atual',
    previousHour: 'Hora anterior',
    previousShift: 'Turno anterior',
    currentShift: 'Turno atual',
  },
  resources: {
    production: 'Produção',
    waste: 'Desperdício',
    manhours: 'Horas de trabalho',
    timeScope: 'Escopo de tempo',
    workers: 'Trabalhadores',
    worker: 'Trabalhador',
    history: 'Histórico',
    noEntries: 'Sem entradas',
    hours: 'horas',
    minutes: 'minutos',
  },
  actions: {
    finishChangeover: 'Terminar e iniciar ordem',
    abortChangeover: 'Abortar troca',
    productionReconciliation: 'Reconciliação de produção',
    finishOrder: 'Concluir ordem',
  },
  productionReconciliation: {
    increase: 'Aumentar',
    fromTo: 'De - Para',
    rawCounter: 'Contador bruto',
    who: 'Quem',
    lastRefresh: 'Última atualização',
    saveEntry: 'Salvar entrada',
    arithmeticExpressionsInfoBoxText: 'Nos campos de entrada você pode usar expressões aritméticas simples, por exemplo, “100-10”. Depois de clicar em “salvar entrada”, o sistema salvará e calculará o valor.',
    entryWithDifferentUnitError: 'Algumas unidades de entrada diferem da unidade da ordem. Para adicionar uma nova entrada, exclua a entrada com unidade diferente.',
    orderUnit: 'Unidade da ordem',
  },
  validations: {
    orderNotFound: 'Ordem relacionada não encontrada',
    externalIdError: "ID pode conter apenas números, letras, caracteres '-', '/' e '_' e pode começar apenas com um número ou uma letra",
  },
  deleteConfirmMessage: 'Tem certeza de que deseja excluir esta atividade?',
  hasValidationErrors: 'Tem erros de validação',
  noLine: 'Sem linha',
  editOrder: 'Editar ordem',
  editChangeover: 'Editar troca',
  cannotCreateOrder: 'Não é possível criar ordem',
  orderExists: 'Ordem com o ID de Atividade especificado já existe.',
  endOfLastShift: 'Fim do último turno',
  endOfLastHour: 'Fim da última hora',
  productionHistory: 'Histórico de produção',
  manhoursHistory: 'Histórico de horas de trabalho',
  changeoverDurationTitle: 'Duração da troca',
  expectedEffort: 'Esforço esperado (mh)',
  expectedChangeoverEffort: 'Esforço esperado de troca (mh)',
  typeCorrectExpectedEffort: 'O esforço esperado não pode ser negativo',
  typeCorrectValue: 'O valor não pode ser negativo',
  typeChangeoverDurationIfChangeoverExpectedEffortGiven: 'A duração esperada da troca é necessária quando o esforço esperado da troca é fornecido',
  editMode: 'Modo de edição',
  validationErrors: {
    skuError: 'Produto ausente ou configurado incorretamente',
    flowIdError: 'Configuração de fluxo ausente',
    endDateNotValid: 'Data de término planejada não é válida',
    lineError: 'Configuração de linha ausente',
    orderedQuantityError: 'Quantidade ordenada ausente',
    orderedQuantityUnitError: 'Unidade de quantidade ordenada ausente ou configurada incorretamente',
  },
};
