export default {
  'activities': 'Activiteiten',
  'activityType': 'Activiteiten type',
  'addActivity': 'Plan nieuwe activiteit',
  'add': 'Voeg activiteit toe',
  'edit': 'Wijzig activiteit',
  'addChangeover': 'Voeg changeover toe',
  'step': 'Stap',
  'order': 'Order',
  'general': 'Algemeen',
  'quantity': 'Hoeveelheid',
  'dates': 'Data',
  'configuration': 'Configuratie',
  'plannedStart': 'Geplande start',
  'plannedEnd': 'Gepland einde',
  'cleaning': 'Schoonmaak',
  'changeover': 'Changeover',
  'activityId': 'Activiteit-ID',
  'activity': 'Activiteit',
  'activityStart': 'Activiteit start',
  'toStart': 'Starten',
  'afterStart': 'Na starten',
  'activityAlreadyExists': 'Activiteits-ID bestaat al',
  'activityIdIsTooLong': 'Activiteits-ID is te lang',
  'changeoverDuration': 'Changeover tijd (minuten)',
  'manage': 'Beheer activiteiten',
  'changeoverType': 'Type changeover',
  'editExisting': 'Bewerk bestaande',
  'pickALine': 'Kies een lijn',
  'typeEffort': 'Type inspanning',
  'showNotPlanned': 'Toon niet geplande activiteiten',
  'summary': {
    'summary': 'Samenvatting',
    'orderTotal': 'Order totaal',
    'lineTotal': 'LIJN TOTAAL',
    'inLineUnit': 'in lijneenheid',
    'inOrderUnit': 'in order eenheid',
    'goodCount': 'GOED PRODUCT',
    'wasteCount': 'AFVAL',
    'counterTotal': 'Totaal',
    'performanceGuard': 'Prestatie bewaker',
    'stateGuard': 'Status bewaker',
    'performanceGuardTippy': 'Prestatiedrempel overschreden',
    'missingPackingStructure': 'Ontbrekende verpakkingsstructuur',
    'manualReconciliation': 'Handmatige reconciliatie'
  },
  'list': {'order': 'Orders', 'cleaning': 'Schoonmaak', 'changeover': 'Changeovers'},
  'activityAdded': 'Activiteit toegevoegd',
  'noActivities': 'Geen activiteiten',
  'status': {
    'released': 'Vrijgegeven',
    'draft': 'Voorlopige versie',
    'started': 'Gestart',
    'suspended': 'Uitgesteld',
    'completed': 'Voltooid',
    'activated': 'Geactiveerd',
    'scheduled': 'Gepland',
    'notPlanned': 'Niet gepland'
  },
  'schedule': {
    'tableView': 'Tabel',
    'axisView': 'Tijdlijn',
    'full': 'Vol',
    'collapsed': 'Ingestort',
    'scheduled': 'Gepland',
    'currentHour': 'Einde van het huidige uur',
    'previousHour': 'Vorig uur',
    'previousShift': 'Vorige dienst',
    'currentShift': 'Huidige dienst'
  },
  'resources': {
    'production': 'Productie',
    'waste': 'Afval',
    'manhours': 'Manuren',
    'timeScope': 'Tijdsbereik',
    'workers': 'Werknemers',
    'worker': 'Werknemer',
    'history': 'Geschiedenis',
    'noEntries': 'Geen items',
    'hours': 'Uren',
    'minutes': 'Minuten'
  },
  'actions': {
    'finishChangeover': 'Voltooi en start de order',
    'abortChangeover': 'Changeover afbreken',
    'productionReconciliation': 'Productie reconciliatie',
    'finishOrder': 'Rond de order af'
  },
  'validations': {
    'orderNotFound': 'Gerelateerde order niet gevonden',
    'externalIdError': 'ID mag alleen cijfers, letters, tekens \'-\', \'/\' en \'_\' bevatten en mag alleen beginnen met een cijfer of een letter'
  },
  'deleteConfirmMessage': 'Weet u zeker dat u deze activiteit wilt verwijderen?',
  'hasValidationErrors': 'Heeft validatie errors',
  'noLine': 'Geen lijn',
  'editOrder': 'Wijzig order',
  'editChangeover': 'Wijzig changeover',
  'cannotCreateOrder': 'Kan order niet aanmaken',
  'orderExists': 'Order met specifieke activiteit-ID bestaat',
  'endOfLastShift': 'Einde van de laatste dienst',
  'endOfLastHour': 'Einde van het laatste uur',
  'productionHistory': 'Productie geschiedenis',
  'manhoursHistory': 'Manuren geschiedenis',
  'changeoverDurationTitle': 'Changeover duur',
  'expectedEffort': 'Verwachte inspanning (mh)',
  'expectedChangeoverEffort': 'Verwachte changeover inspanning (mh)',
  'typeCorrectExpectedEffort': 'Verwachte inspanning kan niet negatief zijn',
  'typeCorrectValue': 'Waarde kan niet negatief zijn',
  'typeChangeoverDurationIfChangeoverExpectedEffortGiven': 'De verwachte changeover-duur is vereist, wanneer de verwachte changeover inspanning is aangegeven',
  'editMode': 'Bewerkingsmodus',
  'validationErrors': {
    'skuError': 'Product ontbreekt of is verkeerd geconfigureerd',
    'flowIdError': 'Flow configuratie ontbreekt',
    'endDateNotValid': 'Geplande einddatum is niet geldig',
    'lineError': 'Lijnconfiguratie ontbreekt',
    'orderedQuantityError': 'Bestelde order hoeveelheid ontbreekt',
    'orderedQuantityUnitError': 'Bestelde order hoeveelheid ontbreekt of is verkeerd geconfigureerd'
  }
};
