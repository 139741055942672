<template>
  <div class="pt-4 w-100">
    <BTabs
      v-model="tab"
      class="order-status-tabs"
      nav-class="justify-content-center"
      pills
      lazy
    >
      <BTab
        v-if="canEditOrders"
        key="add"
        :title-link-attributes="{
          style: `border: 2px solid ${tab !== 0 ? $color('green') : 'white'};
            background-color: ${tab === 0 ? $color('green') : '#fff'}`
        }"
        @click="editedId = null"
      >
        <template #title>
          <div
            :class="tab === 0 ? 'text-white' : 'text-success'"
          >
            <i class="fas fa-plus pr-2" />
            {{ $t('general.add') }}
          </div>
        </template>

        <BModal
          visible
          hide-header
          hide-footer
          modal-class="modal-fullscreen"
        >
          <ActivityFormModal
            :line="lineId"
            :activity="edited"
            :is-edited="edited ? true : false"
            @close="showRightTab($event); editedId = null;"
            @saved="handleSave"
          />
        </BModal>
      </BTab>
      <BTab
        v-for="(state, i) in states"
        :key="state.name"
        :title-link-attributes="{
          style: `background-color: ${isSelected(i) ? state.color : null}; color: white;`
        }"
      >
        <template #title>
          <div :style="{ color: isSelected(i) ? 'white' : state.color }">
            <i
              :class="state.icon"
              class="pr-2"
            />
            {{ state.desc }}
            ( {{ ordersOfState(state.name).length }} )
          </div>
        </template>
        <TransitionHeight>
          <OrderList
            class="pt-3"
            :list="ordersOfState(state.name)"
            :selected-id="selectedId"
            :actions="actions"
            :hoverable="hoverable"
            :hide-element="hideElement"
            :sort-type="sortType"
            :with-durations="true"
            @update:selectedId="$emit('update:selectedId', $event)"
            @settle="$emit('settle', $event)"
            @edit="editedId = $event; tab = 0"
            @components="$emit('components', $event)"
            @stopped="showWaiting(); $emit('refresh')"
            @started="showInProgress(); $emit('refresh')"
            @activated="showActivated(); $emit('refresh')"
            @showProductionStats="$emit('showProductionStats', $event)"
            @audit="$emit('audit', $event)"
            @deleted="$emit('refresh')"
            @isEditBlocked="isEditBlocked = $event"
          />
        </TransitionHeight>
      </BTab>
    </BTabs>
    <Error
      v-if="error"
      :message="error.detail"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import {
  PACKOS_ORDER_EDIT,
  PACKOS_ORDER_MANAGEMENT,
  PACKOS_PLANT_ADMIN,
} from '@core/dict/permissions';
import OrderList from '@/components/order/OrderList';
import colors from '@/utils/colors';
import TransitionHeight from '@/components/utils/TransitionHeight';
import { activityStatus } from '@/utils/dictionary';
import ActivityFormModal from '@/components/activity/form/ActivityFormModal';

export default {
  props: {
    list: Array,
    actions: {
      type: Boolean,
      default: true,
    },
    hoverable: Boolean,
    expand: {
      type: Object,
      default: () => ({}),
    },
    selectedId: String,
    lineId: String,
    status: String,
    hideElement: Boolean,
    sortType: String,
  },
  data: () => ({
    tab: 2,
    ready: false,
    isEditBlocked: false,
    editedId: null,
    currentState: 2,
    stateNames: Object.values(activityStatus),
    error: null,
  }),
  components: {
    TransitionHeight,
    OrderList,
    ActivityFormModal,
  },
  computed: {
    ...mapGetters('core', ['canOnElement', 'canAnyElementInPlant', 'can']),
    ...mapGetters(['getActivityState', 'plantId']),
    ...mapGetters('plant', ['displayChangeovers']),
    canEditOrders() {
      if (this.lineId) {
        return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.lineId)
          || this.canOnElement(PACKOS_ORDER_EDIT, this.lineId);
      }
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
          || this.canAnyElementInPlant(PACKOS_ORDER_MANAGEMENT, this.plantId)
          || this.canAnyElementInPlant(PACKOS_ORDER_EDIT, this.plantId);
    },
    ordersByState() {
      return this.list.reduce((acc, curr) => {
        const index = this.stateNames.findIndex(s => s === curr.status);
        const key = index !== -1 ? curr.status : 'Other';

        if (!acc[key]) acc[key] = [curr];
        else acc[key].push(curr);

        return acc;
      }, { });
    },
    states() {
      return this.stateNames.map(status => this.getActivityState({ status }))
        .filter(({ name }) =>
          !((name === activityStatus.unknown || name === activityStatus.suspended
           || (!this.displayChangeovers && name === activityStatus.activated))
        && this.ordersOfState(name).length === 0));
    },
    edited() {
      return this.list.find(x => x.id === this.editedId);
    },
  },
  watch: {
    tab(val) {
      this.currentState = val;
      this.$emit('update:currentState', this.currentState);
      if (this.ready) {
        this.$emit('update:selectedId', null);
      }
    },
    status() {
      if (this.ready) {
        this.setTab();
      }
    },
  },
  methods: {
    colors,
    ...mapActions(['getActivities']),
    isSelected(index) {
      if (!this.canEditOrders) return this.tab === index;
      return this.tab - 1 === index;
    },
    showWaiting() {
      if (this.canEditOrders) {
        this.tab = 2;
      } else {
        this.tab = 3;
      }
    },
    showRightTab(tabName) {
      const index = this.stateNames.findIndex(x => x === tabName);
      this.tab = index + 1;
    },
    showInProgress() {
      if (this.canEditOrders) {
        this.tab = 4;
      } else {
        this.tab = 0;
      }
    },
    showActivated() {
      if (this.canEditOrders) {
        this.tab = 3;
      } else {
        this.tab = 4;
      }
    },
    removeOrder() {
      this.$emit('refresh');
      this.tab = 2;
    },
    ordersOfState(name) {
      return (this.ordersByState[name] || [])
        .slice()
        .sort((a, b) => {
          const aSortKey = a.actualExecution?.end || a.actualExecution?.begin || a.scheduledExecution?.begin;
          const bSortKey = b.actualExecution?.end || b.actualExecution?.begin || b.scheduledExecution?.begin;

          return moment(bSortKey).unix() - moment(aSortKey).unix();
        });
    },
    setTab() {
      const states = this.displayChangeovers ? this.stateNames
        : this.stateNames.filter(x => x !== activityStatus.activated);
      const index = states.findIndex(x => x === (this.status
        || this.list.find(y => y.status === activityStatus.started)?.status
          || this.list.find(y => y.status === activityStatus.activated)?.status
            || this.list.find(y => y.status === activityStatus.released)?.status || activityStatus.draft));
      if (index > -1) {
        this.tab = index + 1;
      }
      this.$nextTick(() => {
        this.ready = true;
      });
    },
    handleSave(activity) {
      this.setTab();
      this.editedId = null;
      this.$emit('refresh', activity);
    },
    showCorrelated(correlationId) {
      this.getActivities({
        params: {
          query: {
            plantId: this.plantId,
            correlationIds: correlationId,
          },
        },
      }).then(({ data }) => {
        this.editedId = data.filter(a => a.id !== this.editedId)[0]?.id || null;
      }).catch(({ response: { data } }) => { this.editedId = null; this.error = data; });
    },
  },
  created() {
    this.$emit('update:currentState', this.currentState);
    this.expanded = { ...this.expanded, ...this.expand };
    this.setTab();
  },
};
</script>

<style lang="scss" scoped>
  .expandable {
    cursor: pointer;
  }

  .order-status-tabs :deep(.nav-pills .nav-item) {
    border: none;
    min-width: 150px;
    margin-top: 5px;
    text-align: center;
  }

  .order-status-tabs :deep(.nav-pills .nav-link.active) {
    background: none;
  }

</style>
