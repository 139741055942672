<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <VideoTrigger
        v-if="showAutomaticVideoUploadSwitch"
        :element-id="elementId"
        :work-event-id="workEventId"
      />

      <BButton
        v-if="showAutomaticVideoUploadSwitch"
        variant="dark"
        size="sm"
        class="mb-4"
        @click="navigateTo('visionRequestClip')"
      >
        <i class="fas fa-download mr-1" />
        {{ $t('downtimes.visionRequestClip') }}
      </BButton>
    </div>

    <div
      v-for="cameraWithClips in camerasWithClips"
      :key="cameraWithClips.id"
    >
      <div class="camera-name">
        {{ cameraWithClips.description }}
      </div>

      <div class="clips">
        <div
          v-for="clip in cameraWithClips.clips"
          :key="clip.id"
          class="clip"
        >
          <div>
            <span>
              {{ title(clip) }}
            </span>
          </div>

          <div class="d-flex align-items-center">
            <span
              v-if="clip.downloadStatus !== 'Success'"
            >
              <BButton
                :variant="clip.downloadStatus === 'FailedOnEdge' ? 'danger' : 'secondary'"
                class="text-white cursor-default"
                size="sm"
              >
                <i
                  v-if="clip.downloadStatus === 'FailedOnEdge'"
                  class="fas fa-exclamation-triangle mr-2"
                />
                <i
                  v-else
                  class="fas fa-info-circle mr-2"
                />
                {{ getClipStatusMessage(clip) }}
              </BButton>
            </span>
            <BButton
              v-else
              variant="primary"
              size="sm"
              @click="selectClip(clip)"
            >
              <i class="fas fa-play mr-2" />
              {{ $t('vision.watchVideo') }}
            </BButton>
          </div>
        </div>
      </div>
    </div>

    <p
      v-if="camerasWithClips.length === 0 && isVisionActive"
      class="text-center mt-3 text-black-50"
    >
      <i class="fas fa-video-slash mr-1" />
      {{ $t('downtimes.noClipsRequested') }}
    </p>

    <BModal
      v-if="selectedClip"
      v-model="showPreviewModal"
      :title="title(selectedClip)"
      size="xl"
      hide-footer
      @hide="hideVideoModal()"
    >
      <VisionPlayer
        v-if="showPreviewModal"
        :clip="selectedClip"
        :video-path="selectedClip.videoUrl"
        :video-poster="selectedClip.posterRemoteStoragePath"
        :video-start-date="selectedClip.startDate"
        :downtime-start-date="startDate"
        :downtime-end-date="endDate"
        :offset-video-playback-seconds-back="offsetVideoPlaybackSecondsBack"
      />
    </BModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import calcDuration from '@/utils/calcDuration';
import moment from 'moment';
import VisionPlayer from '@/components/vision/VisionPlayer';
import VideoTrigger from '@/components/vision/VideoTrigger';

export default {
  name: 'VisionCameraGroupedClips',
  inject: [
    'navigateTo',
  ],
  props: {
    rootWorkLog: {
      type: Object,
      default: () => ({}),
    },
    workEvent: {
      type: Object,
      required: true,
    },
    elementId: {
      type: String,
      required: true,
    },
    isLine: {
      type: Boolean,
      required: true,
    },
    clips: {
      type: Array,
      default: () => [],
    },
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      type: Number,
      required: true,
    },

    showAutomaticVideoUploadSwitch: {
      type: Boolean,
      default: false,
    },
    isVisionActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPreviewModal: false,
    selectedClip: null,
  }),
  components: { VideoTrigger, VisionPlayer },
  filters: {
    formatHour(d) {
      if (!d) return '...';
      return moment(d).format('HH:mm:ss');
    },
  },
  computed: {
    ...mapState({
      cameras: state => state.vision.visionElementCameras,
    }),
    camerasWithClips() {
      if (!this?.cameras) return [];

      return this.cameras
        .filter(camera => this.clips.map(c => c.cameraId).includes(camera.cameraId))
        .sort((a, b) =>
          (a.description || a.name).localeCompare(b.description || b.name))
        .map(camera => ({
          ...camera,
          clips: this.clips.filter(c => c.cameraId === camera.cameraId),
        }));
    },
    offsetVideoPlaybackSecondsBack() {
      const camera = this.cameras.find(c => c.cameraId === this.selectedClip.cameraId);
      return camera?.offsetVideoPlaybackSecondsBack || 5;
    },
    workEventId() {
      return this.isLine ? this.rootWorkLog.workEvent.id : this.workEvent.id;
    },
  },
  methods: {
    calcDuration,
    hideVideoModal() {
      this.showPreviewModal = false;
      this.selectedClip = null;
    },
    selectClip(clip) {
      this.selectedClip = clip;
      this.showPreviewModal = true;
    },
    title(c) {
      const startDate = moment(c.startDate * 1000).format('HH:mm:ss');
      const endDate = moment(c.endDate * 1000).format('HH:mm:ss');
      return `${c.description ? `${c.description} ` : ''}
      ${startDate} - ${endDate} | ${calcDuration(c.startDate, c.endDate)}`;
    },
    clipDownloadedByUser(c) {
      return !c.externalId || c.externalId === '0';
    },
    getClipStatusMessage(clip) {
      switch (clip.downloadStatus) {
        case 'InProgress': return this.$t('vision.clipIsNotReadyYet');
        case 'Schedule': return this.$t('vision.clipIsNotReadyYet');
        case 'FailedOnEdge': return this.$t('downloadStatus.failedOnEdge');
        case 'NoArchiveRecordings': return this.$t('downloadStatus.noArchiveRecordings');
        case 'Deleted': return this.$t('downloadStatus.deleted');
        default:
        case 'Success': return undefined;
      }
    },
  },
};
</script>

<style scoped class="scss">
  .camera-name {
    color: #A6A6A6;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .clip {
    background-color: #F4F4F4;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
