<template>
  <div
    class="order-box my-1 position-relative"
    :class="{ hoverable }"
    :style="{
      'border-color': selected ? statusInfo.color : null,
    }"
  >
    <div
      v-if="hasValidationErrors"
      v-tippy="{ arrow: true }"
      :content="$t('activities.hasValidationErrors')"
      class="float-right validation-error-icon"
    >
      <i
        style="background-color: white"
        class="fas fa-exclamation-circle text-danger rounded-circle"
      />
    </div>

    <div class="d-flex justify-content-between pt-2 px-3 ml-1">
      <h3 class="order-title mb-0">
        {{ description || externalId }}
      </h3>

      <div
        v-if="lineId"
        class="element text-fancy"
      >
        <ElementPicture
          :element-id="lineId"
          style="max-height: 20px"
        />
        {{ elementName(lineId) }}
      </div>
    </div>

    <div class="row mx-1 mb-2">
      <div class="col-7">
        <div
          class="order-status d-flex align-items-center justify-content-between"
        >
          <div
            class="order-status-badge text-uppercase"
            :style="{ 'background-color': statusInfo.color }"
          >
            <i
              style="font-size: 0.9em;"
              :class="activityInfo.icon"
            />
            {{ activityInfo.desc }}
          </div>
        </div>
        <div
          class="text-secondary pl-1 py-1"
          style="font-size: 0.9em; line-height: 1.2"
        >
          <div>
            <small>{{ $t('orders.number') }}:</small>  {{ externalId }}
          </div>

          <div v-if="skuNumber">
            <small>{{ $t('orders.skuNumber') }}:</small> {{ skuNumber }}
          </div>

          <div v-if="skuDescription">
            <small>{{ $t('orders.skuDescription') }}:</small> {{ skuDescription }}
          </div>
          <div
            v-for="(f, index) in additionalFields"
            :key="index"
          >
            <small>{{ f.name }}:</small> {{ f.value }}
          </div>
        </div>

        <div>
          <div
            v-if="isStartedOrCompleted"
            style="max-width: 500px; margin-top: 10px"
          >
            <div
              v-if="orderedQuantity"
              class="d-flex justify-content-between font-weight-bold"
            >
              <div>
                <i class="far fa-clipboard pr-1" />
                {{ producedFormatted | round | addSpaces }} |
                <span v-if="wastedQuantity">
                  <span class="text-danger">
                    {{ wastedFormatted | round | addSpaces }}
                  </span> |
                </span>
                {{ plannedFormatted | round | addSpaces }}
                {{ (orderedQuantity.unit) }}
              </div>
              <div>
                {{ producedPercentage | integer }}%
              </div>
            </div>

            <ProductionChart
              v-if="orderedQuantity"
              :done="producedQuantity && producedQuantity.value || 0"
              :wasted="wastedQuantity && wastedQuantity.quantity || 0"
              :estimated="estimated && estimated.quantity || 0"
              :planned="orderedQuantity.value"
              :unit="orderedQuantity.unit"
              :hide-labels="true"
              style="height: 20px;"
            />
          </div>
          <div
            v-else-if="orderedQuantity"
            class="ml-1"
          >
            <span class="prop-title text-secondary">
              {{ $t('orders.plannedProduction') }}:
            </span>

            {{ ( orderedQuantity.value || 0) | addSpaces({unbreakable:true}) }}{{ `\xa0${orderedQuantity.unit}` }}
          </div>
          <div
            v-if="displayLabour && isStartedOrCompleted"
            style="max-width: 500px; margin-top: 10px"
          >
            <div class="d-flex justify-content-between font-weight-bold">
              <div>
                <i class="fas fa-male pr-2" />
                {{ actualEffort ? actualEffort.manhours : 0 | round | addSpaces }} |
                {{ expectedEffort ? expectedEffort.manhours : 0 | round | addSpaces }}
                {{ 'mh' }}
              </div>
              <div>
                {{ manHoursPercentage | integer }}%
              </div>
            </div>

            <ProductionChart
              :done="actualEffort && actualEffort.manhours || 0"
              :planned="expectedEffort && expectedEffort.manhours || 0"
              :unit="'mh'"
              :hide-labels="true"
              style="height: 20px;"
            />
          </div>
        </div>

        <div class="p-1">
          <BSpinner
            v-if="loadingValidationErrors"
            small
            variant="danger"
          />
          <ValidationErrors
            v-if="validationErrors.length"
            :validation-errors="validationErrors"
          />
        </div>
      </div>

      <div class="col-5 pt-2">
        <div
          v-if="!isCompleted"
          class="small pb-1"
          v-html="duration"
        />
        <div
          v-if="estimatedEnd && !isCompleted"
          style="line-height: 1.1"
          class="no-gutters"
        >
          <span class="prop-title text-secondary col-12 col-md-auto">
            {{ $t('order.eta') }}:
          </span>
          <span>
            {{ estimatedEnd | formatDate }}
            <span class="text-secondary small">
              ({{ estimatedEndDuration }})
            </span>
          </span>
        </div>
        <div
          v-if="(status === activityStatus.started || statusInfo.name === activityStatus.started) ||
            (status === activityStatus.completed || statusInfo.name === activityStatus.completed)"
          style="line-height: 1.1; padding-top: 5px"
          class="no-gutters"
        >
          <span class="prop-title text-secondary col-12 col-md-auto">
            {{ $t('orders.realisation') }}:
          </span>
          <span style="line-height: 1">
            {{ actualExecution && actualExecution.begin | formatDate }} -
            {{ actualExecution && actualExecution.end | formatDate }}
          </span>
        </div>
        <div
          v-if="status !== activityStatus.completed && (statusDesc && statusDesc.name) !== activityStatus.completed"
          style="line-height: 1.1; padding-top: 5px"
          class="no-gutters"
        >
          <span class="prop-title text-secondary col-12 col-md-auto">
            {{ $t('orders.scheduled') }}:
          </span>
          <span v-if="scheduledExecution">
            {{ scheduledExecution.begin | formatDate }} -
            {{ scheduledExecution.end | formatDate }}
          </span>
          <span v-else> - </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrors from '@/components/activity/ValidationErrors';
import ProductionChart from '@/components/charts/production/ProductionChart';
import loopRequest from '@/mixins/loopRequest';
import calcDuration from '@/utils/calcDuration';
import { activityStatus, activityType } from '@/utils/dictionary';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    id: String,
    description: String,
    lineId: String,
    scheduledExecution: Object,
    estimated: Object,
    externalId: String,
    status: String,
    statusDesc: Object,
    hoverable: {
      type: Boolean,
      default: true,
    },
    hideElement: Boolean,
    selected: Boolean,
    orderedQuantity: Object,
    producedQuantity: Object,
    wastedQuantity: Object,
    actualExecution: Object,
    productId: String,
    estimatedEnd: String,
    additionalFields: Array,
    type: String,
    validationErrorCount: Number,
    actualEffort: Object,
    expectedEffort: Object,
  },
  data: () => ({
    activityStatus,
    now: 0,
    elementForm: false,
    loadingValidationErrors: false,
    validationErrors: [],
  }),
  components: {
    ValidationErrors,
    ProductionChart,
  },
  filters: {
    formatDate(d) {
      if (!d) return '...';
      return moment(d).format('Do\xa0MMM\xa0HH:mm');
    },
    round(v) {
      return Math.round((v || 0) * 100) / 100;
    },
  },
  mixins: [loopRequest('updateNow', 3000)],
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters(['getActivityState', 'getActivityType']),
    ...mapGetters('element', ['elementName']),
    ...mapGetters('plant', ['displayLabour']),
    ...mapState({
      items: state => state.item.items,
    }),
    isStartedOrCompleted() {
      return this.statusInfo.name === this.activityStatus.started || this.isCompleted;
    },
    skuNumber() {
      return this.items?.find(i => i.id === this.productId)?.skuNo;
    },
    skuDescription() {
      return this.items?.find(i => i.id === this.productId)?.description;
    },
    isCompleted() {
      return this.status === activityStatus.completed || this.statusInfo.name === activityStatus.completed;
    },
    statusInfo() {
      return this.getActivityState({ status: this.statusDesc?.name || this.status });
    },
    activityInfo() {
      return this.getActivityType({ type: this.type });
    },
    producedFormatted() {
      return Math.round(((this.producedQuantity?.value) || 0 + Number.EPSILON) * 100) / 100;
    },
    wastedFormatted() {
      return Math.round((this.wastedQuantity?.value + Number.EPSILON) * 100) / 100;
    },
    plannedFormatted() {
      return Math.round((this.orderedQuantity.value || 0 + Number.EPSILON) * 100) / 100;
    },
    producedPercentage() {
      return (this.producedQuantity?.value && this.orderedQuantity?.value
        && (this.producedQuantity?.value / this.orderedQuantity?.value) * 100) || 0;
    },
    manHoursPercentage() {
      return (this.actualEffort?.manhours && this.expectedEffort?.manhours
        && (this.actualEffort?.manhours / this.expectedEffort?.manhours) * 100) || 0;
    },
    duration() {
      if (this.status || this.statusDesc?.desc === activityStatus.started) {
        const end = moment(this.scheduledExecution?.end).unix();
        if (!end) return '';
        if (end < this.now) {
          return `<span class="text-danger">
            <b>${calcDuration(end, this.$now)}</b> ${this.$t('orders.afterPlannedEnd')}
          </span>`;
        }
        return `<b>${calcDuration(this.$now, end)}</b> ${this.$t('orders.toPlannedEnd')}`;
      }
      if (this.status || this.statusDesc?.desc === activityStatus.released) {
        const start = moment(this.scheduledExecution?.begin).unix();
        if (!start) return '';
        if (start < this.now) {
          return `<span class="text-danger">
            <b>${calcDuration(start, this.$now)}</b> ${this.$t('orders.afterPlannedStart')}
          </span>`;
        }
        return `<b>${calcDuration(this.$now, start)}</b> ${this.$t('orders.toPlannedStart')}`;
      }
      return '';
    },
    estimatedEndDuration() {
      return calcDuration(this.now, moment(this.estimatedEnd).unix());
    },
    hasValidationErrors() {
      return this.status === this.activityStatus.draft && this.validationErrorCount;
    },
  },
  watch: {
    validationErrorCount() {
      this.getValidationErrors();
    },
  },
  methods: {
    ...mapActions(['getOrderErrors', 'getChangeoverErrors']),
    calcDuration,
    updateNow() {
      this.now = this.$now;
    },
    formatToEpoch(d) {
      return moment(d).unix();
    },
    getValidationErrors() {
      if (!this.id) return;

      this.loadingValidationErrors = true;
      if (this.type === activityType.changeover) {
        this.getChangeoverErrors({
          params: {
            changeoverId: this.id,
            query: { plantId: this.plantId },
          },
        }).then(({ data }) => {
          this.validationErrors = data;
        }).finally(() => {
          this.loadingValidationErrors = false;
        });
      } else {
        this.getOrderErrors({
          params: {
            orderId: this.id,
            query: { plantId: this.plantId },
          },
        }).then(({ data }) => {
          this.validationErrors = data;
        }).finally(() => {
          this.loadingValidationErrors = false;
        });
      }
    },
  },
  created() {
    this.now = this.$now;
    if (this.validationErrorCount > 0) this.getValidationErrors();
  },
};
</script>

<style lang="scss" scoped>
  .order-box {
    box-shadow: 0 0 5px rgba(150,150,150,0.2),
      0 0 25px rgba(150,150,150,0.2);
    border-radius: 20px;
    padding-bottom: 2px;
    border: 2px solid transparent;

    &.hoverable {
      transition: box-shadow 300ms, transform 300ms;
      cursor: pointer;

      &:hover {
        transform: translateX(-5px);
        box-shadow: 0 0 5px rgba(150,150,150,0.3),
        0 0 25px rgba(150,150,150,0.3);
      }
    }

    &:hover {
      transform: translateX(0px);
    }
  }

  .prop-title {
    line-height: 1.1;
    font-size: 14px;
  }

  .order-status {
    height: 25px;
    line-height: 25px;
    text-align: center;
  }

  .order-status-badge {
    padding: 3px 10px;
    text-align: left;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1;
    color: white;
  }

  .order-title {
    font-size: 16px;
    font-weight: 500;
  }

  .order-p {
    font-size: 14px;
    line-height: 1;
  }

  .element {
    padding: 2px 10px 0;
    text-align: left;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;

    span {
      position: relative;
      top: 2px
    }
  }

  .order-scheduled {
    .header {
      font-size: 12px;
      font-weight: normal;
    }
    font-size: 12px;
    font-weight: normal;
  }

  .validation-error-icon {
    font-size: 1.2em;
    margin-top: -0.7em;
    margin-right: -0.3em;
  }

</style>
