<template>
  <div>
    <div
      v-if="haveInheritedData"
      class="d-flex justify-content-end"
    >
      <div
        v-tippy="{ arrow: true }"
        class="small text-secondary text-right performance-from-group"
        :content="$t('items.inheritedFromGroup')"
      >
        <i class="fas fa-object-group pr-1" />
        {{ inheritedValue ? inheritedFlowName : '-' }}
      </div>
    </div>
    <div class="h-100">
      <select
        v-model="flow"
        :disabled="pending"
        class="form-control form-control-sm time-select w-100"
        style="width: 60px"
        @change="publish"
      >
        <option :value="null">
          -
        </option>
        <option
          v-for="t in graphs || []"
          :key="t.id"
          :value="t.id"
        >
          {{ t.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: String,
    inheritedValue: String,
    lineId: String,
    pending: Boolean,
    haveInheritedData: Boolean,
  },
  data: () => ({
    graphs: [],
    flow: null,
  }),
  computed: {
    ...mapGetters(['plantId']),
    inheritedFlowName() {
      const graph = this.graphs.find(x => x.id === this.inheritedValue);
      return graph ? graph.name : '?';
    },
  },
  watch: {
    value(v) {
      this.flow = v || null;
    },
  },
  methods: {
    ...mapActions('plant', ['getGraphs']),
    publish() {
      this.$emit('update:value', this.flow);
    },
  },
  created() {
    this.flow = this.value || null;
    this.getGraphs({
      params: {
        plantId: this.plantId,
        elementId: this.lineId,
        type: 'Flow',
      },
    })
      .then(({ data }) => {
        this.graphs = data;
      });
  },
};
</script>

<style lang="scss" scoped>
  .performance-from-group {
    font-size: 12px;
  }
</style>
