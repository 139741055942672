<template>
  <div>
    <LiveWorklog
      :element-ids="[elementId]"
      :start-date="start || startDate"
      :end-date="end || endDate"
      :minor-stops="true"
      vuex-key="modalElementLogs"
      @response="haveData = true"
    />

    <div
      v-if="!hideActions"
      class="row work-actions mx-0 pb-3"
    >
      <div class="col">
        <div
          class="action d-flex flex-md-column justify-content-center align-items-center"
          @click="showWorklogs"
        >
          <i class="fas fa-history pr-1" />
          {{ $t('work.spectrum') }}
        </div>
      </div>
      <div
        v-if="!hideDowntimesButton"
        class="col"
      >
        <div
          class="action d-flex flex-md-column justify-content-center align-items-center"
          @click="showDowntimes"
        >
          <i class="icon-downtime pr-1" />
          {{ $t('work.downtimes') }}
        </div>
      </div>
    </div>

    <div>
      <HourSlider
        v-if="haveData && !hideTimeSelector"
        :zoom="zoom"
        :start-date="startDate"
        :end-date="endDate"
        class="px-1 px-md-3"
        @changed="updateDates"
      />
      <div
        v-if="haveData"
        class="ml-1 ml-md-2 mt-2"
        style="margin-right: -40px"
      >
        <Worklog
          :worklogs="parsedWorklogs"
          :zoom="zoom"
          :start-date="start || startDate"
          :end-date="end || endDate"
          :style="isMobile && 'height: 60px;' || 'height: 80px;'"
          @zoomed="zoom = $event"
        />
      </div>
      <div
        v-else
        class="mt-5"
      >
        <Loader :error="error" />
      </div>
    </div>
    <div v-if="showSummary">
      <WorklogsSummary
        v-if="haveData"
        :show-chart="false"
        :worklogs="worklogs"
        :start-date="start || startDate"
        :end-date="end || endDate"
        :order-id="orderId"
        :element-id="elementId"
        :resolution="'hour'"
        style="width: 100%;"
        @selected="selected = $event"
      />
    </div>

    <div>
      <hr class="mt-1 mb-0 my-md-2">
      <StackedBarChart
        v-if="buckets"
        class="worklog-stacked-barchart"
        :resolution="'hour'"
        :series="statesChartData.series"
        :colors="statesChartData.colors"
        :categories="statesChartData.categories"
        :data="buckets"
        legend="top"
        :height="isMobile && '200px;' || '300px'"
        style="margin-bottom: -10px"
      />
    </div>
  </div>
</template>

<script>
import Worklog from '@/components/charts/state-diagram/Worklog';
import WorklogsSummary from '@/components/charts/state-diagram/WorklogsSummary';
import StackedBarChart from '@/components/charts/work/StackedBarChart';
import LiveWorklog from '@/components/data/LiveWorklogs';
import HourSlider from '@/components/selectors/HourSlider';
import { groupWorkLogsToBuckets } from '@/utils/aggregator';
import { PACKOS_DOWNTIME_CHANGE, PACKOS_DOWNTIME_MANAGEMENT } from '@core/dict/permissions';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import colors from '../../utils/colors';

export default {
  props: {
    elementId: String,
    orderId: String,
    showSummary: {
      type: Boolean,
      default: true,
    },
    hideTimeSelector: Boolean,
    hideActions: Boolean,
    start: Number,
    end: Number,
  },
  data: () => ({
    selected: [],
    haveData: false,
    zoom: null,
    error: null,
    endDate: null,
    buckets: null,
  }),
  components: {
    Worklog,
    HourSlider,
    WorklogsSummary,
    LiveWorklog,
    StackedBarChart,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'startDate',
      'isMobile',
    ]),
    ...mapGetters('core', ['canOnElement']),
    ...mapGetters('plant', [
      'structure',
      'lines',
      'hideDowntimesButton',
    ]),
    ...mapGetters('work', [
      'workEvent',
      'workState',
      'parseWorklog',
      'bufferedWorkLog',
      'worklogForChart',
    ]),
    ...mapState({
      now: state => state.time.now,
    }),
    canEditWorklogs() {
      return this.canOnElement(PACKOS_DOWNTIME_MANAGEMENT, this.elementId)
        || this.canOnElement(PACKOS_DOWNTIME_CHANGE, this.elementId);
    },
    statesChartData() {
      const isWork = x => x.workState.name === 'work';

      const list = this.buckets.slice()
        .sort((a, b) => isWork(b) - isWork(a));

      return {
        series: list.map(el => ({
          name: el.workState.description,
          data: el.buckets.filter(x => x.start <= this.now)
            .map(b => (b.timePercent > 0 ? Math.floor(b.timePercent) : null)),
          timeValues: el.buckets.filter(x => x.start <= this.now)
            .map(b => (b.duration > 0 ? Math.floor(b.duration) : null)),
        })),
        colors: list.map(el => colors(el.workState.colorCode)),
        categories: !list || list.length === 0 ? []
          : list[0].buckets.filter(x => x.start <= this.now).map(b => moment(b.start * 1000)),
      };
    },
    worklogs() {
      return (this.bufferedWorkLog(this.elementId, 'modalElementLogs') || []).reverse()
        .map(wl => this.parseWorklog(wl))
        .map(x => ({
          ...x,
          startDate: Math.max(x.startDate, this.start || this.startDate),
          endDate: Math.min(x.endDate || this.endDate || this.now),
        }));
    },
    parsedWorklogs() {
      const startDate = this.start || this.startDate;
      const endDate = this.end || this.endDate;

      if (!this.worklogs) return [];
      return this.worklogs
        .filter(el => el && this.isSelected(el))
        .map(el => this.worklogForChart({ worklog: el, startDate, endDate }))
        .filter(el => el.start < el.end);
    },
  },
  watch: {
    haveData(v) {
      if (v) {
        this.calcBuckets();
      }
    },
  },
  methods: {
    colors,
    updateDates(e) {
      if (!e) this.zoom = null;
      else {
        this.zoom = {
          start: e.startDate,
          end: e.endDate,
        };
      }
    },
    isLine(elementId) {
      if (!this.lines) return false;
      return !!this.lines.find(x => x.id === elementId);
    },
    isSelected(el) {
      if (this.selected.length === 0) return true;
      return this.selected.findIndex(x => x.workStateId === el.workState.id) > -1;
    },
    calcBuckets() {
      const list = [{ log: this.worklogs }];
      const startDate = moment((this.start || this.startDate) * 1000).startOf('hour').unix();
      const endDate = moment((this.end || this.endDate) * 1000).endOf('hour').unix();
      this.buckets = groupWorkLogsToBuckets(list, startDate, endDate,
        this.$now, 60 * 60, 'workState');
    },
    showWorklogs() {
      const lineId = this.isLine(this.elementId) ? this.elementId : this.structure.parent(this.elementId);
      let link = `/${this.plantId}/dashboard/line/${lineId}/worklogs`;
      if (!this.isLine(this.elementId)) {
        link += `/${this.elementId}`;
      }
      this.$router.push(link);
    },
    showDowntimes() {
      const lineId = this.isLine(this.elementId) ? this.elementId : this.structure.parent(this.elementId);

      this.$router.push(`/${this.plantId}/dashboard/line/${lineId}/worklogs/${this.elementId}`);
    },
  },
  created() {
    this.endDate = this.$now;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .work-actions {
    margin-bottom: 15px;

    .action {
      font-size: 18px;
      height: 80px;
      font-weight: 500;
      border-radius: 10px;
      border: 2px solid rgba(150, 150, 150, 0.1);
      text-align: center;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(150, 150, 150, 0.1);
      cursor: pointer;

      &:hover {
        border-color: rgba($ilabo, 0.3);
      }
    }
  }

  @media (max-width: 1024px) {
    .work-actions {
      margin-bottom: 10px;

      .action {
        font-size: 12px;
        padding: 12px 6px;
        height: auto;
      }
    }
  }
</style>

<style lang="scss">
  @media (max-width: 600px) {
    .worklog-stacked-barchart {
      svg g text.apexcharts-xaxis-label:nth-child(odd), svg g text.apexcharts-yaxis-label:nth-child(even) {
        display: none;
      }
    }
  }
</style>
