<template>
  <div
    v-if="segments"
    class="mb-5"
  >
    <LiveElementData
      :ids="machineIds.concat(supplyingIds).concat([lineId])"
      :split-by-shift="splitByShift"
      :split-by-order="splitByOrder"
      @response="pending = false"
    />
    <LiveSignals
      :element-ids="machineIds.concat(supplyingIds)"
      :signal-ids="liveSignalIds"
      @currentValues="saveSignalValues"
    />

    <div
      class="sticky-top"
      style="min-height: 40px; z-index: 1"
    >
      <div
        v-if="pending"
        class="machines-loader"
      >
        <Loader />
      </div>
      <ElementMinimap
        v-if="!showNotInFlow && flow"
        :flow="flow"
        :element-id="lineId"
        :scroll-width="scrollWidth"
        :scroll-left="scrollLeft"
        :colors="elementColors"
        :names="elementNames"
        style="margin: auto"
        @scroll="updateScroll"
      />

      <div
        v-if="notInFlow && notInFlow.length > 0 && machines && machines.length > 0"
        style="position: absolute; left: 0; top: 0"
      >
        <button
          v-b-tooltip.right="showNotInFlow ? null : $t('monitoring.notInFlow')"
          class="btn btn-outline-secondary icon-btn"
          @click="showNotInFlow = !showNotInFlow"
        >
          <span v-if="!showNotInFlow">
            <i
              class="ion ion-ios-git-network position-relative"
              style="left: -3px"
            />
            <i
              class="ion ion-md-close"
              style="position: absolute; left: 18px; top: 16px; font-size: 12px;"
            />
          </span>
          <span v-else>
            <i class="ion ion-md-close" />
          </span>
        </button>
      </div>
    </div>

    <div
      ref="container"
      class="position-relative"
      style="user-select: none; margin: 0 -15px;"
    >
      <div v-if="!showNotInFlow">
        <div
          ref="machines"
          :style="{
            height: `${graphHeight}px`,
            width: scrollWidth < 1 ? null : `${graphWidth + 50}px`,
          }"
          class="machines p-2"
          @mousedown="mouseDown"
          @scroll="updateLeftScroll"
        >
          <div
            v-for="machine in machines"
            :key="machine.id"
            :style="styleFor(machine)"
            style="position: absolute; z-index: 10; padding-right: 25px; cursor:pointer"
            @click="openDetails(machine)"
          >
            <div class="line-element">
              <Machine
                v-bind="machine"
                :plant-id="plantId"
                :line-id="lineId"
                @switch-state="isStarting = $event; startStopMachineId = machine.id; machineStartModal = true"
                @downtimes="showModal(machine.id, $event)"
                @current="showMachineDetails(machine)"
              />
              <div v-if="signalsLayout[machine.id]">
                <div
                  v-for="(s, sid, index) in signalsLayout[machine.id]"
                  :key="s.pos"
                  :style="getSignalStyle(s, sid, machine.id, index)"
                  style="position: absolute"
                  class="machine-value"
                >
                  <div style="font-size: 10px; font-weight: 400">
                    {{ getSignalDescription(sid) }}
                  </div>
                  <span style="white-space: nowrap">
                    {{ getSignalValue(sid, machine.id) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="asset in supplyingAssets"
            :key="asset.id"
            :style="styleFor(asset)"
            style="position: absolute; z-index: 10; padding-right: 25px;"
          >
            <div class="line-element">
              <Machine
                v-bind="asset"
                :alien="true"
                :plant-id="plantId"
                :line-id="lineId"
                @modal="showModal(asset.id, $event)"
                @current="showMachineDetails(asset)"
              />
              <div v-if="signalsLayout[asset.id]">
                <div
                  v-for="(s, sid, index) in signalsLayout[asset.id]"
                  :key="s.pos"
                  v-tippy
                  :style="getSignalStyle(s, sid, asset.id, index)"
                  style="position: absolute"
                  class="machine-value"
                  :content="signalDescription(sid)"
                >
                  <div class="desc">
                    {{ getSignalDescription(sid) }}
                  </div>
                  {{ getSignalValue(sid, asset.id) }}
                </div>
              </div>
            </div>
          </div>

          <Connections
            :paths="paths"
            :show-flow="true"
            :active-edges="paths"
            :buffers="machinesBuffers"
            :style="{
              paddingTop: '10px',
              left: '100px',
              width: `${graphWidth}px`,
            }"
          />
          <Connections
            :paths="supplyingEdges"
            :show-flow="false"
            :active-edges="supplyingEdges"
            :active-style="`stroke: ${colors('blue')}; stroke-width: 3`"
            :style="{
              paddingTop: '10px',
              left: '100px',
              width: `${graphWidth}px`,
            }"
            connection-type="straight"
          />

          <Transition name="fade">
            <div
              v-if="showDetails"
              :style="{ width: `${graphWidth + 50}px` }"
              class="overlay"
              @click="showDetails = null"
            />
          </Transition>
        </div>
        <MachineDetails
          v-if="showDetails"
          :style="styleFor(machineDetails, true)"
          :machine="machineDetails"
          style="z-index: 102; position: absolute"
          @close="showDetails = null"
        />
      </div>
      <div
        v-else
        class="d-flex justify-content-center flex-wrap"
      >
        <div
          v-for="machine in notInFlow"
          :key="machine.id"
          :style="{
            'z-index': highlighted === machine.id ? 101 : 10
          }"
          class="p-3"
          style="z-index: 10; position: relative"
        >
          <Machine
            :ref="`machine${machine.id}`"
            v-bind="machine"
            :plant-id="plantId"
            :line-id="lineId"
            @modal="showModal(machine.id, $event)"
            @switch-state="switchState(machine.id, $event)"
            @current="showMachineDetails(machine)"
          />
        </div>
        <Transition name="fade">
          <div
            v-if="showDetails"
            style="width: 100%; height: 100%"
            class="overlay"
            @click="showDetails = null"
          />
        </Transition>
        <MachineDetails
          v-if="showDetails"
          :style="styleForOutOfFlow(machineDetails)"
          :machine="machineDetails"
          style="z-index: 102; position: absolute"
          @close="showDetails = null"
        />
      </div>
    </div>

    <LModal
      :show.sync="machineStartModal"
      size="lg"
    >
      <StartElement
        :element-id="startStopMachineId"
        @close="machineStartModal = false"
      />
    </LModal>
  </div>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import LiveElementData from '@/components/data/LiveElementData';
import LiveSignals from '@/components/data/LiveSignals';
import Connections from '@/components/graph/Connections';
import ElementMinimap from '@/components/graph/ElementMinimap';
import Graph from '@/components/graph/Graph';
import {
  getPath, graphHeight, graphWidth, nodePositions,
} from '@/components/graph/layout';
import Machine from '@/components/machine/Machine';
import MachineDetails from '@/components/machine/MachineDetails';
import StartElement from '@/components/modals/StartElement';
import colors from '@/utils/colors';
import { elementType, plantProperty } from '@/utils/dictionary';
import { addSpaces } from '@core/utils/numbers';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    lineId: String,
  },
  data: () => ({
    modalShown: false,
    modalMachineId: null,
    machineWidth: 250,
    margin: 20,
    scrollWidth: 0,
    scrollLeft: 0,
    showNotInFlow: false,
    showDetails: null,
    highlighted: null,
    machineStartModal: false,
    startStopMachineId: null,
    isStarting: true,
    pending: true,
    graphs: [],
    signalValues: {},
    elementTypeEnum: elementType,
  }),
  components: {
    Connections,
    LiveSignals,
    LiveElementData,
    ElementMinimap,
    MachineDetails,
    Machine,
    StartElement,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'startDate',
      'screenWidth',
      'splitByOrder',
      'splitByShift',
    ]),
    ...mapGetters('work', [
      'workStateOfEvent',
    ]),
    ...mapGetters('element', [
      'elementType',
    ]),
    ...mapGetters('plant', [
      'structure',
      'plantGraphs',
      'plantProperty',
      'flowLayout',
    ]),
    showVision() {
      return this.plantProperty(plantProperty.vision) === 'true';
    },
    machineIds() {
      return this.structure.allLowerChildren(this.lineId)
        .filter(id => this.elementType(id) === this.elementTypeEnum.machine);
    },
    segmentIds() {
      return this.structure.lowerChildrenOf(this.lineId);
    },
    supplying() {
      if (!this.plantGraphs) return [];
      return this.plantGraphs
        .filter(g => g.type === 'Supplying')
        .map(g => {
          const common = this.machineIds.find(id => !!g.node(id));
          let edges = [];
          let nodeIds = [common];

          while (nodeIds.length > 0) {
            // eslint-disable-next-line no-loop-func
            const newEdges = g.edges.filter(e => nodeIds.findIndex(id => id === e.to) > -1);
            edges = edges.concat(newEdges);
            nodeIds = newEdges.map(e => e.from);
          }

          return { ...g, edges, nodes: g.nodeList() };
        });
    },
    supplyingIds() {
      return this.supplyingAssets.map(({ id }) => id);
    },
    supplyingAssets() {
      const edges = this.supplying.flatMap(g => g.edges);

      const ids = this.supplying
        .flatMap(g => g.nodes)
        .map(x => x.id)
        .filter(x => this.machineIds.findIndex(id => x === id) === -1)
        .filter(x => this.graphPositions[x])
        .filter(x => edges.findIndex(({ from, to }) => from === x || to === x) !== -1);

      return this.$store.getters['plant/elements'](ids);
    },
    machineDetails() {
      if (!this.showDetails) return null;
      return this.$store.getters['element/element'](this.showDetails);
    },
    flow() {
      const fs = this.$store.getters['plant/flows'](this.lineId);

      if (fs.length > 0) {
        return fs[0];
      }
      return null;
    },
    paths() {
      if (!this.flow) return [];
      return this.flow.edges
        .map(e => getPath(e, this.graphPositions, true))
        .filter(x => x);
    },
    supplyingEdges() {
      return this.supplying
        .flatMap(({ edges }) => edges)
        .map(e => getPath(e, this.graphPositions, true))
        .filter(x => x);
    },
    graphPositions() {
      const container = { overflow: true };
      const box = { maxHeight: 250, maxWidth: 250 };
      const layout = this.flow ? this.flowLayout(this.flow.id) : [];

      return nodePositions(this.flow, layout, container, box, 10, 20);
    },
    liveSignalIds() {
      return Object.keys(Object.values(this.signalsLayout)
        .flatMap(x => Object.keys(x))
        .reduce((acc, k) => {
          acc[k] = true;
          return acc;
        }, {}));
    },
    signalsLayout() {
      const layout = this.flow ? this.flowLayout(this.flow.id) : [];
      if (!layout || !Array.isArray(layout)) return {};
      return layout.reduce((acc, curr) => {
        if (!curr.config) return acc;
        acc[curr.id] = curr.config;
        return acc;
      }, {});
    },
    graphWidth() {
      return graphWidth(this.graphPositions) + 200;
    },
    graphHeight() {
      return graphHeight(this.graphPositions) + 110;
    },
    elementColors() {
      return this.machines.reduce((acc, m) => {
        this.$set(acc, m.id, this.getColor(m));
        return acc;
      }, {});
    },
    elementNames() {
      return this.machines.reduce((acc, m) => {
        this.$set(acc, m.id, m.name.slice(0, 1).toUpperCase());
        return acc;
      }, {});
    },
    allSegments() {
      return this.$store.getters['plant/elements'](this.segmentIds);
    },
    machines() {
      if (!this.flow) return [];
      let ids = this.structure.allLowerChildren(this.lineId)
        .filter(id => this.elementType(id) === this.elementTypeEnum.machine);
      if (this.flow) {
        ids = ids.filter(x => this.flow.nodes[x]);
      }
      return this.$store.getters['plant/elements'](ids);
    },
    machinesBuffers() {
      const line = this.$store.getters['element/element'](this.lineId);
      return this.flow.edges.filter(edge => !!getPath(edge, this.graphPositions, true))
        .map(edge => {
          if (!line.buffers || line.buffers.length === 0) return null;
          const buffer = line.buffers.find(b =>
            (b.consumerIds.includes(edge.from) && b.producerIds.includes(edge.to))
          || (b.consumerIds.includes(edge.to) && b.producerIds.includes(edge.from)));
          if (!buffer) return null;
          return {
            name: buffer.name,
            value: buffer.value,
            unit: buffer.unit,
          };
        });
    },
    notInFlow() {
      const machineIds = this.structure.allLowerChildren(this.lineId)
        .filter(id => this.elementType(id) === this.elementTypeEnum.machine);
      const ids = machineIds.filter(id => !this.machines.some(y => y.id === id));

      return this.$store.getters['plant/elements'](ids);
    },
    segments() {
      return this.allSegments.map(s => {
        const machineIds = this.structure.lowerChildrenOf(s.id);
        return {
          ...s,
          machines: this.$store.getters['plant/elements'](machineIds),
        };
      });
    },
  },
  watch: {
    screenWidth() {
      this.updateScrollWidth();
    },
    graphWidth() {
      this.updateScrollWidth();
    },
    machines(m) {
      if (m.length === 0) {
        this.showNotInFlow = true;
      } else {
        this.showNotInFlow = false;
      }
    },
  },
  methods: {
    ...mapActions(['getTenantCameras']),
    ...mapActions('work', ['startElement']),
    ...mapActions('element', ['switchElementState']),
    ...mapActions('plant', ['getPlantGraphs']),
    colors,
    openDetails(machine) {
      const { plantId } = this;

      this.$router.push(`/${plantId}/element/${machine.id}/details`);
    },
    showModal(id) {
      this.modalMachineId = id;
      this.modalShown = true;
    },
    saveSignalValues(values) {
      this.signalValues = values;
    },
    getSignalDescription(sid) {
      if (!this.signalValues || !this.signalValues[sid]) return '';
      return this.signalValues[sid].description;
    },
    getSignalStyle(s, id, mid, index) {
      const v = this.signalValues[id];
      if (!v) return { display: 'none' };
      const positionLayoutById = Object.values(this.signalsLayout[mid] || []);
      const otherPositionsAmountBefore = positionLayoutById
        .filter((i, idx) => i !== s && idx <= index)
        .length;
      const positionIndex = positionLayoutById
        .filter(i => i === s)
        .indexOf(s, index - otherPositionsAmountBefore);
      const signalSpacerHight = 32 * positionIndex;
      let color = null;
      if (v.type && v.type.toUpperCase().includes('WASTE')) {
        color = this.$color('red');
      }

      if (s === 'r') {
        const top = `${80 + (signalSpacerHight)}px`;

        return {
          color,
          top,
          right: '-5px',
          transform: 'translateX(100%)',
        };
      }
      if (s === 'l') {
        const top = `${80 + (signalSpacerHight)}px`;

        return {
          color,
          top,
          left: '-5px',
          transform: 'translateX(-100%)',
        };
      }
      if (s === 't') {
        return {
          color,
          top: `${-5 - (signalSpacerHight)}px`,
          transform: 'translate(-50%, -100%)',
          left: '50%',
        };
      }
      if (s === 'b') {
        return {
          color,
          bottom: `${-5 - (signalSpacerHight)}px`,
          transform: 'translate(-50%, 100%)',
          left: '50%',
        };
      }
      return {};
    },
    getSignalValue(sId, machineId) {
      let v = this.signalValues[sId];
      if (!v) return '';
      if (v.type && v.type.toUpperCase().trim() === 'COUNTER') {
        const m = this.machines.find(x => x.id === machineId);
        if (m && m.production) {
          v = { ...v, value: m.production.done };
        } else {
          return '?';
        }
      } else if (v.type && v.type.toUpperCase().trim() === 'WASTE_COUNTER') {
        const m = this.machines.find(x => x.id === machineId);
        if (m && m.production) {
          v = { ...v, value: m.production.wasted };
        } else {
          return '?';
        }
      }

      if (v.valueType === 'INT' || v.valueType === 'DECIMAL') {
        return `${addSpaces(v.value || 0)} ${v.unit || ''}`;
      }
      return `${v.value || ''} ${v.unit || ''}`;
    },
    showMachineDetails(machine) {
      this.showDetails = machine.id;
      this.highlighted = machine.id;
    },
    mouseDown(e) {
      this.mousePos = { x: e.x };
    },
    getColor(m) {
      if (!m.currentState) return colors('grey');
      const state = this.workStateOfEvent(m.currentState.eventId);
      return state ? colors(state.colorCode) : colors('grey');
    },
    updateScroll({ left }) {
      this.$refs.machines.scrollLeft = left * this.graphWidth;
    },
    updateScrollWidth() {
      if (!this.$refs.container) return;
      const width = this.$refs.container.offsetWidth;
      this.scrollWidth = (width / this.graphWidth);
    },
    updateLeftScroll() {
      if (!this.$refs.machines) return;
      const left = this.$refs.machines.scrollLeft;
      this.scrollLeft = (left / this.graphWidth);
    },
    showWorklogs() {
      this.$router.push(`/${this.plantId}/dashboard/line/${this.lineId}/worklogs`);
    },
    getXY(id) {
      return this.graphPositions[id];
    },
    styleFor(machine, absolute) {
      const p = this.getXY(machine.id);
      if (!p) return {};
      const { y } = p;
      let { x } = p;
      if (absolute && this.$refs.machines) {
        const left = this.$refs.machines.scrollLeft;
        const width = this.$refs.container.offsetWidth;
        const offset = this.$refs.machines.offsetLeft;
        x -= left - offset;

        if (width - x < 560) {
          x -= 570;
        }
      }

      const fixedOffset = 50 * Math.floor(this.screenWidth / 600);

      return {
        left: `${x + fixedOffset}px`,
        top: `${y + 10}px`,
        'z-index': this.highlighted === machine.id ? 101 : 10,
      };
    },
    styleForOutOfFlow({ id }) {
      const machines = this.$refs[`machine${id}`];
      if (!machines || machines.length === 0) return {};
      const [machine] = machines;
      const { left, top } = machine.$el.getBoundingClientRect();
      const cont = this.$refs.container.getBoundingClientRect();

      return {
        left: `${left}px`,
        top: `${top - cont.top}px`,
      };
    },
    getCameraForTenant() {
      if (!this.showVision) return;
      this.getTenantCameras();
    },
    updatePlantFlows() {
      this.getPlantGraphs({
        params: {
          plantId: this.plantId,
          query: {
            type: ['Flow'],
          },
        },
      })
        .then(({ data }) => {
          const graphs = data
            .filter(x => x.isActive)
            .map(f => {
              const nodes = f.nodes.map(n => this.structure.node(n));
              const g = new Graph(f.id, nodes, f.edges, f.elementId);
              g.name = f.name;
              g.type = f.type;
              return g;
            });
          this.$store.commit('plant/updateFlows', graphs);
        });
    },
  },
  created() {
    this.updatePlantFlows();

    this.$emit('pageChange', 'machines');
    const cached = this.machines.findIndex(m => !!m.currentState);
    if (cached !== -1) {
      this.pending = false;
    }
    if (this.machines.length === 0) {
      this.showNotInFlow = true;
    }

    this.mouseMove = e => {
      if (!this.mousePos) return;
      const diffX = e.pageX - this.mousePos.x;

      this.$refs.machines.scrollLeft -= diffX;
      this.mousePos.x = e.pageX;
    };

    this.mouseUp = () => {
      this.mousePos = null;
    };

    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
  },
  destroyed() {
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
  },
  mounted() {
    this.updateScrollWidth();
    this.updateLeftScroll();
    this.getCameraForTenant();
  },

};
</script>

<style lang="scss" scoped>
  .machines {
    position: relative;
    overflow: auto;
    margin: 20px auto;
    height: 100%;
    z-index: 0;
    padding-top: 10px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    user-select: none;
  }

  .machines-loader {
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: -17px;
    z-index: 100;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .line-element {
    border-radius: 5px;
    background-color: white;
    position: relative;
  }

  .machine-value {
    font-weight: 500;
    line-height: 1.1;
    .desc {
      font-size: 10px;
      font-weight: 400;
    }
  }

  .machines::-webkit-scrollbar {
    display: none;
  }

  .fade-leave-active,
  .fade-enter-active {
    transition: all 300ms;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to, .fade-leave {
    opacity: 1;
  }

</style>
