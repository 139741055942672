<template>
  <div class="row mt-2 mx-1">
    <div
      class="route col-6 text-center"
      :style="{ opacity: isDowntime ? '1' : '0.5' }"
    />

    <LinePauseButton
      class="route col-6 text-center"
      :line-id="elementId"
      :is-forced="currentState ? currentState.isForced : false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LinePauseButton from '../modules/LinePauseButton';

export default {
  props: {
    elementId: String,
    currentState: Object,
  },
  data: () => ({
    currentStateEdit: null,
    downtimeChangeModal: false,
  }),
  components: {
    LinePauseButton,
  },
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('work', ['workStateOfEvent']),
    isDowntime() {
      if (!this.currentState) return false;
      const state = this.workStateOfEvent(this.currentState.eventId);
      return state.name.toLowerCase() !== 'work';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../line";
</style>
