<template>
  <div class="mt-1">
    <div class="d-flex align-items-center justify-content-between numbers-box">
      <div>
        <span>
          {{ doneQuantityValue | integer }}
        </span>
        |
        <span class="text-danger">
          {{ wastedQuantityValue | integer }}
        </span>
        |
        <span>
          {{ orderedQuantityValue | integer }}
        </span>
        <span>
          {{ unit }}
        </span>
      </div>
      <div>
        {{ completionPercent | integer }}%
      </div>
    </div>

    <div class="position-relative">
      <BarChart
        :list="chartList"
        style="height: 8px;"
        hide-labels
      />

      <div
        v-if="estimatedQuantityValue > -1"
        :style="{left: caretPosition}"
        class="icon"
      >
        <span
          :class="estimatedQuantityValue ? productionColor({ doneQuantityValue, estimatedQuantityValue }) : ''"
          class="xs-caret"
        >
          <i class="fas fa-minus fa-rotate-90" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import productionColor from './productionColor';

export default {
  name: 'SmallProductionBarWithNumbers',
  props: {
    wastedQuantityValue: {
      type: Number,
      required: true,
    },
    doneQuantityValue: {
      type: Number,
      required: true,
    },
    orderedQuantityValue: {
      type: Number,
      required: true,
    },
    estimatedQuantityValue: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  components: { BarChart },
  computed: {
    caretPosition() {
      return this.orderedQuantityValue === 0
        ? '100%' : `${(Math.min(1, (this.estimatedQuantityValue / this.orderedQuantityValue))) * 100}%`;
    },
    completionPercent() {
      return this.orderedQuantityValue === 0
        ? 100 : (this.doneQuantityValue / this.orderedQuantityValue) * 100;
    },
    chartList() {
      const leftValue = 100 - this.completionPercent;
      return [
        { color: '#5886C1', value: this.completionPercent },
        { color: '#C4C4C4', value: leftValue },
      ];
    },
  },
  methods: {
    productionColor,
  },
};
</script>

<style lang="scss" scoped>
 .numbers-box {
   font-size: 10px;
   font-weight: 500;
   margin-bottom: 2px;
 }

 .icon {
   position: absolute;
   text-align: center;
   margin-left: -10px;
   top: 0;
   line-height: 18px;

   .xs-caret {
     position: relative;
     top: -6px;
     margin-left: 4px;
     font-size: 14px;
     line-height: 0;
     text-shadow: 0 0 3px rgba(100,100,100,0.5);
   }
 }
</style>
