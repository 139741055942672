<template>
  <div class="d-flex align-items-center no-gutters">
    <div
      class="px-0"
      style="width: 25px"
    >
      <button
        class="btn btn-outline-secondary btn-sm icon-btn"
        type="button"
        @click="$emit('remove')"
      >
        <i class="fas fa-times" />
      </button>
    </div>
    <div class="flex-grow-1 px-2">
      <BFormInput
        v-model="workerName"
        :placeholder="`${$t('activities.resources.worker')} ${index}`"
        @input="$emit('update:name', workerName)"
      />
    </div>
    <div class="time-column">
      <BFormInput
        ref="hours"
        v-model="hours"
        class="text-right"
        :formatter="threeLetterNumber"
        @input="update"
        @click.native="$event.target.select()"
      />
    </div>
    <div>
      <span class="hour-divider">
        :
      </span>
    </div>
    <div class="time-column">
      <BFormInput
        v-model="minutes"
        class="text-right"
        :formatter="twoLetterNumber"
        @input="update"
        @click.native="$event.target.select()"
      />
    </div>
  </div>
</template>

<script>
import { twoLetterNumber, threeLetterNumber } from '@/utils/time';

export default {
  props: {
    index: {
      type: Number,
      default: 1,
    },
    name: String,
    duration: String,
    maxDuration: String,
  },
  data: () => ({
    workerName: '',
    hours: '',
    minutes: '',
  }),
  watch: {
    duration() {
      this.initTime();
    },
  },
  methods: {
    twoLetterNumber,
    threeLetterNumber,
    update() {
      this.$emit('update:duration', `${this.hours}:${this.minutes}`);
    },
    initTime() {
      const parts = this.duration.split(':');
      if (parts.length === 2) {
        this.hours = parts[0];
        this.minutes = parts[1];
      }
    },
  },
  created() {
    this.workerName = this.name;
    this.initTime();
  },
};
</script>

<style lang="scss" scoped>
@import "./workers";
</style>
