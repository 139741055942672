import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .post({
    action: 'addCorrectiveAction',
    path: ({ plantId, workEventId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/correctiveAction`,
  })
  .put({
    action: 'updateCorrectiveAction',
    path: ({
      plantId, workEventId, correctiveActionId,
    }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/correctiveAction/${correctiveActionId}`,
  })
  .delete({
    action: 'removeCorrectiveAction',
    path: ({ plantId, workEventId, correctiveActionId }) =>
      `/v2/plant/${plantId}/workEvent/${workEventId}/correctiveAction/${correctiveActionId}`,
  })
  .getStore();

export default store;
