<template>
  <BOverlay
    :show="pending || loadingWorkEventTaxonomiesBindings"
    opacity="0.5"
    spinner-variant="primary"
    class="h-100"
  >
    <div class="h-100 d-flex flex-column mx-n4">
      <div class="d-flex justify-content-between align-items-center px-4 title-box">
        <div class="d-flex align-items-center">
          <div
            v-if="canGoBack"
            class="mr-3 h3 mb-0 text-black-50 cursor-pointer"
            style="line-height: 0"
            @click.stop="goBack"
          >
            <i class="fas fa-chevron-circle-left" />
          </div>

          <div>
            <p class="text-left small text-black-50 mb-0">
              {{ activeSubComponent.subTitle }}
            </p>
            <h5 class="mb-0">
              {{ activeSubComponent.title }}
            </h5>
          </div>
        </div>

        <div class="h5 mb-0 text-black-50">
          <i
            class="fas fa-times cursor-pointer p-1"
            @click.stop="hideSummary"
          />
        </div>
      </div>
      <hr class="mx-0 mb-0">

      <div class="pt-4 content-wrapper h-100">
        <KeepAlive>
          <Transition name="subpage">
            <div class="h-100 d-flex flex-column overflow-auto px-4">
              <Component
                :is="activeSubComponent.component"
                v-if="showView"
                :target-element-id="lineId"
                :line-id="lineId"
                :element-id="lineId"
                :target-state-id="targetStateId"
                :state-id="targetStateId"
                :target-work-event-id="targetWorkEventId"
                :work-event-id="targetWorkEventId"
                :forced-events="forcedEvents"
                :forced-state-events="stateEvents"
                line-stop-offcanvas
                @close="hideSummary"
              />
            </div>
          </Transition>
        </KeepAlive>
      </div>
    </div>
  </BOverlay>
</template>

<script>
import DateTimePicker from '@ilabo/vue-ctk-date-time-picker';
import { mapActions, mapGetters, mapState } from 'vuex';
import colors from '@/utils/colors';
import moment from 'moment';
import CalcDuration from '@/utils/calcDuration';
import { downtimeSummaryRoutes } from '@/components/offcanvases/DowntimeSummaryOffcanvas';
import TimeInputWithDurations from '@core/components/TimeInputWithDurations';

export default {
  props: {
    lineId: {
      type: String,
      default: '',
    },
  },
  provide() {
    return {
      navigateTo: this.switchRoute,
      clearRouteStack: this.clearRouteStack,
      overwriteRouteStack: this.clearRouteStack,

      setTargetElementId: this.setTargetElementId,
      setTargetStateId: this.setTargetStateId,
      setTargetWorkEventId: this.setTargetWorkEventId,
      updateRootCause: this.updateRootCause,
      selectTaxonomyNode: this.selectTaxonomyNode,
      popTaxonomyNode: this.popTaxonomyNode,
      resetSelectTaxonomyNode: this.resetSelectTaxonomyNode,
      lastTaxonomyNodeId: () => this.lastTaxonomyNodeId,
      workEventsTaxonomies: () => this.workEventsTaxonomies,
    };
  },
  data: () => ({
    pending: false,
    routesStack: [],
    showView: true,
    pickerFormat: 'YYYY-MM-DD HH:mm',
    start: null,
    startHour: null,
    end: null,
    endHour: null,
    targetElementId: '',
    targetStateId: '',
    targetWorkEventId: '',
    selectedTaxonomyNodes: [],
    workEventsTaxonomies: [],
    loadingWorkEventTaxonomiesBindings: false,
  }),
  components: {
    TimeInputWithDurations,
    DateTimePicker,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('core', [
      'locale',
      'weekStart',
    ]),
    ...mapGetters('element', [
      'elementName',
    ]),
    ...mapGetters('work', {
      workStateGetter: 'workState',
      selectableWorkEvents: 'selectableWorkEvents',
      workEvents: 'workEvents',
    }),
    ...mapGetters('plant', [
      'plantProperty',
    ]),
    ...mapState({
      workEventTaxonomies: state => state.taxonomies.workEventTaxonomies,
    }),
    forcedEvents() {
      return this.workEvents(this.lineId)
        .filter(e => e.isForced);
    },
    maxEndDate() {
      return moment().format('YYYY-MM-DD');
    },
    invalidDowntimeEnd() {
      return moment(this.end).isSameOrAfter();
    },
    rawDowntimeDuration() {
      return moment(this.end).unix() - moment(this.start).unix();
    },
    downtimeDuration() {
      return this.CalcDuration(moment(this.start).unix(), moment(this.end).unix());
    },
    currentRoute() {
      return this.routesStack[0];
    },
    isRootRouteVisible() {
      return this.currentRoute === downtimeSummaryRoutes.root;
    },
    canGoBack() {
      return this.routesStack.length > 1;
    },
    workEventTaxonomy() {
      const code = this.plantProperty('TAXONOMY_CODE_FOR_REGISTERING_DOWNTIMES');
      return this.workEventTaxonomies.find(t => t.code === code);
    },
    lastTaxonomyNodeId() {
      if (this.selectedTaxonomyNodes.length > 0) {
        return this.selectedTaxonomyNodes[this.selectedTaxonomyNodes.length - 1];
      }
      return null;
    },
    activeSubComponent() {
      if (this.currentRoute.includes('taxonomy')) {
        return {
          subTitle: this.$t('downtimes.step', { step: 1 }),
          title: this.$t('downtimes.selectProblem'),
          component: () => import('@/components/downtimes/subcomponents/DowntimeTaxonomySelector.vue'),
        };
      }
      switch (this.currentRoute) {
        case downtimeSummaryRoutes.state:
          return {
            title: this.$t('downtimes.selectState'),
            subTitle: this.$t('downtimeSelector.stopLineStep', { step: 1 }),
            component: () => import('@/components/downtimes/subcomponents/DowntimeStateSelector'),
          };
        case downtimeSummaryRoutes.problem:
          return {
            title: this.$t('downtimes.selectProblem'),
            subTitle: this.$t('downtimeSelector.stopLineStep', { step: 2 }),
            component: () => import('@/components/downtimes/subcomponents/DowntimeProblemSelector'),
          };
        case downtimeSummaryRoutes.summary:
          return {
            title: this.$t('general.summary'),
            subTitle: this.$t('downtimeSelector.stopLine'),
            component: () => import('@/components/downtimes/subcomponents/DowntimeLineStopSummary'),
          };
        default: return {};
      }
    },
    isSaveEnabled() {
      return this.targetElementId !== ''
        && this.targetWorkEventId !== ''
        && this.targetStateId !== ''
        && this.start
        && this.end
        && this.rawDowntimeDuration > 0
        && !this.invalidDowntimeEnd;
    },
    targetMachineName() {
      if (!this.targetElementId) return '-';
      return this.elementName(this.targetElementId);
    },
    events() {
      return this.selectableWorkEvents(this.lineId);
    },
    eventsByState() {
      if (this.events.length === 0) return {};
      return this.events.reduce((acc, curr) => {
        if (acc[curr.workStateId]) {
          acc[curr.workStateId].push(curr);
        } else {
          acc[curr.workStateId] = [curr];
        }
        return acc;
      }, {});
    },
    stateEvents() {
      return this?.targetStateId && this.eventsByState[this?.targetStateId].filter(e => e.isForced);
    },
    states() {
      return Object.keys(this.eventsByState)
        .map(id => this.workStateGetter(id))
        .filter(x => x);
    },
    targetState() {
      if (!this.targetStateId) return {};
      return this.states.find(s => s.id === this.targetStateId);
    },
    targetEvent() {
      if (!this.targetWorkEventId) return {};
      return this.stateEvents.find(p => p.id === this.targetWorkEventId);
    },
    targetEventName() {
      return this.targetEvent?.name || '-';
    },
  },
  watch: {
    start() {
      if (!this.end || moment(this.end)
        .unix() < moment(this.start)
        .unix()) {
        this.end = this.start;
      }
    },
    end() {
      if (!this.end || moment(this.end)
        .unix() < moment(this.start)
        .unix()) {
        this.end = this.start;
      }
    },
  },
  methods: {
    ...mapActions('work', ['addDowntime']),
    ...mapActions('taxonomies', ['getWorkEventsTaxonomyBinding']),
    colors,
    CalcDuration,
    switchRoute(route) {
      this.routesStack.unshift(route);
    },
    clearRouteStack() {
      if (this.workEventTaxonomy) {
        this.routesStack = ['taxonomy'];
      } else {
        this.routesStack = ['state'];
      }
    },
    goBack() {
      if (this.selectedTaxonomyNodes.length > 0) {
        this.popTaxonomyNode();
      } else {
        this.routesStack.shift();
      }
    },
    transitionPageSwitch() {
      this.showView = false;
      this.$nextTick(() => {
        this.showView = true;
      });
    },
    hideSummary() {
      this.$emit('hide');
    },
    setTargetElementId(elementId) {
      this.targetElementId = elementId;
    },
    setTargetStateId(stateId) {
      this.targetStateId = stateId;
    },
    setTargetWorkEventId(workEventId) {
      this.targetWorkEventId = workEventId;
    },
    updateRootCause() {
      this.routesStack.unshift('summary');
    },
    resetSelectTaxonomyNode() {
      this.selectedTaxonomyNodes = [];
    },
    selectTaxonomyNode(nodeId) {
      this.selectedTaxonomyNodes = [...this.selectedTaxonomyNodes, nodeId];
    },
    popTaxonomyNode() {
      this.selectedTaxonomyNodes.pop();
      this.selectedTaxonomyNodes = [...this.selectedTaxonomyNodes];
    },
    async getWorkEventTaxonomiesBindings() {
      if (!this.workEventTaxonomy) return;
      this.loadingWorkEventTaxonomiesBindings = true;
      try {
        const { data } = await this.getWorkEventsTaxonomyBinding({
          params: {
            plantId: this.plantId,
            query: {
              taxonomyId: this.workEventTaxonomy?.id,
            },
          },
        });
        this.workEventsTaxonomies = data;
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.title || '-', {
          title: this.$t('error.unableToDownloadData'),
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
        });
      } finally {
        this.loadingWorkEventTaxonomiesBindings = false;
      }
    },
  },
  mounted() {
    this.start = moment.unix(this.startDate)
      .set('seconds', 0)
      .format(this.pickerFormat);
  },
  created() {
    this.getWorkEventTaxonomiesBindings();

    if (this.workEventTaxonomy) {
      this.routesStack = ['taxonomy'];
    } else {
      this.routesStack = ['state'];
    }
  },
};
</script>

<style lang="scss" scoped>
.title-box {
  min-height: 40px;
  max-height: 40px;
}

.create-form {
  &:deep() {
    .field-input {
      padding-top: 0 !important;
    }
  }
}

.subpage-enter-active, .subpage-leave-active {
  transition: all .25s;
}
.subpage-enter, .subpage-leave-to {
  transition: 0s;
  opacity: 0;
  transform: translateX(15px);
}
</style>
