export default {
  order: 'Zlecenie',
  orders: 'Zlecenia',
  import: 'Importuj zlecenia',
  completedOrders: 'Ukończone zlecenia',
  inProgressOrders: 'Trwające zlecenia',
  start: 'Start',
  removeLastEntryFirst: 'Przed ponownym potwierdzeniem produkcji usuń ostatnią deklarację',
  show: 'Pokaż zlecenie',
  saveAndActivate: 'Zapisz i aktywuj',
  orderDetails: 'Szczegóły zlecenia',
  activate: 'Aktywuj',
  startOrder: 'Wystartuj zlecenie',
  reportResources: 'Raportuj zasoby ',
  confirmCurrentProduction: 'Potwierdź produkcję',
  activateOrder: 'Aktywuj zlecenie',
  abortOrderActivate: 'Przerwij aktywację',
  selectOrder: 'Wybierz zlecenie',
  selectLineAndSku: 'Wybierz linię i SKU',
  selectSku: 'Wybierz SKU',
  skuPerformance: 'Wydajność dla sku',
  abort: 'Przerwij',
  end: 'Koniec',
  stop: 'Zatrzymaj',
  status: 'Status',
  number: 'Numer',
  orderNumber: 'Numer zlecenia',
  skuDescription: 'Opis SKU',
  skuNumber: 'Numer SKU',
  skuId: 'ID SKU',
  orderId: 'ID Zlecenia',
  item: 'Produkt',
  sku: 'SKU',
  itemNo: 'Numer produktu',
  schedule: 'Harmonogram',
  scheduled: 'Zaplanowano',
  plannedProduction: 'Zaplanowana produkcja',
  realisation: 'Realizacja',
  expectedStart: 'Planowany\xa0Start',
  expectedEnd: 'Planowane\xa0Zakończenie',
  toPlannedEnd: 'do planowego zakończenia',
  toPlannedStart: 'do planowego startu',
  afterPlannedEnd: 'po planowanym zakończeniu',
  afterPlannedStart: 'po planowanym starcie',
  expectedPerformance: 'Oczekiwana wydajność',
  description: 'Opis',
  quantity: 'Wyprodukowano',
  limitExceeded: 'Limit zleceń na linii został osiągnięty. Zatrzymaj lub zakończ obecne zlecenia',
  production: 'Podsumowanie produkcji',
  wasted: 'Straty',
  updateProduction: 'Zaktualizuj produkcję',
  totalProduction: 'Produkcja całkowita',
  incrementalProduction: 'Produkcja przyrostowa',
  productionUpdates: 'Aktualizacje produkcji',
  complete: 'Zakończ',
  hold: 'Wstrzymaj',
  completed: 'Zakończone',
  planned: 'Zaplanowane',
  lineConfig: 'Konfiguracja linii',
  config: 'Konfiguracja',
  additional: 'Dodatkowe',
  settlement: 'Rozliczenie',
  machinesForOrder: 'Wybierz maszyny na których checesz wystartować zlecenie',
  requiredComponentsNotScanned: 'Wymagane komponenty nie zostały zeskanowane',
  firstActivate: 'Niektóre z komponentów są wymagane do startu, aktywuj zlecenie i zeskanuj je aby wystartować',
  productionStats: 'Produkcja',
  release: 'Oddaj',
  take: 'Podejmij',
  comment: {
    startOrder: 'Zlecenie zostanie wystartowane. Wszystkie zeskanowane paczki zostaną aktywowane',
    activateOrder: 'Zlecenie zostanie przygotowane do startu, konfiguracja linii może zostać zmieniona oraz niektóre opakowania będą musiały być zeskanowane przed startem',
    completeOrder: 'Zlecenie zostanie zakończone, i nie będzie mogło już być wystartowane',
    stopOrder: 'Zlecenie zostanie zatrzymane, może zostać wystartowane ponownie',
    abortOrderActivate: 'Aktywacja zotanie wstrzymana, zlecenie wróci do stanu \'Gotowe\'',
  },
  states: {
    inProgress: 'W\xa0trakcie',
    completed: 'Ukończone',
    other: 'Inne',
    activated: 'Aktywowane',
    ready: 'Oczekujące',
    onHold: 'Wstrzymane',
    taken: 'Podjęte',
  },
  activities: {
    addActivity: 'Zaplanuj aktywność',
    editActivity: 'Edycja aktywności',
    changeover: 'Przezbrojenie',
    order: 'Zlecenie',
    stop: 'Zatrzymanie przez operatora',
    failure: 'Awaria',
    lack: 'Brak komponentów lub produktu',
    idle: 'Gotowość',
    holdup: 'Zator',
    inactive: 'Nieaktywny',
    cip: 'CIP',
    completedJob: 'Ukończone zlecenie',
    inProgressJob: 'Zlecenie w realizacji',
  },
  add: 'Dodaj zlecenie',
  create: 'Utwórz zlecenie',
  new: 'Nowe zlecenie',
  edit: 'Edycja zlecenia',
  orderedQuantity: 'Planowana ilość',
  typeCorrectQuantity: 'Planowana ilość musi być większa niż zero',
  quantityUnit: 'Jednostka',
  type: 'Typ',
  loadingItems: 'Wczytywanie listy produktów..',
  manage: 'Zarządzaj zleceniami',
  noActiveOrPlannedOrders: 'Brak aktywnych lub zaplanowanych zleceń',
  noOrders: 'Brak zleceń',
  noProducts: 'Brak SKU',
  orderStarted: 'Rozpoczęto zlecenie',
  productionEnteredManually: 'Produkcja i straty zarejestrowane manualnie',
  manualProductionAdded: 'Dodano produkcję manualnie',
  manualProductionOverwritten: 'Nadpisano manualną produkcję',
  incrementalProduction: 'Przyrost',
  totalProduction: 'Całkowita',
  finishOrder: 'Zakończenie zlecenia',
  product: 'Produkt',
  plannedQuantity: 'Planowana ilość',
  orderHistory: 'Historia zleceń',
  defaultConfiguration: 'Domyślna konfiguracja',
};
