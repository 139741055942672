export default {
  'nothing': 'Geen notificaties',
  'list': 'Lijst',
  'subscribedOnly': 'Alleen voor ingeschreven gebruikers',
  'subscription': 'Inschrijving',
  'addSubscription': 'Inschrijving toevoegen',
  'notificationType': 'Notificatie type',
  'pushNotSupported': 'Notificaties worden niet ondersteund in deze omgeving',
  'pushDenied': 'Notificaties worden geblokt',
  'enablePush': 'Zet push notificaties aan',
  'pushDisabled': 'Push notificaties uit',
  'pushEnabled': 'Push notificaties aan',
  'outdated': 'Over datum sinds',
  'wastedThreshold': 'Afval drempelwaarde',
  'threshold': 'Drempelwaarde',
  'mailing': 'Ontvangen in e-mail',
  'mySubscriptions': 'Mijn inschrijvingen',
  'usersSubscriptions': 'Gebruikers inschrjivingen',
  'teamsSubscriptions': 'Teams inschrijvingen',
  'numberOfSubs': 'Aantal inschrijvingen',
  'subscriptionFromTeam': 'Deze inschrijving komt van het team en kan alleen worden aangepast voor het hele team door een gebruiker met de juiste rechten',
  'type': {'hints': 'Hints', 'warnings': 'Waarschuwingen', 'userNotifications': 'Notificatie'},
  'name': {
    'cmmsNotification': 'Onderhoud',
    'connectionLost': 'Machine connectie verloren',
    'availabilityInsight': 'Beschikbaarheid inzichten',
    'ongoingStop': 'Lopende lijn stop',
    'newDowntime': 'Nieuwe stilstand',
    'orderNotifications': 'Order status verandering',
    'thresholdExceeded': 'Drempelwaarde overschreven',
    'oeeThreshold': 'OEE onder het doel',
    'orderProductionDone': 'Geplande productie gehaald',
    'orderProductionNotAchieved': 'Order afgesloten met minder aantallen dan gepland',
    'orderDelayed': 'Order vertraagd',
    'performanceThreshold': 'Machine prestaties lager dan verwacht',
    'orderWastedThresholdExceeded': 'Drempelwaarde order afval overschreden',
    'selectedLines': 'Geselecteerde lijnen',
    'allLines': 'Alle lijnen',
    'ignoredCounter': 'Counter genegeerd',
    'oeeInfo': 'OEE samenvatting'
  },
  'timePeriod': {
    'none': 'Nee',
    'immediately': 'Onmiddelijk',
    'hourly': 'In uurlijkse notificatie samenvatting',
    'daily': 'In dagelijkse notificatie samenvatting'
  }
};
