<template>
  <div>
    <div class="order-summary py-3 px-4 m-3 mb-4">
      <div v-if="summary">
        <CounterSummary
          :summary="summary"
        />
        <hr>
        <div
          class="row total-style"
        >
          <div class="col-6">
            {{ $t('activities.summary.orderTotal') }}
            <small
              class="pl-1"
              style="position: relative; top: -1px"
            >
              (1 {{ summary.line.lineCounterUnit }} = {{ orderMultiplier }} {{ summary.order.orderUnit }}):
            </small>
          </div>
          <div class="col-2 text-right">
            {{ summary.order.accepted.goodCount | round }} {{ summary.order.orderUnit }}
          </div>
          <div class="col-3 text-danger text-right">
            {{ summary.order.accepted.wasteCount | round }} {{ summary.order.orderUnit }}
          </div>
        </div>
      </div>
      <div v-else>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@core/utils/numbers';
import CounterSummary from '@/components/counters/CounterSummary';

export default {
  props: {
    summary: Object,
  },
  components: {
    CounterSummary,
  },
  computed: {
    orderMultiplier() {
      if (this.summary.line.accepted.goodCount === 0) return 0;
      const multiplier = this.summary.order.accepted.goodCount / this.summary.line.accepted.goodCount;
      return round(multiplier, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
  .order-summary {
    box-shadow: 0 0 5px rgba(150, 150, 150, 0.2), 0 0 25px rgba(150, 150, 150, 0.2);
    background-color: white;
    border-radius: 20px;
    margin: 10px;
  }

  .good-text {
    opacity: 0.85;
    color: gray;
  }

  .total-style {
    font-weight: 700;
    font-size: 1.1em;
  }

</style>
