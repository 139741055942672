<template>
  <div class="importer-wrapper">
    <div>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Navigation
          :parent="parent"
          page="importer"
          class="pb-3"
        />
        <div
          class="mb-2 mb-md-0 mr-2"
          style="width: 200px"
        >
          <button
            class="btn btn-primary btn-block btn-sm"
            @click="showTemplates()"
          >
            <i class="far fa-file-alt pr-2" />
            {{ $t('import.seeTemplates') }}
          </button>
        </div>
      </div>
    </div>
    <div class="importer-container">
      <MainImportPage
        :parent="parent"
        @showView="showImportPage($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from '@/components/importer/Navigation';
import MainImportPage from '@/components/importer/MainImportPage';

export default {
  props: {
    parent: String,
  },
  components: {
    Navigation,
    MainImportPage,
  },
  computed: {
    ...mapGetters(['plantId']),
  },
  methods: {
    showTemplates() {
      this.$router.push(`/${this.plantId}/settings/import-templates`);
    },
    showImportPage(importId) {
      this.$router.push(`/${this.plantId}/importer/${this.parent}/import/${importId}`);
    },
  },

};
</script>

<style lang='scss' scoped>
  @import "./style.scss";
</style>
