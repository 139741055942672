<template>
  <div>
    <Error
      v-if="error"
      class="px-3 text-center py-2"
      style="line-height: 1.1"
      :message="error.detail"
    />

    <div
      v-for="(action, i) in orderActions"
      :key="i"
      style="flex-shrink: 0; margin: 10px"
    >
      <div
        v-if="action.showError"
        class="float-right validation-error-icon"
      >
        <i
          style="background-color: white"
          class="fas fa-exclamation-circle text-danger rounded-circle"
        />
      </div>

      <div
        v-tippy="{ arrow: true, trigger: action.disabled ? 'mouseenter' : 'manual' }"
        class="order-action mx-auto w-100"
        :class="{
          [action.color || '']: true,
          disabled: pending || action.disabled,
        }"
        :content="$t('orders.limitExceeded')"
        @click="pending || action.disabled ? null : action.click()"
      >
        <i :class="action.icon" />
        <div class="text">
          {{ action.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { activityStatus, activityType, lineProperty } from '@/utils/dictionary';

export default {
  props: {
    order: Object,
    hideComponents: Boolean,
    showEdit: Boolean,
    canManageOrders: Boolean,
    canEditOrdersCompleted: Boolean,
    canEditOrdersInprogress: Boolean,
    canConfirmProduction: Boolean,
    canEditOrders: Boolean,
  },
  data: () => ({
    pending: false,
    error: null,
    manualWayOfWorkingEnabledForThisLine: false,
  }),
  computed: {
    ...mapGetters('element', [
      'element',
      'elementProperty',
    ]),
    ...mapGetters('plant', [
      'flows',
      'displayChangeovers',
      'displayLabour',
      'displayManualWayOfWorking',
    ]),
    ...mapGetters(['plantId', 'getActivityState']),
    canStartOrActivate() {
      if (!this.order) return true;
      const line = this.element(this.order.lineId);
      if (!line?.activities?.activeOrder) return true;
      return false;
    },
    isActivated() {
      return this.order && this.order.status === activityStatus.activated;
    },
    isInProgress() {
      return this.order && this.order.status === activityStatus.started;
    },
    isCompleted() {
      return this.order && this.order.status === activityStatus.completed;
    },
    isDraft() {
      return this.order && this.order.status === activityStatus.draft;
    },
    isOrder() {
      return this.order?.type === activityType.order;
    },
    isChangeover() {
      return this.order?.type === activityType.changeover;
    },
    isCleaning() {
      return this.order?.type === activityType.cleaning;
    },
    startButton() {
      return {
        icon: 'fas fa-play',
        text: this.$t('orders.start'),
        color: 'blue',
        disabled: this.isOrder ? false : !this.canStartOrActivate,
        click: () => this.start(),
        show: this.canManageOrders && (this.isOrder || this.isCleaning),
      };
    },
    activateButton() {
      return {
        icon: 'fas fa-bolt',
        text: this.$t('orders.activate'),
        color: 'blue',
        disabled: !this.canStartOrActivate,
        click: () => this.orderActivate(),
        show: this.canManageOrders && this.isOrder,
      };
    },
    auditLogButton() {
      return {
        icon: 'fas fa-clock',
        text: this.$t('general.audit'),
        color: 'outline',
        click: () => this.$emit('audit'),
        show: true,
      };
    },
    orderActions() {
      if (this.isActivated) {
        return [
          this.startButton,
          {
            icon: 'fas fa-ban',
            text: this.$t('general.cancel'),
            color: 'dark',
            click: () => this.abortActivate(),
            show: this.canManageOrders && this.isOrder,
          },
          this.auditLogButton,
        ].filter(x => x.show);
      }

      if (this.isCompleted) {
        return [
          {
            icon: 'fas fa-file-contract',
            text: this.$t('orders.settlement'),
            color: 'outline',
            click: () => this.showDetails(),
            show: this.canConfirmProduction && this.isOrder,
          },
          {
            icon: 'far fa-file-alt',
            text: this.$t('orders.reportResources'),
            color: 'outline',
            click: () => this.showResourceReportModal(true),
            show:
              this.canConfirmProduction
              && this.displayLabour
              && (this.isChangeover || this.isCleaning),
          },
          {
            icon: 'fas fa-pen',
            text: this.$t('general.edit'),
            color: 'dark',
            click: () => this.showForm(),
            show: this.canEditOrdersCompleted,
          },
          this.auditLogButton,
        ].filter(x => x.show);
      }

      if (this.isInProgress) {
        return [
          {
            icon: 'fas fa-stop',
            text: this.$t('general.finish'),
            color: 'blue',
            click: () => this.finish(),
            show: this.canManageOrders && (this.isOrder || this.isCleaning),
          },
          {
            icon: 'fas fa-forward',
            text: this.$t('activities.actions.finishChangeover'),
            color: 'blue',
            click: () => this.orderStart(),
            show: this.canManageOrders && this.isChangeover,
          },
          {
            icon: 'fas fa-stop',
            text: this.$t('activities.actions.abortChangeover'),
            color: 'dark',
            click: () => this.abortActivate(),
            show: this.canManageOrders && this.isChangeover,
          },
          // TODO revive when update production in progress ready
          {
            icon: 'far fa-clipboard',
            text: this.$t('activities.actions.productionReconciliation'),
            color: 'outline',
            click: () => this.showProductionReconciliationModal(),
            show: this.canConfirmProduction
              && (this.displayManualWayOfWorking || this.manualWayOfWorkingEnabledForThisLine)
              && this.isOrder,
          },
          {
            icon: 'far fa-file-alt',
            text: this.$t('orders.reportResources'),
            color: 'outline',
            click: () => this.showResourceReportModal(true),
            show: this.canConfirmProduction && this.displayLabour,
          },
          // {
          //   icon: 'fas fa-chart-line',
          //   text: this.$t('orders.productionStats'),
          //   color: 'blue',
          //   click: () => this.showProductionStatsModal(),
          //   show: this.canManageOrders,
          // },
          {
            icon: 'fas fa-pen',
            text: this.$t('general.edit'),
            color: 'outline',
            click: () => this.showForm(),
            show: this.canEditOrdersInprogress,
          },
          this.auditLogButton,
        ].filter(x => x.show);
      }

      return [
        ...(this.isDraft
          ? [] : [(this.displayChangeovers && this.isOrder)
            ? this.activateButton
            : this.startButton]),
        ...(this.showEdit
          ? [{
            icon: 'fas fa-pen',
            text: this.$t('general.edit'),
            color: 'dark',
            showError: this.isDraft && this.order.validationErrorCount,
            click: () => this.showForm(),
            show: this.canEditOrders,
          },
          {
            icon: 'fas fa-trash',
            text: this.$t('delete'),
            color: 'outline',
            click: () => this.deleteActivity(),
            show: this.canEditOrders,
          }] : []),
        this.auditLogButton,
      ].filter(x => x.show);
    },
  },
  methods: {
    ...mapActions([
      'deactivateOrder',
      'startOrder',
      'activateOrder',
      'getOrderComponents',
      'deleteChangeover',
      'deleteOrder',
      'deleteCleaning',
      'startCleaning',
      'completeCleaning',
    ]),
    ...mapActions('element', ['getElementProperties']),
    checkManualWayOfWorkingForThisLine() {
      this.getElementProperties({
        params: {
          plantId: this.plantId,
          elementId: this.order.lineId,
        },
      })
        .then(({ data }) => {
          const property = data
            .find(x => x.name.toLowerCase() === lineProperty.manualWayOfWorkingEnabled.toLowerCase());

          this.manualWayOfWorkingEnabledForThisLine = property?.value === 'true' || property?.value === 1;
        });
    },
    showComponents() {
      this.$emit('components');
    },
    showForm() {
      this.$emit('edit');
    },
    showSettlement() {
      this.$emit('settle');
    },
    finish() {
      if (this.isCleaning) {
        this.finishCleaning();
      } else {
        this.showSettlement();
      }
    },
    finishCleaning() {
      this.completeCleaning({
        params: {
          query: {
            plantId: this.plantId,
          },
          cleaningId: this.order.id,
        },
      })
        .then(() => {
          this.$emit('stopped');
        })
        .catch(({ response }) => {
          this.error = response.data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    orderActivate() {
      this.pending = true;
      this.error = null;
      this.activateOrder({
        params: {
          query: {
            plantId: this.plantId,
          },
          orderId: this.order.id,
        },
      })
        .then(() => {
          this.$emit('activated');
        })
        .catch(({ response }) => {
          this.error = response.data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    abortActivate() {
      this.error = null;

      const orderId = this.isOrder
        ? this.order.id
        : this.element(this.order.lineId)?.activities?.activeOrder?.id;

      if (orderId == null) {
        this.error = { detail: this.$t('activities.validations.orderNotFound') };
        return;
      }

      this.pending = true;
      this.deactivateOrder({
        params: {
          orderId,
          query: {
            plantId: this.plantId,
          },
        },
      })
        .then(() => {
          this.$emit('stopped');
        })
        .catch(({ response }) => {
          this.error = response.data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    start() {
      if (this.isCleaning) {
        this.startCleaning({
          params: {
            cleaningId: this.order.id,
            query: {
              plantId: this.plantId,
            },
          },
        })
          .then(() => {
            this.$emit('started');
          }).catch(({ response }) => {
            this.error = response.data;
          }).finally(() => {
            this.pending = false;
          });
      } else {
        this.orderStart();
      }
    },
    orderStart() {
      this.error = null;

      const orderId = this.isOrder
        ? this.order.id
        : this.element(this.order.lineId)?.activities?.activeOrder?.id;

      if (orderId == null) {
        this.error = { detail: this.$t('activities.validations.orderNotFound') };
        return;
      }

      this.pending = true;
      this.startOrder({
        params: {
          orderId,
          query: {
            plantId: this.plantId,
          },
        },
      })
        .then(() => {
          this.$emit('started');
        }).catch(({ response }) => {
          this.error = response.data;
        }).finally(() => {
          this.pending = false;
        });
    },
    showProductionStatsModal() {
      this.$emit('showProductionStats');
    },
    showDetails() {
      this.$emit('showDetails', true);
    },
    showResourceReportModal(resources) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          reportResources: true,
          activityId: this.order.id,
          humanResources: resources,
        },
      });
    },
    showProductionReconciliationModal() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          productionReconciliation: true,
          activityId: this.order.id,
        },
      });
    },
    deleteActivity() {
      this.$bvModal.msgBoxConfirm(this.$t('activities.deleteConfirmMessage'), {
        title: this.$t('general.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        cancelVariant: 'outline',
        okTitle: this.$t('general.confirm'),
        cancelTitle: this.$t('general.cancel'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(confirm => {
        if (!confirm) {
          return;
        }
        let promise;
        if (this.isOrder) {
          promise = this.deleteOrder({
            params: {
              orderId: this.order.id,
              query: { plantId: this.plantId },
            },
          });
        } else if (this.isChangeover) {
          promise = this.deleteChangeover({
            params: {
              changeoverId: this.order.id,
              query: { plantId: this.plantId },
            },
          });
        } else if (this.isCleaning) {
          promise = this.deleteCleaning({
            params: {
              cleaningId: this.order.id,
              query: { plantId: this.plantId },
            },
          });
        }

        promise.then(() => {
          this.$emit('deleted');
        }).catch(({ response: { data } }) => {
          this.error = data;
        });
      });
    },
  },
  created() {
    this.checkManualWayOfWorkingForThisLine();
  },
};
</script>

<style lang="scss" scoped>
  .order-action {
    cursor: pointer;
    transition: box-shadow 300ms;
    width: 210px;

    &:hover {
      box-shadow: 0 0px 3px 2px rgba(150, 150, 150, 0.5);
    }
  }
  .validation-error-icon {
    margin-bottom: -0.9em;
  }
</style>
