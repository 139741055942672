<template>
  <div>
    <form @submit.prevent="submit">
      <div class="px-3">
        <ProductionForm
          :done.sync="done"
          :wasted.sync="wasted"
          :unit="unit"
        />
      </div>
      <div class="text-center">
        <Error
          v-if="error"
          class="mt-2"
          :message="error"
        />
        <hr>
        <div class="d-flex justify-content-between">
          <div class="w-50 px-2">
            <button
              class="btn btn-block btn-secondary btn-sm"
              type="button"
              @click="$emit('cancel')"
            >
              {{ $t('general.cancel') }}
            </button>
          </div>
          <div class="w-50 px-2">
            <button
              class="btn btn-block btn-primary btn-sm"
              type="submit"
              :disabled="pending || disabled"
            >
              {{ actionName || $t('general.confirm') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { round } from '@core/utils/numbers';
import ProductionForm from './ProductionForm';

export default {
  props: {
    activityId: String,
    unit: String,
    startDate: String,
    endDate: String,
    disabled: Boolean,
    incremental: Boolean,
    wastedSum: Number,
    doneSum: Number,
    actionName: String,
  },
  data: () => ({
    done: 0,
    wasted: 0,
    pending: false,
    error: null,
  }),
  components: {
    ProductionForm,
  },
  computed: {
    ...mapGetters(['plantId']),
  },
  watch: {
    done(v) {
      this.$emit('done', v);
    },
    wasted(v) {
      this.$emit('wasted', v);
    },
    incremental(v) {
      if (!v) {
        this.done = round(Math.max(this.done, this.doneSum));
        this.wasted = round(Math.max(this.wasted, this.wastedSum));
      } else {
        this.done = round(Math.max(0, this.done - this.doneSum));
        this.wasted = round(Math.max(0, this.wasted - this.wastedSum));
      }
    },
    doneSum(v) {
      this.done = round(v);
    },
    wastedSum(v) {
      this.wasted = round(v);
    },
  },
  methods: {
    ...mapActions(['addOrderManualProduction']),
    submit() {
      if (this.actionName) {
        this.$emit('complete');
        return;
      }

      this.pending = true;
      this.error = null;

      this.addOrderManualProduction({
        params: {
          plantId: this.plantId,
          orderId: this.activityId,
        },
        data: {
          done: this.done,
          wasted: this.wasted,
          to: moment(this.endDate).toISOString(),
          unit: this.unit,
          incremental: this.incremental,
        },
      })
        .then(({ data }) => {
          this.$emit('refresh', data);
        })
        .catch(({ response: { data } }) => {
          this.error = data?.detail || this.$t('error.error');
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.done = round(this.doneSum);
    this.wasted = round(this.wastedSum);
  },
};
</script>

<style>

</style>
