<template>
  <div
    ref="scheduleLogWrapperRef"
    class="position-relative"
    :style="{
      'padding-bottom': displayAxis ? '20px' : null,
      height: `${calculatedHeight}px`,
    }"
  >
    <div
      ref="chartWrapper"
      class="worklog-wrapper"
      @mousedown="activitiesEditMode ? mouseDownOnActivityBlock($event) : mouseDown($event)"
    >
      <div
        ref="chart"
        class="progress downtimes-progress"
      >
        <TransitionGroup
          :name="animation"
          tag="div"
        >
          <div
            v-for="(position, index) in blocks"
            :id="`block${preparePositionId(position.id)}`"
            :key="position.id"
            :ref="`block${position.id}`"
            :style="containerStyle(position)"
            class="progress-bar schedule-bar flex-row flex-wrap"
            :class="{
              hovered: isHovered(position),
              edited: isEdited(position),
              hovering: !isHovered(position) && !!hovered,
              static: position.static,
              conflict: hasPositionIndexConflicts(position),
            }"
            @click="select(position.id)"
            @mouseover="handleMouseOverBlock(position)"
          >
            <div
              class="header"
              :class="{'drag-line-trigger': activitiesEditMode && isDraggingMarkerVisibleForPosition(position)}"
              :style="barStyle(position)"
            >
              <div
                v-if="showCompletionLine"
                class="header-completion-line"
                :class="{'drag-line-trigger': activitiesEditMode && isDraggingMarkerVisibleForPosition(position)}"
              >
                <div
                  class="header-completion-line--inner"
                  :style="{
                    width: calculateCompletionLineWidthForBlock(position),
                    backgroundColor: getCompletionLineColor(position)
                  }"
                />
              </div>

              <div class="block-marker">
                <div
                  v-if="!hideEntryDate || !hideEntryDate[0]"
                  :class="{
                    editable: editableLeft,
                    'center-marker': position.realWidth <= 100,
                    'left-marker': position.realWidth > 100,
                  }"
                >
                  {{ formatDate(getPositionStartDate(position)) }}
                  <span
                    v-if="!hideEntryDate || hideEntryDate[1]"
                    class="end"
                  >
                    - {{ formatDate(position.end) }}
                  </span>
                </div>
                <div
                  v-if="isLeftEditMarkerVisibleForPosition(position)"
                  class="left-marker-line left-marker-line-trigger"
                  :style="borderColor(position, index)"
                  :class="{ editable: editableLeft && isEditMarkersVisibleForPosition(position) }"
                >
                  <i class="fas fa-grip-lines-vertical left-marker-line-trigger" />
                </div>
              </div>
              <div
                class="bar-title text-truncate"
                :class="{'drag-line-trigger': activitiesEditMode && isDraggingMarkerVisibleForPosition(position)}"
              >
                <i
                  v-if="position.icon"
                  :class="position.icon"
                />
                &nbsp;<slot
                  name="body"
                  :block="position"
                >
                  {{ position.name }}
                </slot>
              </div>
              <div class="block-marker">
                <div
                  v-if="position.realWidth > 100 || editableRight"
                  class="right-marker"
                  :class="{
                    editable: editableRight,
                    manual: manualEdit && manualEdit.id === position.id
                  }"
                >
                  <div
                    v-if="manualEdit && manualEdit.id === position.id"
                    class="d-flex"
                  >
                    <div>
                      <BFormInput
                        v-model="manualDate"
                        type="date"
                        class="manual-input"
                        style="border-color: white; max-width: 130px;"
                        @blur="tryBlurManualTime"
                      />
                    </div>
                    <div class="pl-1">
                      <BFormInput
                        :ref="`manual-edit-${position.id}`"
                        v-model="manualTime"
                        type="time"
                        class="manual-input"
                        style="border-color: white;"
                        @blur="tryBlurManualTime"
                      />
                    </div>
                  </div>
                  <span
                    v-else
                    class="right-marker-date"
                  >
                    {{ formatDate(getPositionEndDate(position)) }}
                  </span>
                </div>
                <div
                  v-if="isRightEditMarkerVisibleForPosition(position)"
                  class="right-marker-line right-marker-line-trigger"
                  :class="{ editable: editableLeft && isEditMarkersVisibleForPosition(position) }"
                  :style="borderColor(position, index)"
                >
                  <i class="fas fa-grip-lines-vertical right-marker-line-trigger" />
                </div>
                <div
                  :ref="`dateEditPopup${position.id}`"
                  class="edit-date"
                  style="display: none"
                >
                  {{ formatDate(position.end) }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="plannedBlock && currentEditState"
            key="planned"
            :style="{
              ...containerStyle(plannedBlock, plannedBlock.index),
              zIndex: activitiesPlannedHovering ? 91 : 102
            }"
            class="progress-bar schedule-bar flex-row flex-wrap planned-bar"
            @click="activitiesPlannedHovering ? $emit('edit', hovered) : $emit('edit', hovered.item)"
          >
            <div class="header">
              <span
                v-if="!activitiesPlannedHovering"
                class="planned-info"
              >
                {{ $t('orders.planned') }}
              </span>
            </div>
          </div>
        </TransitionGroup>
      </div>

      <Tippy
        v-for="position in (hideTooltip ? [] : blocks.filter(x => !x.hideTooltip))"
        :key="position.id + '_tooltip'"
        sticky
        :target="`#block${preparePositionId(position.id)}`"
        placement="top"
        theme="light"
        multiple
        :distance="25"
        arrow
        :interactive="interactiveTooltip"
        @show="() => !activitiesEditMode"
      >
        <slot :block="position" />
      </Tippy>
    </div>

    <TimeAxis
      ref="timeaxis"
      :key="activitiesEditMode ? 'editing' : 'not-editing'"
      :hide-labels="!displayAxis"
      style="height: 30px"
      :wrapper-height="calculatedTimeAxisHeight"
      :start-date="currentStartDate + (ignoreUtcOffsetInAxis ? 0 : utcOffset)"
      :end-date="currentEndDate + (ignoreUtcOffsetInAxis ? 0 : utcOffset)"
      :show-background="showBackground"
    />
  </div>
</template>

<script>
import moment from 'moment';
import tinycolor from 'tinycolor2';
import saveConflicts from '@/components/schedule/conflicts';
import { activityStatus, activityType } from '@/utils/dictionary';
import { mapActions } from 'vuex';
import TimeAxis from './TimeAxis';

export default {
  inject: ['editState'],
  props: {
    log: {
      type: Array,
      default: () => [],
    },
    allLog: {
      type: Array,
      default: () => [],
    },
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      type: Number,
      default: Math.floor(Date.now() / 1000),
    },
    displayAxis: {
      type: Boolean,
      default: true,
    },
    selected: String,
    conflicts: {
      type: Boolean,
      default: true,
    },
    showWarnings: {
      type: Boolean,
      default: true,
    },
    scheduleLogIndex: {
      type: Number,
      default: 0,
    },
    hoverScheduleLogIndex: {
      type: Number,
      default: 0,
    },
    hideNow: Boolean,
    hideEntryDate: Array,
    editable: [Boolean, Array],
    editedId: [String, Number],
    height: Number,
    interactiveTooltip: {
      type: Boolean,
      default: false,
    },
    timezone: String,
    hideTooltip: Boolean,
    showCompletionLine: {
      type: Boolean,
      default: false,
    },
    bigPadding: {
      type: Boolean,
      default: false,
    },
    paddingBottom: {
      type: Boolean,
      default: false,
    },
    showBackground: {
      type: Boolean,
      default: false,
    },
    externalHovered: {
      type: Object,
      default: null,
    },
    activitiesPlannedHovering: {
      type: Boolean,
      default: false,
    },
    activitiesEditMode: {
      type: Boolean,
      default: false,
    },
    overlapConflicts: {
      type: Boolean,
      default: false,
    },
    simpleEditMode: {
      type: Boolean,
      default: false,
    },
    editDraggingDisabled: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    noLineActivitiesLogIndex: {
      type: Number,
      default: -1,
    },
    rootLineIds: {
      type: Array,
      default: () => [],
    },
    ignoreUtcOffsetInAxis: {
      type: Boolean,
      default: false,
    },
    onSignalsScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manualEdit: null,
      showDetails: false,
      alarms: false,
      drawn: false,
      animation: 'move-left',
      manualTime: '',
      manualDate: '',
      margin: 0,
      leftOffset: 0,
      minPopoverWidth: 400,
      container: {},
      containerWidth: 0,
      currentStartDate: this.startDate,
      currentEndDate: this.endDate || Math.floor(Date.now() / 1000),
      popoverWidth: 1,
      nowLeft: 0,
      internalHovered: null,
      draggingId: null,
      clipping: true,
      conflictOverlapMultiplier: 1.3,
      startEditCursorPosition: null,
      handleResize: null,
      finalSaving: false,
    };
  },
  components: {
    TimeAxis,
  },
  computed: {
    currentEditState() {
      return this.editState?.current;
    },
    offset() {
      return moment.tz(this.timezone).utcOffset() * 60;
    },
    utcOffset() {
      return moment().utcOffset() * 60;
    },
    editableLeft() {
      if (Array.isArray(this.editable)) {
        return this.editable[0] && this.activitiesEditMode;
      }
      return this.editable || this.activitiesEditMode;
    },
    editableRight() {
      if (Array.isArray(this.editable)) {
        return this.editable[1] && this.activitiesEditMode;
      }
      return this.editable || this.activitiesEditMode;
    },
    hovered() {
      return this.internalHovered || this.externalHovered;
    },
    isHoveredOnThisScheduleLog() {
      if (!this.hovered) return false;
      return !!this.log.find(l => l.id === this.hovered.id);
    },
    plannedBlock() {
      if (!this.isHoveredOnThisScheduleLog) return null;
      if (!this.hovered) return null;
      if (!this.hovered || (!this.activitiesPlannedHovering && !this.hovered.item)) return null;
      const item = this.activitiesPlannedHovering ? { ...this.hovered } : { ...this.hovered.item };
      if (
        !this.activitiesPlannedHovering
        && !item.plannedExecution?.end
        && !item.plannedExecution?.begin
      ) return null;
      if (!item.plannedExecution?.end && !item.plannedExecution?.begin) return null;
      const block = {
        id: 'p',
        index: this.hovered.index,
        start: Math.max(moment(item.scheduledExecution?.begin).unix(), this.currentStartDate),
        end: Math.min(moment(item.scheduledExecution?.end).unix(), this.currentEndDate),
      };
      const fullWidth = this.containerWidth;

      return {
        ...block,
        left: this.blockOffset(block),
        width: this.blockLength(block),
        realWidth: this.blockLength(block) * (fullWidth / 100),
        color: tinycolor(this.hovered.color).lighten(15).toString(),
        barStyle: {
          backgroundColor: tinycolor(this.hovered.color).lighten(25).toString(),
          borderColor: tinycolor(this.hovered.color).lighten(5).toString(),
        },
      };
    },
    blocks() {
      const fullWidth = this.containerWidth;
      return this.log
        .map(el => {
          let { start, end } = el;
          if (this.editState?.current && this.editState?.current.id === el.id && !this.activitiesEditMode) {
            if (this.editState?.current.handle === 'left') {
              start = this.cursorTimestamp;
            } else if (this.editState?.current.handle === 'right') {
              end = this.cursorTimestamp;
            }
          }

          if (this.activitiesEditMode && this.editState?.current && this.editState?.current.id === el.id) {
            const { start: editStart, end: editEnd } = this.editState?.current;
            start = editStart;
            end = editEnd;
          }

          return {
            ...el,
            start: Math.max(this.currentStartDate, start),
            end: Math.min(this.currentEndDate, (end || this.currentEndDate)),
          };
        })
        .map(el => ({
          ...el,
          left: this.blockOffset(el),
          width: this.blockLength(el),
        }))
        .filter(el => el.width > 0)
        .map((e, index) => ({
          ...e,
          realWidth: e.width * (fullWidth / 100),
          index,
        }));
    },
    blocksWithoutEditing() {
      const fullWidth = this.containerWidth;
      return (this.allLog.length > 0 ? this.allLog : this.log)
        .map(el => {
          const { start, end } = el;
          return {
            ...el,
            start: Math.max(this.currentStartDate, start),
            end: Math.min(this.currentEndDate, (end || this.currentEndDate)),
          };
        })
        .map(el => ({
          ...el,
          left: this.blockOffset(el),
          width: this.blockLength(el),
        }))
        .filter(el => el.width > 0)
        .map((e, index) => ({
          ...e,
          realWidth: e.width * (fullWidth / 100),
          index,
        }));
    },
    withConflicts() {
      if (!this.conflicts) {
        return this.blocks.map(b => ({ ...b, conflicts: 0 }));
      }
      return saveConflicts(this.blocksWithoutEditing);
    },
    period() {
      return this.currentEndDate - this.currentStartDate + 1;
    },
    maxConflicts() {
      if (this.log.some(l => l.static)) return this.log.length - this.log.filter(l => l.static).length;
      return this.blocks.reduce((acc, p, index) => {
        const confl = this.withConflicts[index];
        return Math.max(acc, confl.conflicts);
      }, 0);
    },
    calculatedHeight() {
      return this.height
        + (this.displayAxis ? 30 : 10)
        + (this.height / this.conflictOverlapMultiplier) * this.maxConflicts
        + (this.bigPadding ? 24 : 0)
        + (this.paddingBottom ? 12 : 0);
    },
    calculatedTimeAxisHeight() {
      return this.height
        + (this.height / this.conflictOverlapMultiplier) * this.maxConflicts
        + (this.bigPadding ? 34 : 10)
        + (this.paddingBottom ? 12 : 0);
    },
    isSomethingEdited() {
      return this.hovered || this.editState?.current;
    },
  },
  watch: {
    startDate(sd) {
      if (sd < this.currentStartDate) {
        this.animation = 'move-left';
      } else {
        this.animation = 'move-right';
      }
      this.currentStartDate = sd;
    },
    endDate(ed) {
      this.currentEndDate = ed || Math.floor(Date.now() / 1000);
    },
    internalHovered(h) {
      this.$emit('hover', h);
    },
    activitiesEditMode() {
      window.setTimeout(() => {
        this.container = this.$refs.chartWrapper.getBoundingClientRect();
        this.containerWidth = this.container.width;
      }, 100);
    },
  },
  methods: {
    ...mapActions([
      'createOrUpdateOrder',
      'createChangeover',
      'createCleaning',
    ]),
    tinycolor,
    formatDate(time) {
      if (Number.isNaN(time)) {
        return '...';
      }
      if (!this.timezone) {
        return moment(time * 1000).format('Do MMM HH:mm');
      }
      return moment((time - this.offset) * 1000).tz(this.timezone).format('Do MMM HH:mm');
    },
    tryBlurManualTime(e) {
      if (!e.relatedTarget.classList.contains('manual-input')) {
        this.cursorTimestamp = moment(`${this.manualTime} ${this.manualDate}`, 'HH:mm YYYY-MM-DD').unix();

        this.$emit('applyEdit', {
          startDate: this.manualEdit.start,
          endDate: this.cursorTimestamp,
        });
        this.manualEdit = null;
      }
    },
    handleMouseOverBlock(position) {
      if (this.isEditing) return;
      if (!position.static) {
        this.internalHovered = position;
      }
    },
    openManualEdit(e) {
      this.manualEdit = e;
      this.manualTime = moment(e.to).format('HH:mm');
      this.manualDate = moment(e.to).format('YYYY-MM-DD');

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[`manual-edit-${e.id}`][0].focus();
        }, 50);
      });
    },
    isSelected(p) {
      return p && this.selected === p.id;
    },
    isHovered(p) {
      return (this.hovered && this.hovered.id === p.id)
      || (this.editedId === p.id);
    },
    isEdited(p) {
      return p.id === this?.editState?.current?.id;
    },
    isEditMarkersVisibleForPosition(position) {
      if ([
        activityStatus.completed,
        activityStatus.started,
        activityStatus.activated,
      ].includes(position.status)) return false;
      return (this.editable || this.activitiesEditMode) && !position.static;
    },
    isRightEditMarkerVisibleForPosition(position) {
      return this.isEditMarkersVisibleForPosition(position)
          && this.getPositionEndDate(position) <= this.currentEndDate && this.editableRight;
    },
    isLeftEditMarkerVisibleForPosition(position) {
      return this.isEditMarkersVisibleForPosition(position)
        && this.getPositionStartDate(position) >= this.currentStartDate && this.editableLeft;
    },
    isDraggingMarkerVisibleForPosition(position) {
      return this.isEditMarkersVisibleForPosition(position) && !this.editDraggingDisabled;
    },
    getExactTimestamp(offset) {
      const density = this.period / this.containerWidth;
      return Math.floor(this.currentStartDate + (offset * density));
    },
    getTimestamp(offset) {
      const exactTimestamp = this.getExactTimestamp(offset);
      const date = new Date(exactTimestamp * 1000);
      if (this.clipping) {
        const tenMinutes = Math.round(date.getMinutes() / 10) * 10;
        date.setMinutes(tenMinutes, 0, 0);
      }

      return Math.max((this.startDate + 60),
        Math.min(this.endDate,
          Math.floor(date.getTime() / 1000)));
    },
    barStyle(p) {
      let bgColor = p.color || this.$color('blue');
      if (this.hasPositionIndexConflicts(p)) {
        bgColor = tinycolor('#F9414D');
      }
      if (this.activitiesEditMode) {
        bgColor = tinycolor(bgColor).lighten(5);
      }
      if (!this.isEdited(p) && !this.isHovered(p) && this.isSomethingEdited) {
        bgColor = tinycolor(this.$color('grey')).lighten(15);
      }
      return {
        ...(p.style || {}),
        'background-color': bgColor,
      };
    },
    borderColor(p) {
      if (this.hasPositionIndexConflicts(p)) {
        return {
          'border-color': tinycolor(this.$color('#F9414D')).darken(15).toString(),
        };
      }

      const color = p.color || this.$color('blue');
      return {
        'border-color': tinycolor(color).darken(15).toString(),
      };
    },
    isConflicting(index) {
      return this.withConflicts[index].conflicts > 0;
    },
    getCompletionLineColor(p) {
      let bgColor = this.$color('blue');
      if (!this.isEdited(p) && !this.isHovered(p) && this.isSomethingEdited) {
        bgColor = tinycolor(this.$color('grey')).lighten(15);
      }
      return tinycolor(bgColor).darken(15);
    },
    containerStyle(p, index) {
      const conflIndex = (index || this.withConflicts.findIndex(block => block.id === p.id));
      const confl = (conflIndex === -1 ? 0 : this.withConflicts[conflIndex]?.conflicts) ?? 0;
      const emptyLineHeight = 64;
      const topHoverOffset = 18;

      let conflictsTopOffset = ((this.overlapConflicts ? 0 : confl) * (this.height / this.conflictOverlapMultiplier));

      let otherLineTopOffset = 0;
      if (
        this.editState?.current?.id === p.id
        && this.$refs.scheduleLogWrapperRef
        && this.hoverScheduleLogIndex !== this.scheduleLogIndex
      ) {
        const wrapper = this.$refs.scheduleLogWrapperRef;
        const mainParent = wrapper.parentElement.parentElement.parentElement.parentElement;
        const rowsElements = mainParent.querySelectorAll('.timeline-container');
        if (this.scheduleLogIndex < this.hoverScheduleLogIndex) {
          conflictsTopOffset = 0;

          for (let i = this.scheduleLogIndex; i < this.hoverScheduleLogIndex; i += 1) {
            if (rowsElements[i]) otherLineTopOffset += rowsElements[i].clientHeight;
          }

          // No line top margin
          if (this.noLineActivitiesLogIndex === this.hoverScheduleLogIndex) otherLineTopOffset += 16;
        }
        if (this.scheduleLogIndex > this.hoverScheduleLogIndex) {
          conflictsTopOffset = 0;

          for (let i = this.scheduleLogIndex; i >= this.hoverScheduleLogIndex; i -= 1) {
            if (rowsElements[i]) otherLineTopOffset -= rowsElements[i].clientHeight;
            if (i === this.scheduleLogIndex) otherLineTopOffset += rowsElements[i].clientHeight - emptyLineHeight;
            if (i === this.hoverScheduleLogIndex) otherLineTopOffset += emptyLineHeight - topHoverOffset;
          }
        }
      }

      const styles = {
        width: `${Math.ceil(p.realWidth)}px`,
        top: `${conflictsTopOffset
        + (this.margin / 2)
        + (this.bigPadding ? 14 : 0)
        + otherLineTopOffset}px`,
        transition: this.draggingId === p.id ? 'none' : undefined,
      };

      const chartWidth = this.containerWidth;
      if (!chartWidth) return styles;
      const realLeft = (p.left / 100) * chartWidth;

      return {
        ...(p.barStyle || {}),
        ...styles,
        left: `${realLeft}px`,
        height: `${this.height - this.margin}px`,
        'line-height': '25px',
      };
    },
    hasPositionIndexConflicts(p, index) {
      if (!this.isEdited(p) && !this.isHovered(p) && this.isSomethingEdited) return false;
      const conflIndex = (index || this.withConflicts.findIndex(block => block.id === p.id));
      const confl = conflIndex === -1 ? 0 : this.withConflicts[conflIndex].conflicts;
      return confl > 0;
    },
    blockLength({ start, end }) {
      return Math.max((((end - start) / this.period) * 100), 0);
    },
    blockOffset({ start }) {
      return ((start - this.currentStartDate) / this.period) * 100;
    },
    checkTarget(e) {
      const el = e.target && e.target.closest && e.target.closest('.schedule-bar');
      if (!el) {
        this.internalHovered = this.editState?.current;
      }
    },
    select(id) {
      if (this.editState?.current && this.getEditMouseDragDuration() !== 0) return;
      // Handle click
      if (!this.editable || !this.editedId) {
        const clicked = this.log.find(e => !e.static && e.id === id);
        if (clicked) {
          this.$emit('edit', { ...clicked });
        }
      }
    },
    getEditMouseDragDuration() {
      return this.cursorTimestamp - this.startEditCursorPosition;
    },
    mouseDownOnActivityBlock(ev) {
      if (!this.hovered) return;
      this.editState.current = {
        id: this.hovered.id,
      };
      this.startEditCursorPosition = this.cursorTimestamp;
      this.updateBlock(ev);
    },
    async mouseUpOnActivityBlock() {
      if (!this.editState?.current) return;
      if (this.getEditMouseDragDuration() === 0) {
        this.editState.current = null;
        this.startEditCursorPosition = null;
        return;
      }

      const blockToUpdate = this.log.find(l => l.id === this.editState?.current.id);
      blockToUpdate.start = this.editState?.current.start;
      blockToUpdate.end = this.editState?.current.end;

      if (!this.simpleEditMode) {
        this.finalSaving = true;
        await this.submitUpdateActivity();

        window.setTimeout(() => {
          this.finalSaving = false;
          this.internalHovered = null;
        }, 10);
      } else {
        this.$emit('applyEdit', {
          startDate: this.editState?.current.start,
          endDate: this.editState?.current.end,
        });
      }

      if (this.getEditMouseDragDuration() === 0) {
        this.editState.current = null;
        this.startEditCursorPosition = null;
      } else {
        window.setTimeout(() => {
          this.editState.current = null;
          this.startEditCursorPosition = null;
        }, 10);
      }
    },
    updateBlock(ev) {
      if (!this.editState?.current) return;
      if (this.finalSaving) return;

      const block = this.log.find(({ id }) => id === this.editState?.current.id);
      if (!block) return;
      if ([
        activityStatus.completed,
        activityStatus.started,
        activityStatus.activated,
      ].includes(block.status)) {
        this.editState.current = null;
        return;
      }

      this.editState.current = { ...block };
      if (ev.target.classList.contains('right-marker-line-trigger')) {
        if (this.cursorTimestamp > this.editState?.current.start) {
          this.editState.current.end = this.cursorTimestamp;
        }
      }
      if (ev.target.classList.contains('left-marker-line-trigger')) {
        if (this.cursorTimestamp < this.editState?.current.end) {
          this.editState.current.start = this.cursorTimestamp;
        }
      }
      if (ev.target.classList.contains('drag-line-trigger')) {
        this.editState.current.start = block.start + this.getEditMouseDragDuration();
        this.editState.current.end = block.end + this.getEditMouseDragDuration();
      }

      setTimeout(() => {
        this.updateBlock(ev);
      }, 100);
    },
    prepareRequestDataFromEditState() {
      const keysToExtract = [
        'actualActivation',
        'actualEffort',
        'actualExecution',
        'additionalFields',
        'changeoverExpectedEffort',
        'correlationId',
        'created',
        'description',
        'designedSpeed',
        'estimatedEnd',
        'expectedEffort',
        'externalId',
        'flowId',
        'history',
        'id',
        'type',
        'lineId',
        'materialRequirementId',
        'modified',
        'orderedQuantity',
        'plannedActivation',
        'plannedExecution',
        'plantId',
        'producedQuantity',
        'productId',
        'scheduledActivation',
        'scheduledExecution',
        'status',
        'type',
        'validatedSpeed',
        'validationErrorCount',
        'wastedQuantity',
      ];
      const returnObj = { ...this.editState?.current };
      Object.keys(this.editState?.current).forEach(key => {
        if (!keysToExtract.includes(key)) delete returnObj[key];
      });
      return returnObj;
    },
    async submitUpdateActivity() {
      this.$emit('saving');

      const requestData = this.prepareRequestDataFromEditState();
      if (requestData.type === 'Order') {
        await this.createOrUpdateOrder({
          data: {
            ...requestData,
            lineId: this.rootLineIds[this.hoverScheduleLogIndex],
            plannedActivation: {},
            scheduledExecution: {
              begin: moment(this.editState?.current.start * 1000).toISOString(),
              end: this.editState?.current.end
                ? moment(this.editState?.current.end * 1000).toISOString() : null,
            },
            plannedExecution: {
              begin: moment(this.editState?.current.start * 1000).toISOString(),
              end: this.editState?.current.end
                ? moment(this.editState?.current.end * 1000).toISOString() : null,
            },
          },
        })
          .then(({ data }) => {
            this.$emit('saved', data);
          })
          .catch(({ response: { data } }) => {
            this.error = data;
          }).finally(() => { this.pending = false; });
      } else if (requestData.type === 'Changeover') {
        await this.createChangeover({
          data: {
            ...requestData,
            plannedExecution: {
              begin: moment(this.editState?.current.start * 1000).toISOString(),
              end: this.editState?.current.end
                ? moment(this.editState?.current.end * 1000).toISOString() : null,
            },
          },
        })
          .then(({ data }) => {
            this.$emit('saved', data);
          })
          .catch(({ response: { data } }) => {
            this.error = data;
          }).finally(() => { this.pending = false; });
      } else {
        await this.createCleaning({
          data: {
            ...requestData,
            plannedExecution: {
              begin: moment(this.editState?.current.start * 1000).toISOString(),
              end: this.editState?.current.end
                ? moment(this.editState?.current.end * 1000).toISOString() : null,
            },
          },
        })
          .then(({ data }) => {
            this.$emit('saved', data);
          })
          .catch(({ response: { data } }) => {
            this.error = data;
          }).finally(() => { this.pending = false; });
      }
    },
    mouseDown(ev) {
      if (this.manualEdit) return;
      const margin = 5;
      const allowedOffset = (this.period / this.containerWidth) * margin;
      const e = this.log.find(({ id }) => id === this.editedId);
      if (!e) return;
      this.draggingId = e.id;

      if (ev.target.classList.contains('right-marker-date')) {
        this.openManualEdit(e);
        return;
      }

      const left = ev.pageX - this.container.left;
      const timestamp = this.getExactTimestamp(left);
      const dateEditPopup = this.$refs[`dateEditPopup${e.id}`][0];

      // Left handle
      if (Math.abs(e.start - timestamp) < allowedOffset && this.editableLeft) {
        dateEditPopup.style.display = 'block';
        dateEditPopup.style.left = '0px';

        this.editState.current = {
          id: e.id,
          handle: 'left',
          timestamp,
          limit: e.limit,
          start: e.start,
          end: e.end,
        };
        this.$nextTick(() => {
          this.updateEdited();
        });
        return;
      }

      // Right handle
      if (Math.abs(e.end - timestamp) < allowedOffset && this.editableRight) {
        dateEditPopup.style.display = 'block';
        dateEditPopup.style.right = '0px';

        this.editState.current = {
          id: e.id,
          handle: 'right',
          timestamp,
          limit: e.limit,
          start: e.start,
          end: e.end,
        };
        this.$nextTick(() => {
          this.updateEdited();
        });
      }
    },
    mouseUp() {
      this.draggingId = null;
      if (!this.editable) return;
      if (this.editState?.current) {
        const dateEditPopup = this.$refs[`dateEditPopup${this.editedId}`][0];
        dateEditPopup.style.display = 'none';
        dateEditPopup.style.right = 'auto';
        dateEditPopup.style.left = 'auto';

        if (this.editState?.current.handle === 'left') {
          this.$emit('applyEdit', {
            startDate: this.cursorTimestamp,
            endDate: this.editState?.current.end,
          });
        } else if (this.editState?.current.handle === 'right') {
          this.$emit('applyEdit', {
            startDate: this.editState?.current.start,
            endDate: this.cursorTimestamp,
          });
        } else {
          this.$emit('unselect');
          this.editState.current = null;
        }
      }
      this.editState.current = null;
    },
    updateEdited() {
      if (!this.editedId || !this.editState?.current) return;
      const timestamp = this.cursorTimestamp;

      const refs = this.$refs[`block${this.editedId}`];
      if (refs.length === 0 || !refs[0]) return;
      const ref = refs[0];
      const chartWidth = this.containerWidth;

      const block = {
        start: this.editState?.current.start,
        end: this.editState?.current.end,
      };
      if (this.editState?.current.handle === 'left') {
        block.start = timestamp;
      } else if (this.editState?.current.handle === 'right') {
        block.end = timestamp;
      }

      this.$refs[`dateEditPopup${this.editedId}`][0].innerHTML = this.formatDate(timestamp);

      const left = this.blockOffset(block);
      const width = this.blockLength(block);

      const realLeft = (left / 100) * chartWidth;
      const realWidth = (width * chartWidth) / 100;
      ref.style.transform = `translateX(${realLeft}px)`;
      ref.style.width = `${realWidth}px`;
    },
    keyDown(e) {
      // alt
      if (e.keyCode === 18) {
        this.clipping = false;
      }
    },
    keyUp(e) {
      if (e.keyCode === 18) {
        this.clipping = true;
      }
    },
    calculateCompletionLineWidthForBlock(block) {
      if (block.status === activityStatus.started) {
        let completionPercentage = 0;

        if (block.type === activityType.changeover) {
          completionPercentage = this.calculateCompletionPercentageForChangeoverBlock(block);
        }

        if (block.type === activityType.order) {
          completionPercentage = this.calculateCompletionPercentageForOrderBlock(block);
        }

        if (completionPercentage > 1) completionPercentage = 1;
        return `${(completionPercentage) * 100}%`;
      }
      return 0;
    },
    calculateCompletionPercentageForChangeoverBlock(block) {
      const changeoverDuration = moment(block.plannedExecution.end).unix()
        - moment(block.plannedExecution.begin).unix();
      const passedDuration = this.$now - moment(block.actualExecution.begin).unix();
      return passedDuration / changeoverDuration;
    },
    calculateCompletionPercentageForOrderBlock(block) {
      const { value: totalQuantity } = block.orderedQuantity;
      const { value: producedQuantity } = block.producedQuantity;
      return producedQuantity / totalQuantity;
    },
    preparePositionId(id) {
      return id.replace(/[^a-zA-Z0-9]/g, '');
    },
    getPositionStartDate(position) {
      if (this.isEdited(position)) {
        return this.editState?.current.start;
      }
      if (position?.actualExecution?.begin) {
        return moment(position.actualExecution.begin).unix();
      }
      if (position?.scheduledExecution?.begin) {
        return moment(position.scheduledExecution?.begin).unix();
      }
      return position.plannedExecution ? moment(position.plannedExecution.begin).unix() : position.start;
    },
    getPositionEndDate(position) {
      if (this.isEdited(position)) {
        return this.editState?.current.end;
      }
      if (position?.actualExecution?.end) {
        return moment(position.actualExecution?.end).unix();
      }
      if (position?.actualExecution?.begin && !position.actualExecution?.end) {
        const blockDuration = moment(position.plannedExecution.end).unix()
          - moment(position.plannedExecution.start).unix();
        const endDate = moment(position.actualExecution.start).unix() + blockDuration;
        return Math.max(endDate, this.$now);
      }
      if (position?.scheduledExecution?.end) {
        return moment(position.scheduledExecution.end).unix();
      }
      return position.plannedExecution ? moment(position.plannedExecution.end).unix() : position.end;
    },
  },
  created() {
    this.currentStartDate = this.startDate;
    this.currentEndDate = this.endDate || Math.floor(Date.now() / 1000);

    if (this.editedId) {
      this.editState.current = this.log.find(l => l.id === this.editedId);
    }

    this.mouseMove = e => {
      this.checkTarget(e);
      const left = e.pageX - this.container.left;
      this.cursorTimestamp = this.getTimestamp(left);

      if (!this.editState?.current) return;
      if (this.editState?.current.limit) {
        this.cursorTimestamp = Math.max(this.editState?.current.limit[0], this.cursorTimestamp);
        this.cursorTimestamp = Math.min(this.editState?.current.limit[1], this.cursorTimestamp);
      }

      this.updateEdited();
    };
    document.addEventListener('mousemove', this.mouseMove);
  },
  mounted() {
    this.handleResize = () => {
      this.container = this.$refs.chartWrapper.getBoundingClientRect();
      this.containerWidth = this.container.width;
    };
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'mouseup',
      ev => (this.activitiesEditMode ? this.mouseUpOnActivityBlock(ev) : this.mouseUp(ev)),
    );
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
  },
  destroyed() {
    window.removeEventListener(
      'mouseup',
      ev => (this.activitiesEditMode ? this.mouseUpOnActivityBlock(ev) : this.mouseUp(ev)),
    );
    document.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .diagram-title {
    position: absolute;
    width: 100%;
    top: -20px;
    line-height: 42px;
  }

  .marker {
    border-left: 1px solid rgba(#333, 0.5);
    height: 100%;
    width: 3px;
    top: 0;
  }

  .grid-line {
    border-left: 1px solid rgba(100, 100, 100, 0.5);
  }

  .disconnected-icon {
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 10px;
  }

  .move-right-enter, .move-left-leave-to {
    transform: translateX(10px) !important;
    opacity: 0;
  }
  .move-right-leave-to, .move-left-enter {
    transform: translateX(-10px) !important;
    opacity: 0;
  }

  .popover-content {
    white-space: normal;
    max-width: 100%;
    width: 100%;
    padding: 5px 15px;
  }

  .worklog-wrapper {
    position: relative;
    height: 100%;
    user-select: none;
  }

  .downtimes-progress {
    height: 100%;
    position: relative;
    border-radius: 0;
    background-color: rgba(200, 200, 200, 0);
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    margin-top: -1px;
  }
  .progress-bar {
    position: absolute;
    left: 0;
    background-color: transparent;
    transition: width 200ms, left 200ms;

    .header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .progress {
    overflow: visible;
    z-index: 2;
  }

  .conflict-icon {
    text-shadow: 0 0 5px rgba(100, 100, 100, 0.6);
    font-size: 18px;
    position: relative;
    top: 2px;
  }

  .schedule-bar {
    transition: all 300ms;
    left: 0;
    z-index: 90;
    cursor: pointer;

    &.static {
      cursor: default;
      height: calc(100% - 25px) !important;
      z-index: 0;
    }

    &.edited {
      cursor: default;
      //transition: unset !important;
    }

    .header {
      transition: all 300ms;
      padding: 2px;
      font-weight: 500;
      border-radius: 4px;

      .header-completion-line {
        width: 100%;
        border-radius: 4px 4px 0 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
        overflow: hidden;

        &--inner {
          height: 100%;
        }
      }
    }

    &.edited {
      z-index: 104 !important;
    }

    &.hovered, &.edited {
      overflow: visible;
      z-index: 100;

      .header {
        border-color: transparent;
        transform: translateY(-4px);
      }
    }

    &.selected {
      border: 0px solid transparent;

      border-radius: 20px;
      min-width: 400px;
      transform: translateY(-20px) scale(1);
      z-index: 100;
      box-shadow: 0 0 20px rgba(#333, 0.8),  0 0 120px rgba(#333,0.4);
      background-color: #4d4d4d;

      .header {
        border-radius: 10px;
        box-shadow: none;
      }

      .bar-title {
        display: inline-block;
        font-size: 16px;
        padding-right: 25px;
        padding-left: 25px;
        white-space: initial;
      }
    }
  }

  .planned-bar {
    border-radius: 3px;
    transform: translateY(2px);
    border-width: 2px;
    border-style: dashed;

    .header {
      .planned-info {
        color: rgba(100, 100, 100, 0.5);
        text-shadow: 0 0 10px rgba(#333, 0.8);
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .progress-bar {
    &:hover, &.edited {
      .block-marker {
        .left-marker-line, .right-marker-line {
          opacity: 1;
        }
      }
    }

    .block-marker {
      font-size: 9px;
      font-weight: bold;
      color: #333;
      line-height: 1;
      display: none;

      > div {
        position: absolute;
      }

      .left-marker, .right-marker, .center-marker {
        background-color: white;
        padding-bottom: 1px;
        padding-top: 1px;
        top: -14px;
      }

      .left-marker {
        left: 0;
        transform: translateX(-50%);
        .end {
          display: none;
        }
      }
      .right-marker {
        right: 0;
        transform: translateX(50%);
      }
      .center-marker {
        left: 50%;
        transform: translateX(-50%);
      }

      .left-marker-line, .right-marker-line {
        height: 100%;
        top: 0;
        width: 10px;
        z-index: 101;
        cursor: ew-resize;
        border-style: solid;
        border-width: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left-marker-line {
        left: -2.5px;
        border-radius: 4px 0 0 4px;
      }

      .right-marker-line {
        right: -2.5px;
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .edit-date {
    background-color: white;
    box-shadow: 0 2px 4px #ddd;
    border-radius: 5px;
    padding: 5px;
    transform: translate(50%, -50%);
    top: -17px;
    font-size: 1.18rem;
  }

  .edited {
    .editable.left-marker-line,
    .editable.right-marker-line {
      cursor: w-resize;
    }

    .editable.left-marker,
    .editable.right-marker {
      background-color: white;
      box-shadow: 0 2px 4px #ddd;
      border-radius: 5px;
      font-size: 1rem;
      top: -17px;
      padding: 5px;
    }

    .editable.right-marker {
      transform: translate(50%, -50%);

      &:hover {
        background-color: #eee;
      }
      &.manual {
        background-color: #fff;
        top: -25px;
      }
    }

    .editable.left-marker {
      transform: translate(-50%, -50%);
    }
  }

  .manual-edit-form {
    border: 0px;
    padding: 5px 10px;
  }

  .right-marker {
    input[type="time"]::-webkit-calendar-picker-indicator {
        display: none;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }
  }

  .static {
    .block-marker {
      display: block;
    }
  }

  .hovered, .progress-bar.edited {
    .block-marker {
      display: block;
    }
  }

  .drag-line-trigger {
    cursor: grab;
  }

</style>
