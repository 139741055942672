<template>
  <div class="calendar-filters">
    <div
      class="pb-1"
      style="margin-left:-3px"
    >
      <small><i class="fas fa-filter" /></small>
      <h5
        class="d-inline"
        style="font-size: 18px;"
      >
        {{ $t('general.filters') }}
      </h5>
    </div>

    <div
      v-for="(filter,index) in filters"
      :key="`filter_${index}`"
      style="font-size: 12px;"
    >
      <div
        style="cursor: pointer;"
        class="pt-1 pb-1 mt-2 form-checkbox"
        @click="toggleCollapse(`filter_${index}`)"
      >
        <div class="d-inline pr-1">
          <i
            v-if="collapse[`filter_${index}`]"
            class="ion ion-md-arrow-dropdown"
          />
          <i
            v-else
            class="ion ion-md-arrow-dropright"
          />
        </div>
        <div class="d-inline font-weight-bold">
          {{ filter.text }}
        </div>
      </div>

      <BFormCheckboxGroup
        v-model="filterModel[filter.value || filter.text]"
        stacked
      >
        <Transition name="slide-bottom">
          <div v-if="collapse[`filter_${index}`]">
            <BFormCheckbox
              v-for="(option,optionIndex) in filter.options"
              :key="`radio_${optionIndex}`"
              class="form-checkbox ml-3"
              :value="option.value"
              @change="$emit('filtersUpdate', filterModel);"
            >
              <div
                class="pt-1"
                style="cursor: pointer;"
              >
                {{ option.text }}
              </div>
            </BFormCheckbox>
          </div>
        </Transition>
      </BFormCheckboxGroup>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: Array,
    initFilters: Object,
  },
  data() {
    return {
      filterModel: {},
      collapse: {},
    };
  },
  watch: {
    initFilters(v) {
      this.filterModel = { ...v };
    },
  },
  methods: {
    toggleCollapse(filterName) {
      this.$set(this.collapse, filterName, !this.collapse[filterName]);
    },
    setTrueCollapse() {
      this.filters.forEach((filter, index) => {
        this.$set(this.collapse, `filter_${index}`, true);
      });
    },
  },
  created() {
    if (this.initFilters) {
      this.filterModel = { ...this.initFilters };
    }
  },
  mounted() {
    this.setTrueCollapse();
  },
};
</script>

<style scoped>
.form-checkbox:hover {
  background-color: #DDDDDD;
  user-select: none;
}
</style>
