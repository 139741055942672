export default {
  nothing: '没有通知',
  list: '列表',
  subscribedOnly: '仅订阅',
  subscription: '订阅',
  addSubscription: '添加订阅',
  notificationType: '通知类型',
  pushNotSupported: '此环境不支持通知',
  pushDenied: '通知已被阻止',
  enablePush: '启用推送通知',
  pushDisabled: '推送通知已禁用',
  pushEnabled: '推送通知已启用',
  outdated: '从开始过时',
  wastedThreshold: '浪费阈值',
  threshold: '临界点',
  mailing: '通过电子邮件接收',
  mySubscriptions: '我的订阅',
  usersSubscriptions: '用户订阅',
  teamsSubscriptions: '团队订阅',
  numberOfSubs: '订阅数',
  subscriptionFromTeam: '此订阅来自团队，只能由具有适当权限的用户对整个团队进行编辑',
  type: { hints: '提示', warnings: '警告', userNotifications: '通知' },
  name: {
    cmmsNotification: '维修保养服务', connectionLost: '机器连接丢失', availabilityInsight: '可用性洞察', ongoingStop: '产线停止中', newDowntime: '新的停机时间', orderNotifications: '订单状态变化', thresholdExceeded: '超出阈值', oeeThreshold: 'OEE低于目标', orderProductionDone: '订单生产实现', orderProductionNotAchieved: '订单关闭，产量低于订单', orderDelayed: '订单延迟', performanceThreshold: '机器性能低于预期', orderWastedThresholdExceeded: '超出订单浪费阈值', selectedLines: '选定的产线', allLines: '所有产线',
  },
  timePeriod: {
    none: '无', immediately: '立即地', hourly: '在每小时通知摘要中', daily: '在每日通知摘要中',
  },
};
