import Vapi from 'vuex-rest-api';
import { workBoost as axios } from '@/api/axios';

const store = new Vapi({
  axios,
})
  .get({
    action: 'getTenantProjects',
    path: ({ tenantId }) => `tenant/${tenantId}/projects`,
  })
  .get({
    action: 'getCategoryQuests',
    path: ({ tenantId, category }) => `tenant/${tenantId}/quests/${category}`,
  })
  .getStore();

export default store;
