/* eslint-disable max-len */
export default {
  nothing: 'Brak powiadomień',
  list: 'Lista',
  subscribedOnly: 'Tylko obserwowane',
  subscription: 'Subskrypcja',
  addSubscription: 'Dodaj subskrybcje',
  notificationType: 'Typ notyfikacji',
  pushNotSupported: 'Powiadomienia nie są wspierane na tym środowisku',
  pushDenied: 'Powiadomienia zostały zablokowane',
  enablePush: 'Włącz powiadomienia push',
  pushDisabled: 'Powiadomienia push zablokowane',
  pushEnabled: 'Powiadomienia włączone',
  outdated: 'Nieaktualne od',
  wastedThreshold: 'Próg odrzutów',
  threshold: 'Próg',
  mailing: 'Otrzymuj w mailu',
  mySubscriptions: 'Moje subskrypcje',
  usersSubscriptions: 'Subskrypcje użytkowników',
  teamsSubscriptions: 'Subskrypcje zespołów',
  numberOfSubs: 'Liczba subskrypcji',
  subscriptionFromTeam: 'Ta subskrypcja pochodzi od zespołu i może być edytowana tylko w kontekście całego zespołu przez uprawnioną osobę',
  type: {
    hints: 'Wskazówki',
    warnings: 'Ostrzeżenia',
    userNotifications: 'Powiadomienia',
  },
  name: {
    cmmsNotification: 'Wezwanie serwisowe',
    connectionLost: 'Utracenie połączenia z maszyną',
    availabilityInsight: 'Wskazówki dotyczące dostępności',
    ongoingStop: 'Ciągły przestój na linii',
    newDowntime: 'Nowy przestój',
    orderNotifications: 'Zmiana statusu zlecenia',
    thresholdExceeded: 'Wartość progowa sygnału przekroczona',
    oeeThreshold: 'OEE poniżej targetu',
    orderProductionDone: 'Wyprodukowanie zamówionej ilości na zleceniu',
    orderProductionNotAchieved: 'Zakończenie zlecenia przy nieosiągniętej produkcji',
    orderDelayed: 'Opóźnienie się zlecenia',
    performanceThreshold: 'Praca maszyny z wydajnością mniejsza niż zakładana',
    orderWastedThresholdExceeded: 'Przekroczenie progu odrzutów na zleceniu',
    selectedLines: 'Wybrane Linie',
    allLines: 'Wszystkie Linie',
    ignoredCounter: 'Zignorowane liczniki',
    oeeInfo: 'Podsumowanie OEE',
    oeeSummaryMail: 'Podsumowanie OEE - raport email',
  },
  timePeriod: {
    none: 'Nie',
    immediately: 'Natychmiast',
    hourly: 'W godzinnym podsumowaniu notyfikacji',
    daily: 'W dziennym podsumowaniu notyfikacji',
    everyEndOfShift: 'Z każdym końcem zmiany',
    everyDay: 'Każdego dnia',
    everyWeek: 'Każdego tygodnia',
  },
};
