var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"position-relative my-2 text-center",style:({
    height: `${_vm.height}px`,
  })},[_vm._l((_vm.elements),function(e){return _c('div',{key:e.id,staticClass:"box position-absolute",class:{
      toggle: _vm.toggle,
      on: _vm.toggle && _vm.isOn(e.id),
      warn: _vm.toggle && _vm.isDisabled(e.id),
      running: _vm.toggle && _vm.isRunning(e.id),
    },style:({
      width: `${e.w}px`,
      height: `${e.h}px`,
      top: `${e.y}px`,
      left: `${e.x}px`
    }),on:{"click":function($event){return _vm.toggleElement(e.id)}}},[_c('div',{staticClass:"icon"},[(_vm.toggle)?_c('i',{class:_vm.getIcon(e.id)}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column justify-content-center h-100 position-relative",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.nameFun ? _vm.nameFun(e) : e.name)+" ")]),_c('div',{staticClass:"text-center text-success w-100",staticStyle:{"position":"absolute","bottom":"0","line-height":"1"}},[(_vm.isRunning(e.elementId))?_c('i',{staticClass:"ion ion-md-pulse"}):_vm._e()])])])}),_c('Connections',{attrs:{"paths":_vm.paths,"connection-type":_vm.connectionType}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }