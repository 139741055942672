export default {
  'orders': 'Orders',
  'order': 'Order',
  'import': 'Importeer orders',
  'completedOrders': 'Afgeronde orders',
  'inProgressOrders': 'Order in uitvoering',
  'start': 'Start',
  'saveAndActivate': 'Opslaan en activeer',
  'orderDetails': 'Order details',
  'activate': 'Activeer',
  'stop': 'Stop',
  'end': 'Einde',
  'show': 'Toon order',
  'startOrder': 'Start order',
  'activateOrder': 'Activeer order',
  'abortOrderActivate': 'Breek activiatie af',
  'selectOrder': 'Selecteer order',
  'selectLineAndSku': 'Selecteer lijn en SKU',
  'skuPerformance': 'SKU prestatie',
  'abort': 'Afbreken',
  'status': 'Status',
  'number': 'Nummer',
  'orderNumber': 'Ordernummer',
  'skuId': 'SKU ID',
  'orderId': 'Order ID',
  'item': 'Artikel',
  'sku': 'SKU',
  'itemNo': 'Artikel nummer',
  'schedule': 'Planning',
  'scheduled': 'Gepland',
  'plannedProduction': 'Geplande productie',
  'realisation': 'Realisatie',
  'expectedStart': 'Verwachte start',
  'expectedEnd': 'Verwacht einde',
  'toPlannedEnd': 'tot gepland eind',
  'toPlannedStart': 'tot geplande start',
  'afterPlannedEnd': 'na gepland eind',
  'afterPlannedStart': 'na geplande start',
  'expectedPerformance': 'Verwachte prestaties',
  'description': 'Omschrijving',
  'quantity': 'Geproduceerd',
  'limitExceeded': 'Limiet van orders overschreven, stop of rond alle huidige orders af',
  'production': 'Productie samenvatting',
  'wasted': 'Afval',
  'productionUpdates': 'Productie updates',
  'complete': 'Afgerond',
  'hold': 'Wacht',
  'completed': 'Afgerond',
  'planned': 'Geplande',
  'lineConfig': 'Lijn configuratie',
  'config': 'Configuratie',
  'additional': 'Aanvullend',
  'machinesForOrder': 'Selecteer machines voor deze taak',
  'requiredComponentsNotScanned': 'Sommige componenten moeten eerst worden gescand',
  'firstActivate': 'Sommige componenten zijn benodigd voor de start. Activeer de order en scan de componenten',
  'productionStats': 'Productie',
  'comment': {
    'startOrder': 'De order zal onmiddelijk worden gestart. Alle gescande dozen zullen worden geactiveerd.',
    'completeOrder': 'De order zal worden afgerond en kan niet opnieuw worden gestart',
    'stopOrder': 'De order zal worden gestopt en kan op een later moment opnieuw worden gestart',
    'abortOrderActivate': 'Activatie wordt gestopt, order status zal terug veranderen naar \'Gereed\'',
    'activateOrder': 'De order wordt klaargemaakt om te beginnen, flow kan nog worden aangepast en een aantal doosjes dienen gescand te worden voordat er begonnen wordt'
  },
  'states': {
    'inProgress': 'In uitvoering',
    'ready': 'Wachten',
    'completed': 'Afgerond',
    'other': 'Overig',
    'activated': 'Geactiveerd',
    'onHold': 'In de wacht',
    'taken': 'Gekozen'
  },
  'activities': {
    'addActivity': 'Plan nieuwe activiteit',
    'editActivity': 'Activiteit aanpassen',
    'changeover': 'Ombouwen',
    'order': 'Order',
    'cip': 'CIP',
    'completedJob': 'Afgeronde taak',
    'inProgressJob': 'Taak in uitvoering',
    'stop': 'Gestopt door operator',
    'failure': 'Storing',
    'lack': 'Te weinig componenten en product',
    'idle': 'Inactief',
    'holdup': 'Vertraging',
    'inactive': 'Inactief'
  },
  'add': 'Order toevoegen',
  'create': 'Maak order',
  'new': 'Nieuwe order',
  'edit': 'Order aanpassen',
  'orderedQuantity': 'Gepland aantal',
  'typeCorrectQuantity': 'Gepland aantal moet groter zijn dan nul',
  'quantityUnit': 'Aantal eenheid',
  'type': 'Type',
  'loadingItems': 'Artikelen laden..',
  'manage': 'Beheer orders',
  'noActiveOrPlannedOrders': 'Geen actieve of geplande orders',
  'noOrders': 'Geen orders',
  'orderStarted': 'Order gestart',
  'manualProductionAdded': 'Manuele productie toegevoegd',
  'manualProductionOverwritten': 'Manuele productie overschreven',
  'removeLastEntryFirst': 'Verwijder laatste productie gegevens, voordat je een nieuwe order start',
  'reportResources': 'Report resources',
  'confirmCurrentProduction': 'Bevestig productie',
  'selectSku': 'Selecteer SKU',
  'skuDescription': 'SKU omschrijving',
  'skuNumber': 'SKU nummer',
  'updateProduction': 'Update productie',
  'totalProduction': 'Totaal',
  'incrementalProduction': 'Incrementeel',
  'settlement': 'Verrekening',
  'noProducts': 'Geen SKU',
  'productionEnteredManually': 'Productie en afval handmatig bevestigd',
  'release': 'Vrijgegeven',
  'take': 'Nemen',
  'finishOrder': 'Sluit order af',
  'product': 'Product',
  'plannedQuantity': 'Geplande aantallen',
  'orderHistory': 'Order geschiedenis',
  'defaultConfiguration': 'Standaard configuratie'
};
