import Vapi from 'vuex-rest-api';
import moment from 'moment';
import axios from '@/api/axios';
import colors from '@/utils/colors';
import lang from '@/lang';
import { activityStatus, activityType } from '@/utils/dictionary';

const activities = new Vapi({
  axios,
  state: {
    activities: [],
    filterDates: {
      startDate: moment()
        .startOf('day')
        .format(),
      endDate: moment()
        .endOf('day')
        .format(),
      scope: 'day',
    },
  },
})
  .get({
    action: 'getActivity',
    path: ({ activityId }) => `v3/activities/${activityId}`,
  })
  .get({
    action: 'getActivities',
    property: 'activities',
    path: 'v3/activities',
    onSuccess: (s, { data }, _, { params }) => {
      if (!params.query || !params.query.correlationId) {
        s.items = data;
      }
    },
  })
  .get({
    action: 'getActivitiesBetween',
    property: 'activities',
    path: () => '/v3/activities/between',
  })
  .get({
    action: 'getActivitiesUnplanned',
    path: () => '/v3/activities/unplanned',
  })
  .get({
    action: 'getActivityManualResources',
    path: ({ plantId, activityId }) => `/v2/plant/${plantId}/activity/${activityId}/manualResources`,
  })
  .post({
    action: 'addOrderManualProduction',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/manualProduction`,
  })
  .delete({
    action: 'removeOrderManualProduction',
    path: ({ plantId, orderId }) => `/v2/plant/${plantId}/order/${orderId}/manualProduction`,
  })
  .post({
    action: 'addManualManhours',
    path: ({ plantId, activityId }) => `/v2/plant/${plantId}/activity/${activityId}/humanResources`,
  })
  .delete({
    action: 'removeManualResource',
    path: ({
      plantId, activityId,
    }) => `/v2/plant/${plantId}/activity/${activityId}/manualResources`,
  })
  .getStore();

activities.getters = {
  filterDates: s => s.filterDates,
  getActivityState: () => ({ status }) => {
    switch ((status || '')) {
      case 'Released':
        return {
          color: colors('orange'),
          desc: lang.t('activities.status.released'),
          name: status,
        };
      case 'Started':
        return {
          color: colors('blue'),
          desc: lang.t('activities.status.started'),
          name: status,
        };
      case 'Completed':
        return {
          color: colors('green'),
          desc: lang.t('activities.status.completed'),
          name: status,
        };
      case 'Suspended':
        return {
          color: colors('grey'),
          desc: lang.t('activities.status.suspended'),
          name: status,
        };
      case 'Activated':
        return {
          color: colors('blue_pastel'),
          desc: lang.t('activities.status.activated'),
          name: status,
        };
      case 'Draft':
        return {
          color: colors('grey'),
          desc: lang.t('activities.status.draft'),
          name: status,
        };
      default:
        return {
          color: colors('grey'),
          desc: lang.t('orders.states.other'),
          name: status,
        };
    }
  },
  getActivityType: () => ({ type }) => {
    switch ((type || '')) {
      case activityType.order:
        return {
          icon: 'fas fa-tags',
          color: colors('orange'),
          desc: lang.t('activities.order'),
          name: type,
        };
      case activityType.cleaning:
        return {
          icon: 'fas fa-shower',
          desc: lang.t('activities.cleaning'),
          name: type,
        };
      case activityType.changeover:
        return {
          icon: 'fas fa-wrench',
          desc: lang.t('activities.changeover'),
          name: type,
        };
      default:
        return {
          icon: 'ion ion-md-help',
          desc: lang.t('orders.states.other'),
          name: type,
        };
    }
  },
  activityToScheduleLog: (s, g, rs, rg) => (list, startDate, endDate, now, withActivation) => {
    if (!list) return [];
    const start = startDate;
    const end = endDate || now;

    const result = list.map(o => {
      const activated = o.actualActivation?.begin;
      return { ...o, activated: activated ? moment(activated).unix() : null };
    }).map(o => ({
      start: o.status === activityStatus.activated
        ? moment.unix(Math.max(start, o.activated)).startOf('minute').unix()
        : moment.unix(Math.max(
          start,
          moment(o.actualExecution?.begin || o.scheduledExecution?.begin).unix(),
        )).startOf('minute').unix(),
      end: o.status === activityStatus.started || o.status === activityStatus.activated ? now
        : moment.unix(Math.min(
          (o.actualExecution?.end ? moment(o.actualExecution?.end).unix() : end),
          end,
        )).startOf('minute').unix(),
      color: g.getActivityState(o).color,
      icon: g.getActivityState(o).icon,
      type: rg.activityTypes.ZPROD.name,
      name: o.externalId,
      id: `order-${o.id}`,
      item: {
        ...o,
      },
    }));

    if (withActivation) {
      const activations = result.flatMap(activity => {
        if (activity.item.activated && activity.item.status !== activityStatus.activated) {
          const state = g.getOrderState({ status: activityStatus.activated });

          const activation = {
            ...activity,
            start: activity.item.activated,
            color: state ? state.color : colors('grey'),
            end: activity.start,
            id: `${activity.id}_activation`,
            name: lang.t('orders.states.activated'),
            icon: state.icon,
            item: {
              ...activity.item,
              id: `${activity.id}_activation`,
              status: activityStatus.activated,
            },
          };
          return [activation];
        }
        return [];
      });
      return result.concat(activations);
    }

    return result;
  },

};

activities.mutations.setFilterDates = (s, v) => { s.filterDates = v; };

export const activityResourceType = {
  production: 'production',
  waste: 'waste',
};

export default activities;
