<template>
  <div>
    <Apexcharts
      v-click-outside="handleClickOutside"
      :options="options"
      :series="series"
      :height="height"
      type="bar"
      style="margin-left: 0px; margin-top: -10px; width: 100%"
      @click="handleChartClick"
    />

    <div
      v-show="selectedWorklog"
      ref="tooltip"
      role="tooltip"
      class="tracking-tooltip"
    >
      <BButton
        variant="outline-secondary"
        block
        @click.stop.prevent="track"
      >
        <i class="fas fa-history mr-1" />
        {{ $t('reports.downtimeTracker.workspectrumForThisSelection') }}
      </BButton>
    </div>
  </div>
</template>

<script>
import Apexcharts from 'vue-apexcharts';
import calcDuration from '@/utils/calcDuration';

export default {
  props: {
    startDate: Number,
    endDate: Number,
    height: [Number, String],
    series: Array,
    colors: Array,
    categories: Array,
    resolution: String,
    stackType: {
      type: String,
      default: '100%',
    },
    data: Array,
    worklogs: Array,
    xLabels: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedWorklog: null,
  }),
  components: {
    Apexcharts,
  },
  computed: {
    format() {
      switch (this.resolution) {
        case 'day':
          return 'DD MMM';
        case 'week':
          return 'ww';
        default:
          return 'HH:00';
      }
    },
    options() {
      return {
        yaxis: {
          max: 100,
        },
        chart: {
          height: 350,
          type: 'bar',
          stacked: true,
          stackType: this.stackType,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        colors: this.colors,
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        plotOptions: {
          bar: {
            columnWidth: '90%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: !this.categories ? []
            : this.categories
              .map(el => el.format(this.format)),
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            show: this.xLabels.length > 0,
            formatter: (_, opts) => this.xLabels[opts.dataPointIndex % this.xLabels.length],
          },
          y: {
            formatter: (s, op) => {
              const res = `${s}%`;
              const series = this.series[op.seriesIndex];
              if (!series || !series.timeValues) return res;
              const duration = series.timeValues[op.dataPointIndex];
              if (!duration) return res;
              return `${res} - ${calcDuration(1, duration + 1)}`;
            },
          },
        },
        legend: {
          showForZeroSeries: false,
          showForSingleSeries: true,
          showForNullSeries: false,
          horizontalAlign: 'center',
          position: 'top',
          offsetY: 20,
        },
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.selectedWorklog = null;
    },
    handleChartClick() {
      // handleChartClick(e, a, b) {

      // TODO TRACKING
      // https://app.shortcut.com/ilabo/story/21671/możliwość-przechodzenia-z-
      // innych-miejsc-w-systemie-do-raportu-trackera#activity-22100

      // const worklogs = this.topLevelFilteredWorklogs || this.worklogs;
      // const clickedWorklog = worklogs[b.dataPointIndex];
      // if (!clickedWorklog) return;
      //
      // const popoverTarget = this.$refs.chart.$el.querySelectorAll('path')[b.dataPointIndex];
      // this.selectedWorklog = clickedWorklog;
      //
      // setTimeout(() => {
      //   createPopper(popoverTarget, this.$refs.tooltip, {
      //     placement: 'right',
      //   });
      // }, 5);
    },
    track() {
    },
  },
};
</script>

<style>

</style>
