export default {
  'moveHereToGroup': 'verschiebe Spalte hierher zum gruppieren',
  'moveHereToHide': 'verschiebe Spalte hierher zum ausblenden',
  'sEmptyTable': 'keine Daten in der Tabelle verfügbar',
  'sInfoFiltered': '(gefiltert aus _MAX_ Gesamteinträgen)',
  'sLoadingRecords': 'lädt…',
  'sProcessing': 'in Bearbeitung…',
  'sSearch': 'Suche:',
  'sZeroRecords': 'keine übereinstimmenden Aufzeichnungen gefunden',
  'oPaginate': {
    'sFirst': 'Erster',
    'sLast': 'Letzter',
    'sNext': 'Nächster',
    'sPrevious': 'Vorheriger'
  },
  'oAria': {
    'sSortAscending': 'aktivieren, um die Spalte aufsteigend zu sortieren',
    'sSortDescending': 'aktivieren, um die Spalte absteigend zu sortieren'
  },
  'downloadAsXls': 'Download als Excel'
};
