<template>
  <div
    ref="container"
    :style="{
      height: `${height + 6}px`,
      width: `${graphWidth + 6}px`,
    }"
    class="position-relative my-2 text-center minimap"
    @mousedown="mouseDown"
  >
    <div
      v-for="e in elements"
      :key="e.id"
      :style="{
        width: `${e.w}px`,
        height: `${e.h}px`,
        'line-height': `${e.h}px`,
        top: `${e.y + 3}px`,
        left: `${e.x + 3}px`,
        'border-color': colors[e.id]
      }"
      class="box position-absolute"
    >
      {{ names[e.id] }}
    </div>

    <div
      :style="{
        width: `${scrollWidth * graphWidth}px`,
        left: `${scrollLeft * graphWidth + (isMobile && 3)}px`
      }"
      class="scroller"
    />

    <Connections
      :paths="paths"
      :connection-type="connectionType"
      style="padding-top: 3px"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Graph from '@/components/graph/Graph';
import Connections from '@/components/graph/Connections';
import { elementType } from '@/utils/dictionary';
import { graphHeight, graphWidth, nodePositions } from './layout';

export default {
  props: {
    flow: {
      type: Graph,
      required: true,
    },
    scrollLeft: Number,
    scrollWidth: Number,
    scrollTop: Number,
    scrollHeight: Number,
    colors: Object,
    names: Object,
    elementId: String,
    connectionType: {
      type: String,
      default: 'round',
    },
    yAxis: Boolean,
  },
  data: () => ({
    size: 20,
    isDown: false,
    elementTypeEnum: elementType,
  }),
  components: {
    Connections,
  },
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('element', [
      'elementType',
    ]),
    ...mapGetters('plant', [
      'plantProperty',
      'structure',
      'flowLayout',
    ]),
    nodes() {
      return this.flow.nodeList();
    },
    height() {
      if (!this.elements || this.elements.length === 0) return 0;
      const maxY = this.elements.reduce((acc, curr) => Math.max(curr.y, acc), 0);
      const size = this.elements[0].w;

      return maxY + size;
    },
    graphPositions() {
      const layout = this.flowLayout(this.flow.id);
      const box = { maxHeight: this.size, forceSquare: true };

      return nodePositions(this.flow, layout, {}, box, 0);
    },
    graphWidth() {
      return graphWidth(this.graphPositions);
    },
    graphHeight() {
      return graphHeight(this.graphPositions);
    },
    paths() {
      if (!this.flow) return [];
      return this.flow.edges.map(({ from, to }) => ({
        fromPos: this.getPos(from),
        toPos: this.getPos(to),
      }));
    },
    elements() {
      let { nodes } = this;

      if (this.elementId) {
        const childrenIds = this.structure.allLowerChildren(this.elementId)
          .filter(id => this.elementType(id) === this.elementTypeEnum.machine);
        nodes = nodes
          .filter(({ id }) => childrenIds.findIndex(cid => cid === id) > -1);
      }

      return nodes
        .map(({ id, name }) => {
          const pos = this.graphPositions[id];
          return {
            id,
            name,
            ...pos,
          };
        });
    },
  },
  methods: {
    getPos(elementId) {
      const pos = this.graphPositions[elementId];
      const size = pos.w;
      return {
        x: pos.x + size / 2,
        y: pos.y + size / 2,
      };
    },
    mouseDown(e) {
      this.isDown = true;
      this.emitScroll(e);
    },
    emitScroll(e) {
      const rect = this.$refs.container.getBoundingClientRect();
      const left = (e.x - rect.x) / this.graphWidth - (this.scrollWidth / 2);
      const top = (e.y - rect.y) / this.graphHeight - (this.scrollHeight / 2);
      this.$emit('scroll', { left, top });
    },
  },
  created() {
    this.mouseMove = e => {
      if (this.isDown) {
        this.emitScroll(e);
      }
    };
    this.mouseUp = () => {
      this.isDown = false;
    };

    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
  },
  destroyed() {
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .minimap {
    user-select: none;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .scroller {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 21;
    background-color: rgba(100,100,100,0.03);
    border: 1px solid rgba(150, 150, 150, 0.3);

    &:hover {
      background-color: rgba(100,100,100,0.1);
    }
  }

  .box {
    border: 1px solid rgba(100,100,100,0.5);
    box-shadow: 0 0 8px rgba(100,100,100,0.3);
    border-radius: 20px;
    background-color: white;
    z-index: 20;
    font-size: 10px;
    font-weight: bold;
    text-align: center;

    &.on {
      border-color: $green;
      &.warn {
        border-color: $orange;
      }
    }
    &.off {
      border-color: $grey;
    }

    &.on, &.off {
      &:hover {
        box-shadow: 0 0 8px rgba(100,100,100,0.5)
      }
    }

    .icon {
      background-color: rgba(white, 0.9);
      box-shadow: 0 0 2px rgba(150, 150, 150, 0.3);
      position: absolute;
      top: -4px;
      right: -4px;
      font-size: 20px;
      line-height: 1;
      border-radius: 50%;
    }
  }
  .name {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
  }
</style>
