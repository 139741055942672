<template>
  <div :class="{ inline }">
    <div
      v-if="showTitle"
      class="text-uppercase font-weight-bold select-title"
    >
      {{ $t('monitoring.performanceType') }}
    </div>
    <BButtonGroup size="sm">
      <BButton
        :variant="type === 'designed' ? 'ilabo' : 'outline-ilabo'"
        class="text-nowrap text-uppercase"
        @click="set('designed')"
      >
        {{ $t('monitoring.designSpeed') }}
      </BButton>
      <BButton
        :variant="type === 'validated' ? 'ilabo' : 'outline-ilabo'"
        class="text-nowrap text-uppercase"
        @click="set('validated')"
      >
        {{ $t('monitoring.validatedSpeed') }}
      </BButton>
      <BButton
        v-if="showPlanFromOrders"
        :variant="type === 'orders' ? 'ilabo' : 'outline-ilabo'"
        class="text-nowrap text-uppercase"
        @click="set('orders')"
      >
        {{ $t('monitoring.plannedFromOrders') }}
      </BButton>
    </BButtonGroup>
  </div>
</template>

<script>
export default {
  props: {
    validatedSpeed: Boolean,
    planFromOrders: Boolean,
    inline: Boolean,
    showPlanFromOrders: Boolean,
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    type() {
      if (this.planFromOrders) return 'orders';
      if (this.validatedSpeed) return 'validated';
      return 'designed';
    },
  },
  methods: {
    set(type) {
      if (type === 'orders') {
        this.$emit('update:planFromOrders', true);
      } else {
        this.$emit('update:planFromOrders', false);
      }

      if (type === 'validated') {
        this.$emit('update:validatedSpeed', true);
      } else {
        this.$emit('update:validatedSpeed', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-title {
    font-size: 10px;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  .inline {
    display: flex;
    align-items: center;
    margin-left: -2px;

    .select-title {
      padding-right: 6px;
      margin-bottom: 0;
      font-size: 11px;
    }
  }

  .inline :deep(.btn) {
    font-size: 11px;
  }
</style>
