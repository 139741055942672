import axios from '@/api/axios';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {

  },
})
  .get({
    action: 'getCustomFunctions',
    property: 'list',
    path: ({ plantId }) => `v2/plant/${plantId}/preprocessing`,
  })
  .post({
    action: 'addCustomFunction',
    path: ({ plantId }) => `v2/plant/${plantId}/preprocessing`,
    onSuccess: (s, { data }) => {
      s.list.push(data);
    },
  })
  .put({
    action: 'updateCustomFunction',
    path: ({ plantId, id }) => `v2/plant/${plantId}/preprocessing/${id}`,
    onSuccess: (s, { data }) => {
      const idx = s.list.findIndex(x => x.id === data.id);
      Vue.set(s.list, idx, data);
    },
  })
  .delete({
    action: 'deleteCustomFunction',
    path: ({ plantId, id }) => `v2/plant/${plantId}/preprocessing/${id}`,
    onSuccess: (s, _, a, req) => {
      s.list = s.list.filter(x => x.id !== req.params.id);
    },
  })
  .post({
    action: 'testCustomFunction',
    path: ({ plantId, id }) => `v2/plant/${plantId}/preprocessing/${id}/test`,
    property: 'test',
  })
  .get({
    action: 'getPreProcessingExecutions',
    path: ({ functionId }) => `v2/preprocessing/execution/${functionId}`,
    property: 'test',
  })
  .getStore();

export default store;
