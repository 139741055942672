<template>
  <div>
    <div
      v-tippy="{ arrow: true }"
      class="state-icon pause"
      :content="isForced ? $t('lineControl.start') : $t('lineControl.stop')"
      @click.stop="clickStopStartButton"
    >
      <Loader
        v-if="pending"
        color="#333"
        size="12px"
      />
      <span v-else-if="isForced">
        <i
          class="fas fa-play"
        />
        <span
          v-if="isMobile"
          class="pl-2"
        >{{ $t('lineControl.start') }}</span>
      </span>
      <span v-else>
        <i
          class="fas fa-pause"
          style="position: relative; top: -1px"
        />
        <span
          v-if="isMobile"
          class="pl-2"
        >{{ $t('lineControl.stop') }}</span>
      </span>
    </div>

    <!-- Line pause offcanvas -->
    <Offcanvas
      v-if="!isForced"
      :show.sync="showStopOffcanvas"
      no-bottom-padding
    >
      <LineStopOffcanvas
        v-if="showStopOffcanvas"
        :line-id="lineId"
        @hide="showStopOffcanvas = false"
      />
    </Offcanvas>

    <LModal
      :show.sync="modal"
      size="lg"
    >
      <StartElement
        :element-id="lineId"
        @pending="pending = $event"
        @close="modal = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StartElement from '@/components/modals/StartElement';
import LineStopOffcanvas from '@/components/offcanvases/LineStopOffcanvas';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
    isForced: Boolean,
  },
  data: () => ({
    pending: false,
    showStopOffcanvas: false,
    modal: false,
    isStarting: false,
  }),
  components: {
    LineStopOffcanvas,
    StartElement,
  },
  computed: {
    ...mapGetters(['plantId', 'isMobile']),
  },
  methods: {
    clickStopStartButton() {
      if (this.isForced) {
        this.modal = true;
      } else {
        this.showStopOffcanvas = true;
      }
    },
  },
};
</script>
