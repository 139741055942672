import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';
import activities from './activities';
import downtimes from './downtimes';

export default {

  downtimes,
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,

  components: {
    consumptionType: {
      help: 'PLUSIEURS\' autorise plusieurs paquets ouverts à la fois, le premier est consommé.\n\'SEUL\' ferme automatiquement le paquet précédent lorsqu’un nouveau est activé, le dernier est consommé',
      title: 'Type de consommation'
    },
    warningLevelHelp: '% - Avertissement lorsqu\'un certain pourcentage du paquet est consommé\n\nMin - Avertissement lorsque le nombre estimé de minutes restantes',
    active: 'Actif',
    addComponent: 'Ajouter un composant',
    batchTracking: 'Suivi des lots',
    clickToActivate: 'Cliquez pour activer',
    closeConfirm: 'Fermé',
    closed: 'Fermé',
    scannerCodeType: 'Type de code',
    one: 'Composant',
    invalidCode: 'Composant introuvable',
    title: 'Composants',
    used: 'Consommé',
    convertUnit: 'Unité de conversion',
    estimatedTotalQuantity: 'Quantité estimée nécessaire',
    eta: 'ETA',
    defaultPackageQuantity: 'Dans le package',
    noComponents: 'Aucun composant affecté',
    noTrackingId: 'Pas d’identifiant de suivi',
    packageScannerCode: 'Code du paquet',
    packages: 'Paquets',
    required: 'Obligatoire',
    scan: 'Scannez',
    registeredDate: 'Date d’analyse',
    scanPackages: 'Scan des paquets',
    scanTrackingId: 'Scan du numéro de suivi',
    scanned: 'Scanné',
    scannerCode: 'Code du scanner',
    defaultPackageQuantitySum: 'Quantité totale en colis',
    trackingId: 'ID de suivi',
    usagePerUnit: 'Utilisation par unité',
    validation: 'Validation requise',
    warningLevel: 'Niveau d’avertissement',
    warningLevelPercentage: 'Pourcentage d’avertissement',
    batchTrackingHelp: 'Lorsque le suivi est activé, vous devez fournir un numéro d’identification de lot pour chaque colis numérisé',
  },
  vision: {
    auto: 'A',
    noRecords: 'L\'appareil photo n\'a aucun enregistrement de ce jour à télécharger',
    toMoreAfter: 'Choisissez une date de fin ultérieure pour la vidéo.',
    toMoreBefore: 'Choisissez une date de début antérieure pour la vidéo.',
    currentVideoTime: 'Heure actuelle de la vidéo',
    endDowntime: 'Mettre fin aux temps d\'arrêt',
    getTheTwoPreviousMinutes: 'Obtenez 2 minutes avant et 2 après l’heure de début',
    getClip: 'Obtenir un clip',
    invalidTimeRange: 'Plage de temps non valide',
    manual: 'M',
    maxVideoIs: 'La longueur maximale de la vidéo est',
    noClips: 'Aucun clip trouvé',
    selectCamera: 'Sélectionnez  une Caméra',
    selectEndClip: 'Sélectionner la vidéo de fin',
    selectStartClip: 'Sélectionnez Démarrer la vidéo',
    showAllClips: 'Afficher tous les clips',
    showLiveVideo: 'Afficher la vidéo en direct de la caméra',
    startDowntime: 'Démarrer les temps d’arrêt',
    startVideoTime: 'Démarrer l’heure de la vidéo',
    videoTrigger: 'Lorsqu\'il est sélectionné, l\'étiquette VideoTrigger est configurée pour ce type de problème',
    autoDownload: 'Demander la vidéo pour tous les événements futurs de ce type',
    videoDurationIs: 'La durée de la vidéo est',
    autoLong: 'Vidéo demandée automatiquement par le système',
    manualLong: 'Vidéo demandée manuellement par l’utilisateur',
    vision: 'Vision',
    'watchVideo': 'Regarder la vidéo',
    'clipIsNotReadyYet': 'La vidéo n\'est pas encore prête',
    'hasCamera': 'A une caméra',
  },
  menu: {
    userSettings: 'Paramètres du compte',
    activities: 'Activités',
    andon: 'Et sur',
    homePageDevice: {
      title: 'Page d’accueil de cet appareil',
      description: 'Cet écran s’affichera toujours après la connexion sur cet appareil'
    },
    homePageUser: {
      title: 'Page d’accueil de votre compte',
      description: 'Cet écran s’affichera après votre connexion si l’appareil n’a pas de page d’accueil'
    },
    language: 'Langue',
    lineGraph: 'Graphique de la ligne',
    loadMore: 'Charger plus',
    login: 'Connecter',
    logout: 'Déconnexion',
    setFullScreen: 'Faire un plein écran',
    monitoring: 'Visualisation',
    notifications: 'Notifications',
    overview: 'Aperçu',
    preview: 'Aperçu',
    reports: 'Rapports',
    scoreAnalysis: 'Analyse des scores',
    selectPlant: 'Sélectionner l\'usine',
    setAsHome: 'Définir comme page d’accueil',
    settings: 'Paramètres',
    user: 'Utilisateur',
    weeklyAnalysis: 'Analyse hebdomadaire',
  },
  import: {
    actionRequired: 'Action requise',
    chooseImage: 'Choisissez une image',
    dataSaved: 'Données sauvegardées avec succès',
    upload: 'Déposez des fichiers ici pour les télécharger',
    groupFailedToAssign: 'Échec de l’affectation au groupe',
    groupFailedToRemove: 'Impossible de supprimer du groupe',
    fileIsBeingProcessed: 'Le fichier est en cours de traitement. Nous vous enverrons une notification lorsqu’elle sera prête',
    fillAndAdd: 'Remplir et ajouter',
    nothingImported: 'Aucune donnée valide à importer',
    notSaved: 'Non enregistré',
    previousImports: 'Importations antérieures',
    saved: 'Sauvgardé',
    groupSuccessfullyAssign: 'Affectation réussie au groupe',
    groupSuccessfullyRemoved: 'Supprimé avec succès du groupe',
    checked: 'Vérifié',
    noChanges: 'Aucun changement',
    multiplierIsInvalid: 'Le multiplicateur est en conflit avec la conversion déjà définie',
    multiplierIsLessOrEqualZero: 'Le multiplicateur ne peut pas être inférieur ou égal à zéro',
    seeTemplates: 'Voir les modèles',
    fileReview: 'Examen du fichier',
    templates: 'Modèles',
    addTemplate: 'Ajouter un modèle',
    editTemplate: 'Modifier un modèle',
    import: 'Importer',
    uploadFile: 'Télécharger un fichier',
    template: 'Modèle',
    toUploadFilePickTemplate: 'Pour télécharger un fichier, choisissez le modèle',
    result: 'Résultat',
    testFile: 'Fichier de test',
    toSeeResultUploadTestFile: 'Pour voir le résultat, téléchargez un fichier de test',
    toTestFileSaveChanges: 'Pour tester le fichier, enregistrez vos modifications',
    visible: 'Visible',
    saveFunction: 'Enregistrer la fonction',
    key: 'Clé',
    value: 'Valeur',
    oldValue: 'Ancienne valeur',
    newValue: 'Nouvelle valeur',
    unchanged: 'Inchangé',
    status: {
      applied: 'Succès',
      appliedWithErrors: 'Appliqué avec des erreurs',
      scheduled: 'Planifié',
      faulted: 'Défectueux',
      processing: 'En cours de traitement',
      parsingFailed: 'Échec de l\'analyse',
    },
    wasSuccessfully: 'a été effectué avec succès',
    added: 'ajouté',
    imported: 'importé',
    failed: 'échoué',
  },
  general: {
    actions: 'Actions',
    add: 'Ajouter',
    all: 'Tout',
    areYouSure: 'Êtes-vous sûr?',
    attach: 'Attacher',
    back: 'Retour',
    dir: {
      bottom: 'Bas', left: 'Gauche', right: 'Droite', top: 'Retour au début',
    },
    cancel: 'Annuler',
    cancelled: 'Annulé',
    change: 'Modifier',
    clear: 'Effacer',
    close: 'Fermé',
    code: 'Code',
    confirm: 'Confirmer',
    continue: 'Continuer',
    createNew: 'Créer de nouveaux',
    dates: 'Dates',
    deleted: 'Supprimé',
    description: 'Description',
    detach: 'Détacher',
    details: 'Détails',
    deviceId: 'Identifiant de l\'appareil',
    dimensions: 'Dimensions',
    disable: 'Désactiver',
    disabled: 'Désactivé',
    disabledShe: 'Désactivé',
    unsavedChanges: 'Voulez-vous vraiment partir? Vous avez des modifications non enregistrées!',
    download: 'télécharger',
    edit: 'Editer',
    enable: 'Activer',
    enabled: 'Activé',
    enabledShe: 'Activé',
    end: 'Fin',
    event: 'Évènement',
    filters: 'Filtres',
    finish: 'Finir',
    forcedByOperator: 'Forcé par l\'opérateur',
    from: 'De',
    fromStart: 'Du début',
    group: 'Groupe',
    hidden: 'Masqué',
    hide: 'Masquer',
    ignore: 'Ignorer',
    inactive: 'Inactive',
    last: 'Dernier',
    max: 'Max',
    min: 'Min',
    name: 'Nom',
    no: 'Non',
    noData: 'Pas de données',
    noResults: 'Aucun résultat',
    nominal: 'Nominal',
    nothingDefined: 'Rien de défini',
    now: 'Maintenant',
    others: 'Autres',
    progress: 'Progrès',
    reason: 'Raisons',
    reasonUnknown: 'Raison inconnue',
    refresh: 'Rafraîchir',
    remove: 'Supprimer',
    requestReceived: 'Demande reçue',
    reset: 'Réinitialisation',
    restoreDefaults: 'Restaurer les valeurs par défaut',
    results: 'Résultats',
    save: 'Sauvegarder',
    saveFailed: 'Échec de l’enregistrement',
    saveSuccessed: 'Sauvegarde réussi',
    search: 'Rechercher',
    select: 'Sélectionner',
    selectAll: 'Sélectionner tout',
    selected: 'Sélectionné',
    setDate: 'Définir la date',
    setTime: 'Définir l’heure',
    shouldArchive: 'Devrait archiver',
    show: 'Montrer',
    showAll: 'Afficher tout',
    showMore: 'Afficher plus',
    sourceURI: 'Source URI',
    start: 'Commencer',
    status: 'Statut',
    summary: 'Résumé',
    time: 'Temps',
    title: 'Titre',
    to: 'À',
    toEnd: 'Pour finir',
    tryAgain: 'Réessayez',
    type: 'Type',
    unit: 'Unité',
    update: 'Mettre à jour',
    useExisting: 'Utiliser l’existant',
    value: 'Valeur',
    valueType: 'Type de valeur',
    view: 'Vue',
    yes: 'OUI',
    'errors': 'Erreurs',
    'inherited': 'Hérité',
    'lastRefresh': 'Dernière actualisation : il y a {0}',
    'collapseDetails': 'Réduire les détails',
    'next': 'Suivant',
    'allSelected': 'Tout sélectionner',
    'unchecked': 'non vérifié',
    'manHours': 'Heures-homme',
    'pickDate': 'Choisissez une date',
    'recent': 'Récent',
    'total': 'Total',
    'line': 'Ligne',
    'option': 'Option',
    'default': 'Défaut',
    'set': 'Enregistrer',
    'percent': 'Pourcentage',
    'audit': 'Audit',
  },
  order: {
    activatedDate: 'Date d\'activation',
    addNewProperty: 'Ajouter une nouvelle propriété',
    ascending: 'Ascendant',
    delete: 'Effacer tout les filtres',
    descending: 'Décroissant',
    totalTime: 'Durée de la commande',
    endDate: 'Date de din',
    eta: 'ETA',
    plannedProduction: 'Production planifiée',
    quantityProduced: 'Quantité produite',
    sortBy: 'Trier par',
    sortByDate: 'Trier par date',
    plannedDate: 'Date de début',
  },
  graph: {
    isActive: 'Actif',
    addElement: 'Ajouter un élément',
    andon: 'Et sur',
    archiveChanges: 'Archiver les modifications',
    detached: 'Détaché',
    flow: 'Flux',
    flows: 'Flux',
    noActiveFlow: 'Pas de flux actif',
    physical: 'Physique',
    production: 'Flux de production',
    supplying: 'Approvisionnement',
    'setAsABaseAvailability': 'Définir comme disponibilité de base',
    'setAsABaseQuantity': 'Définir comme quantité de base',
    'youNeedToSpecifyBaseMachines': 'Vous devez spécifier la disponibilité de base et la quantité de base de la machine.',
  },
  monitoring: {
    machine: {
      activeAlarms: 'Alarmes actives',
      alarms: 'Alarmes',
      alarmsHoldups: 'Alarmes et Agressions',
      condition: 'État de l\'actif',
      performanceInfo: 'Performances moyennes depuis le dernier {0}',
      cost: 'Coût',
      counter: 'Compteur',
      counters: 'Compteurs',
      currentParameters: 'Paramètre actuel',
      environmentCondition: 'Conditions environnementales',
      failurePreditcion: 'Prédiction de défaillance',
      failureType: 'Type de défaillance',
      lifespan: 'Durée de vie',
      maintenanceHistory: 'Historique de maintenance',
      others: 'Autres',
      parameters: 'Paramètres',
      predictedFailure: 'Indisponiblitée prévu',
      timeToFailure: 'Temps d\'indisponiblitée prévu',
      probabilityFailure: 'Probabilité d’indisponibilité dans les 10 jours',
      processParameters: 'Paramètres de processus',
    },
    averageTargetPerformance: 'Performance cible moyenne',
    segment: {
      baseAvailability: 'Disponibilité de base',
      basePerformance: 'Performances de base',
      baseQuality: 'Qualité de base',
      baseQuantity: 'Quantité de base',
    },
    cause: 'Cause',
    causes: 'Causes',
    changeCurrentDowntime: 'Modifier les temps d\'arrêt',
    lineInfo: {
      confirmLinePause: 'Confirmer la pause de la ligne',
      toAchieveTarget: 'Volume de production prévu à l’heure actuelle :',
      advices: 'Conseils',
      jobs: 'Travails',
      completed: 'Travaux effectués',
      planned: 'Travaux en file d\'attente',
      problems: 'Défaut',
      forService: 'Tâches',
    },
    correctiveAction: 'Mesure corrective',
    designSpeed: 'Design',
    disconnected: 'Déconnécté',
    doubleClickToEdit: 'Double-cliquez pour modifier les temps d\'ârret',
    downtimes: 'Arrêt',
    performance: {
      effective: 'Performances efficaces',
      short: 'Performances du dernier {0}',
      nominal: 'Performances nominales'
    },
    event: 'Évènement',
    events: 'Evènements',
    notWorking: 'En temps d\'arrêt',
    minorStops: 'Y compris les arrêts mineurs',
    lastMessage: 'Dernier message',
    lineStates: 'Etats de la ligne',
    maxPerfProduction: 'Performance max',
    noAlarms: 'Pas d’alarmes',
    noConnection: 'Aucune connexion avec le serveur',
    noConnectionInfo: 'Aucune information sur la connexion',
    problems: 'aucun problème n\'| un problème | {0} questions',
    noMessages: 'Aucun message',
    noPlan: 'Pas de plan',
    notInFlow: 'Pas dans le flux de production',
    percentShare: 'Part en pourcentage',
    performanceType: 'Type de performance',
    planned: 'Planifié',
    plannedFromOrders: 'Commandes planifiées',
    remainingEstimatedProduction: 'Production restante possible en fonction de la vitesse effective',
    remainingPlannedProduction: 'Production restante possible en fonction de la vitesse nominale',
    processing: 'Traitement',
    dataProcessingFromLast: 'Traitement des données du dernier',
    produced: 'Produit',
    production: 'Production',
    productionMap: 'Carte de production',
    productionProgress: 'Progression de la production',
    targetProduction: 'Objectif de production',
    ready: 'prêt',
    realTime: 'Temps réel',
    stoppedRealTime: 'Arrêt en temps réel',
    reasons: 'Raisons',
    remainingTime: 'Temps restant',
    reportParam: 'Paramétrage des rapports',
    serverDisconnected: 'Serveur déconnecté',
    stopRealTime: 'Arrêt en temps réel',
    timeShare: 'Temps partagé',
    toRealTime: 'En temps réel',
    validatedSpeed: 'Validé',
    warnings: 'Avertissements',
    wasted: 'Rebus',
    workLog: 'Historique de travail',
    workStates: 'États de travail',
    working: 'Travail',
    worstAlarms: 'Défaut',
    ofThePlan: 'Vous avez atteint %{value}% de cette valeur',
    'performanceSettingHistory': 'Historique des paramètres de performance',
    'unitConversionsHistory': 'Historique des conversions d\'unités',
    'goodCount': 'Compteur de bons',
    'wasteCount': 'Compteur de déchets',
    'overdue': '{0} en retard',
    'left': 'Il reste {0}',
  },
  items: {
    addGroup: 'Ajouter un groupe',
    add: 'Ajouter un item',
    components: 'Composants',
    description: 'Description',
    edit: 'Editer l\'élements',
    expectedChangeover: 'Performances attendues',
    expectedPerformance: 'Performances attendues',
    groupPackingStructure: 'Structure d’emballage de groupe',
    groupRoutingSpeed: 'Vitesse de routage de groupe',
    groups: 'Groupes',
    import: 'Importer des éléments',
    inheritedFromGroup: 'Hérité du groupe',
    itemGroup: 'Groupe d\'élement',
    itemGroups: 'Groupes d\'élement',
    itemRoutingSpeed: 'Vitesse de routage des éléments',
    items: 'Elements',
    lineConfiguration: 'Configuration de la ligne',
    new: 'Nouvel élément',
    noLineConfigured: 'Aucun produit configuré pour cette ligne',
    number: 'Nombre',
    packingStructure: 'Structure d’emballage',
    performance: 'Performance',
    unit: 'Unité de quantité',
    quantity: 'Quantité/palette',
    'item': 'Article',
    'export': 'Exporter',
    'bulkDeleteWarning': 'Êtes-vous sûr de vouloir supprimer les produits sélectionnés ?',
    'bulkDeleteConfirm': 'Supprimer les produits sélectionnés',
    'additionalFields': 'Champs supplémentaires',
    'noAdditionalFields': 'Aucun champ supplémentaire',
  },
  schedules: {addLine: 'Ajouter une ligne aux filtres', clearLines: 'Effacer toutes les lignes'},
  panel: {
    provideReasonAndComment: 'Ajouter une raison et un commentaire',
    averagePerformance: 'Performances moyennes',
    comment: 'Commenter',
    currentLineStatus: 'Etat de ligne actuel',
    currentPerformance: 'Performance actuelle',
    downtimeFrom: 'Temps d\'arrêt à partir de',
    number: 'Nombre',
    ongoing: 'Continue',
    selectMachine: 'Sélectionner la machine',
    selectState: 'Sélectionner l’état',
    sku: 'Sku',
    commentPlaceholder: 'Écrivez votre commentaire',
    'confirmData': 'Confirmer les données',
    'inputPlaceholder': 'Écrire ici',
  },
  addSchedule: 'Ajouter un horraire',
  work: {
    addSelected: 'Ajouter sélectionée',
    addTags: 'Ajouter des balises',
    correctiveAction: 'Mesure corrective',
    actionTaken: 'Mesure corrective prise',
    correctiveActions: 'Mesures correctives',
    default: 'Défaut',
    detachRootCause: 'Détacher la cause fondamentale',
    disconnected: 'Déconnécté',
    downtime: 'Temps d\'arrêt',
    overrideQueued: 'Insertion de temps d’arrêt en file d’attente. Cela peut prendre quelques secondes',
    downtimeList: 'Liste des temps d’arrêt',
    downtimeSince: 'Temps d\'arrêt depuis',
    updateQueued: 'Temps d’arrêt mis à jour en file d’attente. Cela peut prendre quelques secondes',
    downtimes: 'Arrêt',
    lasting: 'Durable',
    noOngoingOrder: 'Aucune commande en cours',
    noOngoingShift: 'Pas de changement d\'équipe en cours',
    noForcedState: 'Aucun problème défini avec le tag \'Forcé\'. Ajoutez une définition de temps d’arrêt avec cette balise, pour arrêter la machine ou la ligne',
    plannedDowntimes: 'Temps d’arrêt planifiés',
    event: 'Problème',
    events: 'Problèmes',
    reason: 'Raisons',
    reasons: 'Raisons',
    rootCause: 'Cause principale',
    rootMachine: 'Machine de la cause principal',
    state: 'État',
    states: 'États',
    systemStates: 'États du système',
    tags: 'Tags',
    unplannedDowntimes: 'Temps d’arrêt imprévus',
    wiki: 'Articles Wiki',
    spectrum: 'Spectre de travail',
    workTime: 'Durée de travail',
    'byState': 'Par état',
    'persisted': 'Modification enregistrée',
    'processing': 'Traitement en cours...',
    'saving': 'Enregistrement...',
    'updateType': {
      'SetWorkLogProblem': 'Mise à jour du type de temps d\'arrêt',
      'SetWorkLogReasonMessage': 'Mise à jour de la raison',
      'SetWorkLogCorrectiveActionMessage': 'Mise à jour de l\'action corrective',
      'AddWorkLogAnnotationMessage': 'Nouveau commentaire',
      'DeleteWorkLogAnnotationMessage': 'Supprimer le commentaire',
      'OpenWorkLogMessage': 'Début de l\'arrêt forcé',
      'RecalculateElementStateMessage': 'Fin de l\'arrêt forcé',
      'DeleteWorkLogMessage': 'Supprimer le temps d\'arrêt',
      'OverrideWorkLogMessage': 'Création d\'un nouveau temps d\'arrêt',
      'AdjustWorkLogDuration': 'Ajustement de la durée du temps d\'arrêt',
      'WorkLogSplitMessage': 'Division du temps d\'arrêt'
    },
    'audit': {
      'onElement': 'sur',
      'started': 'début du temps d\'arrêt',
      'WorkLogOpeningRequested': 'début du temps d\'arrêt',
      'WorkLogProblemUpdateRequested': 'changement du type de temps d\'arrêt',
      'WorkLogDeletionRequested': 'suppression du temps d\'arrêt',
      'WorkLogRecalculationRequested': 'fin de l\'arrêt forcé',
      'WorkLogDurationAdjustmentRequested': 'mise à jour de la durée du temps d\'arrêt',
      'WorkLogOverrideRequested': 'création du temps d\'arrêt',
      'WorkLogSplitRequested': 'division du temps d\'arrêt'
    }
  },
  allInstances: 'Toutes les occurrences',
  area: 'Région',
  areas: 'Régions',
  quest: {
    assignToGroups: 'Attribuer à des groupes',
    closeToState: 'Proche de l\'état',
    comments: 'Commentaires',
    files: 'Fichiers',
    noFiles: 'Aucun fichier',
    project: 'Projet',
    quest: 'Tâche',
    questDescription: 'Description de la tâche',
    taskDetails: 'Détails de la tâche',
    state: 'État de la tâche',
    questTitle: 'Titre de la tâche',
    type: 'Type de tâche',
  },
  oee: {
    availability: 'Disponibilité',
    oee: 'TRS',
    oeeLoss: 'Perte TRS',
    performance: 'Performance',
    quality: 'Qualité',
    teep: 'TEEP',
    'oeePercentage': 'OEE [%]',
    'availabilityPercentage': 'Disponibilité [%]',
    'performancePercentage': 'Performance [%]',
    'utilization': 'Utilisation',
    'target': 'Cible',
  },
  time: {
    availabilityOnly: 'Pertes de disponibilité',
    currentOrder: 'Commande actuelle',
    date: 'Date',
    day: 'Jour',
    days: 'Jours',
    duration: 'Durée',
    endDate: 'Date de fin',
    endTime: 'Heure de fin',
    friday: 'Vendredi',
    excludeDefaults: 'Afficher uniquement les défauts',
    hour: 'Heure',
    hours: 'Heures',
    hoursPlural: 'Heures',
    longerThan: 'Plus longtemps que',
    minutes: 'Minutes',
    monday: 'Lundi',
    month: 'Mois',
    performanceOnly: 'Pertes de performance',
    plannedDate: 'Date prévue',
    quarter: 'Trimestre',
    resolution: 'Résolution',
    saturday: 'Samedi',
    seconds: 'Secondes',
    selectDay: 'Sélectionner le jour',
    shorterThan: 'Plus court que',
    showMinorStops: 'Afficher les arrêts mineurs',
    plannedOnly: 'Afficher uniquement prévu',
    unplannedOnly: 'Afficher uniquement les imprévus',
    startDate: 'Date de début',
    startTime: 'Heure de début',
    sunday: 'Dimanche',
    thursday: 'Jeudi',
    scope: 'Étendue temporelle',
    tuesday: 'Mardi',
    utilizationOnly: 'Pertes d’utilisation',
    wednesday: 'Mercredi',
    week: 'Semaine',
    year: 'Année',
    none: 'Aucun',
    custom: 'Personnalisé',
    factoryTimezone: 'Fuseau horaire de l\'usine',
  },
  energy: {
    max: 'Moyenne maximale',
    baseline: 'Ligne de base',
    currentPower: 'Puissance actuelle',
    currentLeastEfficient: 'Machine actuellement la moins efficace',
    usedEnergy: 'Énergie utilisée',
  },
  downloadStatus: {
    failedOnEdge: 'La caméra a échoué',
    noArchiveRecordings: 'La caméra n\'a aucun enregistrement',
    deleted: 'Supprimé',
    failed: 'Échec',
    inProgress: 'En cours',
    success: 'Prêt',
  },
  wall: {
    comment: 'Commenter',
    comments: 'Commentaires',
    downtimeEditorSettings: 'Paramètres de l’éditeur de temps d’arrêt',
    vision: 'Vision',
  },
  processParams: {
    compositionName: 'Nom de la composition',
    compositions: 'Compositions',
    ongoingOrder: 'Commande en cours',
    ongoingProblem: 'Problème persistant',
    ongoingState: 'État en cours',
    orderEnd: 'Fin de commande',
    orderProduction: 'Production des commandes',
    orderStart: 'Début de la commande',
    problemEnd: 'Fin du problème',
    problemStart: 'Démarrage du problème',
    saveComposition: 'Enregistrer la composition',
    signalName: 'Nom du signal',
    signalValue: 'Valeur du signal',
    signals: 'Signaux',
  },
  panel_confirmData: 'Confirmer les données',
  machineControl: {
    current: 'Actuel',
    liveStream: 'Diffusion en direct',
    startMachine: 'Démarrer la machine <b>{machine}</b>?',
    turnOff: 'Arrêter',
    turnOn: 'Allumer',
    disconnectedWarning: 'AVERTISSEMENT! La machine est actuellement déconnectée - la mise hors tension entraînera une perte de données mises en mémoire tampon',
  },
  currentOrder: 'Commande actuelle',
  currentShift: 'Equipe en cours',
  currentWeek: 'Semaine en cours',
  cycle: 'Cycle',
  delete: 'Supprimer',
  deleteRecurringInstance: 'Supprimer une instance récurrente',
  editRecurringInstance: 'Editer une instance récurrente',
  element: 'Élément',
  ends: 'Finit',
  plant: 'Usine',
  users: {firstName: 'Prénom', lastName: 'Nom'},
  asset: {
    globalCounter: 'Compteur global',
    localCounter: 'Compteur local',
    pumpBalance: 'Balance de la pompe',
    valvePosition: 'Position de la vanne',
    waterCounter: 'Compteur d’eau',
    waterFlow: 'Water flow',
    waterLevel: 'Niveau d’eau',
  },
  health: {health: 'Santé', healthHistory: 'Performances précédentes'},
  importSchedule: 'Planification de l’importation',
  issue: 'Emettre',
  line: 'Ligne',
  lines: 'Lignes',
  machine: 'Machine',
  machines: 'Machines',
  map: 'Plan',
  metrics: {
    mtbf: 'Temps moyen entre les pannes (MTBF)',
    mtbm: 'Temps moyen entre les travaux de maintenance (MTBM)',
    mttr: 'Temps moyen de réparation (MTTR)',
    reliabilityMetrics: 'Mesures de fiabilité',
  },
  never: 'Jamais',
  noCycle: 'Pas de définition de cycle',
  healthcheck: {noDriverConnection: 'Aucune connexion de pilote'},
  noShift: 'Pas d\'équipe',
  noShifts: 'Pas d\'équipe assigné',
  on: 'Sur',
  calendar: {period: 'Période'},
  production: {rawCounterIncreases: 'Valeurs brutes des compteurs au cours de cette période'},
  repeatEvery: 'Répétez tous les',
  repeatOn: 'Répétez l’opération',
  schedule: 'Horaire',
  segment: 'Segment',
  shift: 'Équipe',
  shifts: 'Équipes',
  lineControl: {start: 'Commencer', startLine: 'Démarrer Ligne <b>{line}</b>?', stop: 'Arrêter'},
  starts: 'Commence',
  quests: 'Tâches',
  teams: {teamName: 'Nom de l’équipe'},
  thisAndFollowingInstances: 'Cette instance et les suivantes',
  thisInstance: 'Cette instance',
  today: 'Aujourd’hui',
  typeOfIssue: 'Type de problème',
  chart: {weekSymbol: 'W'},
  pageNotFoundExplanation: 'Nous ne trouvons pas cette page, excusez-nous pour la gêne occasionnée',
  workCalendar: 'Calendrier de travail',
  world: 'Monde',
  panel_inputPlaceholder: 'Écrivez ici',
  score: {
    points: 'Points',
    reached: 'Atteint',
    target: 'Cibler',
    failures: 'Les échecs',
    betterWeek: '<b>{count}</b> mieux que la semaine dernière !',
    worseWeek: '<b>{count}</b> pire que la semaine dernière',
    sameWeek: 'La même position que la semaine dernière',
  },
  'factoryStructure': 'Structure de l\'usine',
  'audit': {
    'log': 'Journal d\'audit',
  },
  'taxonomies': {
    'taxonomy': 'Taxonomie',
    'classification': 'Classification',
    'selectClassification': 'Sélectionner la classification',
    'selectClassificationLevel': 'Sélectionner le niveau de classification',
    'selectClassificationNodes': 'Sélectionner les nœuds de classification',
    'defaultTaxonomy': 'Défaut',
    'pickCategory': 'Choisir la catégorie',
    'selectTaxonomy': 'Sélectionner la taxonomie',
    'selectTaxonomyLevel': 'Sélectionner le niveau de taxonomie',
  },
  'andon': {
    'top3Downtimes': 'Top 3 des temps d\'arrêt',
    'todayOeeTrend': 'Tendance OEE du jour',
    'stateDistribution': 'Distribution des états',
    'thisWeekOeeTrend': 'Tendance OEE de cette semaine',
    'currentState': 'État actuel',
    'noActiveOrder': 'Aucune commande active',
  }
};
