export default {
  status: {
    activated: 'Activé',
    completed: 'Terminé',
    draft: 'Brouillon',
    released: 'Libéré',
    started: 'A débuté',
    suspended: 'Suspendu',
    scheduled: 'Planifié',
    'notPlanned': 'Non planifié',
  },
  activities: 'Activités',
  activity: 'Activité',
  activityAdded: 'Activité ajoutée',
  activityId: 'Identifiant d\'activité',
  activityAlreadyExists: 'L\'ID d\'activité existe déjà',
  activityType: 'Type d\'activité',
  afterStart: 'Après le démarrage',
  changeover: 'Changement',
  changeoverDuration: 'Durée de basculement',
  list: {changeover: 'Changements', cleaning: 'Nettoyages', order: 'Ordres'},
  cleaning: 'Nettoyage',
  schedule: {
    collapsed: 'S\'est effondré',
    endOfLastHour: 'Fin de la dernière heure',
    endOfLastShift: 'Fin de la dernière équipe',
    full: 'Complet',
    scheduled: 'Prévue',
    tableView: 'Table',
    axisView: 'Chronologie',
    'currentHour': 'Fin de l\'heure actuelle',
    'previousHour': 'Heure précédente',
    'previousShift': 'Quart précédent',
    'currentShift': 'Quart actuel'
  },
  resources: {
    manhours: 'Heures de travail',
    production: 'Production',
    timeScope: 'Étendue temporelle',
    'waste': 'Déchets',
    'workers': 'Travailleurs',
    'worker': 'Travailleur',
    'history': 'Historique',
    'noEntries': 'Aucune entrée',
    'hours': 'heures',
    'minutes': 'minutes'
  },
  noActivities: 'Aucune activité',
  order: 'Commande',
  addActivity: 'Planifier une nouvelle activité',
  plannedEnd: 'Fin prévue',
  plannedStart: 'Début prévu',
  toStart: 'pour commencer',
  'add': 'Ajouter une activité',
  'edit': 'Éditer une activité',
  'addChangeover': 'Ajouter un changement',
  'step': 'Étape',
  'general': 'Général',
  'quantity': 'Quantité',
  'dates': 'Dates',
  'configuration': 'Configuration',
  'activityStart': 'Début de l\'activité',
  'activityIdIsTooLong': 'L\'ID de l\'activité est trop long',
  'manage': 'Gérer les activités',
  'changeoverType': 'Type de changement',
  'editExisting': 'Éditer existant',
  'pickALine': 'Choisir une ligne',
  'typeEffort': 'Type d\'effort',
  'showNotPlanned': 'Afficher les activités non planifiées',
  'summary': {
    'summary': 'Résumé',
    'orderTotal': 'Total de la commande',
    'lineTotal': 'TOTAL DE LA LIGNE',
    'inLineUnit': 'dans l\'unité de ligne',
    'inOrderUnit': 'dans l\'unité de commande',
    'goodCount': 'BON COMPTE',
    'wasteCount': 'COMPTAGE DES DÉCHETS',
    'counterTotal': 'Compteur Total',
    'performanceGuard': 'Garde de performance',
    'stateGuard': 'Garde d\'état',
    'performanceGuardTippy': 'Seuil de performance dépassé',
    'missingPackingStructure': 'Structure d\'emballage manquante',
    'manualReconciliation': 'Réconciliation manuelle'
  },
  'actions': {
    'finishChangeover': 'Terminer et commencer la commande',
    'abortChangeover': 'Abandonner le changement',
    'productionReconciliation': 'Réconciliation de production',
    'finishOrder': 'Fin de la commande'
  },
  'validations': {
    'orderNotFound': 'Commande associée non trouvée',
    'externalIdError': 'L\'ID ne peut contenir que des chiffres, des lettres, des caractères \'-\', \'/\' et \'_\' et ne peut commencer qu\'avec un chiffre ou une lettre'
  },
  'deleteConfirmMessage': 'Êtes-vous sûr de vouloir supprimer cette activité ?',
  'hasValidationErrors': 'A des erreurs de validation',
  'noLine': 'Pas de ligne',
  'editOrder': 'Éditer la commande',
  'editChangeover': 'Éditer le changement',
  'cannotCreateOrder': 'Impossible de créer une commande',
  'orderExists': 'Commande avec l\'ID d\'activité spécifié existe déjà.',
  'endOfLastShift': 'Fin du dernier quart',
  'endOfLastHour': 'Fin de la dernière heure',
  'productionHistory': 'Historique de production',
  'manhoursHistory': 'Historique des heures-homme',
  'changeoverDurationTitle': 'Durée du changement',
  'expectedEffort': 'Effort attendu (mh)',
  'expectedChangeoverEffort': 'Effort attendu de changement (mh)',
  'typeCorrectExpectedEffort': 'L\'effort attendu ne peut pas être négatif',
  'typeCorrectValue': 'La valeur ne peut pas être négative',
  'typeChangeoverDurationIfChangeoverExpectedEffortGiven': 'La durée attendue du changement est requise lorsque l\'effort attendu du changement est donné',
  'editMode': 'Mode d\'édition',
  'validationErrors': {
    'skuError': 'Le produit est manquant ou mal configuré',
    'flowIdError': 'La configuration du flux est manquante',
    'endDateNotValid': 'La date de fin prévue n\'est pas valide',
    'lineError': 'La configuration de la ligne est manquante',
    'orderedQuantityError': 'La quantité commandée est manquante',
    'orderedQuantityUnitError': 'L\'unité de quantité commandée est manquante ou mal configurée'
  }
};
