<template>
  <div class="text-center d-flex justify-content-center not-found">
    <div class="align-self-center text-fancy">
      404<br>
      Page Not Found<br>
      <div class="text-secondary comment">
        {{ $t('pageNotFoundExplanation') }}
      </div>
      <span
        class="globe"
        @click="toWorld"
      >
        <i class="ion ion-md-globe" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toWorld() {
      this.$router.push('/world');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .not-found {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-size: 50px;
    .comment {
      font-size: 20px;
      max-width: 500px;
    }
    .globe {
      font-size: 50px;
      color: $ilabo;
      cursor: pointer;
    }
    .globe:hover {
    color: #aeaeae;
    }
  }

</style>
