export default {
  'originalDates': 'Originele data',
  'originalDuration': 'Oorspronkelijke duur',
  'newDates': 'Nieuwe data',
  'newDuration': 'Nieuwe duur',
  'firstDowntime': '1e stilstand',
  'secondDowntime': '2e stilstand',
  'shorten': 'Verkorten',
  'split': 'Splitsen',
  'thisMachine': 'Deze machine',
  'addComment': 'Voeg een opmerking toe',
  'noComments': 'Geen opmerkingen',
  'downtimeSummary': 'Stilstanden samenvatting',
  'step': 'Stap {stap}',
  'selectRootLineMachine': 'Selecteer een plaats of oorzaak',
  'selectLineMachine': 'Selecteer lijn/machine',
  'selectState': 'Selecteer status',
  'selectProblem': 'Selecteer probleem',
  'specifyAReason': 'Specifieer een reden',
  'correctiveActionTaken': 'Correctieve actie genomen',
  'downtimeDuration': 'Stilstand duur',
  'vision': 'Visie',
  'oeeLossType': 'OEE verlies type',
  'table': {'downtime': 'Stilstand', 'details': 'Details', 'time': 'Tijd', 'duration': 'Duur'},
  'noSharedComments': 'Geen gedeelde opmerkingen'
};
