<template>
  <div style="position: relative; overflow: hidden">
    <div class="header-row">
      <div
        v-if="shift"
        class="w-100"
      >
        <div
          style="width: 200px; margin-left: auto; margin-right: 0"
          class="shift-box"
        >
          <span class="shift-time">
            <span class="font-weight-bold">{{ shift.code }}</span>:
            {{ formatTime(shift.startDate) }} - {{ formatTime(shift.endDate) }}
          </span>
          <TimeProgress
            :start-date="toUnix(shift.startDate)"
            :end-date="toUnix(shift.endDate)"
          />
        </div>
      </div>
      <div
        v-if="splitByOrder && pendingOrder"
        class="w-100"
      >
        <div
          style="width: 200px; margin-left: auto; margin-right: 0"
          class="shift-box"
        >
          <span class="shift-time">
            <span class="font-weight-bold">{{ pendingOrder.description || pendingOrder.externalId }}</span>:
            {{ formatTime(
              (pendingOrder.actualExecution && pendingOrder.actualExecution.begin)
                || (pendingOrder.actualActivation && pendingOrder.actualActivation.begin)
            ) }} - {{ formatUnix($now) }}
          </span>
        </div>
      </div>

      <div class="text-center position-relative">
        <div class="d-flex align-items-center">
          <div class="line-picture">
            <img :src="`${image(id)}`">
          </div>
          <div class="flex-grow-1 align-self-center">
            <h2
              class="line-name mb-0 text-fancy"
            >
              {{ name }}
            </h2>
            <div class="text-left">
              <LineState
                v-if="currentState"
                :current-state="currentState"
              />
            </div>
          </div>
          <div>
            <Healthcheck
              :element-id="id"
            />
          </div>
        </div>
      </div>
      <hr
        v-if="currentState"
        class="mt-2 mb-1"
      >
      <LineStateChange
        v-if="currentState && isHeader"
        :element-id="id"
        :current-state="currentState"
      />
      <hr
        v-if="isHeader"
        class="mb-1"
      >

      <div v-if="displayEnergy && energy">
        <CurrentPower
          v-bind="energy"
          :production="production ? production.done : null"
          :unit="production ? production.unit : null"
          class="current-power"
        />
        <hr class="my-1">
      </div>

      <div class="row">
        <div
          style="margin: auto"
          :style="{ height: disabled ? '30px' : '80px' }"
          class="col-12 col-sm-6"
        >
          <div
            v-if="disabled"
            class="text-center w-100 text-uppercase text-secondary"
          >
            {{ $t('general.disabled') }}
          </div>
          <VerticalOEEs
            v-if="!loading && !disabled"
            v-bind="oees"
            :target="targetOees"
            size="xs"
            style="font-size: 15px; overflow: hidden"
          />
        </div>

        <hr class="my-1">
        <div
          class="col-12 col-sm-6"
        >
          <LineActivity
            :line-id="id"
            :activities="activities || {}"
          />
        </div>
      </div>

      <div
        v-if="!loading && production && production.isUnitConversionComplete"
        class="mx-2"
      >
        <hr class="my-2">
        <ProductionWithNumbers
          :production="production"
          :performance="performance"
          :production-from-orders="productionFromOrders"
          class="mb-1"
        />

        <StateParetoChart
          :clickable="false"
          :height="17"
          :pareto-states="paretoStates"
          class="chart"
        />
      </div>
      <div v-if="loading">
        <Loader />
      </div>

      <div class="my-1">
        <LineActions
          :line-id="id"
          :current-state="currentState"
        />
      </div>

      <div v-if="!loading">
        <hr class="my-2">
        <LineRoutes
          :element-id="id"
          :oees="oees"
          :shift="shift"
          :target-oees="targetOees"
          :pareto-states="paretoStates"
          :production="production"
          :performance="performance"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import colors from '@/utils/colors';
import CalcDuration from '@/utils/calcDuration';
import VerticalOEEs from '@/components/charts/oees/VerticalOEEs';
import Healthcheck from '@/components/utils/Healthcheck';
import TimeProgress from '@/components/schedule/TimeProgress';
import StateParetoChart from '@/components/charts/state-pareto/StateParetoChart';
import ProductionWithNumbers from '@/components/charts/production/ProductionWithNumbers';
import { activityStatus } from '@/utils/dictionary';
import LineState from './mobile/LineState';
import LineStateChange from './mobile/LineStateChange';
import LineActions from './modules/LineActions';
import LineActivity from './modules/LineActivity';
import CurrentPower from './mobile/CurrentPower';
import LineRoutes from './modules/LineRoutes';

export default {
  props: {
    id: String,
    name: String,
    oees: Object,
    targetOees: Object,
    paretoStates: Array,
    currentState: Object,
    production: Object,
    shift: Object,
    disabled: Boolean,
    performance: Object,
    energy: Object,
    isHeader: Boolean,
    activities: Object,

  },
  components: {
    ProductionWithNumbers,
    StateParetoChart,
    LineStateChange,
    LineActions,
    LineState,
    TimeProgress,
    LineActivity,
    VerticalOEEs,
    Healthcheck,
    CurrentPower,
    LineRoutes,
  },
  computed: {
    ...mapGetters(['plantId', 'screenWidth', 'isMobile', 'splitByOrder']),
    ...mapGetters('plant', ['displayEnergy', 'productionFromOrders']),
    ...mapGetters('element', ['image']),
    loading() {
      return !this.production;
    },
    pendingOrder() {
      return this.activities?.activeOrder?.status === activityStatus.started ? this.activities?.activeOrder : undefined;
    },
  },
  methods: {
    colors,
    CalcDuration,
    toUnix(date) {
      return moment(date).unix();
    },
    formatTime(date) {
      return moment(date).format('HH:mm');
    },
    formatUnix(date) {
      return moment.unix(date).format('HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .state {
    font-size: 10px;
  }

  .line-name {
    font-size: 1.125rem;
    line-height: 1.25;
    text-align: left;
    font-weight: bold;
  }

  .shift-box {
    box-shadow: 0 1px 3px #efefef;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -5px;
    border-radius: 5px;
  }

  .header-row {
    padding: 15px 10px 5px 10px;
    border-top: 10px solid rgba(126, 126, 126, 0.2);
  }

  .line-picture {
    height: 40px;
    width: 40px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(150, 150, 150, 0.2);
    border-radius: 50%;
    margin-right: 12px;

    img {
      max-height: 40px;
    }
  }
</style>
