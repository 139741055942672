<template>
  <div class="row">
    <BFormGroup
      class="pl-3"
      :label="$t('general.view').toUpperCase()"
    >
      <BFormRadioGroup
        id="btn-radios-1"
        v-model="selectedView"
        button-variant="outline-primary"
        name="radio-btn-outline"
        size="sm"
        buttons
      >
        <template v-for="option in viewOptions">
          <BFormRadio
            :key="option.text"
            :value="option.value"
          >
            <span class="py-2 px-3">
              <i
                :class="{
                  'fas fa-chart-column': option.value === 'axis',
                  'fas fa-table': option.value === 'table'
                }"
                class="pr-1"
              />
              {{ option.text }}
            </span>
          </BFormRadio>
        </template>
      </BFormRadioGroup>
    </BFormGroup>

    <div class="form-group pl-3">
      <label class="bv-no-focus-ring col-form-label pt-0 text-uppercase">
        {{ $t('general.search') }}
      </label>
      <SearchInput @input="$emit('updateSearch', $event)" />
    </div>
  </div>
</template>

<script>
import SearchInput from '@core/components/SearchInput';

export default {
  props: {
    view: String,
  },
  data: () => ({
    selectedView: 'table',
    selectedAxis: 'startDate',
  }),
  components: { SearchInput },
  computed: {
    viewOptions() {
      return [
        { text: this.$t('activities.schedule.tableView'), value: 'table' },
        { text: this.$t('activities.schedule.axisView'), value: 'axis' },
      ];
    },
  },
  watch: {
    selectedView(value) {
      this.$emit('updateView', value);
    },
  },
  created() {
    this.selectedView = this.view;
  },
};
</script>

<style  lang="scss" scoped>
 .col-form-label {
  font-size: 14px;
}

 .search {
   border-radius: .25rem;
 }
</style>
