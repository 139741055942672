export default {
  moveHereToGroup: 'Переместить в этот столбец чтобы группировать',
  moveHereToHide: 'Перемест. столбец сюда, чтобы скрыть',
  sEmptyTable: 'В таблице отсутсвуют данные',
  sInfoFiltered: '(отфильтровано из _MAX_ записей)',
  sLoadingRecords: 'Загрузка...',
  sProcessing: 'Обработка...',
  sSearch: 'Поиск:',
  sZeroRecords: 'Совпадающих записей не найдено',
  oPaginate: {
    sFirst: 'Первый', sLast: 'Последний', sNext: 'Следующий', sPrevious: 'Предыдущий',
  },
  oAria: { sSortAscending: ': активировать для сорт. столбца по возраст.', sSortDescending: ': активировать для сорт. столбца по убыв.' },
};
