<template>
  <div
    ref="container"
    :style="{
      height: `${height}px`,
    }"
    class="position-relative my-2 text-center"
  >
    <div
      v-for="e in elements"
      :key="e.id"
      :class="{
        toggle,
        on: toggle && isOn(e.id),
        warn: toggle && isDisabled(e.id),
        running: toggle && isRunning(e.id),
      }"
      :style="{
        width: `${e.w}px`,
        height: `${e.h}px`,
        top: `${e.y}px`,
        left: `${e.x}px`
      }"
      class="box position-absolute"
      @click="toggleElement(e.id)"
    >
      <div class="icon">
        <i
          v-if="toggle"
          :class="getIcon(e.id)"
        />
      </div>

      <div
        class="d-flex flex-column justify-content-center h-100 position-relative"
        style="overflow: hidden"
      >
        <div class="name">
          {{ nameFun ? nameFun(e) : e.name }}
        </div>
        <div
          class="text-center text-success w-100"
          style="position: absolute; bottom: 0; line-height: 1"
        >
          <i
            v-if="isRunning(e.elementId)"
            class="ion ion-md-pulse"
          />
        </div>
      </div>
    </div>

    <Connections
      :paths="paths"
      :connection-type="connectionType"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Graph from '@/components/graph/Graph';
import Connections from '@/components/graph/Connections';

export default {
  props: {
    flow: {
      type: Graph,
      required: true,
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Array,
      default: () => [],
    },
    running: {
      type: Array,
      default: () => [],
    },
    nameFun: Function,
    maxHeight: Number,
    margin: {
      type: Number,
      default: 10,
    },
    connectionType: String,
  },
  data: () => ({
    width: null,
  }),
  components: {
    Connections,
  },
  computed: {
    ...mapGetters(['screenWidth']),
    ...mapGetters('plant', ['plantProperty']),
    nodes() {
      return this.flow.nodeList();
    },
    height() {
      if (!this.elements || this.elements.length === 0) return 0;
      const maxY = this.elements.reduce((acc, curr) => Math.max(curr.y, acc), 0);
      const size = this.elements[0].w;
      const height = maxY + size;
      if (this.maxHeight) {
        return Math.min(this.maxHeight, height);
      }
      return height;
    },
    flowLayouts() {
      return JSON.parse(this.plantProperty('FLOWS_LAYOUT') || '{}');
    },
    graphPositions() {
      const ids = this.nodes.map(e => e.id);
      if (!this.flow || ids.length === 0) return {};

      let positions = this.flow.getPositionsForNodes(ids);

      const flowLayout = this.flowLayouts[this.flow.id];

      if (flowLayout) {
        positions = flowLayout.reduce((acc, curr) => {
          acc[curr.id] = { x: curr.x, y: curr.y };
          return acc;
        }, {});
      }

      // Find max X and Y coordinate
      const {
        maxX, minX, maxY, minY,
      } = Object.values(positions)
        .reduce((acc, { x, y }) => ({
          maxX: Math.max(acc.maxX, x),
          maxY: Math.max(acc.maxY, y),
          minX: Math.min(acc.minX, x),
          minY: Math.min(acc.minY, y),
        }), {
          maxX: 0, maxY: 0, minX: 9999, minY: 9999,
        });

      let size = Math.min(500, this.width / ((maxX - minX) + 1));
      if (this.maxHeight) {
        size = Math.min(size, this.maxHeight / ((maxY - minY) + 1));
      }

      const dx = maxX - minX + 1;
      return ids.reduce((acc, id) => {
        const pos = positions[id];
        const { x, y } = pos || { x: 0, y: 0 };
        const marginSize = (size / this.margin);

        acc[id] = {
          x: (x * size) - (minX * size) + (this.width / 2) - ((dx * size) / 2) + (marginSize / 2),
          y: (y * size) - (minY * size) + (marginSize / 2),
          w: size - marginSize,
          h: size - marginSize,
        };
        return acc;
      }, {});
    },
    paths() {
      if (!this.flow) return [];
      return this.flow.edges.map(({ from, to }) => ({
        fromPos: this.getPos(from),
        toPos: this.getPos(to),
      }))
        .filter(x => x.fromPos && x.toPos);
    },
    elements() {
      return this.nodes.map(({ id, name }) => {
        const pos = this.graphPositions[id];
        return {
          id,
          name: pos.h > 100 ? name : (name || '?').substring(0, 3),
          ...pos,
        };
      });
    },
  },
  watch: {
    screenWidth() {
      if (this.$refs.container) {
        this.width = this.$refs.container.offsetWidth;
      }
    },
  },
  methods: {
    isOn(elementId) {
      return this.enabled.findIndex(id => id === elementId) > -1;
    },
    isRunning(elementId) {
      return this.running.findIndex(id => id === elementId) > -1;
    },
    isDisabled(elementId) {
      return this.disabled.findIndex(id => id === elementId) > -1;
    },
    getIcon(elementId) {
      if (this.isOn(elementId)) {
        if (this.isDisabled(elementId)) {
          return 'far fa-hand-paper text-warning';
        }
        if (this.isRunning(elementId)) {
          return '';
        }
        return 'ion ion-ios-play-circle text-success';
      }
      return '';
    },
    toggleElement(elementId) {
      if (!this.toggle) return;
      const eindex = this.enabled.findIndex(e => e === elementId);
      const dindex = this.disabled.findIndex(e => e === elementId);

      if (eindex === -1) {
        this.$emit('update:enabled', this.enabled.concat([elementId]));
      } else {
        this.$emit('update:enabled', this.enabled.filter(e => e !== elementId));
        if (dindex === -1) {
          this.$emit('update:disabled', this.disabled.concat([elementId]));
        } else {
          this.$emit('update:disabled', this.disabled.filter(e => e !== elementId));
        }
      }
    },
    getPos(elementId) {
      const pos = this.graphPositions[elementId];
      if (!pos) return null;
      const size = pos.w;
      return {
        x: pos.x + size / 2,
        y: pos.y + size / 2,
      };
    },
  },
  mounted() {
    if (this.$refs.container) {
      this.width = this.$refs.container.offsetWidth;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .box {
    border: 1px solid rgba(100,100,100,0.5);
    box-shadow: 0 0 8px rgba(100,100,100,0.3);
    border-radius: 20px;
    background-color: white;
    z-index: 20;

    &.toggle {
      cursor: pointer;
    }

    &.on {
      border-color: $green;

      &.running {
        border-color: $grey;
      }

      &.warn {
        border-color: $orange;
      }
    }
    &.off {
      border-color: $grey;
    }

    &.on, &.off {
      &:hover {
        box-shadow: 0 0 8px rgba(100,100,100,0.5)
      }
    }

    .icon {
      background-color: rgba(white, 0.9);
      box-shadow: 0 0 2px rgba(150, 150, 150, 0.3);
      position: absolute;
      top: -4px;
      right: -4px;
      font-size: 20px;
      line-height: 1;
      border-radius: 50%;
    }
  }
  .name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }
</style>
