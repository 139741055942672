<template>
  <div class="p-3 machine-params">
    <div class="params">
      <div class="mb-1">
        <div class="title">
          <i class="pr-1 ion ion-ios-stats" />
          {{ $t('monitoring.machine.currentParameters') }}
        </div>
      </div>

      <div
        v-if="!haveData"
        class="mt-3"
      >
        <Loader />
      </div>
      <div v-else>
        <Parameter
          v-for="(param, i) in signals"
          :key="i"
          v-bind="param"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import calcDuration from '@/utils/calcDuration';
import loopRequest from '@/mixins/loopRequest';
import Parameter from './Parameter';

export default {
  props: {
    machineId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    signals: null,
    haveData: false,
    error: null,
  }),
  components: {
    Parameter,
  },
  mixins: [loopRequest('request', 5000)],
  computed: {
    ...mapGetters(['plantId']),
  },
  methods: {
    calcDuration,
    ...mapActions('element', ['getElementsSignals']),
    request() {
      return this.getElementsSignals({
        params: {
          plantId: this.plantId,
          query: {
            id: this.machineId,
          },
        },
      })
        .then(({ data }) => {
          this.haveData = true;
          if (data.length > 0) {
            this.signals = data[0].signals;
          }
        })
        .catch(({ data }) => {
          this.error = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .params {
    position: relative;
    font-size: 12px;
  }

  .param-name {
    font-weight: 300;
    font-size: 9px;
  }

  .param-value {
    font-size: 12px;
    right: 0;
  }

  .alarms {
    font-size: 12px;
    position: relative;
  }

  .alarm-name {
    font-weight: normal;
  }

  .alarm-duration {
    float: right;
    font-size: 12px;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .machine-params {
    box-shadow: 0 0 8px rgba(150, 150, 150, 0.5);
    border-radius: 15px;
    width: 300px;
  }
</style>
