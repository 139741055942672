import Vapi from 'vuex-rest-api';
import { getUpdaterFor, getDeleterFor } from '@core/utils/storeUpdater';
import axios from '@/api/axios';
import { ACTIVITY_UPDATE, ACTIVITY_DELETE } from '@/store/mutation-types';

const store = new Vapi({
  axios,
  state: {
    plan: [],
  },
})
  .get({
    action: 'getProductionPlan',
    property: 'plan',
    path: ({ plantId, startDate, endDate }) => `/aps/${plantId}/plan/${startDate}/${endDate}`,
  })
  .post({
    action: 'addActivity',
    path: ({ plantId }) => `/aps/${plantId}`,
  })
  .put({
    action: 'updateActivity',
    path: ({ plantId, activityId }) => `/aps/${plantId}/${activityId}`,
  })
  .delete({
    action: 'deleteActivity',
    path: ({ plantId, activityId }) => `/aps/${plantId}/${activityId}`,
  })
  .getStore();

store.mutations[ACTIVITY_UPDATE] = getUpdaterFor('plan', 'activityId');
store.mutations[ACTIVITY_DELETE] = getDeleterFor('plan', 'activityId');

export default store;
