import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const downtimes = new Vapi({
  axios,
  state: {},
})

  .patch({
    action: 'switchElementState',
    path: ({
      plantId, elementId, state,
    }) => `/v2/plant/${plantId}/element/${elementId}/${state}`,
  })
  .get({
    action: 'getPlantOee',
    path: ({
      plantId, elementId, startDate, endDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/oee/${startDate}/${endDate}`,
  })
  .get({
    action: 'getPlantEnergy',
    path: ({
      plantId, elementId, startDate, endDate,
    }) => `/v2/plant/${plantId}/element/${elementId}/energy/${startDate}/${endDate}`,
  })
  .getStore();

export default downtimes;
