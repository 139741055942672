<template>
  <div
    class="d-flex flex-column w-100 h-100"
    style="max-width: 100%"
  >
    <div class="d-flex justify-content-between">
      <div>
        <button
          class="btn btn-outline-secondary icon-btn"
          @click="edit"
        >
          <i class="ion ion-md-create" />
        </button>
      </div>
      <div class="sticky-top">
        <ElementMinimap
          v-if="flow"
          :flow="flow"
          :colors="elementColors"
          :names="elementNames"
          :scroll-width="scrollWidth"
          :scroll-height="scrollHeight"
          :scroll-left="scrollLeft"
          :connection-type="'straight'"
          style="margin: auto;"
          @scroll="updateScroll"
        />
      </div>
      <div style="width: 35px" />
    </div>
    <div
      ref="container"
      class="position-relative w-100 flex-grow-1 graph"
      style="overflow: hidden; max-width: 100%"
      :style="{ height: `${graphHeight + 200}px` }"
      @mousedown="mouseDown"
      @scroll="updateLeftScroll"
    >
      <LiveElementData :ids="elementIds" />
      <LiveSignals
        :element-ids="elementIds"
        :signal-ids="signalIds"
        @currentValues="currentValues = $event"
      />

      <div
        v-for="e in elements"
        :key="e.id"
        :style="{
          width: `${e.w}px`,
          height: `${e.h}px`,
          top: `${e.y}px`,
          left: `${e.x}px`
        }"
        class="position-absolute"
        style="z-index: 1; border-radius: 20px"
      >
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 14px"
        >
          {{ e.parentName || '&nbsp;' }}
        </div>
        <Machine
          v-bind="e"
          class="position-relative"
        >
          <div
            v-for="(dir, id) in (elementsParams[e.id] || [])"
            :key="id"
            :class="`pos-${dir}`"
            class="box-param d-flex justify-content-center align-items-center"
          >
            <div
              v-if="currentValues[id]"
              style="max-width: 100%; max-height: 100%"
              class="param"
            >
              <div
                class="small text-uppercase "
                style="font-size: 10px; line-height: 1"
              >
                {{ currentValues[id].description }}
              </div>
              <div style="font-size: 15px; line-height: 1; font-weight: bold; padding-top: 3px">
                {{ currentValues[id].value }} {{ currentValues[id].unit }}
              </div>
            </div>
          </div>
        </Machine>
      </div>
      <Connections
        :style="{ width: `${graphWidth}px`}"
        :paths="paths"
        :connection-type="'straight'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LiveElementData from '@/components/data/LiveElementData';
import Machine from '@/components/machine/Machine';
import ElementMinimap from '@/components/graph/ElementMinimap';
import Connections from '@/components/graph/Connections';
import LiveSignals from '@/components/data/LiveSignals';
import {
  nodePositions, graphWidth, graphHeight, getPath,
} from '@/components/graph/layout';

export default {
  props: {
    flowId: String,
  },
  data: () => ({
    width: null,
    height: null,
    scrollLeft: 0,
    scrollHeight: 0,
    mousePos: null,
    currentValues: {},
  }),
  components: {
    Connections,
    ElementMinimap,
    LiveElementData,
    LiveSignals,
    Machine,
  },
  computed: {
    ...mapGetters(['screenWidth', 'plantId']),
    ...mapGetters('element', ['element']),
    ...mapGetters('work', ['workStateOfEvent']),
    ...mapGetters('plant', [
      'structure',
      'plantProperty',
      'plantGraphs',
      'flowLayout',
    ]),
    flow() {
      return this.plantGraphs.find(g => g.id === this.flowId);
    },
    graphWidth() {
      return graphWidth(this.graphPositions);
    },
    graphHeight() {
      return graphHeight(this.graphPositions);
    },
    scrollWidth() {
      return this.width / this.graphWidth;
    },
    elementIds() {
      if (!this.flow) return [];
      return this.flow.nodeList().map(x => x.id);
    },
    graphPositions() {
      const container = { overflow: true };
      const box = { forceSquare: true, maxHeight: 250, maxWidth: 260 };

      return nodePositions(this.flow, this.flowLayout(this.flowId), container, box, 10, 10);
    },
    elementsParams() {
      const layout = this.flowLayout(this.flowId);
      if (!layout) return {};
      const res = {};

      layout
        .filter(p => p.config)
        .forEach(s => {
          res[s.id] = s.config;
        });

      return res;
    },
    signalIds() {
      return Object.values(this.elementsParams)
        .flatMap(config => Object.keys(config));
    },
    paths() {
      if (!this.flow) return [];
      return this.flow.edges
        .map(e => getPath(e, this.graphPositions))
        .filter(x => x);
    },
    elements() {
      if (!this.flow) return [];
      return this.flow.nodeList()
        .map(({ id }) => {
          const pos = this.graphPositions[id];
          const parentId = this.structure.parent(id, 2);
          return {
            parentName: (this.element(parentId) || {}).description,
            ...this.element(id),
            ...pos,
            id,
          };
        });
    },
    elementColors() {
      return this.elements.reduce((acc, m) => {
        this.$set(acc, m.id, this.getColor(m));
        return acc;
      }, {});
    },
    elementNames() {
      return this.elements.reduce((acc, m) => {
        this.$set(acc, m.id, m.name.slice(0, 1).toUpperCase());
        return acc;
      }, {});
    },
  },
  watch: {
    screenWidth() {
      if (this.$refs.container) {
        this.width = this.$refs.container.offsetWidth;
      }
    },
  },
  methods: {
    getColor(m) {
      if (!m.currentState) return this.$color('grey');
      const state = this.workStateOfEvent(m.currentState.eventId);
      return state ? this.$color(state.colorCode) : this.$color('grey');
    },
    mouseDown(e) {
      this.mousePos = { x: e.x, y: e.y };
    },
    updateScroll({ left }) {
      this.$refs.container.scrollLeft = left * this.graphWidth;
    },
    updateLeftScroll() {
      if (!this.$refs.container) return;
      const left = this.$refs.container.scrollLeft;
      this.scrollLeft = (left / this.graphWidth);
    },
    edit() {
      this.$router.push(`/${this.plantId}/switchboard/${this.flowId}`);
    },
  },
  mounted() {
    if (this.$refs.container) {
      this.width = this.$refs.container.offsetWidth;
      this.height = this.$refs.container.offsetHeight;
    }

    this.mouseMove = e => {
      if (!this.mousePos || !this.$refs.container) return;
      const diffX = e.pageX - this.mousePos.x;

      this.$refs.container.scrollLeft -= diffX;
      this.mousePos.x = e.pageX;
    };

    this.mouseUp = () => {
      this.mousePos = null;
    };

    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
  },
};
</script>

<style lang="scss" scoped>
  .graph {
    user-select: none;
  }

  .box-param {
    position: absolute;
    $size: 50px;
    flex-direction: row;

    .param {
      background-color: rgba(white, 0.7);
      text-align: center;
    }

    &.pos-t {
      width: 100%;
      left: 0;
      height: $size;
      top: -$size - 15;
    }
    &.pos-b {
      width: 100%;
      left: 0;
      height: $size;
      bottom: -$size;
    }
    &.pos-l {
      height: 100%;
      width: $size*2;
      left: -$size*2 - 10;
      top: 0;
      flex-direction: column;
    }
    &.pos-r {
      height: 100%;
      top: 0;
      width: $size*2;
      right: -$size*2 - 10;
      flex-direction: column;
    }
  }
</style>
