<template>
  <span style="font-size: 15px">
    <RouterLink
      v-if="url"
      :to="url"
    >
      <span v-if="!icon">{{ display }}</span>
      <span v-else><i :class="icon" /></span>
    </RouterLink>
    <span v-else>
      <span v-if="!icon">{{ display }}</span>
      <span v-else><i :class="icon" /></span>
    </span>
    <span class="chevron">
      <i class="ion ion-ios-arrow-forward" />
    </span>
  </span>
</template>

<script>
export default {
  props: {
    display: String,
    url: String,
    selected: String,
    amount: Number,
    index: Number,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  a {
    color: $ilabo;
  }

  .chevron {
    padding: 0 15px;
    color: #999;
  }

  a:hover {
    color: $green;
    text-decoration: none;
  }
</style>
