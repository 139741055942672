<template>
  <div
    v-if="rootLoading"
    class="text-center py-5"
  >
    <BSpinner variant="primary" />
  </div>

  <TrendCanvas
    v-else
    :data="data"
    :from-x="fromX"
    :from-y="fromY"
    :request="requestData"
  />
</template>

<script>
import TrendCanvas from '@/components/trend-canvas/TrendCanvas';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import trendCanvasDataParser from '@/components/trend-canvas/TrendCanvasDataParser';

const canvasScope = {
  year: 'year',
  month: 'month',
  week: 'isoWeek',
  day: 'day',
};

export default {
  data: () => ({
    data: {
      x: [],
      y: [],
    },
    fromX: 0,
    fromY: 0,
    rootLoading: false,
  }),
  components: { TrendCanvas },
  mixins: [
    trendCanvasDataParser,
  ],
  computed: {
    ...mapGetters([
      'plantId',
      'propertyDayStart',
    ]),
    ...mapGetters('report', [
      'selectedTrendScope',
      'selectedTargetTrendScope',
      'elementIds',
    ]),
  },
  watch: {
    selectedTargetTrendScope(scope) {
      if (!scope) return;
      this.handleUpdateScopeWithTarget(scope);
    },
    elementIds() {
      this.handleUpdateScopeWithTarget(this.selectedTrendScope);
    },
  },
  methods: {
    ...mapActions([
      'getEnergy',
    ]),
    async handleUpdateScopeWithTarget(scope) {
      this.rootLoading = true;

      let targetTileUnit = '';
      let targetAmount = '';

      switch (scope) {
        case canvasScope.day:
          targetTileUnit = 'hour';
          targetAmount = 24;
          break;
        case canvasScope.week:
          targetTileUnit = 'day';
          targetAmount = 7;
          break;
        case canvasScope.month:
          targetTileUnit = 'isoWeek';
          targetAmount = 5;
          break;
        case canvasScope.year:
        default:
          targetTileUnit = 'month';
          targetAmount = 12;
          break;
      }

      this.$store.commit('report/setSelectedTrendScope', scope);

      await this.requestData(
        moment()
          .subtract(1, scope)
          .startOf(scope)
          .startOf(targetTileUnit)
          .seconds(-this.propertyDayStart)
          .unix(),
        moment()
          .subtract(1, scope)
          .startOf(scope)
          .startOf(targetTileUnit)
          .seconds(-this.propertyDayStart)
          .unix(),
        targetAmount,
        targetTileUnit,
        true,
        true,
      );

      this.$store.commit('report/setSelectedTargetTrendScope', '');

      this.rootLoading = false;
    },
    async requestData(
      startX,
      startY,
      amount,
      aggregate,
      updateX = false,
      updateY = false,
    ) {
      const parsedAggregate = aggregate === 'isoWeek' ? 'week' : aggregate;

      if (updateX) {
        const endX = moment.unix(startX).add(amount, aggregate).unix();
        const { data: dataX } = await this.getEnergy({
          params: {
            startDate: startX,
            endDate: endX,
            plantId: this.plantId,
            elementId: this.elementIds[0] || this.plantId,
            query: {
              aggregate: parsedAggregate,
            },
          },
        });

        this.data.x = this.parseDataForTrendCanvas(
          startX,
          amount,
          dataX.map(i => ({
            ...i,
            energy: Math.round(i.energy),
          })),
          'from',
          aggregate,
          'energy',
        );
      }

      if (updateY) {
        const endY = moment.unix(startY).add(amount, aggregate).unix();
        const { data: dataY } = await this.getEnergy({
          params: {
            startDate: startY,
            endDate: endY,
            plantId: this.plantId,
            elementId: this.elementIds[0] || this.plantId,
            query: {
              aggregate: parsedAggregate,
            },
          },
        });
        this.data.y = this.parseDataForTrendCanvas(
          startY,
          amount,
          dataY.map(i => ({
            ...i,
            energy: Math.round(i.energy),
          })),
          'from',
          aggregate,
          'energy',
        );
      }

      this.fromX = this.data.x[0].unix;
      this.fromY = this.data.y[0].unix;
    },
  },
  created() {
    this.$emit('selected', 'energy-trends');

    this.handleUpdateScopeWithTarget('month');
  },
};
</script>

<style scoped>

</style>
