<template>
  <div
    class="text-center details"
  >
    <div v-if="skuNumber">
      {{ $t("orders.sku") }}: {{ skuNumber }}
    </div>
    <div v-else>
      <span class="text-dashed">-</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    activity: Object,
  },
  computed: {
    ...mapState({
      items: state => state.item.items,
    }),
    skuNumber() {
      return this.items?.find(i => i.id === this.activity.productId)?.skuNo;
    },
  },
};
</script>

<style lang="scss" scoped>

.details {
  color: gray;
  font-size: 0.656rem;
}

</style>
