<template>
  <div class="text-center action-boxes-wrapper">
    <div class="action-boxes row no-gutters mx-0 justify-content-center">
      <div
        v-if="!isMobile"
        class="col-6 col-md-auto col-lg-12 order-md-2 order-lg-1 mx-3 d-flex"
      >
        <div
          class="action-container andon-btn"
          @click.stop.prevent="showAndon"
        >
          <i class="fas fa-tv" />
        </div>
        <a
          v-if="cockpitUrl"
          class="action-container andon-btn"
          :href="`${cockpitUrl}/${plantId}/panel/line/${lineId}`"
          @click.stop=""
        >
          <i class="fas fa-tools" />
        </a>
      </div>

      <div class="col-12 col-md col-lg-12 order-md-1 order-lg-2">
        <div class="row justify-content-center align-items-center no-gutters">
          <!-- Advices button -->
          <div class="col-4 col-md-3 col-lg-12 pt-1">
            <div
              class="line-action"
              @click.stop="showAdvices"
            >
              <div class="d-flex justify-content-center">
                <div
                  :class="advicesCount == 0 ? 'text-secondary' : 'text-primary'"
                  class="icon px-2 text-nowrap d-flex align-items-center"
                >
                  <div style="width: 1.5rem">
                    <i class="far fa-lightbulb" />
                  </div>
                  {{ advicesCount }}
                </div>
                <div class="title flex-grow-1">
                  {{ $t('monitoring.lineInfo.advices') }}
                </div>
              </div>
            </div>
          </div>

          <!-- Worst 3 button -->
          <div class="col-4 col-md-3 col-lg-12 pt-1">
            <WorstsModal
              :plant-id="plantId"
              :element-id="lineId"
              class="line-action"
              @worsts="worsts = $event"
              @openProblems="$emit('openProblems')"
            >
              <div class="d-flex justify-content-center">
                <div
                  :class="worstsCount > 0 ? 'text-danger' : 'text-secondary'"
                  class="icon px-2 text-nowrap d-flex align-items-center"
                >
                  <div style="width: 1.5rem">
                    <i class="far fa-clock" />
                  </div>
                  {{ worstsCount }}
                </div>
                <div class="title flex-grow-1">
                  {{ $t('monitoring.lineInfo.problems') }}
                </div>
              </div>
            </WorstsModal>
          </div>

          <!-- Warnings button -->
          <div
            class="col-4 col-md-3 col-lg-12 pt-1"
          >
            <div
              :class="warningsNum > 0 ? 'pulse-warning-icon' : ''"
              class="line-action"
              @click.stop="showWarnings"
            >
              <div class="d-flex justify-content-center">
                <div
                  :class="warningsNum > 0 ? 'text-danger' : 'text-secondary'"
                  class="icon px-2 text-nowrap d-flex align-items-center"
                >
                  <div style="width: 1.5rem">
                    <i class="fas fa-exclamation" />
                  </div>
                  {{ warningsNum }}
                </div>
                <div class="title flex-grow-1">
                  {{ $t('monitoring.warnings') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { elementType, notificationTypes } from '@/utils/dictionary';
import colors from '@/utils/colors';
import WorstsModal from '@/components/modals/WorstsModal';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
    generalInfo: Object,
    currentState: Object,
  },
  data: () => ({
    worsts: null,
    showAreYouSure: false,
    downtimeModal: false,
    startError: null,
    startPending: false,
    notifications: [],
    elementTypeEnum: elementType,
  }),
  components: {
    WorstsModal,
  },
  computed: {
    ...mapGetters([
      'tenantModules',
      'plantId',
      'isMobile',
      'startDate',
      'endDate',
    ]),
    ...mapGetters('plant', [
      'plantProperty',
      'structure',
      'newAndonScreen',
    ]),
    ...mapGetters('work', [
      'workEvent',
    ]),
    ...mapGetters('element', [
      'elementType',
    ]),
    ...mapState({
      elementNotifications: state => state.notify.notifications.elementNotifications,
    }),
    cockpitUrl() {
      return this.tenantModules.find(x => x.module === 'cockpit')?.url;
    },
    worstsCount() {
      return this.worsts ? this.worsts.length : 0;
    },
    lineAdvices() {
      return this.structure.allLowerChildren(this.lineId)
        .filter(id => this.elementType(id) === this.elementTypeEnum.machine)
        .flatMap(id => this.elementNotifications[id] || [])
        .concat(this.elementNotifications[this.lineId] || []);
    },
    advicesCount() {
      return this.lineAdvices
        .filter(x => x.type === notificationTypes.hint && (!x.closingDate || x.closingDate * 1000 > Date.now()))
        .length;
    },
    warningsNum() {
      return this.lineAdvices
        .filter(x => x.type === notificationTypes.warning && (!x.closingDate || x.closingDate * 1000 > Date.now()))
        .length;
    },
  },
  methods: {
    colors,
    showAdvices() {
      this.$store.commit('notify/notificationExplorer', {
        elementId: this.lineId,
        selectTypes: [notificationTypes.hint],
      });
    },
    showWarnings() {
      this.$store.commit('notify/notificationExplorer', {
        elementId: this.lineId,
        selectTypes: [notificationTypes.warning],
      });
    },
    showJobs() {
      this.ordersModal = true;
    },
    showAndon() {
      if (this.newAndonScreen) {
        this.$router.push(`/${this.plantId}/andon2/${this.lineId}`);
      } else {
        this.$router.push(`/${this.plantId}/andon/${this.lineId}`);
      }
    },
    showPanel() {
      this.$router.push(`/${this.plantId}/panel/line/${this.lineId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .action-boxes-wrapper {
    width: 100%;
    position: relative;
  }

  .xs-worsts {
    position: relative;
    top: -2px;
  }

  .o-title {
    font-weight: 500;
    font-size: 20px;
  }

  .line-action {
    transition: box-shadow 300ms;
    cursor: pointer;
    width: 100%;
    padding: 2px 0px;
    font-size: 15px;
    border-radius: 20px;

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
    }

    .title {
      font-size: 12px;
      line-height: 27px;
      text-align: left;
    }

    .icon {
      font-size: 1rem
    }
  }

  .action-boxes {
    hr {
      width: 100%;
      margin: 0;
    }
    > div {
      padding: 0;
      margin: 1px 0;
    }
  }

  .double-icon {
    max-width: 100px;
    margin: auto;
  }

  .row-actions {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 40px;
  }

  .action-container {
    transition: border-color 500ms, background-color 500ms;
    text-align: center;
    font-size: 15px;
    border: 2px solid rgba(150, 150, 150, 0.2);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-left: 5px;
    line-height: 33px;
    cursor: pointer;

    &:hover {
      border-color: $blue;
      background-color: rgba($blue, 0.1);
    }
  }

  .action-warning:hover {
    border-color: $orange;
    background-color: rgba($orange, 0.1);
  }

  .action-danger:hover {
    border-color: $red;
    background-color: rgba($red, 0.1);
  }

  @keyframes pulse {
    from {
      background-color: $orange;
    }
    to {
      background-color: white;
    }
  }

  .andon-btn {
    color: rgb(124, 124, 124);
  }

  .pulse-warning-icon {
    animation-duration: 1.5s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @media screen and (max-width: 1600px){
    .line-action .title {
      display: none;
    }
  }
</style>
