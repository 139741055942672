import moment from 'moment';

// checks if the period start - end
// is in any part between baseStart and baseEnd
export function isInRange(baseStart, baseEnd, start, end) {
  if (start > end) return false;
  return start <= baseEnd && end >= baseStart;
}

// creates all subpoints that includes point startDate and point endDate
export function genSubPoints(start, groupSize, point) {
  const { startDate, endDate } = point;
  let currStart = start;
  const res = [];
  while (currStart < endDate) {
    const currEnd = currStart + groupSize;
    if (currEnd >= startDate) {
      res.push({
        startDate: Math.max(currStart, startDate),
        endDate: Math.min(currEnd, endDate),
      });
    }
    currStart += groupSize;
  }
  return res;
}

// adds empty points to provided list until targetDate is next data point
const addEmptyPoints = (list, startDate, targetDate, groupSize) => {
  const index = list.length - 1;
  let start = startDate;
  // check if last element already exist
  if (list.length !== 0 && list[index].startDate === start) {
    start += groupSize;
  }

  while (start + groupSize <= targetDate) {
    list.push({
      startDate: start,
      avg: null,
      min: null,
      max: null,
      n: 0,
    });
    start += groupSize;
  }

  return start;
};

export function aggregator(values, startDate, endDate, groupSizeInSeconds, isStepped) {
  if (!startDate) throw Error('StartDate must be defined');
  if (!endDate) throw Error('EndDate must be defined');
  if (startDate > endDate) throw Error('StartDate must be before EndDate');

  let currStart = startDate;
  const groupSize = groupSizeInSeconds;

  const reduced = values
    .filter(val => isInRange(startDate, endDate, val.startDate, val.endDate))
    .sort((a, b) => a.startDate - b.startDate)
    .reduce((acc, curr) => {
      // datapoint end too early, get next
      if (curr.endDate <= currStart) return acc;
      // datapoint start too late, generate empty
      if (currStart + groupSize <= curr.startDate) {
        currStart = addEmptyPoints(acc, currStart, curr.startDate, groupSize);
      }
      // now curr must be in range
      const subPoints = genSubPoints(currStart, groupSize, curr);
      // connect generated subpoints with the result
      subPoints.forEach(point => {
        if (point.endDate > endDate) return;
        const duration = point.endDate - point.startDate;
        const { value } = curr;
        const index = acc.length - 1;

        if (acc.length !== 0 && acc[index].startDate + groupSize > point.startDate) {
          const last = acc[index];
          acc[index].min = Math.min(last.min, value);
          acc[index].max = Math.max(last.max, value);

          if (isStepped) {
            // if "isStepped" just save value with the longest duration
            if (duration > last.maxDuration) {
              acc[index].maxDuration = duration;
              acc[index].avg = value;
            }
          } else {
            acc[index].avg = ((last.avg * last.n) + (value * duration)) / (last.n + duration);
          }

          acc[index].n += duration;
        } else {
          acc.push({
            startDate: currStart,
            min: curr.value,
            max: curr.value,
            avg: curr.value,
            values: [{ duration, value: curr.value }],
            n: duration,
          });
        }

        if (point.endDate >= currStart + groupSize) {
          currStart += groupSize;
        }
      });
      return acc;
    }, []);

  addEmptyPoints(reduced, currStart, endDate, groupSize);

  return reduced;
}

// For one history of element
export function splitWorklogsToBuckets(worklogs, start, end, bucketInSeconds, now) {
  const worklogsCopy = worklogs.slice();
  const result = [];
  const bucket = [];
  let current = start;
  let wl = worklogsCopy.shift();

  const closeBucket = () => {
    result.push({
      start: current,
      end: current + bucketInSeconds,
      worklogs: JSON.parse(JSON.stringify(bucket)),
    });
    bucket.length = 0;
    current += bucketInSeconds;
  };
  while (wl) {
    const ed = Math.min(wl.endDate || end, now);
    if (current + bucketInSeconds < wl.startDate) {
      closeBucket();
    } else if (ed >= current) {
      let sd = Math.max(wl.startDate, current);
      // worklog longer than the bucket
      while (current + bucketInSeconds < ed) {
        const endDate = Math.min(current + bucketInSeconds, sd + bucketInSeconds);
        bucket.push({
          ...wl,
          startDate: sd,
          endDate,
        });
        sd = endDate;
        closeBucket();
      }

      bucket.push({
        ...wl,
        endDate: ed,
        startDate: sd,
      });

      closeBucket();
    } else {
      wl = undefined;
    }
  }
  return result;
}

export function groupWorkLogsToBuckets(logOfLogs, start, end, now, bucketInSeconds, groupKey) {
  if (!logOfLogs) return [];
  const combined = logOfLogs.flatMap(el => el.log);
  const groupLabels = combined.reduce((acc, curr) => {
    const index = acc.findIndex(el => el.id === curr[groupKey].id);
    if (index === -1) {
      acc.push(curr[groupKey]);
    }
    return acc;
  }, []);

  const buckets = bucketInSeconds === null ? [{ start, end, combined }]
    : logOfLogs.map(({ log }) => log.map(l => splitWorklogsToBuckets([l], start, end, bucketInSeconds, now)).flat())
      .flatMap(e => e)
      .reduce((acc, curr) => {
        const index = acc.findIndex(el => el.start === curr.start);
        if (index === -1) {
          acc.push(curr);
        } else {
          acc[index].worklogs = acc[index].worklogs.concat(curr.worklogs);
        }
        return acc;
      }, []);

  return groupLabels.map(we => ({
    [groupKey]: we,
    buckets: buckets.map(b => {
      const accumm = b.worklogs
        .filter(w => !!w[groupKey] && w[groupKey].id === we.id)
        .reduce((acc, curr) => {
          const endDate = Math.min((curr.endDate || now), end);
          const startDate = Math.max(curr.startDate, start);
          if (endDate <= startDate) return acc;

          acc.duration += Math.max(0, (endDate - startDate));
          acc.number += 1;

          return acc;
        }, { duration: 0, number: 0 });

      const { totalTime, totalNumber } = b.worklogs
        .filter(w => !!w[groupKey])
        .reduce((acc, curr) => {
          const endDate = Math.min((curr.endDate || now), end);
          const startDate = Math.max(curr.startDate, start);
          if (endDate <= startDate) return acc;

          acc.totalTime += Math.max(0, (endDate - startDate));
          acc.totalNumber += 1;
          return acc;
        }, { totalTime: 0, totalNumber: 0 });

      return {
        start: b.start,
        end: b.end,
        duration: accumm.duration,
        number: accumm.number,
        timePercent: (accumm.duration / totalTime) * 100,
        numberPercent: (accumm.number / totalNumber) * 100,
      };
    })
      .filter(b => moment(b.start * 1000).isBefore((end || now) * 1000)),
  }));
}

export default aggregator;
