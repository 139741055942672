export default {
  'rules': 'Regels',
  'addRule': 'Regel toevoegen',
  'countingOEE': 'Includeren in OEE',
  'excludeOEE': 'Excluderen in OEE',
  'thresholds': 'Drempelwaardes',
  'saved': 'Opgeslagen',
  'baseUnit': 'Basis eenheid',
  'notSelected': 'Niet geselecteerd',
  'saveFailed': 'Opslaan mislukt',
  'addMachine': 'Machine toevoegen aan filters',
  'rule': {
    'rule': 'Regel',
    'condition': 'Conditie',
    'addAndCondition': 'EN',
    'showAdvanced': 'Gebruik geavanceerde editor',
    'info': 'Machine status wordt bepaald op basis van de regels hieronder. Wanneer een signaalwaarde verandert,\\n zal de rule engine de regels hieronder analyseren beginnende bij de hoogste prioriteit.\\n De eerste regel welke \'waar\' is zal de nieuwe status bepalen.',
    'ruleType': 'Type regel',
    'eventType': 'Simpele voorwaarden',
    'cSharpType': 'C#-functie',
    'timeBasedRule': 'Op tijd gebaseerde regel',
    'script': 'Script'
  },
  'events': {'forcedOnly': 'Alleen geforceerd', 'showSystem': 'Toon systeem gebeurtenissen'},
  'value': 'Waarde',
  'priority': 'Prioriteit',
  'dayOfWork': 'Werkdag',
  'plantConfiguration': 'Fabriek configuratie',
  'machineSignals': 'Machine signalen',
  'signals': 'Signalen',
  'signalsExport': 'Exporteer signalen',
  'logixGateConfigExport': 'Exporteerd LogixGate configuratie',
  'signalsImport': 'Importeer signalen',
  'workEventsImport': 'Importeer problemen',
  'csvImport': 'Importeer CSV',
  'csvExport': 'Exporteer CSV',
  'properties': 'Eigenschappen',
  'signalsAndProperties': 'Signalen en eigenschappen',
  'machineLicenses': 'Machine licenties',
  'addSignal': 'Signaal toevoegen',
  'addProperty': 'Eigenschap toevoegen',
  'electricity': 'Elektriciteit',
  'electricityBaseline': 'Elektriciteit grondwaarde',
  'electricityMax': 'Gemiddelde maximale elektriciteit',
  'shiftDefinitions': 'Dienst definities',
  'info': 'Informatie',
  'deleteInfo': 'Druk op de knop \'Opslaan\' om te verwijderen',
  'differentTimezoneInfo': 'U bevindt zich in een andere tijdzone dan de fabriek',
  'differentTimezoneSettingsInfo': 'Onderstaande instellingen zijn van toepassing op de fabriek tijdzone',
  'differentTimezoneSelectorInfo': 'We zullen de tijd tonen aangepast op uw tijdzone',
  'wasteBaseDiffCounter': 'Basis machine om verschil in afval te berekenen',
  'workingHours': 'Werk uren',
  'addNewShift': 'Nieuwe dienst toevoegen',
  'productionExpectations': 'Productie doelen',
  'doYouWantToDelete': 'Verwijderen?',
  'newShift': 'Nieuwe dienst',
  'editShift': 'Dienst aanpassen',
  'removeShift': 'Dienst verwijderen',
  'users': 'Gebruikers',
  'nothingToAdd': 'Er is niets toe te voegen',
  'attachedLinesAndMachines': 'Aangesloten lijnen en machines',
  'addLineOrMachine': 'Voeg lijn / machine toe',
  'layout': 'Indeling',
  'saveLayout': 'Indeling opslaan',
  'elementsOrder': 'Elementen volgorde',
  'advanced': 'Geavanceerd',
  'default': 'Standaard',
  'noLayoutLine': 'Lijn is niet gedefinieerd in fabrieksindeling',
  'units': 'Eenheden',
  'unitCode': 'Eenheid code',
  'isBaseUnit': 'Is basis',
  'fromUnit': 'Ondergeschikte meeteenheid',
  'toUnit': 'Hoofd meeteenheid',
  'multiplier': 'Vermenigvuldiger',
  'shortName': 'Korte naam',
  'blockCard': 'Blokkeer kaart',
  'activeSince': 'Actief sinds',
  'production': 'Productie',
  'card': 'Kaart',
  'cardId': 'Kaart ID',
  'cardPin': 'Kaart PIN',
  'oldPin': 'Vorige PIN',
  'newPin': 'Nieuwe PIN',
  'pinChange': 'PIN veranderen',
  'pinLengthError': 'PIN moet exact 4 nummers hebben',
  'cardIdLengthError': 'Kaart ID moet tenmiste 4 karakters hebben',
  'connections': 'Stromen',
  'displaySettings': 'Scherm',
  'adminPlantSettings': 'Beheer fabrieksinstellingen',
  'orderAutostartThreshold': 'Order automatisch starten',
  'forcedStateThreshold': 'Beëindigen geforceerde stilstand',
  'downtimeThreshold': 'Beëindigen huidige stilstand',
  'allowProductionOnDowntime': 'Productie toestaan tijdens stilstand',
  'minorStopThreshold': 'Drempelwaarde voor een minor stop',
  'designPerformanceInfo': 'De machine prestaties welke gehaald kan worden volgens de fabrikant',
  'validatedPerformanceInfo': 'Prestatie van de machine welke gemeten en gevalideerd is in echte omstandigheden',
  'orderAutostartThresholdInfo': 'Drempelwaarde welke definieert hoeveel productie eenheden benodigd zijn om een order automatisch te starten',
  'forcedStateThresholdInfo': 'Drempelwaarde welke definieert hoeveel\\n productie eenheden benodigd zijn om de geforceerde stilstand automatisch te stoppen',
  'downtimeThresholdInfo': 'Drempelwaarde welke definieert hoeveel\\n productie eenheden benodigd zijn om de huidige stilstand automatisch te stoppen',
  'expectations': {
    'expectedOee': 'Verwachte OEE',
    'expectedPerformance': 'Verwachte prestatie',
    'maxExpectedFailures': 'Verwachte maximale aantal storingen',
    'validatedPerformance': 'Gevalideerde prestatie',
    'designPerformance': 'Ontwerp prestatie'
  },
  'automation': {
    'automation': 'Automatisering',
    'add': 'Automatisering toevoegen',
    'type': {
      'CreateQuestOnWorkEvent': {
        'title': 'Maak taak',
        'description': 'Maak een taak wanneer dit type probleem zich voordoet op de machine'
      },
      'CreateAndCloseQuestOnWorkEvent': {
        'title': 'Maak en sluit taak',
        'description': 'Maak een taak wanneer dit type probleem zich voordoet op de machine en sluit deze automatisch wanneer het probleem stopt'
      }
    }
  },
  'unit': {
    'usedInPackageStructure': 'Kan de eenheid niet verwijderen, het wordt gebruikt in een verpakking structuur',
    'fieldsNotValid': 'Onjuist veldformaat'
  },
  'functions': 'Voorbewerkingsfuncties',
  'help': 'Helpen',
  'operatorCockpit': 'Operator-cockpit',
  'weekStart': 'Week start',
  'cockpitType': 'Cockpit-type',
  'cockpitLayout': 'Cockpit-indeling',
  'maxPerformance': 'Maximale onmiddellijke prestaties',
  'maxPerformanceInfo': 'Gebruikt in de tegenbeveiliging, om te valideren of de nieuwe teller haalbaar is (0 = beveiliging uitgeschakeld)',
  'baseOrderUnit': 'Standaard order eenheid',
  'noCounter': 'Machine heeft geen teller',
  'machineBaseUnitSetInCounter': 'Machine-eenheid wordt bepaald door de eenheid van de teller',
  'shortPerformancePeriod': 'Periode',
  'setupIssues': 'Installatieproblemen',
  'noIssuesDetected': 'Geen configuratieproblemen gedetecteerd!',
  'test': 'Test',
  'isDummy': 'Is dummy',
  'isDummyInfo': 'Dummymachines worden niet gecontroleerd en hebben geen signalen. Ze kunnen handmatig worden gemarkeerd als hoofdoorzaak van de stilstand.',
  'maxTimeBufferOffset': 'Stop vertraging na de vorige machine',
  'maxTimeBufferOffsetInfo': 'Als de vorige machine stopte met werken, hoeland duurt het voordat deze machine stopt?',
  'rootCauseDelay': 'Oorzaak vertraging',
  'rootCauseDelayInfo': 'Hoelang kan deze machine stilstaan, zodat andere machines het nog steeds als de hoofdoorzaak aanwijzen?',
  'signalNameError': 'Signaalnaam mag geen tekens "(" en ")" bevatten',
  'tooltipState': {
    'lack': 'Deze status verschijnt wanneer uw machine niet genoeg componenten ontvangt die nodig zijn om continue door te werken. Het probleem kan een defecte transportband zijn, de operators die componenten niet op tijd bijvullen of de opslag van componenten die te ver van de lijn verwijderd is.',
    'work': 'Deze status verschijnt wanneer uw machine goed werkt en het productieproces bezig is',
    'inactive': 'Dit is een neutrale status die PackOS gebruikt voor uw machine wanneer er geen storing is maar de machine niet werkt',
    'stop': 'Deze status geeft aan dat uw machine met opzet is gestopt door een operator, omdat hij de machine bijvoorbeeld wilde configureren of opschonen. Wanneer uw machine zich in deze status bevindt, toont PackOS het probleem dat zich voordeed voordat de status Gestopt door operator van kracht werd',
    'failure': 'Deze status geeft aan dat uw machine is gestopt of niet goed functioneert door bijvoorbeeld mechanische slijtage of een bedieningsfout. In dit geval is de oorzaak van het probleem het probleem dat optrad voordat de storingsstatus verscheen',
    'off': 'Deze status verschijnt wanneer uw machine is losgekoppeld omdat bijvoorbeeld de stroom is uitgevallen of de verbinding met het systeem is verbroken',
    'idle': 'Deze status geeft aan wanneer de machine niet werkt, hoewel deze niet kapot is en er geen probleem mee is. In dit geval zoekt PackOS naar de oorzaak van het probleem door de stroomopwaartse machine te controleren.',
    'nolivedata': 'Dit is een tijdelijke toestand die optreedt wanneer PackOS tijdelijk de verbinding met de fabriek verliest, bijvoorbeeld vanwege problemen met de internetverbinding. PackOS toont deze status slechts tijdelijk en verandert deze snel in andere operationele statussen',
    'holdup': 'Deze status verschijnt wanneer uw machine niet werkt omdat de uitvoer ervan wordt geblokkeerd, meestal door stroomafwaartse machines. In dit geval zoekt PackOS naar de oorzaak van het probleem door de stroomafwaartse machine te controleren. De bron van het probleem in de stroomafwaartse machine wordt gekopieerd als de status en het probleem voor de machine met de status Holdup',
    'changeover': 'Deze status verschijnt wanneer uw machine wordt gestopt omdat de productielijn of de machine wordt aangepast voor een nieuw productieproces (wisselen)'
  },
  'shortPerformance': 'Onmiddellijke prestaties',
  'shortPerformanceInfo': 'Onmiddellijke prestatie berekend als een recente gemiddelde productietoename',
  'timezone': 'Tijd zone',
  'yourCurrent': 'Huidige',
  'differentialWaste': 'Verschil afval',
  'disableBufferResetBetweenOrders': 'Schakel bufferreset tussen orders uit',
  'calculateEnergyFromMachines': 'Berekend energie uit machines',
  'taxonomy': {'taxonomy': 'Groep'},
  'sku': {
    'expectedEffort': 'Verwachte inspanning (mh)',
    'skuDescriptionTooltip': 'Indien leeg gelaten, wordt de SKU-beschrijving gebruikt'
  },
  'function': {
    'sessionName': 'Sessie naam',
    'sessionInfo': 'Functies die dezelfde sessienaam gebruiken, worden opeenvolgend uitgevoerd (in plaats van gelijktijdig)',
    'run': 'Run',
    'testValue': 'Test waarde',
    'testResult': 'Testresultaat',
    'triggers': 'Triggers',
    'script': 'Script',
    'isStateful': 'Statig',
    'sequenceProcessing': 'Verwerk de volgende tags achtereenvolgens',
    'newFunction': 'Voeg functie toe',
    'editFunction': 'Wijzig functie'
  },
  'energy': 'Energie',
  'setUrl': 'Sla nieuwe URL op',
  'authUrl': 'Verificatie-URL-adres',
  'loadedOnNextLogin': 'Wordt geladen na nieuwe login',
  'allTypes': 'Alle types'
};
