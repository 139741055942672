import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const edgeDevices = new Vapi({
  axios,
})
  .get({
    action: 'getEdgeDevices',
    path: () => '/v2/edge-devices',
  })
  .post({
    action: 'createEdgeDevice',
    path: () => '/v2/edge-devices',
  })
  .put({
    action: 'updateEdgeDevice',
    path: () => '/v2/edge-devices',
  })
  .delete({
    action: 'deleteEdgeDevice',
    path: ({ name }) => `/v2/edge-devices/${name}`,
  })
  .get({
    action: 'getVisionEdgeDevices',
    path: () => '/v2/vision/edge-device',
  })
  .post({
    action: 'createVisionEdgeDevice',
    path: () => '/v2/vision/edge-device',
  })
  .delete({
    action: 'deleteVisionEdgeDevice',
    path: () => '/v2/vision/edge-device',
  })
  .getStore({
    namespaced: true,
  });

export default edgeDevices;
