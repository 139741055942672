<template>
  <div class="d-flex align-items-center px-2">
    <template
      v-for="b in filteredBreadcrumb"
    >
      <div
        :key="`${b.key}title`"
        class="secondary-text"
        @click="$router.push(b.route)"
      >
        {{ b.title }}
      </div>
      <div
        :key="`${b.key}arrow`"
        class="icon-style"
      >
        <i class="ion ion-ios-arrow-forward" />
      </div>
    </template>
    <div class="primary-text">
      {{ selected.title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    parent: String,
    page: String,
    importId: String,
    importFileName: String,
    templateId: String,
  },
  computed: {
    ...mapGetters(['plantId']),
    parentTitle() {
      return this.parent === 'items'
        ? this.$t('items.items')
        : this.$t('menu.activities');
    },
    breadcrumb() {
      return [
        {
          key: 'importer',
          title: this.$t('import.import'),
          route: `/${this.plantId}/importer/${this.parent}`,
        },
        ...(this.importId ? [{
          key: 'import',
          title: this.importFileName,
          route: `/${this.plantId}/importer/${this.parent}/import/${this.importId}`,
        }] : []),
        {
          key: 'templates',
          title: this.$t('import.templates'),
          route: `/${this.plantId}/importer/${this.parent}/templates`,
        },
        {
          key: 'template',
          title: this.templateId ? this.$t('import.editTemplate') : this.$t('import.addTemplate'),
          route: `/${this.plantId}/importer/${this.parent}/template`,
        },
      ];
    },
    filteredBreadcrumb() {
      const index = this.breadcrumb.findIndex(x => x.key === this.page);
      return this.breadcrumb.filter((_, i) => i < index);
    },
    selected() {
      return this.breadcrumb.find(x => x.key === this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.secondary-text {
  color: darken($grey, 20);
  font-size: 1.3rem;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.icon-style {
  font-size: 1.25em;
  color: darken($grey, 20);
  font-weight: 500;
  margin-inline: 15px;
  margin-top: 5px;
}

.primary-text {
  font-size: 1.75em;
  font-weight: 500;
  opacity: 0.9;
}
</style>
