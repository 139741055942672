export default {
  'originalDates': 'Fechas originales',
  'originalDuration': 'Duración original',
  'newDates': 'Nuevas fechas',
  'newDuration': 'Nueva duración',
  'firstDowntime': '1er parada',
  'secondDowntime': '2da parada',
  'shorten': 'Reducir',
  'split': 'Separar',
  'thisMachine': 'Esta maquína',
  'addComment': 'Adicionar comentario',
  'noComments': 'No hay comentarios',
  'downtimeSummary': 'Resumen de tiempos de parada',
  'step': 'Paso {paso}',
  'selectRootLineMachine': 'Seleccione lugar físico de la causa raiz',
  'selectLineMachine': 'Seleccione Línea/Maquina',
  'selectState': 'Seleccionar estado',
  'selectProblem': 'Seleccionar problema',
  'specifyAReason': 'Especificar una razón',
  'correctiveActionTaken': 'Acción correctiva tomada',
  'downtimeDuration': 'Duración de la parada',
  'vision': 'Visión',
  'oeeLossType': 'Tipo de perdida de OEE',
  'table': {'downtime': 'Parada', 'details': 'Detalles', 'time': 'Tiempo', 'duration': 'Duración'},
  'noSharedComments': 'Comentarios no compartidos'
};
