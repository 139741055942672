export default {
  'addDowntime': 'Voeg Stilstand Toe',
  'newDowntime': 'Nieuwe Stilstand',
  'actionsAndReasons': 'Acties en reden',
  'performActions': 'Voer volgende acties uit',
  'secifyReasonIfKnown': 'Geef een reden',
  'downtimelist': 'Reden voor stilstand',
  'annotations': 'Aantekeningen',
  'categories': 'Labels',
  'clearRootCause': 'Verwijder achterliggende oorzaak',
  'reason': 'Reden',
  'reasons': 'Redenen',
  'rootCauseReason': 'Achterliggende reden',
  'noReasons': 'Geen oorzaak gedefinieerd',
  'noCorrectiveActions': 'Geen corrigerende actie geselecteerd',
  'noEvents': 'Geen gebeurtenis gedefinieerd',
  'noCameras': 'Geen camera\'s gedefinieerd',
  'removeReasonWorning': 'Waarschuwing! alle corrigerende acties bij deze oorzaak zullen worden verwijderd',
  'addReason': 'Als een nieuwe oorzaak',
  'selectEvent': 'Voorval / Probleem',
  'newCorrectiveAction': 'Nieuwe corrigerende actie',
  'correctiveAction': 'Corrigerende actie',
  'correctiveActions': 'Corrigerende acties',
  'callForService': 'Bel voor onderhoud',
  'serviceHasBeenCalled': 'Onderhoud is gebeld',
  'specifyRootcause': 'Oorzaak',
  'lastDowntimes': 'Laatste stilstand',
  'startTime': 'Gestart',
  'endTime': 'Afgerond',
  'externalReason': 'Externe oorzaak',
  'uncategorized': 'Niet gecategoriseerd',
  'noActions': 'Er hoeft geen actie uitgevoerd te worden',
  'timeSlot': 'Tijdslot',
  'selectOtherMachineDowntime': 'Selecteer een andere machine stilstand',
  'selectMachine': 'Selecteer machine',
  'workEventFilter': 'Gebeurtenis filter',
  'deselectAll': 'Deselecteer alles',
  'connect': 'Verbinden',
  'commonReason': 'Veel voorkomende oorzaak',
  'commonProblem': 'Veel voorkomend probleem',
  'clearSelection': 'Verwijder slectie',
  'noReason': 'Geen oorzaak gedefinieerd',
  'addOrCreateReason': 'Selecteer gerelateerde oorzaak',
  'lineState': 'Productielijn status',
  'selectProblemFirst': 'Selecteer eerst het probleem',
  'setProblem': 'Probleem instellen',
  'setReason': 'Oorzaak instellen',
  'setCorrectiveAction': 'Corrigerende actie instellen',
  'summary': 'Samenvatting',
  'deleteConfirmMessage': 'Bevestig dat je de geselecteerde stilstand wilt verwijderen',
  'changeDuration': 'Verander tijdsduur',
  'downtimeUpdated': 'Stilstand verwerkt',
  'newReason': 'Nieuwe reden',
  'noCorrectiveAction': 'Geen correctieve actie genomen',
  'toEditSetCommonWorkEvent': 'Om aan te passen kies gemeenschappelijk \'work-event\'',
  'differentCorrectiveActions': 'Verschillende correctieve acties',
  'differentReasons': 'Verschillende redenen',
  'noMachineDowntime': 'Geldt niet voor machines',
  'setAction': 'Kies actie',
  'addAnotherCorrectiveAction': 'Voeg een andere correctieve actie toe',
  'addAnotherReason': 'Voeg een andere reden toe'
};
