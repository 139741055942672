<template>
  <div>
    <div style="max-width: 200px; margin: 6px auto;">
      <button
        class="btn btn-block btn-sm btn-outline-dark"
        @click="showReport"
      >
        <i class="fas fa-chart-column mr-2 details-icon" />
        {{ $t('reports.report') }}
      </button>
    </div>
    <div v-if="currentOees.components">
      <hr>

      <BBadge
        v-if="performanceType === 0"
        variant="primary"
      >
        {{ $t('settings.expectations.designPerformance') }}
      </BBadge>
      <BBadge
        v-else
        variant="primary"
      >
        {{ $t('settings.expectations.validatedPerformance') }}
      </BBadge>

      <hr>

      <OEEComponentsInfo
        v-bind="currentOees.components"
        :start-date="startDate"
        :end-date="endDate"
      />
      <hr>
    </div>
    <div v-if="oees && currentOees">
      <div class="position-relative">
        <h2 class="oee-title">
          {{ $t('oee.oee') }}
        </h2>
        <div class="d-flex">
          <OEEHistory
            :oees="oees"
            :check="{ oee: true }"
            :target="target"
            :resolution="resolution"
            :height="chartHeight"
            class="flex-grow-1"
          />
          <div :style="isMobile && 'width: 60px;' || 'width: 120px;'">
            <OEE
              :value="currentOees.oee"
              :target="target ? target.oee : null"
              :style="isMobile && 'width: 60px; height: 120px' || 'width: 120px; height: 120px'"
            />
          </div>
        </div>
      </div>
      <hr class="my-2">
      <div class="position-relative">
        <h2 class="oee-title">
          {{ $t('oee.availability') }}
        </h2>
        <div class="d-flex">
          <OEEHistory
            :oees="oees"
            :check="{ oeeA: true }"
            :target="target"
            :resolution="resolution"
            :height="chartHeight"
            class="flex-grow-1"
          />
          <div :style="isMobile && 'width: 60px;' || 'width: 120px;'">
            <OEE
              :value="currentOees.oeeA"
              :target="target ? target.oeeA : null"
              :style="isMobile && 'width: 60px; height: 120px' || 'width: 120px; height: 120px'"
            />
          </div>
        </div>
        <div
          class="more-details-icon"
          @click="details.oeea = !details.oeea"
        >
          <i
            v-if="details.oeea"
            class="ion ion-ios-close-circle-outline"
          />
          <i
            v-else
            class="ion ion-ios-information-circle-outline"
          />
        </div>
        <div class="charts-margin">
          <Transition name="slide">
            <WorklogModal
              v-if="details.oeea"
              :element-id="elementId"
              :start="startDate"
              :end="Math.min($now, endDate)"
              :hide-time-selector="true"
              :hide-actions="true"
            />
          </Transition>
        </div>
      </div>
      <hr class="my-2">
      <div class="position-relative">
        <h2 class="oee-title">
          {{ $t('oee.performance') }}
        </h2>
        <div class="d-flex">
          <OEEHistory
            :oees="oees"
            :check="{ oeeP: true }"
            :target="target"
            :resolution="resolution"
            :height="chartHeight"
            class="flex-grow-1"
          />
          <div :style="isMobile && 'width: 60px;' || 'width: 120px;'">
            <OEE
              :value="currentOees.oeeP"
              :target="target ? target.oeeP : null"
              :style="isMobile && 'width: 60px; height: 120px' || 'width: 120px; height: 120px'"
            />
          </div>
        </div>
        <div
          class="more-details-icon"
          @click="details.oeep = !details.oeep"
        >
          <i
            v-if="details.oeep"
            class="ion ion-ios-close-circle-outline"
          />
          <i
            v-else
            class="ion ion-ios-information-circle-outline"
          />
        </div>
        <div class="charts-margin">
          <Transition name="slide">
            <ProductionModal
              v-if="details.oeep"
              :element-id="elementId"
              :check="{ planned: true, produced: true }"
              :start="startDate"
              :end="endDate"
            />
          </Transition>
        </div>
      </div>
      <hr class="my-2">
      <div class="position-relative">
        <h2 class="oee-title">
          {{ $t('oee.quality') }}
        </h2>
        <div class="d-flex">
          <OEEHistory
            :oees="oees"
            :check="{ oeeQ: true }"
            :target="target"
            :resolution="resolution"
            :height="chartHeight"
            class="flex-grow-1"
          />
          <div :style="isMobile && 'width: 60px;' || 'width: 120px;'">
            <OEE
              :value="currentOees.oeeQ"
              :target="target ? target.oeeQ : null"
              :style="isMobile && 'width: 60px; height: 120px' || 'width: 120px; height: 120px'"
            />
          </div>
        </div>
        <div
          class="more-details-icon"
          @click="details.oeeq = !details.oeeq"
        >
          <i
            v-if="details.oeeq"
            class="ion ion-ios-close-circle-outline"
          />
          <i
            v-else
            class="ion ion-ios-information-circle-outline"
          />
        </div>
        <div class="charts-margin">
          <Transition name="slide">
            <ProductionModal
              v-if="details.oeeq"
              :element-id="elementId"
              :check="{ wasted: true }"
              :start="startDate"
              :end="endDate"
            />
          </Transition>
        </div>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >
      <Loader :size="20" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import OEEHistory from '@/components/charts/oees/history/OEEHistory';
import WorklogModal from '@/components/modals/WorklogModal';
import ProductionModal from '@/components/modals/ProductionModal';
import OEEComponentsInfo from '@/components/reports/oee/OEEComponentsInfo';
import OEE from '@/components/charts/oees/OEE';

export default {
  props: {
    elementId: String,
    currentOees: Object,
    target: Object,
    startDate: Number,
    endDate: Number,
  },
  data: () => ({
    oees: null,
    error: null,
    chartHeight: 150,
    details: {
      oeea: false,
      oeeq: false,
      oeep: false,
    },
  }),
  components: {
    OEE,
    WorklogModal,
    ProductionModal,
    OEEComponentsInfo,
    OEEHistory,
  },
  computed: {
    ...mapGetters('element', ['element']),
    ...mapGetters([
      'plantId',
      'isMobile',
      'performanceType',
    ]),
    resolution() {
      return {
        name: 'hour',
        res: 'hours',
        description: this.$t('time.hour'),
        format: 'H:mm',
        aggregate: 'h',
        minHourDiff: 0,
        maxHourDiff: 48,
      };
    },
  },
  methods: {
    ...mapActions(['getOees']),
    showReport() {
      const { elementId } = this;
      this.$store.dispatch('report/selected', elementId);
      this.$router.push(`/${this.plantId}/reports/oee`);
    },
    toUnix(date) {
      return moment(date).unix();
    },
    request() {
      this.getOees({
        params: {
          startDate: this.startDate,
          endDate: this.endDate,
          plantId: this.plantId,
          elementId: this.elementId,
          query: {
            aggregate: 'hour',
            performanceType: this.performanceType === 0
              ? 'DesignSpeed'
              : 'ValidatedSpeed',
          },
        },
      })
        .then(({ data }) => {
          this.oees = data.oees;
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .oee-title {
    font-size: 20px;
    font-weight: 400;
  }
  .charts-margin {
    margin-right: 120px;
  }
  .more-details-icon {
    font-size: 25px;
    position: absolute;
    cursor: pointer;
    right: -3px;
    top: 130px;
    transition: transform 300ms;

    &:hover {
      transform: scale(1.3);
    }
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 200px; }

  .slide-enter-active { transition: opacity 400ms, max-height 400ms }
  .slide-leave-active { transition: opacity 200ms, max-height 200ms }
</style>
