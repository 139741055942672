<template>
  <div class="text-left">
    <h5
      class="text-left mb-1"
      style="font-size: 16px"
    >
      {{ $t('metrics.reliabilityMetrics') }}
    </h5>
    <div class="d-flex">
      <div class="flex-grow-1 pr-2 small">
        {{ $t('metrics.mtbf') }}:
      </div>
      <div class="font-weight-bold">
        {{ CalcDuration(1, Math.floor(mtbf)+1) }}
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1 pr-2 small">
        {{ $t('metrics.mttr') }}:
      </div>
      <div class="font-weight-bold">
        {{ CalcDuration(1, Math.floor(mttr)+1) }}
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1 pr-2 small">
        {{ $t('metrics.mtbm') }}:
      </div>
      <div class="font-weight-bold">
        {{ CalcDuration(1, Math.floor(mtbm)+1) }}
      </div>
    </div>
  </div>
</template>

<script>
import CalcDuration from '@/utils/calcDuration';

export default {
  props: {
    reliabilityMetrics: Object,
    mtbf: Number,
    mttr: Number,
    mtbm: Number,
  },
  methods: {
    CalcDuration,
  },
};
</script>

<style>

</style>
