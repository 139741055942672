import Vapi from 'vuex-rest-api';
import { hub as axios } from '@core/api/axios';

const store = new Vapi({
  axios,
})
  .get({
    action: 'getUsers',
    property: 'users',
    path: ({ tenantId }) => `tenant/${tenantId}/users`,
  })
  .get({
    action: 'getTeams',
    property: 'teams',
    path: ({ tenantId }) => `tenant/${tenantId}/teams`,
  })
  .get({
    action: 'getAllTeams',
    property: 'allTeams',
    path: () => 'teams',
  })
  .get({
    action: 'getWorkEventWorkflows',
    path: ({ workEventId }) => `workflows/work/event/${workEventId}`,
  })
  .post({
    action: 'createQuestOnWorkEvent',
    path: 'workflows/template/createQuestOnWorkEvent',
  })
  .post({
    action: 'createAndCloseQuestOnWorkEvent',
    path: 'workflows/template/createAndCloseQuestOnWorkEvent',
  })
  .delete({
    action: 'deleteWorkflow',
    path: ({ workflowId }) => `workflow/${workflowId}`,
  })
  .getStore();

export default store;
