import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const changeovers = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getChangeover',
    path: ({ changeoverId }) => `v3/activities/changeover/${changeoverId}`,
  })
  .head({
    action: 'checkChangeover',
    path: ({ externalId }) => `v3/activities/changeover/${externalId}`,
  })
  .put({
    action: 'createChangeover',
    path: 'v3/activities/changeover',
  })
  .get({
    action: 'getChangeoverErrors',
    path: ({ changeoverId }) => `v3/activities/changeover/${changeoverId}/validation-errors`,
  })
  .post({
    action: 'withdrawChangeover',
    path: ({ changeoverId }) => `/v3/activities/changeover/${changeoverId}/commands/withdraw`,
  })
  .delete({
    action: 'deleteChangeover',
    path: ({ changeoverId }) => `v3/activities/changeover/${changeoverId}`,
  })
  .getStore();
export default changeovers;
