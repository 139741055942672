import Vapi from 'vuex-rest-api';
import { PACKOS_ELEMENT_ACCESS } from '@core/dict/permissions';
import axios from '@/api/axios';
import Graph from '@/components/graph/Graph';
import { elementType, plantProperty } from '@/utils/dictionary';

const store = new Vapi({
  axios,
  state: {
    elements: {},
    structure: null,
    flows: {},
    detachedNodes: [],
    healthcheck: null,
    images: [],
  },
})
  .get({
    action: 'getElements',
    path: ({ plantId }) => `/v2/plant/${plantId}/elements`,
    onSuccess: (s, { data }, _, { params }) => {
      s.structure = new Graph(
        'elements',
        data,
        data.map(n => ({
          from: n.parentId,
          to: n.id,
        })),
        params.plantId.toLowerCase(),
      );

      s.detachedNodes = [];
    },
  })
  .getStore();

store.mutations.updateFlows = (s, flows) => {
  s.flows = flows
    .reduce((acc, curr) => {
      acc[curr.rootId] = [...(acc[curr.elementId]?.length ? acc[curr.elementId] : []), curr];
      return acc;
    }, {});
};

store.getters = {
  structure: s => s.structure,
  flows: s => id => s.flows[id] || [],
  activeFlows: s => Object.values(s.flows).flatMap(x => x),
  lines: (s, g, _, rg) => {
    if (!g.structure || !rg.plantId) return [];
    const ids = g.structure.allLowerChildren(rg.plantId.toLowerCase())
      .filter(id => rg['element/elementType'](id) === elementType.line)
      .filter(x => rg['core/canOnElement'](PACKOS_ELEMENT_ACCESS, x))
      .sort((a, b) => {
        if (!g.elementsOrder[a]) return 1;
        if (!g.elementsOrder[b]) return -1;

        return g.elementsOrder[a] - g.elementsOrder[b];
      });

    return g.elements(ids);
  },
  areas: (s, g, _, rg) => {
    if (!g.structure || !rg.plantId) return [];
    const ids = g.structure.lowerChildrenOf(rg.plantId, 1)
      .sort((a, b) => (g.elementsOrder[a] || 0) - (g.elementsOrder[b] || 0));
    return g.elements(ids);
  },
  machines: (s, g, _, rg) => lineId => {
    if (!g.structure || !rg.plantId) return [];
    const ids = g.structure.allLowerChildren(lineId)
      .filter(id => rg['element/elementType'](id) === elementType.machine);
    return g.elements(ids);
  },

  plantLayout: (_, g) => g.plantProperty(plantProperty.layout),
  healthcheck: s => elementId => {
    if (!s.healthcheck) return {};
    return s.healthcheck.keepAlives.find(h => h.elementId === elementId);
  },
  elementsOrder: (_, g) => {
    const value = g.plantProperty(plantProperty.elementsOrder);
    return value ? JSON.parse(value) : {};
  },
};

export default store;
