import moment from 'moment-timezone';

export const timezoneDayStart = (timezone, now) => (timezone
  ? moment(now).tz(timezone).startOf('day') : moment(now).startOf('day')
);

const pad = n => n.toString().padStart(2, '0');

export const durationToSeconds = time => {
  if (!time) return 0;
  const arr = time.split(':');
  if (arr.length < 3) return 0;
  const h = Number.parseInt(arr[0], 10);
  const m = Number.parseInt(arr[1], 10);
  const s = arr.length > 2 ? Number.parseInt(arr[2], 10) : 0;

  return (h * 60 + m) * 60 + s;
};

export const secondsToDuration = n => {
  const min = Math.floor(n / 60);
  const h = Math.floor(min / 60);
  const s = pad(n - min * 60);
  return `${pad(h)}:${pad(min)}:${s}`;
};

export const minutesToDuration = time => {
  const hours = Math.floor(time / 60);
  const minutes = time - (hours * 60);
  return `${pad(hours)}:${pad(minutes)}`;
};

export const durationToMinutes = duration => {
  const parts = duration.split(':');
  if (parts.length < 2) return 0;
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  if (Number.isNaN(hours) || Number.isNaN(minutes)) return 0;
  return hours * 60 + minutes;
};

export const twoLetterNumber = v => {
  const str = v.toString().replace(/\D/g, '');
  return str.substring(str.length - 2);
};

export const threeLetterNumber = v => {
  const str = v.toString().replace(/\D/g, '');
  return str.substring(str.length - 3);
};

const getShiftStartEnd = (shift, plantTimezone, now) => {
  const startTime = durationToSeconds(shift.startTime);
  const endTime = durationToSeconds(shift.endTime);

  const tz = plantTimezone;
  // Midnight case
  if (endTime <= startTime) {
    // Before midnight
    if (timezoneDayStart(tz, now).add(startTime, 'seconds').isBefore(moment(now).tz(tz))) {
      return {
        start: timezoneDayStart(tz, now).add(startTime, 'seconds'),
        end: timezoneDayStart(tz, now).add(24, 'hours').add(endTime, 'seconds'),
      };
    }
    // After midnight
    return {
      start: timezoneDayStart(tz, now).subtract(1, 'days').add(startTime, 'seconds'),
      end: timezoneDayStart(tz, now).add(endTime, 'seconds'),
    };
  }

  return {
    start: timezoneDayStart(tz, now).add(startTime, 'seconds'),
    end: timezoneDayStart(tz, now).add(endTime, 'seconds'),
  };
};

const removeDSTOffset = ({ start, end }) => {
  if ((start.isDST() && end.isDST()) || (!start.isDST() && !end.isDST())) {
    return { start, end };
  } if (start.isDST()) {
    end.add(1, 'hours');
  } else {
    end.subtract(1, 'hours');
  }

  return {
    start,
    end,
  };
};

// Finds current shift
export function getCurrentShift(shifts, plantTimezone, now) {
  return shifts
    .map(s => {
      const { start, end } = removeDSTOffset(
        getShiftStartEnd(s, plantTimezone, now * 1000),
      );

      return {
        ...s,
        start,
        end,
      };
    })
    .find(el => moment(now * 1000).isBefore(el.end) && moment(now * 1000).isAfter(el.start));
}

// Returns day start timestamp
export function getRealDayStart(secFromMidnight, now) {
  const start = timezoneDayStart(0, now).add(secFromMidnight, 'seconds');

  if (start.isSameOrBefore(now)) {
    return start;
  }

  return start.subtract(24, 'hours');
}

// get resolution for date range
export function getResolution(startDate, endDate) {
  const diff = moment((endDate || moment().unix()) * 1000).diff(startDate * 1000, 'hour');

  if (diff <= 48) {
    return 'hour';
  // When less than a week, display it with "days" resolution
  } if (diff <= 24 * 7) {
    return 'day';
  }

  // If more than a week, display in "weeks" resolution
  return 'week';
}

export default {
  getCurrentShift,
  getRealDayStart,
  timezoneDayStart,
  getResolution,
};
