<template>
  <div>
    <div>
      <div class="label font-weight-light">
        {{ $t('orders.order') }}:
      </div>
      <div class="title">
        {{ order.externalId }}
      </div>
    </div>
    <div v-if="order.description !== order.externalId">
      <div class="label font-weight-light">
        {{ $t('orders.description') }}:
      </div>
      <div class="title">
        {{ order.description }}
      </div>
    </div>
    <hr class="my-1">
    <div>
      <div class="label font-weight-light">
        {{ $t('orders.item') }}:
      </div>
      <div class="title">
        {{ skuNumber }}
      </div>
    </div>
    <div v-if="skuDescription !== skuNumber">
      <div class="label font-weight-light">
        {{ $t('orders.description') }}:
      </div>
      <div class="title">
        {{ skuDescription }}
      </div>
    </div>
    <hr class="my-1">
    <div v-if="order.status">
      <div class="label font-weight-light">
        {{ $t('general.status') }}:
      </div>
      <div class="title">
        <i :class="orderState.icon" />
        {{ orderState.desc }}
      </div>
    </div>
    <hr
      v-if="flows"
      class="my-1"
    >
    <div v-if="flows">
      <div class="label font-weight-light">
        {{ $t('graph.flow') }}:
      </div>
      <div class="title">
        {{ flow ? flow : $t('general.noData') }}
      </div>
    </div>
    <hr class="my-1">
    <div class="dates">
      <div v-if="order.scheduledExecution">
        <div class="date-title">
          {{ $t('orders.scheduled') }}:
        </div>
        <span>
          <i class="far fa-calendar-check pr-1" />
          {{ order.scheduledExecution.begin | formatDate }} -
          {{ order.scheduledExecution.end | formatDate }}
        </span>
      </div>
      <div v-if="activated">
        <div class="date-title">
          {{ $t('orders.states.activated') }}:
        </div>
        <span>
          <i class="far fa-calendar pr-1" />
          {{ activated | formatDate }}
        </span>
      </div>
      <div v-if="order.actualExecution">
        <div class="date-title">
          {{ $t('orders.realisation') }}:
        </div>
        <span>
          <i class="far fa-calendar pr-1" />
          {{ order.actualExecution.begin | formatDate }} -
          {{ order.actualExecution.end | formatDate }}
        </span>
      </div>
      <div v-if="order.estimatedEnd">
        <div class="date-title">
          {{ $t('order.eta') }}:
        </div>
        <span>
          <i class="far fa-calendar pr-1" />
          {{ order.estimatedEnd | formatDate }}
          <span class="text-secondary small">
            ({{ estimatedEndDuration }})

          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import calcDuration from '@/utils/calcDuration';

export default {
  props: {
    order: Object,
    flows: Array,
  },
  data() {
    return {
      now: this.$now,
    };
  },
  filters: {
    formatDate(d) {
      if (!d) return '...';
      return moment(d).format('Do MMM HH:mm');
    },
  },
  computed: {
    ...mapGetters(['getActivityState']),
    ...mapState({
      items: state => state.item.items,
    }),
    orderState() {
      return this.getActivityState({ status: this.order.status });
    },
    flow() {
      return this.flows.filter(flow => flow.id === this.order.flowId).map(flow => flow.name)[0];
    },
    skuNumber() {
      return this.items?.find(i => i.id === this.order.productId)?.skuNo;
    },
    skuDescription() {
      return this.items?.find(i => i.id === this.order.productId)?.description;
    },
    activated() {
      return this.order.actualActivation?.begin;
    },
    estimatedEndDuration() {
      return calcDuration(this.now, moment(this.order.estimatedEnd).unix());
    },
  },
  methods: {
    calcDuration,
  },
};
</script>

<style scoped>
  .title {
    text-align: left;
    font-size: 14px;
    display: inline-block;
  }
  .date-title {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
  }
  .dates {
    font-weight: 400;
  }
  .label {
    margin-right: 10px;
    display: inline-block;
    width: 80px;
  }
</style>
