import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    list: [],
  },
})
  .get({
    action: 'getShifts',
    property: 'list',
    path: '/v2/shifts',
  })
  .get({
    action: 'getShiftSummary',
    path: ({
      plantId, shiftId, startDate, endDate,
    }) =>
      `/v2/plant/${plantId}/report/shift/${shiftId}/summary/${startDate}/${endDate}`,
  })
  .post({
    action: 'addShift',
    path: '/v2/shifts',
    onSuccess: (s, { data }) => {
      s.list.push(data);
    },
  })
  .put({
    action: 'updateShift',
    path: ({ shiftId }) => `/v2/shifts/${shiftId}`,
    onSuccess: (s, { data }) => {
      const shift = s.list.find(x => x.id === data.id);
      if (shift) {
        shift.name = data.name;
        shift.code = data.code;
      }
    },
  })
  .delete({
    action: 'removeShift',
    path: ({ shiftId }) => `/v2/shifts/${shiftId}`,
    onSuccess: (s, _, a, { params }) => {
      s.list = s.list.filter(x => x.id !== params.shiftId);
    },
  })
  .getStore();

store.getters = {
  shifts: s => s.list,
  shift: s => id => s.list.find(shift => shift.id === id),
};

export default store;
