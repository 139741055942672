import Vue from 'vue';

const state = {
  elements: {},
};

const mutations = {
  clearElementsCache(s) {
    s.elements = {};
  },
  setElementData(s, { elementId, value }) {
    Vue.set(s.elements, elementId, value);
  },
  updateElementProp(s, { elementId, key, value }) {
    const element = s.elements[elementId];
    if (element) {
      Vue.set(element, key, value);
    }
  },
  updateElements(s, { elements, disabledIds }) {
    elements.forEach(e => {
      Vue.set(s.elements, e.id, {
        ...s.elements[e.id],
        ...e,
      });
    });

    disabledIds.forEach(id => {
      const el = s.elements[id];
      if (!el) return;
      Vue.set(s.elements, id, {
        description: el.description,
        id: el.id,
        type: el.type,
        name: el.name,
      });
    });
  },
  fillFromElements(s, elements) {
    if (!elements || elements.length === 0) return;
    elements.forEach(n => {
      if (!s.elements[n.id]) {
        Vue.set(s.elements, n.id, n);
      }
    });
  },
};

const getters = {
  elements: s => ids => ids.map(id => s.elements[id]).filter(x => x),
};

const actions = {
  clearElementCache(ctx, elementId) {
    const { structure } = ctx.getters;
    const node = structure.node(elementId);

    ctx.commit('setElementData', { elementId, value: node });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
