export default {
  oAria: {
    sSortAscending: ': activer pour trier la colonne par ordre croissant',
    sSortDescending: ': activer pour trier la colonne par ordre décroissant'
  },
  sInfoFiltered: '(filtré à partir du total des entrées _MAX_)',
  oPaginate: {
    sFirst: 'Premier', sLast: 'Dernier', sNext: 'Suivant', sPrevious: 'Précédent',
  },
  sLoadingRecords: 'Chargement...',
  moveHereToGroup: 'Déplacer la colonne ici vers le groupe',
  moveHereToHide: 'Déplacer la colonne ici pour masquer',
  sEmptyTable: 'Aucune donnée disponible dans le tableau',
  sZeroRecords: 'Aucun enregistrement correspondant trouvé',
  sProcessing: 'Traitement...',
  sSearch: 'Rechercher:',
  'downloadAsXls': 'Télécharger en xls',
};
