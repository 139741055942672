export default {
  rules: '规则',
  addRule: '添加规则',
  countingOEE: '计算 OEE',
  excludeOEE: '排除 OEE',
  thresholds: '阈值',
  saved: '已保存',
  baseUnit: '基本单元',
  notSelected: '未选中的',
  saveFailed: '保存失败',
  addMachine: '将机器添加到过滤器',
  rule: {
    rule: '规则', condition: '状况', addAndCondition: '和', showAdvanced: '使用高级编辑器', info: '机器状态根据以下规则确定。 当观察到信号值发生变化时，\\n 规则引擎将从最高优先级开始分析以下规则。\\n 返回“true”的第一个规则将确定新状态',
  },
  events: { forcedOnly: '只有强迫', showSystem: '显示系统事件' },
  value: '价值',
  priority: '优先事项',
  dayOfWork: '工作日',
  plantConfiguration: '工厂配置',
  machineSignals: '机器信号',
  signals: '信号',
  signalsExport: '导出信号',
  logixGateConfigExport: '导出 LogixGate 配置',
  signalsImport: '导入信号',
  workEventsImport: '导入问题',
  csvImport: '导入 CSV',
  csvExport: '导出 CSV',
  properties: '属性',
  signalsAndProperties: '信号和属性',
  machineLicenses: '机器许可证',
  addSignal: '添加信号',
  addProperty: '添加属性',
  electricity: '电',
  electricityBaseline: '电力基线',
  electricityMax: '电力平均最大值',
  shiftDefinitions: '班次定义',
  info: '信息',
  deleteInfo: '按“保存”按钮删除',
  differentTimezoneInfo: '您与工厂位于不同的时区！',
  differentTimezoneSettingsInfo: '以下设置将适用于工厂时区',
  differentTimezoneSelectorInfo: '我们将显示转换为您所在时区的时间',
  wasteBaseDiffCounter: '以机器为单位计算不同废物',
  workingHours: '工作时间',
  addNewShift: '添加新班次',
  productionExpectations: '生产目标',
  doYouWantToDelete: '你要删除吗',
  newShift: '新班次',
  editShift: '编辑班次',
  removeShift: '移除班次',
  users: '用户',
  nothingToAdd: '没什么可添加的',
  attachedLinesAndMachines: '附属的产线和机器',
  addLineOrMachine: '添加产线/机器',
  layout: '布局',
  saveLayout: '保存布局',
  elementsOrder: '元素顺序',
  advanced: '高级',
  default: '默认',
  noLayoutLine: '产线未在工厂布局中定义',
  units: '单位',
  unitCode: '单位代码',
  isBaseUnit: '基准',
  fromUnit: '从属单位',
  toUnit: '主计量单位',
  multiplier: '乘数',
  shortName: '简称',
  blockCard: '停卡',
  activeSince: '从几时活跃',
  production: '生产',
  card: '卡片',
  cardId: '卡片号',
  cardPin: '卡片密码',
  oldPin: '以前的密码',
  newPin: '新密码',
  pinChange: '更改密码',
  pinLengthError: 'PIN 必须正好有 4 个数字',
  cardIdLengthError: '卡片号必须至少有 4 个字符',
  connections: '流量',
  displaySettings: '显示',
  adminPlantSettings: '管理工厂设置',
  orderAutostartThreshold: '订购自动启动',
  forcedStateThreshold: '结束强制停机',
  downtimeThreshold: '结束持续停机',
  allowProductionOnDowntime: '允许停机生产',
  minorStopThreshold: '点点停的阈值',
  designPerformanceInfo: '制造商可达到的机器性能\\n',
  validatedPerformanceInfo: '在实际条件下测量和验证的机器性能',
  orderAutostartThresholdInfo: '定义自动开始订单所需的\\n 生产量的阈值',
  forcedStateThresholdInfo: '定义自动完成强制停机所需的\\n 生产量的阈值',
  downtimeThresholdInfo: '定义自动完成持续停机所需的\\n 生产量的阈值',
  expectations: {
    expectedOee: '预期 OEE', expectedPerformance: '预期表现', maxExpectedFailures: '预期最大失败次数', validatedPerformance: '验证性能', designPerformance: '设计表现',
  },
  automation: { automation: '自动化', add: '添加自动化', type: { '|': { title: '创建任务', description: '机器出现此类问题时创建任务' } } },
  unit: { usedInPackageStructure: '删除单元不可能，它用于包结构定义' },
};
