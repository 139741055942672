<template>
  <Transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'TransitionExpand',
  methods: {
    enter(element) {
      const { width } = window.getComputedStyle(element);

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const { height } = window.getComputedStyle(element);

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      const { height } = window.getComputedStyle(element);

      element.style.height = height;
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 300ms, opacity 300ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  height: 0;
}
</style>
