export default {
  abort: 'Abandonner',
  abortOrderActivate: 'Abandonner l’activation',
  activate: 'Activer',
  activateOrder: 'Activer la commande',
  states: {
    activated: 'Activé',
    completed: 'Terminé',
    inProgress: 'En cours',
    onHold: 'En attente',
    other: 'Autre',
    ready: 'Attente',
    'taken': 'Pris',
  },
  comment: {
    abortOrderActivate: 'L’activation sera interrompue, l’état de la commande redeviendra \'Prêt\'',
    completeOrder: 'La commande sera terminée et ne pourra pas être recommencée',
    activateOrder: 'La commande sera préparée pour le démarrage, le flux peut toujours être modifié et certains paquets devront être analysés avant le début',
    stopOrder: 'La commande sera arrêtée et pourra être recommencée plus tard',
    startOrder: 'La commande commencera immédiatement. Tous les paquets numérisés seront activés',
  },
  add: 'Ajouter une commande',
  additional: 'Additionnel',
  afterPlannedEnd: 'Après la fin prévue',
  afterPlannedStart: 'Après le démarrage prévu',
  activities: {
    changeover: 'Changement',
    cip: 'CIP',
    completedJob: 'Travail terminé',
    editActivity: 'Modifier l’activité',
    failure: 'Echec',
    holdup: 'Attente',
    idle: 'Inutilisé',
    inProgressJob: 'Travail en cours',
    inactive: 'Inacif',
    lack: 'Manque de composants et de produits',
    order: 'Commande',
    addActivity: 'Planifier une nouvelle activité',
    stop: 'Arrêt par l’opérateur',
  },
  complete: 'Complet',
  completed: 'Terminé',
  completedOrders: 'Commandes terminées',
  config: 'Configuration',
  create: 'Créer une commande',
  description: 'Descritption',
  edit: 'Modifier la commande',
  end: 'Fin',
  expectedEnd: 'Fin prévu',
  expectedPerformance: 'Performances attendues',
  expectedStart: 'Début prévu',
  hold: 'Tenir',
  import: 'Importer des commandes',
  incrementalProduction: 'Production incrémentielle',
  item: 'Article',
  itemNo: 'Nombre d\'article',
  limitExceeded: 'Limite d’ordres dépassée, veuillez arrêter ou compléter les ordres en cours d’exécution',
  lineConfig: 'Configuration de la ligne',
  loadingItems: 'Chargement d\'éléments..',
  manage: 'Gérer les commandes',
  manualProductionAdded: 'Ajout de la production manuelle',
  manualProductionOverwritten: 'Production manuelle écrasée',
  new: 'Nouvelle commande',
  noActiveOrPlannedOrders: 'Aucune commande active ou planifiée',
  noOrders: 'Aucune commandes',
  number: 'Nombre',
  order: 'Commande',
  orderDetails: 'Détails de la commande',
  orderHistory: 'Historique des commandes',
  orderId: 'ID de commande',
  orderNumber: 'Numéro de commande',
  orderStarted: 'Commande commencé',
  orders: 'Commandes',
  inProgressOrders: 'Commandes en cours',
  planned: 'Planifié',
  plannedProduction: 'production planifiée',
  orderedQuantity: 'Quantité planifiée',
  typeCorrectQuantity: 'La quantité prévue doit être supérieure à zéro',
  quantity: 'Produit',
  productionStats: 'Production',
  productionEnteredManually: 'Production et déchets confirmés manuellement',
  production: 'Résumé de la production',
  productionUpdates: 'Mise à jour de production',
  quantityUnit: 'Unité de quantité',
  realisation: 'Réalisation',
  reportResources: 'Ressources du rapport',
  saveAndActivate: 'Enregistrer et activer',
  schedule: 'Planning',
  scheduled: 'Planification',
  selectLineAndSku: 'Sélectionner la ligne et la référence SKU',
  machinesForOrder: 'Sélectionner des machines pour cette tâche',
  selectOrder: 'Sélectionner une commande',
  selectSku: 'Sélectionnez SKU',
  show: 'Afficher la commande',
  sku: 'SKU',
  skuId: 'SKU ID',
  skuPerformance: 'Performances SKU',
  firstActivate: 'Certains composants sont requis avant le démarrage. Activez la commande et scannez d’abord les composants',
  requiredComponentsNotScanned: 'Certains composants doivent d’abord être analysés',
  start: 'Commencer',
  startOrder: 'Commencer la commande',
  status: 'Statut',
  stop: 'Arrêter',
  toPlannedEnd: 'Jusqu’à la fin planifiée',
  toPlannedStart: 'Au démarrage planifié',
  totalProduction: 'Production totale',
  type: 'Type',
  updateProduction: 'Mise à jour de la production :',
  wasted: 'Rebus',
  confirmCurrentProduction: 'Confirmer la production',
  'removeLastEntryFirst': 'Supprimez la dernière déclaration de production avant de confirmer à nouveau la production de la commande',
  'skuDescription': 'Description du SKU',
  'skuNumber': 'Numéro du SKU',
  'settlement': 'Règlement',
  'noProducts': 'Aucun SKU',
  'release': 'Libération',
  'take': 'Prendre',
  'finishOrder': 'Terminer la commande',
  'product': 'Produit',
  'plannedQuantity': 'Quantité prévue',
  'defaultConfiguration': 'Configuration par défaut'
};
