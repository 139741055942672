export default {
  moveHereToGroup: '将列移到此处以进行分组',
  moveHereToHide: '将列移至此处以隐藏',
  sEmptyTable: '表中无可用数据',
  sInfoFiltered: '（从 _MAX_ 个条目中筛选出）',
  sLoadingRecords: '加载中',
  sProcessing: '处理中',
  sSearch: '搜索：',
  sZeroRecords: '没有找到匹配的记录',
  oPaginate: {
    sFirst: '第一个', sLast: '最后一个', sNext: '下一个', sPrevious: '前一个',
  },
  oAria: { sSortAscending: '激活以对列进行升序排序', sSortDescending: '激活以对列进行降序排序' },
};
