export default {
  'originalDates': 'Datas originais',
  'originalDuration': 'Duração original',
  'newDates': 'Novas datas',
  'newDuration': 'Nova duração',
  'firstDowntime': '1º Parada:',
  'secondDowntime': '2º Parada:',
  'shorten': 'Abreviar',
  'split': 'Dividir',
  'thisMachine': 'esta máquina',
  'addComment': 'Adicionar um comentário',
  'noComments': 'Sem comentários',
  'downtimeSummary': 'Resumo de Paradas',
  'step': 'Etapa {step}',
  'selectRootLineMachine': 'Selecionar o local da causa raiz',
  'selectLineMachine': 'Selecione linha/máquina',
  'selectState': 'Selecionar estado',
  'selectProblem': 'Selecionar problema',
  'specifyAReason': 'Especificar um motivo',
  'correctiveActionTaken': 'Ação corretiva tomada',
  'downtimeDuration': 'Duração da parada',
  'vision': 'Visão',
  'oeeLossType': 'Tipo de perda OEE',
  'table': {'downtime': 'Parada', 'details': 'Detalhes', 'time': 'Tempo', 'duration': 'Duração'},
  'noSharedComments': 'Sem comentários compartilhados',
  visionRequestClip: "Solicitar vídeo",
  chooseACamera: "Escolha uma câmera",
  startOfAVideo: "Início de um vídeo",
  endOfAVideo: "Fim de um vídeo",
  requestVideo: "Solicitar vídeo",
  lossType: "Tipo de Perda",
  filterAny: "Qualquer",
  hasClips: "Tem clipes de vídeo",
  notHaveClips: "Não tem clipes de vídeo",
  hasCorrectiveAction: "Tem ação corretiva",
  notHaveCorrectiveAction: "Não tem ação corretiva",
  hasReason: "Tem motivo",
  notHaveReason: "Não tem motivo",
  hasComments: "Tem comentários",
  notHaveComments: "Não tem comentários",
  noClipsRequested: "Nenhum clipe de vídeo solicitado",
};
