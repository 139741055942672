<template>
  <div>
    <LineIndex
      v-if="isLine"
      :selected-page="page"
      :line-id="elementId"
    >
      <div />
    </LineIndex>
    <Machine
      v-else
      :element-id="elementId"
      :selected-page="page"
    >
      <div />
    </Machine>
    <RouterView @pageChange="page = $event" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LineIndex from './line/Index';
import Machine from './machine/Index';

export default {
  props: {
    elementId: String,
  },
  data: () => ({
    page: '',
  }),
  components: {
    LineIndex,
    Machine,
  },
  computed: {
    ...mapGetters('plant', ['lines']),
    isLine() {
      if (!this.lines) return false;
      return this.lines.find(x => x.id === this.elementId);
    },
  },
};
</script>

<style>

</style>
