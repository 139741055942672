<template>
  <div class="d-flex justify-content-center">
    <div class="mx-2">
      <button
        v-if="customSelected"
        v-tippy="{ arrow: true }"
        :content="$t('reports.selectors.selectExactDates')"
        class="btn btn-sm btn-secondary icon-btn ml-2"
        style="margin-top: 20px;"
        @click="closePicker"
      >
        <i
          class="fas fa-calendar-day"
          style="font-size: 0.9em"
        />
      </button>
      <button
        v-else
        v-tippy="{ arrow: true }"
        :content="$t('reports.selectors.selectExactDates')"
        class="btn btn-sm btn-secondary icon-btn"
        style="margin-top: 20px;"
        @click="openFromPicker"
      >
        <i
          class="fas fa-calendar-alt"
          style="font-size: 0.9em"
        />
      </button>
    </div>
    <div
      class="flex-grow-1"
    >
      <RelativeDateSelector
        v-if="!customSelected"
        ref="relativeSelector"
        :init-period="period"
        :init-moment="moment"
        @moment="select($event)"
      />
      <div v-else>
        <h6 class="w-100">
          {{ $t('general.from') }} - {{ $t('general.to') }}
        </h6>
        <div class="d-flex">
          <DateTimePicker
            id="startDateSelector"
            ref="startDateSelector"
            v-model="initialCustomStart"
            :color="colors('ilabo')"
            :locale="locale"
            :first-day-of-week="weekStart === 0 ? 7 : weekStart"
            class="w-50 custom-picker mx-1"
            overlay
            no-clear-button
            enable-button-validate
            :format="pickerFormat"
            formatted="D MMM YYYY HH:mm"
            label=""
            @is-hidden="hideStart"
          />
          <DateTimePicker
            id="endDateSelector"
            ref="endDateSelector"
            v-model="customRange.end"
            :color="colors('ilabo')"
            :locale="locale"
            :first-day-of-week="weekStart === 0 ? 7 : weekStart"
            class="w-50 custom-picker mx-1"
            overlay
            no-clear-button
            enable-button-validate
            :format="pickerFormat"
            formatted="D MMM YYYY HH:mm"
            label=""
            :right="true"
            @is-hidden="hideEnd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RelativeDateSelector from '@/components/selectors/RelativeDateSelector';
import colors from '@/utils/colors';
import DateTimePicker from '@ilabo/vue-ctk-date-time-picker';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    startDate: Number,
    endDate: Number,
    period: Number,
    moment: String,
    showItem: Boolean,
  },
  data() {
    return {
      pickerFormat: 'YYYY-MM-DD HH:mm',
      selectedPeriod: 1,
      selectedMoment: 'today',
      customSelected: false,
      selectingFirst: false,
      selectingSecond: false,
      localStartDate: null,
      localEndDate: null,
      initialCustomStart: null,
      customRange: {
        start: null,
        end: null,
      },
      rangeInit: false,
    };
  },
  components: {
    DateTimePicker,
    RelativeDateSelector,
  },
  computed: {
    ...mapGetters('core', ['locale', 'weekStart']),
    ...mapGetters('report', [
      'skuId',
    ]),
    ...mapState({
      orders: state => state.orders.orders || [],
    }),
    skus() {
      const ordersBySku = this.orders
        .filter(x => x.status === 'complete')
        .reduce((acc, curr) => {
          if (acc[curr.skuId]) {
            acc[curr.skuId].push(curr);
          } else {
            acc[curr.skuId] = [curr];
          }
          return acc;
        }, {});

      return Object.values(ordersBySku).map(orders => ({
        id: orders[0].skuId,
        number: orders[0].skuNumber,
        description: orders[0].skuDescription,
        orders,
      }));
    },
    dateFrom: {
      get() {
        return this.localStartDate;
      },
      set(timestamp) {
        this.localStartDate = timestamp;
      },
    },
    dateTo: {
      get() {
        return this.localEndDate || this.$now;
      },
      set(timestamp) {
        this.localEndDate = timestamp;
      },
    },
    dataToEmit() {
      return {
        startDate: this.localStartDate,
        endDate: this.localEndDate,
        moment: this.selectedMoment,
        period: this.selectedPeriod,
        isCustom: this.customSelected,
      };
    },
    startMoment() {
      return moment(this.dateFrom * 1000);
    },
    endMoment() {
      return moment(this.dateTo * 1000);
    },
  },
  watch: {
    dataToEmit(data) {
      this.$emit('setDates', data);
    },
  },
  methods: {
    colors,

    setSku(skuId) {
      const skuWithOrders = this.skus.find(x => x.id === skuId);
      this.$store.commit('report/setSku', skuWithOrders);
    },
    select(m) {
      if (m) {
        this.dateFrom = m.startDate;
        this.dateTo = m.endDate;
        this.selectedPeriod = m.period;
        this.selectedMoment = m.key;
      }
    },
    formatDate(date) {
      const timestamp = moment(date).unix();
      return moment(timestamp * 1000).format('H:mm D MMM YYYY');
    },
    hideStart() {
      if (this.initialCustomStart !== this.customRange.start) {
        this.customRange.start = this.initialCustomStart;

        if (moment(this.customRange.start).isAfter(this.customRange.end)) {
          this.customRange.end = moment(this.customRange.start).add(1, 'day').format();
        }
        this.$refs.endDateSelector.toggleDatePicker();
      }
    },
    hideEnd() {
      this.select({
        startDate: moment(this.customRange.start).unix(),
        endDate: moment(this.customRange.end).unix(),
      });
    },
    closePicker() {
      this.customSelected = false;
      this.rangeInit = false;
    },
    openFromPicker() {
      this.customSelected = true;
      this.customRange = {
        start: moment(this.dateFrom * 1000).format(this.pickerFormat),
        end: moment(this.dateTo * 1000).format(this.pickerFormat),
      };
      this.initialCustomStart = moment(this.dateFrom * 1000).format(this.pickerFormat);
    },
  },
  created() {
    this.closePicker();
    if (this.startDate && this.endDate) {
      this.dateFrom = this.startDate;
      this.dateTo = this.endDate;
      this.customSelected = true;
      this.customRange = {
        start: moment(this.dateFrom * 1000).format(this.pickerFormat),
        end: moment(this.dateTo * 1000).format(this.pickerFormat),
      };
      this.initialCustomStart = moment(this.dateFrom * 1000).format(this.pickerFormat);
    } else if (this.period && this.moment) {
      this.selectedPeriod = this.period;
      this.selectedMoment = this.moment;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .date-box {
    padding: 2px 15px;
    font-size: 15px;
    margin: 0 3px;
    cursor: pointer;
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);

    &.active {
      border-color: $ilabo;
      background-color: rgba($ilabo, 0.2);
    }
  }

  .custom-picker {
    font-weight: normal;
  }

  .custom-selectors {
    margin: 0;
    position: relative;
    max-width: 400px;
  }

  .custom-picker :deep(.field .field-input) {
    padding-top: 0 !important;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    font-family: inherit;
    height: 38px;
    min-height: 38px;
  }

  .custom-picker :deep(.datetimepicker) {
    z-index: 100;
  }

  .custom-picker :deep(.time-picker-overlay) {
    z-index: 99;
  }

  h6 {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .over-connector {
    position: relative;
    z-index: 2;
  }

  .clear-icon {
    cursor: pointer;
    transition: transform 0.5s, color 0.5s;
  }

  .clear-icon:hover {
    transform: scale(1.5);
    color: #8bb812;
  }

  .date-box-connector {
    position: absolute;
    width: 100%;
    top: 8px;
  }

</style>
