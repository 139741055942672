import Vue from 'vue';
import Chart from 'chart.js';
import vClickOutside from 'v-click-outside';
import VueApexCharts from 'vue-apexcharts';
import VueChartist from 'vue-chartist';
import VueTippy from 'vue-tippy';

import { addSpaces, round } from '@core/utils/numbers';

import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import '@ilabo/vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'vue-search-select/dist/VueSearchSelect.css';

import StateBadge from '@/components/utils/StateBadge';
import ElementPicture from '@/components/utils/ElementPicture';

Vue.use(VueChartist);
Vue.use(vClickOutside);
Vue.use(VueTippy);

Chart.defaults.global.defaultFontFamily = 'Roboto';
Chart.defaults.global.plugins.datalabels.display = false;

Vue.filter('integer', value => (typeof (value) === 'number' ? Math.round(value) : value));
Vue.filter('addSpaces', addSpaces);
Vue.filter('round', round);

Vue.use(VueApexCharts);

Vue.component('StateBadge', StateBadge);
Vue.component('ElementPicture', ElementPicture);
