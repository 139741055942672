<template>
  <div>
    <div class="text-center position-relative">
      <ElementSelector
        v-if="!nested"
        :show-plant="true"
        :element-id="localElementId"
        @select="changeElement"
      />
    </div>
    <div class="row pt-1">
      <div
        v-if="!nested"
        class="col-md-4"
      >
        <div
          v-for="t in notificationTypes"
          :key="t.id"
          :class="{ selected: selectedTypes[t.id] }"
          class="type d-flex justify-content-between"
          @click="$set(selectedTypes, t.id, !selectedTypes[t.id])"
        >
          <div>
            <i :class="`${t.icon} pr-2`" />
            {{ t.name }}
          </div>
          <div
            v-if="selectedTypes[t.id]"
            class="text-ilabo position-relative"
            style="font-size: 18px; line-height: 1; top: 2px"
          >
            <i class="ion ion-ios-checkmark-circle" />
          </div>
        </div>
        <div class="mt-4">
          <CustomCheckbox
            :value.sync="subscribedOnly"
            :color="$color('ilabo')"
            class="mx-2 font-weight-bold"
          >
            <i class="ion ion-ios-body px-2" />
            {{ $t('notify.subscribedOnly') }}
          </CustomCheckbox>
        </div>
      </div>
      <div :class="nested ? 'col-md-12' : 'col-md-8'">
        <div
          v-if="!customDates"
          class="d-flex justify-content-end"
        >
          <DateAndItemPicker
            :start-date="startDate"
            :end-date="endDate"
            @setDates="updateTime"
          />
        </div>
        <hr v-if="!customDates">
        <div
          v-if="pending"
          class="text-center"
        >
          <Loader />
        </div>
        <div
          v-else-if="filteredNotifications.length > 0"
          style="max-height: 700px; min-height: 700px; overflow-y: scroll; padding: 0 15px"
        >
          <TransitionGroup name="list-complete">
            <div
              v-for="(a, index) in filteredNotifications"
              :key="index"
              class="list-complete-item w-100"
            >
              <NotificationRow
                v-bind="a"
                :action="getAction(a)"
                :feedback-dictionary="feedbackDictionary"
              />
            </div>
          </TransitionGroup>
        </div>

        <div
          v-if="false"
          style="height: 700px;"
        >
          <h5 class="text-center">
            {{ $t('notify.nothing') }}
          </h5>
          <div
            v-if="advices.length > 0"
            class="mt-2 text-center"
          >
            <button
              class="btn btn-ilabo btn-sm px-4"
              @click="showAll"
            >
              {{ $t('general.showAll') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementSelector from '@/components/element/ElementSelector';
import action from '@/components/notifications/action';
import DateAndItemPicker from '@/components/reports/DateAndItemPicker';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import NotificationRow from './NotificationRow';

export default {
  props: {
    elementId: String,
    selectTypes: Array,
    subscribed: Boolean,
    nested: Boolean,
    settings: Object,
    customDates: Boolean,
    customStartDate: Number,
    customEndDate: Number,
  },
  data: () => ({
    localElementId: null,
    selectedTypes: { },
    subscribedOnly: false,
    date: null,
    localTime: null,
    pending: false,
    notifications: {},
    ready: false,
    feedbackDictionary: [],
  }),
  components: {
    DateAndItemPicker,
    ElementSelector,
    NotificationRow,
  },
  computed: {
    ...mapGetters('core', ['locale', 'weekStart']),
    ...mapGetters(['plantId', 'dayStartOffsetInUserTimezone', 'startDate', 'endDate']),
    ...mapGetters('notify', ['notificationTypes']),
    ...mapGetters('plant', ['structure']),
    filteredNotifications() {
      return this.advices
        .filter(a => this.selectedTypes[a.type])
        .sort((a, b) => b.created - a.created);
    },
    advices() {
      if (this.localElementId === this.plantId) {
        return [
          ...this.structure.lowerChildrenOf(this.localElementId, 2)
            .flatMap(id => this.notifications[id] || []),

          ...this.structure.lowerChildrenOf(this.localElementId, 3)
            .flatMap(id => this.notifications[id] || []),

          ...this.notifications[this.localElementId] || [],
        ];
      }

      return this.structure.lowerChildrenOf(this.localElementId, 1)
        .flatMap(id => this.notifications[id] || [])
        .concat(this.notifications[this.localElementId] || []);
    },
  },
  watch: {
    subscribedOnly() {
      if (this.ready) {
        this.request();
      }
    },
    elementId(id) {
      this.localElementId = id;
    },
    customStartDate(d) {
      this.localTime.startDate = d;
      if (this.ready) {
        this.request();
      }
    },
    customEndDate(d) {
      this.localTime.endDate = d;
      if (this.ready) {
        this.request();
      }
    },
  },
  methods: {
    ...mapActions('core', ['getNotifications']),
    ...mapActions('notify', [
      'getElementNotifications',
      'getFeedbackDictionary',
    ]),
    changeElement(id) {
      this.localElementId = id.toLowerCase();
      this.request();
    },
    updateTime(d) {
      this.localTime = d;
      this.request();
    },
    getAction(n) {
      const notificationAction = action(n, this.$router);
      if (!notificationAction) return null;
      return () => {
        this.$emit('close');
        notificationAction();
      };
    },
    showAll() {
      this.notificationTypes.forEach(t => {
        this.$set(this.selectedTypes, t.id, true);
      });
    },
    requestSubscribed() {
      this.pending = true;
      this.getNotifications({
        params: {
          utcOffset: -(moment().utcOffset()),
          query: {
            startDate: this.localTime.startDate,
            endDate: this.localTime.endDate,
            plantId: this.plantId,
          },
        },
      })
        .then(({ data, config: { params } }) => {
          if (params.query.startDate !== this.localTime.startDate
            || params.query.endDate !== this.localTime.endDate) {
            return;
          }
          this.notifications = {};
          data.forEach(x => {
            if (this.notifications[x.elementId]) {
              this.notifications[x.elementId].push(x);
            } else {
              this.$set(this.notifications, x.elementId, [x]);
            }
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
    request() {
      if (!this.localElementId) return;

      if (this.subscribedOnly) {
        this.requestSubscribed();
        return;
      }
      const utcOffset = -(moment().utcOffset());

      this.pending = true;
      this.getElementNotifications({
        params: {
          plantId: this.plantId,
          utcOffset,
          query: {
            startDate: this.localTime.startDate,
            endDate: this.localTime.endDate,
          },
        },
      })
        .then(({ data, config: { params } }) => {
          if (params.query.startDate !== this.localTime.startDate
            || params.query.endDate !== this.localTime.endDate) {
            return;
          }

          this.notifications = {};
          data.forEach(x => {
            if (this.notifications[x.elementId]) {
              this.notifications[x.elementId].push(x);
            } else {
              this.$set(this.notifications, x.elementId, [x]);
            }
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.localElementId = this.elementId.toLowerCase();
    this.subscribedOnly = this.subscribed;

    if (this.customDates) {
      this.localTime = {
        startDate: this.customStartDate,
        endDate: this.customEndDate,
      };
    } else {
      this.localTime = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    }

    this.getFeedbackDictionary()
      .then(({ data }) => {
        this.feedbackDictionary = data;
      });

    if (this.selectTypes) {
      this.selectTypes.forEach(t => {
        this.$set(this.selectedTypes, t, true);
      });
    } else {
      this.notificationTypes.forEach(t => {
        this.$set(this.selectedTypes, t.id, true);
      });
    }

    this.request();
    this.$nextTick(() => {
      this.ready = true;
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .type {
    padding: 4px 10px;
    border: 2px solid rgba(150, 150, 150, 0.2);
    margin-top: 5px;
    border-radius: 10px;
    box-shadow: 0 0 3px rgba(100, 100, 100, 0.2);
    transition: box-shadow 400ms, transform 300ms;
    cursor: pointer;
    font-weight: bold;

    &.selected {
      transform: translateX(2px);
      border: 2px solid $ilabo;
      color: $ilabo;
    }

    &:hover {
      box-shadow: 0 0 3px rgba(100, 100, 100, 0.5);
      transform: translateX(4px);
    }

  }
</style>
