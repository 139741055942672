export default {
  orders: 'Заказы',
  order: 'Заказ',
  import: 'Импорт заказов',
  completedOrders: 'Выполненные заказы',
  inProgressOrders: 'Заказы выполняются',
  start: 'Начать',
  saveAndActivate: 'Сохранить и активировать',
  orderDetails: 'Информация для заказа',
  activate: 'Активировать',
  stop: 'Стоп',
  end: 'Конец',
  show: 'Показать порядок',
  startOrder: 'Начать заказ',
  activateOrder: 'Активировать заказ',
  abortOrderActivate: 'Отменить активацию',
  selectOrder: 'Выбрать порядок',
  selectLineAndSku: 'Выберите линию и артикул',
  skuPerformance: 'Показатели\\производительность по SKU',
  abort: 'Прервать',
  status: 'Статус',
  number: 'Число',
  orderNumber: 'Номер заказа',
  skuId: 'SKU ID',
  orderId: 'номер заказа',
  item: 'Элемент',
  sku: 'SKU',
  itemNo: 'Номер предмета',
  schedule: 'Расписание',
  scheduled: 'Запланированное',
  plannedProduction: 'Планируемое производство',
  realisation: 'Реализация',
  expectedStart: 'Ожидается старт',
  expectedEnd: 'Ожидается конец',
  toPlannedEnd: 'к запланированному концу',
  toPlannedStart: 'к запланированному началу',
  afterPlannedEnd: 'после запланированного конца',
  afterPlannedStart: 'после запланированного старта',
  expectedPerformance: 'Ожидаемая производительность',
  description: 'Описание',
  quantity: 'Произведено',
  limitExceeded: 'Превышен лимит заказов, пожалуйста, остановите или завершите все текущие заказы',
  production: 'Сводка производства',
  wasted: 'Потрачено',
  updateTotalProduction: 'Обновить общее производство:',
  productionUpdates: 'Обновления производства',
  complete: 'Выполнено',
  hold: 'Держать',
  completed: 'Завершенный',
  planned: 'Планируется',
  lineConfig: 'Конфигурация линии',
  config: 'Конфигурация',
  additional: 'Дополнительно',
  machinesForOrder: 'Выберите машины для этой работы',
  requiredComponentsNotScanned: 'Некоторые компоненты необходимо сначала просканировать',
  firstActivate: 'Перед запуском требуются некоторые компоненты. Активируйте заказ и сначала просканируйте компоненты',
  productionStats: 'Производство',
  comment: {
    startOrder: 'Заказ начнется немедленно. Все отсканированные упаковки будут активированы', completeOrder: 'Заказ будет выполнен и не может быть запущен снова', stopOrder: 'Заказ будет остановлен, и его можно будет запустить снова позже.', abortOrderActivate: 'Активация будет прервана, состояние заказа снова изменится на «Готово».',
  },
  states: {
    inProgress: 'В ходе выполнения', ready: 'Ожидание', completed: 'Завершено', other: 'Другое', activated: 'Активированно', onHold: 'На удерживании',
  },
  activities: {
    addActivity: 'Планируйте новую деятельность', editActivity: 'Изменить действие', changeover: 'Переключение', order: 'порядок', cip: 'CIP', completedJob: 'Завершенная работа', inProgressJob: 'В процессе работы',
  },
  add: 'Добавить заказ',
  create: 'Создать заказ',
  new: 'Новый заказ',
  edit: 'Изменить заказ',
  orderedQuantity: 'Планируемое количество',
  typeCorrectQuantity: 'Планируемое количество должно быть больше нуля',
  quantityUnit: 'Единица измерения количества',
  type: 'Тип',
  loadingItems: 'Загрузка предметов ..',
  manage: 'Управлять заказами',
  noActiveOrPlannedOrders: 'Нет активных или запланированных заказов',
  noOrders: 'Нет заказов',
  orderStarted: 'Заказ начат',
  manualProductionAdded: 'Добавлено ручное производство',
  manualProductionOverwritten: 'Ручное производство перезаписано',
};
