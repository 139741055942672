<template>
  <div class="row no-gutters mx-0">
    <div
      v-for="r in routes"
      :key="r.key"
      class="route col text-nowrap text-center"
      @click.stop="r.select()"
    >
      <i :class="`${r.icon} pr-2`" />
      {{ r.text }}
    </div>

    <LModal
      :show.sync="worklogModal"
      size="lg"
    >
      <h3 class="font-weight-bold modal-title">
        {{ $t('monitoring.workStates') }}
      </h3>
      <WorklogModal
        :element-id="elementId"
        :start="dateScope.startDate"
        :end="dateScope.endDate"
      />
    </LModal>

    <LModal
      :show.sync="productionModal"
      size="lg"
    >
      <h3 class="modal-title">
        {{ $t('monitoring.productionProgress') }}
      </h3>
      <ProductionModal
        :element-id="elementId"
        :production="production"
        :performance="performance"
      />
    </LModal>

    <LModal
      :show.sync="oeeModal"
      size="lg"
    >
      <OEEInfoModal
        :element-id="elementId"
        :current-oees="oees"
        :target="targetOees"
        :start-date="dateScope.startDate"
        :end-date="dateScope.endDate"
        style="cursor: default"
      />
    </LModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import WorklogModal from '@/components/modals/WorklogModal';
import OEEInfoModal from '@/components/modals/OEEInfoModal';
import ProductionModal from '../modals/ProductionModal';

export default {
  props: {
    elementId: String,
    oees: Object,
    targetOees: Object,
    production: Object,
    pendingOrder: Object,
    performance: Object,
    shift: Object,
  },
  data() {
    return {
      worklogModal: false,
      productionModal: false,
      oeeModal: false,
      orders: [],
      routes: [
        {
          key: 'production',
          icon: 'fas fa-chart-line',
          text: this.$t('monitoring.production'),
          select: () => {
            this.productionModal = true;
          },
        },
        {
          key: 'worklog',
          icon: 'icon-downtime',
          text: this.$t('monitoring.workLog'),
          select: () => {
            this.worklogModal = true;
          },
        },
        {
          key: 'oee',
          icon: 'fas fa-tachometer-alt',
          text: this.$t('oee.oee'),
          select: () => {
            this.oeeModal = true;
          },
        },
      ],
    };
  },
  components: {
    ProductionModal,
    WorklogModal,
    OEEInfoModal,
  },
  computed: {
    ...mapGetters([
      'startDate',
      'endDate',
      'splitByOrder',
      'splitByShift',
    ]),
    dateScope() {
      if (this.splitByOrder && this.pendingOrder) {
        return {
          startDate: moment(this.pendingOrder.actualExecution?.begin).unix(),
          endDate: this.$now,
        };
      }
      if (this.splitByShift) {
        return {
          startDate: this.shift ? this.shift.startDate : null,
          endDate: this.shift ? this.shift.endDate : null,
        };
      }

      return {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
  },
  methods: {
    toUnix(date) {
      return moment(date).unix();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../line";
</style>
