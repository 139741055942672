export default {
  'orders': 'Aufträge',
  'order': 'Auftrag',
  'import': 'Aufträge importieren',
  'completedOrders': 'abgeschlossene Aufträge',
  'inProgressOrders': 'laufende Aufträge',
  'start': 'Start',
  'saveAndActivate': 'speichern und aktivieren',
  'orderDetails': 'Auftragsdetails',
  'activate': 'aktivieren',
  'stop': 'stop',
  'end': 'Ende',
  'show': 'zeige Auftrag',
  'startOrder': 'Starte Auftrag',
  'activateOrder': 'Auftrag aktivieren',
  'abortOrderActivate': 'Aktivierung abbrechen',
  'selectOrder': 'Auftrag auswählen',
  'selectLineAndSku': 'Linie und SKU auswählen',
  'skuPerformance': 'SKU Leistung',
  'abort': 'abbrechen',
  'status': 'Status',
  'number': 'Nummer',
  'orderNumber': 'Auftragsnummer',
  'skuId': 'SKU ID',
  'orderId': 'Auftragsnummer',
  'item': 'Artikel',
  'sku': 'SKU',
  'itemNo': 'Artikelnummer',
  'schedule': 'Zeitplan',
  'scheduled': 'geplanter Produktionszeitraum',
  'plannedProduction': 'geplante Produktionsmenge',
  'realisation': 'realisierter Produktionszeitraum',
  'expectedStart': 'erwarteter Start',
  'expectedEnd': 'erwartetes Ende',
  'toPlannedEnd': 'bis zu dem geplanten Ende',
  'toPlannedStart': 'bis zu dem geplanten Starte',
  'afterPlannedEnd': 'hinter dem geplanten Ende',
  'afterPlannedStart': 'hinter dem geplanten Start',
  'expectedPerformance': 'erwartete Leistung',
  'description': 'Beschreibung',
  'quantity': 'Produzierte Menge',
  'limitExceeded': 'Grenze von Aufträgen überschritten, laufende Aufträge bitte stoppen oder beenden',
  'production': 'Zusammenfassung Produktion',
  'wasted': 'Ausschuss',
  'updateProduction': 'Update Produktion',
  'totalProduction': 'Gesamtproduktion',
  'incrementalProduction': 'Schrittweise Produktion',
  'productionUpdates': 'Produktionsupdates',
  'complete': 'abschließen',
  'hold': 'halt',
  'completed': 'Abgeschlossen',
  'planned': 'geplant',
  'lineConfig': 'Linien Konfiguration',
  'config': 'Konfiguration',
  'additional': 'zusätzlich',
  'machinesForOrder': 'wähle Maschinen für den Auftraga aus',
  'requiredComponentsNotScanned': 'einige Komponenten müssen zuerst gescannt werden',
  'firstActivate': 'Einige Komponenten werden vor dem Start benötigt. Scannen Sie zuerst die Komponenten und Aktivieren Sie dann den Auftrag',
  'productionStats': 'Produktion',
  'comment': {
    'startOrder': 'Der Auftrag wird sofort gestartet. Alle gescannten Pakete werden aktiviert',
    'activateOrder': 'Der Auftrag wird für den Start vorbereitet, die Reihenfolge kann noch geändert werden und ggf. müssen Teile vor dem Start gescannt werden',
    'completeOrder': 'Der Auftrag wird abgeschlossen und kann nicht erneut gestartet werden',
    'stopOrder': 'Der Auftrag wird gestoppt und kann später wieder gestartet werden',
    'abortOrderActivate': 'Die Aktivierung wird abgebrochen, der Auftragsstatus wechselt wieder auf \'Bereit\''
  },
  'states': {
    'inProgress': 'Wird produziert',
    'ready': 'Wartende Aufträge',
    'completed': 'Beendete Aufträge',
    'other': 'Andere',
    'activated': 'Aktivierte Aufträge',
    'onHold': 'Wartend',
    'taken': 'genommen'
  },
  'activities': {
    'addActivity': 'neue Aktivität planen',
    'editActivity': 'Aktivität bearbeiten',
    'changeover': 'Rüsten',
    'stop': 'Stop durch den Bediener',
    'failure': 'Störung',
    'lack': 'Mangel an Ressourcen und Produkten',
    'order': 'Auftrag',
    'idle': 'Stillstand',
    'holdup': 'keine Maschinenbelegung',
    'inactive': 'Inaktiv',
    'cip': 'CIP',
    'completedJob': 'Auftrag beendet',
    'inProgressJob': 'Auftrag in Bearbeitung'
  },
  'add': 'Auftrag hinzufügen',
  'create': 'Auftrag erstellen',
  'new': 'Neuer Auftrag',
  'edit': 'Auftrag bearbeiten',
  'orderedQuantity': 'geplante Menge',
  'typeCorrectQuantity': 'geplante Menge muss größer als Null sein',
  'quantityUnit': 'Mengen Einheit',
  'type': 'Typ',
  'loadingItems': 'lädt Artikel',
  'manage': 'Aufträge verwalten',
  'noActiveOrPlannedOrders': 'keine aktiven oder geplanten Aufträge',
  'noOrders': 'keine Aufträge',
  'orderStarted': 'Auftrag gestartet',
  'manualProductionAdded': 'Produktion manuell hinzugefügt',
  'manualProductionOverwritten': 'Produktion manuell geändert',
  'release': 'Veröffentlichung',
  'take': 'nehmen',
  'finishOrder': 'Auftrag abschließen',
  'product': 'Produkt',
  'plannedQuantity': 'Geplante Menge',
  'removeLastEntryFirst': 'Entfernen Sie die letzte Produktiondaten, bevor Sie den Auftrag erneut bestätigen',
  'reportResources': 'Personenstunden eingeben',
  'confirmCurrentProduction': 'Produktion bestätigen',
  'selectSku': 'Wähle SKU',
  'skuDescription': 'SKU Beschreibung',
  'skuNumber': 'SKU Nummer',
  'settlement': 'Übersicht',
  'noProducts': 'keine SKU',
  'productionEnteredManually': 'Produktionsmenge und Ausschuss manuell bestätigt',
  'orderHistory': 'Auftragshistorie',
  'defaultConfiguration': 'Standardkonfigurationen'
};
