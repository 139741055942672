export default {
  sEmptyTable: 'ไม่มีข้อมูลในตาราง',
  sInfoFiltered: '(กรองจากทั้งหมด MAX รายการ)',
  sLoadingRecords: 'กำลังโหลด...',
  sProcessing: 'กำลังประมวลผล...',
  sSearch: 'ค้นหา:',
  sZeroRecords: 'ไม่พบระเบียนที่ตรงกัน',
  downloadAsXls: 'ดาวน์โหลดเป็น xls',
  oPaginate: {
    sFirst: 'หน้าแรก', sLast: 'หน้าสุดท้าย', sNext: 'ถัดไป', sPrevious: 'ก่อนหน้า',
  },
  oAria: { sSortAscending: ': เปิดใช้งานเพื่อเรียงคอลัมน์จากน้อยไปมาก', sSortDescending: ': เปิดใช้งานเพื่อเรียงคอลัมน์จากมากไปน้อย' },
};
