export default {
  'moveHereToGroup': 'Verplaats kolom hierheen om te groeperen',
  'moveHereToHide': 'Verplaats kolom hierheen om te verbergen',
  'sEmptyTable': 'Geen data beschikbaar in tabel',
  'sInfoFiltered': '(gefilterd van _MAX_ totale eenheden)',
  'sLoadingRecords': 'Laden...',
  'sProcessing': 'Verwerken...',
  'sSearch': 'Zoeken:',
  'sZeroRecords': 'Geen matchende regels gevonden',
  'oPaginate': {'sFirst': 'Eerste', 'sLast': 'Laatste', 'sNext': 'Volgende', 'sPrevious': 'Vorige'},
  'oAria': {
    'sSortAscending': 'Aanzetten weergeven kolommen van laag naar hoog',
    'sSortDescending': 'Aanzetten weergeven kolommen van hoog naar laag'
  },
  'downloadAsXls': 'Download als xls'
};
