import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const eventrules = new Vapi({
  axios,
  state: {
    list: [],
  },
})
  .get({
    action: 'getElementEventRules',
    property: 'list',
    path: ({ plantId, elementId }) => `/v2/plant/${plantId}/element/${elementId}/rules`,
    beforeRequest: s => {
      s.list = [];
    },
  })
  .post({
    action: 'addEventRule',
    path: ({ plantId }) => `/v2/plant/${plantId}/rule`,
    onSuccess: (s, { data }) => {
      if (!s.list) return;
      s.list.push(data);
    },
  })
  .put({
    action: 'updateEventRule',
    path: ({ plantId, ruleId }) => `/v2/plant/${plantId}/rule/${ruleId}`,
    onSuccess: (s, { data }) => {
      if (!s.list) return;
      const index = s.list.findIndex(r => r.id === data.id);
      if (index !== -1) {
        Vue.set(s.list, index, data);
      }
    },
  })
  .delete({
    action: 'deleteEventRule',
    path: ({ plantId, ruleId }) => `/v2/plant/${plantId}/rule/${ruleId}`,
    onSuccess: (s, _, a, { params }) => {
      if (!s.list) return;
      s.list = s.list.filter(r => r.id !== params.ruleId);
    },
  })
  .getStore();

export default eventrules;
